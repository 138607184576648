import React from "react";
import { useSelector } from "react-redux";
import { Layout, Text } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import selectTimeCardById from "../../../store/timeCards/selectors/selectTimeCardById";
import selectUserById from "../../../store/users/selectors/selectUserById";
import getUserFullName from "../../../utils/getUserFullName";
import Separator from "../../buildingBlocks/Separator";
import UiKittenTouchable from "../../UiKittenTouchable";
import selectTimeFormat from "../../../store/settings/selectors/selectTimeFormat";

dayjs.extend(duration);

type Props = {
  id: string;
  onPress: (id: string) => void;
};

const styles = StyleSheet.create({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    flex: 1,
  },
  subtitle: {
    flex: 1,
  },
  textRow: {
    alignItems: "baseline",
    flexDirection: "row",
  },
});

const HistoricalTimeCardListItem = ({ id, onPress }: Props) => {
  const timeCard = useSelector((state) => selectTimeCardById(state, id));
  const user = useSelector((state) =>
    selectUserById(state, timeCard && timeCard.started.by)
  );
  const timeFormat = useSelector(selectTimeFormat);
  const handleOnPress = () => onPress(id);
  if (!timeCard) {
    return null;
  }

  return (
    <UiKittenTouchable onPress={handleOnPress}>
      <Layout style={styles.root}>
        <Separator size="small" />
        <View style={styles.textRow}>
          <Text category="h6" numberOfLines={1} style={styles.title}>
            {getUserFullName(user)}
          </Text>
          <Separator horizontal size="small" />
          <Text>{dayjs(timeCard.started.at).format(timeFormat)}</Text>
        </View>
        <View style={styles.textRow}>
          <Text appearance="hint" numberOfLines={1} style={styles.subtitle}>
            {`${dayjs
              .duration(dayjs(timeCard.quit.at).diff(timeCard.started.at))
              .as("hours")
              .toFixed(1)} hours`}
          </Text>
          <Separator horizontal size="small" />
          <Text appearance="hint">
            {dayjs(timeCard.quit.at).format(
              dayjs(timeCard.quit.at).isSame(timeCard.started.at, "day")
                ? timeFormat
                : `ddd, ${timeFormat}`
            )}
          </Text>
        </View>
        <Separator size="small" />
      </Layout>
      <Separator size="small" />
    </UiKittenTouchable>
  );
};

export default HistoricalTimeCardListItem;
