import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectEntities";

const selectSiteById = createSelector(
  [selectEntities, (_, id: string) => id],
  (entities, id) => entities[id]
);

export default selectSiteById;
