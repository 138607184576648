import { CheckBox, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";
import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import { InboxItem, Reminder } from "../../../types";

import Separator from "../../buildingBlocks/Separator";
import UiKittenTouchable from "../../UiKittenTouchable";
import selectTimeFormat from "../../../store/settings/selectors/selectTimeFormat";
import selectInboxItemById from "../../../store/inbox/selectors/selectInboxItemById";
import CompactLabel from "../../CompactLabel";
import ListItem, { ListItemProps } from "../Base";
import ReminderListItem from "../Reminder";

export const height = 86;

const FakeListItemAccessoryContainer = styled.View`
  margin: 0 8px;
`;

const TypeHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

const TextContainer = styled.View`
  padding-left: 8px;
  padding-right: 8px;
`;

interface InboxListItemProps
  extends Pick<ListItemProps, "accessoryLeft" | "accessoryRight"> {
  checked: boolean;
  inboxItemId: string;
  onCheckboxChange: (inboxItem: InboxItem) => void;
  onPress?: (inboxItem: InboxItem) => void;
  showsCustomer?: boolean;
}

const InboxListItem = ({
  checked,
  inboxItemId,
  onCheckboxChange,
  onPress,
  showsCustomer = true,
}: InboxListItemProps) => {
  const theme = useTheme();

  const inboxItem = useSelector((state) =>
    selectInboxItemById(state, inboxItemId)
  );
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const customer = useSelector((state) =>
    selectCustomerById(state, inboxItem.metadata?.customerId)
  );

  return (
    <ListItem
      accessoryLeft={() => (
        <FakeListItemAccessoryContainer>
          <CheckBox
            checked={checked}
            onChange={() => onCheckboxChange(inboxItem)}
          />
        </FakeListItemAccessoryContainer>
      )}
      accessoryRight={() => (
        <FakeListItemAccessoryContainer>
          <Icon
            name="arrow-ios-forward"
            style={{
              height: 24,
              tintColor: theme["text-hint-color"],
              width: 24,
            }}
          />
        </FakeListItemAccessoryContainer>
      )}
      onPress={() => onPress(inboxItem)}
      title={() => (
        <TextContainer>
          <View>
            <TypeHeaderContainer>
              <Icon
                name={
                  inboxItem.type === "Reminder"
                    ? "bell-outline"
                    : "email-outline"
                }
                style={{
                  height: 18,
                  tintColor: theme["text-hint-color"],
                  width: 18,
                }}
              />
              <Separator horizontal size="small" />
              <Text appearance="hint" category="label">
                {inboxItem.type === "Reminder" ? "Reminder" : "Email activity"}
              </Text>
            </TypeHeaderContainer>
          </View>
          <Text category="s1">
            {inboxItem.type === "Reminder"
              ? inboxItem.title
              : "Email not delivered"}
          </Text>
          <Text appearance="hint">
            {dayjs(
              inboxItem.type === "Reminder"
                ? inboxItem.metadata.dateTime
                : inboxItem.createdAt
            ).format(dateTimeFormat)}
          </Text>
          {showsCustomer && customer && (
            <Text appearance="hint">{customer.name}</Text>
          )}
        </TextContainer>
      )}
    />
  );
};

export default InboxListItem;
