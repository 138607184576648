import { Company } from "../../types";

const isCustomersEnabled = (company?: Company) => {
  if (company) {
    return (
      company.customersEnabled ||
      company.customersEnabled === undefined ||
      company.customersEnabled === null
    );
  }
  return false;
};

export default isCustomersEnabled;
