import React, { useState } from "react";
import {
  FlatList,
  Keyboard,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import {
  Divider,
  Input,
  Layout,
  ListItem,
  Icon,
  useTheme,
} from "@ui-kitten/components";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import FullScreenModal from "../FullScreen";
import Container from "../../Container";
import Header from "../../buildingBlocks/Header";

const styles = StyleSheet.create({
  listContent: {
    paddingTop: 8,
  },
  root: {
    flex: 1,
  },
  inputContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  titleContainer: {
    flexDirection: "row",
  },
});

interface Props {
  isVisible: boolean;
  onClose: () => void;
  emails: Array<string>;
  onSelect: (id: string) => void;
  selected: Array<string>;
}

const CustomerEmailPickerModal = ({
  isVisible,
  onClose,
  emails,
  onSelect,
  selected,
}: Props) => {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<Array<string>>([]);
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const runSearch = (text) => {
    const formattedQuery = text.trim().toLowerCase();
    setQuery(text);
    setResults(
      emails.filter((email) =>
        email.trim().toLowerCase().includes(formattedQuery)
      )
    );
  };

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <Layout
        onResponderGrant={Keyboard.dismiss}
        onStartShouldSetResponder={() => true}
        style={styles.root}
      >
        <Container>
          <SafeAreaView>
            <Header
              navigation={{
                icon: "close-outline",
                onPress: onClose,
              }}
              title="Select email"
              accessoryRight={() => null}
            />
          </SafeAreaView>
          <View style={styles.inputContainer}>
            <Input
              value={query}
              onChangeText={runSearch}
              clearButtonMode="while-editing"
              placeholder="Search..."
            />
          </View>
        </Container>
        <Divider />
        <FlatList
          data={!query ? emails : results}
          keyExtractor={(email) => email}
          renderItem={(input) => {
            const email = input.item;
            return (
              <ListItem
                key={email}
                title={email}
                accessoryRight={(props) =>
                  selected.includes(email) && (
                    <Icon
                      {...props}
                      name="checkmark-outline"
                      fill={theme["color-primary-default"]}
                    />
                  )
                }
                onPress={() => onSelect(email)}
              />
            );
          }}
          contentContainerStyle={[
            styles.listContent,
            { paddingBottom: insets.bottom },
          ]}
          keyboardShouldPersistTaps="handled"
        />
      </Layout>
    </FullScreenModal>
  );
};

export default CustomerEmailPickerModal;
