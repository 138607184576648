import React from "react";
import { useSelector } from "react-redux";

import SiteMapCircle from "../SiteMapCircle";
import selectSiteById from "../../store/sites/selectors/selectById";

interface SiteMapCircleProps {
  id: string;
}

const LiveSiteMapCircle = ({ id }: SiteMapCircleProps) => {
  const site = useSelector((state) => selectSiteById(state, id));
  if (!site) return null;

  return (
    <SiteMapCircle
      center={{
        latitude: site.coordinates.lat,
        longitude: site.coordinates.lng,
      }}
      radius={site.geofence}
    />
  );
};

export default LiveSiteMapCircle;
