import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setGroupPending } from "../../../store/groups/slice";

const dispatchOptimisticResponse = (group) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  const timestamp = new Date().toISOString();
  dispatch(
    setGroupPending({
      group: {
        ...group,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        isDeleted: true,
      },
      mutation: "adminDeleteCompanyGroup",
    })
  );
};

const adminDeleteCompanyGroup = (group) => {
  dispatchOptimisticResponse(group);
  client.mutate({
    mutation,
    variables: {
      id: group.id,
    },
    errorPolicy: "all",
  });
};

export default adminDeleteCompanyGroup;
