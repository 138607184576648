import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

const deleteTaskCompletionRecord = gql`
  mutation deleteTaskCompletionRecord($jobId: ID!, $taskId: ID!) {
    deleteTaskCompletionRecord(jobId: $jobId, taskId: $taskId) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export default deleteTaskCompletionRecord;
