import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setJobPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = ({ timestamp, coordinates }, job) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setJobPending({
      job: {
        ...job,
        startJobRecords: [
          ...job.startJobRecords.filter(({ record }) => record.by !== userId),
          { record: { at: timestamp, by: userId, coordinates } },
        ],
        syncDateTime: timestamp,
      },
      mutation: "createStartJobRecord",
    })
  );
};

const createStartJobRecord = (
  { coordinates, timestamp = new Date().toISOString() },
  job
) => {
  dispatchOptimisticResponse(
    {
      id: job.id,
      timestamp,
      coordinates,
    },
    job
  );
  client.mutate({
    mutation,
    variables: deepCopy({
      jobId: job.id,
      timestamp,
      coordinates,
    }),
    errorPolicy: "all",
  });
};

export default createStartJobRecord;
