import gql from "graphql-tag";

import {
  actionItemFragment,
  companyFragment,
  companyGroupFragment,
  companyUserFragment,
  customerFragment,
  inboxItemFragment,
  jobFragment,
  privateCompanySiteFragment,
} from "../../../../fragments";

export default gql`
  query syncCompany($lastSync: AWSDateTime, $after: ID) {
    syncCompany(lastSync: $lastSync, after: $after) {
      __typename
      items {
        ... on ActionItem {
          ...actionItemFragment
        }
        ... on Company {
          ...companyFragment
        }
        ... on CompanyGroup {
          ...companyGroupFragment
        }
        ... on CompanySite {
          ...privateCompanySiteFragment
        }
        ... on CompanyUser {
          ...companyUserFragment
        }
        ... on Customer {
          ...customerFragment
        }
        ... on InboxItem {
          ...inboxItemFragment
        }
        ... on Job {
          ...jobFragment
        }
      }
      nextToken
    }
  }
  ${actionItemFragment}
  ${companyFragment}
  ${companyGroupFragment}
  ${companyUserFragment}
  ${customerFragment}
  ${inboxItemFragment}
  ${jobFragment}
  ${privateCompanySiteFragment}
`;
