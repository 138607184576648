import gql from "graphql-tag";

import { incidentReportFragment } from "../../../fragments";

const adminDeleteIncidentReport = gql`
  mutation adminDeleteIncidentReport($id: ID!, $jobId: ID!) {
    adminDeleteIncidentReport(id: $id, jobId: $jobId) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default adminDeleteIncidentReport;
