import gql from "graphql-tag";

import { stripeExpandedInvoiceFragment } from "../../../fragments";

const retrieveInvoice = gql`
  query retrieveInvoice($id: ID!) {
    retrieveInvoice(id: $id) {
      ...stripeExpandedInvoiceFragment
    }
  }
  ${stripeExpandedInvoiceFragment}
`;

export default retrieveInvoice;
