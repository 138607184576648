import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripePrice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

export type ListPricesInput = Pick<
  Stripe.PriceListParams,
  "active" | "starting_after"
>;

const listPrices = async (input?: ListPricesInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        active: input?.active,
        starting_after: input?.starting_after,
      },
    })) as GraphQLResult<{
      listPrices: {
        data: Array<StripePrice>;
        has_more: boolean;
      };
    }>;
    return data.listPrices;
  } catch ({ errors }) {
    throw generateErrorMessage("listPrices", errors);
  }
};

export default listPrices;
