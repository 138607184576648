import { useSelector } from "react-redux";

import selectAppearance from "../../store/settings/selectors/selectAppearance";
import getColorScheme from "../../utils/getColorScheme";

const useColorScheme = () => {
  const appearance = useSelector(selectAppearance);
  return getColorScheme(appearance);
};

export default useColorScheme;
