import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = (
  { completed, timestamp },
  dailyActivityReport
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setReportPending({
      mutation: "submitDailyActivityReport",
      report: {
        ...dailyActivityReport,
        syncDateTime: timestamp,
        submitted: {
          at: timestamp,
          by: userId,
        },
        completed,
      },
    })
  );
};

const submitDailyActivityReport = (
  { completed, timestamp = new Date().toISOString() },
  dailyActivityReport
) => {
  dispatchOptimisticResponse(
    {
      completed,
      timestamp,
    },
    dailyActivityReport
  );
  client.mutate(
    deepCopy({
      mutation,
      variables: {
        id: dailyActivityReport.id,
        timestamp,
        jobId: dailyActivityReport.jobId,
        completed,
      },
      errorPolicy: "all",
    })
  );
};

export default submitDailyActivityReport;
