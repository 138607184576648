import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TutorialStatuses, TutorialTypes } from "../../../types";
import { resetUser } from "../../auth/slice";

const name = "tutorials";

export interface TutorialState {
  quickStartAdmin: TutorialStatuses;
  quickStartEmployee: TutorialStatuses;
}

const initialState: TutorialState = {
  quickStartAdmin: "notCompleted",
  quickStartEmployee: "notCompleted",
};

const usersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTutorialStatus: (
      state: TutorialState,
      action: PayloadAction<{
        tutorial: TutorialTypes;
        status: TutorialStatuses;
      }>
    ) => {
      const { tutorial, status } = action.payload;
      state[tutorial] = status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUser.fulfilled, () => initialState);
  },
});

const { actions, reducer } = usersSlice;

export const { setTutorialStatus } = actions;

export default reducer;
