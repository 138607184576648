const theme = {
  "color-danger-100": "#FEDDCB",
  "color-danger-200": "#FDB298",
  "color-danger-300": "#FA7E64",
  "color-danger-400": "#F64E3D",
  "color-danger-500": "#F00100",
  "color-danger-600": "#CE0010",
  "color-danger-700": "#AC001D",
  "color-danger-800": "#8B0023",
  "color-danger-900": "#730027",
  "color-danger-transparent-100": "rgba(240, 1, 0, 0.08)",
  "color-danger-transparent-200": "rgba(240, 1, 0, 0.16)",
  "color-danger-transparent-300": "rgba(240, 1, 0, 0.24)",
  "color-danger-transparent-400": "rgba(240, 1, 0, 0.32)",
  "color-danger-transparent-500": "rgba(240, 1, 0, 0.4)",
  "color-danger-transparent-600": "rgba(240, 1, 0, 0.48)",
  "color-info-100": "#EFCDFE",
  "color-info-200": "#DA9CFE",
  "color-info-300": "#C16AFD",
  "color-info-400": "#A845FC",
  "color-info-500": "#7F08FB",
  "color-info-600": "#6205D7",
  "color-info-700": "#4904B4",
  "color-info-800": "#330291",
  "color-info-900": "#240178",
  "color-info-transparent-100": "rgba(127, 8, 251, 0.08)",
  "color-info-transparent-200": "rgba(127, 8, 251, 0.16)",
  "color-info-transparent-300": "rgba(127, 8, 251, 0.24)",
  "color-info-transparent-400": "rgba(127, 8, 251, 0.32)",
  "color-info-transparent-500": "rgba(127, 8, 251, 0.4)",
  "color-info-transparent-600": "rgba(127, 8, 251, 0.48)",
  "color-primary-100": "#CDECFD",
  "color-primary-200": "#9DD5FC",
  "color-primary-300": "#6AB7F6",
  "color-primary-400": "#4599EC",
  "color-primary-500": "#0D6EE1",
  "color-primary-600": "#0955C1",
  "color-primary-700": "#063FA2",
  "color-primary-800": "#042C82",
  "color-primary-900": "#021F6C",
  "color-primary-transparent-100": "rgba(13, 110, 225, 0.08)",
  "color-primary-transparent-200": "rgba(13, 110, 225, 0.16)",
  "color-primary-transparent-300": "rgba(13, 110, 225, 0.24)",
  "color-primary-transparent-400": "rgba(13, 110, 225, 0.32)",
  "color-primary-transparent-500": "rgba(13, 110, 225, 0.4)",
  "color-primary-transparent-600": "rgba(13, 110, 225, 0.48)",
  "color-success-100": "#EDFCD0",
  "color-success-200": "#D7F9A3",
  "color-success-300": "#B7EF73",
  "color-success-400": "#96E04F",
  "color-success-500": "#68CC1C",
  "color-success-600": "#4EAF14",
  "color-success-700": "#38920E",
  "color-success-800": "#257608",
  "color-success-900": "#186105",
  "color-success-transparent-100": "rgba(104, 204, 28, 0.08)",
  "color-success-transparent-200": "rgba(104, 204, 28, 0.16)",
  "color-success-transparent-300": "rgba(104, 204, 28, 0.24)",
  "color-success-transparent-400": "rgba(104, 204, 28, 0.32)",
  "color-success-transparent-500": "rgba(104, 204, 28, 0.4)",
  "color-success-transparent-600": "rgba(104, 204, 28, 0.48)",
  "color-warning-100": "#FEF5D8",
  "color-warning-200": "#FDE9B1",
  "color-warning-300": "#FBD989",
  "color-warning-400": "#F7C86B",
  "color-warning-500": "#F3AE3C",
  "color-warning-600": "#D08C2B",
  "color-warning-700": "#AE6D1E",
  "color-warning-800": "#8C5013",
  "color-warning-900": "#743C0B",
  "color-warning-transparent-100": "rgba(243, 174, 60, 0.08)",
  "color-warning-transparent-200": "rgba(243, 174, 60, 0.16)",
  "color-warning-transparent-300": "rgba(243, 174, 60, 0.24)",
  "color-warning-transparent-400": "rgba(243, 174, 60, 0.32)",
  "color-warning-transparent-500": "rgba(243, 174, 60, 0.4)",
  "color-warning-transparent-600": "rgba(243, 174, 60, 0.48)",
};

export default theme;
