import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import selectUsers from "../../store/users/selectors/selectUsers";
import { CompanyUser, CompanyUserRole, EntityState } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import userRolePermitted from "../../utils/userRolePermitted";

const userPassesArchivedFilter = ({
  includeArchived,
  user,
}: {
  includeArchived: boolean;
  user: CompanyUser;
}) => includeArchived || !user.isArchived;

const userPassesPermittedRolesFilter = ({
  permittedRoles,
  user,
}: {
  permittedRoles?: Array<CompanyUserRole>;
  user: CompanyUser;
}) => {
  return (
    !permittedRoles ||
    userRolePermitted({
      permittedRoles,
      userRole: user.role,
    })
  );
};

const filterUserIds = ({
  includeArchived,
  permittedRoles,
  searchText,
  users,
}: {
  includeArchived: boolean;
  permittedRoles?: Array<CompanyUserRole>;
  searchText: string;
  users: EntityState<CompanyUser>;
}) => {
  const filteredUserIds = searchText
    ? users.ids.filter((userId) => {
        const user = users.entities[userId];
        return (
          userPassesArchivedFilter({ includeArchived, user }) &&
          userPassesPermittedRolesFilter({
            permittedRoles,
            user,
          }) &&
          getUserFullName(user)
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        );
      })
    : users.ids.filter((userId) => {
        const user = users.entities[userId];
        return (
          userPassesArchivedFilter({
            includeArchived,
            user,
          }) &&
          userPassesPermittedRolesFilter({
            permittedRoles,
            user,
          })
        );
      });
  return filteredUserIds.sort((firstUserId, secondUserId) => {
    const firstUser = users.entities[firstUserId];
    const secondUser = users.entities[secondUserId];
    return getUserFullName(firstUser).localeCompare(
      getUserFullName(secondUser)
    );
  });
};

const useUserList = (params?: {
  includeArchived?: boolean;
  permittedRoles?: Array<CompanyUserRole>;
}) => {
  const [searchText, setSearchText] = useState("");
  const users = useSelector(selectUsers);
  const includeArchived = Boolean(params?.includeArchived);
  const filteredUserIds = useMemo(
    () =>
      filterUserIds({
        includeArchived,
        permittedRoles: params?.permittedRoles,
        searchText,
        users,
      }),
    [includeArchived, searchText, users]
  );
  return { userIds: filteredUserIds, searchText, setSearchText };
};

export default useUserList;
