import { useNavigation } from "@react-navigation/native";
import { Divider, Icon, Layout, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FlatList, Keyboard, ScrollView, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";
import Button from "../../components/buildingBlocks/Button";

import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import HeaderContainer from "../../components/HeaderContainer";
import ListItem from "../../components/listItems/Base";
import ReminderListItem from "../../components/listItems/Reminder";
import EditReminderModal from "../../components/modals/EditReminder";
import { Reminder } from "../../types";

const data: Array<Reminder> = [
  {
    customerId: "Jun-Min Lee",
    dateTime: dayjs().startOf("month").toISOString(),
    id: "1",
    subject: "Send Letter",
  },
  {
    customerId: "Jun-Min Lee",
    dateTime: new Date().toISOString(),
    id: "2",
    subject: "Send Quote",
  },
  {
    customerId: "Jun-Min Lee",
    dateTime: dayjs().endOf("month").toISOString(),
    id: "3",
    subject: "Schedule Follow-Up Meeting",
  },
];

const FlatListContainer = styled.View`
  flex: 1;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const Reminders = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const theme = useTheme();

  const [editReminderModalVisible, setEditReminderModalVisible] = useState(
    false
  );

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <Header
            navigation={{ icon: "arrow-back", onPress: navigation.goBack }}
            subtitle={"3 items \u2022 Updated 2 minutes ago"}
            title="Reminders"
          />
        </HeaderContainer>
        <Divider />
        <FlatListContainer>
          <FlatList
            contentInsetAdjustmentBehavior="automatic"
            data={data}
            ItemSeparatorComponent={() => <Divider />}
            keyExtractor={(item) => item.id}
            ListHeaderComponent={
              <>
                <Separator size="small" />
                <ScrollView
                  contentContainerStyle={styles.scrollViewContentContainerStyle}
                  horizontal
                >
                  <StyledButton appearance="filled" status="basic">
                    All reminders
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton appearance="outline" status="basic">
                    Overdue reminders
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton appearance="outline" status="basic">
                    Today's reminders
                  </StyledButton>
                </ScrollView>
                <ListItem
                  accessoryLeft={(imageProps) => (
                    <Icon {...imageProps} name="person" />
                  )}
                  style={{ marginEnd: 16, marginStart: 16 }}
                  title="Filter by customer"
                />
                <Separator size="medium" />
              </>
            }
            renderItem={({ item: reminder }) => (
              <Layout>
                <ReminderListItem
                  onPress={() =>
                    navigation.navigate("ReminderDetails", {
                      reminder,
                    })
                  }
                  reminder={reminder}
                />
              </Layout>
            )}
            scrollIndicatorInsets={{ right: 1 }}
          />
        </FlatListContainer>
        <FAB
          icon="plus"
          onPress={() => {
            Keyboard.dismiss();
            setEditReminderModalVisible(true);
          }}
          style={{
            backgroundColor: theme["color-primary-default"],
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: insets.bottom,
          }}
        />
      </Root>
      <EditReminderModal
        isVisible={editReminderModalVisible}
        onClose={() => setEditReminderModalVisible(false)}
      />
    </>
  );
};

export default Reminders;
