import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { MediaUrl } from "../../../types";

interface GetMediaResponse {
  getMedia: MediaUrl;
}

const getMedia = async (id: string) => {
  try {
    const { data } = await client.query<GetMediaResponse>({
      query,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    return data.getMedia;
  } catch (error) {
    throw generateErrorMessage("getMedia", error);
  }
};

export default getMedia;
