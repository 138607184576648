const repeatByWeekday = {
  monday: {
    text: "Monday",
    value: "monday",
    rruleValue: "MO",
    momentValue: 1,
  },
  tuesday: {
    text: "Tuesday",
    value: "tuesday",
    rruleValue: "TU",
    momentValue: 2,
  },
  wednesday: {
    text: "Wednesday",
    value: "wednesday",
    rruleValue: "WE",
    momentValue: 3,
  },
  thursday: {
    text: "Thursday",
    value: "thursday",
    rruleValue: "TH",
    momentValue: 4,
  },
  friday: {
    text: "Friday",
    value: "friday",
    rruleValue: "FR",
    momentValue: 5,
  },
  saturday: {
    text: "Saturday",
    value: "saturday",
    rruleValue: "SA",
    momentValue: 6,
  },
  sunday: {
    text: "Sunday",
    value: "sunday",
    rruleValue: "SU",
    momentValue: 0,
  },
};
export default repeatByWeekday;
