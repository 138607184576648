import { FormikProps } from "formik";
import _ from "lodash";
import { useState } from "react";

import { Props as AddMediaButtonsProps } from "../../components/AddMediaButtons";
import { Props as MediaListItemProps } from "../../components/listItems/Media";
import { AlertModalProps } from "../../components/modals/Alert";
import { Props as MediaViewerModalProps } from "../../components/modals/MediaViewer";
import { Media, MediaOnDevice } from "../../types";

interface FormValues {
  media: Array<Media | MediaOnDevice>;
}

interface FormikMediaHelpers {
  AddMediaButtonsProps: AddMediaButtonsProps;
  DeleteMediaAlertModalProps: AlertModalProps;
  EditMediaDescriptionModalProps: {
    headerTitle: string;
    isVisible: boolean;
    maxLength: number;
    onClose: () => void;
    onSubmit: (text: string) => void;
    text?: string;
  };
  MediaListItemProps: Omit<MediaListItemProps, "media">;
  MediaViewerModalProps: MediaViewerModalProps & { editable: true };
}

const useFormikMediaHelpers = (
  formik: FormikProps<FormValues>
): FormikMediaHelpers => {
  const [deleteMediaConfirmation, setDeleteMediaConfirmation] = useState({
    mediaId: null,
    visible: false,
  });
  const [mediaDescriptionEditor, setMediaDescriptionEditor] = useState({
    mediaId: null,
    visible: false,
  });
  const [mediaViewer, setMediaViewer] = useState({
    mediaId: null,
    visible: false,
  });

  const getMediaIndex = (mediaId?: string) =>
    _.findIndex(formik.values.media, (media) => media.id === mediaId);
  const getMedia = (mediaId?: string) =>
    formik.values.media && formik.values.media[getMediaIndex(mediaId)];
  const openMediaDescriptionEditor = (mediaId: string) =>
    setMediaDescriptionEditor({
      mediaId,
      visible: true,
    });
  const attemptDeleteMedia = (mediaId: string) => {
    setDeleteMediaConfirmation({
      mediaId,
      visible: true,
    });
  };
  return {
    AddMediaButtonsProps: {
      onAddMedia: (media: MediaOnDevice) =>
        formik.setFieldValue(`media[${formik.values.media.length}]`, media),
    },
    DeleteMediaAlertModalProps: {
      cancelText: "Cancel",
      confirmText: "Delete media",
      isVisible: deleteMediaConfirmation.visible,
      message: "Are you sure you want to delete this media?",
      onCancel: () =>
        setDeleteMediaConfirmation((prevState) => ({
          ...prevState,
          visible: false,
        })),
      onClose: () =>
        setDeleteMediaConfirmation((prevState) => ({
          ...prevState,
          visible: false,
        })),
      onConfirm: () => {
        formik.setFieldValue(
          "media",
          formik.values.media.filter(
            (media) => media.id !== deleteMediaConfirmation.mediaId
          )
        );
        setDeleteMediaConfirmation((prevState) => ({
          ...prevState,
          visible: false,
        }));
        setMediaViewer((prevState) => ({ ...prevState, visible: false }));
      },
    },
    EditMediaDescriptionModalProps: {
      headerTitle: `${
        getMedia(mediaDescriptionEditor.mediaId)?.description ? "Edit" : "Add"
      } description`,
      isVisible: mediaDescriptionEditor.visible,
      maxLength: 280,
      onClose: () =>
        setMediaDescriptionEditor((prevState) => ({
          ...prevState,
          visible: false,
        })),
      onSubmit: (text) => {
        formik.setFieldValue(
          `media[${getMediaIndex(mediaDescriptionEditor.mediaId)}].description`,
          text
        );
        setMediaDescriptionEditor((prevState) => ({
          ...prevState,
          visible: false,
        }));
      },
      text: getMedia(mediaDescriptionEditor.mediaId)?.description,
    },
    MediaListItemProps: {
      editable: true,
      onDeletePress: attemptDeleteMedia,
      onDescriptionPress: openMediaDescriptionEditor,
      onPress: (mediaId) =>
        setMediaViewer({
          mediaId,
          visible: true,
        }),
      onUpload: (mediaId: string) => {
        const index = getMediaIndex(mediaId);
        formik.setFieldValue(`media[${index}].uploaded`, true);
      },
    },
    MediaViewerModalProps: {
      editable: true,
      isVisible: mediaViewer.visible,
      media: getMedia(mediaViewer.mediaId),
      onClose: () =>
        setMediaViewer((prevState) => ({ ...prevState, visible: false })),
      onDeletePress: attemptDeleteMedia,
      onDescriptionPress: openMediaDescriptionEditor,
    },
  };
};

export default useFormikMediaHelpers;
