import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setSitePending } from "../../../store/sites/slice";

const dispatchOptimisticResponse = (site) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const timestamp = new Date().toISOString();
  dispatch(
    setSitePending({
      mutation: "adminArchiveCompanySite",
      site: {
        ...site,
        syncDateTime: timestamp,
        isArchived: true,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
    })
  );
};

const adminArchiveCompanySite = (site) => {
  dispatchOptimisticResponse(site);
  client.mutate({
    mutation,
    variables: {
      id: site.id,
    },
    errorPolicy: "all",
  });
};

export default adminArchiveCompanySite;
