import gql from "graphql-tag";

import { stripeTaxRateFragment } from "../../../fragments";

const createTaxRate = gql`
  mutation createTaxRate(
    $display_name: String!
    $inclusive: Boolean!
    $percentage: Float!
  ) {
    createTaxRate(
      display_name: $display_name
      inclusive: $inclusive
      percentage: $percentage
    ) {
      ...stripeTaxRateFragment
    }
  }
  ${stripeTaxRateFragment}
`;

export default createTaxRate;
