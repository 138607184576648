import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

const completeJob = gql`
  mutation completeJob(
    $id: ID!
    $timestamp: AWSDateTime!
    $coordinates: CoordinatesInput!
    $nextId: ID
  ) {
    completeJob(
      id: $id
      timestamp: $timestamp
      coordinates: $coordinates
      nextId: $nextId
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export default completeJob;
