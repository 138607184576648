import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { memo } from "react";
import { StyleSheet, Pressable } from "react-native";
import { useSelector } from "react-redux";

import selectJobById from "../../../store/jobs/selectors/selectJobById";
import Marker from "./Marker";

dayjs.extend(duration);

const styles = StyleSheet.create({
  clickable: {
    alignItems: "center",
    cursor: "pointer",
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
});

interface JobMarkerProps {
  id: string;
  onPress: (id: string) => void;
}

const JobMarker = ({ id, onPress }: JobMarkerProps) => {
  const job = useSelector((state) => selectJobById(state, id));
  if (!job) {
    return null;
  }

  const {
    status: { priority },
  } = job;

  const handlePress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress(id);
  };

  return (
    <Pressable
      onPress={handlePress}
      style={[styles.clickable, { zIndex: priority }]}
    >
      <Marker jobId={id} />
    </Pressable>
  );
};

export default memo(JobMarker);
