import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { initSession, resetUser } from "../../auth/slice";
import { Mode } from "../../../components/TimeRangePicker";
import { DateTimeRange } from "../../../types";

export type State = {
  customDateTimeRange: Partial<DateTimeRange>;
  filters: {
    customerIds: Array<string>;
    groupIds: Array<string>;
    siteIds: Array<string>;
    userIds: Array<string>;
  };
  mode: Mode;
  queryDateTimeRange?: DateTimeRange;
  selectedDate: string;
};

const getInitialState = (): State => ({
  customDateTimeRange: {},
  filters: {
    customerIds: [],
    groupIds: [],
    siteIds: [],
    userIds: [],
  },
  mode: "week",
  selectedDate: new Date().toISOString(),
});

const slice = createSlice({
  initialState: getInitialState(),
  name: "historicalSearch",
  reducers: {
    setAssigneesFilter: (
      state,
      action: PayloadAction<{ groupIds: Array<string>; userIds: Array<string> }>
    ) => {
      const { groupIds, userIds } = action.payload;
      state.filters.groupIds = groupIds;
      state.filters.userIds = userIds;
    },
    setCustomersFilter: (
      state,
      action: PayloadAction<{ customerIds: Array<string> }>
    ) => {
      const { customerIds } = action.payload;
      state.filters.customerIds = customerIds;
    },
    setSitesFilter: (
      state,
      action: PayloadAction<{ siteIds: Array<string> }>
    ) => {
      const { siteIds } = action.payload;
      state.filters.siteIds = siteIds;
    },
    setSelectedDate: (
      state,
      action: PayloadAction<{ selectedDate: string }>
    ) => {
      const { selectedDate } = action.payload;
      state.selectedDate = selectedDate;
    },
    setCustomDateTimeRange: (
      state,
      action: PayloadAction<{ customDateTimeRange: Partial<DateTimeRange> }>
    ) => {
      const { customDateTimeRange } = action.payload;
      state.customDateTimeRange = customDateTimeRange;
    },
    setQueryDateTimeRange: (
      state,
      action: PayloadAction<{ queryDateTimeRange?: DateTimeRange }>
    ) => {
      const { queryDateTimeRange } = action.payload;
      state.queryDateTimeRange = queryDateTimeRange;
    },
    setMode: (state, action: PayloadAction<{ mode: Mode }>) => {
      const { mode } = action.payload;
      state.mode = mode;
    },
    resetFilters: (state) => {
      state.filters = {
        customerIds: [],
        groupIds: [],
        siteIds: [],
        userIds: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initSession.fulfilled, (state) => {
      state.filters = {
        customerIds: [],
        groupIds: [],
        siteIds: [],
        userIds: [],
      };
    });
    builder.addCase(resetUser.fulfilled, () => getInitialState());
    builder.addCase(PURGE, () => getInitialState());
  },
});

const { actions, reducer } = slice;

export const {
  resetFilters,
  setAssigneesFilter,
  setCustomDateTimeRange,
  setCustomersFilter,
  setMode,
  setQueryDateTimeRange,
  setSelectedDate,
  setSitesFilter,
} = actions;

export default reducer;
