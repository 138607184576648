import { Layout, Text } from "@ui-kitten/components";
import React, { ReactNode, useState } from "react";
import { SafeAreaView, StyleSheet } from "react-native";

import Separator from "../buildingBlocks/Separator";
import Container from "../Container";

interface AppDisablerProps {
  children?: ReactNode;
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

export const AppDisablerContext = React.createContext({
  disableApp: () => {},
});

const AppDisabler = ({ children }: AppDisablerProps) => {
  const [appDisabled, setAppDisabled] = useState(false);

  const disableApp = () => setAppDisabled(true);

  return (
    <AppDisablerContext.Provider value={{ disableApp }}>
      {!appDisabled ? (
        children
      ) : (
        <Layout style={styles.root}>
          <SafeAreaView style={styles.root}>
            <Container>
              <Separator />
              <Text category="h5" style={styles.text}>
                Mock location data detected
              </Text>
              <Text style={styles.text}>
                Disable mock location and restart this application
              </Text>
            </Container>
          </SafeAreaView>
        </Layout>
      )}
    </AppDisablerContext.Provider>
  );
};

export default AppDisabler;
