import { Text, TextProps } from "@ui-kitten/components";
import moment from "moment-timezone";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import { rrulestr } from "rrule";

import momentFormats from "../../constants/momentFormats";
import rruleFreqToRepeatFrequency from "../../constants/rruleFreqToRepeatFrequency";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectTimeFormat from "../../store/settings/selectors/selectTimeFormat";
import convertUtcRRuleToLocalWeekdays from "../../utils/convertUtcRRuleToLocal";
import getLocalByWeekdayRRuleDescription from "../../utils/getLocalByWeekdayRRuleDescription";

interface Props {
  endDateTime: string;
  rrule?: string;
  startDateTime: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
}

const TimeRange = ({
  appearance = "default",
  category = "p1",
  endDateTime,
  startDateTime,
  style,
  textStyle,
  rrule,
}: Props & Pick<TextProps, "appearance" | "category">) => {
  const timeFormat = useSelector(selectTimeFormat);
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const startMoment = moment(startDateTime);
  const endMoment = moment(endDateTime);
  let firstLine: string;
  let secondLine: string;

  if (!endDateTime) {
    firstLine = moment(startDateTime).format(dateTimeFormat);
  } else if (startMoment.isSame(endMoment, "day")) {
    firstLine = startMoment.format(momentFormats.dateFormat);
    secondLine = `from ${startMoment.format(timeFormat)} to ${endMoment.format(
      timeFormat
    )}`;
  } else {
    firstLine = `from ${startMoment.format(dateTimeFormat)}`;
    secondLine = `to ${endMoment.format(dateTimeFormat)}`;
  }
  return (
    <View style={style}>
      <Text
        appearance={appearance}
        category={category}
        ellipsizeMode="tail"
        numberOfLines={1}
        style={textStyle}
      >
        {firstLine}
      </Text>
      {endDateTime && (
        <Text
          appearance={appearance}
          category={category}
          style={textStyle}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {secondLine}
        </Text>
      )}
      {/*{rrule && (*/}
      {/*  <Text*/}
      {/*    appearance={appearance}*/}
      {/*    category={category}*/}
      {/*    style={textStyle}*/}
      {/*    numberOfLines={1}*/}
      {/*    ellipsizeMode="tail"*/}
      {/*  >*/}
      {/*    {`repeats ${getLocalByWeekdayRRuleDescription({*/}
      {/*      startDateTime,*/}
      {/*      byWeekday: convertUtcRRuleToLocalWeekdays({*/}
      {/*        utcRRule: rrule,*/}
      {/*      }),*/}
      {/*      frequency:*/}
      {/*        rruleFreqToRepeatFrequency[rrulestr(rrule).origOptions.freq],*/}
      {/*    })}`}*/}
      {/*  </Text>*/}
      {/*)}*/}
    </View>
  );
};

TimeRange.defaultProps = {
  rrule: undefined,
  style: undefined,
  textStyle: undefined,
};

export default TimeRange;
