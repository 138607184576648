import React from "react";
import { useSelector } from "react-redux";

import UserMapCircle from "../UserMapCircle";
import selectUserById from "../../store/users/selectors/selectUserById";

interface LiveUserMapCircleProps {
  id: string;
}

const LiveUserMapCircle = ({ id }: LiveUserMapCircleProps) => {
  const user = useSelector((state) => selectUserById(state, id));

  if (!user || !user.coordinates.accuracy) return null;

  return (
    <UserMapCircle
      center={{
        latitude: user.coordinates.lat,
        longitude: user.coordinates.lng,
      }}
      radius={user.coordinates.accuracy}
    />
  );
};

export default LiveUserMapCircle;
