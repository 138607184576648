import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import selectActionItems from "../../store/actionItems/selectors/selectActionItems";
import { ActionItem, EntityState } from "../../types";

const actionItemPassesDeletedFilter = (actionItem: ActionItem) =>
  !actionItem.isDeleted;

const actionItemPassesCustomFilter = ({
  actionItem,
  filter,
}: {
  actionItem: ActionItem;
  filter?: (actionItem: ActionItem) => boolean;
}) => !filter || filter(actionItem);

const actionItemPassesSearchTextFilter = ({
  actionItem,
  searchText,
}: {
  actionItem: ActionItem;
  searchText: string;
}) =>
  !searchText ||
  actionItem.description
    .toLowerCase()
    .includes(searchText.trim().toLowerCase());

const filterActionItemIds = ({
  actionItems,
  filter,
  searchText,
}: {
  actionItems: EntityState<ActionItem>;
  filter?: (actionItem: ActionItem) => boolean;
  searchText: string;
}) => {
  return actionItems.ids
    .filter((actionItemId) => {
      const actionItem = actionItems.entities[actionItemId];
      return (
        actionItemPassesDeletedFilter(actionItem) &&
        actionItemPassesCustomFilter({ actionItem, filter }) &&
        actionItemPassesSearchTextFilter({ actionItem, searchText })
      );
    })
    .sort((firstActionItemId, secondActionItemId) => {
      const firstActionItem = actionItems.entities[firstActionItemId];
      const secondActionItem = actionItems.entities[secondActionItemId];
      return firstActionItem.description.localeCompare(
        secondActionItem.description
      );
    });
};

const useActionItemList = (params?: {
  filter: (actionItem: ActionItem) => boolean;
}) => {
  const [searchText, setSearchText] = useState("");
  const actionItems = useSelector(selectActionItems);
  const filteredActionItemIds = useMemo(
    () =>
      filterActionItemIds({
        actionItems,
        filter: params?.filter,
        searchText,
      }),
    [actionItems, params, searchText]
  );
  return {
    actionItems: {
      ids: filteredActionItemIds,
      entities: actionItems.entities,
    },
    searchText,
    setSearchText,
  };
};

export default useActionItemList;
