import { createSelector } from "@reduxjs/toolkit";

import selectUsers from "../selectUsers";

const selectUserEntities = createSelector(
  [selectUsers],
  (users) => users.entities
);

export default selectUserEntities;
