import gql from "graphql-tag";

import { inboxItemFragment } from "../../../fragments";

export default gql`
  mutation updateInboxItem(
    $description: String
    $id: ID!
    $metadata: InboxItemMetadataInput!
    $title: String!
  ) {
    updateInboxItem(
      description: $description
      id: $id
      metadata: $metadata
      title: $title
    ) {
      ...inboxItemFragment
    }
  }
  ${inboxItemFragment}
`;
