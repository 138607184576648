import { createSelector, EntityState } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

import selectJobs from "../selectJobs";
import selectUser from "../../../auth/selectors/selectUser";
import selectGroups from "../../../groups/selectors/selectGroups";
import selectQueryDateTimeRange from "../../../historicalSearch/selectors/selectQueryDateTimeRange";
import selectSignedInUserRole from "../../../users/selectors/selectSignedInUserRole";
import getUserGroups from "../../../../utils/getUserGroups";
import {
  CompanyGroup,
  CompanySite,
  CompanyUserRole,
  DateTimeRange,
  FormattedJob,
  Job,
} from "../../../../types";
import selectFilters from "../../../historicalSearch/selectors/selectFilters";
import userRolePermitted from "../../../../utils/userRolePermitted";
import selectSites from "../../../sites/selectors/selectSites";

interface SignedInUser {
  id: string;
  role?: CompanyUserRole;
}

const jobPassesAssigneesFilter = ({
  assigneesFilter,
  groups,
  job,
  signedInUser,
}: {
  assigneesFilter: {
    groupIds: Array<string>;
    userIds: Array<string>;
  };
  groups: EntityState<CompanyGroup>;
  job: Job;
  signedInUser: SignedInUser;
}) => {
  if (
    userRolePermitted({
      permittedRoles: ["Supervisor", "Admin"],
      userRole: signedInUser.role,
    })
  ) {
    const { groupIds, userIds } = assigneesFilter;
    if (groupIds.length > 0 || userIds.length > 0) {
      return job.assignedTo.some(
        (assignee) =>
          groupIds.includes(assignee.id) || userIds.includes(assignee.id)
      );
    }
    return true;
  }
  const userGroups = getUserGroups({
    groups,
    userId: signedInUser.id,
  });
  return job.assignedTo.some(
    (assignee) =>
      assignee.id === signedInUser.id || userGroups.ids.includes(assignee.id)
  );
};

const jobPassesCustomersFilter = ({
  customerIds,
  job,
  sites,
}: {
  customerIds: Array<string>;
  job: Job;
  sites: EntityState<CompanySite>;
}) =>
  customerIds.length === 0 ||
  customerIds.includes(sites.entities[job.siteId].customerId);

const jobPassesSiteFilter = ({
  job,
  siteIds,
}: {
  job: Job;
  siteIds: Array<string>;
}) => siteIds.length === 0 || siteIds.includes(job.siteId);

const jobPassesFilters = ({
  filters,
  groups,
  job,
  queryDateTimeRange,
  signedInUser,
  sites,
}: {
  filters: {
    customerIds: Array<string>;
    groupIds: Array<string>;
    siteIds: Array<string>;
    userIds: Array<string>;
  };
  groups: EntityState<CompanyGroup>;
  job: Job;
  queryDateTimeRange: DateTimeRange;
  signedInUser: SignedInUser;
  sites: EntityState<CompanySite>;
}) => {
  if (
    job.isDeleted ||
    job.finished?.status === "dismissed" ||
    job.startDateTime < queryDateTimeRange.startDateTime ||
    job.startDateTime >= queryDateTimeRange.endDateTime
  ) {
    return false;
  }
  return (
    jobPassesCustomersFilter({
      customerIds: filters.customerIds,
      job,
      sites,
    }) &&
    jobPassesAssigneesFilter({
      assigneesFilter: filters,
      groups,
      job,
      signedInUser,
    }) &&
    jobPassesSiteFilter({ job, siteIds: filters.siteIds })
  );
};

const selectScheduleJobs = createSelector(
  [
    selectJobs,
    selectQueryDateTimeRange,
    selectFilters,
    selectUser,
    selectSignedInUserRole,
    selectGroups,
    selectSites,
  ],
  (
    jobs: EntityState<FormattedJob>,
    queryDateTimeRange,
    filters,
    signedInUser,
    signedInUserRole,
    groups,
    sites
  ) => {
    if (queryDateTimeRange) {
      return orderBy(
        jobs.ids
          .filter((jobId) => {
            const job = jobs.entities[jobId];
            return jobPassesFilters({
              filters,
              groups,
              job,
              queryDateTimeRange,
              signedInUser: {
                id: signedInUser.id,
                role: signedInUserRole,
              },
              sites,
            });
          })
          .map((jobId) => jobs.entities[jobId]),
        ["startDateTime"],
        ["asc"]
      );
    }
    return [];
  }
);

export default selectScheduleJobs;
