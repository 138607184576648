import { CompanyUser, Coordinates } from "../../../types";
import client from "../../client";
import mutation from "./graphql";

interface UpdateCompanyUserStatusInput {
  speed?: number;
  heading?: number;
  createdAt: string;
  coordinates: Coordinates;
}

const updateCompanyUserStatus = async ({
  createdAt,
  coordinates,
  heading,
  speed,
}: UpdateCompanyUserStatusInput): Promise<CompanyUser> => {
  const { data } = await client.mutate<
    { updateCompanyUserStatus: CompanyUser },
    UpdateCompanyUserStatusInput
  >({
    mutation,
    variables: {
      createdAt,
      coordinates,
      heading,
      speed,
    },
  });
  return data.updateCompanyUserStatus;
};

export default updateCompanyUserStatus;
