import { Divider, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import {
  TextInput,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import IconButton from "../../IconButton";
import TaskListItem from "../../listItems/Task/TaskListItem";
import companyUserRoles from "../../../constants/companyUserRoles";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import selectSiteById from "../../../store/sites/selectors/selectById";

interface Props {
  backIconName?: string;
  initialText?: string;
  jobId?: string;
  maxLength?: number;
  onBackPress: () => void;
  onSubmitPress: (text: string) => void;
  title?: string;
  visible: boolean;
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  detailsHeaderContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  detailsTextContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  footerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  icon: {
    height: 18,
    width: 18,
  },
  input: {
    flex: 1,
    paddingBottom: 16,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 16,
    textAlignVertical: "top",
  },
  inputContainer: {
    flex: 1,
  },
  tinySeparator: {
    height: 4,
  },
});

const JobTextInputModalScreen = ({
  backIconName,
  initialText,
  jobId,
  maxLength,
  onBackPress,
  onSubmitPress,
  title,
  visible,
}: Props) => {
  const { group } = useSelector(selectUser);
  const job = useSelector((state) => selectJobById(state, jobId));
  const site = useSelector((state) => selectSiteById(state, job && job.siteId));
  const theme = useTheme();
  const [detailsMaxHeight, setDetailsMaxHeight] = useState(null);
  const [text, setText] = useState("");
  const textInputRef = useRef(null);
  const [showDetails, setShowDetails] = useState(true);
  useAndroidBackHandler({ enabled: visible, onBackPress });
  useEffect(() => {
    if (visible) {
      setText(initialText || "");
      setShowDetails(true);
      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <HeaderWithTextAction
        action={{
          "data-test": "submitButtonInJobTextInputModalScreen",
          loading: false,
          onPress: () => onSubmitPress(text),
          text: "Done",
        }}
        navigation={{
          icon: backIconName,
          onPress: onBackPress,
        }}
        title={title}
      />
      <Divider />
      <View
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => setDetailsMaxHeight(height / 2)}
        style={styles.content}
      >
        <Layout level="2" style={styles.inputContainer}>
          <ScrollView>
            <TextInput
              data-test="textInputInJobTextInputModalScreen"
              maxLength={maxLength}
              multiline
              onChangeText={setText}
              ref={textInputRef}
              scrollEnabled={false}
              style={[styles.input, { color: theme["text-basic-color"] }]}
              defaultValue={text}
            />
          </ScrollView>
        </Layout>
        {showDetails && (
          <>
            <Divider />
            <Layout style={{ maxHeight: detailsMaxHeight }}>
              <ScrollView>
                <Separator size="small" />
                <View style={styles.detailsHeaderContainer}>
                  <Icon
                    fill={theme["text-hint-color"]}
                    name="pin-outline"
                    style={styles.icon}
                  />
                  <Separator horizontal size="small" />
                  <Text appearance="hint" category="s2">
                    {site.name}
                  </Text>
                </View>
                <View style={styles.detailsTextContainer}>
                  {Array.isArray(job.tasks) && job.tasks.length > 0 && (
                    <>
                      <Separator size="medium" />
                      <Text appearance="hint" category="c1">
                        Tasks
                      </Text>
                      <View style={styles.tinySeparator} />
                      {job.tasks.map((task, index) => (
                        <Fragment key={task.id}>
                          {index > 0 && <Separator size="small" />}
                          <TaskListItem job={job} readonly task={task} />
                        </Fragment>
                      ))}
                    </>
                  )}
                  {Boolean(site.notes) && (
                    <>
                      <Separator size="medium" />
                      <Text appearance="hint" category="c1">
                        Notes
                      </Text>
                      <Text>{site.notes}</Text>
                    </>
                  )}
                  {Boolean(
                    site.privateNotes && group !== companyUserRoles.EMPLOYEE
                  ) && (
                    <>
                      <Separator size="medium" />
                      <Text appearance="hint" category="c1">
                        Private notes
                      </Text>
                      <Text>{site.privateNotes}</Text>
                    </>
                  )}
                </View>
                <Separator size="small" />
              </ScrollView>
            </Layout>
          </>
        )}
      </View>
      <Divider />
      <Layout>
        <SafeAreaView>
          <View style={styles.footerContainer}>
            <IconButton
              appearance={showDetails ? "outline" : "ghost"}
              name="file-text-outline"
              onPress={() => setShowDetails(!showDetails)}
            />
            {maxLength && <Text>{`${text.length} / ${maxLength}`}</Text>}
          </View>
        </SafeAreaView>
      </Layout>
    </>
  );
};

JobTextInputModalScreen.defaultProps = {
  backIconName: "arrow-back-outline",
  initialText: undefined,
  maxLength: undefined,
  jobId: undefined,
  title: undefined,
};

export default memo(
  JobTextInputModalScreen,
  (prevProps, nextProps) => !prevProps.visible && !nextProps.visible
);
