import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";
import * as yup from "yup";

import adminUpdateCompany from "../../api/functions/adminUpdateCompany";
import sendUnsubscribeRequest from "../../api/functions/sendUnsubscribeRequest";
import Button from "../../components/buildingBlocks/Button";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import ListItem from "../../components/listItems/Base";
import RadioListItem from "../../components/listItems/Radio";
import ToggleListItem from "../../components/listItems/Toggle";
import LoadingButton from "../../components/LoadingButton";
import MediaThumbnail from "../../components/MediaThumbnail";
import AlertModal from "../../components/modals/Alert";
import ImageEditingModal from "../../components/modals/ImageEditing";
import PendingMutationsModal from "../../components/modals/PendingMutations";
import SimpleModal from "../../components/modals/Simple";
import StripeConnectAccountCard from "../../components/StripeConnectAccountCard";
import * as autocompleteJobConstants from "../../constants/autocompleteJob";
import companyUserRoles from "../../constants/companyUserRoles";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectSettings from "../../store/settings/selectors/selectSettings";
import {
  manualColorSchemeChanged,
  setUseAmpmTime,
  useSystemColorSchemeToggled,
} from "../../store/settings/slice";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import { StackParamList } from "../../types";
import getAsyncStorageKey from "../../utils/getAsyncStorageKey";
import isCustomersEnabled from "../../utils/isCustomersEnabled";
import isFeatureEnabled from "../../utils/isFeatureEnabled";
import userRolePermitted from "../../utils/userRolePermitted";

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const styles = StyleSheet.create({
  uploadContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    marginEnd: 16,
  },
  imageButtons: {
    flex: 1,
    marginHorizontal: 16,
  },
  divider: {
    marginStart: 16,
  },
  fakeListItem: {
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    paddingEnd: 16,
    paddingStart: 16,
  },
  input: {
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  sectionSeparator: {
    height: 24,
  },
  separator: {
    height: 8,
  },
  updateButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingEnd: 16,
  },
  listAccessory: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginEnd: 8,
    marginStart: 8,
  },
  content: {
    marginBottom: 26,
    marginTop: 20,
  },
  footer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  message: {
    marginEnd: 16,
    marginStart: 16,
  },
  logo: {
    width: 280,
    height: 140,
    resizeMode: "contain",
  },
  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 16,
  },
  radioListItem: { paddingStart: 32 },
  radioListItemTitle: {
    paddingEnd: 6,
    paddingStart: 6,
  },
  text: { paddingEnd: 16, paddingStart: 16 },
});

const Settings = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Settings">
  >();
  const theme = useTheme();
  const company = useSelector(selectCompany);
  const settings = useSelector(selectSettings);
  const { id } = useSelector(selectUser);
  const signedInUserRole = useSelector(selectSignedInUserRole);

  const autocompleteJobAsyncStorage = useAsyncStorage(
    getAsyncStorageKey({
      key: autocompleteJobConstants.asyncStorageKey,
      userId: id,
    })
  );
  const autocompleteJob =
    autocompleteJobAsyncStorage.value ||
    autocompleteJobConstants.permissions.ask;

  const customersEnabled = isCustomersEnabled(company);

  const [customersEnabledToggle, setCustomersEnabledToggle] = useState(
    customersEnabled
  );
  const [showNameEditingModal, setShowNameEditingModal] = useState<boolean>(
    false
  );
  const [showCustomerFacingLogo, setShowCustomerFacingLogo] = useState<boolean>(
    false
  );
  const [showPendingMutationsModal, setShowPendingMutationsModal] = useState(
    false
  );
  const [showCustomersToggleModal, setShowCustomersToggleModal] = useState(
    false
  );
  const [
    showCancelSubscriptionModal,
    setShowCancelSubscriptionModal,
  ] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: company.name,
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
    }),
    onSubmit: async (values, actions) => {
      adminUpdateCompany(
        { customersEnabled, name: values.name, logo: company.logo },
        company
      );
      actions.setSubmitting(false);
      setShowNameEditingModal(false);
    },
  });

  const renderListAccessory = useCallback(
    () => (
      <View style={styles.listAccessory}>
        {company && company.logo ? (
          <MediaThumbnail
            height={64}
            width={64}
            media={{ id: company.logo.id }}
          />
        ) : (
          <Text appearance="hint">Press to upload</Text>
        )}
        <Separator horizontal size="small" />
        <Icon
          name="arrow-ios-forward-outline"
          fill={theme["text-hint-color"]}
          height={24}
          width={24}
        />
      </View>
    ),
    [company, theme]
  );

  useEffect(() => {
    setCustomersEnabledToggle(customersEnabled);
  }, [customersEnabled]);
  useEffect(() => {
    if (showNameEditingModal)
      formik.resetForm({
        values: {
          name: company.name,
        },
      });
    // eslint-disable-next-line
  }, [showNameEditingModal]);

  const handleSet24HourTime = () =>
    dispatch(
      setUseAmpmTime({
        useAmpmTime: false,
      })
    );
  const handleSetAmpmTime = () =>
    dispatch(
      setUseAmpmTime({
        useAmpmTime: true,
      })
    );

  const reportsEnabled = isFeatureEnabled({ company, feature: "reports" });
  const dailyActivityReportsEnabled = company.jobSettings
    ? company.jobSettings.dailyActivityReportsEnabled
    : true;
  const incidentReportsEnabled = company.jobSettings
    ? company.jobSettings.incidentReportsEnabled
    : true;

  return (
    <>
      <Layout level="2" style={styles.root}>
        <HeaderContainer>
          <TopNavigation
            title="Settings"
            alignment="center"
            accessoryLeft={() => (
              <TopNavigationAction
                icon={(props) => <Icon {...props} name="arrow-back-outline" />}
                onPress={() => navigation.goBack()}
              />
            )}
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              {signedInUserRole === companyUserRoles.ADMIN && (
                <>
                  <Separator />
                  <StyledCard title="Company">
                    <ListItem
                      title="Name"
                      onPress={() => setShowNameEditingModal(true)}
                      accessoryRight={() => (
                        <View style={styles.listAccessory}>
                          <Text>{company.name}</Text>
                          <Separator horizontal size="small" />
                          <Icon
                            name="arrow-ios-forward-outline"
                            fill={theme["text-hint-color"]}
                            height={24}
                            width={24}
                          />
                        </View>
                      )}
                    />
                    <Divider style={styles.divider} />
                    <ListItem
                      title="Logo"
                      description="Custom brand Talarium"
                      onPress={() => setShowCustomerFacingLogo(true)}
                      accessoryRight={renderListAccessory}
                    />
                  </StyledCard>
                </>
              )}
              <Separator />
              <StyledCard title="Theme">
                <ToggleListItem
                  title="Automatic"
                  checked={settings.appearance.useSystemColorScheme}
                  onChange={(checked) =>
                    dispatch(
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      useSystemColorSchemeToggled({
                        enabled: checked,
                      })
                    )
                  }
                />
                {!settings.appearance.useSystemColorScheme && (
                  <>
                    <View style={styles.separator} />
                    <RadioListItem
                      checked={
                        settings.appearance.manualColorScheme === "light"
                      }
                      onChange={() =>
                        dispatch(
                          manualColorSchemeChanged({
                            colorScheme: "light",
                          })
                        )
                      }
                      title="Light"
                    />
                    <Divider style={styles.divider} />
                    <RadioListItem
                      checked={settings.appearance.manualColorScheme === "dark"}
                      onChange={() =>
                        dispatch(
                          manualColorSchemeChanged({
                            colorScheme: "dark",
                          })
                        )
                      }
                      title="Dark"
                    />
                  </>
                )}
              </StyledCard>
              <Separator />
              <StyledCard title="Time format">
                <RadioListItem
                  checked={settings.time.useAmpmTime}
                  onChange={handleSetAmpmTime}
                  title="12-hour"
                />
                <Divider style={styles.divider} />
                <RadioListItem
                  checked={!settings.time.useAmpmTime}
                  onChange={handleSet24HourTime}
                  title="24-hour"
                />
              </StyledCard>
              {userRolePermitted({
                permittedRoles: ["Admin"],
                userRole: signedInUserRole,
              }) && (
                <>
                  <Separator />
                  <StyledCard title="Customers">
                    <Separator size={12} />
                    <Text appearance="hint" category="p2" style={styles.text}>
                      Group multiple Locations under a Customer. Enables
                      Quotes/Invoices and sending Job Reports.
                    </Text>
                    <Separator size="medium" />
                    <ToggleListItem
                      title={`Customers are currently ${
                        customersEnabled ? "ENABLED" : "DISABLED"
                      }`}
                      checked={customersEnabledToggle}
                      onChange={(checked) => {
                        setCustomersEnabledToggle(checked);
                        setShowCustomersToggleModal(true);
                      }}
                    />
                  </StyledCard>
                </>
              )}
              <Separator />
              <StyledCard title="Jobs">
                <Separator size={12} />
                <Text appearance="hint" category="c1" style={styles.text}>
                  Checklist items
                </Text>
                {userRolePermitted({
                  permittedRoles: ["Admin"],
                  userRole: signedInUserRole,
                }) && (
                  <>
                    <ListItem
                      accessoryRight={(imageProps) => (
                        <Icon
                          {...imageProps}
                          name="arrow-ios-forward-outline"
                        />
                      )}
                      data-test="editActionItemsListItem"
                      onPress={() => navigation.navigate("ActionItemList")}
                      title="Edit additional checklist items"
                    />
                    <Divider style={styles.divider} />
                  </>
                )}
                <ListItem title="Automatically complete job after completing last checklist item" />
                <RadioListItem
                  checked={
                    autocompleteJob ===
                    autocompleteJobConstants.permissions.never
                  }
                  onChange={() =>
                    autocompleteJobAsyncStorage.setItem(
                      autocompleteJobConstants.permissions.never
                    )
                  }
                  style={styles.radioListItem}
                  title={() => (
                    <Text style={styles.radioListItemTitle}>Never</Text>
                  )}
                />
                <RadioListItem
                  checked={
                    autocompleteJob === autocompleteJobConstants.permissions.ask
                  }
                  onChange={() =>
                    autocompleteJobAsyncStorage.setItem(
                      autocompleteJobConstants.permissions.ask
                    )
                  }
                  style={styles.radioListItem}
                  title={() => (
                    <Text style={styles.radioListItemTitle}>Ask next time</Text>
                  )}
                />
                <RadioListItem
                  checked={
                    autocompleteJob ===
                    autocompleteJobConstants.permissions.always
                  }
                  onChange={() =>
                    autocompleteJobAsyncStorage.setItem(
                      autocompleteJobConstants.permissions.always
                    )
                  }
                  style={styles.radioListItem}
                  title={() => (
                    <Text style={styles.radioListItemTitle}>Always</Text>
                  )}
                />
                {userRolePermitted({
                  permittedRoles: ["Admin"],
                  userRole: signedInUserRole,
                }) &&
                  reportsEnabled && (
                    <>
                      <Separator size="medium" />
                      <Text appearance="hint" category="c1" style={styles.text}>
                        Reports
                      </Text>
                      <ToggleListItem
                        checked={dailyActivityReportsEnabled}
                        onChange={(checked) =>
                          adminUpdateCompany(
                            {
                              customersEnabled,
                              jobSettings: {
                                dailyActivityReportsEnabled: checked,
                                incidentReportsEnabled,
                              },
                              logo: company.logo,
                              name: company.name,
                            },
                            company
                          )
                        }
                        title="Enable daily activity reports"
                      />
                      <Divider style={styles.divider} />
                      <ToggleListItem
                        checked={incidentReportsEnabled}
                        onChange={(checked) =>
                          adminUpdateCompany(
                            {
                              customersEnabled,
                              jobSettings: {
                                dailyActivityReportsEnabled,
                                incidentReportsEnabled: checked,
                              },
                              logo: company.logo,
                              name: company.name,
                            },
                            company
                          )
                        }
                        title="Enable incident reports"
                      />
                    </>
                  )}
              </StyledCard>
              <Separator />
              <StyledCard title="Advanced">
                <ListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => {
                    setShowPendingMutationsModal(true);
                  }}
                  title="Pending mutations"
                />
              </StyledCard>
              {userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: signedInUserRole,
              }) && (
                <>
                  <Separator />
                  <StripeConnectAccountCard
                    style={{ marginEnd: 16, marginStart: 16 }}
                  />
                </>
              )}
              {Platform.OS === "web" &&
                userRolePermitted({
                  permittedRoles: ["Admin"],
                  userRole: signedInUserRole,
                }) && (
                  <>
                    <Separator />
                    <StyledButton
                      appearance="ghost"
                      onPress={() => setShowCancelSubscriptionModal(true)}
                      status="danger"
                    >
                      Cancel subscription
                    </StyledButton>
                  </>
                )}
              <Separator />
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Layout>
      <SimpleModal
        isVisible={showNameEditingModal}
        onBackPress={() => setShowNameEditingModal(false)}
        onBackdropPress={() => {}}
      >
        <View onStartShouldSetResponder={() => true}>
          <View style={styles.content}>
            <Input
              value={formik.values.name}
              onChangeText={formik.handleChange("name")}
              autoCapitalize="words"
              caption={
                formik.touched.name &&
                formik.errors.name &&
                typeof formik.errors.name === "string"
                  ? formik.errors.name
                  : null
              }
              disabled={formik.isSubmitting}
              label="Company Name"
              onFocus={() => {
                formik.setFieldTouched("name");
              }}
              status={
                formik.touched.name && formik.errors.name ? "danger" : "basic"
              }
              style={styles.input}
            />
          </View>
          <View style={styles.footer}>
            <LoadingButton
              onPress={formik.handleSubmit}
              disabled={!formik.values.name || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Save
            </LoadingButton>
            <Separator size="small" />
            <Button
              onPress={() => setShowNameEditingModal(false)}
              appearance="ghost"
            >
              Cancel
            </Button>
          </View>
        </View>
      </SimpleModal>
      <ImageEditingModal
        isVisible={showCustomerFacingLogo}
        onClose={() => setShowCustomerFacingLogo(false)}
        onUpload={(media) => {
          adminUpdateCompany(
            { customersEnabled, name: company.name, logo: media },
            company
          );
          setShowCustomerFacingLogo(false);
        }}
        media={company ? company.logo : null}
        onDelete={() => {
          adminUpdateCompany(
            { customersEnabled, name: company.name, logo: null },
            company
          );
          setShowCustomerFacingLogo(false);
        }}
      />
      <PendingMutationsModal
        title="Syncing with server..."
        continueDescription="Close"
        isVisible={showPendingMutationsModal}
        onCancel={() => {
          setShowPendingMutationsModal(false);
        }}
        onContinue={() => {
          setShowPendingMutationsModal(false);
        }}
        onEmptyOutbox={() => {}}
      />
      <AlertModal
        cancelText="Not Now"
        confirmText="Confirm"
        isVisible={showCustomersToggleModal}
        message={`${!customersEnabled ? "Enable" : "Disable"} customers?`}
        onCancel={() => {
          setShowCustomersToggleModal(false);
          setCustomersEnabledToggle(customersEnabled);
        }}
        onClose={() => {
          setShowCustomersToggleModal(false);
          setCustomersEnabledToggle(customersEnabled);
        }}
        onConfirm={() => {
          adminUpdateCompany(
            {
              customersEnabled: !customersEnabled,
              logo: company.logo,
              name: company.name,
            },
            company
          );
          setShowCustomersToggleModal(false);
        }}
      />
      <AlertModal
        cancelText="Not Now"
        confirmAsync
        confirmText="Confirm"
        isVisible={showCancelSubscriptionModal}
        message="If you confirm, your cancellation will be processed and effective within 24 hours."
        onCancel={() => setShowCancelSubscriptionModal(false)}
        onClose={() => setShowCancelSubscriptionModal(false)}
        onConfirm={async () => {
          try {
            await sendUnsubscribeRequest();
            addNotification({
              status: "success",
              title: "Your request to cancel the subscription has been sent",
            });
          } catch (error) {
            addNotification({
              message: (error && error.message) || error,
              status: "danger",
              title: "Send unsubscribe request failed",
            });
          }
          setShowCancelSubscriptionModal(false);
        }}
      />
    </>
  );
};

export default Settings;
