import { Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, View } from "react-native";

import { Props as DefaultDayProps } from "../DefaultDay";
import UiKittenTouchable from "../../UiKittenTouchable";

interface Props extends DefaultDayProps {
  colors?: Array<string>;
}

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: 40,
  },
  date: { alignItems: "center" },
  dayOfMonth: {
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    height: 40,
    justifyContent: "center",
    overflow: "hidden",
    width: 40,
  },
  dot: {
    borderRadius: 2,
    height: 4,
    margin: 1,
    width: 4,
  },
  dotsContainer: {
    bottom: 2,
    end: 4,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    position: "absolute",
    start: 4,
  },
});

const DottedDay = ({ colors, dateTime, onPress, selected }: Props) => {
  const theme = useTheme();
  const day = dayjs(dateTime);
  return (
    <View style={styles.date}>
      <UiKittenTouchable
        onPress={() => onPress(dateTime)}
        style={styles.button}
      >
        <View
          style={[
            styles.dayOfMonth,
            {
              backgroundColor: selected
                ? theme["color-primary-transparent-default"]
                : undefined,
              borderColor: selected
                ? theme["color-primary-default"]
                : "transparent",
            },
          ]}
        >
          <Text>{day.format("D")}</Text>
          <View style={styles.dotsContainer}>
            {colors.map((color) => (
              <View
                key={color}
                style={[styles.dot, { backgroundColor: color }]}
              />
            ))}
          </View>
        </View>
      </UiKittenTouchable>
    </View>
  );
};

DottedDay.defaultProps = {
  colors: [],
};

export default DottedDay;
