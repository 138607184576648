import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import {
  Button,
  Divider,
  Icon,
  IndexPath,
  Layout,
  MenuItem,
  OverflowMenu,
  ThemeType,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Keyboard,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";

import queryDateTimeData from "../../api/functions/queryDateTimeData";
import BottomTabHeader from "../../components/BottomTabHeader";
import { Separator } from "../../components/buildingBlocks";
import DottedDay from "../../components/calendar/DottedDay";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import AssigneesSelectModal from "../../components/modals/AssigneesSelectModal";
import CustomerSelectModal from "../../components/modals/CustomerSelect";
import JobEditingModal from "../../components/modals/JobEditing";
import JobExportModal from "../../components/modals/JobExportModal";
import SiteSelectModal from "../../components/modals/SiteSelect";
import ScheduleGridView from "../../components/ScheduleGridView";
import ScheduleView from "../../components/ScheduleView";
import ThemedFAB from "../../components/ThemedFAB";
import TimeRangePicker from "../../components/TimeRangePicker";
import companyUserRoles from "../../constants/companyUserRoles";
import jobStatuses from "../../constants/jobStatuses";
import useOnInitialFocus from "../../hooks/useOnInitialFocus";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectHistoricalSearch from "../../store/historicalSearch/selectors/selectHistoricalSearch";
import {
  resetFilters,
  setAssigneesFilter,
  setCustomDateTimeRange,
  setCustomersFilter,
  setMode,
  setQueryDateTimeRange,
  setSelectedDate,
  setSitesFilter,
} from "../../store/historicalSearch/slice";
import selectScheduleJobs from "../../store/jobs/selectors/selectScheduleJobs";
import { queryFinishedJobs } from "../../store/jobs/slice";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import {
  AssigneeType,
  BottomTabParamList,
  FormattedJob,
  Job,
  NestedBottomTabNavigationProp,
} from "../../types";
import isCustomersEnabled from "../../utils/isCustomersEnabled";
import isFeatureEnabled from "../../utils/isFeatureEnabled";
import userRolePermitted from "../../utils/userRolePermitted";

const jobIsSameDay = ({
  dateTime,
  job,
}: {
  dateTime: string;
  job: FormattedJob;
}) => dayjs(job.startDateTime).isSame(dayjs(dateTime), "day");

const getDottedDayColors = ({
  dateTime,
  jobs,
  theme,
}: {
  dateTime: string;
  jobs: Array<FormattedJob>;
  theme: ThemeType;
}) => {
  const colors: Array<string> = [];
  if (
    jobs.some(
      (job) =>
        jobIsSameDay({ dateTime, job }) &&
        !job.finished &&
        job.status.id === jobStatuses.immediate.id
    )
  ) {
    colors.push(theme["color-danger-default"]);
  }
  if (
    jobs.some(
      (job) =>
        jobIsSameDay({ dateTime, job }) &&
        !job.finished &&
        job.status.id === jobStatuses.late.id
    )
  ) {
    colors.push(theme["color-warning-default"]);
  }
  if (
    jobs.some(
      (job) =>
        jobIsSameDay({ dateTime, job }) &&
        !job.finished &&
        job.status.id === jobStatuses.future.id
    )
  ) {
    colors.push(theme["color-info-default"]);
  }
  if (
    jobs.some(
      (job) =>
        jobIsSameDay({ dateTime, job }) &&
        !job.finished &&
        job.status.id === jobStatuses.inProgress.id
    )
  ) {
    colors.push(theme["color-primary-default"]);
  }
  if (jobs.some((job) => jobIsSameDay({ dateTime, job }) && job.finished)) {
    colors.push(theme["color-success-default"]);
  }
  return colors;
};

const styles = StyleSheet.create({
  checkBox: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  container: {
    flex: 1,
  },
  dateContainer: {
    width: 48,
    flexDirection: "column",
    marginHorizontal: 8,
    alignItems: "center",
  },
  divider: {
    marginStart: 16,
  },
  emptyDate: {
    height: 84,
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: "#C5CEE0",
  },
  mobileFab: {
    end: 16,
    bottom: 16,
    position: "absolute",
  },
  webFab: {
    end: 88,
    bottom: 16,
    position: "absolute",
  },
  filterButtonsContainer: {
    flexDirection: "row",
  },
  filterContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  item: {
    backgroundColor: "white",
    flex: 1,
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginTop: 17,
  },
  jobContainer: {
    marginLeft: 64,
    flex: 1,
    flexDirection: "row",
    borderTopColor: "#C5CEE0",
  },
  listContainer: {
    flex: 1,
  },
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  pillButton: {
    borderRadius: 100,
  },
  root: {
    flex: 1,
  },
  topNavigationAction: {
    paddingVertical: 8,
  },
});

const HistoricalSearchScreen = () => {
  const screenWidth = Dimensions.get("window").width;
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList, "HistoricalSearch">,
      NestedBottomTabNavigationProp
    >
  >();
  const { params } = useRoute<
    RouteProp<BottomTabParamList, "HistoricalSearch">
  >();
  const company = useSelector(selectCompany);
  const signedInUserRole = useSelector(selectSignedInUserRole);
  const historicalSearch = useSelector(selectHistoricalSearch);
  const jobs = useSelector(selectScheduleJobs);

  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobsRefreshing, setJobsRefreshing] = useState(false);
  const [createJobVisible, setCreateJobVisible] = useState(false);
  const [showAssigneesSelectModal, setShowAssigneesSelectModal] = useState(
    false
  );
  const [showCustomerPickerModal, setShowCustomerPickerModal] = useState(false);
  const [showSitePickerModal, setShowSitePickerModal] = useState(false);
  const [showJobExportModal, setShowJobExportModal] = useState(false);

  const [overflowMenuVisible, setOverflowMenuVisible] = useState(false);
  const [overflowMenuIndex, setOverflowMenuIndex] = useState<IndexPath>(
    new IndexPath(0)
  );

  const runQueryDateTimeData = async ({
    endDateTime,
    startDateTime,
  }: {
    endDateTime: string;
    startDateTime: string;
  }) => {
    if (startDateTime > endDateTime) {
      throw new Error("'From' must be before 'To'");
    }
    dispatch(queryFinishedJobs.pending());
    try {
      const results = await queryDateTimeData({
        from: startDateTime,
        to: endDateTime,
        type: "History",
      });
      dispatch(queryFinishedJobs.fulfilled({ jobs: results as Array<Job> }));
    } catch (error) {
      dispatch(queryFinishedJobs.rejected(undefined, error));
      throw error;
    }
  };
  const loadJobs = async (queryRange) => {
    dispatch(setQueryDateTimeRange({ queryDateTimeRange: queryRange }));
    if (queryRange.startDateTime && queryRange.endDateTime) {
      setJobsLoading(true);
      try {
        await runQueryDateTimeData(queryRange);
      } catch (error) {
        addNotification({
          message: (error && error.message) || error,
          status: "danger",
          title: "Query failed",
        });
      }
    }
    setJobsLoading(false);
  };

  useOnInitialFocus(() => {
    if (historicalSearch.queryDateTimeRange) {
      loadJobs(historicalSearch.queryDateTimeRange);
    } else if (historicalSearch.mode === "week") {
      loadJobs({
        endDateTime: dayjs(historicalSearch.selectedDate)
          .endOf("week")
          .toISOString(),
        startDateTime: dayjs(historicalSearch.selectedDate)
          .startOf("week")
          .toISOString(),
      });
    }
  });
  useEffect(() => {
    if (params) {
      const {
        customerIds,
        endDateTime,
        siteIds,
        startDateTime,
        userIds,
      } = params;
      dispatch(setCustomersFilter({ customerIds }));
      dispatch(setSitesFilter({ siteIds }));
      dispatch(setAssigneesFilter({ groupIds: [], userIds }));
      dispatch(setMode({ mode: "custom" }));
      dispatch(
        setCustomDateTimeRange({
          customDateTimeRange: {
            endDateTime,
            startDateTime,
          },
        })
      );
      loadJobs({ endDateTime, startDateTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleSitesSelectModalOpen = () => setShowSitePickerModal(true);
  const handleSitesSelectModalClose = () => setShowSitePickerModal(false);
  const handleSitesSelection = (siteIds: Array<string>) => {
    handleSitesSelectModalClose();
    dispatch(setSitesFilter({ siteIds }));
  };

  const customersEnabled = isCustomersEnabled(company);

  const assigneesFilterEnabled =
    historicalSearch.filters.groupIds.length > 0 ||
    historicalSearch.filters.userIds.length > 0;

  const gridViewSelected = overflowMenuIndex.row === 0;
  const gridViewEnabled =
    Platform.OS === "web" &&
    userRolePermitted({
      permittedRoles: ["Admin", "Supervisor"],
      userRole: signedInUserRole,
    }) &&
    screenWidth >= 600 &&
    gridViewSelected;

  const jobSummariesEnabled =
    Boolean(company) &&
    isFeatureEnabled({
      company,
      feature: "jobSummaries",
    });

  const renderFilterBar = () => {
    if (
      userRolePermitted({
        permittedRoles: ["Admin", "Supervisor"],
        userRole: signedInUserRole,
      })
    ) {
      const filterButtons = (
        <>
          <View style={styles.filterButtonsContainer}>
            {customersEnabled && (
              <>
                <Button
                  appearance={
                    historicalSearch.filters.customerIds.length > 0
                      ? "filled"
                      : "outline"
                  }
                  onPress={() => setShowCustomerPickerModal(true)}
                  size="tiny"
                  style={styles.pillButton}
                >
                  {`Customers \u2022 ${
                    historicalSearch.filters.customerIds.length || "All"
                  }`}
                </Button>
                <Separator horizontal size="small" />
              </>
            )}
            <Button
              appearance={
                historicalSearch.filters.siteIds.length > 0
                  ? "filled"
                  : "outline"
              }
              onPress={handleSitesSelectModalOpen}
              size="tiny"
              style={styles.pillButton}
            >
              {`Locations \u2022 ${
                historicalSearch.filters.siteIds.length || "All"
              }`}
            </Button>
            <Separator horizontal size="small" />
            <Button
              appearance={assigneesFilterEnabled ? "filled" : "outline"}
              onPress={() => setShowAssigneesSelectModal(true)}
              size="tiny"
              style={styles.pillButton}
            >
              {`Assignees \u2022 ${
                historicalSearch.filters.groupIds.length +
                  historicalSearch.filters.userIds.length || "All"
              }`}
            </Button>
            <Separator horizontal size="small" />
            <Button
              disabled={
                historicalSearch.filters.customerIds.length === 0 &&
                historicalSearch.filters.siteIds.length === 0 &&
                !assigneesFilterEnabled
              }
              onPress={() => dispatch(resetFilters())}
              size="tiny"
              status="control"
              style={styles.pillButton}
            >
              Clear
            </Button>
          </View>
        </>
      );
      if (screenWidth >= 600) {
        return (
          <>
            <Container>
              <View
                style={[
                  styles.filterContainer,
                  { flexDirection: "row", justifyContent: "space-between" },
                ]}
              >
                {filterButtons}
                <OverflowMenu
                  anchor={() => (
                    <Button
                      accessoryLeft={(imageProps) => (
                        <Icon
                          {...imageProps}
                          name={gridViewSelected ? "grid" : "list"}
                        />
                      )}
                      accessoryRight={(imageProps) => (
                        <Icon {...imageProps} name="arrow-ios-downward" />
                      )}
                      appearance="ghost"
                      onPress={() => setOverflowMenuVisible(true)}
                    />
                  )}
                  onBackdropPress={() => setOverflowMenuVisible(false)}
                  onSelect={(index) => {
                    setOverflowMenuIndex(index);
                    setOverflowMenuVisible(false);
                  }}
                  selectedIndex={overflowMenuIndex}
                  visible={overflowMenuVisible}
                >
                  <MenuItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="grid" />
                    )}
                    title="Grid"
                  />
                  <MenuItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="list" />
                    )}
                    title="List"
                  />
                </OverflowMenu>
              </View>
            </Container>
            <Divider />
          </>
        );
      }
      return (
        <View>
          <ScrollView contentContainerStyle={styles.filterContainer} horizontal>
            {filterButtons}
          </ScrollView>
          <Divider />
        </View>
      );
    }
    return null;
  };

  return (
    <>
      <Layout data-test="scheduleScreen" style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <BottomTabHeader
              accessoryRight={() => {
                return (
                  signedInUserRole !== companyUserRoles.EMPLOYEE && (
                    <TopNavigationAction
                      icon={(props) => <Icon name="plus-outline" {...props} />}
                      onPress={() => {
                        Keyboard.dismiss();
                        setCreateJobVisible(true);
                      }}
                    />
                  )
                );
              }}
              data-test="bottomTabHeaderInScheduleScreen"
              title="Schedule"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.container}>
          <SafeAreaView style={{ flex: 1 }}>
            <Container>
              <Separator size="small" />
              <TimeRangePicker
                customRange={historicalSearch.customDateTimeRange}
                loading={jobsLoading}
                maxSpan={
                  gridViewEnabled ? { unit: "month", value: 1 } : undefined
                }
                mode={historicalSearch.mode}
                onCustomRangeChanged={(customRange) => {
                  dispatch(
                    setCustomDateTimeRange({
                      customDateTimeRange: customRange,
                    })
                  );
                }}
                onModeChanged={(mode) => {
                  dispatch(setMode({ mode }));
                  dispatch(setQueryDateTimeRange({ queryDateTimeRange: null }));
                }}
                onQueryRangeChanged={loadJobs}
                onSelectedDateChanged={(dateTime) => {
                  dispatch(setSelectedDate({ selectedDate: dateTime }));
                }}
                renderCalendarDay={({ dateTime, onPress, selected }) => {
                  const colors = getDottedDayColors({
                    dateTime,
                    jobs,
                    theme,
                  });
                  return (
                    <DottedDay
                      colors={colors}
                      dateTime={dateTime}
                      onPress={onPress}
                      selected={selected}
                    />
                  );
                }}
                selectedDate={historicalSearch.selectedDate}
                style={{ paddingEnd: 16, paddingStart: 16 }}
              />
              <Separator size="small" />
            </Container>
            <Divider />
            <Layout style={{ flex: 1 }}>
              {renderFilterBar()}
              {gridViewEnabled ? (
                <ScheduleGridView
                  onJobPress={(jobId: string) => {
                    navigation.navigate("JobDetails", { jobId });
                  }}
                  onRefresh={async () => {
                    if (historicalSearch.queryDateTimeRange) {
                      setJobsRefreshing(true);
                      setJobsLoading(true);
                      try {
                        await runQueryDateTimeData(
                          historicalSearch.queryDateTimeRange
                        );
                      } catch (error) {
                        addNotification({
                          message: (error && error.message) || error,
                          status: "danger",
                          title: "Refresh failed",
                        });
                      }
                    }
                    setJobsRefreshing(false);
                    setJobsLoading(false);
                  }}
                  refreshing={jobsLoading || jobsRefreshing}
                />
              ) : (
                <Layout level="2" style={{ flex: 1 }}>
                  <ScheduleView
                    onJobPress={(jobId: string) => {
                      navigation.navigate("JobDetails", { jobId });
                    }}
                    onRefresh={async () => {
                      if (historicalSearch.queryDateTimeRange) {
                        setJobsRefreshing(true);
                        setJobsLoading(true);
                        try {
                          await runQueryDateTimeData(
                            historicalSearch.queryDateTimeRange
                          );
                        } catch (error) {
                          addNotification({
                            message: (error && error.message) || error,
                            status: "danger",
                            title: "Refresh failed",
                          });
                        }
                      }
                      setJobsRefreshing(false);
                      setJobsLoading(false);
                    }}
                    refreshing={jobsRefreshing}
                  />
                </Layout>
              )}
            </Layout>
            {jobSummariesEnabled &&
              userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: signedInUserRole,
              }) && (
                <View
                  style={
                    Platform.OS === "web" ? styles.webFab : styles.mobileFab
                  }
                >
                  <ThemedFAB
                    alternate
                    icon="export"
                    isVisible={jobs.length > 0}
                    label="Send"
                    onPress={() => setShowJobExportModal(true)}
                  />
                </View>
              )}
          </SafeAreaView>
        </Layout>
      </Layout>
      <CustomerSelectModal
        headerTitle="Select customers"
        isVisible={showCustomerPickerModal}
        multiSelect
        onClose={() => setShowCustomerPickerModal(false)}
        onSubmit={(customerIds) => {
          dispatch(setCustomersFilter({ customerIds }));
          setShowCustomerPickerModal(false);
        }}
        selected={historicalSearch.filters.customerIds}
      />
      <SiteSelectModal
        headerTitle="Select locations"
        isVisible={showSitePickerModal}
        multiSelect
        onClose={handleSitesSelectModalClose}
        onSubmit={handleSitesSelection}
        selected={historicalSearch.filters.siteIds}
      />
      <AssigneesSelectModal
        isVisible={showAssigneesSelectModal}
        onClose={() => setShowAssigneesSelectModal(false)}
        onSelectedChange={(assignees) => {
          const groupIds = [];
          const userIds = [];
          assignees.forEach((assignee) => {
            if (assignee.type === "Group") {
              groupIds.push(assignee.id);
            } else if (assignee.type === "User") {
              userIds.push(assignee.id);
            }
          });
          dispatch(setAssigneesFilter({ groupIds, userIds }));
          setShowAssigneesSelectModal(false);
        }}
        selected={[
          ...historicalSearch.filters.groupIds.map((groupId) => ({
            id: groupId,
            type: "Group" as AssigneeType,
          })),
          ...historicalSearch.filters.userIds.map((userId) => ({
            id: userId,
            type: "User" as AssigneeType,
          })),
        ]}
      />
      <JobEditingModal
        isVisible={createJobVisible}
        onClose={() => setCreateJobVisible(false)}
      />
      {jobSummariesEnabled && (
        <JobExportModal
          onClose={() => setShowJobExportModal(false)}
          isVisible={showJobExportModal}
          onSettingsPress={() => {
            setShowJobExportModal(false);
            navigation.navigate("Settings");
          }}
        />
      )}
    </>
  );
};

export default HistoricalSearchScreen;
