import { Text, TextProps } from "@ui-kitten/components";
import React from "react";
import { useSelector } from "react-redux";

import selectGroupById from "../../store/groups/selectors/selectGroupById";

interface Props extends TextProps {
  groupId: string;
}

const GroupNameText = ({ groupId, ...props }: Props) => {
  const group = useSelector((state) => selectGroupById(state, groupId));
  return <Text {...props}>{group.name}</Text>;
};

export default GroupNameText;
