import React from "react";

import FullScreenModal from "../FullScreen";
import JobTextInputModalScreen from "../../modalScreens/JobTextInput";

interface Props {
  isVisible: boolean;
  jobId?: string;
  onClose: () => void;
  onSubmit: (text: string) => void;
  text?: string;
}

const EditMediaDescriptionModal = ({
  isVisible,
  jobId,
  onClose,
  onSubmit,
  text,
}: Props) => {
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <JobTextInputModalScreen
        backIconName="close-outline"
        initialText={text || ""}
        jobId={jobId}
        maxLength={280}
        onBackPress={onClose}
        onSubmitPress={onSubmit}
        title={`${text ? "Edit" : "Add"} description`}
        visible={isVisible}
      />
    </FullScreenModal>
  );
};

EditMediaDescriptionModal.defaultProps = {
  jobId: undefined,
  text: undefined,
};

export default EditMediaDescriptionModal;
