import { Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";
import {
  CloseActionType,
  DropdownAlertProps,
} from "react-native-dropdownalert";

const styles = StyleSheet.create({
  text: {
    color: "white",
  },
});

interface Props {
  alertData: {
    type: string;
    title?: string;
    message?: string;
    action?: CloseActionType;
    payload?: any;
    interval?: number;
  };
  props: DropdownAlertProps;
}

const InAppNotificationsMessage = ({
  alertData: { message },
  props: { messageNumOfLines },
}: Props) => {
  if (message) {
    return (
      <Text category="p2" numberOfLines={messageNumOfLines} style={styles.text}>
        {message}
      </Text>
    );
  }
  return null;
};

export default InAppNotificationsMessage;
