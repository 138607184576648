import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import {
  CompositeNavigationProp,
  useNavigation,
} from "@react-navigation/native";
import {
  Divider,
  Icon,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useState, useRef } from "react";
import {
  Platform,
  ScrollView,
  SectionList,
  StyleSheet,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { useDispatch, useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import clockOut from "../../api/functions/clockOut";
import queryDateTimeData from "../../api/functions/queryDateTimeData";
import Button from "../../components/buildingBlocks/Button";
import Separator from "../../components/buildingBlocks/Separator";
import DottedDay from "../../components/calendar/DottedDay";
import ClockInButton from "../../components/ClockInButton";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import HistoricalTimeCardListItem from "../../components/listItems/HistoricalTimeCard";
import ClockedOutModal from "../../components/modals/ClockedOut";
import TimesheetModal from "../../components/modals/Timesheet";
import UsersPickerModal from "../../components/modals/UsersPicker";
import SectionItem from "../../components/SectionItem";
import ThemedFAB from "../../components/ThemedFAB";
import TimeRangePicker from "../../components/TimeRangePicker";
import TourStep from "../../components/TourStep";
import companyUserRolesExtended from "../../constants/companyUserRolesExtended";
import momentFormats from "../../constants/momentFormats";
import useOnInitialFocus from "../../hooks/useOnInitialFocus";
import { getLocation } from "../../location/mapLocation";
import selectUser from "../../store/auth/selectors/selectUser";
import selectQueriedTimeCards from "../../store/timeCards/selectors/selectQueriedTimeCards";
import {
  setUserIds,
  setMode,
  setQueryDateTimes,
  setTimeCards,
  setCustomRangeDateTimes,
  setSelectedDate,
} from "../../store/timeCards/slice";
import selectUserById from "../../store/users/selectors/selectUserById";
import selectUsers from "../../store/users/selectors/selectUsers";
import {
  BottomTabParamList,
  DateTimeRange,
  NestedBottomTabNavigationProp,
  Timecard,
} from "../../types";
import sortUsers from "../../utils/sortUsers";
import BottomTabHeader from "../../components/BottomTabHeader";
import TimerText from "../../components/TimerText";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";

const styles = StyleSheet.create({
  timeRangePicker: {
    paddingHorizontal: 16,
  },
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  filterButton: {
    marginEnd: 8,
  },
  container: {
    flex: 1,
  },
  filterButtonsContainer: {
    flexDirection: "row",
  },
  filterContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 24,
  },
  footer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  root: {
    flex: 1,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  timer: {
    paddingEnd: 16,
    paddingStart: 16,
    textAlign: "center",
  },
  timeCard: {
    alignItems: "center",
    borderRadius: 8,
    elevation: 1,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  timeCardContainer: {
    marginEnd: "auto",
    marginStart: "auto",
  },
  topNavigationAction: {
    marginHorizontal: 0,
  },
  listItemAccessory: {
    marginEnd: 8,
    marginStart: 8,
  },
  sectionHeader: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  webFab: {
    position: "absolute",
    bottom: 16,
    right: 88,
  },
  mobileFab: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },
});

const TimeCard = () => {
  const insets = useSafeAreaInsets();
  const ref = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation<
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList, "TimeCard">,
      NestedBottomTabNavigationProp
    >
  >();
  const { id, group } = useSelector(selectUser);
  const user = useSelector((state) => selectUserById(state, id));
  const users = useSelector(selectUsers);
  const filterUsers: Array<string> = useSelector(
    (state: any) => state.data.timeCards.userIds
  );
  const queryDateTimes: DateTimeRange = useSelector(
    (state: any) => state.data.timeCards.queryDateTimes
  );
  const mode = useSelector((state: any) => state.data.timeCards.mode);
  const selectedDate = useSelector(
    (state: any) => state.data.timeCards.selectedDate
  );
  const customRangeDateTimes = useSelector(
    (state: any) => state.data.timeCards.customRangeDateTimes
  );

  const [clockOutPending, setClockOutPending] = useState(false);
  const [clockedOut, setClockedOut] = useState<{
    timeCard?: Timecard;
    visible: boolean;
  }>({
    timeCard: null,
    visible: false,
  });
  const [showTimesheetModal, setShowTimesheetModal] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showUsersPickerModal, setShowUsersPickerModal] = useState<boolean>(
    false
  );
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const { sections, itemIds } = useSelector(selectQueriedTimeCards);

  const queryTimeCards = async ({
    endDateTime,
    startDateTime,
  }: {
    endDateTime: string;
    startDateTime: string;
  }): Promise<void> => {
    setLoading(true);
    dispatch(setQueryDateTimes({ startDateTime, endDateTime }));
    try {
      const results = await queryDateTimeData({
        from: startDateTime,
        to: endDateTime,
        type: "Timecard",
      });
      dispatch(setTimeCards({ timecards: results }));
    } catch (e) {
      addNotification({
        message: e.message || "An unexpected error occurred",
        status: "danger",
        title: "Error querying for time cards",
      });
    }
    setLoading(false);
  };

  useOnInitialFocus(() => {
    if (mode === "custom") {
      return queryTimeCards(queryDateTimes);
    }
    return queryTimeCards({
      startDateTime: dayjs(selectedDate).startOf("week").toISOString(),
      endDateTime: dayjs(selectedDate).endOf("week").toISOString(),
    });
  });

  const scrollToDay = (date) => {
    const formattedDate = dayjs(date).format(momentFormats.dateFormat);
    sections.forEach((item, index) => {
      if (item.title === formattedDate) {
        ref.current.scrollToLocation({
          sectionIndex: index,
          itemIndex: 0,
        });
      }
    });
  };

  const runClockOut = async () => {
    setClockOutPending(true);
    const location = await getLocation({ title: "Clock out failed" });
    setClockOutPending(false);
    if (location) {
      const timeCardId = uuidv4();
      clockOut(
        {
          coordinates: {
            accuracy: location.accuracy,
            lat: location.latitude,
            lng: location.longitude,
          },
          id: timeCardId,
          timestamp: new Date().toISOString(),
        },
        user
      );
      const timestamp = new Date().toISOString();
      setClockedOut({
        timeCard: {
          id: timeCardId,
          started: {
            ...user.clockedIn,
            coordinates: {
              ...user.clockedIn.coordinates,
            },
          },
          quit: {
            at: timestamp,
            by: user.id,
            coordinates: {
              accuracy: location.accuracy,
              lat: location.latitude,
              lng: location.longitude,
            },
          },
          updated: {
            at: timestamp,
            by: user.id,
          },
          created: {
            at: timestamp,
            by: user.id,
          },
        },
        visible: true,
      });
    }
  };
  return (
    <>
      <Layout level="2" style={styles.root}>
        <Layout>
          <Container>
            <View
              style={{
                paddingEnd: insets.right,
                paddingStart: insets.left,
                paddingTop: insets.top,
              }}
            >
              <BottomTabHeader title="Time Keeping" />
            </View>
          </Container>
        </Layout>
        <Divider />
        <Container style={styles.container}>
          {user ? (
            <>
              <Separator size="medium" />
              <TourStep name="timeCard">
                <SectionItem
                  status={user.clockedIn && "primary"}
                  title="TIME CARD"
                >
                  <ListItem
                    accessoryLeft={(props) => <Icon name="clock" {...props} />}
                    accessoryRight={() => (
                      <View style={styles.listItemAccessory}>
                        {user.clockedIn ? (
                          <Button
                            data-test="clockOutButtonInTimeCardsScreen"
                            loading={clockOutPending}
                            onPress={runClockOut}
                            size="small"
                            appearance="outline"
                          >
                            Clock out
                          </Button>
                        ) : (
                          <ClockInButton
                            data-test="clockInButtonInTimeCardsScreen"
                            size="small"
                          />
                        )}
                      </View>
                    )}
                    description={(props) =>
                      user.clockedIn ? (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TimerText {...props} dateTime={user.clockedIn.at} />
                      ) : (
                        <Text {...props}>
                          Clock in to start a new time card
                        </Text>
                      )
                    }
                    title={
                      user.clockedIn
                        ? `Clocked in at ${dayjs(user.clockedIn.at).format(
                            dateTimeFormat
                          )}`
                        : "Not clocked in"
                    }
                  />
                </SectionItem>
              </TourStep>
              <Separator size="medium" />
              <TimeRangePicker
                style={styles.timeRangePicker}
                customRange={customRangeDateTimes}
                loading={loading}
                mode={mode}
                onCustomRangeChanged={({ startDateTime, endDateTime }) =>
                  dispatch(
                    setCustomRangeDateTimes({ startDateTime, endDateTime })
                  )
                }
                onModeChanged={(updatedMode) => {
                  dispatch(setTimeCards({ timecards: [] }));
                  dispatch(setMode({ mode: updatedMode }));
                }}
                onQueryRangeChanged={({ endDateTime, startDateTime }) =>
                  queryTimeCards({ endDateTime, startDateTime })
                }
                onSelectedDateChanged={(updatedDate) => {
                  scrollToDay(updatedDate);
                  dispatch(setSelectedDate({ selectedDate: updatedDate }));
                }}
                selectedDate={selectedDate}
                renderCalendarDay={({ dateTime, onPress, selected }) => {
                  const colors: Array<string> = [];
                  const key = dayjs(dateTime).format(momentFormats.dateFormat);
                  sections.forEach((item) => {
                    if (item.title === key)
                      colors.push(theme["color-basic-600"]);
                  });
                  return (
                    <DottedDay
                      colors={colors}
                      selected={selected}
                      dateTime={dateTime}
                      onPress={onPress}
                    />
                  );
                }}
              />
              <Separator size="small" />
              <Divider />
              <Layout style={{ flex: 1 }}>
                {group !== companyUserRolesExtended.EMPLOYEE.value && (
                  <View>
                    <ScrollView
                      contentContainerStyle={styles.filterContainer}
                      horizontal
                    >
                      <View style={styles.filterButtonsContainer}>
                        <Button
                          appearance={filterUsers.length ? "filled" : "outline"}
                          onPress={() => setShowUsersPickerModal(true)}
                          size="tiny"
                          style={[styles.filterButton, { borderRadius: 56 }]}
                        >
                          {`Users \u2022 ${filterUsers.length || "All"}`}
                        </Button>
                        <Button
                          disabled={!filterUsers.length}
                          onPress={() => dispatch(setUserIds({ userIds: [] }))}
                          size="tiny"
                          status="basic"
                          style={[styles.filterButton, { borderRadius: 56 }]}
                        >
                          Clear
                        </Button>
                      </View>
                    </ScrollView>
                    <Divider />
                  </View>
                )}
                <SectionList
                  ref={ref}
                  onRefresh={async () => {
                    setRefreshing(true);
                    await queryTimeCards(queryDateTimes);
                    setRefreshing(false);
                  }}
                  refreshing={refreshing}
                  sections={sections}
                  keyExtractor={(item) => item}
                  ItemSeparatorComponent={Divider}
                  getItemLayout={sectionListGetItemLayout({
                    getItemHeight: () => 68,
                    getSeparatorHeight: () => 1,
                    getSectionHeaderHeight: () => 36,
                  })}
                  renderItem={({ item }) => (
                    <HistoricalTimeCardListItem
                      data-test={`${item}-historicalTimeCardListItem`}
                      id={item}
                      onPress={() =>
                        navigation.navigate("TimeCardDetails", {
                          timeCardId: item,
                        })
                      }
                    />
                  )}
                  renderSectionHeader={({ section }) => {
                    const { data, title } = section;
                    return (
                      <Layout level="3" style={styles.sectionHeader}>
                        <Text>{`${title} (${data.length})`}</Text>
                      </Layout>
                    );
                  }}
                  stickySectionHeadersEnabled
                  ListEmptyComponent={
                    <View style={styles.listEmptyComponent}>
                      <Text category="h3">No time cards found</Text>
                    </View>
                  }
                  contentContainerStyle={{
                    paddingBottom: itemIds.length > 0 ? 88 : 0,
                  }}
                />
                <View
                  style={
                    Platform.OS === "web" ? styles.webFab : styles.mobileFab
                  }
                >
                  <ThemedFAB
                    isVisible={itemIds.length > 0}
                    label="Timesheet"
                    icon="timetable"
                    onPress={() => setShowTimesheetModal(true)}
                    alternate
                  />
                </View>
              </Layout>
            </>
          ) : (
            <></>
          )}
        </Container>
      </Layout>
      <TimesheetModal
        isVisible={showTimesheetModal}
        onClose={() => setShowTimesheetModal(false)}
        timeCardIds={itemIds}
      />
      <ClockedOutModal
        isVisible={clockedOut.visible}
        onClose={() =>
          setClockedOut((prevState) => ({ ...prevState, visible: false }))
        }
        timeCard={clockedOut.timeCard}
      />
      <UsersPickerModal
        availableUserIds={users.ids.sort((a, b) =>
          sortUsers(users.entities[a], users.entities[b])
        )}
        initialSelectedUserIds={filterUsers}
        isVisible={showUsersPickerModal}
        onClose={() => setShowUsersPickerModal(false)}
        onSubmit={(ids) => {
          dispatch(setUserIds({ userIds: ids }));
          setShowUsersPickerModal(false);
        }}
      />
    </>
  );
};

export default TimeCard;
