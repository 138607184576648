import gql from "graphql-tag";

import { inboxItemFragment } from "../../../fragments";

const completeInboxItem = gql`
  mutation completeInboxItem($id: ID!) {
    completeInboxItem(id: $id) {
      ...inboxItemFragment
    }
  }
  ${inboxItemFragment}
`;

export default completeInboxItem;
