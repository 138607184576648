import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
  location: undefined,
};

const slice = createSlice({
  initialState,
  name: "permissions",
  reducers: {
    setLocationPermission: (state, action) => {
      const { locationPermission } = action.payload;
      state.location = locationPermission;
    },
  },
  extraReducers: {
    [PURGE]: () => initialState,
  },
});

const { actions, reducer } = slice;

export const { setLocationPermission } = actions;

export default reducer;
