import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Icon, Layout, Spinner, Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { RefreshControl, SafeAreaView, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

import acceptQuote from "../../api/functions/acceptQuote";
import cancelQuote from "../../api/functions/cancelQuote";
import retrieveQuote from "../../api/functions/retrieveQuote";
import retrieveStripeAccount from "../../api/functions/retrieveStripeAccount";
import sendQuote from "../../api/functions/sendQuote";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import ListItem from "../../components/listItems/Base";
import QuoteLineItemListItem from "../../components/listItems/QuoteLineItem";
import TimelineListItem from "../../components/listItems/Timeline";
import AlertModal from "../../components/modals/Alert";
import SendJobSummariesModal from "../../components/modals/PostmarkResponsesModal";
import QuoteEditingModal from "../../components/modals/QuoteEditing";
import QuoteEmailActivityCard from "../../components/QuoteEmailActivityCard";
import StripeQuoteStatusText from "../../components/StripeQuoteStatusText";
import selectCustomerByStripeCustomerId from "../../store/customers/selectors/selectCustomerByStripeCustomerId";
import { setInvoicesStale, setQuotesStale } from "../../store/payments/slice";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import {
  PostmarkMessageSendingResponse,
  StackParamList,
  StripeAccount,
  StripeExpandedQuote,
} from "../../types";
import formatCurrency from "../../utils/formatCurrency";

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const ListItemContainer = styled.View`
  padding: 8px;
`;

const ListItemText = styled(Text)`
  padding: 0 8px;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const SummaryRow = styled.View`
  flex-direction: row;
`;

const SummaryRowNameContainer = styled.View`
  align-items: flex-end;
  flex: 2;
`;

const SummaryRowValueContainer = styled.View`
  align-items: flex-end;
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledQuoteEmailActivityCard = styled(QuoteEmailActivityCard)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const getInvoiceTitle = (quote: StripeExpandedQuote) => {
  if (quote.invoice.number) {
    return quote.invoice.number;
  }
  return `${quote.customer.invoice_prefix}-DRAFT`;
};

const getQuoteTitle = (quote: StripeExpandedQuote) => {
  if (quote.number) {
    return quote.number;
  }
  if (quote.customer) {
    return `QT-${quote.customer.invoice_prefix}-DRAFT`;
  }
  return "QT-DRAFT";
};

const QuoteDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "QuoteDetails">
  >();
  const {
    params: { quoteId },
  } = useRoute<RouteProp<StackParamList, "QuoteDetails">>();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [stripeAccount, setStripeAccount] = useState<StripeAccount>();
  const [quote, setQuote] = useState<StripeExpandedQuote>();

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const [quoteEditingModalVisible, setQuoteEditingModalVisible] = useState(
    false
  );
  const [postmarkModalVisible, setPostmarkModalVisible] = useState(false);
  const [cancelQuoteModalVisible, setCancelQuoteModalVisible] = useState(false);
  const [
    viewGeneratedInvoiceModalVisible,
    setViewGeneratedInvoiceModalVisible,
  ] = useState(false);

  const [acceptQuotePending, setAcceptQuotePending] = useState(false);
  const [cancelQuotePending, setCancelQuotePending] = useState(false);
  const [sendQuotePending, setSendQuotePending] = useState(false);

  const [postmarkResponses, setPostmarkResponses] = useState<
    PostmarkMessageSendingResponse[]
  >([]);

  const customer = useSelector((state) =>
    selectCustomerByStripeCustomerId(state, quote?.customer?.id)
  );

  const runRetrieveStripeAccount = useCallback(async () => {
    try {
      setStripeAccount(await retrieveStripeAccount());
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve Stripe account failed",
      });
    }
  }, []);
  const runRetrieveQuote = useCallback(async () => {
    try {
      setQuote(await retrieveQuote(quoteId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve quote failed",
      });
    }
  }, [quoteId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await runRetrieveStripeAccount();
      await runRetrieveQuote();
      setLoading(false);
    })();
  }, [runRetrieveQuote]);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            action={
              quote?.status === "draft" && {
                onPress: () => setQuoteEditingModalVisible(true),
                text: "Edit",
              }
            }
            navigation={{
              icon: "arrow-back",
              onPress: navigation.goBack,
            }}
            title="Quote details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          refreshControl={
            <RefreshControl
              onRefresh={async () => {
                setRefreshing(true);
                await runRetrieveStripeAccount();
                await runRetrieveQuote();
                setRefreshing(false);
              }}
              refreshing={refreshing}
            />
          }
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              {quote ? (
                <>
                  <Separator size="medium" />
                  <StyledCard>
                    <Separator size="medium" />
                    <StyledText category="h6">
                      {getQuoteTitle(quote)}
                    </StyledText>
                    <View
                      style={{ alignItems: "center", flexDirection: "row" }}
                    >
                      <StyledText>
                        for{" "}
                        <Text category="s1">
                          {formatCurrency(quote.amount_total)}
                        </Text>
                      </StyledText>
                      <StripeQuoteStatusText quote={quote} />
                    </View>
                    <Separator size="medium" />
                    {Boolean(quote.status_transitions.accepted_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(quote.status_transitions.accepted_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        title="Quote was accepted"
                      />
                    )}
                    {Boolean(quote.status_transitions.canceled_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(quote.status_transitions.canceled_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        title="Quote was canceled"
                      />
                    )}
                    {Boolean(quote.status_transitions.finalized_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(quote.status_transitions.finalized_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        showTopConnector={Boolean(
                          quote.status_transitions.accepted_at ||
                            quote.status_transitions.canceled_at
                        )}
                        title="Quote was finalized"
                      />
                    )}
                    <TimelineListItem
                      description={dayjs
                        .unix(quote.created)
                        .format(dateTimeFormat)}
                      disabled
                      showTopConnector={Boolean(
                        quote.status_transitions.accepted_at ||
                          quote.status_transitions.finalized_at ||
                          quote.status_transitions.canceled_at
                      )}
                      title="Quote was created"
                    />
                    <Separator size="medium" />
                    {quote.status === "open" && (
                      <>
                        <StyledDivider />
                        <Separator size="medium" />
                        <StyledButton
                          loading={acceptQuotePending}
                          onPress={async () => {
                            setAcceptQuotePending(true);
                            try {
                              setQuote(await acceptQuote(quoteId));
                              dispatch(setQuotesStale({ quotesStale: true }));
                              dispatch(
                                setInvoicesStale({ invoicesStale: true })
                              );
                              setViewGeneratedInvoiceModalVisible(true);
                            } catch (error) {
                              addNotification({
                                message: error?.message || error,
                                status: "danger",
                                title: "Accept quote failed",
                              });
                            }
                            setAcceptQuotePending(false);
                          }}
                        >
                          Convert to invoice
                        </StyledButton>
                        <Separator size="medium" />
                      </>
                    )}
                  </StyledCard>
                  <Separator />
                  <StyledCard title="Summary">
                    {customer && (
                      <>
                        <ListItem
                          accessoryRight={(imageProps) => (
                            <Icon {...imageProps} name="arrow-ios-forward" />
                          )}
                          onPress={() =>
                            navigation.navigate("CustomerDetails", {
                              customerId: customer.id,
                            })
                          }
                          description={() => (
                            <ListItemText>{customer.name}</ListItemText>
                          )}
                          title={() => (
                            <ListItemText appearance="hint" category="c1">
                              Customer
                            </ListItemText>
                          )}
                        />
                      </>
                    )}
                    {quote.invoice && stripeAccount?.details_submitted && (
                      <>
                        <ListItem
                          accessoryRight={(imageProps) => (
                            <Icon {...imageProps} name="arrow-ios-forward" />
                          )}
                          onPress={() =>
                            navigation.navigate("InvoiceDetails", {
                              invoiceId: quote.invoice.id,
                            })
                          }
                          description={() => (
                            <ListItemText>
                              {getInvoiceTitle(quote)}
                            </ListItemText>
                          )}
                          title={() => (
                            <ListItemText appearance="hint" category="c1">
                              Invoice
                            </ListItemText>
                          )}
                        />
                      </>
                    )}
                    <ListItemContainer>
                      <ListItemText appearance="hint" category="c1">
                        Expiration date
                      </ListItemText>
                      <ListItemText>
                        {dayjs.unix(quote.expires_at).format(dateTimeFormat)}
                      </ListItemText>
                    </ListItemContainer>
                    {Boolean(quote.number) && (
                      <ListItemContainer>
                        <ListItemText appearance="hint" category="c1">
                          Quote number
                        </ListItemText>
                        <ListItemText>{quote.number}</ListItemText>
                      </ListItemContainer>
                    )}
                    <Separator size="small" />
                  </StyledCard>
                  <Separator />
                  {quote.line_items.data.length > 0 && (
                    <StyledCard>
                      <Separator size="medium" />
                      <StyledText category="h6">Items</StyledText>
                      {quote.line_items.data.map((line_item) => (
                        <QuoteLineItemListItem
                          disabled
                          key={line_item.id}
                          line_item={line_item}
                        />
                      ))}
                      <Separator size="medium" />
                    </StyledCard>
                  )}
                  <Separator />
                  <SummaryRow>
                    <SummaryRowNameContainer>
                      <StyledText category="s1">Subtotal</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText category="s1">
                        {formatCurrency(quote.amount_subtotal)}
                      </StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                  {quote.total_details.breakdown.taxes.map((tax) => (
                    <SummaryRow key={JSON.stringify(tax)}>
                      <SummaryRowNameContainer>
                        <StyledText>{`${tax.rate.display_name} (${tax.rate.percentage}%)`}</StyledText>
                      </SummaryRowNameContainer>
                      <SummaryRowValueContainer>
                        <StyledText>{formatCurrency(tax.amount)}</StyledText>
                      </SummaryRowValueContainer>
                    </SummaryRow>
                  ))}
                  <SummaryRow>
                    <SummaryRowNameContainer>
                      <StyledText category="s1">Total</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText category="s1">
                        {formatCurrency(quote.amount_total)}
                      </StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                  {quote.status !== "draft" && (
                    <>
                      <Separator />
                      <StyledQuoteEmailActivityCard quoteId={quoteId} />
                    </>
                  )}
                  <Separator size={48} />
                  {(quote.status === "open" || quote.status === "accepted") && (
                    <>
                      <StyledButton
                        appearance="ghost"
                        loading={sendQuotePending}
                        onPress={async () => {
                          setPostmarkModalVisible(true);
                          setSendQuotePending(true);
                          try {
                            setPostmarkResponses(
                              await sendQuote({
                                quoteId,
                              })
                            );
                          } catch (error) {
                            addNotification({
                              message: error?.message || error,
                              status: "danger",
                              title: "Send quote failed",
                            });
                          }
                          setSendQuotePending(false);
                        }}
                      >
                        Email PDF to customer
                      </StyledButton>
                    </>
                  )}
                  {(quote.status === "draft" || quote.status === "open") && (
                    <>
                      <Separator size="small" />
                      <StyledButton
                        appearance="ghost"
                        loading={cancelQuotePending}
                        onPress={() => setCancelQuoteModalVisible(true)}
                        status="danger"
                      >
                        Cancel quote
                      </StyledButton>
                    </>
                  )}
                  <Separator />
                  <AlertModal
                    confirmText="Cancel quote"
                    isVisible={cancelQuoteModalVisible}
                    message="Are you sure you want to cancel quote? This can't be undone."
                    onClose={() => setCancelQuoteModalVisible(false)}
                    onConfirm={async () => {
                      setCancelQuoteModalVisible(false);
                      setCancelQuotePending(true);
                      try {
                        setQuote(await cancelQuote(quoteId));
                        dispatch(setQuotesStale({ quotesStale: true }));
                      } catch (error) {
                        addNotification({
                          message: error?.message || error,
                          status: "danger",
                          title: "Cancel quote failed",
                        });
                      }
                      setCancelQuotePending(false);
                    }}
                  />
                </>
              ) : (
                <EmptyScreenContainer>
                  <Separator />
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Text appearance="hint" category="s1">
                      No results found
                    </Text>
                  )}
                </EmptyScreenContainer>
              )}
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
      <SendJobSummariesModal
        isVisible={postmarkModalVisible}
        loading={sendQuotePending}
        onClose={() => setPostmarkModalVisible(false)}
        postmarkMessageSendingResponses={postmarkResponses}
      />
      <QuoteEditingModal
        isVisible={quoteEditingModalVisible}
        onClose={async (stale) => {
          setQuoteEditingModalVisible(false);
          if (stale) {
            dispatch(setQuotesStale({ quotesStale: true }));
            setLoading(true);
            setQuote(null);
            await runRetrieveQuote();
            setLoading(false);
          }
        }}
        quote={quote}
      />
      <AlertModal
        cancelText="Dismiss"
        confirmText="View invoice"
        isVisible={viewGeneratedInvoiceModalVisible}
        message="A draft invoice has been generated."
        onClose={() => setViewGeneratedInvoiceModalVisible(false)}
        onConfirm={() => {
          setViewGeneratedInvoiceModalVisible(false);
          navigation.navigate("InvoiceDetails", {
            invoiceId: quote.invoice?.id,
          });
        }}
      />
    </>
  );
};

export default QuoteDetails;
