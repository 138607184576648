import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = (dailyActivityReport) => {
  const { dispatch } = store;
  dispatch(
    setReportPending({
      deleted: true,
      mutation: "deleteDailyActivityReport",
      report: {
        ...dailyActivityReport,
      },
    })
  );
};

const deleteDailyActivityReport = (dailyActivityReport) => {
  dispatchOptimisticResponse(dailyActivityReport);
  client.mutate({
    mutation,
    variables: {
      id: dailyActivityReport.id,
      jobId: dailyActivityReport.jobId,
    },
    errorPolicy: "all",
  });
};

export default deleteDailyActivityReport;
