import API, { GraphQLResult } from "@aws-amplify/api";

import { StripeExpandedQuote } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

const acceptQuote = async (id: string) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        id,
      },
    })) as GraphQLResult<{ acceptQuote: StripeExpandedQuote }>;
    return data.acceptQuote;
  } catch ({ errors }) {
    throw generateErrorMessage("acceptQuote", errors);
  }
};

export default acceptQuote;
