import { useEffect, useState } from "react";
import getMedia from "../../api/functions/getMedia";
import { Media, MediaOnDevice } from "../../types";
import isMediaOnDevice from "../../utils/isMediaOnDevice";

const useMediaUrl = (media?: Media | MediaOnDevice) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (media) {
      (async () => {
        if (isMediaOnDevice(media)) {
          setUrl(media.uri);
        } else {
          try {
            const mediaResponse = await getMedia(media.id);
            setUrl(mediaResponse.url);
          } catch (error) {
            // Do nothing
          }
        }
      })();
    }
  }, [media]);

  return url;
};

export default useMediaUrl;
