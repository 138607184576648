import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { useSelector } from "react-redux";

import DailyActivityReportDetails from "./DailyActivity";
import IncidentReportDetails from "./Incident";
import selectReportByIdAndJobId from "../../store/jobs/selectors/selectReportByIdAndJobId";
import { StackParamList } from "../../types";

const ReportDetails = () => {
  const route = useRoute<RouteProp<StackParamList, "ReportDetails">>();
  const { jobId, reportId } = route.params;
  const report = useSelector((state) =>
    selectReportByIdAndJobId(state, { id: reportId, jobId })
  );
  switch (report && report.__typename) {
    case "DailyActivityReport":
      return <DailyActivityReportDetails dailyActivityReport={report} />;
    case "IncidentReport":
      return <IncidentReportDetails incidentReport={report} />;
    default:
      return null;
  }
};

export default ReportDetails;
