import API, { GraphQLResult } from "@aws-amplify/api";

import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

const deleteInvoice = async (id: string) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        id,
      },
    })) as GraphQLResult<{ deleteInvoice: { deleted: boolean; id: string } }>;
    return data.deleteInvoice;
  } catch ({ errors }) {
    throw generateErrorMessage("deleteInvoice", errors);
  }
};

export default deleteInvoice;
