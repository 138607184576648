import { Divider } from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import MapView, { Region } from "react-native-maps";

import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import MarkerPositioningMapView from "../../map/MarkerPositioningMapView";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import { Coordinates } from "../../../types";
import getDeltaFromZoom from "../../../utils/getDeltaFromZoom";

interface Props extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  initialCoordinates?: Coordinates;
  onSubmit: (coordinates: Coordinates) => void;
}

const initialZoom = 18;
const initialDelta = getDeltaFromZoom(initialZoom);

const styles = StyleSheet.create({
  map: {
    flex: 1,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const LocationSettingModal = ({
  initialCoordinates,
  isVisible,
  onClose,
  onSubmit,
}: Props) => {
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  const [initialRegion, setInitialRegion] = useState<Region>();
  const [region, setRegion] = useState<Region>();
  const mapRef = useRef<MapView>();
  useEffect(() => {
    if (isVisible) {
      if (!initialRegion) {
        setInitialRegion({
          latitude: initialCoordinates.lat,
          latitudeDelta: initialDelta,
          longitude: initialCoordinates.lng,
          longitudeDelta: initialDelta,
        });
      } else {
        mapRef.current?.setCamera({
          center: {
            latitude: initialCoordinates.lat,
            longitude: initialCoordinates.lng,
          },
          zoom: initialZoom,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <HeaderWithTextAction
        action={{
          loading: false,
          onPress: () =>
            onSubmit({
              accuracy: initialCoordinates.accuracy,
              lat: region.latitude,
              lng: region.longitude,
            }),
          text: "Done",
        }}
        navigation={{ icon: "arrow-back-outline", onPress: onClose }}
        title="Set location"
      />
      <Divider />
      {initialRegion && (
        <MarkerPositioningMapView
          initialRegion={initialRegion}
          onRegionChangeComplete={setRegion}
          ref={mapRef}
          style={styles.map}
        />
      )}
    </FullScreenModal>
  );
};

LocationSettingModal.defaultProps = {
  initialCoordinates: undefined,
};

export default LocationSettingModal;
