const finishedJobStatuses = {
  completed: {
    value: "completed",
    text: "Completed",
  },
  dismissed: {
    value: "dismissed",
    text: "Dismissed",
  },
};

export default finishedJobStatuses;
