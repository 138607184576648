import { useEffect } from "react";
import { ColorSchemeName, useColorScheme } from "react-native";

import useDebounce from "../useDebounce";
import useAppActive from "../../device/useAppActive";

const useColorSchemeListener = (
  onChange: (colorScheme: ColorSchemeName) => void
) => {
  const colorScheme = useColorScheme();
  const appActive = useAppActive();
  const debouncedAppActive = useDebounce(appActive, 1000);
  useEffect(() => {
    if (colorScheme && debouncedAppActive) {
      onChange(colorScheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorScheme, debouncedAppActive]);
};

export default useColorSchemeListener;
