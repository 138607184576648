import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectEntities";

const selectById = createSelector(
  [selectEntities, (_, id: string) => id],
  (entities, id) => entities[id]
);

export default selectById;
