import { createSelector } from "@reduxjs/toolkit";

import selectHistoricalSearch from "../selectHistoricalSearch";

const selectQueryDateTimeRange = createSelector(
  [selectHistoricalSearch],
  (historicalSearch) => historicalSearch.queryDateTimeRange
);

export default selectQueryDateTimeRange;
