import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";

const useAsyncStorage = (key: string) => {
  const [value, setValue] = useState<string | null>();

  useEffect(() => {
    (async () => {
      try {
        setValue(await AsyncStorage.getItem(key));
      } catch (error) {
        // error reading value
      }
    })();
  }, []);

  const setItem = async (setItemValue: string) => {
    try {
      await AsyncStorage.setItem(key, setItemValue);
      setValue(setItemValue);
    } catch (error) {
      // Do nothing
    }
  };

  return {
    setItem,
    value,
  };
};

export default useAsyncStorage;
