import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedInvoiceItem } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdateInvoiceItemInput
  extends Pick<Stripe.InvoiceItemUpdateParams, "quantity" | "tax_rates"> {
  id: string;
}

const updateInvoiceItem = async ({
  id,
  quantity,
  tax_rates,
}: UpdateInvoiceItemInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        id,
        quantity,
        tax_rates,
      },
    })) as GraphQLResult<{ updateInvoiceItem: StripeExpandedInvoiceItem }>;
    return data.updateInvoiceItem;
  } catch ({ errors }) {
    throw generateErrorMessage("updateInvoiceItem", errors);
  }
};

export default updateInvoiceItem;
