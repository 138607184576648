import { Button, Divider, Layout, Text } from "@ui-kitten/components";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
import React from "react";
import { StyleSheet, View } from "react-native";

import SimpleModal from "../Simple";
import Separator from "../../buildingBlocks/Separator";
import useFormatDate from "../../../hooks/useFormatDate";
import StaticMapView from "../../map/StaticMapView";
import Marker from "../../markers/Marker";
import UserMapCircle from "../../UserMapCircle";
import { Timecard } from "../../../types";
import getDeltaFromZoom from "../../../utils/getDeltaFromZoom";

momentDurationFormatSetup(moment);

type Props = {
  isVisible: boolean;
  onClose: () => void;
  timeCard?: Timecard;
};

const zoom = 17;
const latLngDelta = getDeltaFromZoom(zoom);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  header: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  label: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  mapContainer: {
    alignSelf: "flex-end",
    borderRadius: 8,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
    width: "50%",
  },
  row: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  text: {
    flex: 1,
    textAlign: "right",
  },
});

const ClockedOutModal = ({ isVisible, onClose, timeCard }: Props) => {
  const { formatDateTime } = useFormatDate();
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={() => null}
      onBackPress={onClose}
    >
      {timeCard && (
        <View onStartShouldSetResponder={() => true}>
          <Separator size="medium" />
          <Text category="h6" style={styles.header}>
            Time card summary
          </Text>
          <Separator />
          <View style={styles.row}>
            <Text appearance="hint" category="p2">
              Started
            </Text>
            <Text category="p2" style={styles.text}>
              {formatDateTime(timeCard.started.at)}
            </Text>
          </View>
          <Separator size="small" />
          <Layout level="2" style={styles.mapContainer}>
            <StaticMapView
              region={{
                latitude: timeCard.started.coordinates.lat,
                latitudeDelta: latLngDelta,
                longitude: timeCard.started.coordinates.lng,
                longitudeDelta: latLngDelta,
              }}
            >
              {timeCard.started.coordinates.accuracy && (
                <UserMapCircle
                  center={{
                    latitude: timeCard.started.coordinates.lat,
                    longitude: timeCard.started.coordinates.lng,
                  }}
                  radius={timeCard.started.coordinates.accuracy}
                />
              )}
              <Marker
                coordinate={{
                  latitude: timeCard.started.coordinates.lat,
                  longitude: timeCard.started.coordinates.lng,
                }}
              />
            </StaticMapView>
          </Layout>
          <Separator size="medium" />
          <View style={styles.row}>
            <Text appearance="hint" category="p2">
              Quit
            </Text>
            <Text category="p2" style={styles.text}>
              {formatDateTime(timeCard.quit.at)}
            </Text>
          </View>
          <Separator size="small" />
          <Layout level="2" style={styles.mapContainer}>
            <StaticMapView
              region={{
                latitude: timeCard.quit.coordinates.lat,
                latitudeDelta: latLngDelta,
                longitude: timeCard.quit.coordinates.lng,
                longitudeDelta: latLngDelta,
              }}
            >
              {timeCard.quit.coordinates.accuracy && (
                <UserMapCircle
                  center={{
                    latitude: timeCard.quit.coordinates.lat,
                    longitude: timeCard.quit.coordinates.lng,
                  }}
                  radius={timeCard.quit.coordinates.accuracy}
                />
              )}
              <Marker
                coordinate={{
                  latitude: timeCard.quit.coordinates.lat,
                  longitude: timeCard.quit.coordinates.lng,
                }}
              />
            </StaticMapView>
          </Layout>
          <Separator size="medium" />
          <Divider style={styles.divider} />
          <Separator size="medium" />
          <View style={styles.row}>
            <Text category="s1">Duration</Text>
            <Text category="s1" style={styles.text}>
              {`${moment
                .duration(moment(timeCard.quit.at).diff(timeCard.started.at))
                .as("hours")
                .toFixed(1)}h`}
            </Text>
          </View>
          <Separator />
          <Button onPress={onClose} size="small" style={styles.button}>
            Dismiss
          </Button>
          <Separator size="small" />
        </View>
      )}
    </SimpleModal>
  );
};

ClockedOutModal.defaultProps = {
  timeCard: undefined,
};

export default ClockedOutModal;
