import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";

const useOnInitialFocus = (onInitialFocus: () => void) => {
  const isFocused = useIsFocused();
  const [onInitialFocusCalled, setOnInitialFocusCalled] = useState(false);
  useEffect(() => {
    if (isFocused && !onInitialFocusCalled) {
      setOnInitialFocusCalled(true);
      onInitialFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, onInitialFocusCalled]);
};

export default useOnInitialFocus;
