import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Layout, Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import deleteTaskCompletionRecord from "../../api/functions/deleteTaskCompletionRecord";
import { Header } from "../../components/buildingBlocks";
import Button from "../../components/buildingBlocks/Button";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import LevelTwoListItem from "../../components/listItems/LevelTwo";
import ReportListItem from "../../components/listItems/Report";
import TaskCompletionRecordListItem from "../../components/listItems/TaskCompletionRecord";
import StaticMapView from "../../components/map/StaticMapView";
import Marker from "../../components/markers/Marker";
import PersonDotMarker from "../../components/markers/PersonDot";
import AlertModal from "../../components/modals/Alert";
import MediaViewerModal from "../../components/modals/MediaViewer";
import taskCompletionModal from "../../components/modals/TaskCompletionModal";
import ScaledImage from "../../components/ScaledImage";
import SiteMapCircle from "../../components/SiteMapCircle";
import UiKittenTouchable from "../../components/UiKittenTouchable";
import useCompanySettings from "../../hooks/useCompanySettings";
import useCompletionRecordUtils from "../../hooks/useCompletionRecordUtils";
import useJob from "../../hooks/useJobUtils";
import useMediaUrl from "../../hooks/useMediaUrl";
import selectUser from "../../store/auth/selectors/selectUser";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectSiteById from "../../store/sites/selectors/selectById";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import selectUserById from "../../store/users/selectors/selectUserById";
import { StackParamList, TaskCompletionRecord } from "../../types";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  image: {
    borderRadius: 8,
    overflow: "hidden",
  },
  imageContainer: {
    marginEnd: 16,
    marginStart: 16,
  },
  mapContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  mapFrame: {
    borderRadius: 8,
    overflow: "hidden",
  },
  root: {
    flex: 1,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const TaskCompletionRecordDetails = () => {
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "TaskCompletionRecordDetails">
  >();
  const route = useRoute<
    RouteProp<StackParamList, "TaskCompletionRecordDetails">
  >();

  const {
    params: { jobId, taskId },
  } = route;

  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const { getTaskCompletionRecordByTaskId, job } = useJob(jobId);
  const site = useSelector((state) => selectSiteById(state, job.siteId));

  const task = job.tasks.find((element) => element.id === taskId);
  const taskCompletionRecordRef = useRef<TaskCompletionRecord>();
  if (getTaskCompletionRecordByTaskId(task.id)) {
    taskCompletionRecordRef.current = getTaskCompletionRecordByTaskId(task.id);
  }
  const taskCompletionRecord = taskCompletionRecordRef.current;

  const { markedCompleted } = useCompletionRecordUtils({
    checklistItem: task,
    completionRecord: taskCompletionRecord,
    disableThumbnail: false,
    jobId,
    readonly: false,
    reportsLoading: false,
  });

  const { id: selfUserId } = useSelector(selectUser);
  const selfUserRole = useSelector(selectSignedInUserRole);

  const { dailyActivityReportsEnabled } = useCompanySettings();

  const mediaUrl = useMediaUrl(taskCompletionRecord?.media);

  const [imageWidth, setImageWidth] = useState<number>();
  const [
    deleteTaskCompletionRecordConfirmationVisible,
    setDeleteTaskCompletionRecordConfirmationVisible,
  ] = useState(false);
  const [mediaViewerVisible, setMediaViewerVisible] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setMapVisible(true), 300);
  }, []);

  return (
    <>
      <Layout level="2" style={styles.root}>
        <HeaderContainer>
          <Header
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Checklist item"
          />
        </HeaderContainer>
        <Divider />
        {taskCompletionRecord && (
          <>
            <ScrollView contentInsetAdjustmentBehavior="automatic">
              <SafeAreaView>
                <Container>
                  {markedCompleted && (
                    <>
                      <Separator size="medium" />
                      <LevelTwoListItem
                        disabled
                        title={`Completed: ${dayjs(
                          taskCompletionRecord.record.at
                        ).format(dateTimeFormat)}`}
                      />
                    </>
                  )}
                  <Separator size="medium" />
                  <StyledCard>
                    <Separator size="medium" />
                    <View style={styles.mapContainer}>
                      <Layout level="2" style={styles.mapFrame}>
                        {mapVisible && (
                          <StaticMapView
                            initialRegion={{
                              latitude: site.coordinates.lat,
                              longitude: site.coordinates.lng,
                              latitudeDelta: 0.01,
                              longitudeDelta: 0.01,
                            }}
                            minZoomLevel={16}
                          >
                            <Marker
                              coordinate={{
                                latitude: site.coordinates.lat,
                                longitude: site.coordinates.lng,
                              }}
                            />
                            <SiteMapCircle
                              center={{
                                latitude: site.coordinates.lat,
                                longitude: site.coordinates.lng,
                              }}
                              radius={site.geofence}
                            />
                            <PersonDotMarker
                              lat={taskCompletionRecord.record.coordinates.lat}
                              lng={taskCompletionRecord.record.coordinates.lng}
                            />
                          </StaticMapView>
                        )}
                      </Layout>
                    </View>
                    <Separator size="small" />
                    <TaskCompletionRecordListItem
                      disableThumbnail
                      jobId={jobId}
                      readonly
                      task={task}
                      taskCompletionRecord={taskCompletionRecord}
                    />
                    <Separator size="small" />
                  </StyledCard>
                  {Boolean(mediaUrl) && (
                    <StyledCard title="Media">
                      <Separator size="medium" />
                      <View
                        onLayout={(event) =>
                          setImageWidth(event.nativeEvent.layout.width)
                        }
                        style={styles.imageContainer}
                      >
                        {typeof imageWidth === "number" && (
                          <UiKittenTouchable
                            onPress={() => setMediaViewerVisible(true)}
                          >
                            <ScaledImage
                              source={{ uri: mediaUrl }}
                              style={styles.image}
                              width={imageWidth}
                            />
                          </UiKittenTouchable>
                        )}
                      </View>
                      <Separator size="medium" />
                    </StyledCard>
                  )}
                  {taskCompletionRecord.dailyActivityReportId &&
                    dailyActivityReportsEnabled && (
                      <>
                        <Separator />
                        <StyledCard title="Report">
                          <ReportListItem
                            id={taskCompletionRecord.dailyActivityReportId}
                            jobId={jobId}
                            onPress={() => {
                              navigation.navigate("ReportDetails", {
                                jobId,
                                reportId:
                                  taskCompletionRecord.dailyActivityReportId,
                              });
                            }}
                          />
                        </StyledCard>
                      </>
                    )}
                  {(taskCompletionRecord.record.by === selfUserId ||
                    userRolePermitted({
                      permittedRoles: ["Admin", "Supervisor"],
                      userRole: selfUserRole,
                    })) && (
                    <>
                      <Separator size={48} />
                      <Button
                        appearance="ghost"
                        onPress={() =>
                          setDeleteTaskCompletionRecordConfirmationVisible(true)
                        }
                        status="danger"
                        style={styles.button}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  <Separator />
                </Container>
              </SafeAreaView>
            </ScrollView>
            {taskCompletionRecord.media && (
              <MediaViewerModal
                isVisible={mediaViewerVisible}
                media={taskCompletionRecord.media}
                onClose={() => setMediaViewerVisible(false)}
              />
            )}
          </>
        )}
      </Layout>
      <AlertModal
        confirmText="Delete"
        isVisible={deleteTaskCompletionRecordConfirmationVisible}
        message="Delete completion record?"
        onCancel={() => setDeleteTaskCompletionRecordConfirmationVisible(false)}
        onClose={() => setDeleteTaskCompletionRecordConfirmationVisible(false)}
        onConfirm={() => {
          deleteTaskCompletionRecord({ taskId }, job);
          setDeleteTaskCompletionRecordConfirmationVisible(false);
          navigation.goBack();
        }}
      />
    </>
  );
};

export default TaskCompletionRecordDetails;
