import { Icon, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { ScrollView, StyleSheet } from "react-native";

import SimpleModal, { Props as SimpleModalProps } from "../Simple";
import Button from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";
import SpinnerAccessory from "../../buildingBlocks/SpinnerAccessory";
import BaseListItem from "../../listItems/Base";
import { PostmarkMessageSendingResponse } from "../../../types";

interface Props
  extends Omit<SimpleModalProps, "onBackdropPress" | "onBackPress"> {
  loading: boolean;
  onClose: () => void;
  postmarkMessageSendingResponses?: Array<PostmarkMessageSendingResponse>;
}

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const PostmarkResponsesModal = ({
  isVisible,
  loading,
  onClose,
  postmarkMessageSendingResponses = undefined,
}: Props) => {
  const theme = useTheme();
  let title = "Empty";
  let description = "No email addresses were found.";
  if (
    Array.isArray(postmarkMessageSendingResponses) &&
    postmarkMessageSendingResponses.length > 0
  ) {
    let errorCount = 0;
    postmarkMessageSendingResponses.forEach(
      (postmarkMessageSendingResponse) => {
        if (postmarkMessageSendingResponse.errorCode) {
          errorCount += 1;
        }
      }
    );
    if (errorCount > 0) {
      title = "Warning";
      description =
        "There was an error sending an email to one or more addresses. Check below for details.";
    } else {
      title = "Success";
      description = "The following addresses have been sent emails.";
    }
  }
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={() => null}
      onBackPress={onClose}
      style={{ height: "50%" }}
    >
      <Separator size="small" />
      {loading ? (
        <BaseListItem accessoryLeft={SpinnerAccessory} title="Please wait..." />
      ) : (
        <>
          <Separator size="small" />
          <Text category="h6" style={styles.text}>
            {title}
          </Text>
          <Text style={styles.text}>{description}</Text>
        </>
      )}
      <Separator size="small" />
      <ScrollView>
        {Array.isArray(postmarkMessageSendingResponses) &&
          postmarkMessageSendingResponses.length > 0 &&
          postmarkMessageSendingResponses.map(
            (postmarkMessageSendingResponse, index) => (
              <BaseListItem
                accessoryLeft={(imageProps) => (
                  <Icon
                    {...imageProps}
                    fill={
                      theme[
                        postmarkMessageSendingResponse.errorCode
                          ? "color-danger-default"
                          : "color-success-default"
                      ]
                    }
                    name={
                      postmarkMessageSendingResponse.errorCode
                        ? "close-outline"
                        : "checkmark-outline"
                    }
                  />
                )}
                description={postmarkMessageSendingResponse.message}
                disabled
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={postmarkMessageSendingResponse.to}
              />
            )
          )}
      </ScrollView>
      <Separator size="small" />
      <Button
        appearance="ghost"
        onPress={onClose}
        size="small"
        style={styles.button}
      >
        Close
      </Button>
      <Separator size="small" />
    </SimpleModal>
  );
};

export default PostmarkResponsesModal;
