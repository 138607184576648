import { MaterialIcons } from "@expo/vector-icons";
import { IconProps } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

const MaterialIcon = ({ name, style }: IconProps) => {
  const { height, tintColor, ...iconStyle } = StyleSheet.flatten(style);
  return (
    <MaterialIcons
      color={tintColor}
      name={name}
      size={height}
      style={iconStyle}
    />
  );
};

const IconProvider = (name: PropertyKey) => ({
  toReactElement: (props) => MaterialIcon({ name, ...props }),
});

const MaterialIconsPack = {
  icons: new Proxy(
    {},
    {
      get: (target, name) => IconProvider(name),
    }
  ),
  name: "MaterialIcons",
};

export default MaterialIconsPack;
