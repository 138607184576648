const sortGroups = (a, b) => {
  const nameComparison = a.name.localeCompare(b.name);
  const createdAtComparison = a.created.at.localeCompare(b.created.at);
  if (nameComparison !== 0) {
    return nameComparison;
  }
  return createdAtComparison;
};

export default sortGroups;
