import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Input,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Keyboard, ScrollView, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";

import listTaxRates from "../../api/functions/listTaxRates";
import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import TaxRateListItem from "../../components/listItems/TaxRate";
import CreateTaxRateModal from "../../components/modals/CreateTaxRate";
import { StackParamList, StripeTaxRate } from "../../types";

const Root = styled(Layout)`
  flex: 1;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    maxWidth: 960,
    marginEnd: "auto",
    marginStart: "auto",
    width: "100%",
  },
});

const listTaxRatesErrorTitle = "List tax rates failed";

const TaxRates = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "TaxRates">
  >();
  const { params } = useRoute<RouteProp<StackParamList, "TaxRates">>();
  const stale = params?.stale;
  const theme = useTheme();

  const isFocused = useIsFocused();

  const [active, setActive] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [taxRates, setTaxRates] = useState<Array<StripeTaxRate>>();
  const [searchText, setSearchText] = useState("");
  const [createTaxRateModalVisible, setCreateTaxRateModalVisible] = useState<
    boolean
  >(false);

  const runListTaxRates = useCallback(async () => {
    try {
      let data = [];
      let startingAfter: string;
      do {
        const listTaxRatesResult = await listTaxRates({
          active,
          starting_after: startingAfter,
        });
        data = data.concat(listTaxRatesResult.data);
        startingAfter = listTaxRatesResult.has_more
          ? listTaxRatesResult.data[listTaxRatesResult.data.length - 1].id
          : null;
      } while (startingAfter);
      setTaxRates(data);
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: listTaxRatesErrorTitle,
      });
    }
  }, [active]);
  const navigateToTaxRateDetails = useCallback(
    (taxRate: StripeTaxRate) =>
      navigation.navigate("TaxRateDetails", { taxRateId: taxRate.id }),
    []
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      setTaxRates(null);
      await runListTaxRates();
      setLoading(false);
    })();
  }, [runListTaxRates]);
  useEffect(() => {
    if (isFocused && stale) {
      (async () => {
        setLoading(true);
        setTaxRates(null);
        await runListTaxRates();
        setLoading(false);
      })();
    }
  }, [isFocused, stale]);

  const filteredTaxRates = taxRates?.filter((taxRate) => {
    if (taxRate.percentage === 0) {
      return false;
    }
    return (
      !searchText ||
      taxRate.percentage
        .toString()
        .toLowerCase()
        .includes(searchText.trim().toLowerCase())
    );
  });

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <Header
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Tax rates"
          />
          <Separator size="small" />
          <StyledInput
            onChangeText={setSearchText}
            placeholder="Search..."
            value={searchText}
          />
          <Separator size="small" />
        </HeaderContainer>
        <Divider />
        <FlatList
          contentContainerStyle={[
            styles.flatListContentContainerStyle,
            {
              paddingBottom: insets.bottom,
              paddingEnd: insets.right,
              paddingStart: insets.left,
            },
          ]}
          data={filteredTaxRates}
          ItemSeparatorComponent={() => (
            <Layout>
              <StyledDivider />
            </Layout>
          )}
          keyExtractor={(item) => item.id}
          ListEmptyComponent={
            <ListEmptyContainer>
              <Separator />
              {loading ? (
                <Spinner />
              ) : (
                <Text appearance="hint" category="s1">
                  No results found
                </Text>
              )}
            </ListEmptyContainer>
          }
          ListHeaderComponent={
            <>
              <ScrollView
                contentContainerStyle={styles.scrollViewContentContainerStyle}
                horizontal
              >
                <StyledButton
                  appearance={active ? "filled" : "outline"}
                  onPress={() => setActive(true)}
                  status="control"
                >
                  Available
                </StyledButton>
                <Separator horizontal size="small" />
                <StyledButton
                  appearance={active ? "outline" : "filled"}
                  onPress={() => setActive(false)}
                  status="control"
                >
                  Archived
                </StyledButton>
              </ScrollView>
            </>
          }
          onRefresh={async () => {
            setRefreshing(true);
            await runListTaxRates();
            setRefreshing(false);
          }}
          refreshing={refreshing}
          renderItem={({ item: taxRate }) => (
            <Layout>
              <TaxRateListItem
                onPress={navigateToTaxRateDetails}
                taxRate={taxRate}
              />
            </Layout>
          )}
        />
        <FAB
          icon="plus"
          onPress={() => {
            Keyboard.dismiss();
            setCreateTaxRateModalVisible(true);
          }}
          style={{
            backgroundColor: theme["color-primary-default"],
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: insets.bottom,
          }}
        />
      </Root>
      <CreateTaxRateModal
        isVisible={createTaxRateModalVisible}
        onClose={() => setCreateTaxRateModalVisible(false)}
        onSuccess={async () => {
          setCreateTaxRateModalVisible(false);
          setLoading(true);
          setTaxRates(null);
          await runListTaxRates();
          setLoading(false);
        }}
      />
    </>
  );
};

export default TaxRates;
