import { Text, TextProps } from "@ui-kitten/components";
import React from "react";
import { useSelector } from "react-redux";

import selectUserById from "../../store/users/selectors/selectUserById";
import getUserFullName from "../../utils/getUserFullName";

interface Props extends TextProps {
  userId: string;
}

const UserFullNameText = ({ userId, ...props }: Props) => {
  const user = useSelector((state) => selectUserById(state, userId));
  return <Text {...props}>{getUserFullName(user)}</Text>;
};

export default UserFullNameText;
