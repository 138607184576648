import { ListItemProps, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import Separator from "../../buildingBlocks/Separator";

import ListItem from "../Base";

interface TimelineListItemProps
  extends Pick<
    ListItemProps,
    "accessoryRight" | "description" | "disabled" | "title"
  > {
  showBottomConnector?: boolean;
  showTopConnector?: boolean;
}

const styles = StyleSheet.create({
  accessoryLeftContainer: {
    alignItems: "center",
    marginBottom: -8,
    marginEnd: 8,
    marginStart: 8,
    marginTop: -8,
    width: 24,
  },
  connector: {
    flex: 1,
    width: 1,
  },
  dot: {
    height: 12,
    borderRadius: 12,
    borderWidth: 2,
    width: 12,
  },
});

const TimelineListItem = ({
  description,
  disabled,
  showBottomConnector = false,
  showTopConnector = false,
  title,
}: TimelineListItemProps) => {
  const theme = useTheme();
  return (
    <ListItem
      accessoryLeft={() => (
        <View style={styles.accessoryLeftContainer}>
          <View
            style={[
              styles.connector,
              showTopConnector && { backgroundColor: theme["text-hint-color"] },
            ]}
          />
          <Separator size="small" />
          <View
            style={[styles.dot, { borderColor: theme["text-hint-color"] }]}
          />
          <Separator size="small" />
          <View
            style={[
              styles.connector,
              showBottomConnector && {
                backgroundColor: theme["text-hint-color"],
              },
            ]}
          />
        </View>
      )}
      description={description}
      disabled={disabled}
      title={title}
    />
  );
};

export default TimelineListItem;
