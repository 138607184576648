const timeZones = {
  "America/New_York": "America/New_York",
  "America/Chicago": "America/Chicago",
  "America/Denver": "America/Denver",
  "America/Phoenix": "America/Phoenix",
  "America/Los_Angeles": "America/Los_Angeles",
  "America/Anchorage": "America/Anchorage",
  "Pacific/Honolulu": "Pacific/Honolulu",
};

export default timeZones;
