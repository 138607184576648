import gql from "graphql-tag";

import { stripeInvoiceFragment } from "../../../fragments";

const listInvoices = gql`
  query listInvoices(
    $customer: ID
    $due_date: Int
    $starting_after: ID
    $status: StripeInvoiceStatus
  ) {
    listInvoices(
      customer: $customer
      due_date: $due_date
      starting_after: $starting_after
      status: $status
    ) {
      data {
        ...stripeInvoiceFragment
      }
      has_more
    }
  }
  ${stripeInvoiceFragment}
`;

export default listInvoices;
