import { createSelector } from "@reduxjs/toolkit";

import selectReportEntitiesByJobId from "../selectReportEntitiesByJobId";

const selectReportByIdAndJobId = createSelector(
  [selectReportEntitiesByJobId, (_, { id }: { id: string }) => id],
  (entities, id) => entities && entities[id]
);

export default selectReportByIdAndJobId;
