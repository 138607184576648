import { Text, useTheme } from "@ui-kitten/components";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import ButtonListItem from "../listItems/Button";
import selectTime from "../../store/settings/selectors/selectTime";
import DateAndTimePickerModal from "../modals/DateAndTimePicker";

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = StyleSheet.create({
  dateTime: {
    marginEnd: 8,
  },
  divider: {
    marginHorizontal: 16,
  },
  root: {
    flexDirection: "row",
    marginEnd: 8,
  },
  progressBar: {
    marginHorizontal: 16,
  },
  progressBarContainer: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
  },
});

interface Props {
  disabled?: boolean;
  divider?: React.ReactNode;
  endDateTime?: string;
  endDateTimeError?: boolean;
  endTitle?: string;
  onValueChange: ({
    startDateTime,
    endDateTime,
  }: {
    startDateTime: string;
    endDateTime: string;
  }) => void;
  startDateTime?: string;
  startDateTimeError?: boolean;
  startTitle?: string;
  loading?: boolean;
}

const BasicTimeRangePicker = ({
  disabled,
  divider,
  endDateTime,
  endDateTimeError,
  endTitle,
  onValueChange,
  startDateTime,
  startDateTimeError,
  startTitle,
  loading,
}: Props) => {
  const [startPickerVisible, setStartPickerVisible] = useState(false);
  const [endPickerVisible, setEndPickerVisible] = useState(false);
  const theme = useTheme();
  const timeSettings = useSelector(selectTime);

  const getFormattedStartDateTime = () => {
    if (startDateTime) {
      return dayjs(startDateTime).format(timeSettings.dateTimeFormat);
    }
    return "Select a date and time";
  };

  const getFormattedEndDateTime = () => {
    if (endDateTime) {
      if (
        startDateTime &&
        dayjs(startDateTime).isSame(dayjs(endDateTime), "day")
      ) {
        return dayjs(endDateTime).format(timeSettings.timeFormat);
      }
      return dayjs(endDateTime).format(timeSettings.dateTimeFormat);
    }
    return "Select a date and time";
  };
  return (
    <>
      <View>
        <ButtonListItem
          accessoryRight={() => (
            <Text
              status={startDateTimeError ? "danger" : undefined}
              style={styles.dateTime}
            >
              {getFormattedStartDateTime()}
            </Text>
          )}
          data-test="startDateTimeButtonInTimeRangePicker"
          disabled={disabled || loading}
          onPress={() => setStartPickerVisible(true)}
          title={startTitle}
          icon="calendar"
        />
        {divider}
        <ButtonListItem
          accessoryRight={() => (
            <Text
              status={
                endDateTimeError ||
                dayjs(endDateTime).isBefore(dayjs(startDateTime))
                  ? "danger"
                  : undefined
              }
              style={styles.dateTime}
            >
              {getFormattedEndDateTime()}
            </Text>
          )}
          data-test="endDateTimeButtonInTimeRangePicker"
          disabled={disabled || loading}
          onPress={() => setEndPickerVisible(true)}
          title={endTitle}
          icon="calendar"
        />
        <View style={styles.progressBarContainer}>
          {/* @ts-ignore */}
          <ProgressBar
            indeterminate={loading}
            visible={loading}
            color={theme["color-primary-default"]}
            style={styles.progressBar}
          />
        </View>
      </View>
      <DateAndTimePickerModal
        dateTime={
          startDateTime ? dayjs(startDateTime).toDate() : dayjs().toDate()
        }
        visible={startPickerVisible}
        onClose={() => setStartPickerVisible(false)}
        onDone={(date) => {
          setStartPickerVisible(false);
          onValueChange({
            startDateTime: date.toISOString(),
            endDateTime,
          });
        }}
      />
      <DateAndTimePickerModal
        dateTime={endDateTime ? dayjs(endDateTime).toDate() : dayjs().toDate()}
        visible={endPickerVisible}
        onClose={() => setEndPickerVisible(false)}
        onDone={(date) => {
          setEndPickerVisible(false);
          onValueChange({
            startDateTime,
            endDateTime: date.toISOString(),
          });
        }}
      />
    </>
  );
};

BasicTimeRangePicker.defaultProps = {
  disabled: false,
  divider: undefined,
  endDateTime: undefined,
  endDateTimeError: false,
  endTitle: "Ends",
  startDateTime: undefined,
  startDateTimeError: false,
  startTitle: "Starts",
  loading: false,
};

export default BasicTimeRangePicker;
