import { Layout, Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { SectionList, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { useSelector } from "react-redux";
import styled from "styled-components/native";
import Separator from "../buildingBlocks/Separator";

import ScheduleJobListItem, {
  height as listItemHeight,
} from "../listItems/ScheduleJob";
import momentFormats from "../../constants/momentFormats";
import selectHistoricalSearch from "../../store/historicalSearch/selectors/selectHistoricalSearch";
import selectScheduleJobs from "../../store/jobs/selectors/selectScheduleJobs";
import formatSectionListData from "../../utils/formatSectionListData";

interface ScheduleViewProps {
  onJobPress: (jobId: string) => void;
  onRefresh: () => void;
  refreshing: boolean;
}

const styles = StyleSheet.create({
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  sectionListContent: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const StyledScheduleJobListItem = styled(ScheduleJobListItem)`
  margin-left: 16px;
  margin-right: 16px;
`;

const ScheduleView = ({
  onJobPress,
  onRefresh,
  refreshing,
}: ScheduleViewProps) => {
  const insets = useSafeAreaInsets();

  const historicalSearch = useSelector(selectHistoricalSearch);
  const jobs = useSelector(selectScheduleJobs);

  const sectionListRef = useRef<SectionList>();

  let sections;
  if (historicalSearch.queryDateTimeRange) {
    const sectionListData = formatSectionListData({
      data: jobs,
      startDateTimeExtractor: (item) => item.startDateTime,
      idExtractor: (item) => item.id,
      sortCompareFunction: (jobA, jobB) =>
        jobA.startDateTime.localeCompare(jobB.startDateTime),
    });
    sections = sectionListData.sections;
  } else {
    sections = [];
  }

  useEffect(() => {
    const formattedDate = dayjs(historicalSearch.selectedDate).format(
      momentFormats.dateFormat
    );
    sections.forEach((item, index) => {
      if (item.title === formattedDate) {
        sectionListRef.current.scrollToLocation({
          sectionIndex: index,
          itemIndex: 0,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicalSearch.selectedDate]);

  return (
    <SectionList
      contentContainerStyle={[
        styles.sectionListContent,
        { paddingBottom: 16 + insets.bottom, paddingTop: 16 },
      ]}
      getItemLayout={sectionListGetItemLayout({
        getItemHeight: () => listItemHeight,
        getSeparatorHeight: () => 1,
        getSectionHeaderHeight: () => 36,
      })}
      ItemSeparatorComponent={() => <Separator size="medium" />}
      keyExtractor={(item) => item}
      ListEmptyComponent={
        <View style={styles.listEmptyComponent}>
          <Text category="h3">No jobs found</Text>
        </View>
      }
      onRefresh={onRefresh}
      ref={sectionListRef}
      refreshing={refreshing}
      renderItem={({ item }) => (
        <StyledScheduleJobListItem jobId={item} onPress={onJobPress} />
      )}
      renderSectionHeader={({ section: { data, title } }) => (
        <Layout
          level="3"
          style={{
            borderRadius: 8,
            marginEnd: 16,
            marginStart: 16,
            paddingHorizontal: 16,
            paddingVertical: 8,
          }}
        >
          <Text>{`${title} (${data.length})`}</Text>
        </Layout>
      )}
      sections={sections}
      SectionSeparatorComponent={() => <Separator size="medium" />}
      stickySectionHeadersEnabled
    />
  );
};

export default ScheduleView;
