import { Button, Text } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import * as yup from "yup";

import SimpleModal from "../Simple";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import adminSetCompanyUserPassword from "../../../api/functions/adminSetCompanyUserPassword";
import PasswordInput from "../../inputs/Password";

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  visibilityButton: {
    minHeight: 36,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});

interface AdminSetPasswordModalProps {
  isVisible: boolean;
  onCancelPress: () => void;
  onPasswordChange: () => void;
  userId: string;
}

const AdminSetPasswordModal = ({
  isVisible,
  onCancelPress,
  onPasswordChange,
  userId,
}: AdminSetPasswordModalProps) => {
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      adminSetCompanyUserPassword({ id: userId, password: values.password });
      addNotification({
        message: "Password has been changed",
        title: "Success",
        status: "success",
      });
      onPasswordChange();
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters.")
        .required("Password is required."),
    }),
  });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackPress={onCancelPress}
      onBackdropPress={onCancelPress}
    >
      <View onStartShouldSetResponder={() => true}>
        <Separator size="medium" />
        <Text style={styles.text}>Set a new password for this user.</Text>
        <Separator />
        <PasswordInput
          data-test="adminSetUserPasswordInput"
          onBlur={() => formik.setFieldTouched("password")}
          onChangeText={formik.handleChange("password")}
          value={formik.values.password}
          status={formik.errors.password && formik.touched.password && "danger"}
          caption={formik.touched.password && formik.errors.password}
          style={styles.input}
        />
        <Separator />
        <Button
          data-test="changePasswordSubmitButton"
          onPress={() => formik.handleSubmit()}
          style={styles.button}
        >
          Change password
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          onPress={onCancelPress}
          style={styles.button}
        >
          Cancel
        </Button>
        <Separator size="small" />
      </View>
    </SimpleModal>
  );
};

export default AdminSetPasswordModal;
