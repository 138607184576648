import store from "../../../store";
import { setInboxItemAsync } from "../../../store/inbox/slice";
import { InboxItem } from "../../../types";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import mutation from "./graphql";

interface UpdateInboxItemInput {
  description?: string;
  metadata: {
    customerId: string;
    dateTime: string;
    userId: string;
  };
  title: string;
}

const dispatchOptimisticResponse = (
  { description, metadata, title }: UpdateInboxItemInput,
  inboxItem: InboxItem
) => {
  const { dispatch } = store;
  const timestamp = new Date().toISOString();
  dispatch(
    setInboxItemAsync.pending({
      inboxItem: {
        ...inboxItem,
        description,
        metadata: {
          customerId: metadata.customerId,
          dateTime: metadata.dateTime,
          userId: metadata.userId,
        },
        title,
        syncDateTime: timestamp,
      },
      mutation: "updateInboxItem",
    })
  );
};

const updateInboxItem = async (
  { description, metadata, title }: UpdateInboxItemInput,
  inboxItem: InboxItem
) => {
  dispatchOptimisticResponse({ description, metadata, title }, inboxItem);
  client.mutate({
    mutation,
    variables: deepCopy({
      description,
      id: inboxItem.id,
      metadata,
      title,
    }),
  });
};

export default updateInboxItem;
