import { ProgressBar, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import useMarkerProgress from "./useMarkerProgress";

const styles = StyleSheet.create({
  clickable: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "white",
    fontWeight: "bold",
  },
  markerContainer: {
    borderRadius: 10,
    padding: 4,
    borderWidth: 2,
    borderColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Marker = ({ jobId }: { jobId: string }) => {
  const job = useSelector((state) => selectJobById(state, jobId));
  const {
    status: { colors },
  } = job;
  const theme = useTheme();
  const {
    isSomeProgressDetails,
    isInProgress,
    completedTasks,
    percentComplete,
  } = useMarkerProgress({ jobId });
  return (
    <View
      style={[
        styles.markerContainer,
        { backgroundColor: theme[colors.default] },
        { width: isSomeProgressDetails ? 50 : 20 },
        { minHeight: isSomeProgressDetails ? undefined : 20 },
      ]}
    >
      {job.tasks.length > 0 && isInProgress && (
        <Text numberOfLines={1} style={styles.text}>
          {`${completedTasks.length} of ${job.tasks.length}`}
        </Text>
      )}
      {!isNaN(percentComplete) && isInProgress && (
        <ProgressBar
          progress={1 - percentComplete}
          status={"control"}
          animating={false}
          style={{
            marginTop: 2,
            backgroundColor: "black",
            height: 8,
            width: "100%",
          }}
        />
      )}
    </View>
  );
};

export default Marker;
