import React from "react";
import { Button } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";

import FutureStatusJobCard from "../jobStatusCards/FutureStatusJobCard";
import InProgressStatusJobCard from "../jobStatusCards/InProgressStatusJobCard";
import LateStatusJobCard from "../jobStatusCards/LateStatusJobCard";
import ImmediateStatusJobCard from "../jobStatusCards/ImmediateStatusJobCard";
import mapFilterConstants from "../../constants/mapFilter";
import companyUserRoles from "../../constants/companyUserRoles";
import selectUser from "../../store/auth/selectors/selectUser";
import {
  setFilterAssignees,
  setFilterStatus,
  setFilterUnassignedOnly,
} from "../../store/jobs/slice";
import { Separator } from "../buildingBlocks";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectMapFilter from "../../store/jobs/selectors/selectMapFilter";

const styles = StyleSheet.create({
  additionalContainer: {
    flexDirection: "row",
    marginHorizontal: 8,
    marginBottom: 8,
  },
  button: {
    borderRadius: 100,
    marginEnd: 8,
  },
  statusCardContainer: {
    flex: 1,
  },
  statusCardsContainer: {
    flexDirection: "row",
  },
});

interface FilterBarProps {
  callback?: () => void;
  onViewUnassigned?: () => void;
}

const FilterBar = ({ callback, onViewUnassigned }: FilterBarProps) => {
  const { group } = useSelector(selectUser);
  const dispatch = useDispatch();
  const { unassigned } = useSelector(selectMapJobs);
  const { unassignedOnly } = useSelector(selectMapFilter);

  const handleViewUnassignedJobsPress = () => {
    batch(() => {
      dispatch(setFilterUnassignedOnly({ unassignedOnly: true }));
      dispatch(setFilterStatus({ status: null }));
      dispatch(
        setFilterAssignees({
          assignees: mapFilterConstants.visibility.all.value,
        })
      );
    });
    onViewUnassigned();
  };

  const handleViewAllJobs = () => {
    batch(() => {
      dispatch(setFilterUnassignedOnly({ unassignedOnly: false }));
      dispatch(setFilterStatus({ status: null }));
      dispatch(
        setFilterAssignees({
          assignees: mapFilterConstants.visibility.all.value,
        })
      );
    });
  };

  const getViewUnassignedText = () => {
    const unassignedCount = unassigned.length;
    return `View ${unassignedCount} unassigned job${
      unassignedCount === 1 ? "" : "s"
    }`;
  };

  return (
    <View>
      <Separator size="medium" />
      <View style={styles.statusCardsContainer}>
        <Separator horizontal size="medium" />
        <View style={styles.statusCardContainer}>
          <FutureStatusJobCard callback={callback} />
        </View>
        <Separator horizontal size="small" />
        <View style={styles.statusCardContainer}>
          <InProgressStatusJobCard callback={callback} />
        </View>
        <Separator horizontal size="small" />
        <View style={styles.statusCardContainer}>
          <LateStatusJobCard callback={callback} />
        </View>
        <Separator horizontal size="small" />
        <View style={styles.statusCardContainer}>
          <ImmediateStatusJobCard callback={callback} />
        </View>
        <Separator horizontal size="medium" />
      </View>
      <Separator size="small" />
      {group !== companyUserRoles.EMPLOYEE && (
        <>
          <View style={styles.additionalContainer}>
            {unassigned.length !== 0 && !unassignedOnly && (
              <>
                <Separator size="small" />
                <Button
                  style={styles.button}
                  size="small"
                  onPress={handleViewUnassignedJobsPress}
                  status="basic"
                >
                  {getViewUnassignedText()}
                </Button>
              </>
            )}
            {unassignedOnly && (
              <>
                <Separator size="small" />
                <Button
                  style={styles.button}
                  size="small"
                  onPress={handleViewAllJobs}
                  status="basic"
                >
                  View all jobs
                </Button>
              </>
            )}
          </View>
        </>
      )}
    </View>
  );
};

FilterBar.defaultProps = {
  callback: () => {},
  onViewUnassigned: () => {},
};

export default FilterBar;
