/* eslint-disable react/jsx-props-no-spreading */
import { Divider } from "@ui-kitten/components";
import React, { forwardRef, PropsWithChildren, useRef } from "react";
import { StyleSheet, View } from "react-native";
import MapView, { MapViewProps } from "react-native-maps";
import styled from "styled-components/native";

import IconButton from "../../IconButton";
import DefaultMapView from "../DefaultMapView";
import AspectRatioView from "../../AspectRatioView";
import UiKittenTouchable from "../../UiKittenTouchable";

interface StaticMapViewProps extends PropsWithChildren<MapViewProps> {
  aspectRatio?: { height: number; width: number };
  onPress?: () => void;
}

const styles = StyleSheet.create({
  map: {
    flex: 1,
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
  },
});

const ZoomButtonsContainer = styled.View`
  right: 8px;
  position: absolute;
  top: 8px;
`;

const ZoomInIconButton = styled(IconButton)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const ZoomOutIconButton = styled(IconButton)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const StaticMapView = ({
  aspectRatio = {
    height: 9,
    width: 16,
  },
  onPress,
  style,
  ...props
}: StaticMapViewProps) => {
  const ref = useRef<MapView>();
  const renderMap = () => (
    <DefaultMapView
      {...props}
      pitchEnabled={false}
      ref={ref}
      rotateEnabled={false}
      scrollEnabled={false}
      style={styles.map}
      zoomEnabled={false}
      zoomTapEnabled={false}
    />
  );
  return (
    <View style={style}>
      <AspectRatioView height={aspectRatio.height} width={aspectRatio.width}>
        {renderMap()}
        {onPress ? (
          <UiKittenTouchable onPress={onPress} style={styles.overlay} />
        ) : (
          <View style={styles.overlay} />
        )}
        <ZoomButtonsContainer>
          <ZoomInIconButton
            name="plus"
            onPress={async () => {
              const camera = await ref.current.getCamera();
              if (camera) {
                ref.current.animateCamera({
                  ...camera,
                  zoom: camera.zoom + 1,
                });
              }
            }}
            status="basic"
          />
          <Divider />
          <ZoomOutIconButton
            name="minus"
            onPress={async () => {
              const camera = await ref.current.getCamera();
              if (camera) {
                ref.current.animateCamera({
                  ...camera,
                  zoom: camera.zoom - 1,
                });
              }
            }}
            status="basic"
          />
        </ZoomButtonsContainer>
      </AspectRatioView>
    </View>
  );
};

export default StaticMapView;
