import { Icon, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";
import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";

import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import { Reminder } from "../../../types";
import Separator from "../../buildingBlocks/Separator";
import UiKittenTouchable from "../../UiKittenTouchable";
import ListItem, { ListItemProps } from "../Base";

interface ReminderListItemProps
  extends Pick<ListItemProps, "accessoryLeft" | "accessoryRight"> {
  onPress?: (reminder: Reminder) => void;
  reminder: Reminder;
  showsCustomer?: boolean;
  showsType?: boolean;
}

const TypeHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding-left: 26px;
`;

const TextContainer = styled.View`
  padding-left: 8px;
  padding-right: 8px;
`;

const ReminderListItem = ({
  accessoryLeft,
  accessoryRight,
  onPress,
  reminder,
  showsCustomer = true,
  showsType = false,
}: ReminderListItemProps) => {
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const customer = useSelector((state) =>
    selectCustomerById(state, reminder.metadata?.customerId)
  );

  return (
    <UiKittenTouchable onPress={() => onPress(reminder)}>
      {showsType && (
        <View>
          <Separator size="small" />
          <TypeHeaderContainer>
            <Icon
              name="bell-outline"
              style={{
                height: 18,
                tintColor: theme["text-hint-color"],
                width: 18,
              }}
            />
            <Separator horizontal size="small" />
            <Text appearance="hint" category="label">
              Reminder
            </Text>
          </TypeHeaderContainer>
        </View>
      )}
      <ListItem
        accessoryLeft={accessoryLeft}
        accessoryRight={accessoryRight}
        disabled
        title={() => (
          <TextContainer>
            <Text category="s1">{reminder.title}</Text>
            <Text appearance="hint">
              {dayjs(reminder.metadata.dateTime).format(dateTimeFormat)}
            </Text>
            {showsCustomer && <Text appearance="hint">{customer.name}</Text>}
          </TextContainer>
        )}
      />
    </UiKittenTouchable>
  );
};

export default ReminderListItem;
