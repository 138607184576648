import mutation from "./graphql";
import client from "../../client";
import mapToCoordinatesInput from "../../utils/mapToCoordinatesInput";
import store from "../../../store";
import { setUserPending } from "../../../store/users/slice";
import { CompanyUser, Coordinates } from "../../../types";

type ClockInInput = {
  coordinates: Coordinates;
  timestamp: string;
};

const dispatchOptimisticResponse = (
  { coordinates, timestamp }: ClockInInput,
  user: CompanyUser
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setUserPending({
      mutation: "clockIn",
      user: {
        ...user,
        syncDateTime: new Date().toISOString(),
        clockedIn: {
          at: timestamp,
          by: userId,
          coordinates,
        },
      },
    })
  );
};

const clockIn = (
  { coordinates, timestamp }: ClockInInput,
  user: CompanyUser
) => {
  dispatchOptimisticResponse({ coordinates, timestamp }, user);

  // Intentionally ignore promise
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      coordinates: mapToCoordinatesInput(coordinates),
      timestamp,
    },
  });
};

export default clockIn;
