import { Layout } from "@ui-kitten/components";
import React, { ReactNode } from "react";
import { SafeAreaView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import Container from "../Container";

interface HeaderContainerProps {
  children?: ReactNode;
}

const HeaderContainer = ({ children }: HeaderContainerProps) => {
  const insets = useSafeAreaInsets();
  return (
    <Layout
      style={{
        paddingTop: insets.top,
      }}
    >
      <SafeAreaView>
        <Container>{children}</Container>
      </SafeAreaView>
    </Layout>
  );
};

export default HeaderContainer;
