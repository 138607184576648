import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import uuidv4 from "uuid/v4";
import * as yup from "yup";

import MainScreen from "./MainScreen";
import UsersSelectScreen from "./UsersSelectScreen";
import FullScreenModal from "../FullScreen";
import useModalScreens from "../useModalScreens";
import { addNotification } from "../../InAppNotifications";
import adminCreateCompanyGroup from "../../../api/functions/adminCreateCompanyGroup";
import adminUpdateCompanyGroup from "../../../api/functions/adminUpdateCompanyGroup";
import selectUsers from "../../../store/users/selectors/selectUsers";
import sortUsers from "../../../utils/sortUsers";

const screensConfig = [["main"], ["usersSelect"]];

const GroupEditingModal = ({ isVisible, onClose, group }) => {
  const users = useSelector(selectUsers);
  const formik = useFormik({
    initialValues: {
      name: "",
      members: [],
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (group) {
        adminUpdateCompanyGroup(
          {
            id: values.id,
            name: values.name,
            members: values.members,
          },
          group
        );
        addNotification({
          status: "success",
          title: "Group updated",
        });
      } else {
        adminCreateCompanyGroup({
          id: uuidv4(),
          members: values.members,
          name: values.name,
        });
        addNotification({
          status: "success",
          title: "Group created",
        });
      }
      onClose();
    },
  });
  const { navigation, jumpTo, goBack } = useModalScreens(screensConfig);

  useEffect(() => {
    if (isVisible) {
      if (group) {
        formik.resetForm({
          values: {
            id: group.id,
            name: group.name,
            members: group.members,
          },
        });
      } else {
        formik.resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const screen = navigation.screens[navigation.state.index];

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      {screen.name === "main" && (
        <MainScreen
          title={group ? "Edit group" : "New group"}
          submitText={group ? "Done" : "Add"}
          formik={formik}
          users={users}
          onClose={onClose}
          onAddUsersPress={() => jumpTo("usersSelect")}
          visible
        />
      )}
      {screen.name === "usersSelect" && (
        <UsersSelectScreen
          availableUserIds={users.ids.sort((a, b) =>
            sortUsers(users.entities[a], users.entities[b])
          )}
          initialSelectedUserIds={formik.values.members}
          navigation={{
            icon: "arrow-back-outline",
            onPress: goBack,
          }}
          submit={{
            onPress: (selectedUserIds) => {
              formik.setFieldValue("members", selectedUserIds);
              goBack();
            },
            text: "Done",
          }}
          visible
        />
      )}
    </FullScreenModal>
  );
};

GroupEditingModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    syncDateTime: PropTypes.string,
    created: PropTypes.shape({}),
    updated: PropTypes.shape({}),
    isDeleted: PropTypes.bool,
  }),
};

GroupEditingModal.defaultProps = {
  group: null,
};

export default GroupEditingModal;
