import API, { GraphQLResult } from "@aws-amplify/api";

import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

const deleteInvoiceItem = async (id: string) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        id,
      },
    })) as GraphQLResult<{
      deleteInvoiceItem: { deleted: boolean; id: string };
    }>;
    return data.deleteInvoiceItem;
  } catch ({ errors }) {
    throw generateErrorMessage("deleteInvoiceItem", errors);
  }
};

export default deleteInvoiceItem;
