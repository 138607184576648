import React from "react";

import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import { Base, Content } from "../../buildingBlocks";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";

interface DefaultFullScreenModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  title: string;
  subtitle?: string;
  leftControlIcon: string;
  rightControlTextButton?: {
    loading: boolean;
    text: string;
    onPress: () => void;
  };
  children: React.ReactNode;
  scrollable: boolean;
}

const DefaultFullScreenModal = ({
  isVisible,
  onClose,
  leftControlIcon,
  rightControlTextButton,
  title,
  subtitle,
  children,
  scrollable,
}: DefaultFullScreenModalProps) => {
  const leftControl = {
    icon: leftControlIcon,
    onPress: onClose,
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Base>
          <HeaderWithTextAction
            navigation={leftControl}
            title={title}
            action={rightControlTextButton}
            subtitle={subtitle}
          />
          <Content scrollable={scrollable}>{children}</Content>
        </Base>
      </FullScreenModal>
    </>
  );
};

DefaultFullScreenModal.defaultProps = {
  rightControlTextButton: null,
  subtitle: undefined,
};

export default DefaultFullScreenModal;
