import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  mutation createCommuteRecord(
    $coordinates: CoordinatesInput!
    $jobId: ID!
    $timestamp: AWSDateTime!
  ) {
    createCommuteRecord(
      coordinates: $coordinates
      jobId: $jobId
      timestamp: $timestamp
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;
