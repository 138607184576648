import {
  Button,
  Divider,
  IndexPath,
  Input,
  Layout,
  ListItem,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import _ from "lodash";
import React, { memo, useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import HeaderWithTextAction from "../../../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../../../buildingBlocks/Separator";
import { addNotification } from "../../../../InAppNotifications";
import SectionItem from "../../../../SectionItem";
import useIosBottomSpace from "../../../../../device/useIosBottomSpace";
import regions from "../../../../../constants/regions";
import {
  ethnicity,
  eyeColor,
  hairColor,
  race,
  sex,
  type,
} from "../../../../../constants/subject";
import useAndroidBackHandler from "../../../../../device/useAndroidBackHandler";
import { Subject } from "../../../../../types";

const regionArray = Object.values(regions);
const ethnicityArray = Object.values(ethnicity);
const eyeColorArray = Object.values(eyeColor);
const hairColorArray = Object.values(hairColor);
const raceArray = Object.values(race);
const sexArray = Object.values(sex);
const typeArray = Object.values(type);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  listItemAccessory: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  multilineInput: {
    height: 60,
  },
  sectionItem: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  select: {
    marginBottom: 4,
    marginEnd: 16,
    marginStart: 16,
  },
  scrollContent: {
    paddingTop: 8,
  },
});

const initialValues = {
  address: "",
  age: "",
  dateOfBirth: "",
  description: "",
  ethnicity: null,
  eyeColor: null,
  hairColor: null,
  height: "",
  license: {
    number: "",
    region: null,
  },
  name: "",
  phoneNumber: "",
  race: null,
  sex: null,
  type: null,
  weight: "",
};

interface SubjectScreenProps {
  onBackPress: () => void;
  onDeletePress: () => void;
  onSubmitPress: (Subject) => void;
  subject?: Subject;
  visible: boolean;
}

const SubjectScreen = ({
  onBackPress,
  onDeletePress,
  onSubmitPress,
  subject,
  visible,
}: SubjectScreenProps) => {
  const iosBottomSpace = useIosBottomSpace();
  const formik = useFormik<Subject>({
    initialValues,
    onSubmit: (values) => {
      if (_.isEqual(values, initialValues)) {
        addNotification({
          message: "Cannot be empty.",
          status: "danger",
          title: "Error",
        });
      } else {
        const formattedSubject = {
          address: values.address || null,
          age: values.age || null,
          dateOfBirth: values.dateOfBirth || null,
          description: values.description || null,
          ethnicity: values.ethnicity,
          eyeColor: values.eyeColor,
          hairColor: values.hairColor,
          height: values.height || null,
          license:
            values.license.region && values.license.number
              ? values.license
              : null,
          name: values.name || null,
          phoneNumber: values.phoneNumber || null,
          race: values.race,
          sex: values.sex,
          type: values.type,
          weight: values.weight || null,
        };
        onSubmitPress(formattedSubject);
      }
    },
  });
  useAndroidBackHandler({ enabled: visible, onBackPress });
  useEffect(() => {
    if (visible) {
      if (subject) {
        formik.resetForm({
          values: {
            name: subject.name,
            sex: subject.sex,
            ethnicity: subject.ethnicity,
            race: subject.race,
            phoneNumber: subject.phoneNumber,
            address: subject.address,
            license: subject.license || {
              number: "",
              region: null,
            },
            description: subject.description,
            type: subject.type,
            height: subject.height,
            weight: subject.weight,
            dateOfBirth: subject.dateOfBirth,
            age: subject.age,
            hairColor: subject.hairColor,
            eyeColor: subject.eyeColor,
          },
        });
      } else {
        formik.resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <HeaderWithTextAction
        action={{
          "data-test": "submitButtonInSubjectModalScreen",
          loading: false,
          onPress: formik.handleSubmit,
          text: subject ? "Done" : "Add",
        }}
        navigation={{ icon: "arrow-back-outline", onPress: onBackPress }}
        title={subject ? "Edit subject" : "New subject"}
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView
          contentContainerStyle={[
            styles.scrollContent,
            { paddingBottom: iosBottomSpace + 8 },
          ]}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Separator />
          <SectionItem title="GENERAL">
            <View style={styles.sectionItem}>
              <Input
                data-test="nameInputInSubjectModalScreen"
                label="Name"
                onChangeText={formik.handleChange("name")}
                onFocus={() => {
                  formik.setFieldTouched("name");
                }}
                style={styles.input}
                value={formik.values.name}
              />
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.type
                    ? new IndexPath(
                        _.findIndex(
                          typeArray,
                          (typeConstant) =>
                            typeConstant.value === formik.values.type
                        )
                      )
                    : null
                }
                onSelect={(index: any) => {
                  formik.setFieldValue("type", typeArray[index.row].value);
                }}
                value={
                  type[formik.values.type]
                    ? type[formik.values.type].text
                    : null
                }
                label="Type"
                placeholder="Select option"
                style={styles.select}
              >
                {typeArray.map((typeConstant) => (
                  <SelectItem
                    key={typeConstant.value}
                    title={typeConstant.text}
                  />
                ))}
              </Select>
              <Separator size="small" />
              <Input
                label="Description"
                multiline
                numberOfLines={3}
                onFocus={() => {
                  formik.setFieldTouched("description");
                }}
                onChangeText={formik.handleChange("description")}
                style={styles.input}
                textAlignVertical="top"
                textStyle={styles.multilineInput}
                defaultValue={formik.values.description}
              />
              <Separator size="small" />
              <Input
                value={formik.values.address}
                onChangeText={formik.handleChange("address")}
                label="Address"
                onFocus={() => {
                  formik.setFieldTouched("address");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.phoneNumber}
                onChangeText={formik.handleChange("phoneNumber")}
                label="Phone"
                onFocus={() => {
                  formik.setFieldTouched("phoneNumber");
                }}
                style={styles.input}
              />
            </View>
          </SectionItem>
          <Separator />
          <SectionItem title="PROFILE">
            <View style={styles.sectionItem}>
              <Input
                value={formik.values.age}
                onChangeText={formik.handleChange("age")}
                label="Age"
                onFocus={() => {
                  formik.setFieldTouched("age");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.dateOfBirth}
                onChangeText={formik.handleChange("dateOfBirth")}
                label="Date of birth"
                onFocus={() => {
                  formik.setFieldTouched("dateOfBirth");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.race
                    ? new IndexPath(
                        _.findIndex(
                          raceArray,
                          (raceConstant) =>
                            raceConstant.value === formik.values.ethnicity
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue("race", raceArray[index.row].value)
                }
                value={
                  race[formik.values.race]
                    ? race[formik.values.race].text
                    : null
                }
                label="Race"
                placeholder="Select option"
                style={styles.select}
              >
                {raceArray.map((raceConstant) => (
                  <SelectItem
                    key={raceConstant.value}
                    title={raceConstant.text}
                  />
                ))}
              </Select>
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.ethnicity
                    ? new IndexPath(
                        _.findIndex(
                          ethnicityArray,
                          (ethnicityConstant) =>
                            ethnicityConstant.value === formik.values.ethnicity
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue(
                    "ethnicity",
                    ethnicityArray[index.row].value
                  )
                }
                value={
                  ethnicity[formik.values.ethnicity]
                    ? ethnicity[formik.values.ethnicity].text
                    : null
                }
                label="Ethnicity"
                placeholder="Select option"
                style={styles.select}
              >
                {ethnicityArray.map((ethnicityConstant) => (
                  <SelectItem
                    key={ethnicityConstant.value}
                    title={ethnicityConstant.text}
                  />
                ))}
              </Select>
              <Separator size="small" />
              <Input
                value={formik.values.height}
                onChangeText={formik.handleChange("height")}
                label="Height"
                onFocus={() => {
                  formik.setFieldTouched("height");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.weight}
                onChangeText={formik.handleChange("weight")}
                label="Weight"
                onFocus={() => {
                  formik.setFieldTouched("weight");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Select
                label="Hair"
                onSelect={(index: any) =>
                  formik.setFieldValue(
                    "hairColor",
                    hairColorArray[index.row].value
                  )
                }
                placeholder="Select option"
                selectedIndex={
                  formik.values.hairColor
                    ? new IndexPath(
                        _.findIndex(
                          hairColorArray,
                          (hairColorConstant) =>
                            hairColorConstant.value === formik.values.hairColor
                        )
                      )
                    : null
                }
                style={styles.select}
                value={
                  hairColor[formik.values.hairColor]
                    ? hairColor[formik.values.hairColor].text
                    : null
                }
              >
                {hairColorArray.map((hairColorConstant) => (
                  <SelectItem
                    key={hairColorConstant.value}
                    title={hairColorConstant.text}
                  />
                ))}
              </Select>
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.eyeColor
                    ? new IndexPath(
                        _.findIndex(
                          eyeColorArray,
                          (eyeColorConstant) =>
                            eyeColorConstant.value === formik.values.eyeColor
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue(
                    "eyeColor",
                    eyeColorArray[index.row].value
                  )
                }
                value={
                  eyeColor[formik.values.eyeColor]
                    ? eyeColor[formik.values.eyeColor].text
                    : null
                }
                label="Eyes"
                placeholder="Select option"
                style={styles.select}
              >
                {eyeColorArray.map((eyeColorConstant) => (
                  <SelectItem
                    key={eyeColorConstant.value}
                    title={eyeColorConstant.text}
                  />
                ))}
              </Select>
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.sex
                    ? new IndexPath(
                        _.findIndex(
                          sexArray,
                          (sexConstant) =>
                            sexConstant.value === formik.values.sex
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue("sex", sexArray[index.row].value)
                }
                value={
                  sex[formik.values.sex] ? sex[formik.values.sex].text : null
                }
                label="Sex"
                placeholder="Select option"
                style={styles.select}
              >
                {sexArray.map((sexConstant) => (
                  <SelectItem
                    key={sexConstant.value}
                    title={sexConstant.text}
                  />
                ))}
              </Select>
            </View>
          </SectionItem>
          <Separator />
          <SectionItem title="DRIVER'S LICENSE">
            <View style={styles.sectionItem}>
              <Input
                label="License #"
                onFocus={() => {
                  formik.setFieldTouched("license.number");
                }}
                onChangeText={formik.handleChange("license.number")}
                style={styles.input}
                value={formik.values.license.number}
              />
              <Separator size="small" />
              <Select
                label="State"
                onSelect={(index: any) =>
                  formik.setFieldValue(
                    "license.region",
                    regionArray[index.row].value
                  )
                }
                placeholder="Select option"
                selectedIndex={
                  formik.values.license.region
                    ? new IndexPath(
                        _.findIndex(
                          regionArray,
                          (regionConstant) =>
                            regionConstant.value ===
                            formik.values.license.region
                        )
                      )
                    : null
                }
                style={styles.select}
                value={
                  regions[formik.values.license.region]
                    ? regions[formik.values.license.region].text
                    : null
                }
              >
                {regionArray.map((regionConstant) => (
                  <SelectItem
                    key={regionConstant.value}
                    title={regionConstant.text}
                  />
                ))}
              </Select>
            </View>
          </SectionItem>
          {Boolean(subject) && (
            <>
              <Separator />
              <SectionItem status="danger" title="DANGER ZONE">
                <ListItem
                  accessoryRight={() => (
                    <View style={styles.listItemAccessory}>
                      <Button
                        onPress={onDeletePress}
                        size="small"
                        status="danger"
                      >
                        Delete
                      </Button>
                    </View>
                  )}
                  description="Once you delete a subject there is no going back."
                  disabled
                  title="Delete this subject"
                />
              </SectionItem>
            </>
          )}
          <Separator />
        </ScrollView>
      </Layout>
    </>
  );
};

SubjectScreen.defaultProps = {
  subject: undefined,
};

export default memo(
  SubjectScreen,
  (prevProps, nextProps) => !prevProps.visible && !nextProps.visible
);
