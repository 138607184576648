import React, { useEffect, useState } from "react";
import { Image, ImageStyle, StyleProp } from "react-native";

interface Props {
  height?: number;
  source: {
    uri: string;
  };
  style?: StyleProp<ImageStyle>;
  width?: number;
}

const ScaledImage = ({ height, source, style, width }: Props) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    Image.getSize(source.uri, (imageWidth, imageHeight) => {
      if (width && !height) {
        setDimensions({
          height: imageHeight * (width / imageWidth),
          width,
        });
      } else if (!width && height) {
        setDimensions({
          height,
          width: imageWidth * (height / imageHeight),
        });
      } else {
        setDimensions({
          height,
          width,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source.uri]);
  return <Image source={source} style={[style, dimensions]} />;
};

ScaledImage.defaultProps = {
  height: undefined,
  style: undefined,
  width: undefined,
};

export default ScaledImage;
