import React from "react";
import { SafeAreaView, ScrollView, StyleSheet } from "react-native";
import Separator from "../buildingBlocks/Separator";

import FutureStatusJobCard from "../jobStatusCards/FutureStatusJobCard";
import InProgressStatusJobCard from "../jobStatusCards/InProgressStatusJobCard";
import LateStatusJobCard from "../jobStatusCards/LateStatusJobCard";
import ImmediateStatusJobCard from "../jobStatusCards/ImmediateStatusJobCard";

const styles = StyleSheet.create({
  safeAreaView: {
    flexDirection: "row",
  },
  statusCardContainer: {
    flexDirection: "row",
    marginHorizontal: 4,
    marginBottom: 4,
  },
});

interface FilterHorizontalListProps {
  callback?: () => void;
}

const FilterHorizontalList = ({
  callback = () => null,
}: FilterHorizontalListProps) => {
  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic" horizontal>
      <SafeAreaView style={styles.safeAreaView}>
        <Separator horizontal size="medium" />
        <FutureStatusJobCard callback={callback} dense />
        <Separator horizontal size="small" />
        <InProgressStatusJobCard callback={callback} dense />
        <Separator horizontal size="small" />
        <LateStatusJobCard callback={callback} dense />
        <Separator horizontal size="small" />
        <ImmediateStatusJobCard callback={callback} dense />
        <Separator horizontal size="medium" />
      </SafeAreaView>
    </ScrollView>
  );
};

export default FilterHorizontalList;
