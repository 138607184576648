import gql from "graphql-tag";

import { inboxItemFragment } from "../../../fragments";

export default gql`
  mutation createInboxItem(
    $description: String
    $id: ID!
    $metadata: InboxItemMetadataInput!
    $title: String!
    $type: InboxItemTypes!
  ) {
    createInboxItem(
      description: $description
      id: $id
      metadata: $metadata
      title: $title
      type: $type
    ) {
      ...inboxItemFragment
    }
  }
  ${inboxItemFragment}
`;
