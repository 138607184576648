import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { CompanyUser, CompanyUserRole } from "../../../types";

interface AdminCreateCompanyUserInput {
  phone?: string;
  email?: string;
  firstName: string;
  lastName: string;
  role: CompanyUserRole;
}

const adminCreateCompanyUser = async ({
  phone,
  email,
  firstName,
  lastName,
  role,
}: AdminCreateCompanyUserInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        phone,
        email,
        firstName,
        lastName,
        role,
      },
    })) as GraphQLResult<{ adminCreateCompanyUser: CompanyUser }>;
    return data.adminCreateCompanyUser;
  } catch ({ errors }) {
    throw generateErrorMessage("adminCreateCompanyUser", errors);
  }
};

export default adminCreateCompanyUser;
