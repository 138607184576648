import React from "react";
import { AppState } from "react-native";

const useAppActive = () => {
  const [appActive, setAppActive] = React.useState(true);
  React.useEffect(() => {
    const handleAppStateChange = (state) => {
      setAppActive(state === "active");
    };
    const listener = AppState.addEventListener("change", handleAppStateChange);
    return () => {
      listener.remove();
    };
  }, []);
  return appActive;
};

export default useAppActive;
