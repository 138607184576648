import {
  Divider,
  Icon,
  Input,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Keyboard, ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import * as yup from "yup";

import AlertModal from "../Alert";
import FullScreenModal from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import { addNotification } from "../../InAppNotifications";
import adminUpdateCompanyUser from "../../../api/functions/adminUpdateCompanyUser";
import companyUserRoles from "../../../constants/companyUserRolesExtended";
import selectUser from "../../../store/auth/selectors/selectUser";
import { CompanyUser } from "../../../types";
import SectionItem from "../../SectionItem";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  user: CompanyUser;
}

const roles = Object.values(companyUserRoles);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  scroll: {
    flex: 1,
  },
  separator: {
    height: 24,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  snackbar: {
    marginEnd: 16,
    marginStart: 16,
  },
  inputSeparator: {
    height: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  header: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  caption: {
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 4,
  },
});

const EditUserModal = ({ isVisible, onClose, user }: Props) => {
  const formik = useFormik({
    initialValues: {
      id: null,
      firstName: "",
      lastName: "",
      role: companyUserRoles.EMPLOYEE.value,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
      role: yup.string(),
    }),
    onSubmit: (values) => {
      adminUpdateCompanyUser(
        {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
        },
        user
      );
      addNotification({
        status: "success",
        title: "User updated",
      });
      onClose();
    },
  });
  const signedInUser = useSelector(selectUser);
  const theme = useTheme();

  const [alertVisible, setAlertVisible] = useState(false);

  const lastNameInputRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleClose = () => {
    if (formik.dirty) {
      setAlertVisible(true);
    } else {
      onClose();
    }
  };
  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Layout level="2" style={styles.root}>
          <HeaderWithTextAction
            navigation={{
              icon: "close-outline",
              onPress: handleClose,
            }}
            title="Edit User"
            action={{
              disabled: false,
              loading: false,
              onPress: formik.dirty ? formik.handleSubmit : () => onClose(),
              text: "Done",
              "data-test": "submitUserEditButton",
            }}
          />
          <Divider />
          <ScrollView style={styles.scroll}>
            <View style={styles.separator} />
            <Divider />
            <Layout style={styles.section}>
              <Input
                data-test="userFirstNameInput"
                value={formik.values.firstName}
                onChangeText={formik.handleChange("firstName")}
                autoCapitalize="words"
                blurOnSubmit={false}
                caption={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
                disabled={formik.isSubmitting}
                label="First name"
                onFocus={() => {
                  formik.setFieldTouched("firstName");
                }}
                onSubmitEditing={() => lastNameInputRef.current.focus()}
                returnKeyType="next"
                status={
                  formik.touched.firstName && formik.errors.firstName
                    ? "danger"
                    : "basic"
                }
                style={styles.input}
              />
              <View style={styles.inputSeparator} />
              <Input
                data-test="userLastNameInput"
                value={formik.values.lastName}
                onChangeText={formik.handleChange("lastName")}
                autoCapitalize="words"
                blurOnSubmit={false}
                caption={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
                disabled={formik.isSubmitting}
                label="Last name"
                onFocus={() => {
                  formik.setFieldTouched("lastName");
                }}
                onSubmitEditing={Keyboard.dismiss}
                ref={lastNameInputRef}
                returnKeyType="next"
                status={
                  formik.touched.lastName && formik.errors.lastName
                    ? "danger"
                    : "basic"
                }
                style={styles.input}
              />
            </Layout>
            <Divider />
            <View style={styles.separator} />
            <SectionItem title="ROLE">
              {roles.map((role) => {
                const { value, description, permissions } = role;
                return (
                  <Fragment key={value}>
                    <ListItem
                      data-test={`user${value}RoleButton`}
                      onPress={() => formik.setFieldValue("role", value)}
                      accessoryRight={(props) =>
                        value === formik.values.role && (
                          <Icon
                            {...props}
                            name="checkmark-outline"
                            fill={theme["color-primary-default"]}
                          />
                        )
                      }
                      description={`${description}\n\nPermissions:\n- ${permissions.join(
                        "\n- "
                      )}`}
                      key={value}
                      title={value}
                    />
                    <Divider />
                  </Fragment>
                );
              })}
            </SectionItem>
            {signedInUser.id === user.id && (
              <Text appearance="hint" category="c1" style={styles.caption}>
                You are not allowed to change your own role.
              </Text>
            )}
            <View style={styles.separator} />
          </ScrollView>
        </Layout>
      </FullScreenModal>
      <AlertModal
        isVisible={alertVisible}
        onClose={() => setAlertVisible(false)}
        message="Are you sure you want to discard this new user?"
        confirmText="Keep editing"
        onConfirm={() => setAlertVisible(false)}
        cancelText="Discard changes"
        onCancel={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
    </>
  );
};

export default EditUserModal;
