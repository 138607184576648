import {
  Button,
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { FormikProps } from "formik";
import React, { Fragment, useState } from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import companyUserRoles from "../../../../../constants/companyUserRoles";
import useFormikMediaHelpers from "../../../../../hooks/useFormikMediaHelpers";
import { getLocation } from "../../../../../location/mapLocation";
import selectUser from "../../../../../store/auth/selectors/selectUser";
import selectJobById from "../../../../../store/jobs/selectors/selectJobById";
import selectSiteById from "../../../../../store/sites/selectors/selectById";
import { Coordinates, DailyActivityReport } from "../../../../../types";
import AddMediaButtons from "../../../../AddMediaButtons";
import HeaderWithTextAction from "../../../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../../../buildingBlocks/Separator";
import Card from "../../../../Card";
import ClockInEnabledButton from "../../../../ClockInEnabledButton";
import ListItem from "../../../../listItems/Base";
import BaseListItem from "../../../../listItems/Base";
import MediaListItem from "../../../../listItems/Media";
import TimerText from "../../../../TimerText";
import AlertModal from "../../../Alert";
import EditMediaDescriptionModal from "../../../EditMediaDescription";
import MediaViewerModal from "../../../MediaViewer";

dayjs.extend(duration);

interface Props {
  dailyActivityReport: DailyActivityReport;
  formik: FormikProps<Pick<DailyActivityReport, "media" | "summary">>;
  onClosePress: () => void;
  onDeletePress: () => void;
  onMediaUpdate: () => void;
  onSubmitPress: (coordinates: Coordinates) => void;
  onSummaryPress: () => void;
}

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const styles = StyleSheet.create({
  autoSaveMessage: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  listItemAccessory: {
    marginEnd: 8,
    marginStart: 8,
  },
  scrollContent: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  submitButton: {
    marginEnd: 16,
    marginStart: 16,
  },
  detailsHeaderContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  detailsTextContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  footerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  icon: {
    height: 18,
    width: 18,
  },
  input: {
    flex: 1,
    paddingBottom: 16,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 16,
    textAlignVertical: "top",
  },
  inputContainer: {
    marginBottom: 16,
    marginTop: 16,
  },
  tinySeparator: {
    height: 4,
  },
  textInput: {
    marginEnd: 16,
    marginStart: 16,
  },
  textInputText: {
    height: 100,
  },
});

const MainScreen = ({
  dailyActivityReport,
  formik,
  onClosePress,
  onDeletePress,
  onMediaUpdate,
  onSubmitPress,
}: Props) => {
  const theme = useTheme();

  const { group } = useSelector(selectUser);
  const job = useSelector((state) =>
    selectJobById(state, dailyActivityReport.jobId)
  );
  const site = useSelector((state) => selectSiteById(state, job && job.siteId));

  const dailyActivityReportSubmitted = Boolean(dailyActivityReport.submitted);
  const {
    AddMediaButtonsProps,
    DeleteMediaAlertModalProps,
    EditMediaDescriptionModalProps,
    MediaListItemProps,
    MediaViewerModalProps,
  } = useFormikMediaHelpers(formik);

  const [notesVisible, setNotesVisible] = useState(true);
  const [submitReportPending, setSubmitReportPending] = useState(false);

  return (
    <>
      <HeaderWithTextAction
        navigation={{
          "data-test": "closeButtonInDailyActivityReportEditingModal",
          icon: "close-outline",
          onPress: onClosePress,
        }}
        title="Edit daily activity report"
        subtitle={
          !dailyActivityReportSubmitted
            ? (textProps) => (
                <TimerText
                  {...textProps}
                  dateTime={dailyActivityReport.created.at}
                />
              )
            : undefined
        }
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView
          contentContainerStyle={styles.scrollContent}
          style={styles.scrollContent}
        >
          <Text
            appearance="hint"
            category="label"
            style={styles.autoSaveMessage}
          >
            All changes are automatically saved.
          </Text>
          <Separator />
          <StyledCard title="SUMMARY">
            <View style={styles.inputContainer}>
              <Input
                defaultValue={formik.values.summary}
                autoFocus={Platform.OS !== "web"}
                multiline
                onBlur={() => formik.handleSubmit()}
                onChangeText={formik.handleChange("summary")}
                placeholder="Write a summary"
                style={styles.textInput}
                textAlignVertical="top"
                textStyle={styles.textInputText}
              />
            </View>
            {Boolean(site.notes || site.privateNotes) && (
              <>
                <Divider />
                <ListItem
                  accessoryRight={(imageProps) => (
                    <Icon
                      {...imageProps}
                      name={
                        notesVisible ? "arrow-ios-upward" : "arrow-ios-downward"
                      }
                    />
                  )}
                  onPress={() => setNotesVisible(!notesVisible)}
                  title={() => (
                    <Text
                      appearance="hint"
                      style={{ paddingEnd: 8, paddingStart: 8 }}
                    >
                      {`${notesVisible ? "Hide" : "Show"} location notes`}
                    </Text>
                  )}
                />
                {notesVisible && (
                  <>
                    <Separator size="small" />
                    <View style={styles.detailsHeaderContainer}>
                      <Icon
                        fill={theme["text-hint-color"]}
                        name="pin-outline"
                        style={styles.icon}
                      />
                      <Separator horizontal size="small" />
                      <Text>{site.name}</Text>
                    </View>
                    <View style={styles.detailsTextContainer}>
                      {Boolean(site.notes) && (
                        <>
                          <Separator size="medium" />
                          <Text>{site.notes}</Text>
                        </>
                      )}
                      {Boolean(
                        site.privateNotes && group !== companyUserRoles.EMPLOYEE
                      ) && (
                        <>
                          <Separator size="medium" />
                          <Text appearance="hint" category="c1">
                            PRIVATE
                          </Text>
                          <Text>{site.privateNotes}</Text>
                        </>
                      )}
                    </View>
                    <Separator size="medium" />
                  </>
                )}
              </>
            )}
          </StyledCard>
          <Separator />
          <StyledCard title="MEDIA">
            <Separator size="small" />
            <AddMediaButtons onAddMedia={AddMediaButtonsProps.onAddMedia} />
            <Separator size="small" />
            {Array.isArray(formik.values.media) &&
              formik.values.media.length > 0 && (
                <>
                  <Divider />
                  {formik.values.media.map((media, index) => (
                    <Fragment key={media.id}>
                      {index > 0 && <Divider style={styles.divider} />}
                      <MediaListItem
                        editable
                        media={media}
                        onDeletePress={MediaListItemProps.onDeletePress}
                        onDescriptionPress={
                          MediaListItemProps.onDescriptionPress
                        }
                        onPress={MediaListItemProps.onPress}
                        onUpload={(mediaId) => {
                          MediaListItemProps.onUpload(mediaId);
                          onMediaUpdate();
                        }}
                      />
                    </Fragment>
                  ))}
                </>
              )}
          </StyledCard>
          <Separator size="small" />
          <StyledText appearance="hint" category="c1">
            Images that cannot be uploaded will not be added to the report.
          </StyledText>
          <Separator />
          <StyledCard
            style={{
              borderColor: theme["color-danger-default-border"],
              borderWidth: 1,
            }}
            title="DANGER ZONE"
          >
            <BaseListItem
              accessoryRight={() => (
                <Button
                  onPress={onDeletePress}
                  size="small"
                  status="danger"
                  style={styles.listItemAccessory}
                >
                  Delete
                </Button>
              )}
              description="Once you delete a report there is no going back."
              disabled
              title="Delete this daily activity report"
            />
          </StyledCard>
          <Separator />
        </ScrollView>
      </Layout>
      {!dailyActivityReport.submitted && (
        <>
          <Divider />
          <Layout>
            <SafeAreaView>
              <Separator size="small" />
              <ClockInEnabledButton
                data-test="submitButtonInDailyActivityReportEditingModal"
                onPress={async () => {
                  formik.handleSubmit();
                  setSubmitReportPending(true);
                  const location = await getLocation({
                    title: "Submit report failed",
                  });
                  setSubmitReportPending(false);
                  onSubmitPress({
                    lat: location.latitude,
                    lng: location.longitude,
                  });
                }}
                style={styles.submitButton}
              >
                Submit
              </ClockInEnabledButton>
              <Separator size="small" />
            </SafeAreaView>
          </Layout>
        </>
      )}
      <MediaViewerModal
        editable={MediaViewerModalProps.editable}
        isVisible={MediaViewerModalProps.isVisible}
        media={MediaViewerModalProps.media}
        onClose={MediaViewerModalProps.onClose}
        onDeletePress={MediaViewerModalProps.onDeletePress}
        onDescriptionPress={MediaViewerModalProps.onDescriptionPress}
      />
      <EditMediaDescriptionModal
        isVisible={EditMediaDescriptionModalProps.isVisible}
        jobId={dailyActivityReport.jobId}
        onClose={EditMediaDescriptionModalProps.onClose}
        onSubmit={(text) => {
          EditMediaDescriptionModalProps.onSubmit(text);
          onMediaUpdate();
        }}
        text={EditMediaDescriptionModalProps.text}
      />
      <AlertModal
        cancelText={DeleteMediaAlertModalProps.cancelText}
        confirmText={DeleteMediaAlertModalProps.confirmText}
        isVisible={DeleteMediaAlertModalProps.isVisible}
        message={DeleteMediaAlertModalProps.message}
        onCancel={DeleteMediaAlertModalProps.onCancel}
        onClose={DeleteMediaAlertModalProps.onClose}
        onConfirm={DeleteMediaAlertModalProps.onConfirm}
      />
    </>
  );
};

export default MainScreen;
