import gql from "graphql-tag";

export default gql`
  mutation sendJobSummaries($jobIds: [ID!]!) {
    sendJobSummaries(jobIds: $jobIds) {
      to
      submittedAt
      messageId
      errorCode
      message
    }
  }
`;
