import { createSelector } from "@reduxjs/toolkit";

import selectInboxItems from "../selectInboxItems/input";

const selectInboxItemEntities = createSelector(
  [selectInboxItems],
  (inboxItems) => {
    return inboxItems.entities;
  }
);

export default selectInboxItemEntities;
