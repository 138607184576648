import { createSelector } from "@reduxjs/toolkit";

import selectStripeCustomers from "../selectStripeCustomers";

const selectCustomerByStripeCustomerId = createSelector(
  [
    selectStripeCustomers,
    (state, stripeCustomerId: string) => stripeCustomerId,
  ],
  (stripeCustomers, stripeCustomerId) =>
    stripeCustomers.entities[stripeCustomerId]
);

export default selectCustomerByStripeCustomerId;
