import React, { useState } from "react";
import { useSelector } from "react-redux";

import AppHeaderMessage from "../AppHeaderMessage";
import selectUser from "../../store/auth/selectors/selectUser";
import selectUserById from "../../store/users/selectors/selectUserById";
import runClockIn from "../../utils/runClockIn";

const ClockInAppHeaderMessage = () => {
  const signedInUser = useSelector(selectUser);
  const signedInCompanyUser = useSelector((state) =>
    selectUserById(state, signedInUser.id)
  );

  const [clockInPending, setClockInPending] = useState(false);

  return (
    <AppHeaderMessage
      description="Press to clock in"
      loading={clockInPending}
      onPress={async () => {
        setClockInPending(true);
        await runClockIn({ user: signedInCompanyUser });
        setClockInPending(false);
      }}
      status="warning"
      title="Not clocked in"
    />
  );
};

export default ClockInAppHeaderMessage;
