import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectSiteById from "../../../store/sites/selectors/selectById";
import { setSitePending } from "../../../store/sites/slice";

interface LinkSiteToCustomerInput {
  siteId: string;
  customerId: string;
}

const linkSiteToCustomer = ({
  customerId,
  siteId,
}: LinkSiteToCustomerInput) => {
  const nowDateTime = new Date().toISOString();
  const state = store.getState();
  const user = selectUser(state);
  const site = selectSiteById(state, siteId);
  store.dispatch(
    setSitePending({
      mutation: "linkSiteToCustomer",
      site: {
        ...site,
        syncDateTime: nowDateTime,
        updated: {
          at: nowDateTime,
          by: user.id,
        },
        customerId,
      },
    })
  );
  client.mutate({
    mutation,
    variables: {
      customerId,
      siteId,
    },
  });
};

export default linkSiteToCustomer;
