import {
  Button,
  Divider,
  Input,
  Toggle,
  useTheme,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet } from "react-native";
import uuidv4 from "uuid/v4";
import useCompanySettings from "../../../hooks/useCompanySettings";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import LevelTwoListItem from "../../listItems/LevelTwo";

import AlertModal from "../Alert";
import FullScreenModal from "../FullScreen";
import { Props as TextInputModalProps } from "../TextInput";
import Separator from "../../buildingBlocks/Separator";
import UserRecord from "../../UserRecord";
import createActionItem from "../../../api/functions/createActionItem";
import deleteActionItem from "../../../api/functions/deleteActionItem";
import updateActionItem from "../../../api/functions/updateActionItem";
import { ActionItem } from "../../../types";

interface Props extends Pick<TextInputModalProps, "isVisible" | "onClose"> {
  actionItem?: ActionItem;
  onDelete: () => void;
  onSubmit: (actionItemId: string) => void;
}

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  listItem: {
    borderWidth: 1,
    marginEnd: 16,
    marginStart: 16,
    minHeight: 48,
  },
  textInput: {
    marginEnd: 16,
    marginStart: 16,
  },
  textInputText: {
    height: 60,
  },
  userRecord: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const ActionItemEditingModal = ({
  actionItem,
  isVisible,
  onClose,
  onDelete,
  onSubmit,
}: Props) => {
  const theme = useTheme();

  const { dailyActivityReportsEnabled } = useCompanySettings();

  const [description, setDescription] = useState("");
  const [
    dailyActivityReportRequired,
    setDailyActivityReportRequired,
  ] = useState(false);

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(
    false
  );
  const [editConfirmation, setEditConfirmation] = useState<{
    text?: string;
    visible: boolean;
  }>({
    visible: false,
  });

  useEffect(() => {
    if (isVisible) {
      setDescription(actionItem ? actionItem.description : "");
      setDailyActivityReportRequired(actionItem?.dailyActivityReportRequired);
    }
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <HeaderWithTextAction
        navigation={{
          icon: "close-outline",
          onPress: onClose,
        }}
        title={actionItem ? "Update checklist item" : "Create checklist item"}
      />
      <Divider />
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        keyboardShouldPersistTaps="handled"
        scrollIndicatorInsets={{ right: 1 }}
      >
        <SafeAreaView>
          <Separator size="medium" />
          <Input
            multiline
            onChangeText={setDescription}
            placeholder="Tap to add a description"
            style={styles.textInput}
            textAlignVertical="top"
            textStyle={styles.textInputText}
            defaultValue={description}
          />
          <Separator />
          {dailyActivityReportsEnabled && (
            <>
              <LevelTwoListItem
                accessoryRight={() => (
                  <Toggle
                    checked={dailyActivityReportRequired}
                    onChange={() =>
                      setDailyActivityReportRequired(
                        !dailyActivityReportRequired
                      )
                    }
                  />
                )}
                disabled
                style={[
                  styles.listItem,
                  { borderColor: theme["border-basic-color-4"] },
                ]}
                title="Daily activity report required"
              />
              <Separator />
            </>
          )}
          <Button
            disabled={!description}
            onPress={() => {
              if (actionItem) {
                if (actionItem.description === description) {
                  updateActionItem({
                    description,
                    id: actionItem.id,
                    dailyActivityReportRequired,
                  });
                  onSubmit(actionItem.id);
                } else {
                  setEditConfirmation({ text: description, visible: true });
                }
              } else {
                const id = uuidv4();
                createActionItem({
                  description,
                  id,
                  dailyActivityReportRequired,
                });
                onSubmit(id);
              }
            }}
            style={styles.button}
          >
            {actionItem ? "Update" : "Create"}
          </Button>
          {actionItem && (
            <>
              <Separator size="small" />
              <Button
                appearance="ghost"
                onPress={() => setDeleteConfirmationVisible(true)}
                status="danger"
                style={styles.button}
              >
                Delete
              </Button>
              <Separator size={48} />
              <UserRecord
                label="Created:"
                style={styles.userRecord}
                userRecord={actionItem.created}
              />
              <Separator size="small" />
              <UserRecord
                label="Updated:"
                style={styles.userRecord}
                userRecord={actionItem.updated}
              />
              <AlertModal
                confirmText="Confirm"
                isVisible={editConfirmation.visible}
                message="All jobs where this action item was completed will have their descriptions updated."
                onCancel={() =>
                  setEditConfirmation((prevState) => ({
                    ...prevState,
                    visible: false,
                  }))
                }
                onClose={() =>
                  setEditConfirmation((prevState) => ({
                    ...prevState,
                    visible: false,
                  }))
                }
                onConfirm={() => {
                  updateActionItem({
                    description: editConfirmation.text,
                    id: actionItem.id,
                    dailyActivityReportRequired,
                  });
                  setEditConfirmation((prevState) => ({
                    ...prevState,
                    visible: false,
                  }));
                  onSubmit(actionItem.id);
                }}
              />
              <AlertModal
                confirmText="Delete"
                isVisible={deleteConfirmationVisible}
                message="Delete this checklist item?"
                onCancel={() => setDeleteConfirmationVisible(false)}
                onClose={() => setDeleteConfirmationVisible(false)}
                onConfirm={() => {
                  deleteActionItem({ id: actionItem.id });
                  setDeleteConfirmationVisible(false);
                  onDelete();
                }}
              />
            </>
          )}
        </SafeAreaView>
      </ScrollView>
    </FullScreenModal>
  );
};

ActionItemEditingModal.defaultProps = {
  actionItem: undefined,
};

export default ActionItemEditingModal;
