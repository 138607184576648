import API from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";

const logout = async (expoToken) => {
  try {
    const { data } = await API.graphql({
      query: mutation,
      variables: {
        expoToken,
      },
    });
    return data.logout;
  } catch ({ errors }) {
    throw generateErrorMessage("logout", errors);
  }
};

export default logout;
