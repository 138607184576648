import React from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  inputSeparator: {
    height: 16,
  },
});

const InputSeparator = () => {
  return <View style={styles.inputSeparator} />;
};

export default InputSeparator;
