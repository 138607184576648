import React from "react";

import { Job } from "../../../types";
import UserAssigneeListItem from "../../listItems/UserAssignee";

interface UserAssigneesProps {
  assignedUserIds: Array<string>;
  job: Job;
}

const UserAssignees = ({ assignedUserIds, job }: UserAssigneesProps) => {
  const users = {};

  assignedUserIds.forEach((userId) => {
    users[userId] = {};
  });
  job.commuteRecords?.forEach((commuteRecord) => {
    const userId = commuteRecord.by;
    if (users[userId]) {
      users[userId].commuteRecord = commuteRecord;
    } else {
      users[userId] = {
        commuteRecord,
      };
    }
  });
  job.startJobRecords.forEach((startJobRecord) => {
    const userId = startJobRecord.record.by;
    if (users[userId]) {
      users[userId].startJobRecord = startJobRecord;
    } else {
      users[userId] = {
        startJobRecord,
      };
    }
  });
  if (job.finished) {
    const userId = job.finished.record.by;
    if (users[userId]) {
      users[userId].finishedStatusRecord = job.finished;
    } else {
      users[userId] = {
        finishStatusRecord: job.finished,
      };
    }
  }

  const userIds = Object.keys(users);

  return (
    <>
      {userIds.map((userId) => {
        const user = users[userId];
        return (
          <UserAssigneeListItem
            commuteRecord={user.commuteRecord}
            finishedStatusRecord={user.finishedStatusRecord}
            key={userId}
            startJobRecord={user.startJobRecord}
            userId={userId}
          />
        );
      })}
    </>
  );
};

export default UserAssignees;
