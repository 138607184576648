import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import userRolePermitted from "../../utils/userRolePermitted";

import init from "../pusherClient";
import syncCompany from "../../api/functions/syncCompany";
import { addNotification } from "../../components/InAppNotifications";
import navigationRoutes from "../../constants/navigationRoutes";
import store from "../../store";
import { setActionItem } from "../../store/actionItems/slice";
import { setLiveConnectionStatus } from "../../store/appInfo/slice";
import selectUser from "../../store/auth/selectors/selectUser";
import { setCompany } from "../../store/company";
import { setCustomer } from "../../store/customers/slice";
import selectDataLoading from "../../store/data/selectors/selectDataLoading";
import { setGroup } from "../../store/groups/slice";
import { setJob } from "../../store/jobs/slice";
import { setSite } from "../../store/sites/slice";
import { setUser } from "../../store/users/slice";
import { setInboxItem } from "../../store/inbox/slice";
import runCreateUsageRecord from "../../utils/runCreateUsageRecord";
import { Company, InboxItem, Reminder } from "../../types";

const useSubscriptions = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    const attachedHandler = async () => {
      const dataLoading = selectDataLoading(store.getState());
      if (!dataLoading) {
        try {
          await syncCompany();
          runCreateUsageRecord();
        } catch (error) {
          // Do nothing
        }
      }
    };

    let channel;
    let client;
    if (user) {
      client = init();
      client.connection.bind("connected", attachedHandler);
      channel = client.subscribe(`private-${user.companyId}`);
      channel.bind("action-item", (data) =>
        dispatch(setActionItem({ actionItem: data }))
      );
      channel.bind("company-user", (data) => dispatch(setUser({ user: data })));
      channel.bind("job", (data) => dispatch(setJob({ job: data })));
      channel.bind("company-site", (data) => dispatch(setSite({ site: data })));
      channel.bind("company", (data: Company) =>
        dispatch(setCompany({ company: data }))
      );
      channel.bind("company-group", (data) =>
        dispatch(setGroup({ group: data }))
      );
      channel.bind("customer", (data) =>
        dispatch(setCustomer({ customer: data }))
      );
      channel.bind("inbox-item", (data: InboxItem) => {
        dispatch(setInboxItem({ inboxItem: data }));
        if (!data.completed) {
          switch (data.type) {
            case "EmailBounce": {
              if (
                userRolePermitted({
                  permittedRoles: ["Admin", "Supervisor"],
                  userRole: user.group,
                })
              ) {
                addNotification({
                  payload: {
                    navigation: {
                      route: navigationRoutes.Inbox,
                    },
                  },
                  message: data.description,
                  title: `New inbox item \u2022 ${data.title}`,
                  status: "info",
                });
              }
              break;
            }
            case "Reminder": {
              const reminder = data as Reminder;
              if (reminder.createdBy !== user.id) {
                addNotification({
                  payload: {
                    navigation: {
                      route: navigationRoutes.Inbox,
                    },
                  },
                  message: data.description,
                  title: `New inbox item \u2022 ${data.title}`,
                  status: "info",
                });
              }
              break;
            }
            default:
              break;
          }
        }
      });
      channel.bind("sync", async () => {
        try {
          await syncCompany();
        } catch (error) {
          // Do nothing
        }
      });
      client.connection.bind("state_change", ({ current, previous }) => {
        dispatch(
          setLiveConnectionStatus({
            liveConnectionStatus: { current, previous },
          })
        );
      });
    }
    return () => {
      if (channel) {
        channel.unsubscribe();
        channel.unbind();
      }
      if (client) {
        client.connection.unbind("state_change");
        client.disconnect();
      }
    };
  }, [user, dispatch]);
};

export default useSubscriptions;
