import gql from "graphql-tag";

import { emailActivityFragment } from "../../../fragments";

export default gql`
  query queryQuoteEmailActivity($after: ID, $id: ID!) {
    queryQuoteEmailActivity(after: $after, id: $id) {
      __typename
      items {
        ...emailActivityFragment
      }
      nextToken
    }
  }
  ${emailActivityFragment}
`;
