import { Text } from "@ui-kitten/components";
import moment from "moment-timezone";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectUserById from "../../store/users/selectors/selectUserById";
import { UserRecord as UserRecordType } from "../../types";
import getUserFullName from "../../utils/getUserFullName";

type Props = {
  label?: string;
  style?: StyleProp<ViewStyle>;
  userRecord: UserRecordType;
};

const UserRecord = ({ label, style, userRecord }: Props) => {
  const user = useSelector((state) => selectUserById(state, userRecord.by));
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  return (
    <View style={style}>
      {label && <Text appearance="hint">{label}</Text>}
      <Text appearance="hint">
        {`${getUserFullName(user)} \u2022 ${moment(userRecord.at).format(
          dateTimeFormat
        )}`}
      </Text>
    </View>
  );
};

UserRecord.defaultProps = {
  label: undefined,
  style: undefined,
};

export default UserRecord;
