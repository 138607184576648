import { useTheme } from "@ui-kitten/components";
import React, { memo } from "react";
import { Pressable, StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  markerContainer: {
    borderRadius: 10,
    width: 20,
    height: 20,
    borderWidth: 2,
    borderColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  clickable: {
    alignItems: "center",
    cursor: "pointer",
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
});

interface SiteMarkerProps {
  id: string;
  onPress: (id: string) => void;
}

const SiteMarker = ({ id, onPress }: SiteMarkerProps) => {
  const theme = useTheme();
  const handlePress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress(id);
  };
  return (
    <Pressable onPress={handlePress} style={[styles.clickable, { zIndex: 0 }]}>
      <View
        style={[
          styles.markerContainer,
          { backgroundColor: theme["color-basic-600"] },
        ]}
      />
    </Pressable>
  );
};

export default memo(SiteMarker);
