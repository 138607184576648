/* eslint-disable react/no-array-index-key */
import { Button, Divider, Icon, Layout, Text } from "@ui-kitten/components";
import { FormikProps } from "formik";
import React, { Fragment, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import AlertModal from "../../../Alert";
import EditMediaDescriptionModal from "../../../EditMediaDescription";
import MediaViewerModal from "../../../MediaViewer";
import AddMediaButtons from "../../../../AddMediaButtons";
import HeaderWithTextAction from "../../../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../../../buildingBlocks/Separator";
import BaseListItem from "../../../../listItems/Base";
import MediaListItem from "../../../../listItems/Media";
import SubjectListItem from "../../../../listItems/Subject";
import VehicleListItem from "../../../../listItems/Vehicle";
import SectionItem from "../../../../SectionItem";
import TimeRangePicker from "../../../../BasicTimeRangePicker";
import useAndroidBackHandler from "../../../../../device/useAndroidBackHandler";
import useIosBottomSpace from "../../../../../device/useIosBottomSpace";
import useFormikMediaHelpers from "../../../../../hooks/useFormikMediaHelpers";
import { IncidentReport } from "../../../../../types";

type FormValues = Pick<
  IncidentReport,
  | "endDateTime"
  | "media"
  | "policeCase"
  | "startDateTime"
  | "subjects"
  | "summary"
  | "vehicles"
>;

interface Props {
  formik: FormikProps<FormValues>;
  incidentReport: IncidentReport;
  onClosePress: () => void;
  onDateTimesChange: () => void;
  onDeletePress: () => void;
  onMediaChange: () => void;
  onPoliceCasePress: () => void;
  onSubjectPress: (subjectIndex: number) => void;
  onSubmitPress: () => void;
  onSummaryPress: () => void;
  onVehiclePress: (vehicleIndex: number) => void;
  visible: boolean;
}

const styles = StyleSheet.create({
  addButtonContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  autoSaveMessage: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  listItemAccessory: {
    marginEnd: 8,
    marginStart: 8,
  },
  scrollContent: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
});

const MainScreen = ({
  formik,
  incidentReport,
  onClosePress,
  onDateTimesChange,
  onDeletePress,
  onMediaChange,
  onPoliceCasePress,
  onSubjectPress,
  onSubmitPress,
  onSummaryPress,
  onVehiclePress,
  visible,
}: Props) => {
  const iosBottomSpace = useIosBottomSpace();
  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(
    false
  );
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(
    false
  );
  const {
    AddMediaButtonsProps,
    DeleteMediaAlertModalProps,
    EditMediaDescriptionModalProps,
    MediaListItemProps,
    MediaViewerModalProps,
  } = useFormikMediaHelpers(formik);
  useAndroidBackHandler({ enabled: visible, onBackPress: onClosePress });
  return (
    <>
      <HeaderWithTextAction
        navigation={{
          "data-test": "closeButtonInIncidentReportEditingModal",
          icon: "close-outline",
          onPress: onClosePress,
        }}
        title="Edit incident report"
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView
          contentContainerStyle={[
            styles.scrollContent,
            incidentReport.submitted && { paddingBottom: iosBottomSpace + 8 },
          ]}
        >
          <Text
            appearance="hint"
            category="label"
            style={styles.autoSaveMessage}
          >
            All changes are automatically saved.
          </Text>
          <Separator />
          <SectionItem title="SUMMARY">
            <BaseListItem
              accessoryRight={(props) => (
                <Icon name="arrow-ios-forward-outline" {...props} />
              )}
              data-test="summaryButtonInIncidentReportEditingModal"
              description={!formik.values.summary && "Tap to edit"}
              onPress={onSummaryPress}
              title={formik.values.summary}
            />
          </SectionItem>
          <Separator />
          <SectionItem title="TIME">
            <TimeRangePicker
              divider={<Divider style={styles.divider} />}
              endDateTime={formik.values.endDateTime}
              onValueChange={({ startDateTime, endDateTime }) => {
                formik.setFieldValue("startDateTime", startDateTime);
                formik.setFieldValue("endDateTime", endDateTime);
                onDateTimesChange();
              }}
              startDateTime={formik.values.startDateTime}
            />
          </SectionItem>
          <Separator />
          <SectionItem title="POLICE CASE">
            {formik.values.policeCase ? (
              <BaseListItem
                title={
                  formik.values.policeCase.number || "Case # not specified"
                }
                description={formik.values.policeCase.agency}
                onPress={onPoliceCasePress}
                accessoryRight={(props) => (
                  <Icon name="arrow-ios-forward-outline" {...props} />
                )}
              />
            ) : (
              <View style={styles.addButtonContainer}>
                <Button
                  accessoryLeft={(props) => (
                    <Icon name="plus-outline" {...props} />
                  )}
                  data-test="policeCaseButtonInIncidentReportEditingModal"
                  onPress={onPoliceCasePress}
                  size="small"
                >
                  Add
                </Button>
              </View>
            )}
          </SectionItem>
          <Separator />
          <SectionItem title="SUBJECTS">
            <View style={styles.addButtonContainer}>
              <Button
                accessoryLeft={(props) => (
                  <Icon name="plus-outline" {...props} />
                )}
                data-test="subjectButtonInIncidentReportEditingModal"
                onPress={() => onSubjectPress(formik.values.subjects.length)}
                size="small"
              >
                Add
              </Button>
            </View>
            {Array.isArray(formik.values.subjects) &&
              formik.values.subjects.length > 0 && (
                <View style={styles.section}>
                  {formik.values.subjects.map((subject, index) => (
                    <Fragment key={`subject[${index}]`}>
                      {index > 0 && <Divider style={styles.divider} />}
                      <SubjectListItem
                        index={index}
                        subject={subject}
                        onPress={() => onSubjectPress(index)}
                        accessoryRight={(props) => (
                          <Icon name="arrow-ios-forward-outline" {...props} />
                        )}
                      />
                    </Fragment>
                  ))}
                </View>
              )}
          </SectionItem>
          <Separator />
          <SectionItem title="VEHICLES">
            <View style={styles.addButtonContainer}>
              <Button
                accessoryLeft={(props) => (
                  <Icon name="plus-outline" {...props} />
                )}
                data-test="vehicleButtonInIncidentReportEditingModal"
                onPress={() => onVehiclePress(formik.values.vehicles.length)}
                size="small"
              >
                Add
              </Button>
            </View>
            {Array.isArray(formik.values.vehicles) &&
              formik.values.vehicles.length > 0 && (
                <View style={styles.section}>
                  {formik.values.vehicles.map((vehicle, index) => (
                    <Fragment key={`vehicle[${index}]`}>
                      {index > 0 && <Divider style={styles.divider} />}
                      <VehicleListItem
                        index={index}
                        vehicle={vehicle}
                        onPress={() => onVehiclePress(index)}
                        accessoryRight={(props) => (
                          <Icon name="arrow-ios-forward-outline" {...props} />
                        )}
                      />
                    </Fragment>
                  ))}
                </View>
              )}
          </SectionItem>
          <Separator />
          <SectionItem
            caption="Images that cannot be uploaded will not be added to the report."
            title="MEDIA"
          >
            <Separator size="small" />
            <AddMediaButtons onAddMedia={AddMediaButtonsProps.onAddMedia} />
            <Separator size="small" />
            {Array.isArray(formik.values.media) &&
              formik.values.media.length > 0 && (
                <>
                  <Divider />
                  {formik.values.media.map((media, index) => (
                    <Fragment key={media.id}>
                      {index > 0 && <Divider style={styles.divider} />}
                      <MediaListItem
                        editable
                        media={media}
                        onDeletePress={MediaListItemProps.onDeletePress}
                        onDescriptionPress={
                          MediaListItemProps.onDescriptionPress
                        }
                        onPress={MediaListItemProps.onPress}
                        onUpload={(mediaId) => {
                          MediaListItemProps.onUpload(mediaId);
                          onMediaChange();
                        }}
                      />
                    </Fragment>
                  ))}
                </>
              )}
          </SectionItem>
          <Separator />
          <SectionItem status="danger" title="DANGER ZONE">
            <BaseListItem
              accessoryRight={() => (
                <Button
                  data-test="deleteButtonInIncidentReportEditingModal"
                  onPress={() => setDeleteConfirmationVisible(true)}
                  size="small"
                  status="danger"
                  style={styles.listItemAccessory}
                >
                  Delete
                </Button>
              )}
              description="Once you delete a report there is no going back."
              disabled
              title="Delete this report"
            />
          </SectionItem>
          <Separator />
        </ScrollView>
        {!incidentReport.submitted && (
          <>
            <Divider />
            <Layout>
              <SafeAreaView>
                <Separator size="small" />
                <Button
                  data-test="submitButtonInIncidentReportEditingModal"
                  onPress={onSubmitPress}
                  size="small"
                  style={styles.button}
                >
                  Submit
                </Button>
                <Separator size="small" />
              </SafeAreaView>
            </Layout>
          </>
        )}
      </Layout>
      <MediaViewerModal
        editable={MediaViewerModalProps.editable}
        isVisible={MediaViewerModalProps.isVisible}
        media={MediaViewerModalProps.media}
        onClose={MediaViewerModalProps.onClose}
        onDeletePress={MediaViewerModalProps.onDeletePress}
        onDescriptionPress={MediaViewerModalProps.onDescriptionPress}
      />
      <EditMediaDescriptionModal
        isVisible={EditMediaDescriptionModalProps.isVisible}
        jobId={incidentReport.jobId}
        onClose={EditMediaDescriptionModalProps.onClose}
        onSubmit={(text) => {
          EditMediaDescriptionModalProps.onSubmit(text);
          onMediaChange();
        }}
        text={EditMediaDescriptionModalProps.text}
      />
      <AlertModal
        cancelText={DeleteMediaAlertModalProps.cancelText}
        confirmText={DeleteMediaAlertModalProps.confirmText}
        isVisible={DeleteMediaAlertModalProps.isVisible}
        message={DeleteMediaAlertModalProps.message}
        onCancel={DeleteMediaAlertModalProps.onCancel}
        onClose={DeleteMediaAlertModalProps.onClose}
        onConfirm={DeleteMediaAlertModalProps.onConfirm}
      />
      <AlertModal
        cancelText="Keep editing"
        confirmText="Delete"
        isVisible={deleteConfirmationVisible}
        message="Are you sure you want to delete this report?"
        onCancel={() => setDeleteConfirmationVisible(false)}
        onClose={() => setDeleteConfirmationVisible(false)}
        onConfirm={() => {
          setDeleteConfirmationVisible(false);
          onDeletePress();
        }}
      />
      <AlertModal
        isVisible={closeConfirmationVisible}
        onClose={() => setCloseConfirmationVisible(false)}
        message="Are you sure you want to discard your changes?"
        confirmText="Keep editing"
        onConfirm={() => setCloseConfirmationVisible(false)}
        cancelText="Discard changes"
        onCancel={() => {
          setCloseConfirmationVisible(false);
          onClosePress();
        }}
      />
    </>
  );
};

export default MainScreen;
