import {
  createAction,
  createSlice,
  createAsyncThunk,
  Draft,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import Auth from "@aws-amplify/auth";
import { PURGE } from "redux-persist";

import { SignedInUser } from "../../../types";

export const userSignedOut = createAction("auth/userSignedOut");

export interface State {
  authenticating: boolean;
  error?: SerializedError;
  user?: SignedInUser;
}

const initialState: State = {
  authenticating: true,
  error: null,
  user: null,
};

type InitSessionPayload = {
  user: SignedInUser;
};

export const initSession = {
  pending: createAction("auth/initSession/pending"),
  fulfilled: createAction<InitSessionPayload>("auth/initSession/fulfilled"),
  rejected: createAction("auth/initSession/rejected"),
};

export const resetUser = createAsyncThunk("users/resetUser", async () => {
  await Auth.signOut();
});

const initPending = (state: Draft<State>) => {
  state.authenticating = true;
  state.error = null;
};

const initFulfilled = (
  state: Draft<State>,
  action: PayloadAction<InitSessionPayload>
) => {
  const { user } = action.payload;
  state.user = user;
  state.authenticating = false;
};

const initRejected = (state, action) => {
  const { error } = action;
  state.error = error;
  state.authenticating = false;
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initSession.pending, (state) => {
      initPending(state);
    });
    builder.addCase(initSession.fulfilled, (state, action) => {
      initFulfilled(state, action);
    });
    builder.addCase(initSession.rejected, (state, action) => {
      initRejected(state, action);
    });
    builder.addCase(resetUser.pending, (state) => {
      state.authenticating = true;
      state.error = null;
    });
    builder.addCase(resetUser.fulfilled, (state) => {
      state.user = null;
      state.authenticating = false;
    });
    builder.addCase(resetUser.rejected, (state, action) => {
      const { error } = action;
      state.error = error;
      state.authenticating = false;
    });
    builder.addCase(PURGE, () => initialState);
  },
});

const { reducer } = slice;

export default reducer;
