import React from "react";
import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

const Base = ({ children }) => {
  return (
    <Layout level="2" style={styles.root}>
      {children}
    </Layout>
  );
};

Base.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Base;
