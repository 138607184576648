/* eslint-disable react/no-array-index-key */
import { useNavigation } from "@react-navigation/native";
import {
  Divider,
  Icon,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import _ from "lodash/array";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import HeaderWithTextAction from "../../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../../components/buildingBlocks/Separator";
import Container from "../../../components/Container";
import MediaListItem from "../../../components/listItems/Media";
import SiteListItem from "../../../components/listItems/Site";
import SubjectListItem from "../../../components/listItems/Subject";
import VehicleListItem from "../../../components/listItems/Vehicle";
import Marker from "../../../components/markers/Marker";
import IncidentReportEditingModal from "../../../components/modals/ReportEditing/Incident";
import MediaViewerModal from "../../../components/modals/MediaViewer";
import SectionItem from "../../../components/SectionItem";
import StaticMapView from "../../../components/map/StaticMapView";
import TimeRange from "../../../components/TimeRange";
import UserRecord from "../../../components/UserRecord";
import companyUserRoles from "../../../constants/companyUserRoles";
import navigationRoutes from "../../../constants/navigationRoutes";
import useIosBottomSpace from "../../../device/useIosBottomSpace";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import selectSiteById from "../../../store/sites/selectors/selectById";
import selectClockedIn from "../../../store/users/selectors/selectClockedIn";
import selectUserById from "../../../store/users/selectors/selectUserById";
import getDeltaFromZoom from "../../../utils/getDeltaFromZoom";
import getUserFullName from "../../../utils/getUserFullName";
import useClockInProps from "../../../hooks/useClockInProps";

const delta = getDeltaFromZoom(18);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  mapContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  mapFrame: {
    borderRadius: 4,
    overflow: "hidden",
  },
  root: {
    flex: 1,
  },
  summary: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  timeRange: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  userRecord: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const IncidentReportDetails = ({ incidentReport }) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const theme = useTheme();
  const iosBottomSpace = useIosBottomSpace();
  const site = useSelector((state) =>
    selectSiteById(state, incidentReport.siteId)
  );
  const role = useSelector(selectUser).group;
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const clockedIn = useSelector(selectClockedIn);
  const { ConditionalTooltipProps } = useClockInProps({ disabled: false });
  const [mediaViewer, setMediaViewer] = useState({
    visible: false,
    media: null,
  });
  const [
    incidentReportEditingVisible,
    setIncidentReportEditingVisible,
  ] = useState(false);
  const submittedByUser = useSelector((state) =>
    selectUserById(
      state,
      incidentReport.submitted && incidentReport.submitted.by
    )
  );
  const viewMedia = (mediaId) => {
    const index = _.findIndex(
      incidentReport.media,
      (media) => media.id === mediaId
    );
    setMediaViewer({ visible: true, media: incidentReport.media[index] });
  };
  const renderDateTimes = () => {
    if (incidentReport.startDateTime || incidentReport.endDateTime) {
      if (incidentReport.startDateTime && incidentReport.endDateTime) {
        return (
          <TimeRange
            endDateTime={incidentReport.endDateTime}
            startDateTime={incidentReport.startDateTime}
            style={styles.timeRange}
            textStyle={{ color: theme["text-hint-color"] }}
          />
        );
      }
      return (
        <Text appearance="hint" style={styles.timeRange}>
          {moment(
            incidentReport.startDateTime || incidentReport.endDateTime
          ).format(dateTimeFormat)}
        </Text>
      );
    }
    return null;
  };
  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              action={
                role !== companyUserRoles.EMPLOYEE
                  ? {
                      ConditionalTooltipProps,
                      "data-test": "editButtonInIncidentReportDetails",
                      disabled: !clockedIn,
                      loading: false,
                      onPress: () => setIncidentReportEditingVisible(true),
                      text: "Edit",
                    }
                  : undefined
              }
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="Incident report details"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: iosBottomSpace + 8 }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Container>
              <Layout>
                <Separator size="medium" />
                <Text category="h6" style={styles.text}>
                  {`Submitted by ${getUserFullName(submittedByUser)}`}
                </Text>
                <Text style={styles.text}>
                  {moment(incidentReport.submitted.at).format(dateTimeFormat)}
                </Text>
                <Separator size="medium" />
                {Boolean(
                  incidentReport.startDateTime ||
                    incidentReport.endDateTime ||
                    incidentReport.summary
                ) && (
                  <>
                    <Divider style={styles.divider} />
                    <Separator size="medium" />
                    {renderDateTimes()}
                    {Boolean(incidentReport.summary) && (
                      <>
                        {Boolean(
                          incidentReport.startDateTime ||
                            incidentReport.endDateTime
                        ) && <Separator size="small" />}
                        <Text style={styles.summary}>
                          {incidentReport.summary}
                        </Text>
                      </>
                    )}
                    <Separator size="medium" />
                  </>
                )}
              </Layout>
              <Divider />
              {incidentReport.siteId && site && (
                <>
                  <Separator />
                  <SectionItem title="SITE">
                    <SiteListItem siteId={incidentReport.siteId} />
                    <View style={styles.mapContainer}>
                      <Layout level="2" style={styles.mapFrame}>
                        <StaticMapView
                          region={{
                            latitude: site.coordinates.lat,
                            longitude: site.coordinates.lng,
                            latitudeDelta: delta,
                            longitudeDelta: delta,
                          }}
                          showsPointsOfInterest={false}
                        >
                          <Marker
                            coordinate={{
                              latitude: site.coordinates.lat,
                              longitude: site.coordinates.lng,
                            }}
                          />
                        </StaticMapView>
                      </Layout>
                    </View>
                  </SectionItem>
                </>
              )}
              {incidentReport.policeCase && (
                <>
                  <Separator />
                  <SectionItem title="POLICE CASE">
                    <ListItem
                      title={
                        incidentReport.policeCase.number ||
                        "Case # not specified"
                      }
                      description={incidentReport.policeCase.agency}
                      disabled
                    />
                  </SectionItem>
                </>
              )}
              {incidentReport.subjects.length > 0 && (
                <>
                  <Separator />
                  <SectionItem title="SUBJECTS">
                    {incidentReport.subjects.map((subject, index) => (
                      <SubjectListItem
                        key={`subject[${index}]`}
                        index={index}
                        subject={subject}
                        onPress={() =>
                          navigation.navigate(navigationRoutes.SubjectDetails, {
                            reportId: incidentReport.id,
                            jobId: incidentReport.jobId,
                            index,
                          })
                        }
                        accessoryRight={(props) => (
                          <Icon {...props} name="arrow-ios-forward-outline" />
                        )}
                      />
                    ))}
                  </SectionItem>
                </>
              )}
              {incidentReport.vehicles.length > 0 && (
                <>
                  <Separator />
                  <SectionItem title="VEHICLES">
                    {incidentReport.vehicles.map((vehicle, index) => (
                      <View key={`vehicle[${index}]`}>
                        {index > 0 && <Divider style={styles.divider} />}
                        <VehicleListItem
                          index={index}
                          vehicle={vehicle}
                          onPress={() =>
                            navigation.navigate(
                              navigationRoutes.VehicleDetails,
                              {
                                reportId: incidentReport.id,
                                jobId: incidentReport.jobId,
                                index,
                              }
                            )
                          }
                          accessoryRight={(props) => (
                            <Icon {...props} name="arrow-ios-forward-outline" />
                          )}
                        />
                      </View>
                    ))}
                  </SectionItem>
                </>
              )}
              {incidentReport.media.length > 0 && (
                <>
                  <Separator />
                  <SectionItem title="MEDIA">
                    {incidentReport.media.map((media, index) => (
                      <Fragment key={media.id}>
                        {index > 0 && <Divider style={styles.divider} />}
                        <MediaListItem media={media} onPress={viewMedia} />
                      </Fragment>
                    ))}
                  </SectionItem>
                </>
              )}
              <Separator />
              <UserRecord
                label="Created:"
                style={styles.userRecord}
                userRecord={incidentReport.created}
              />
              {incidentReport.updated && (
                <>
                  <Separator size="small" />
                  <UserRecord
                    label="Last updated:"
                    style={styles.userRecord}
                    userRecord={incidentReport.updated}
                  />
                </>
              )}
              <Separator />
            </Container>
          </ScrollView>
        </Layout>
      </Layout>
      <MediaViewerModal
        isVisible={mediaViewer.visible}
        onClose={() =>
          setMediaViewer((prevState) => ({ ...prevState, visible: false }))
        }
        media={mediaViewer.media}
        editable={false}
      />
      <IncidentReportEditingModal
        incidentReport={incidentReport}
        isVisible={incidentReportEditingVisible}
        onClosePress={() => setIncidentReportEditingVisible(false)}
        onDelete={() => {
          setIncidentReportEditingVisible(false);
          navigation.goBack();
        }}
        onSubmit={() => setIncidentReportEditingVisible(false)}
      />
    </>
  );
};

IncidentReportDetails.propTypes = {
  incidentReport: PropTypes.shape({
    created: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
    endDateTime: PropTypes.string,
    id: PropTypes.string,
    jobId: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape({})),
    policeCase: PropTypes.shape({
      agency: PropTypes.string,
      number: PropTypes.string,
    }),
    siteId: PropTypes.string,
    startDateTime: PropTypes.string,
    subjects: PropTypes.arrayOf(PropTypes.shape({})),
    submitted: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
    summary: PropTypes.string,
    updated: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
    vehicles: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default IncidentReportDetails;
