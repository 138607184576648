import gql from "graphql-tag";

import { usageRecordFragment } from "../../../fragments";

export default gql`
  mutation createUsageRecord($timestamp: AWSDateTime!) {
    createUsageRecord(timestamp: $timestamp) {
      ...usageRecordFragment
    }
  }
  ${usageRecordFragment}
`;
