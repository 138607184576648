import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigationAction,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { FlatList, Keyboard, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import styled from "styled-components/native";
import Button from "../../components/buildingBlocks/Button";

import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import CustomerListItem from "../../components/listItems/Customer";
import CreateCustomerModal from "../../components/modals/CreateCustomer";
import companyUserRoles from "../../constants/companyUserRoles";
import useCustomerList from "../../hooks/useCustomerList";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import { StackParamList } from "../../types";
import isCustomersEnabled from "../../utils/isCustomersEnabled";

const ButtonContainer = styled.View`
  flex-direction: row;
  padding: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const styles = StyleSheet.create({
  inputContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  root: {
    flex: 1,
  },
});

const Customers = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Customers">
  >();
  const company = useSelector(selectCompany);
  const signedInUserRole = useSelector(selectSignedInUserRole);
  const { customerIds, searchText, setSearchText } = useCustomerList({
    includeArchived: true,
  });
  const [createCustomerVisible, setCreateCustomerVisible] = useState(false);

  const customersEnabled = isCustomersEnabled(company);

  return (
    <>
      <Layout
        style={[
          styles.root,
          {
            paddingEnd: insets.right,
            paddingStart: insets.left,
            paddingTop: insets.top,
          },
        ]}
      >
        <Container>
          <Header
            accessoryRight={() => {
              if (
                customersEnabled &&
                signedInUserRole !== companyUserRoles.EMPLOYEE
              ) {
                return (
                  <TopNavigationAction
                    data-test="plusButtonInCustomersScreen"
                    icon={(props) => <Icon name="plus-outline" {...props} />}
                    onPress={() => {
                      Keyboard.dismiss();
                      setCreateCustomerVisible(true);
                    }}
                  />
                );
              }
              return null;
            }}
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Customers"
          />
          <View style={styles.inputContainer}>
            <Input
              clearButtonMode="while-editing"
              onChangeText={setSearchText}
              placeholder="Search..."
              value={searchText}
            />
          </View>
        </Container>
        <Divider />
        {customersEnabled ? (
          <FlatList
            contentContainerStyle={{ paddingBottom: insets.bottom }}
            data={customerIds}
            data-test="listInCustomersScreen"
            keyboardShouldPersistTaps="handled"
            keyExtractor={(customerId) => customerId}
            renderItem={({ item: customerId }) => (
              <Container>
                <CustomerListItem
                  customerId={customerId}
                  data-test={`${customerId}-customerListItem`}
                  onPress={() =>
                    navigation.navigate("CustomerDetails", { customerId })
                  }
                />
              </Container>
            )}
          />
        ) : (
          <>
            <Container>
              <Separator />
              <StyledText>
                In order to utilize Customers, it must first be enabled.
              </StyledText>
              <Separator size="medium" />
              <ButtonContainer>
                <Button onPress={() => navigation.navigate("Settings")}>
                  Settings
                </Button>
              </ButtonContainer>
              <Separator />
            </Container>
          </>
        )}
      </Layout>
      <CreateCustomerModal
        isVisible={createCustomerVisible}
        onClose={() => setCreateCustomerVisible(false)}
        onSuccess={() => {
          setCreateCustomerVisible(false);
          addNotification({
            status: "success",
            title: "Customer created",
          });
        }}
      />
    </>
  );
};

export default Customers;
