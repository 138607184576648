import { createSelector } from "@reduxjs/toolkit";

import selectUserEntities from "../selectEntities";
import selectUser from "../../../auth/selectors/selectUser";

const selectClockedIn = createSelector(
  [selectUser, selectUserEntities],
  (authUser, users) => {
    const { id } = authUser;
    return users[id]?.clockedIn;
  }
);

export default selectClockedIn;
