import getUserDistanceInfo from "../getUserDistanceInfo";
import { addNotification } from "../../components/InAppNotifications";
import companyUserRoles from "../../constants/companyUserRoles";
import { CompanyUserRole, Coordinates } from "../../types";

interface Params {
  bypassGeofence?: boolean;
  errorTitle: string;
  location: {
    coordinates: Coordinates;
    geofence: number;
  };
  onSuccess: (userCoordinates: Coordinates) => void;
  userRole: CompanyUserRole;
}

const runGeofenceDependentFunction = async ({
  bypassGeofence = false,
  errorTitle,
  location: { coordinates, geofence },
  onSuccess,
  userRole,
}: Params) => {
  try {
    const {
      distanceFromGeofence,
      isWithinGeofence,
      userCoordinates,
    } = await getUserDistanceInfo({
      coordinates,
      geofence,
    });
    if (
      userRole !== companyUserRoles.EMPLOYEE ||
      isWithinGeofence ||
      bypassGeofence
    ) {
      onSuccess({
        accuracy: userCoordinates.accuracy,
        lat: userCoordinates.latitude,
        lng: userCoordinates.longitude,
      });
    } else {
      addNotification({
        message: `You are ${distanceFromGeofence.toFixed(
          1
        )} m outside of the geofence.`,
        status: "danger",
        title: errorTitle,
      });
    }
  } catch (error) {
    addNotification({
      message: error?.message || error,
      status: "danger",
      title: errorTitle,
    });
  }
};

export default runGeofenceDependentFunction;
