import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeProduct } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdateProductInput
  extends Pick<Stripe.ProductUpdateParams, "active" | "name"> {
  id: string;
}

const updateProduct = async ({ active, id, name }: UpdateProductInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        active,
        id,
        name,
      },
    })) as GraphQLResult<{ updateProduct: StripeProduct }>;
    return data.updateProduct;
  } catch ({ errors }) {
    throw generateErrorMessage("updateProduct", errors);
  }
};

export default updateProduct;
