import { createSelector } from "@reduxjs/toolkit";

import selectSites from "../selectSites";

const selectSiteEntities = createSelector(
  [selectSites],
  (sites) => sites.entities
);

export default selectSiteEntities;
