import { Icon, Text, useTheme } from "@ui-kitten/components";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import BaseListItem from "../Base";
import Separator from "../../buildingBlocks/Separator";
import selectReportByIdAndJobId from "../../../store/jobs/selectors/selectReportByIdAndJobId";
import selectUserById from "../../../store/users/selectors/selectUserById";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import selectClockedIn from "../../../store/users/selectors/selectClockedIn";
import getUserFullName from "../../../utils/getUserFullName";
import ClockInTooltip from "../../ClockInTooltip";

const styles = StyleSheet.create({
  statusFlair: {
    borderRadius: 4,
    color: "white",
    overflow: "hidden",
    paddingEnd: 4,
    paddingStart: 4,
  },
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const ReportListItem = ({ id, jobId, onPress }) => {
  const theme = useTheme();
  const report = useSelector((state) =>
    selectReportByIdAndJobId(state, { id, jobId })
  );
  const { created } = report;
  const createdByUser = useSelector((state) =>
    selectUserById(state, created.by)
  );
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const clockedIn = useSelector(selectClockedIn);
  const renderReportType = () => {
    switch (report.__typename) {
      case "DailyActivityReport":
        return "Daily activity";
      case "IncidentReport":
        return "Incident";
      default:
        return "[undefined]";
    }
  };
  const disabled = !report.completed && !clockedIn;
  return (
    <ClockInTooltip disabled={Boolean(report.completed)}>
      <BaseListItem
        accessoryRight={() =>
          !disabled && (
            <Icon
              name="chevron-right-outline"
              width={32}
              height={32}
              fill={theme["text-hint-color"]}
            />
          )
        }
        description={(textProps) => {
          const { style } = textProps;
          return (
            <Text
              style={[
                style,
                disabled && { color: theme["text-disabled-color"] },
              ]}
            >{`${getUserFullName(createdByUser)} \u2022 ${moment(
              created.at
            ).format(dateTimeFormat)}`}</Text>
          );
        }}
        disabled={disabled}
        onPress={onPress}
        title={() => (
          <View style={styles.titleContainer}>
            <Text
              category="s2"
              style={disabled && { color: theme["text-disabled-color"] }}
            >
              {renderReportType()}
            </Text>
            {!report.submitted && (
              <>
                <Separator horizontal size="small" />
                <Text
                  category="label"
                  style={[
                    styles.statusFlair,
                    {
                      backgroundColor: theme["color-danger-default"],
                    },
                  ]}
                >
                  not submitted
                </Text>
              </>
            )}
          </View>
        )}
        style={
          disabled && { backgroundColor: theme["color-basic-transparent-300"] }
        }
      />
    </ClockInTooltip>
  );
};

ReportListItem.propTypes = {
  id: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

ReportListItem.defaultProps = {
  onPress: undefined,
};

export default ReportListItem;
