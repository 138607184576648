import gql from "graphql-tag";

import { dailyActivityReportFragment } from "../../../fragments";

export default gql`
  mutation createDailyActivityReport(
    $id: ID!
    $timestamp: AWSDateTime!
    $jobId: ID!
    $started: LocationRecordInput!
  ) {
    createDailyActivityReport(
      id: $id
      timestamp: $timestamp
      jobId: $jobId
      started: $started
    ) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;
