import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import ActionItemCompletionRecordDetails from "../../screens/ActionItemCompletionRecordDetails";
import ActionItemList from "../../screens/ActionItemList";
import Billing from "../../screens/Billing";
import CompanyGroups from "../../screens/CompanyGroups";
import CompanySites from "../../screens/CompanySites";
import CompanyUsers from "../../screens/CompanyUsers";
import CustomerDetails from "../../screens/CustomerDetails";
import CustomerEmailActivity from "../../screens/CustomerEmailActivity";
import Customers from "../../screens/Customers";
import EmailActivity from "../../screens/EmailActivity";
import GroupDetails from "../../screens/GroupDetails";
import InboxScreen from "../../screens/Inbox";
import InboxItemDetails from "../../screens/InboxItemDetails";
import InvoiceDetails from "../../screens/InvoiceDetails";
import InvoiceEmailActivity from "../../screens/InvoiceEmailActivity";
import JobDetails from "../../screens/JobDetails";
import Invoices from "../../screens/Invoices";
import ProductDetails from "../../screens/ProductDetails";
import Products from "../../screens/Products";
import QuoteDetails from "../../screens/QuoteDetails";
import QuoteEmailActivity from "../../screens/QuoteEmailActivity";
import Quotes from "../../screens/Quotes";
import ReminderDetails from "../../screens/ReminderDetails";
import Reminders from "../../screens/Reminders";
import ReportDetails from "../../screens/ReportDetails";
import Settings from "../../screens/Settings";
import SignIn from "../../screens/SignIn";
import SiteDetails from "../../screens/SiteDetails";
import SubjectDetails from "../../screens/SubjectDetails";
import TaskCompletionRecordDetails from "../../screens/TaskCompletionRecordDetails";
import TaxRateDetails from "../../screens/TaxRateDetails";
import TaxRates from "../../screens/TaxRates";
import TimeCardDetails from "../../screens/TimeCardDetails";
import UserDetails from "../../screens/UserDetails";
import VehicleDetails from "../../screens/VehicleDetails";
import { SignedInUser, StackParamList } from "../../types";
import MainDrawer from "../MainDrawer";
import { navigationRef } from "../navigator";

const Stack = createStackNavigator<StackParamList>();

interface Props {
  user?: SignedInUser;
}

const RootNavigation = ({ user }: Props) => {
  const renderStack = () => {
    if (user) {
      return (
        <>
          <Stack.Screen name="MainDrawer" component={MainDrawer} />
          <Stack.Screen
            name="ActionItemCompletionRecordDetails"
            component={ActionItemCompletionRecordDetails}
          />
          <Stack.Screen
            component={ActionItemList}
            name="ActionItemList"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Billing}
            name="Billing"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={InboxScreen}
            name="Inbox"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={EmailActivity}
            name="EmailActivity"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={CompanyGroups}
            name="CompanyGroups"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={CompanySites}
            name="CompanySites"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={CompanyUsers}
            name="CompanyUsers"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={CustomerDetails}
            name="CustomerDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={CustomerEmailActivity}
            name="CustomerEmailActivity"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Customers}
            name="Customers"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={GroupDetails}
            name="GroupDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={InboxItemDetails}
            name="InboxItemDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={InvoiceDetails}
            name="InvoiceDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={InvoiceEmailActivity}
            name="InvoiceEmailActivity"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Invoices}
            name="Invoices"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={JobDetails}
            name="JobDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={ProductDetails}
            name="ProductDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Products}
            name="Products"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={QuoteDetails}
            name="QuoteDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={QuoteEmailActivity}
            name="QuoteEmailActivity"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Quotes}
            name="Quotes"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={ReminderDetails}
            name="ReminderDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Reminders}
            name="Reminders"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={ReportDetails}
            name="ReportDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={Settings}
            name="Settings"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={SiteDetails}
            name="SiteDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={SubjectDetails}
            name="SubjectDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={TaskCompletionRecordDetails}
            name="TaskCompletionRecordDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={TaxRateDetails}
            name="TaxRateDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={TaxRates}
            name="TaxRates"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={TimeCardDetails}
            name="TimeCardDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={UserDetails}
            name="UserDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
          <Stack.Screen
            component={VehicleDetails}
            name="VehicleDetails"
            options={{ cardStyle: { flex: 1 } }}
          />
        </>
      );
    }
    return (
      <Stack.Screen
        name="SignIn"
        component={SignIn}
        options={{
          animationTypeForReplace: "pop",
        }}
      />
    );
  };
  return (
    <>
      <NavigationContainer ref={navigationRef}>
        <Stack.Navigator
          screenOptions={{ headerShown: false, gestureEnabled: false }}
        >
          {renderStack()}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
};

RootNavigation.defaultProps = {
  user: null,
};

export default RootNavigation;
