import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import momentFormats from "../../../constants/momentFormats";
import { ColorScheme } from "../../../types";

export interface SettingsState {
  appearance: {
    useSystemColorScheme: boolean;
    systemColorScheme: ColorScheme;
    manualColorScheme: ColorScheme;
  };
  signInWithPhone: boolean;
  time: {
    useAmpmTime: boolean;
    dateTimeFormat: string;
    timeFormat: string;
  };
  unreadMsgCount: number;
}

const initialState: SettingsState = {
  appearance: {
    useSystemColorScheme: true,
    systemColorScheme: "no-preference",
    manualColorScheme: "light",
  },
  signInWithPhone: true,
  time: {
    useAmpmTime: true,
    dateTimeFormat: momentFormats.ampmDateTimeFormat,
    timeFormat: momentFormats.ampmTimeFormat,
  },
  unreadMsgCount: 0,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    useSystemColorSchemeToggled: (state, action) => {
      const { enabled } = action.payload;
      state.appearance.useSystemColorScheme = enabled;
    },
    systemColorSchemeChanged: (state, action) => {
      const { colorScheme } = action.payload;
      state.appearance.systemColorScheme = colorScheme;
    },
    manualColorSchemeChanged: (state, action) => {
      const { colorScheme } = action.payload;
      state.appearance.manualColorScheme = colorScheme;
    },
    setUseAmpmTime: (state, action) => {
      const { useAmpmTime } = action.payload;
      state.time.useAmpmTime = useAmpmTime;
      if (useAmpmTime) {
        state.time.dateTimeFormat = momentFormats.ampmDateTimeFormat;
        state.time.timeFormat = momentFormats.ampmTimeFormat;
      } else {
        state.time.dateTimeFormat = momentFormats.dateTimeFormat;
        state.time.timeFormat = momentFormats.timeFormat;
      }
    },
    setSignInWithPhone: (state, action) => {
      const { signInWithPhone } = action.payload;
      state.signInWithPhone = signInWithPhone;
    },
    setUnreadMsgCount: (state, action) => {
      const { count } = action.payload;
      state.unreadMsgCount = count;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => ({
      ...initialState,
      appearance: state.appearance,
      time: state.time,
    }));
  },
});

const { actions, reducer } = settingsSlice;

export const {
  manualColorSchemeChanged,
  systemColorSchemeChanged,
  setUseAmpmTime,
  useSystemColorSchemeToggled,
  setSignInWithPhone,
  setUnreadMsgCount,
} = actions;

export default reducer;
