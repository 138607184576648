import { Button, Icon } from "@ui-kitten/components";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import Separator from "../buildingBlocks/Separator";
import { Media, MediaOnDevice } from "../../types";

export interface Props {
  media: Media | MediaOnDevice;
  onDeletePress: (mediaId: string) => void;
  onDescriptionPress: (mediaId: string) => void;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
  },
  root: {
    flexDirection: "row",
  },
});

const MediaActions = ({
  media,
  onDeletePress,
  onDescriptionPress,
  style,
}: Props) => {
  return (
    <View style={[styles.root, style]}>
      <View style={styles.buttonContainer}>
        <Button
          accessoryLeft={(props) => <Icon name="edit-2-outline" {...props} />}
          appearance="ghost"
          disabled={"uri" in media && !media.uploaded}
          onPress={() => onDescriptionPress(media.id)}
          size="small"
        >
          Description
        </Button>
      </View>
      <Separator horizontal size="small" />
      <View style={styles.buttonContainer}>
        <Button
          accessoryLeft={(props) => <Icon name="trash-2-outline" {...props} />}
          appearance="ghost"
          onPress={() => onDeletePress(media.id)}
          size="small"
          status="danger"
        >
          Delete
        </Button>
      </View>
    </View>
  );
};

MediaActions.defaultProps = {
  style: undefined,
};

export default MediaActions;
