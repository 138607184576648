import {
  Divider,
  Icon,
  Input,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Keyboard, ScrollView, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { AsYouType } from "libphonenumber-js";

import AlertModal from "../Alert";
import FullScreenModal from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import SectionItem from "../../SectionItem";
import adminCreateCompanyUser from "../../../api/functions/adminCreateCompanyUser";
import companyUserRoles from "../../../constants/companyUserRolesExtended";
import useIosBottomSpace from "../../../device/useIosBottomSpace";
import { setUser } from "../../../store/users/slice";
import { CompanyUserRole } from "../../../types";
import formikUsernameValidationSchema from "../../../utils/formikUsernameValidationSchema";

interface FormValues {
  username: string;
  firstName: string;
  lastName: string;
  role: CompanyUserRole;
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const roles = Object.values(companyUserRoles);

const styles = StyleSheet.create({
  choose: {
    padding: 16,
  },
  listItem: {
    borderRadius: 8,
    borderWidth: 1,
  },
  root: {
    flex: 1,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  scrollContent: {
    paddingTop: 8,
  },
  separator: {
    height: 24,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  inputSeparator: {
    height: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  inputAccessoryLeft: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  header: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  keyboardHelper: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
});

const CreateUserModal = ({ isVisible, onClose, onSuccess }: Props) => {
  const dispatch = useDispatch();
  const iosBottomSpace = useIosBottomSpace();
  const [alertVisible, setAlertVisible] = useState(false);
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [signInWithPhone, setSignInWithPhone] = useState(false);
  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      role: companyUserRoles.EMPLOYEE.value,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("Required"),
      lastName: yup.string().required("Required"),
      username: formikUsernameValidationSchema(signInWithPhone),
    }),
    onSubmit: async (values, actions) => {
      try {
        let phoneNumberInput = null;
        if (signInWithPhone) {
          const phoneNumber = new AsYouType("US");
          phoneNumber.input(values.username);
          phoneNumberInput = phoneNumber.getNumber().number as string;
        }
        const createdUser = await adminCreateCompanyUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: !signInWithPhone ? values.username.trim() : null,
          phone: phoneNumberInput,
          role: values.role,
        });
        dispatch(setUser({ user: createdUser }));
        addNotification({
          status: "success",
          title: "User created",
        });
        onSuccess();
      } catch (error) {
        addNotification({
          message: (error && error.message) || error,
          status: "danger",
          title: "User creation failed",
        });
      }
      actions.setSubmitting(false);
    },
  });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
      setAlertVisible(false);
      setShowUsernameInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const theme = useTheme();

  const handleClose = () => {
    Keyboard.dismiss();
    if (formik.dirty) {
      setAlertVisible(true);
    } else {
      onClose();
    }
  };

  const handleBack = () => {
    Keyboard.dismiss();
    formik.resetForm();
    setShowUsernameInput(false);
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={handleClose}>
        <HeaderWithTextAction
          action={{
            loading: formik.isSubmitting,
            onPress: formik.handleSubmit,
            text: "Add",
            "data-test": "submitUserEditButton",
          }}
          isActionVisible={showUsernameInput}
          navigation={
            !showUsernameInput
              ? { icon: "close-outline", onPress: handleClose }
              : { icon: "arrow-back", onPress: handleBack }
          }
          title="New user"
        />
        <Divider />
        <Layout level="2" style={styles.root}>
          <ScrollView
            contentContainerStyle={[
              styles.scrollContent,
              { paddingBottom: iosBottomSpace + 8 },
            ]}
          >
            {!showUsernameInput && (
              <View style={styles.choose}>
                <Text category="h5">How will the user sign in?</Text>
                <Separator size="small" />
                <ListItem
                  data-test="createUserWithPhone"
                  accessoryLeft={(imageProps) => (
                    <Icon
                      {...imageProps}
                      name="cellphone"
                      pack="MaterialCommunityIcons"
                    />
                  )}
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => {
                    setShowUsernameInput(true);
                    setSignInWithPhone(true);
                  }}
                  style={[
                    styles.listItem,
                    { borderColor: theme["border-basic-color-3"] },
                  ]}
                  title="With phone number"
                />
                <Separator size="small" />
                <ListItem
                  data-test="createUserWithEmail"
                  accessoryLeft={(imageProps) => (
                    <Icon
                      {...imageProps}
                      name="at"
                      pack="MaterialCommunityIcons"
                    />
                  )}
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => {
                    setShowUsernameInput(true);
                    setSignInWithPhone(false);
                  }}
                  style={[
                    styles.listItem,
                    { borderColor: theme["border-basic-color-3"] },
                  ]}
                  title="With email"
                />
              </View>
            )}
            {showUsernameInput && (
              <>
                <Separator />
                <SectionItem title="User info">
                  <Separator size="small" />
                  <Input
                    autoCapitalize="none"
                    blurOnSubmit={false}
                    disabled={formik.isSubmitting}
                    autoComplete={signInWithPhone ? "tel" : "email"}
                    textContentType={
                      signInWithPhone ? "telephoneNumber" : "emailAddress"
                    }
                    keyboardType={
                      signInWithPhone ? "number-pad" : "email-address"
                    }
                    data-test="userUsernameInput"
                    label={signInWithPhone ? "Phone number" : "Email"}
                    onBlur={() => {
                      formik.setFieldTouched("username");
                    }}
                    onChangeText={formik.handleChange("username")}
                    returnKeyType="next"
                    style={styles.input}
                    status={
                      formik.errors.username &&
                      formik.touched.username &&
                      "danger"
                    }
                    placeholder={
                      signInWithPhone ? "(xxx) xxx-xxxx" : "example@domain.com"
                    }
                    value={
                      signInWithPhone
                        ? new AsYouType("US").input(formik.values.username)
                        : formik.values.username
                    }
                    autoCorrect={false}
                  />
                  <Separator size="small" />
                  <Input
                    data-test="userFirstNameInput"
                    value={formik.values.firstName}
                    onChangeText={formik.handleChange("firstName")}
                    autoCapitalize="words"
                    blurOnSubmit={false}
                    caption={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : null
                    }
                    disabled={formik.isSubmitting}
                    label="First name"
                    onFocus={() => {
                      formik.setFieldTouched("firstName");
                    }}
                    returnKeyType="next"
                    status={
                      formik.touched.firstName && formik.errors.firstName
                        ? "danger"
                        : "basic"
                    }
                    style={styles.input}
                  />
                  <Separator size="small" />
                  <Input
                    data-test="userLastNameInput"
                    value={formik.values.lastName}
                    onChangeText={formik.handleChange("lastName")}
                    autoCapitalize="words"
                    blurOnSubmit={false}
                    caption={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : null
                    }
                    disabled={formik.isSubmitting}
                    label="Last name"
                    onFocus={() => {
                      formik.setFieldTouched("lastName");
                    }}
                    returnKeyType="next"
                    status={
                      formik.touched.lastName && formik.errors.lastName
                        ? "danger"
                        : "basic"
                    }
                    style={styles.input}
                  />
                  <Separator size="small" />
                </SectionItem>
                <Divider />
                <Separator />
                <SectionItem title="ROLE">
                  {roles.map((role) => {
                    const { value, description, permissions } = role;
                    return (
                      <Fragment key={value}>
                        <ListItem
                          data-test={`user${value}RoleButton`}
                          onPress={() => formik.setFieldValue("role", value)}
                          accessoryRight={(props) =>
                            value === formik.values.role ? (
                              <Icon
                                {...props}
                                name="checkmark-outline"
                                fill={theme["color-primary-default"]}
                              />
                            ) : (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <View {...props} />
                            )
                          }
                          description={`${description}\n\nPermissions:\n- ${permissions.join(
                            "\n- "
                          )}`}
                          title={value}
                        />
                        <Divider />
                      </Fragment>
                    );
                  })}
                </SectionItem>
                <Separator />
              </>
            )}
          </ScrollView>
        </Layout>
      </FullScreenModal>
      <AlertModal
        isVisible={alertVisible}
        onClose={() => setAlertVisible(false)}
        message="Are you sure you want to discard this new user?"
        confirmText="Keep editing"
        onConfirm={() => setAlertVisible(false)}
        cancelText="Discard changes"
        onCancel={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
    </>
  );
};

export default CreateUserModal;
