import gql from "graphql-tag";

import { locationHistory } from "../../../fragments";

export default gql`
  query adminQueryLocationHistory(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $after: ID
    $userId: ID!
  ) {
    adminQueryLocationHistory(
      from: $from
      to: $to
      after: $after
      userId: $userId
    ) {
      __typename
      nextToken
      items {
        ...locationHistory
      }
    }
  }
  ${locationHistory}
`;
