import { createSelector } from "@reduxjs/toolkit";

import selectCustomers from "../selectCustomers";

const selectCustomerById = createSelector(
  [selectCustomers, (state, id: string) => id],
  (customers, id) => customers.entities[id]
);

export default selectCustomerById;
