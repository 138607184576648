import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import selectCustomers from "../../store/customers/selectors/selectCustomers";
import { Customer, EntityState } from "../../types";

const customerPassesArchivedFilter = ({
  customer,
  includeArchived,
}: {
  customer: Customer;
  includeArchived: boolean;
}) => includeArchived || !customer.isArchived;

const filterCustomerIds = ({
  customers,
  includeArchived,
  searchText,
}: {
  customers: EntityState<Customer>;
  includeArchived: boolean;
  searchText: string;
}) => {
  const filteredCustomerIds = searchText
    ? customers.ids.filter((customerId) => {
        const customer = customers.entities[customerId];
        return (
          customerPassesArchivedFilter({ customer, includeArchived }) &&
          customers.entities[customerId].name
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        );
      })
    : customers.ids.filter((customerId) =>
        customerPassesArchivedFilter({
          customer: customers.entities[customerId],
          includeArchived,
        })
      );
  return filteredCustomerIds.sort((firstCustomerId, secondCustomerId) => {
    const firstCustomer = customers.entities[firstCustomerId];
    const secondCustomer = customers.entities[secondCustomerId];
    return firstCustomer.name.localeCompare(secondCustomer.name);
  });
};

const useCustomerList = (params?: { includeArchived: boolean }) => {
  const [searchText, setSearchText] = useState("");
  const customers = useSelector(selectCustomers);
  const includeArchived = Boolean(params?.includeArchived);
  const filteredCustomerIds = useMemo(
    () => filterCustomerIds({ customers, includeArchived, searchText }),
    [customers, includeArchived, searchText]
  );
  return { customerIds: filteredCustomerIds, searchText, setSearchText };
};

export default useCustomerList;
