import { Divider, Icon, Text } from "@ui-kitten/components";
import React, { Fragment } from "react";
import styled from "styled-components/native";
import useInboxList from "../../hooks/useInboxList";
import { InboxItem } from "../../types";

import Separator from "../buildingBlocks/Separator";
import Card, { CardProps } from "../Card";
import InboxListItem from "../listItems/InboxItem";

interface CustomerRemindersCardProps
  extends Pick<CardProps, "style" | "title"> {
  customerId: string;
  onListItemCheckboxChange: (inboxItem: InboxItem) => void;
  onListItemPress: (inboxItem: InboxItem) => void;
}

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const CustomerRemindersCard = ({
  customerId,
  onListItemPress,
  onListItemCheckboxChange,
  style,
  title,
}: CustomerRemindersCardProps) => {
  const { inboxItemIds } = useInboxList({ customerId });

  return (
    <Card style={style} title={title}>
      {inboxItemIds.length === 0 ? (
        <>
          <Separator size="medium" />
          <StyledText appearance="hint">No reminders</StyledText>
          <Separator size="medium" />
        </>
      ) : (
        inboxItemIds.map((inboxItemId, index) => (
          <Fragment key={inboxItemId}>
            {index > 0 && <StyledDivider />}
            <InboxListItem
              accessoryRight={(imageProps) => (
                <Icon {...imageProps} name="arrow-ios-forward" />
              )}
              onCheckboxChange={onListItemCheckboxChange}
              onPress={onListItemPress}
              inboxItemId={inboxItemId}
              showsCustomer={false}
            />
          </Fragment>
        ))
      )}
    </Card>
  );
};

export default CustomerRemindersCard;
