import { useContext, useEffect } from "react";

import GoogleApi from "../map/GoogleApi";

const TrafficLayer = () => {
  const googleApi = useContext(GoogleApi);
  const map = googleApi && googleApi.map;
  const maps = googleApi && googleApi.maps;
  useEffect(() => {
    let GoogleMapsTrafficLayer;
    if (map && maps) {
      GoogleMapsTrafficLayer = new maps.TrafficLayer();
      GoogleMapsTrafficLayer.setMap(map);
    }
    return () => {
      if (GoogleMapsTrafficLayer) {
        GoogleMapsTrafficLayer.setMap(null);
      }
    };
  }, [map, maps]);
  return null;
};

export default TrafficLayer;
