import { Divider, Layout } from "@ui-kitten/components";
import React, { useState } from "react";
import { StyleSheet } from "react-native";

import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import CheckboxListItem from "../../listItems/Checkbox";
import RadioListItem from "../../listItems/Radio";
import SectionItem from "../../SectionItem";
import repeatFrequencies from "../../../constants/repeatFrequencies";
import repeatByWeekday from "../../../constants/repeatByWeekday";

interface Props extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  byWeekday: Array<string>;
  frequency?: string;
  onSubmit: (value: { frequency?: string; weekdays: Array<string> }) => void;
}

const styles = StyleSheet.create({
  divider: {
    marginHorizontal: 16,
  },
  root: {
    flex: 1,
  },
  rrule: {
    marginHorizontal: 16,
    marginVertical: 8,
  },
});

const RRuleModal = ({
  byWeekday,
  frequency,
  isVisible,
  onClose,
  onSubmit,
}: Props) => {
  const [stateFreq, setStateFreq] = useState(frequency);
  const [stateByWeekday, setStateByWeekday] = useState(byWeekday);

  const handleByWeekdayChange = (value) => {
    if (stateByWeekday.includes(value))
      return setStateByWeekday(
        stateByWeekday.filter((weekday) => weekday !== value)
      );
    return setStateByWeekday([...stateByWeekday, value]);
  };

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <HeaderWithTextAction
        navigation={{
          icon: "arrow-back-outline",
          onPress: () => {
            setStateFreq(frequency);
            setStateByWeekday(byWeekday);
            onClose();
          },
        }}
        action={{
          disabled: false,
          loading: false,
          text: "Done",
          onPress: () =>
            onSubmit({ frequency: stateFreq, weekdays: stateByWeekday }),
        }}
        title="Repeat"
      />
      <Divider />
      <Layout level="2" style={styles.root}>
        <Separator />
        <SectionItem title="Frequency">
          <RadioListItem
            onChange={() => setStateFreq(null)}
            title="Never"
            checked={!stateFreq}
          />
          <Divider style={styles.divider} />
          <RadioListItem
            onChange={() => setStateFreq(repeatFrequencies.daily.value)}
            title={repeatFrequencies.daily.text}
            checked={stateFreq === repeatFrequencies.daily.value}
          />
          <Divider style={styles.divider} />
          <RadioListItem
            onChange={() => setStateFreq(repeatFrequencies.weekly.value)}
            title={repeatFrequencies.weekly.text}
            checked={stateFreq === repeatFrequencies.weekly.value}
          />
        </SectionItem>
        {stateFreq === repeatFrequencies.weekly.value && (
          <>
            <Separator />
            <SectionItem title="Weekdays">
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.monday.value)
                }
                title={repeatByWeekday.monday.text}
                checked={stateByWeekday.includes(repeatByWeekday.monday.value)}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.tuesday.value)
                }
                title={repeatByWeekday.tuesday.text}
                checked={stateByWeekday.includes(repeatByWeekday.tuesday.value)}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.wednesday.value)
                }
                title={repeatByWeekday.wednesday.text}
                checked={stateByWeekday.includes(
                  repeatByWeekday.wednesday.value
                )}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.thursday.value)
                }
                title={repeatByWeekday.thursday.text}
                checked={stateByWeekday.includes(
                  repeatByWeekday.thursday.value
                )}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.friday.value)
                }
                title={repeatByWeekday.friday.text}
                checked={stateByWeekday.includes(repeatByWeekday.friday.value)}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.saturday.value)
                }
                title={repeatByWeekday.saturday.text}
                checked={stateByWeekday.includes(
                  repeatByWeekday.saturday.value
                )}
              />
              <Divider style={styles.divider} />
              <CheckboxListItem
                onChange={() =>
                  handleByWeekdayChange(repeatByWeekday.sunday.value)
                }
                title={repeatByWeekday.sunday.text}
                checked={stateByWeekday.includes(repeatByWeekday.sunday.value)}
              />
            </SectionItem>
          </>
        )}
      </Layout>
    </FullScreenModal>
  );
};

RRuleModal.defaultProps = {
  frequency: undefined,
};

export default RRuleModal;
