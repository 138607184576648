import gql from "graphql-tag";

export default gql`
  query getDistanceMatrix(
    $origins: [CoordinatesInput!]!
    $destinations: [CoordinatesInput!]!
  ) {
    getDistanceMatrix(origins: $origins, destinations: $destinations) {
      origin_addresses
      destination_addresses
      rows {
        elements {
          status
          duration {
            text
            value
          }
          duration_in_traffic {
            text
            value
          }
          distance {
            text
            value
          }
        }
      }
    }
  }
`;
