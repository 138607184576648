import { Divider, Input, Layout, Text } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  FlatList,
  Keyboard,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import Header from "../buildingBlocks/Header";
import Separator from "../buildingBlocks/Separator";
import Container from "../Container";
import useIosBottomSpace from "../../device/useIosBottomSpace";
import selectEntities from "../../store/sites/selectors/selectEntities";

const styles = StyleSheet.create({
  listContent: {
    paddingTop: 8,
  },
  root: {
    flex: 1,
  },
  inputContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  titleContainer: {
    flexDirection: "row",
  },
});

const SiteList = ({
  accessoryRight,
  navigation,
  renderItem,
  showCount,
  siteIds,
  subtitle,
  title,
}) => {
  const iosBottomSpace = useIosBottomSpace();
  const sites = useSelector(selectEntities);
  const [search, setSearch] = useState({
    query: "",
    results: [],
  });
  const runSearch = (text) => {
    const query = text.trim().toLowerCase();
    setSearch({
      query: text,
      results: siteIds.filter((siteId) => {
        const site = sites[siteId];
        return (
          site &&
          (site.name.toLowerCase().includes(query) ||
            site.address.toLowerCase().includes(query))
        );
      }),
    });
  };
  return (
    <Layout
      onResponderGrant={Keyboard.dismiss}
      onStartShouldSetResponder={() => true}
      style={styles.root}
    >
      <Container>
        {navigation !== null && (
          <SafeAreaView>
            <Header
              navigation={navigation}
              subtitle={subtitle}
              title={title}
              accessoryRight={accessoryRight}
            />
          </SafeAreaView>
        )}
        <View style={styles.inputContainer}>
          <Input
            value={search.query}
            onChangeText={runSearch}
            clearButtonMode="while-editing"
            placeholder="Search..."
          />
        </View>
      </Container>
      <Divider />
      <FlatList
        data={!search.query ? siteIds : search.results}
        keyExtractor={(siteId) => siteId}
        renderItem={(input) => <Container>{renderItem(input)}</Container>}
        contentContainerStyle={[
          styles.listContent,
          { paddingBottom: iosBottomSpace + 8 },
        ]}
        keyboardShouldPersistTaps="handled"
      />
    </Layout>
  );
};

SiteList.propTypes = {
  navigation: PropTypes.shape({
    icon: PropTypes.string,
    onPress: PropTypes.func,
  }),
  renderItem: PropTypes.func.isRequired,
  showCount: PropTypes.bool,
  siteIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accessoryRight: PropTypes.func,
};

SiteList.defaultProps = {
  accessoryRight: undefined,
  showCount: false,
  subtitle: undefined,
  title: undefined,
  navigation: null,
};

export default SiteList;
