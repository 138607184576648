import { Layout, Text } from "@ui-kitten/components";
import * as Linking from "expo-linking";
import * as Location from "expo-location";
import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import useAppActive from "../../device/useAppActive";
import selectUser from "../../store/auth/selectors/selectUser";
import selectLocationPermission from "../../store/permissions/selectors/selectLocationPermission";
import { setLocationPermission } from "../../store/permissions/slice";
import logout from "../../utils/logout";

interface Props {
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  boldAndroidText: {
    fontWeight: "bold",
  },
  button: {
    alignSelf: "center",
  },
  content: {
    flex: 1,
  },
  footer: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  image: {
    height: 200,
    resizeMode: "contain",
  },
  imageContainer: {
    alignItems: "center",
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  surface: {
    borderRadius: 8,
    elevation: 2,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const GateKeeper = ({ style }: Props) => {
  const appActive = useAppActive();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const locationPermission = useSelector(selectLocationPermission);
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState(null);
  const [logoutPending, setLogoutPending] = useState(false);

  const openGate = () => {
    dispatch(setLocationPermission({ locationPermission: true }));
  };

  const closeGate = (reason?: string) => {
    if (reason) {
      setError(reason);
    }
    dispatch(setLocationPermission({ locationPermission: false }));
  };

  const attemptOpenGate = async () => {
    const locationPermissionResponse = await Location.getForegroundPermissionsAsync();
    if (locationPermissionResponse.granted) {
      openGate();
    } else {
      closeGate(
        locationPermissionResponse.status === "denied" &&
          "Permission has been denied. Please enable location in your app settings."
      );
    }
  };

  useEffect(() => {
    // If the following condition is met then one of these things are
    // happening:
    // 1) The user just logged in
    // 2) The app has just started and someone is already logged in
    // 3) The app is coming back to the foreground for a logged in user

    // When any of these things are happening we want to check to see that
    // their location is still working. If not then we show the GateKeeper.
    if (appActive && user) {
      (async () => {
        await attemptOpenGate();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appActive, user]);

  if (!user || locationPermission === true) {
    return null;
  }

  const enableLocation = async () => {
    setLoading(true);
    const getLocationPermissionResponse = await Location.getForegroundPermissionsAsync();
    if (
      getLocationPermissionResponse.status === "denied" &&
      !getLocationPermissionResponse.canAskAgain
    ) {
      await Linking.openSettings();
    } else {
      const requestLocationPermissionResponse = await Location.requestForegroundPermissionsAsync();
      if (requestLocationPermissionResponse.granted) {
        openGate();
      } else {
        closeGate(
          requestLocationPermissionResponse.status === "denied" &&
            "Permission has been denied. Please enable location in your app settings."
        );
      }
    }
    setLoading(false);
  };
  const runLogout = async () => {
    setLogoutPending(true);
    await logout();
  };

  return (
    <Layout style={style}>
      <SafeAreaView style={styles.root}>
        <ScrollView>
          <View style={styles.content}>
            <Separator />
            <Text category="h2" style={styles.text}>
              Talarium Location Permissions
            </Text>
            <Separator size="medium" />
            <Text style={styles.text}>
              Only while you are clocked in, Talarium tracks your location even
              when the application is in the background. Background location is
              required to enable asset tracking.
            </Text>
            <Separator />
            <Button
              loading={loading}
              onPress={enableLocation}
              style={styles.button}
            >
              Enable location
            </Button>
          </View>
        </ScrollView>
        <View style={styles.footer}>
          {error !== null && (
            <Text status="danger" style={styles.text}>
              {error}
            </Text>
          )}
          <Button
            appearance="ghost"
            loading={logoutPending}
            onPress={runLogout}
            style={styles.button}
          >
            Logout
          </Button>
          <Separator />
        </View>
      </SafeAreaView>
    </Layout>
  );
};

GateKeeper.defaultProps = {
  style: undefined,
};

export default GateKeeper;
