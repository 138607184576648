import { createSelector } from "@reduxjs/toolkit";

import selectReportsByJobId from "../selectReportsByJobId";

const selectReportEntitiesByJobId = createSelector(
  [selectReportsByJobId],
  (reports) => ({ ...(reports && reports.entities) })
);

export default selectReportEntitiesByJobId;
