import { Text, TextProps } from "@ui-kitten/components";
import React, { Fragment } from "react";

import { Assignee } from "../../types";
import assigneeTypes from "../../constants/assigneeTypes";
import UserFullNameText from "../UserFullNameText";
import GroupNameText from "../GroupNameText";

interface Props extends TextProps {
  assignees: Array<Assignee>;
}

const AssigneesText = ({ assignees, ...props }: Props) => {
  if (assignees.length === 0) {
    return (
      <Text {...props} appearance="hint">
        Unassigned
      </Text>
    );
  }
  return (
    <Text {...props}>
      {assignees.map((assignee, index, array) => (
        <Fragment key={assignee.id}>
          {index > 0 && array.length > 2 && ","}
          {index > 0 && " "}
          {index === array.length - 1 && index > 0 && "and "}
          {assignee.type === assigneeTypes.group && (
            <GroupNameText {...props} groupId={assignee.id} />
          )}
          {assignee.type === assigneeTypes.user && (
            <UserFullNameText {...props} userId={assignee.id} />
          )}
        </Fragment>
      ))}
    </Text>
  );
};

export default AssigneesText;
