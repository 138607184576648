import gql from "graphql-tag";

import { stripePriceFragment } from "../../../fragments";

const createPrice = gql`
  mutation createPrice(
    $currency: StripeCurrency!
    $metadata: StripePriceMetadataInput
    $product_data: StripeProductInput!
    $unit_amount: Int!
  ) {
    createPrice(
      currency: $currency
      metadata: $metadata
      product_data: $product_data
      unit_amount: $unit_amount
    ) {
      ...stripePriceFragment
    }
  }
  ${stripePriceFragment}
`;

export default createPrice;
