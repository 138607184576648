import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedInvoiceItem } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export type CreateInvoiceItemInput = Pick<
  Stripe.InvoiceItemCreateParams,
  "customer" | "invoice" | "price" | "quantity" | "tax_rates"
>;

const createInvoiceItem = async ({
  customer,
  invoice,
  price,
  quantity,
  tax_rates,
}: CreateInvoiceItemInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        customer,
        invoice,
        price,
        quantity,
        tax_rates,
      },
    })) as GraphQLResult<{ createInvoiceItem: StripeExpandedInvoiceItem }>;
    return data.createInvoiceItem;
  } catch ({ errors }) {
    throw generateErrorMessage("createInvoiceItem", errors);
  }
};

export default createInvoiceItem;
