import Storage from "@aws-amplify/storage";
import { Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSelector } from "react-redux";

import BaseListItem from "../Base";
import Separator from "../../buildingBlocks/Separator";
import MediaActions from "../../MediaActions";
import MediaThumbnail from "../../MediaThumbnail";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import { Media, MediaOnDevice } from "../../../types";
import isMediaOnDevice from "../../../utils/isMediaOnDevice";

interface BaseProps {
  media: Media | MediaOnDevice;
  onPress?: (mediaId: string) => void;
  onUpload?: (mediaId: string) => void;
}

interface ReadOnlyProps extends BaseProps {
  editable?: false;
  onDeletePress?: never;
  onDescriptionPress?: never;
}

interface EditableProps extends BaseProps {
  editable: true;
  onDeletePress: (mediaId: string) => void;
  onDescriptionPress: (mediaId: string) => void;
}

export type Props = ReadOnlyProps | EditableProps;

const styles = StyleSheet.create({
  actionsContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  description: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  mediaThumbnail: {
    borderRadius: 8,
    marginEnd: 8,
    marginStart: 8,
    overflow: "hidden",
  },
  progressContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: 8,
  },
  progressBarContainer: {
    flex: 1,
  },
  progressPercent: {
    marginStart: 8,
    textAlign: "right",
    width: 36,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    paddingEnd: 8,
    paddingStart: 8,
  },
});

/**
 * List item that also attempts to upload to S3 if the media passed in is local.
 * Whether or not an image is local is determined by the the existence of
 * "media.uri".
 */
const MediaListItem = ({
  editable,
  media,
  onDeletePress,
  onDescriptionPress,
  onPress,
  onUpload,
}: Props) => {
  const theme = useTheme();
  const authenticatedUser = useSelector(selectUser);
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const [upload, setUpload] = useState({
    progress: null,
    error: null,
  });
  const renderMediaThumbnail = useCallback(() => {
    return (
      <MediaThumbnail
        height={56}
        media={media}
        style={styles.mediaThumbnail}
        width={56}
      />
    );
  }, [media]);
  const uploadMedia = async () => {
    setUpload({ error: null, progress: null });
    if (isMediaOnDevice(media)) {
      try {
        // eslint-disable-next-line
        const response = await fetch(media.uri);
        const blob = await response.blob();
        const { companyId } = authenticatedUser;
        await Storage.put(`${companyId}/${media.id}`, blob, {
          contentType: media.mimeType,
          progressCallback: (progress) => {
            setUpload((prevState) => ({
              ...prevState,
              progress,
            }));
          },
        });
        if (onUpload) {
          onUpload(media.id);
        }
      } catch (error) {
        setUpload({
          error,
          progress: null,
        });
      }
    }
  };
  const uploadRequired = isMediaOnDevice(media) && !media.uploaded;
  useEffect(() => {
    if (uploadRequired) {
      uploadMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadRequired]);
  const progress = upload.progress
    ? upload.progress.loaded / upload.progress.total
    : 0;
  return (
    <View>
      {uploadRequired ? (
        <BaseListItem
          disabled={!upload.error}
          onPress={() => {
            if (upload.error) {
              uploadMedia();
            }
          }}
        >
          {renderMediaThumbnail()}
          <View style={styles.textContainer}>
            <Text category="s2" style={styles.title}>
              {upload.error ? "Upload error" : "Uploading..."}
            </Text>
            {upload.error ? (
              <Text appearance="hint" category="c1" style={styles.description}>
                Tap to retry
              </Text>
            ) : (
              <View style={styles.progressContainer}>
                <View style={styles.progressBarContainer}>
                  {/* @ts-ignore */}
                  <ProgressBar
                    color={theme["color-primary-default"]}
                    indeterminate={!upload.progress}
                    progress={progress}
                  />
                </View>
                <Text
                  appearance="hint"
                  category="p2"
                  style={styles.progressPercent}
                >
                  {`${progress && Math.round(progress * 100)}%`}
                </Text>
              </View>
            )}
          </View>
        </BaseListItem>
      ) : (
        <BaseListItem
          accessoryLeft={renderMediaThumbnail}
          description={dayjs(media.createdAt).format(dateTimeFormat)}
          onPress={() => onPress(media.id)}
          title={(textProps) => (
            <Text {...textProps} numberOfLines={2}>
              {media.description || "No description available"}
            </Text>
          )}
        />
      )}
      {editable && (
        <>
          <Separator size="small" />
          <MediaActions
            media={media}
            onDeletePress={onDeletePress}
            onDescriptionPress={onDescriptionPress}
            style={styles.actionsContainer}
          />
          <Separator size="small" />
        </>
      )}
    </View>
  );
};

MediaListItem.defaultProps = {
  editable: false,
  onDeletePress: undefined,
  onDescriptionPress: undefined,
  onPress: undefined,
  onUpload: undefined,
};

export default MediaListItem;
