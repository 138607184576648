import { Icon } from "@ui-kitten/components";
import { Coords } from "google-map-react";
import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";
import styled from "styled-components";

type PersonDotMarkerProps = Coords;

const styles = StyleSheet.create({
  icon: {
    height: 12,
    tintColor: "white",
    width: 12,
  },
});

const Dot = styled.div`
  align-items: center;
  background-color: #121212;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  height: 18px;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 18px;
`;

const PersonDotMarker: FC<PersonDotMarkerProps> = () => (
  <Dot>
    <Icon name="person" style={styles.icon} />
  </Dot>
);

export default memo(PersonDotMarker);
