import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";

import { CompanyGroup } from "../../types";

interface Params {
  groups: EntityState<CompanyGroup>;
  userId: string;
}

const adapter = createEntityAdapter<CompanyGroup>();

const getUserGroups = ({ groups, userId }: Params) =>
  adapter.upsertMany(
    adapter.getInitialState(),
    groups.ids
      .filter((groupId) => groups.entities[groupId].members.includes(userId))
      .map((groupId) => groups.entities[groupId])
  );

export default getUserGroups;
