import { useDispatch, useSelector } from "react-redux";

import queryDateTimeData from "../../api/functions/queryDateTimeData";
import { addNotification } from "../../components/InAppNotifications";
import selectHistoricalSearch from "../../store/historicalSearch/selectors/selectHistoricalSearch";
import { queryFinishedJobs } from "../../store/jobs/slice";
import { Job } from "../../types";
import syncCompany from "../../api/functions/syncCompany";

const useRequeryJobs = () => {
  const dispatch = useDispatch();
  const historicalSearch = useSelector(selectHistoricalSearch);
  const runQueryDateTimeData = async ({
    endDateTime,
    startDateTime,
  }: {
    endDateTime: string;
    startDateTime: string;
  }) => {
    if (startDateTime > endDateTime) {
      return;
    }
    dispatch(queryFinishedJobs.pending());
    try {
      const results = await queryDateTimeData({
        from: startDateTime,
        to: endDateTime,
        type: "History",
      });
      dispatch(queryFinishedJobs.fulfilled({ jobs: results as Array<Job> }));
    } catch (error) {
      dispatch(queryFinishedJobs.rejected(undefined, error));
      throw error;
    }
  };
  return async () => {
    const { queryDateTimeRange } = historicalSearch;
    if (queryDateTimeRange) {
      try {
        await runQueryDateTimeData(queryDateTimeRange);
        await syncCompany();
      } catch (error) {
        addNotification({
          message: (error && error.message) || error,
          status: "danger",
          title: "Failed to refresh jobs",
        });
      }
    }
  };
};

export default useRequeryJobs;
