import gql from "graphql-tag";

const getMedia = gql`
  query getMedia($id: ID!) {
    getMedia(id: $id) {
      url
    }
  }
`;

export default getMedia;
