import moment from "moment-timezone";
import { useSelector } from "react-redux";

import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectTimeFormat from "../../store/settings/selectors/selectTimeFormat";

const useFormatDate = () => {
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const timeFormat = useSelector(selectTimeFormat);

  const formatDate = (dateTime: string) =>
    moment(dateTime).format(dateTimeFormat.replace(`${timeFormat} `, ""));
  const formatDateTime = (dateTime: string) =>
    moment(dateTime).format(dateTimeFormat);
  const formatTime = (dateTime: string) => moment(dateTime).format(timeFormat);

  return { formatDate, formatDateTime, formatTime };
};

export default useFormatDate;
