import {
  Button,
  Input,
  Layout,
  Text,
  Toggle,
  useTheme,
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { Animated, StyleSheet, View } from "react-native";
import uuidv4 from "uuid/v4";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import useCompanySettings from "../../../hooks/useCompanySettings";
import { Task } from "../../../types";

import Separator from "../../buildingBlocks/Separator";
import Container from "../../Container";
import LevelTwoListItem from "../../listItems/LevelTwo";
import Portal from "../../Portal";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (task: Task) => void;
  task?: Task;
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    bottom: 0,
    end: 0,
    justifyContent: "center",
    position: "absolute",
    start: 0,
    top: 0,
  },
  content: {
    marginBottom: 26,
    marginTop: 16,
  },
  modal: {
    marginEnd: 16,
    marginStart: 16,
    borderRadius: 8,
  },
  footer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  listItem: {
    borderWidth: 1,
    marginEnd: 16,
    marginStart: 16,
    minHeight: 48,
  },
  textInput: {
    marginEnd: 16,
    marginStart: 16,
  },
  textInputText: {
    height: 60,
  },
  title: {
    textAlign: "center",
  },
});

const TaskEditingModal = ({ isVisible, onClose, onSubmit, task }: Props) => {
  const theme = useTheme();

  const { dailyActivityReportsEnabled } = useCompanySettings();

  const [componentRendered, setComponentRendered] = useState(isVisible);
  const [taskDescription, setTaskDescription] = useState("");
  const [
    dailyActivityReportRequired,
    setDailyActivityReportRequired,
  ] = useState(false);
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const componentMounted = useRef(false);
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (isVisible) {
      setTaskDescription(task?.description || "");
      setDailyActivityReportRequired(
        Boolean(task?.dailyActivityReportRequired)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  useEffect(() => {
    if (isVisible && !componentRendered) {
      setComponentRendered(true);
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else if (!isVisible && componentRendered) {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        if (componentMounted.current) {
          setComponentRendered(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  if (!componentRendered) {
    return null;
  }
  return (
    <Portal>
      <Animated.View style={[styles.backdrop, { opacity: fadeAnimation }]}>
        <Container>
          <Layout style={styles.modal}>
            <Separator size="medium" />
            <Text category="s1" style={styles.title}>
              {`${task ? "Update" : "New"} checklist item`}
            </Text>
            <View style={styles.content}>
              <Input
                multiline
                onChangeText={setTaskDescription}
                placeholder="Tap to add a description"
                style={styles.textInput}
                textAlignVertical="top"
                textStyle={styles.textInputText}
                defaultValue={taskDescription}
              />
            </View>
            {dailyActivityReportsEnabled && (
              <>
                <LevelTwoListItem
                  accessoryRight={() => (
                    <Toggle
                      checked={dailyActivityReportRequired}
                      onChange={() =>
                        setDailyActivityReportRequired(
                          !dailyActivityReportRequired
                        )
                      }
                    />
                  )}
                  disabled
                  style={[
                    styles.listItem,
                    { borderColor: theme["border-basic-color-4"] },
                  ]}
                  title="Daily activity report required"
                />
                <Separator size="medium" />
              </>
            )}
            <View style={styles.footer}>
              <Button
                disabled={!taskDescription}
                onPress={() =>
                  onSubmit({
                    dailyActivityReportRequired,
                    description: taskDescription,
                    id: task ? task.id : uuidv4(),
                  })
                }
              >
                {task ? "Update" : "Create"}
              </Button>
              <Separator size="small" />
              <Button appearance="ghost" onPress={onClose}>
                Cancel
              </Button>
            </View>
          </Layout>
        </Container>
      </Animated.View>
    </Portal>
  );
};

TaskEditingModal.defaultProps = {
  task: undefined,
};

export default TaskEditingModal;
