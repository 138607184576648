import { Icon, useTheme } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React from "react";

import SiteList from "../SiteList";
import SiteListItem from "../listItems/Site";

const SitePicker = ({
  navigation,
  onSelect,
  siteIds,
  selected,
  subtitle,
  title,
  accessoryRight,
}) => {
  const theme = useTheme();
  const handlePress = (siteId) => {
    let selectedId = siteId;
    if (siteId === selected) selectedId = null;
    return onSelect(selectedId);
  };

  return (
    <SiteList
      navigation={navigation}
      renderItem={({ item: siteId }) => (
        <SiteListItem
          accessoryRight={(props) =>
            siteId === selected && (
              <Icon
                {...props}
                name="checkmark-outline"
                fill={theme["color-primary-default"]}
              />
            )
          }
          onPress={() => handlePress(siteId)}
          siteId={siteId}
        />
      )}
      siteIds={siteIds}
      subtitle={subtitle}
      title={title}
      accessoryRight={accessoryRight}
    />
  );
};

SitePicker.propTypes = {
  navigation: PropTypes.shape({
    icon: PropTypes.string,
    onPress: PropTypes.func,
  }),
  onSelect: PropTypes.func.isRequired,
  siteIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  accessoryRight: PropTypes.func,
  selected: PropTypes.string,
};

SitePicker.defaultProps = {
  accessoryRight: undefined,
  selected: undefined,
  subtitle: undefined,
  navigation: null,
  title: undefined,
};

export default SitePicker;
