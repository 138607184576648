import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  mutation createJob(
    $id: ID!
    $siteId: ID!
    $assignedTo: [AssigneeInput!]!
    $tasks: [TaskInput!]!
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime
    $rrule: String
    $immediate: Boolean!
    $includeCreatorInMessages: Boolean
    $autostart: Boolean
  ) {
    createJob(
      id: $id
      siteId: $siteId
      assignedTo: $assignedTo
      tasks: $tasks
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      rrule: $rrule
      immediate: $immediate
      includeCreatorInMessages: $includeCreatorInMessages
      autostart: $autostart
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;
