import gql from "graphql-tag";

import { dailyActivityReportFragment } from "../../../fragments";

export default gql`
  mutation adminUpdateDailyActivityReport($input: DailyActivityReportInput!) {
    adminUpdateDailyActivityReport(input: $input) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;
