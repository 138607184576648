import gql from "graphql-tag";

import { stripeExpandedInvoiceItemFragment } from "../../../fragments";

const listInvoiceItems = gql`
  query listInvoiceItems($invoice: ID!, $starting_after: ID) {
    listInvoiceItems(invoice: $invoice, starting_after: $starting_after) {
      data {
        ...stripeExpandedInvoiceItemFragment
      }
      has_more
    }
  }
  ${stripeExpandedInvoiceItemFragment}
`;

export default listInvoiceItems;
