import gql from "graphql-tag";

import { customerFragment } from "../../../fragments";

const createStripeCustomer = gql`
  mutation createStripeCustomer($id: ID!) {
    createStripeCustomer(id: $id) {
      ...customerFragment
    }
  }
  ${customerFragment}
`;

export default createStripeCustomer;
