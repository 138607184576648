import Auth from "@aws-amplify/auth";
import { Divider, Input } from "@ui-kitten/components";
import { useFormik } from "formik";
import React from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { AsYouType } from "libphonenumber-js";
import * as yup from "yup";

import Header from "../../../buildingBlocks/Header";
import LoadingButton from "../../../LoadingButton";
import { addNotification } from "../../../InAppNotifications";
import useAndroidBackHandler from "../../../../device/useAndroidBackHandler";
import selectSignInWithPhone from "../../../../store/settings/selectors/selectSignInWithPhone";
import formikUsernameValidationSchema from "../../../../utils/formikUsernameValidationSchema";

const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  scrollContent: {
    flex: 1,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
});

interface UsernameScreenProps {
  onClosePress: () => void;
  onCodeDelivery: (username: string) => void;
  visible: boolean;
}

const UsernameScreen = ({
  onClosePress,
  onCodeDelivery,
  visible,
}: UsernameScreenProps) => {
  const signInWithPhone = useSelector(selectSignInWithPhone);
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: yup.object({
      username: formikUsernameValidationSchema(signInWithPhone),
    }),
    onSubmit: async (values, actions) => {
      try {
        let usernameInput: string;
        if (signInWithPhone) {
          const phoneNumber = new AsYouType("US");
          phoneNumber.input(values.username);
          usernameInput = phoneNumber.getNumber().number as string;
        } else {
          usernameInput = values.username.trim();
        }
        await Auth.forgotPassword(usernameInput);
        onCodeDelivery(usernameInput);
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Error",
        });
      }
      actions.setSubmitting(false);
    },
  });
  useAndroidBackHandler({ enabled: visible, onBackPress: onClosePress });
  return (
    <>
      <Header
        navigation={{ icon: "close-outline", onPress: onClosePress }}
        title="Reset password"
      />
      <Divider />
      <ScrollView contentContainerStyle={styles.scrollContent}>
        <Input
          autoCapitalize="none"
          blurOnSubmit={false}
          disabled={formik.isSubmitting}
          autoCompleteType={signInWithPhone ? "tel" : "email"}
          textContentType={signInWithPhone ? "telephoneNumber" : "emailAddress"}
          keyboardType={signInWithPhone ? "number-pad" : "email-address"}
          data-test="reset-username"
          onChangeText={formik.handleChange("username")}
          label={signInWithPhone ? "Phone number" : "Email"}
          placeholder={
            signInWithPhone ? "(xxx) xxx-xxxx" : "example@domain.com"
          }
          returnKeyType="next"
          value={
            signInWithPhone
              ? new AsYouType("US").input(formik.values.username)
              : formik.values.username
          }
          onBlur={() => formik.setFieldTouched("username")}
          status={formik.errors.username && formik.touched.username && "danger"}
          autoCorrect={false}
        />
      </ScrollView>
      <Divider />
      <SafeAreaView>
        <View style={styles.footer}>
          <LoadingButton
            disabled={
              !formik.values.username || Object.values(formik.errors).length > 0
            }
            loading={formik.isSubmitting}
            onPress={formik.handleSubmit}
          >
            Next
          </LoadingButton>
        </View>
      </SafeAreaView>
    </>
  );
};

export default UsernameScreen;
