import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../auth/slice";
import { sync } from "../data/actions";
import { Company, OptimisticCompany } from "../../types";

export const setCompany = createAction<{ company: Company }>(
  "company/setCompany"
);

export const setCompanyAsync = {
  fulfilled: createAction<{ company: Company }>("company/setCompany/fulfilled"),
  pending: createAction<{ company: Company; mutation: string }>(
    "company/setCompany/pending"
  ),
  rejected: createAction("company/setCompany/rejected"),
};

export interface State {
  entity?: Company;
  optimisticEntity?: OptimisticCompany;
}

const initialState: State = {
  entity: null,
  optimisticEntity: null,
};

const companyReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    setCompany,
    (state, action: PayloadAction<{ company: Company }>) => {
      const { company } = action.payload;
      state.entity = company;
      state.optimisticEntity = null;
    }
  );
  builder.addCase(setCompanyAsync.pending, (state, action) => {
    const { company, mutation } = action.payload;
    state.optimisticEntity = { ...company, mutation, pending: true };
  });
  builder.addCase(setCompanyAsync.fulfilled, (state, action) => {
    const { company } = action.payload;
    state.entity = company;
    state.optimisticEntity = null;
  });
  builder.addCase(setCompanyAsync.rejected, (state) => {
    state.optimisticEntity = null;
  });
  builder.addCase(sync.fulfilled, (state, action) => {
    const { company } = action.payload;
    if (company) {
      state.entity = company;
      state.optimisticEntity = null;
    }
  });
  builder.addCase(resetUser.fulfilled, () => initialState);
  builder.addCase(PURGE, () => initialState);
});

export default companyReducer;
