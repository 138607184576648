import { createSelector } from "@reduxjs/toolkit";
import moment from "moment-timezone";

import mapFilterConstants from "../../../../constants/mapFilter";
import jobStatuses from "../../../../constants/jobStatuses";
import selectGroupEntities from "../../../groups/selectors/selectEntities";
import selectJobEntities from "../../../jobs/selectors/selectJobEntities";
import selectUserEntities from "../selectEntities";
import selectMapJobs from "../../../jobs/selectors/mapJobs";
import assigneeTypes from "../../../../constants/assigneeTypes";
import { CompanyUser } from "../../../../types";

const showUser = ({
  id,
  user,
  staleTime,
}: {
  id: string;
  user: CompanyUser;
  staleTime: string;
}) => {
  return (
    user.id !== id && // Checks to see if the user is the current user
    !user.isArchived &&
    user.updated.at > staleTime &&
    user.clockedIn
  );
};

const selectVisibleUsers = createSelector(
  [
    selectMapJobs,
    selectJobEntities,
    (state) => state.auth.user,
    (state) => state.data.users.filter.visibility,
    selectUserEntities,
    selectGroupEntities,
  ],
  (mapJobs, jobs, currentUser, visibility, users, groups) => {
    if (!currentUser) return [];
    const { all, colleagues } = mapFilterConstants.users;
    const staleTime = moment().subtract(8, "hours").toISOString();
    const { id } = currentUser;
    let visibleUsers = [];
    switch (visibility) {
      case all.value: {
        visibleUsers = Object.values(users).filter((user) =>
          showUser({ id, user, staleTime })
        );
        break;
      }
      case colleagues.value: {
        const visibleUsersMap = {};
        mapJobs.accessible.forEach((jobId) => {
          const job = jobs[jobId];
          const { assignedTo, status } = job;
          const userAssignees = [];
          const groupAssignees = [];
          assignedTo.forEach((assignee) => {
            if (assignee.type === assigneeTypes.user)
              userAssignees.push(assignee.id);
            else if (assignee.type === assigneeTypes.group)
              groupAssignees.push(assignee.id);
          });

          // Not able to view users of jobs that haven't started
          if (status !== jobStatuses.future) {
            userAssignees.forEach((userId) => {
              const user = users[userId];
              if (showUser({ id, user, staleTime }))
                visibleUsersMap[userId] = users[userId];
            });
            groupAssignees.forEach((groupId) => {
              const { members } = groups[groupId];
              members.forEach((member) => {
                const user = users[member];
                if (showUser({ id, user, staleTime })) {
                  visibleUsersMap[member] = user;
                }
              });
            });
          }
        });
        visibleUsers = Object.values(visibleUsersMap);
        break;
      }
      default:
        return [];
    }
    return visibleUsers.map(({ id: userId }) => userId);
  }
);

export default selectVisibleUsers;
