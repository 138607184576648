import gql from "graphql-tag";

import { privateCompanySiteFragment } from "../../../fragments";

export default gql`
  mutation adminArchiveCompanySite($id: ID!) {
    adminArchiveCompanySite(id: $id) {
      ...privateCompanySiteFragment
    }
  }
  ${privateCompanySiteFragment}
`;
