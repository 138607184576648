import { Icon, TopNavigation, TopNavigationProps } from "@ui-kitten/components";
import React from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { Badge } from "react-native-paper";
import { useSelector } from "react-redux";
import useInboxList from "../../hooks/useInboxList";
import selectSignedInUser from "../../store/users/selectors/selectSignedInUser";

import TopNavigationAction from "../TopNavigationAction";
import selectInboxSections from "../../store/inbox/selectors/selectInboxSections";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import userRolePermitted from "../../utils/userRolePermitted";

export interface Props
  extends Pick<TopNavigationProps, "accessoryRight" | "subtitle" | "title"> {}

const styles = StyleSheet.create({
  topNavigation: {
    paddingHorizontal: 4,
    paddingVertical: 0,
  },
  topNavigationAction: {
    marginHorizontal: 0,
    padding: 12,
  },
  topNavigationActionOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingEnd: 12,
  },
  badge: {
    position: "absolute",
    top: 2,
    right: 2,
  },
});

const BottomTabHeader = ({ accessoryRight, subtitle, title }: Props) => {
  const navigation = useNavigation();
  const signedInUser = useSelector(selectSignedInUser);

  const { inboxItemIds } = useInboxList({
    userId: signedInUser?.id,
  });

  const renderAccessoryRight = () => {
    if (!accessoryRight) {
      return null;
    }
    return (
      <View style={styles.topNavigationActionOverlay}>{accessoryRight()}</View>
    );
  };

  const handlePress = () => {
    Keyboard.dismiss();
    navigation.dispatch(DrawerActions.openDrawer());
  };
  return (
    <View>
      <TopNavigation
        accessoryLeft={() => (
          <>
            <TopNavigationAction
              data-test="drawerButton"
              icon={(imageProps) => (
                <Icon {...imageProps} name="menu-outline" />
              )}
              onPress={handlePress}
              style={styles.topNavigationAction}
            />
            {signedInUser &&
              userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: signedInUser.role,
              }) && (
                <Badge
                  visible={inboxItemIds.length > 0}
                  style={styles.badge}
                  onPress={handlePress}
                >
                  {inboxItemIds.length}
                </Badge>
              )}
          </>
        )}
        alignment="center"
        subtitle={subtitle}
        style={styles.topNavigation}
        title={title}
      />
      {renderAccessoryRight()}
    </View>
  );
};

export default BottomTabHeader;
