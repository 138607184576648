import { ButtonProps, Icon, IconProps } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

import Button from "../buildingBlocks/Button";

interface Props
  extends Pick<
      ButtonProps,
      "appearance" | "disabled" | "onPress" | "status" | "style"
    >,
    Pick<IconProps<{}>, "name"> {
  pack?: "MaterialIcons" | "MaterialCommunityIcons";
}

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: 40,
  },
});

const IconButton = ({
  appearance,
  disabled,
  name,
  onPress,
  pack,
  status,
  style,
}: Props) => {
  return (
    <Button
      appearance={appearance}
      accessoryLeft={(imageProps) => (
        <Icon {...imageProps} name={name} pack={pack} />
      )}
      disabled={disabled}
      onPress={onPress}
      status={status}
      style={[styles.button, style]}
    />
  );
};

IconButton.defaultProps = {
  pack: undefined,
};

export default IconButton;
