import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = ({ id, timestamp, jobId }) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setReportPending({
      report: {
        __typename: "IncidentReport",
        syncDateTime: timestamp,
        id,
        jobId,
        submitted: null,
        updated: {
          at: timestamp,
          by: userId,
        },
        created: {
          at: timestamp,
          by: userId,
        },
        startDateTime: null,
        endDateTime: null,
        summary: "",
        policeCase: null,
        subjects: [],
        vehicles: [],
        media: [],
      },
      mutation: "createIncidentReport",
    })
  );
};

const createIncidentReport = ({
  id,
  timestamp = new Date().toISOString(),
  jobId,
}) => {
  dispatchOptimisticResponse({
    id,
    timestamp,
    jobId,
  });
  client.mutate({
    mutation,
    variables: {
      id,
      timestamp,
      jobId,
    },
    errorPolicy: "all",
  });
};

export default createIncidentReport;
