import gql from "graphql-tag";

import { stripeExpandedQuoteFragment } from "../../../fragments";

const acceptQuote = gql`
  mutation acceptQuote($id: ID!) {
    acceptQuote(id: $id) {
      ...stripeExpandedQuoteFragment
    }
  }
  ${stripeExpandedQuoteFragment}
`;

export default acceptQuote;
