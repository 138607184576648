import getDirections from "react-native-google-maps-directions";

export interface GeoJsonFormatReturn {
  id: string;
  type: "Feature";
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
}

export const geoJsonFormat = ({
  latitude,
  longitude,
  id,
}: {
  latitude: number;
  longitude: number;
  id: string;
}): GeoJsonFormatReturn => ({
  id,
  type: "Feature",
  geometry: {
    type: "Point",
    coordinates: [longitude, latitude],
  },
});

// export const getZoomFromLongDelta = ({ longitudeDelta }) => {
//   return Math.floor(Math.log(360 / longitudeDelta) / Math.LN2);
// };
//
// export const getViewBox = (region, zoom) => ({
//   northeast: {
//     latitude: region.latitude + region.latitudeDelta / 2,
//     longitude: region.longitude + region.longitudeDelta / 2,
//   },
//   southwest: {
//     latitude: region.latitude - region.latitudeDelta / 2,
//     longitude: region.longitude - region.longitudeDelta / 2,
//   },
//   zoom,
// });

export const navigateWithGoogleMaps = async ({ destination }) => {
  const data = {
    destination,
    params: [
      {
        key: "travelmode",
        value: "driving",
      },
    ],
  };
  await getDirections(data);
};

export const getRotationAngle = (previousPosition, currentPosition) => {
  const x1 = previousPosition.latitude;
  const y1 = previousPosition.longitude;
  const x2 = currentPosition.latitude;
  const y2 = currentPosition.longitude;

  const xDiff = x2 - x1;
  const yDiff = y2 - y1;

  return (Math.atan2(yDiff, xDiff) * 180.0) / Math.PI;
};

export const getViewBox = ({ region, mapDims }) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = (lat) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  const zoom = (mapPx, worldPx, fraction) => {
    return Math.ceil(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  };

  const ne = {
    latitude: region.latitude + region.latitudeDelta / 2,
    longitude: region.longitude + region.longitudeDelta / 2,
  };
  const sw = {
    latitude: region.latitude - region.latitudeDelta / 2,
    longitude: region.longitude - region.longitudeDelta / 2,
  };

  const latFraction = (latRad(ne.latitude) - latRad(sw.latitude)) / Math.PI;

  const lngDiff = ne.longitude - sw.longitude;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDims.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDims.width, WORLD_DIM.width, lngFraction);

  const minZoom = Math.min(latZoom, lngZoom, ZOOM_MAX);

  return {
    northeast: ne,
    southwest: sw,
    zoom: minZoom,
  };
};
