import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { PostmarkMessageSendingResponse } from "../../../types";

interface SendInvoiceInput {
  invoiceId: string;
}

const sendInvoice = async ({ invoiceId }: SendInvoiceInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        invoiceId,
      },
    })) as GraphQLResult<{
      sendInvoice: PostmarkMessageSendingResponse[];
    }>;
    return data.sendInvoice;
  } catch ({ errors }) {
    throw generateErrorMessage("sendInvoice", errors);
  }
};

export default sendInvoice;
