import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Web only
const googleMaps = __DEV__ ? "" : "AIzaSyAsXBqN3KbIOSZzuDl0cupIkQW1Pc06U00";

const dev = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:3ada6ff7-7fea-498d-b0f0-1e59b03d601c",
      region: "us-west-2",
      userPoolId: "us-west-2_obFOgoo4S",
      userPoolWebClientId: "7ke0kh9flaf6u905gkshnb2n16",
    },
    aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    aws_appsync_graphqlEndpoint:
      "https://n73hwv67kff3hkisyxdvo2l6mm.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    Storage: {
      AWSS3: {
        bucket: "talarium-media-us-west-2-691015733957",
        region: "us-west-2",
        level: "public",
      },
    },
  },
  pusher: {
    appKey: "8dadf238b1c495a15896",
    appCluster: "us3",
    url:
      "https://t59jbwfrwa.execute-api.us-west-2.amazonaws.com/dev/pusher/channels",
  },
  googleMaps,
  stripe: {
    key:
      "sk_test_51HtHYbJFZdxVHgWqLTafeDFzJ3M6dPOMuaETtxSDppH8HyAsAvcBp847rf4sE8QPD2njzhy58qViO3N6OoRWzwbB00BQzwigWT",
  },
};

const staging = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:f4a2d642-b8a1-4279-86fe-26828fd26a91",
      region: "us-west-2",
      userPoolId: "us-west-2_JaCzk8xmg",
      userPoolWebClientId: "3pbr5bj4m3gs28nkgk51i0lps3",
    },
    aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    aws_appsync_graphqlEndpoint:
      "https://aoutxyytjnfp3cqzvz6rrfoskq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    Storage: {
      AWSS3: {
        bucket: "talarium-media-us-west-2-369582980234",
        region: "us-west-2",
        level: "public",
      },
    },
  },
  pusher: {
    appKey: "20e404a955181098f83a",
    appCluster: "us3",
    url:
      "https://b7c0f3s5u4.execute-api.us-west-2.amazonaws.com/staging/pusher/channels",
  },
  googleMaps,
};

const prod = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:14c3c9ae-aab7-44e5-91cf-f18d873644b4",
      region: "us-west-2",
      userPoolId: "us-west-2_M1WhW2Bz3",
      userPoolWebClientId: "3mklp3n0lees3f7a21sistf27a",
    },
    aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    aws_appsync_graphqlEndpoint:
      "https://phmrdcghujh5le3ka6w6efu3sq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    Storage: {
      AWSS3: {
        bucket: "talarium-media-us-west-2-898086874156",
        region: "us-west-2",
        level: "public",
      },
    },
  },
  pusher: {
    appKey: "83b700d54aadc5c4ac9f",
    appCluster: "us3",
    url:
      "https://1gwmeekzs5.execute-api.us-west-2.amazonaws.com/prod/pusher/channels",
  },
  googleMaps,
};

// eslint-disable-next-line import/no-mutable-exports
let config = dev;

// Don't change this line unless you want to die
if (!__DEV__) config = prod;

export default config;
