import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import {
  CompanySite,
  EntityState,
  OptimisticCompanySite,
} from "../../../types";

const adapter = createEntityAdapter<CompanySite>();
const optimisticAdapter = createEntityAdapter<OptimisticCompanySite>();

export interface State extends EntityState<CompanySite> {
  optimistic: EntityState<OptimisticCompanySite>;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addSite = ({ site, state }) => {
  const existingSite = state.entities[site.id];
  if (
    existingSite === undefined ||
    site.syncDateTime > existingSite.syncDateTime
  ) {
    adapter.upsertOne(state, site);
  }
};

const name = "sites";

export const setSitePending = createAction<{
  mutation: string;
  site: CompanySite;
}>(`${name}/setSite/pending`);
export const setSiteFulfilled = createAction<{
  mutation: string;
  site: CompanySite;
}>(`${name}/setSite/fulfilled`);
export const setSiteRejected = createAction(
  `${name}/setSite/rejected`,
  (payload: { id: string; mutation: string }, error: Error) => ({
    error,
    payload,
  })
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSite: (state, action) => {
      const { site } = action.payload;
      addSite({ site, state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSitePending, (state, action) => {
      const { mutation, site } = action.payload;
      const existingOptimisticSite = state.optimistic.entities[site.id];
      if (
        existingOptimisticSite === undefined ||
        site.syncDateTime > existingOptimisticSite.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...site,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setSiteFulfilled, (state, action) => {
      const { mutation, site } = action.payload;
      addSite({ site, state });
      const existingOptimisticSite = state.optimistic.entities[site.id];
      if (
        existingOptimisticSite !== undefined &&
        mutation === existingOptimisticSite.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, site.id);
      }
    });
    builder.addCase(setSiteRejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticSite = state.optimistic.entities[id];
      if (
        existingOptimisticSite !== undefined &&
        mutation === existingOptimisticSite.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { lastSync, sites } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, sites);
      } else {
        sites.forEach((site) => addSite({ site, state }));
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const { setSite } = actions;

export default reducer;
