import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Input,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Keyboard, ScrollView, StyleSheet } from "react-native";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";

import listPrices from "../../api/functions/listPrices";
import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import ListItem from "../../components/listItems/Base";
import ProductListItem from "../../components/listItems/Product";
import CreateProductModal from "../../components/modals/CreateProduct";
import { StackParamList, StripePrice } from "../../types";

const FlatListContainer = styled.View`
  flex: 1;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    maxWidth: 960,
    width: "100%",
  },
});

const Products = () => {
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Products">
  >();
  const { params } = useRoute<RouteProp<StackParamList, "Products">>();
  const stale = params?.stale;
  const theme = useTheme();

  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [prices, setPrices] = useState<Array<StripePrice>>();
  const [searchText, setSearchText] = useState("");
  const [productEditingModalVisible, setProductEditingModalVisible] = useState<
    boolean
  >(false);

  const runListPrices = useCallback(async () => {
    try {
      let data = [];
      let startingAfter: string;
      do {
        const listPricesResult = await listPrices({
          active,
          starting_after: startingAfter,
        });
        data = data.concat(listPricesResult.data);
        startingAfter = listPricesResult.has_more
          ? listPricesResult.data[listPricesResult.data.length - 1].id
          : null;
      } while (startingAfter);
      setPrices(data);
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List prices failed",
      });
    }
  }, [active]);
  const navigateToProductDetails = useCallback(
    (price: StripePrice) =>
      navigation.navigate("ProductDetails", { priceId: price.id }),
    []
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      setPrices(null);
      await runListPrices();
      setLoading(false);
    })();
  }, [runListPrices]);
  useEffect(() => {
    if (isFocused && stale) {
      (async () => {
        setLoading(true);
        setPrices(null);
        await runListPrices();
        setLoading(false);
      })();
    }
  }, [isFocused, stale]);

  const filteredPrices =
    Array.isArray(prices) && searchText
      ? prices.filter((price) =>
          price.product.name
            .toLocaleLowerCase()
            .includes(searchText.trim().toLocaleLowerCase())
        )
      : prices;

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <Header
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Products"
          />
          <Separator size="small" />
          <StyledInput
            onChangeText={setSearchText}
            placeholder="Search..."
            value={searchText}
          />
          <Separator size="small" />
        </HeaderContainer>
        <Divider />
        <FlatListContainer>
          <FlatList
            contentContainerStyle={{
              maxWidth: 960,
              marginEnd: "auto",
              marginStart: "auto",
              paddingBottom: insets.bottom,
              paddingEnd: insets.right,
              paddingStart: insets.left,
              width: "100%",
            }}
            data={filteredPrices}
            ItemSeparatorComponent={() => (
              <Layout>
                <StyledDivider />
              </Layout>
            )}
            keyExtractor={(price) => price.id}
            ListEmptyComponent={
              <ListEmptyContainer>
                <Separator />
                {loading ? (
                  <Spinner />
                ) : (
                  <Text appearance="hint" category="s1">
                    No results found
                  </Text>
                )}
              </ListEmptyContainer>
            }
            ListHeaderComponent={
              <>
                <Separator size="medium" />
                <ListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward" />
                  )}
                  onPress={() => navigation.navigate("TaxRates")}
                  style={{ marginEnd: 16, marginStart: 16 }}
                  title="Tax rates"
                />
                <Separator />
                <StyledText category="h4">Overview</StyledText>
                <Separator size="small" />
                <ScrollView
                  contentContainerStyle={styles.scrollViewContentContainerStyle}
                  horizontal
                >
                  <StyledButton
                    appearance={active ? "filled" : "outline"}
                    onPress={() => setActive(true)}
                    status="control"
                  >
                    Available
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={active ? "outline" : "filled"}
                    onPress={() => setActive(false)}
                    status="control"
                  >
                    Archived
                  </StyledButton>
                </ScrollView>
              </>
            }
            onRefresh={async () => {
              setRefreshing(true);
              await runListPrices();
              setRefreshing(false);
            }}
            refreshing={refreshing}
            renderItem={({ item: price }) => (
              <Layout>
                <ProductListItem
                  onPress={navigateToProductDetails}
                  price={price}
                />
              </Layout>
            )}
          />
        </FlatListContainer>
        <FAB
          icon="plus"
          onPress={() => {
            Keyboard.dismiss();
            setProductEditingModalVisible(true);
          }}
          style={{
            backgroundColor: theme["color-primary-default"],
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: insets.bottom,
          }}
        />
      </Root>
      <CreateProductModal
        isVisible={productEditingModalVisible}
        onClose={() => setProductEditingModalVisible(false)}
        onSuccess={async () => {
          setProductEditingModalVisible(false);
          setLoading(true);
          setPrices(null);
          await runListPrices();
          setLoading(false);
        }}
      />
    </>
  );
};

export default Products;
