import { Divider } from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import MapView, { Region } from "react-native-maps";

import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import MarkerPositioningMapView from "../../map/MarkerPositioningMapView";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import getDeltaFromZoom from "../../../utils/getDeltaFromZoom";
import { Coordinates } from "../../../types";

const initialZoom = 18;
const initialDelta = getDeltaFromZoom(initialZoom);

type Props = {
  initialCoordinates?: Coordinates;
  onBackPress: () => void;
  onSubmitPress: (coordinates: Coordinates) => void;
  visible: boolean;
};

const styles = StyleSheet.create({
  map: {
    flex: 1,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const LocationSetter = ({
  initialCoordinates,
  onBackPress,
  onSubmitPress,
  visible,
}: Props) => {
  useAndroidBackHandler({ enabled: visible, onBackPress });
  const [initialRegion, setInitialRegion] = useState<Region>();
  const [region, setRegion] = useState<Region>();
  const mapRef = useRef<MapView>();
  useEffect(() => {
    if (visible) {
      if (!initialRegion) {
        setInitialRegion({
          latitude: initialCoordinates.lat,
          latitudeDelta: initialDelta,
          longitude: initialCoordinates.lng,
          longitudeDelta: initialDelta,
        });
      } else {
        mapRef.current?.setCamera({
          center: {
            latitude: initialCoordinates.lat,
            longitude: initialCoordinates.lng,
          },
          zoom: initialZoom,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <HeaderWithTextAction
        navigation={{ icon: "arrow-back-outline", onPress: onBackPress }}
        title="Set location"
        action={{
          loading: false,
          onPress: () =>
            onSubmitPress({
              accuracy: initialCoordinates.accuracy,
              lat: region.latitude,
              lng: region.longitude,
            }),
          text: "Done",
        }}
      />
      <Divider />
      {initialRegion && (
        <MarkerPositioningMapView
          initialRegion={initialRegion}
          onRegionChangeComplete={setRegion}
          ref={mapRef}
          style={styles.map}
        />
      )}
    </>
  );
};

LocationSetter.defaultProps = {
  initialCoordinates: undefined,
};

export default LocationSetter;
