import { useNavigation } from "@react-navigation/native";
import { Divider, Icon, Spinner, Text } from "@ui-kitten/components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

import listInvoices from "../../api/functions/listInvoices";
import { StripeInvoice } from "../../types";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Card from "../Card";
import InvoiceListItem from "../listItems/Invoice";

interface CustomerInvoicesCardProps {
  stripeCustomerId: string;
  style?: StyleProp<ViewStyle>;
}

const SpinnerContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const CustomerInvoicesCard = ({
  stripeCustomerId,
  style,
}: CustomerInvoicesCardProps) => {
  const navigation = useNavigation();

  const [invoices, setInvoices] = useState<{
    data: Array<StripeInvoice>;
    has_more: boolean;
  }>();

  const [listInvoicesPending, setListInvoicesPending] = useState(false);
  const [listInvoicesError, setListInvoicesError] = useState<Error>();

  const runListInvoices = useCallback(async (customer: string) => {
    setListInvoicesPending(true);
    try {
      setInvoices(await listInvoices({ customer }));
    } catch (error) {
      setListInvoicesError(error);
    }
    setListInvoicesPending(false);
  }, []);
  const navigateToInvoiceDetails = useCallback(
    (invoice: { id: string }) => {
      navigation.navigate("InvoiceDetails", { invoiceId: invoice.id });
    },
    [navigation]
  );

  useEffect(() => {
    runListInvoices(stripeCustomerId);
  }, [stripeCustomerId]);

  return (
    <Card style={style} title="Invoices">
      {listInvoicesPending ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {listInvoicesError || invoices?.data.length === 0 ? (
            <StyledText appearance="hint">
              {listInvoicesError
                ? `Error: ${listInvoicesError.message}`
                : "No invoices"}
            </StyledText>
          ) : (
            invoices?.data.map((invoice, index) => (
              <Fragment key={invoice.id}>
                {index > 0 && <StyledDivider />}
                <InvoiceListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward" />
                  )}
                  hideCustomerName
                  invoice={invoice}
                  onPress={navigateToInvoiceDetails}
                />
              </Fragment>
            ))
          )}
        </>
      )}
      {invoices?.has_more && (
        <>
          <StyledDivider />
          <Separator size="medium" />
          <StyledButton
            appearance="ghost"
            onPress={() =>
              navigation.navigate("Invoices", { customerId: stripeCustomerId })
            }
          >
            View all
          </StyledButton>
          <Separator size="medium" />
        </>
      )}
    </Card>
  );
};

export default CustomerInvoicesCard;
