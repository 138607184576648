import Auth from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import localforage from "localforage";
import { Platform } from "react-native";

import config from "..";
import mapToIncidentReport from "../../api/utils/mapToIncidentReport";
import { addNotification } from "../../components/InAppNotifications";
import store from "../../store";
import {
  setActionItemFulfilled,
  setActionItemRejected,
} from "../../store/actionItems/slice";
import { setCompanyAsync } from "../../store/company";
import { setCustomerAsync } from "../../store/customers/slice";
import { setGroupFulfilled, setGroupRejected } from "../../store/groups/slice";
import { setInboxItemAsync } from "../../store/inbox/slice";
import {
  setJobFulfilled,
  setJobRejected,
  setReportFulfilled,
  setReportRejected,
  setJobsFulfilled,
  setJobsRejected,
} from "../../store/jobs/slice";
import { setSiteFulfilled, setSiteRejected } from "../../store/sites/slice";
import { setTimeCard } from "../../store/timeCards/slice";
import { setUserFulfilled, setUserRejected } from "../../store/users/slice";

const handleOfflineCallback = (error, success) => {
  const { dispatch } = store;
  if (error) {
    addNotification({
      message: error.error.message || "An unexpected error occurred.",
      status: "danger",
      title: "AppSync error",
    });
    const { mutation, variables } = error;
    switch (mutation) {
      case "createActionItem":
      case "deleteActionItem":
      case "updateActionItem": {
        setActionItemRejected({ id: variables.id, mutation }, error.error);
        break;
      }
      case "completeInboxItem":
        dispatch(
          setInboxItemAsync.rejected(
            { id: variables.id, mutation },
            error.error
          )
        );
        break;
      case "adminUpdateCompany":
        dispatch(setCompanyAsync.rejected());
        break;
      case "adminCreateCompanyGroup":
      case "adminDeleteCompanyGroup":
      case "adminUpdateCompanyGroup":
        dispatch(
          setGroupRejected(
            {
              id: variables.id,
              mutation,
            },
            error.error
          )
        );
        break;
      case "adminArchiveCompanySite":
      case "adminCreateCompanySite":
      case "adminRestoreCompanySite":
      case "adminUpdateCompanySite":
        dispatch(
          setSiteRejected(
            {
              id: variables.id,
              mutation,
            },
            error.error
          )
        );
        break;
      case "linkSiteToCustomer":
      case "unlinkSiteFromCustomer":
        dispatch(
          setSiteRejected(
            {
              id: variables.siteId,
              mutation,
            },
            error.error
          )
        );
        break;
      case "adminArchiveCompanyUser":
      case "adminRestoreCompanyUser":
      case "adminUpdateCompanyUser":
      case "clockIn":
      case "clockOut":
      case "adminClockOutUser":
        dispatch(setUserRejected({ id: variables.id, mutation }, error.error));
        break;
      case "archiveCustomer":
      case "createCustomer":
      case "restoreCustomer":
      case "updateCustomer":
        dispatch(
          setCustomerAsync.rejected({ id: variables.id, mutation }, error.error)
        );
        break;
      case "adminDeleteDailyActivityReport":
      case "adminDeleteIncidentReport":
      case "createDailyActivityReport":
      case "createIncidentReport":
      case "deleteDailyActivityReport":
      case "deleteIncidentReport":
      case "submitDailyActivityReport":
      case "submitIncidentReport":
        dispatch(
          setReportRejected(
            { id: variables.id, jobId: variables.jobId, mutation },
            error.error
          )
        );
        break;
      case "adminUpdateDailyActivityReport":
      case "updateDailyActivityReport":
      case "adminUpdateIncidentReport":
      case "updateIncidentReport":
        dispatch(
          setReportRejected(
            { id: variables.input.id, jobId: variables.input.jobId, mutation },
            error.error
          )
        );
        break;
      case "completeJob":
      case "dismissJob":
        dispatch(
          setJobsRejected({ ids: [variables.id, variables.nextId], mutation })
        );
        break;
      case "createJob":
      case "deleteJob":
      case "updateJob":
        dispatch(setJobRejected({ id: variables.id, mutation }, error.error));
        break;
      case "createActionItemCompletionRecord":
      case "createStartJobRecord":
      case "createTaskCompletionRecord":
      case "deleteActionItemCompletionRecord":
      case "deleteStartJobRecord":
      case "deleteTaskCompletionRecord":
        dispatch(
          setJobRejected(
            {
              id: variables.jobId,
              mutation,
            },
            error.error
          )
        );
        break;
      case "adminDeleteTimecard":
      case "adminUpdateTimecard":
        dispatch(
          setTimeCard.rejected({ id: variables.id, mutation }, error.error)
        );
        break;
      default:
        break;
    }
  } else if (success) {
    const { data, mutation } = success;
    switch (mutation) {
      case "createActionItem":
      case "deleteActionItem":
      case "updateActionItem": {
        dispatch(
          setActionItemFulfilled({ actionItem: data[mutation], mutation })
        );
        break;
      }
      case "completeInboxItem":
        dispatch(
          setInboxItemAsync.fulfilled({ inboxItem: data[mutation], mutation })
        );
        break;
      case "adminUpdateCompany":
        dispatch(setCompanyAsync.fulfilled({ company: data[mutation] }));
        break;
      case "adminCreateCompanyGroup":
      case "adminDeleteCompanyGroup":
      case "adminUpdateCompanyGroup":
        dispatch(setGroupFulfilled({ group: data[mutation], mutation }));
        break;
      case "adminArchiveCompanySite":
      case "adminCreateCompanySite":
      case "adminRestoreCompanySite":
      case "adminUpdateCompanySite":
      case "linkSiteToCustomer":
      case "unlinkSiteFromCustomer":
        dispatch(setSiteFulfilled({ mutation, site: data[mutation] }));
        break;
      case "adminArchiveCompanyUser":
      case "adminRestoreCompanyUser":
      case "adminUpdateCompanyUser":
      case "clockIn":
      case "clockOut":
      case "adminClockOutUser":
        dispatch(setUserFulfilled({ mutation, user: data[mutation] }));
        break;
      case "archiveCustomer":
      case "createCustomer":
      case "restoreCustomer":
      case "updateCustomer":
        dispatch(
          setCustomerAsync.fulfilled({ customer: data[mutation], mutation })
        );
        break;
      case "adminDeleteDailyActivityReport":
      case "deleteDailyActivityReport":
        dispatch(
          setReportFulfilled({
            deleted: true,
            mutation,
            report: data[mutation],
          })
        );
        break;
      case "adminDeleteIncidentReport":
      case "deleteIncidentReport":
        dispatch(
          setReportFulfilled({
            deleted: true,
            mutation,
            report: mapToIncidentReport(data[mutation]),
          })
        );
        break;
      case "adminUpdateDailyActivityReport":
      case "createDailyActivityReport":
      case "submitDailyActivityReport":
      case "updateDailyActivityReport":
        dispatch(
          setReportFulfilled({
            mutation,
            report: data[mutation],
          })
        );
        break;
      case "adminUpdateIncidentReport":
      case "createIncidentReport":
      case "submitIncidentReport":
      case "updateIncidentReport":
        dispatch(
          setReportFulfilled({
            mutation,
            report: mapToIncidentReport(data[mutation]),
          })
        );
        break;
      case "completeJob":
      case "dismissJob":
        dispatch(setJobsFulfilled({ jobs: data[mutation], mutation }));
        break;
      case "createActionItemCompletionRecord":
      case "createJob":
      case "createStartJobRecord":
      case "createTaskCompletionRecord":
      case "deleteActionItemCompletionRecord":
      case "deleteJob":
      case "deleteStartJobRecord":
      case "deleteTaskCompletionRecord":
      case "updateJob":
        dispatch(setJobFulfilled({ job: data[mutation], mutation }));
        break;
      case "adminDeleteTimecard":
        dispatch(
          setTimeCard.fulfilled({
            deleted: true,
            mutation,
            timeCard: data[mutation],
          })
        );
        break;
      case "adminUpdateTimecard":
        dispatch(setTimeCard.fulfilled({ mutation, timeCard: data[mutation] }));
        break;
      default:
        break;
    }
  }
};

const offlineConfig = {
  callback: handleOfflineCallback,
  storage: Platform.OS === "web" ? localforage : AsyncStorage,
};

const appsyncConfig = {
  url: config.aws.aws_appsync_graphqlEndpoint,
  region: config.aws.aws_appsync_region,
  auth: {
    type: config.aws.aws_appsync_authenticationType,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  offlineConfig,
};

export default appsyncConfig;
