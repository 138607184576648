import { Media } from "../../../types";
import deepCopy from "../../utils/deepCopy";
import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import { setJobPending } from "../../../store/jobs/slice";

interface CreateActionItemCompletionRecordInput {
  actionItemId: string;
  coordinates: {
    accuracy?: number;
    lat: number;
    lng: number;
  };
  dailyActivityReportId?: string;
  jobId: string;
  media?: Media;
  timestamp: string;
}

const dispatchOptimisticResponse = ({
  actionItemId,
  coordinates,
  dailyActivityReportId,
  jobId,
  media,
  timestamp,
}: CreateActionItemCompletionRecordInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = selectUser(state);
  const job = selectJobById(state, jobId);
  const actionItemCompletionRecord = {
    actionItemId,
    record: {
      at: timestamp,
      by: userId,
      coordinates,
    },
    media: media && {
      createdAt: media.createdAt,
      description: media.description,
      id: media.id,
      type: media.type,
    },
    dailyActivityReportId,
  };
  dispatch(
    setJobPending({
      job: {
        ...job,
        actionItemCompletionRecords: job.actionItemCompletionRecords
          ? [...job.actionItemCompletionRecords, actionItemCompletionRecord]
          : [actionItemCompletionRecord],
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
      mutation: "createActionItemCompletionRecord",
    })
  );
};

const createActionItemCompletionRecord = ({
  actionItemId,
  coordinates,
  dailyActivityReportId,
  jobId,
  media,
  timestamp,
}: CreateActionItemCompletionRecordInput) => {
  dispatchOptimisticResponse({
    actionItemId,
    coordinates,
    dailyActivityReportId,
    jobId,
    media,
    timestamp,
  });
  client.mutate({
    mutation,
    variables: deepCopy({
      actionItemId,
      coordinates,
      dailyActivityReportId,
      jobId,
      media,
      timestamp,
    }),
  });
};

export default createActionItemCompletionRecord;
