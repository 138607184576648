import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import { setJobPending } from "../../../store/jobs/slice";
import { Assignee, Task } from "../../../types";

interface UpdateJobInput {
  assignedTo: Array<Assignee>;
  endDateTime?: string;
  id: string;
  immediate: boolean;
  rrule?: string;
  siteId: string;
  startDateTime: string;
  tasks: Array<Task>;
  includeCreatorInMessages?: boolean;
  autostart?: boolean;
}

const dispatchOptimisticResponse = ({
  assignedTo,
  endDateTime,
  id,
  immediate,
  rrule,
  siteId,
  startDateTime,
  tasks,
  includeCreatorInMessages,
  autostart,
}: UpdateJobInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const timestamp = new Date().toISOString();
  const job = selectJobById(state, id);
  dispatch(
    setJobPending({
      job: {
        ...job,
        startDateTime,
        endDateTime,
        siteId,
        assignedTo,
        updated: {
          at: timestamp,
          by: userId,
        },
        tasks,
        syncDateTime: timestamp,
        rrule,
        immediate,
        includeCreatorInMessages,
        autostart,
      },
      mutation: "updateJob",
    })
  );
};

const updateJob = ({
  assignedTo,
  endDateTime,
  id,
  immediate,
  rrule,
  siteId,
  startDateTime,
  tasks,
  includeCreatorInMessages,
  autostart,
}: UpdateJobInput) => {
  const input = deepCopy({
    id,
    siteId,
    assignedTo,
    tasks,
    startDateTime,
    endDateTime,
    rrule,
    immediate,
    includeCreatorInMessages,
    autostart,
  });
  dispatchOptimisticResponse(input);
  client.mutate({
    mutation,
    variables: deepCopy(input),
  });
};

export default updateJob;
