import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Layout, Spinner, Text } from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import queryCustomerEmailActivity from "../../api/functions/queryCustomerEmailActivity";
import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import EmailActivityListItem from "../../components/listItems/EmailActivity";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { EmailActivity, StackParamList } from "../../types";

const FlatListContainer = styled.View`
  flex: 1;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const CustomerEmailActivity = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "CustomerEmailActivity">
  >();
  const { params } = useRoute<
    RouteProp<StackParamList, "CustomerEmailActivity">
  >();
  const customerId = params?.customerId;

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [emailActivityFeed, setEmailActivityFeed] = useState<{
    items: Array<EmailActivity>;
    nextToken?: string;
  }>();

  const runQueryCustomerEmailActivity = useCallback(async (id: string) => {
    try {
      setEmailActivityFeed(await queryCustomerEmailActivity({ id }));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Query customer email activity failed",
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await runQueryCustomerEmailActivity(customerId);
      setLoading(false);
    })();
  }, [customerId]);

  return (
    <Root level="2">
      <HeaderContainer>
        <Header
          navigation={{
            icon: "arrow-back",
            onPress: navigation.goBack,
          }}
          title="Customer email activity"
        />
      </HeaderContainer>
      <Divider />
      <FlatListContainer>
        <FlatList
          contentContainerStyle={[
            styles.flatListContentContainerStyle,
            {
              paddingBottom: insets.bottom,
              paddingEnd: insets.right,
              paddingStart: insets.left,
            },
          ]}
          data={emailActivityFeed?.items}
          ItemSeparatorComponent={() => (
            <Layout>
              <StyledDivider />
            </Layout>
          )}
          keyExtractor={(item) => item.id}
          ListEmptyComponent={
            <ListEmptyContainer>
              <Separator />
              {loading ? (
                <Spinner />
              ) : (
                <Text appearance="hint" category="s1">
                  No results found
                </Text>
              )}
            </ListEmptyContainer>
          }
          onEndReached={async () => {
            if (emailActivityFeed.nextToken) {
              try {
                const queryCustomerEmailActivityResult = await queryCustomerEmailActivity(
                  {
                    after: emailActivityFeed.nextToken,
                    id: customerId,
                  }
                );
                setEmailActivityFeed({
                  items: emailActivityFeed.items.concat(
                    queryCustomerEmailActivityResult.items
                  ),
                  nextToken: queryCustomerEmailActivityResult.nextToken,
                });
              } catch (error) {
                addNotification({
                  message: error?.message || error,
                  status: "danger",
                  title: "Query customer email activity failed",
                });
              }
            }
          }}
          onRefresh={async () => {
            setRefreshing(true);
            await runQueryCustomerEmailActivity(customerId);
            setRefreshing(false);
          }}
          refreshing={refreshing}
          renderItem={({ item: emailActivity }) => (
            <Layout>
              <EmailActivityListItem
                emailActivity={emailActivity}
                timestampFormat={dateTimeFormat}
              />
            </Layout>
          )}
        />
      </FlatListContainer>
    </Root>
  );
};

export default CustomerEmailActivity;
