import gql from "graphql-tag";

const deleteInvoice = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(id: $id) {
      deleted
      id
    }
  }
`;

export default deleteInvoice;
