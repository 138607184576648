import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import {
  CompositeNavigationProp,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import {
  Divider,
  Layout,
  useTheme,
  Text,
  Icon,
  TopNavigationAction,
} from "@ui-kitten/components";
import { Accuracy } from "expo-location";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Keyboard, Platform, StyleSheet, View } from "react-native";
import { UserLocationChangeEvent } from "react-native-maps";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import Supercluster from "supercluster";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { AppDisablerContext } from "../../components/AppDisabler";

import BottomTabHeader from "../../components/BottomTabHeader";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import CreateFab from "../../components/CreateFab";
import DefaultMapView from "../../components/map/DefaultMapView";
import FilterBar from "../../components/FilterBar";
import JobMarker from "../../components/markers/Job";
import JobClusterMarker from "../../components/markers/JobCluster";
import UserMarker from "../../components/markers/User";
import ChangelogModal from "../../components/modals/Changelog";
import JobPickerFromIdsModal from "../../components/modals/JobPickerFromIds";
import SitePickerModal from "../../components/modals/SitePicker";
import UnassignedJobsListModal from "../../components/modals/UnassignedJobsList";
import UpcomingJobsListModal from "../../components/modals/UpcomingJobsList";
import Polyline from "../../components/Polyline";
import ThemedFAB from "../../components/ThemedFAB";
import jobStatuses from "../../constants/jobStatuses";
import mapEdgePadding from "../../constants/mapEdgePadding";
import navigationRoutes from "../../constants/navigationRoutes";
import useAlertSound from "../../hooks/useAlertSound";
import usePrevious from "../../hooks/usePrevious";
import {
  getLocation,
  setLocation,
  getPrevious,
} from "../../location/mapLocation";
import {
  getCurrentLocation,
  startLocationTracking,
  stopLocationTracking,
} from "../../location/useLocation";
import selectSignedInUser from "../../store/auth/selectors/selectUser";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../store/jobs/selectors/selectJobEntities";
import selectMapSelection from "../../store/mapSelection/selectors/selectMapSelection";
import selectLocationPermission from "../../store/permissions/selectors/selectLocationPermission";
import selectAccessibleSiteIds from "../../store/sites/selectors/selectAccessibleSites";
import selectSiteEntities from "../../store/sites/selectors/selectEntities";
import selectClockedIn from "../../store/users/selectors/selectClockedIn";
import selectUserEntities from "../../store/users/selectors/selectEntities";
import selectVisibleUsers from "../../store/users/selectors/visibleUsers";
import {
  BottomTabParamList,
  FormattedJob,
  NestedBottomTabNavigationProp,
  TutorialTypes,
} from "../../types";
import getDeltaFromZoom from "../../utils/getDeltaFromZoom";
import {
  geoJsonFormat,
  getViewBox,
  getRotationAngle,
  GeoJsonFormatReturn,
} from "../../utils/map";
import selectMapFilter from "../../store/jobs/selectors/selectMapFilter";
import selectUpcomingJobIds from "../../store/jobs/selectors/selectUpcomingJobIds";
import { refreshJobStatuses } from "../../store/jobs/slice";
import getJobStatus from "../../utils/getJobStatus";
import SiteClusterMarker from "../../components/markers/SiteCluster";
import SiteMarker from "../../components/markers/Site";
import SitePickerFromIdsModal from "../../components/modals/SitePickerFromIds";
import UserMapCircle from "../../components/LiveUserMapCircle";
import SiteMapCircle from "../../components/LiveSiteMapCircle";
import TutorialModal from "../../components/modals/Tutorial";
import tutorials from "../../tutorials";
import selectTutorialStatus from "../../store/tutorials/selectors/selectTutorialStatus";
import { setTutorialStatus } from "../../store/tutorials/slice";
import AlertModal from "../../components/modals/Alert";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  map: {
    flex: 1,
  },
  mapContainer: {
    flex: 1,
  },
  filterFab: {
    position: "absolute",
    bottom: 0,
    left: 0,
    flex: 1,
  },
  mobileFollowFab: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  webFollowFab: {
    position: "absolute",
    bottom: 84,
    right: 16,
  },
  followingFab: {
    position: "absolute",
    top: 16,
  },
  statusCardContainer: {
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
  additionalContainer: {
    flexDirection: "row",
    marginHorizontal: 8,
    marginBottom: 8,
  },
  topNavigationAccessoryRight: {
    flexDirection: "row",
  },
  upcomingJobCardContainer: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    zIndex: 1,
  },
  userCardContainer: {
    alignItems: "center",
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    zIndex: 1,
  },
  loadingScreen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  listFab: {
    position: "absolute",
    bottom: 16,
  },
  listFabContainer: { alignItems: "center", justifyContent: "center" },
});

const defaultZoom = 16;
const defaultDelta = getDeltaFromZoom(defaultZoom);
const maxZoom = 21;

const Jobs = () => {
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const navigation = useNavigation<
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList, "UpcomingJobs">,
      NestedBottomTabNavigationProp
    >
  >();
  const { params } = useRoute<RouteProp<BottomTabParamList, "UpcomingJobs">>();
  const theme = useTheme();

  const { disableApp } = useContext(AppDisablerContext);

  const [tutorial, setTutorial] = useState<{
    type: TutorialTypes;
    show: boolean;
  }>({ type: "quickstartEmployeeMobile", show: false });
  const [jobAlert, setJobAlert] = useState({
    show: false,
    message: "",
    confirmText: "",
    jobId: null,
  });
  const [mapDims, setMapDims] = useState(null);
  const [showList, setShowList] = useState(false);
  const [showUnassignedList, setShowUnassignedList] = useState(false);
  const [jobSupercluster, setJobSupercluster] = useState(null);
  const [userSupercluster, setUserSupercluster] = useState(null);
  const [siteSupercluster, setSiteSupercluster] = useState(null);
  const [locationReady, setLocationReady] = useState(false);
  const [siteClusterModal, setSiteClusterModal] = useState({
    show: false,
    ids: [],
  });
  const [clusterModal, setClusterModal] = useState({
    show: false,
    ids: [],
  });
  const [viewBox, setViewBox] = useState({
    northeast: {
      latitude: 0,
      longitude: 0,
    },
    southwest: {
      latitude: 0,
      longitude: 0,
    },
    zoom: 0,
  });
  const [defaultCoordinates, setDefaultCoordinates] = useState(null);
  const [sitePickerModalVisible, setSitePickerModalVisible] = useState(false);
  const accessibleSiteIds = useSelector(selectAccessibleSiteIds);
  const mapSelection = useSelector(selectMapSelection);
  const { assignees } = useSelector(selectMapFilter);
  const { coordinates: userCoords, follow, polylineCoordinates } = mapSelection;
  const locationPermission = useSelector(selectLocationPermission);
  const visibleUsers = useSelector(selectVisibleUsers);
  const users = useSelector(selectUserEntities);
  const clockedIn = useSelector(selectClockedIn);
  const signedInUser = useSelector(selectSignedInUser);
  const { accessible: accessibleJobs } = useSelector(selectMapJobs);
  const upcomingJobIds = useSelector(selectUpcomingJobIds);
  const jobs = useSelector(selectJobEntities);
  const sites = useSelector(selectSiteEntities);
  const autoTutorialStatus = useSelector((state) =>
    selectTutorialStatus(
      state,
      signedInUser.group !== "Employee"
        ? "quickstartAdminAndSupervisorMobile"
        : "quickstartEmployeeMobile"
    )
  );
  const map = useRef(null);
  const prevAccessibleJobs: Array<string> | undefined = usePrevious(
    accessibleJobs
  );
  const { playAlertSound, stopAlertSound } = useAlertSound();

  useEffect(() => {
    if (jobAlert.show) {
      playAlertSound();
    } else {
      stopAlertSound();
    }
  }, [jobAlert.show]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (params) {
      map.current.setCamera({
        center: {
          latitude: params.lat,
          longitude: params.lng,
        },
        zoom: 17,
      });
    }
  }, [params]);

  useEffect(() => {
    if (
      autoTutorialStatus === "notCompleted" &&
      defaultCoordinates &&
      // @ts-ignore
      !window.Cypress
    ) {
      setTimeout(() => {
        setTutorial((prev) => ({
          ...prev,
          type:
            signedInUser.group !== "Employee"
              ? "quickstartAdminAndSupervisorMobile"
              : "quickstartEmployeeMobile",
          show: true,
        }));
      }, 1000);
    }
  }, [autoTutorialStatus, signedInUser.group, defaultCoordinates]);

  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      const dispatchRefresh = upcomingJobIds.some((jobId) => {
        const job = jobs[jobId];
        const jobStatus = getJobStatus({
          job,
          jobAssigneeFilter: assignees,
          userId: signedInUser.id,
        });
        return (
          job.status === jobStatuses.future && jobStatus === jobStatuses.late
        );
      });
      if (dispatchRefresh) {
        dispatch(refreshJobStatuses());
      }
    }, 60000);
    return () => clearInterval(refreshInterval);
  }, [assignees, dispatch, jobs, signedInUser, upcomingJobIds]);

  useEffect(() => {
    let newJobs = [];

    if (prevAccessibleJobs) {
      newJobs = accessibleJobs.filter((id) => !prevAccessibleJobs.includes(id));
    }
    newJobs.some((newJob) => {
      const job = jobs[newJob];
      if (
        job.status.id === jobStatuses.immediate.id &&
        signedInUser &&
        job.created.by !== signedInUser.id
      ) {
        const site = sites[job.siteId];
        setJobAlert({
          show: true,
          message: `ASAP job created at ${site.name}.`,
          confirmText: "View",
          jobId: job.id,
        });
        return true;
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleJobs, prevAccessibleJobs, jobs, sites, signedInUser]);

  useEffect(() => {
    if (polylineCoordinates && map.current) {
      map.current.fitToCoordinates(polylineCoordinates, {
        edgePadding: mapEdgePadding,
        animated: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylineCoordinates]);

  useEffect(() => {
    if (!defaultCoordinates) {
      setDefaultCoordinates(userCoords);
    }
  }, [userCoords, defaultCoordinates]);

  useEffect(() => {
    (async () => {
      if (locationPermission) {
        await getCurrentLocation(Accuracy.High);
      }
    })();
  }, [locationPermission]);

  useDeepCompareEffectNoCheck(() => {
    (async () => {
      if (locationPermission && clockedIn) {
        await startLocationTracking();
      } else {
        await stopLocationTracking();
      }
    })();
    return () => {
      (async () => {
        await stopLocationTracking();
      })();
    };
  }, [locationPermission, clockedIn]);

  useEffect(() => {
    const newSupercluster = new Supercluster({
      radius: 40,
      minZoom: 0,
      maxZoom,
    });

    const sitesWithJobs = new Set();
    // Map to store the most recent job for each site
    const mostRecentJobsBySite: Record<string, FormattedJob> = {};

    // Iterate over accessible jobs to find the most recent job for each site
    Object.values(accessibleJobs).forEach((jobId) => {
      const job = jobs[jobId];
      const { siteId } = job;
      const startDateTime = new Date(job.startDateTime).getTime();

      if (
        !mostRecentJobsBySite[siteId] ||
        startDateTime <
          new Date(mostRecentJobsBySite[siteId].startDateTime).getTime() ||
        job.status === jobStatuses.immediate
      ) {
        mostRecentJobsBySite[siteId] = job;
      }
    });

    // Load the most recent job for each site into the supercluster
    newSupercluster.load(
      Object.values(mostRecentJobsBySite).map((job) => {
        const { siteId } = job;
        const site = sites[siteId];
        sitesWithJobs.add(siteId);
        return geoJsonFormat({
          latitude: site.coordinates.lat,
          longitude: site.coordinates.lng,
          id: job.id,
        });
      })
    );

    setJobSupercluster(newSupercluster);

    if (signedInUser.group !== "Employee") {
      const newSiteSupercluster = new Supercluster({
        radius: 40,
        minZoom: 0,
        maxZoom,
      });
      const sitesToLoad: Array<GeoJsonFormatReturn> = [];

      Object.values(sites).forEach((site) => {
        if (!sitesWithJobs.has(site.id) && !site.isArchived) {
          sitesToLoad.push(
            geoJsonFormat({
              latitude: site.coordinates.lat,
              longitude: site.coordinates.lng,
              id: site.id,
            })
          );
        }
      });
      newSiteSupercluster.load(sitesToLoad);
      setSiteSupercluster(newSiteSupercluster);
    }
  }, [jobs, accessibleJobs, sites, signedInUser.group]);

  useEffect(() => {
    const newSupercluster = new Supercluster({
      radius: 0,
      minZoom: 0,
      maxZoom: 0,
    });

    newSupercluster.load(
      visibleUsers.map((userId) => {
        return geoJsonFormat({
          latitude: users[userId].coordinates.lat,
          longitude: users[userId].coordinates.lng,
          id: userId,
        });
      })
    );

    setUserSupercluster(newSupercluster);
  }, [users, visibleUsers]);

  const handleUserPress = useCallback(
    (id) => {
      navigation.navigate(navigationRoutes.UserDetails, { userId: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleCenterMap = async () => {
    if (map.current) {
      const location = await getLocation({ title: "Error" });
      if (location) {
        map.current.animateCamera({
          center: {
            latitude: location.latitude,
            longitude: location.longitude,
          },
          zoom: 17,
        });
      }
    }
  };
  const handleMapLayout = ({ nativeEvent: { layout } }) => {
    const { height, width } = layout;
    setMapDims({
      height,
      width,
    });
  };
  const handleRegionChangeComplete = (region) => {
    if (map.current && mapDims) {
      const newViewBox = getViewBox({ region, mapDims });
      setViewBox(newViewBox);
    }
  };
  const handleJobPress = useCallback(
    (id) => {
      navigation.navigate(navigationRoutes.JobDetails, { jobId: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );
  const handleSitePress = useCallback(
    (id) => {
      navigation.navigate(navigationRoutes.SiteDetails, { siteId: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClusterPress = ({ id, coordinate, onMaxZoom, supercluster }) => {
    const numberId = parseInt(id, 10);
    const zoom = supercluster.getClusterExpansionZoom(numberId);
    if (zoom >= maxZoom) {
      // markers are too close to un-cluster, show additional ui to pick
      onMaxZoom(
        supercluster.getLeaves(numberId, "Infinity").map((item) => item.id)
      );
    } else if (map.current) {
      map.current.animateCamera({ center: coordinate, zoom });
    }
  };
  const handleSiteClusterPress = (id, coordinate) => {
    handleClusterPress({
      id,
      coordinate,
      supercluster: siteSupercluster,
      onMaxZoom: (ids) => setSiteClusterModal({ show: true, ids }),
    });
  };
  const handleJobClusterPress = (id, coordinate) => {
    handleClusterPress({
      id,
      coordinate,
      supercluster: jobSupercluster,
      onMaxZoom: (ids) => setClusterModal({ show: true, ids }),
    });
  };
  const handleLocationReady = ({ latitude, longitude }) => {
    if (map.current) {
      const coords = [
        ...accessibleJobs.map((id) => {
          const { siteId } = jobs[id];
          return {
            latitude: sites[siteId].coordinates.lat,
            longitude: sites[siteId].coordinates.lng,
          };
        }),
        {
          latitude,
          longitude,
        },
      ];
      map.current.fitToCoordinates(coords, {
        edgePadding: mapEdgePadding,
        animated: true,
      });
    }
    setLocationReady(true);
  };
  const handleUserLocationChange = ({
    nativeEvent: { coordinate },
  }: UserLocationChangeEvent) => {
    if (coordinate.isFromMockProvider) {
      disableApp();
    } else {
      const coordinates = {
        latitude: coordinate.latitude,
        longitude: coordinate.longitude,
      };
      setLocation(coordinate);
      if (!locationReady) handleLocationReady(coordinates);
      const prevCoords = getPrevious();
      if (prevCoords && follow === "self" && map.current) {
        const heading = getRotationAngle(prevCoords, coordinate);
        map.current.animateCamera({
          center: coordinates,
          heading: coordinate.speed > 5 ? heading : undefined,
          zoom: 17,
        });
      }
    }
  };
  const renderDirectionsPolyline = () => {
    if (polylineCoordinates) {
      return (
        <Polyline
          coordinates={polylineCoordinates}
          strokeColor={theme["color-primary-default"]}
          strokeWidth={3}
        />
      );
    }
    return null;
  };
  const handleCloseList = () => {
    setShowList(false);
  };
  const handleOpenList = () => {
    setShowList(true);
  };
  const handleCloseJobPicker = () => {
    setClusterModal((prevState) => ({ ...prevState, show: false }));
  };
  const handleClusterJobSelect = (id) => {
    handleCloseJobPicker();
    navigation.navigate(navigationRoutes.JobDetails, { jobId: id });
  };
  const handleCloseSitePicker = () => {
    setSiteClusterModal((prev) => ({ ...prev, show: false }));
  };
  const handleClusterSiteSelect = (id) => {
    handleCloseSitePicker();
    navigation.navigate(navigationRoutes.SiteDetails, { siteId: id });
  };
  const handleViewUnassigned = () => {
    setShowUnassignedList(true);
  };
  const closeJobAlert = () => {
    setJobAlert((prev) => ({ ...prev, show: false }));
  };

  const renderUserMarkers = () => {
    if (!userSupercluster) return null;
    const clusters = userSupercluster.getClusters(
      [
        viewBox.southwest.longitude,
        viewBox.southwest.latitude,
        viewBox.northeast.longitude,
        viewBox.northeast.latitude,
      ],
      viewBox.zoom
    );
    return clusters.map((cluster) => {
      const { id } = cluster;
      if (cluster.properties || !users[id]) return null;
      const returnValue = [
        <UserMarker
          id={id}
          key={id}
          lat={users[id].coordinates.lat}
          lng={users[id].coordinates.lng}
          onPress={handleUserPress}
        />,
      ];

      if (viewBox.zoom >= 16) {
        returnValue.push(<UserMapCircle key={`${id}-geofence`} id={id} />);
      }
      return returnValue;
    });
  };

  const renderSiteMarkers = () => {
    if (!siteSupercluster || signedInUser.group === "Employee") return null;
    const clusters = siteSupercluster.getClusters(
      [
        viewBox.southwest.longitude,
        viewBox.southwest.latitude,
        viewBox.northeast.longitude,
        viewBox.northeast.latitude,
      ],
      viewBox.zoom
    );
    return clusters.map((cluster) => {
      const { id } = cluster;
      const coordinate = {
        latitude: cluster.geometry.coordinates[1],
        longitude: cluster.geometry.coordinates[0],
      };
      if (cluster.properties) {
        return (
          <SiteClusterMarker
            coordinate={coordinate}
            id={`${id}`}
            key={id}
            lat={coordinate.latitude}
            lng={coordinate.longitude}
            text={`${cluster.properties.point_count_abbreviated}`}
            onPress={(clusterId) =>
              handleSiteClusterPress(clusterId, coordinate)
            }
          />
        );
      }
      const site = sites[id];
      if (site) {
        const returnValue = [
          <SiteMarker
            key={id}
            id={id}
            lat={site.coordinates.lat}
            lng={site.coordinates.lng}
            onPress={handleSitePress}
          />,
        ];
        if (viewBox.zoom >= 16)
          returnValue.push(<SiteMapCircle key={`${id}-geofence`} id={id} />);
        return returnValue;
      }
      return null;
    });
  };

  const renderJobMarkers = () => {
    if (!jobSupercluster) return null;
    const clusters = jobSupercluster.getClusters(
      [
        viewBox.southwest.longitude,
        viewBox.southwest.latitude,
        viewBox.northeast.longitude,
        viewBox.northeast.latitude,
      ],
      viewBox.zoom
    );
    return clusters.map((cluster) => {
      const { id } = cluster;
      const coordinate = {
        latitude: cluster.geometry.coordinates[1],
        longitude: cluster.geometry.coordinates[0],
      };
      if (cluster.properties) {
        return (
          <JobClusterMarker
            key={id}
            id={`${id}`}
            coordinate={coordinate}
            lat={coordinate.latitude}
            lng={coordinate.longitude}
            leaves={jobSupercluster
              .getLeaves(id, "Infinity")
              .map((leaf) => leaf.id)}
            text={`${cluster.properties.point_count_abbreviated}`}
            onPress={(clusterId) =>
              handleJobClusterPress(clusterId, coordinate)
            }
          />
        );
      }
      const job = jobs[id];
      if (job) {
        const site = sites[job.siteId];
        const returnValue = [
          <JobMarker
            key={id}
            id={id}
            lat={site.coordinates.lat}
            lng={site.coordinates.lng}
            onPress={handleJobPress}
          />,
        ];

        if (viewBox.zoom >= 16) {
          returnValue.push(
            <SiteMapCircle key={`${id}-geofence`} id={site.id} />
          );
        }
        return returnValue;
      }
      return null;
    });
  };
  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <BottomTabHeader
              accessoryRight={() => (
                <TopNavigationAction
                  icon={(props) => <Icon name="search" {...props} />}
                  onPress={() => {
                    Keyboard.dismiss();
                    setSitePickerModalVisible(true);
                  }}
                />
              )}
              data-test="bottomTabHeaderInScheduleScreen"
              title="Map"
            />
          </View>
        </Container>
        <Divider />
        <View style={styles.mapContainer} onLayout={handleMapLayout}>
          {defaultCoordinates ? (
            <DefaultMapView
              initialRegion={{
                latitude: defaultCoordinates.latitude,
                longitude: defaultCoordinates.longitude,
                latitudeDelta: defaultDelta,
                longitudeDelta: defaultDelta,
              }}
              loadingEnabled
              onRegionChangeComplete={handleRegionChangeComplete}
              onUserLocationChange={handleUserLocationChange}
              rotateEnabled
              showsCompass
              zoomTapEnabled={false}
              ref={map}
              showsMyLocationButton={false}
              showsPointsOfInterest={false}
              showsTraffic
              showsUserLocation
              style={styles.map}
            >
              {renderJobMarkers()}
              {renderDirectionsPolyline()}
              {renderUserMarkers()}
              {renderSiteMarkers()}
            </DefaultMapView>
          ) : (
            <Layout style={styles.loadingScreen}>
              <Text category="h4">Acquiring Location...</Text>
            </Layout>
          )}
          <View
            style={
              Platform.OS === "web"
                ? styles.webFollowFab
                : styles.mobileFollowFab
            }
          >
            {userRolePermitted({
              permittedRoles: ["Admin", "Supervisor"],
              userRole: signedInUser.group,
            }) && (
              <>
                <CreateFab />
                <Separator size="small" />
              </>
            )}
            <ThemedFAB
              icon="crosshairs-gps"
              onPress={handleCenterMap}
              level="2"
              isVisible={defaultCoordinates}
              selected={Boolean(follow)}
            />
          </View>
          <View style={styles.listFabContainer}>
            <View style={styles.listFab}>
              <ThemedFAB
                data-test="jobListFabInJobsScreen"
                icon="calendar-text"
                isVisible
                label="List"
                level="2"
                onPress={handleOpenList}
              />
            </View>
          </View>
          {mapDims && (
            <View style={styles.statusCardContainer}>
              <Container>
                <FilterBar
                  callback={handleOpenList}
                  onViewUnassigned={handleViewUnassigned}
                />
              </Container>
            </View>
          )}
        </View>
      </Layout>
      <UpcomingJobsListModal isVisible={showList} onClose={handleCloseList} />
      <UnassignedJobsListModal
        isVisible={showUnassignedList}
        onClose={() => {
          setShowUnassignedList(false);
        }}
      />
      <JobPickerFromIdsModal
        onSelect={handleClusterJobSelect}
        onClose={handleCloseJobPicker}
        ids={clusterModal.ids}
        isVisible={clusterModal.show}
      />
      <SitePickerModal
        onClose={() => setSitePickerModalVisible(false)}
        siteIds={accessibleSiteIds}
        isVisible={sitePickerModalVisible}
        onSelect={(siteId) => {
          setSitePickerModalVisible(false);
          navigation.navigate(navigationRoutes.SiteDetails, { siteId });
        }}
      />
      <SitePickerFromIdsModal
        ids={siteClusterModal.ids}
        isVisible={siteClusterModal.show}
        onClose={handleCloseSitePicker}
        onSelect={handleClusterSiteSelect}
      />
      <TutorialModal
        visible={tutorial.show}
        tutorial={tutorials[tutorial.type]}
        onClose={() => {
          setTutorial((prev) => ({ ...prev, show: false }));
          dispatch(
            setTutorialStatus({ tutorial: tutorial.type, status: "completed" })
          );
        }}
      />
      <AlertModal
        isVisible={jobAlert.show}
        onClose={closeJobAlert}
        message={jobAlert.message}
        confirmText={jobAlert.confirmText}
        onConfirm={() => {
          closeJobAlert();
          navigation.navigate("JobDetails", {
            jobId: jobAlert.jobId,
          });
        }}
        cancelText="Close"
        onCancel={closeJobAlert}
      />
      <ChangelogModal />
    </>
  );
};

export default Jobs;
