import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export interface State {
  invoicesStale: boolean;
  quotesStale: boolean;
}

const initialState: State = {
  invoicesStale: false,
  quotesStale: false,
};

const slice = createSlice({
  initialState,
  name: "payments",
  reducers: {
    setInvoicesStale: (
      state,
      action: PayloadAction<{ invoicesStale: boolean }>
    ) => {
      const { invoicesStale } = action.payload;
      state.invoicesStale = invoicesStale;
    },
    setQuotesStale: (
      state,
      action: PayloadAction<{ quotesStale: boolean }>
    ) => {
      const { quotesStale } = action.payload;
      state.quotesStale = quotesStale;
    },
  },
  extraReducers: {
    [PURGE]: () => initialState,
  },
});

const { actions, reducer } = slice;

export const { setInvoicesStale, setQuotesStale } = actions;

export default reducer;
