import React, { PropsWithChildren } from "react";
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  root: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const Container = ({ children, style }: PropsWithChildren<Props>) => {
  return (
    <View style={Platform.OS === "web" ? [styles.root, style] : style}>
      {children}
    </View>
  );
};

Container.defaultProps = {
  style: undefined,
};

export default Container;
