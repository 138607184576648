import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { PostmarkMessageSendingResponse } from "../../../types";

interface SendQuoteInput {
  quoteId: string;
}

const sendQuote = async ({ quoteId }: SendQuoteInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        quoteId,
      },
    })) as GraphQLResult<{
      sendQuote: PostmarkMessageSendingResponse[];
    }>;
    return data.sendQuote;
  } catch ({ errors }) {
    throw generateErrorMessage("sendQuote", errors);
  }
};

export default sendQuote;
