import { Divider, Layout, Text } from "@ui-kitten/components";
import React from "react";
import { SectionList } from "react-native";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import selectEntities from "../../../store/jobs/selectors/selectJobEntities";
import formatSectionListData from "../../../utils/formatSectionListData";
import ScheduleJobListItem, {
  height as listItemHeight,
} from "../../listItems/ScheduleJob";
import DefaultFullScreenModal from "../DefaultFullScreen";

interface JobPickerFromIdsModalProps {
  ids: Array<string>;
  isVisible: boolean;
  onClose: () => void;
  onSelect: (jobId: string) => void;
}

const RootLayout = styled(Layout)`
  flex: 1;
`;

const SectionHeaderLayout = styled(Layout)`
  padding: 8px 16px;
`;

const StyledScheduleJobListItem = styled(ScheduleJobListItem)`
  margin-left: 16px;
  margin-right: 16px;
`;

const JobPickerFromIdsModal = ({
  isVisible,
  onClose,
  ids,
  onSelect,
}: JobPickerFromIdsModalProps) => {
  const jobs = useSelector(selectEntities);

  const { sections } = formatSectionListData({
    data: ids.map((id) => jobs[id]),
    startDateTimeExtractor: (item) => item.startDateTime,
    idExtractor: (item) => item.id,
    sortCompareFunction: (a, b) =>
      a.startDateTime.localeCompare(b.startDateTime),
  });

  return (
    <DefaultFullScreenModal
      leftControlIcon="close-outline"
      onClose={onClose}
      isVisible={isVisible}
      title="Multiple jobs"
      subtitle="Select a job to continue"
      scrollable={false}
    >
      <RootLayout level="2">
        <SectionList
          getItemLayout={sectionListGetItemLayout({
            getItemHeight: () => listItemHeight,
            getSeparatorHeight: () => 1,
            getSectionHeaderHeight: () => 36,
          })}
          ItemSeparatorComponent={Divider}
          keyExtractor={(item) => item}
          renderItem={({ item }) => (
            <StyledScheduleJobListItem jobId={item} onPress={onSelect} />
          )}
          renderSectionHeader={({ section: { data, title } }) => (
            <SectionHeaderLayout level="3">
              <Text>{`${title} (${data.length})`}</Text>
            </SectionHeaderLayout>
          )}
          sections={sections}
          stickySectionHeadersEnabled
        />
      </RootLayout>
    </DefaultFullScreenModal>
  );
};

export default JobPickerFromIdsModal;
