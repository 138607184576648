import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigationAction,
} from "@ui-kitten/components";
import React, { useCallback, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import GroupListItem from "../../components/listItems/Group";
import GroupEditingModal from "../../components/modals/GroupEditing";
import selectAll from "../../store/groups/selectors/selectAll";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import { StackParamList } from "../../types";
import sortGroups from "../../utils/sortGroups";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  inputContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  listContent: {
    paddingTop: 8,
  },
  root: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: "row",
  },
});

const CompanyGroups = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "CompanyGroups">
  >();

  const [search, setSearch] = useState({
    query: "",
    results: [],
  });
  const [createGroupVisible, setCreateGroupVisible] = useState(false);

  const allGroups = useSelector(selectAll);
  const groups = allGroups.filter((group) => !group.isDeleted);
  const signedInUserRole = useSelector(selectSignedInUserRole);

  const handlePress = useCallback(
    (id) => {
      navigation.navigate("GroupDetails", { groupId: id });
    },
    [navigation]
  );

  const runSearch = (text) => {
    const query = text.trim().toLowerCase();
    setSearch({
      query: text,
      results: groups.filter((group) =>
        group.name.toLowerCase().includes(query)
      ),
    });
  };

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <Header
              accessoryRight={() =>
                userRolePermitted({
                  permittedRoles: ["Admin", "Supervisor"],
                  userRole: signedInUserRole,
                }) && (
                  <TopNavigationAction
                    data-test="createGroupButton"
                    icon={(imageProps) => (
                      <Icon {...imageProps} name="plus-outline" />
                    )}
                    onPress={() => setCreateGroupVisible(true)}
                  />
                )
              }
              navigation={{ icon: "arrow-back", onPress: navigation.goBack }}
              title={(textProps) => (
                <View style={styles.titleContainer}>
                  <Text {...textProps}>Groups</Text>
                  <Separator horizontal size="small" />
                  <Text appearance="hint">
                    {search.query ? search.results.length : groups.length}
                  </Text>
                </View>
              )}
            />
          </View>
          <View style={styles.inputContainer}>
            <Input
              data-test="groupListSearchInput"
              value={search.query}
              onChangeText={runSearch}
              clearButtonMode="while-editing"
              placeholder="Search..."
            />
          </View>
        </Container>
        <Divider />
        <FlatList
          data={(search.query ? search.results : groups).sort(sortGroups)}
          contentContainerStyle={[
            styles.listContent,
            { paddingBottom: insets.bottom + 8 },
          ]}
          keyExtractor={(group) => group.id}
          renderItem={({ item: group }) => (
            <Container>
              <GroupListItem
                data-test={`${group.id}-groupListItem`}
                groupId={group.id}
                onPress={handlePress}
              />
            </Container>
          )}
        />
      </Layout>
      <GroupEditingModal
        isVisible={createGroupVisible}
        onClose={() => setCreateGroupVisible(false)}
      />
    </>
  );
};

export default CompanyGroups;
