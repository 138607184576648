import { useTheme } from "@ui-kitten/components";
import React from "react";

import Circle from "../Circle";

interface UserMapCircleProps {
  center: {
    latitude: number;
    longitude: number;
  };
  radius: number;
}

const UserMapCircle = ({ center, radius }: UserMapCircleProps) => {
  const theme = useTheme();

  return (
    <Circle
      center={center}
      fillColor={theme["color-primary-transparent-300"]}
      radius={radius}
    />
  );
};

export default UserMapCircle;
