import React from "react";
import { useTheme } from "@ui-kitten/components";
import { FAB } from "react-native-paper";
import { ViewStyle } from "react-native";

interface Props {
  icon?: string;
  label?: string;
  isVisible: boolean;
  onPress?: () => void;
  style?: ViewStyle;
  level?: string;
  alternate?: boolean;
  selected?: boolean;
}

const ThemedFAB = ({
  icon,
  label,
  isVisible,
  onPress,
  style,
  level,
  alternate,
  selected,
}: Props) => {
  const theme = useTheme();
  const themeColor = alternate
    ? theme["text-alternate-color"]
    : theme["text-basic-color"];
  return (
    <FAB
      icon={icon}
      label={label}
      visible={isVisible}
      onPress={onPress}
      color={selected ? theme["color-primary-500"] : themeColor}
      style={{
        backgroundColor: alternate
          ? theme[`background-alternative-color-${level}`]
          : theme[`background-basic-color-${level}`],
        ...style,
      }}
      accessibilityTraits
      accessibilityComponentType
    />
  );
};

ThemedFAB.defaultProps = {
  label: undefined,
  style: {},
  level: "1",
  alternate: false,
  selected: false,
  icon: undefined,
  onPress: undefined,
};

export default ThemedFAB;
