import React from "react";
import uuidv4 from "uuid/v4";
import * as yup from "yup";

import TextInputModal, { Props as TextInputModalProps } from "../TextInput";
import { CustomerEmail } from "../../../types";

interface Props
  extends Omit<TextInputModalProps, "multiline" | "onSubmit" | "text"> {
  email?: CustomerEmail;
  onSubmit: (email: CustomerEmail) => void;
}

const EmailEditingModal = ({
  email,
  headerSubtitle,
  headerTitle,
  isVisible,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <TextInputModal
      autoCapitalize="none"
      headerSubtitle={headerSubtitle}
      headerTitle={headerTitle}
      isVisible={isVisible}
      keyboardType="email-address"
      onClose={onClose}
      onSubmit={(text) =>
        onSubmit({ email: text, id: email ? email.id : uuidv4() })
      }
      text={email?.email}
      validationSchema={yup.object({
        text: yup.string().email("Must be a valid email"),
      })}
    />
  );
};

EmailEditingModal.defaultProps = {
  email: undefined,
};

export default EmailEditingModal;
