import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import adminDeleteCompanyGroup from "../../api/functions/adminDeleteCompanyGroup";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import ScheduleJobListItem from "../../components/listItems/ScheduleJob";
import UserListItem from "../../components/listItems/User";
import AlertModal from "../../components/modals/Alert";
import GroupEditingModal from "../../components/modals/GroupEditing";
import SectionItem from "../../components/SectionItem";
import UserRecord from "../../components/UserRecord";
import companyUserRoles from "../../constants/companyUserRoles";
import { addNotification } from "../../components/InAppNotifications";
import assigneeTypes from "../../constants/assigneeTypes";
import useIosBottomSpace from "../../device/useIosBottomSpace";
import selectUser from "../../store/auth/selectors/selectUser";
import selectGroupById from "../../store/groups/selectors/selectGroupById";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../store/jobs/selectors/selectJobEntities";
import { StackParamList } from "../../types";
import sortDateTimes from "../../utils/sortDateTimes";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  deletedMessageContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  horizontalMargin: {
    marginHorizontal: 16,
  },
  snackbar: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const GroupDetails = () => {
  const insets = useSafeAreaInsets();
  const iosBottomSpace = useIosBottomSpace();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "GroupDetails">
  >();

  const {
    params: { groupId },
  } = useRoute<RouteProp<StackParamList, "GroupDetails">>();
  const group = useSelector((state) => selectGroupById(state, groupId));
  const { accessible } = useSelector(selectMapJobs);
  const jobs = useSelector(selectJobEntities);
  const loggedInUser = useSelector(selectUser);
  const userRole = loggedInUser.group;

  const [alertVisible, setAlertVisible] = useState(false);
  const [editGroupVisible, setEditGroupVisible] = useState(false);

  const handleJobPress = (id) => {
    navigation.navigate("JobDetails", { jobId: id });
  };
  const renderJobs = () => {
    const usersJobIds = {};
    accessible.forEach((jobId) => {
      const { assignedTo } = jobs[jobId];
      assignedTo.forEach((assignee) => {
        if (assignee.type === assigneeTypes.group && assignee.id === groupId) {
          usersJobIds[jobId] = jobId;
        }
      });
    });

    const sortedJobIds = Object.keys(usersJobIds).sort((a, b) =>
      sortDateTimes(b, a)
    );

    if (sortedJobIds.length > 0) {
      return sortedJobIds.map((jobId) => {
        return (
          <ScheduleJobListItem
            jobId={jobId}
            key={jobId}
            onPress={handleJobPress}
          />
        );
      });
    }
    return <ListItem title="No accessible jobs" disabled />;
  };

  const getMembersCount = (members) => {
    if (members.length === 0) {
      return "No members";
    }
    if (members.length === 1) {
      return "1 member";
    }
    return `${members.length} members`;
  };

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.left,
              paddingStart: insets.right,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              navigation={{
                onPress: () => navigation.goBack(),
                icon: "arrow-back-outline",
              }}
              action={
                userRole !== companyUserRoles.EMPLOYEE && {
                  onPress: () => setEditGroupVisible(true),
                  text: "Edit",
                  "data-test": "groupDetailsEditButton",
                }
              }
              title="Group details"
            />
          </View>
        </Container>
        <Divider />
        {group !== undefined && (
          <Layout level="2" style={styles.content}>
            {!group.isDeleted ? (
              <ScrollView
                contentContainerStyle={{ paddingBottom: iosBottomSpace + 8 }}
              >
                <Container>
                  <Layout>
                    <Separator size="medium" />
                    <Text category="h6" style={styles.text}>
                      {group.name}
                    </Text>
                    <Text style={styles.text}>
                      {getMembersCount(group.members)}
                    </Text>
                    <Separator size="medium" />
                  </Layout>
                  <Divider />
                  <Separator />
                  <SectionItem title="MEMBERS">
                    {Array.isArray(group.members) &&
                    group.members.length > 0 ? (
                      group.members.map((userId) => (
                        <UserListItem key={userId} userId={userId} />
                      ))
                    ) : (
                      <ListItem title="None" />
                    )}
                  </SectionItem>
                  {userRole === companyUserRoles.ADMIN && (
                    <>
                      <Separator />
                      <SectionItem title="Jobs">{renderJobs()}</SectionItem>
                    </>
                  )}
                  {userRole === companyUserRoles.ADMIN && (
                    <>
                      <Separator />
                      <SectionItem status="danger" title="DANGER ZONE">
                        <ListItem
                          accessoryRight={() => (
                            <Button
                              data-test="deleteGroupButton"
                              onPress={() => setAlertVisible(true)}
                              size="small"
                              status="danger"
                            >
                              Delete
                            </Button>
                          )}
                          description="Once you delete a group there is no going back."
                          disabled
                          title="Delete this group"
                        />
                      </SectionItem>
                    </>
                  )}
                  <Separator />
                  <UserRecord
                    label="Created:"
                    style={styles.text}
                    userRecord={group.created}
                  />
                  <Separator size="small" />
                  <UserRecord
                    label="Updated:"
                    style={styles.text}
                    userRecord={group.updated}
                  />
                  <Separator />
                </Container>
              </ScrollView>
            ) : (
              <View style={styles.deletedMessageContainer}>
                <Text>Deleted</Text>
              </View>
            )}
          </Layout>
        )}
      </Layout>
      <AlertModal
        isVisible={alertVisible}
        onClose={() => setAlertVisible(false)}
        message="Are you sure you want to delete this group?"
        confirmText="Delete group"
        onConfirm={() => {
          adminDeleteCompanyGroup(group);
          setAlertVisible(false);
          addNotification({
            status: "success",
            title: "Group deleted",
          });
          navigation.goBack();
        }}
        cancelText="Cancel"
        onCancel={() => setAlertVisible(false)}
      />
      {group !== undefined && (
        <GroupEditingModal
          isVisible={editGroupVisible}
          onClose={() => setEditGroupVisible(false)}
          group={group}
        />
      )}
    </>
  );
};

export default GroupDetails;
