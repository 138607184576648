import _ from "lodash/array";
import { useSelector } from "react-redux";
import createActionItemCompletionRecord from "../../api/functions/createActionItemCompletionRecord";

import createTaskCompletionRecord from "../../api/functions/createTaskCompletionRecord";
import deleteActionItemCompletionRecord from "../../api/functions/deleteActionItemCompletionRecord";
import deleteTaskCompletionRecord from "../../api/functions/deleteTaskCompletionRecord";
import { talariumBotUserId } from "../../constants/users";
import selectCognitoUser from "../../store/auth/selectors/selectUser";
import selectJobById from "../../store/jobs/selectors/selectJobById";
import selectReportsByJobId from "../../store/jobs/selectors/selectReportsByJobId";
import selectClockedIn from "../../store/users/selectors/selectClockedIn";
import { Coordinates, Media } from "../../types";
import isCompletionRecordCompleted from "../../utils/isCompletionRecordCompleted";

const useJobUtils = (jobId: string) => {
  const job = useSelector((state) => selectJobById(state, jobId));
  const reports = useSelector((state) =>
    selectReportsByJobId(state, { jobId })
  );

  const { id: selfUserId } = useSelector(selectCognitoUser);
  const selfClockedIn = useSelector(selectClockedIn);
  const selfStartJobRecord =
    job?.startJobRecords[
      _.findIndex(
        job.startJobRecords,
        (startJobRecord) => startJobRecord.record.by === selfUserId
      )
    ];
  const talariumBotStartJobRecord =
    job?.startJobRecords[
      _.findIndex(
        job.startJobRecords,
        (startJobRecord) => startJobRecord.record.by === talariumBotUserId
      )
    ];
  const jobStarted = Boolean(
    job && (selfStartJobRecord || talariumBotStartJobRecord)
  );

  const getTaskCompletionRecordByTaskId = (taskId: string) =>
    job.taskCompletionRecords[
      job.taskCompletionRecords.findIndex(
        (element) => element.taskId === taskId
      )
    ];

  const getTaskCompletionRecordByReportId = (reportId) => {
    return job?.taskCompletionRecords.find(
      (taskCompletionRecord) =>
        taskCompletionRecord.dailyActivityReportId === reportId
    );
  };
  const getActionItemCompletionRecordByActionItemId = (actionItemId: string) =>
    job?.actionItemCompletionRecords &&
    job.actionItemCompletionRecords[
      job.actionItemCompletionRecords.findIndex(
        (element) => element.actionItemId === actionItemId
      )
    ];
  const getActionItemCompletionRecordByReportId = (reportId) => {
    return job?.actionItemCompletionRecords.find(
      (actionItemCompletionRecord) =>
        actionItemCompletionRecord.dailyActivityReportId === reportId
    );
  };

  const runCreateTaskCompletionRecord = ({
    coordinates,
    media,
    taskId,
  }: {
    coordinates: Coordinates;
    media?: Media;
    taskId: string;
  }) => {
    if (job) {
      createTaskCompletionRecord(
        {
          coordinates,
          media,
          taskId,
          timestamp: new Date().toISOString(),
        },
        job
      );
    }
  };
  const runDeleteTaskCompletionRecord = (taskId: string) => {
    if (job) {
      deleteTaskCompletionRecord({ taskId }, job);
    }
  };
  const runCreateActionItemCompletionRecord = ({
    actionItemId,
    coordinates,
    dailyActivityReportId,
    media,
  }: {
    actionItemId: string;
    coordinates: Coordinates;
    dailyActivityReportId?: string;
    media?: Media;
  }) => {
    if (job) {
      createActionItemCompletionRecord({
        actionItemId,
        coordinates,
        dailyActivityReportId,
        jobId,
        media,
        timestamp: new Date().toISOString(),
      });
    }
  };
  const runDeleteActionItemCompletionRecord = (actionItemId: string) => {
    if (job) {
      deleteActionItemCompletionRecord({ actionItemId, jobId });
    }
  };

  const areTasksCompleted = () => {
    let tasksCompleted = true;
    if (job?.tasks.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const task of job.tasks) {
        const taskCompletionRecord = getTaskCompletionRecordByTaskId(task.id);
        if (taskCompletionRecord) {
          const taskCompletionRecordDailyActivityReport =
            reports?.entities[taskCompletionRecord?.dailyActivityReportId];
          tasksCompleted = isCompletionRecordCompleted({
            checklistItem: task,
            completionRecord: taskCompletionRecord,
            completionRecordDailyActivityReport: taskCompletionRecordDailyActivityReport,
          });
        } else {
          tasksCompleted = false;
        }
        if (!tasksCompleted) {
          break;
        }
      }
    }
    return tasksCompleted;
  };

  return {
    createActionItemCompletionRecord: runCreateActionItemCompletionRecord,
    createTaskCompletionRecord: runCreateTaskCompletionRecord,
    deleteActionItemCompletionRecord: runDeleteActionItemCompletionRecord,
    deleteTaskCompletionRecord: runDeleteTaskCompletionRecord,
    getActionItemCompletionRecordByActionItemId,
    getActionItemCompletionRecordByReportId,
    getTaskCompletionRecordByTaskId,
    getTaskCompletionRecordByReportId,
    job,
    jobCompletionEnabled: Boolean(selfClockedIn && jobStarted),
    jobStarted,
    reports,
    selfClockedIn: Boolean(selfClockedIn),
    tasksCompleted: areTasksCompleted(),
  };
};

export default useJobUtils;
