import API, { GraphQLResult } from "@aws-amplify/api";

import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

interface AccountLink {
  expires_at: number;
  url: string;
  created: number;
}

const createStripeAccountLink = async () => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
    })) as GraphQLResult<{ createStripeAccountLink: AccountLink }>;
    return data.createStripeAccountLink;
  } catch ({ errors }) {
    throw generateErrorMessage("createStripeAccountLink", errors);
  }
};

export default createStripeAccountLink;
