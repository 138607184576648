import gql from "graphql-tag";

import { stripeExpandedInvoiceItemFragment } from "../../../fragments";

const createInvoiceItem = gql`
  mutation createInvoiceItem(
    $customer: ID!
    $invoice: ID!
    $price: ID!
    $quantity: Int!
    $tax_rates: [ID]
  ) {
    createInvoiceItem(
      customer: $customer
      invoice: $invoice
      price: $price
      quantity: $quantity
      tax_rates: $tax_rates
    ) {
      ...stripeExpandedInvoiceItemFragment
    }
  }
  ${stripeExpandedInvoiceItemFragment}
`;

export default createInvoiceItem;
