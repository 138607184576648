import gql from "graphql-tag";

import { stripeTaxRateFragment } from "../../../fragments";

const listTaxRates = gql`
  query listTaxRates($active: Boolean, $starting_after: ID) {
    listTaxRates(active: $active, starting_after: $starting_after) {
      data {
        ...stripeTaxRateFragment
      }
      has_more
    }
  }
  ${stripeTaxRateFragment}
`;

export default listTaxRates;
