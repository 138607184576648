import dayjs from "dayjs";
import { useSelector } from "react-redux";

import momentFormats from "../../constants/momentFormats";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectTimeFormat from "../../store/settings/selectors/selectTimeFormat";

interface Params {
  endDateTime?: string;
  showFullDate?: boolean;
  startDateTime: string;
}

const useTimeRange = ({ endDateTime, startDateTime }: Params) => {
  const timeFormat = useSelector(selectTimeFormat);
  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const startMoment = dayjs(startDateTime);
  const endMoment = dayjs(endDateTime);

  if (!endDateTime) {
    return dayjs(startDateTime).format(dateTimeFormat);
  }
  if (startMoment.isSame(endMoment, "day")) {
    return `${startMoment.format(timeFormat)} - ${endMoment.format(
      timeFormat
    )} ${startMoment.format(momentFormats.dateFormat)}`;
  }
  return `${startMoment.format(dateTimeFormat)} -\n${endMoment.format(
    dateTimeFormat
  )}`;
};

export default useTimeRange;
