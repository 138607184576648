import { JobStatus, JobStatusId } from "../../types";

const jobStatuses: Record<JobStatusId, JobStatus> = {
  completed: {
    colors: {
      default: "color-success-default",
      focus: "color-success-focus",
    },
    priority: 0,
    description: "Completed",
    id: "completed",
  },
  future: {
    colors: {
      default: "color-info-default",
      focus: "color-info-focus",
    },
    priority: 1,
    description: "Future",
    id: "future",
  },
  late: {
    colors: {
      default: "color-warning-default",
      focus: "color-warning-focus",
    },
    priority: 3,
    description: "Late",
    id: "late",
  },
  inProgress: {
    colors: {
      default: "color-primary-default",
      focus: "color-primary-focus",
    },
    priority: 2,
    description: "In progress",
    id: "inProgress",
  },
  immediate: {
    colors: {
      default: "color-danger-default",
      focus: "color-danger-focus",
    },
    priority: 4,
    description: "ASAP",
    id: "immediate",
  },
};

export default jobStatuses;
