import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = ({ timestamp }, incidentReport) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const report = {
    ...incidentReport,
    syncDateTime: timestamp,
    submitted: {
      at: timestamp,
      by: userId,
    },
  };
  dispatch(
    setReportPending({
      report,
      mutation: "submitIncidentReport",
    })
  );
};

const submitIncidentReport = (
  { timestamp = new Date().toISOString() },
  incidentReport
) => {
  dispatchOptimisticResponse(
    {
      timestamp,
    },
    incidentReport
  );
  client.mutate({
    mutation,
    variables: {
      id: incidentReport.id,
      timestamp,
      jobId: incidentReport.jobId,
    },
    errorPolicy: "all",
  });
};

export default submitIncidentReport;
