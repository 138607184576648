import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setTimeCard } from "../../../store/timeCards/slice";
import { Timecard, UserLocationRecord } from "../../../types";
import mapToUserLocationRecordInput from "../../utils/mapToUserLocationRecordInput";

const dispatchOptimisticResponse = (
  {
    quit,
    started,
  }: {
    quit: UserLocationRecord;
    started: UserLocationRecord;
  },
  timeCard: Timecard
) => {
  const { dispatch, getState } = store;
  const {
    auth: {
      user: { id: userId },
    },
  } = getState();
  dispatch(
    setTimeCard.pending({
      mutation: "adminUpdateTimecard",
      timeCard: {
        ...timeCard,
        updated: {
          at: new Date().toISOString(),
          by: userId,
        },
        quit,
        started,
      },
    })
  );
};

const adminUpdateTimecard = (
  {
    quit,
    started,
  }: {
    quit: UserLocationRecord;
    started: UserLocationRecord;
  },
  timeCard: Timecard
) => {
  dispatchOptimisticResponse({ quit, started }, timeCard);
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      id: timeCard.id,
      quit: mapToUserLocationRecordInput(quit),
      started: mapToUserLocationRecordInput(started),
    },
  });
};

export default adminUpdateTimecard;
