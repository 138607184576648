import { MaterialIcons } from "@expo/vector-icons";
import { Icon, Layout, Spinner, Text, useTheme } from "@ui-kitten/components";
import { DeviceType, getDeviceTypeAsync } from "expo-device";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import GoogleMapCoordinates, { getLocation } from "../../location/mapLocation";
import { CompanySite, Job } from "../../types";
import Separator from "../buildingBlocks/Separator";
import ListItem from "../listItems/Base";
import AlertModal from "../modals/Alert";
import UiKittenTouchable from "../UiKittenTouchable";

interface JobButtonsProps {
  job: Job;
  onDetailsPress: () => void;
  onLocatePress: () => void;
  onNavigateSuccess: (location: GoogleMapCoordinates) => void;
  onMessagesSuccess: () => void;
  site: CompanySite;
}

const MobileButtonContainer = styled(Layout)`
  align-items: center;
  justify-content: center;
  min-height: 72px;
  min-width: 72px;
`;

const MobileButtonsRow = styled.View`
  align-items: center;
  flex-direction: row;
  padding-right: 16px;
  padding-left: 16px;
`;

const StyledIcon = styled(Icon)`
  height: 24px;
  width: 24px;
`;

const StyledLayout = styled(Layout)`
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;
`;

const StyledMaterialIcons = styled(MaterialIcons)`
  margin-left: 8px;
  margin-right: 8px;
`;

const JobButtons: React.FC<JobButtonsProps> = ({
  onDetailsPress,
  onLocatePress,
  onNavigateSuccess,
}) => {
  const theme = useTheme();

  const [deviceType, setDeviceType] = useState<DeviceType>();
  const [navigateLoading, setNavigateLoading] = useState(false);
  const [noMessagesAlertVisible, setNoMessagesAlertVisible] = useState(false);

  useEffect(() => {
    (async () => {
      setDeviceType(await getDeviceTypeAsync());
    })();
  }, []);

  if (deviceType === DeviceType.PHONE) {
    return (
      <>
        <MobileButtonsRow>
          <View style={{ flex: 1 }}>
            <UiKittenTouchable onPress={onLocatePress}>
              <MobileButtonContainer>
                <MaterialIcons
                  name="my-location"
                  size={24}
                  color={theme["text-hint-color"]}
                />
                <Separator size="small" />
                <Text appearance="hint" category="c2">
                  Locate
                </Text>
              </MobileButtonContainer>
            </UiKittenTouchable>
          </View>
          <Separator horizontal size="small" />
          <View style={{ flex: 1 }}>
            <UiKittenTouchable
              onPress={async () => {
                setNavigateLoading(true);
                const location = await getLocation({
                  title: "Start commute failed",
                });
                await onNavigateSuccess(location);
                setNavigateLoading(false);
              }}
            >
              <MobileButtonContainer>
                {navigateLoading ? (
                  <Spinner size="medium" />
                ) : (
                  <StyledIcon
                    fill={theme["text-hint-color"]}
                    name="navigation"
                  />
                )}
                <Separator size="small" />
                <Text appearance="hint" category="c2">
                  Navigate
                </Text>
              </MobileButtonContainer>
            </UiKittenTouchable>
          </View>
          <Separator horizontal size="small" />
          <View style={{ flex: 1 }}>
            <UiKittenTouchable onPress={onDetailsPress}>
              <MobileButtonContainer>
                <StyledIcon fill={theme["text-hint-color"]} name="info" />
                <Separator size="small" />
                <Text appearance="hint" category="c2">
                  Details
                </Text>
              </MobileButtonContainer>
            </UiKittenTouchable>
          </View>
          <Separator horizontal size="small" />
          <View style={{ flex: 1 }}></View>
        </MobileButtonsRow>
        <AlertModal
          confirmText="Close"
          isVisible={noMessagesAlertVisible}
          message="No messages were sent"
          onClose={() => setNoMessagesAlertVisible(false)}
          onConfirm={() => setNoMessagesAlertVisible(false)}
        />
      </>
    );
  }
  return (
    <StyledLayout>
      <ListItem
        accessoryLeft={(imageProps) => (
          <StyledMaterialIcons
            color={imageProps.style.tintColor}
            name="my-location"
            size={24}
          />
        )}
        title="Locate"
      />
      <ListItem
        accessoryLeft={(imageProps) => (
          <Icon {...imageProps} name="navigation" />
        )}
        title="Navigate"
      />
      <ListItem
        accessoryLeft={(imageProps) => <Icon {...imageProps} name="info" />}
        title="Details"
      />
    </StyledLayout>
  );
};

export default JobButtons;
