import React, { useState } from "react";
import { useSelector } from "react-redux";
import AlertModal from "../../components/modals/Alert";
import selectUser from "../../store/auth/selectors/selectUser";
import selectUserById from "../../store/users/selectors/selectUserById";
import runClockIn from "../../utils/runClockIn";

const useClockInAlertModal = () => {
  const { id } = useSelector(selectUser);
  const user = useSelector((state) => selectUserById(state, id));

  const [alertModalVisible, setAlertModalVisible] = useState(false);

  const showClockInAlert = () => setAlertModalVisible(true);

  const ClockInAlertModal = (
    <AlertModal
      cancelText="Close"
      confirmAsync
      confirmText="Clock in"
      isVisible={alertModalVisible}
      message="You must be clocked in to continue"
      onCancel={() => setAlertModalVisible(false)}
      onClose={() => setAlertModalVisible(false)}
      onConfirm={async () => {
        await runClockIn({ user });
        setAlertModalVisible(false);
      }}
    />
  );

  return {
    ClockInAlertModal,
    showClockInAlert,
  };
};

export default useClockInAlertModal;
