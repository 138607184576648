import gql from "graphql-tag";

import { companyFragment } from "../../../fragments";

const createStripeAccount = gql`
  mutation createStripeAccount {
    createStripeAccount {
      ...companyFragment
    }
  }
  ${companyFragment}
`;

export default createStripeAccount;
