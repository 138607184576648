import { useEffect } from "react";
import { useSelector } from "react-redux";

import syncCompany from "../../api/functions/syncCompany";
import selectUser from "../../store/auth/selectors/selectUser";
import selectLastSyncDateTime from "../../store/data/selectors/selectLastSyncDateTime";

const usePeriodicPoll = () => {
  const user = useSelector(selectUser);
  const lastSyncDateTime = useSelector(selectLastSyncDateTime);
  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(async () => {
        try {
          await syncCompany();
        } catch (error) {
          // Do nothing
        }
      }, 300000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [lastSyncDateTime, user]);
};

export default usePeriodicPoll;
