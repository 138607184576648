import { Spinner } from "@ui-kitten/components";
import React, { memo } from "react";
import { ImageProps, ImageStyle, StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  spinnerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
});

/**
 * Use in UI Kitten components that have accessoryLeft and accessoryRight props.
 */
const SpinnerAccessory = ({ style }: ImageProps) => {
  if (style) {
    const { height, marginHorizontal, width } = style as ImageStyle;
    return (
      <View
        style={[
          styles.spinnerContainer,
          {
            height,
            marginHorizontal,
            width,
          },
        ]}
      >
        <Spinner size="small" status="basic" />
      </View>
    );
  }
  return null;
};

export default memo(SpinnerAccessory);
