import _ from "lodash/lang";
import PropTypes from "prop-types";
import { memo, useContext, useEffect } from "react";

import GoogleApi from "../map/GoogleApi";

const Polyline = memo(
  ({ coordinates, strokeColor, strokeWidth }) => {
    const googleApi = useContext(GoogleApi);
    const map = googleApi && googleApi.map;
    const maps = googleApi && googleApi.maps;
    useEffect(() => {
      let GoogleMapsPolyline;
      if (map && maps) {
        GoogleMapsPolyline = new maps.Polyline({
          path: coordinates.map((coordinate) => ({
            lat: coordinate.latitude,
            lng: coordinate.longitude,
          })),
          strokeColor,
          strokeWidth,
        });
        GoogleMapsPolyline.setMap(map);
      }
      return () => {
        if (GoogleMapsPolyline) {
          GoogleMapsPolyline.setMap(null);
        }
      };
    }, [coordinates, map, maps, strokeColor, strokeWidth]);
    return null;
  },
  (prevProps, nextProps) =>
    _.isEqual(
      {
        coordinates: prevProps.coordinates,
        strokeColor: prevProps.strokeColor,
        strokeWidth: prevProps.strokeWidth,
      },
      {
        coordinates: nextProps.coordinates,
        strokeColor: nextProps.strokeColor,
        strokeWidth: nextProps.strokeWidth,
      }
    )
);

Polyline.propTypes = {
  coordinates: PropTypes.arrayOf(
    PropTypes.shape({ latitude: PropTypes.number, longitude: PropTypes.number })
  ).isRequired,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};

Polyline.defaultProps = {
  strokeColor: undefined,
  strokeWidth: undefined,
};

export default Polyline;
