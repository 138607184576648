import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";
import mapToMediaInput from "../../utils/mapToMediaInput";

const dispatchOptimisticResponse = (
  {
    timestamp,
    startDateTime,
    endDateTime,
    summary,
    policeCase,
    subjects,
    vehicles,
    media,
  },
  incidentReport
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setReportPending({
      mutation: "adminUpdateIncidentReport",
      report: {
        ...incidentReport,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        startDateTime,
        endDateTime,
        summary,
        policeCase,
        subjects,
        vehicles,
        media,
      },
    })
  );
};

const adminUpdateIncidentReport = (
  {
    timestamp = new Date().toISOString(),
    startDateTime,
    endDateTime,
    summary,
    policeCase,
    subjects,
    vehicles,
    media,
  },
  incidentReport
) => {
  const mediaInput = media.map(mapToMediaInput);
  dispatchOptimisticResponse(
    {
      timestamp,
      startDateTime,
      endDateTime,
      summary,
      policeCase,
      subjects,
      vehicles,
      media: mediaInput,
    },
    incidentReport
  );
  client.mutate({
    mutation,
    variables: {
      input: deepCopy({
        id: incidentReport.id,
        jobId: incidentReport.jobId,
        timestamp,
        startDateTime,
        endDateTime,
        summary,
        policeCase,
        subjects,
        vehicles,
        media: mediaInput,
      }),
    },
    errorPolicy: "all",
  });
};

export default adminUpdateIncidentReport;
