import { useEffect, useState } from "react";
import { Keyboard, Platform } from "react-native";

const useKeyboardVisible = () => {
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  useEffect(
    () => {
      const keyboardDidShowListener = Keyboard.addListener(
        Platform.OS === "ios" ? "keyboardWillShow" : "keyboardDidShow",
        () => setKeyboardVisible(true)
      );
      const keyboardDidHideListener = Keyboard.addListener(
        Platform.OS === "ios" ? "keyboardWillHide" : "keyboardDidHide",
        () => setKeyboardVisible(false)
      );
      return () => {
        keyboardDidShowListener.remove();
        keyboardDidHideListener.remove();
      };
    },
    [] // Height should only be defined once
  );
  return keyboardVisible;
};

export default useKeyboardVisible;
