import * as Updates from "expo-updates";
import { useEffect } from "react";
import { Alert } from "react-native";

const useOtaUpdates = () => {
  useEffect(() => {
    const eventSubscription = Updates.addListener((event) => {
      if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
        Alert.alert(
          "A new version is available. Restart the app now to update!",
          "If do not want to restart now don't worry. The update will automatically be applied the next time the app is opened.",
          [
            { style: "cancel", text: "Dismiss" },
            { onPress: Updates.reloadAsync, text: "Restart" },
          ],
          { cancelable: false }
        );
      }
    });
    return () => {
      eventSubscription.remove();
    };
  }, []);
};

export default useOtaUpdates;
