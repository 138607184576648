import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedQuote } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdateQuoteInput
  extends Pick<
    Stripe.QuoteUpdateParams,
    "customer" | "default_tax_rates" | "line_items"
  > {
  id: string;
}

const updateQuote = async ({
  customer,
  default_tax_rates,
  id,
  line_items,
}: UpdateQuoteInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        customer,
        default_tax_rates,
        id,
        line_items,
      },
    })) as GraphQLResult<{ updateQuote: StripeExpandedQuote }>;
    return data.updateQuote;
  } catch ({ errors }) {
    throw generateErrorMessage("updateQuote", errors);
  }
};

export default updateQuote;
