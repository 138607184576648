import {
  Icon,
  ListItem,
  ListItemProps,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";
import UiKittenTouchable from "../../UiKittenTouchable";

const styles = StyleSheet.create({
  root: {
    borderRadius: 8,
    minHeight: 48,
    overflow: "hidden",
    color: "red",
  },
});

interface ButtonListItemProps extends ListItemProps {
  title: string;
  icon: string;
}

const ButtonListItem = ({
  style,
  title,
  icon,
  onPress,
  disabled,
  ...props
}: ButtonListItemProps) => {
  const theme = useTheme();
  const primaryColor = theme["color-primary-default"];
  const disabledColor = theme["color-primary-disabled"];
  const textColor = disabled ? disabledColor : primaryColor;
  return (
    <UiKittenTouchable onPress={onPress} disabled={disabled}>
      <ListItem
        {...props}
        accessoryLeft={(accessoryLeftProps) => (
          <Icon {...accessoryLeftProps} fill={textColor} name={icon} />
        )}
        title={(textProps) => (
          <Text {...textProps} style={[textProps.style, { color: textColor }]}>
            {title}
          </Text>
        )}
        style={[style, styles.root]}
      />
    </UiKittenTouchable>
  );
};

export default ButtonListItem;
