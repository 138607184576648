import gql from "graphql-tag";

import { stripeExpandedQuoteFragment } from "../../../fragments";

const updateQuote = gql`
  mutation updateQuote(
    $customer: ID
    $default_tax_rates: [ID]
    $id: ID!
    $line_items: [StripeLineItemInput]
  ) {
    updateQuote(
      customer: $customer
      default_tax_rates: $default_tax_rates
      id: $id
      line_items: $line_items
    ) {
      ...stripeExpandedQuoteFragment
    }
  }
  ${stripeExpandedQuoteFragment}
`;

export default updateQuote;
