import { ListItemProps, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";
import { Customer } from "../../../types";
import Flair from "../../Flair";
import BaseListItem from "../Base";

interface CustomerListItemProps
  extends Pick<
    ListItemProps,
    "accessoryLeft" | "accessoryRight" | "disabled" | "style"
  > {
  customerId: string;
  onPress?: (customer: Customer) => void;
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const CustomerListItem = ({
  accessoryLeft,
  accessoryRight,
  customerId,
  disabled,
  onPress,
  style,
}: CustomerListItemProps) => {
  const customer = useSelector((state) =>
    selectCustomerById(state, customerId)
  );
  return (
    <BaseListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      disabled={disabled}
      onPress={onPress && (() => onPress(customer))}
      style={style}
      title={() => (
        <View style={styles.titleContainer}>
          {customer.name.split(" ").map((currentValue, index, array) => (
            <Text
              category="s2"
              // eslint-disable-next-line react/no-array-index-key
              key={`${currentValue}:${index}`}
              style={index === array.length - 1 && { paddingEnd: 8 }}
            >{`${currentValue}${index !== array.length - 1 ? " " : ""}`}</Text>
          ))}
          {customer.isArchived && <Flair status="danger">archived</Flair>}
        </View>
      )}
    />
  );
};

export default CustomerListItem;
