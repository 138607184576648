import { CheckBox, Text } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";

import Button from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";
import SimpleModal from "../Simple";

const StyledCheckBox = styled(CheckBox)`
  margin-left: 16px;
  margin-right: 16px;
`;

const styles = StyleSheet.create({
  content: {
    marginBottom: 26,
    marginTop: 20,
  },
  footer: {
    paddingBottom: 16,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  message: {
    marginEnd: 16,
    marginStart: 16,
  },
});

export interface AlertModalProps {
  cancelText?: string;
  confirmAsync?: boolean;
  confirmText?: string;
  doNotAskAgainText?: string;
  isVisible: boolean;
  message: string;
  onCancel?: (doNotAskAgain: boolean) => void;
  onClose: () => void;
  onConfirm?: (doNotAskAgain: boolean) => void;
  showDoNotAskAgain?: boolean;
}

const AlertModal = ({
  cancelText = "Cancel",
  confirmAsync = false,
  confirmText = "Confirm",
  doNotAskAgainText = "Don't show this again",
  isVisible,
  message,
  onCancel,
  onClose,
  onConfirm,
  showDoNotAskAgain = false,
}: AlertModalProps) => {
  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const [confirmPending, setConfirmPending] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setDoNotAskAgain(false);
    }
  }, [isVisible]);

  const showFooter = Boolean(onConfirm) || Boolean(onCancel);
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackPress={onClose}
    >
      <View onStartShouldSetResponder={() => true}>
        <View style={styles.content}>
          <Text style={styles.message}>{message}</Text>
        </View>
        {showDoNotAskAgain && (
          <>
            <StyledCheckBox
              checked={doNotAskAgain}
              onChange={() => setDoNotAskAgain(!doNotAskAgain)}
            >
              {doNotAskAgainText}
            </StyledCheckBox>
            <Separator size={20} />
          </>
        )}
        {showFooter && (
          <View style={styles.footer}>
            {onConfirm && (
              <Button
                data-test="confirmButtonInAlertModal"
                loading={confirmPending}
                onPress={
                  confirmAsync
                    ? async () => {
                        setConfirmPending(true);
                        await onConfirm(doNotAskAgain);
                        setConfirmPending(false);
                      }
                    : () => onConfirm(doNotAskAgain)
                }
              >
                {confirmText}
              </Button>
            )}
            {Boolean(onConfirm) && Boolean(onCancel) && (
              <View style={styles.footerSeparator} />
            )}
            {onCancel && (
              <Button
                data-test="cancelButtonInAlertModal"
                onPress={() => onCancel(doNotAskAgain)}
                appearance="ghost"
              >
                {cancelText}
              </Button>
            )}
          </View>
        )}
      </View>
    </SimpleModal>
  );
};

export default React.memo(AlertModal);
