import gql from "graphql-tag";

import { companyGroupFragment } from "../../../fragments";

export default gql`
  mutation adminCreateCompanyGroup($name: String!, $id: ID!, $members: [ID!]!) {
    adminCreateCompanyGroup(name: $name, id: $id, members: $members) {
      ...companyGroupFragment
    }
  }
  ${companyGroupFragment}
`;
