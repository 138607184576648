import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";

import appsyncClient from "../../api/client";
import logoutMutation from "../../api/functions/logout";
import { getToken } from "../../notifications";
import store from "../../store";
import { resetUser } from "../../store/auth/slice";

const timeout = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

const logout = async () => {
  const token = await getToken();
  try {
    await logoutMutation(token);
  } catch (error) {
    // Do nothing. We try to set them offline if we're able to but the worst case scenario is that
    // other users will see them for another 40 minutes.
  }
  store.dispatch(resetUser());
  // Intentional delay added after running Cognito sign out because 'updateCompanyUserStatus' can also be invoked by the
  // location tracker which asynchronously turns on/off via a useEffect that checks for a signed in user.
  // It's technically possible for a location update to occur (which would then call 'updateCompanyUserStatus')
  // immediately after the user has been signed out.
  await timeout(1000);
  // This clears out the offline mutations in the appsync client.
  appsyncClient.cache.store.dispatch({ type: RESET_STATE });
};

export default logout;
