import Auth from "@aws-amplify/auth";

import roles from "../../constants/companyUserRoles";

export const getRole = (groups) => {
  // Don't change order!
  if (groups.includes(roles.EMPLOYEE)) return roles.EMPLOYEE;
  if (groups.includes(roles.SUPERVISOR)) return roles.SUPERVISOR;
  if (groups.includes(roles.ADMIN)) return roles.ADMIN;
  throw new Error("Role not defined");
};

export default async () => {
  const user = await Auth.currentAuthenticatedUser();

  // The array of groups that the user belongs to
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

  return getRole(groups);
};
