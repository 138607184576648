import { Divider, Layout } from "@ui-kitten/components";
import React from "react";
import { FlatList } from "react-native";

import DefaultFullScreenModal from "../DefaultFullScreen";
import SiteListItem from "../../listItems/Site";

interface Props {
  ids: Array<string>;
  isVisible: boolean;
  onClose: () => void;
  onSelect: (siteId: string) => void;
}

const SitePickerFromIdsModal = ({
                                  isVisible,
                                  onClose,
                                  ids,
                                  onSelect,
                                }: Props) => {
  return (
    <DefaultFullScreenModal
      leftControlIcon="close-outline"
      onClose={onClose}
      isVisible={isVisible}
      title="Multiple locations"
      subtitle="Select a location to continue"
      scrollable={false}
    >
      <Layout level="2" style={{ flex: 1 }}>
        <FlatList
          ItemSeparatorComponent={Divider}
          keyExtractor={(item) => item}
          data={ids}
          renderItem={({ item }) => (
            <SiteListItem siteId={item} onPress={() => onSelect(item)} />
          )}
        />
      </Layout>
    </DefaultFullScreenModal>
  );
};

export default SitePickerFromIdsModal;
