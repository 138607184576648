import { createSelector } from "@reduxjs/toolkit";

import selectCustomerEntities from "../selectCustomerEntities";

const selectCustomerEmails = createSelector(
  [selectCustomerEntities],
  (customersById): Array<string> => {
    const customers = Object.values(customersById);
    const emailSet: Set<string> = new Set();
    customers.forEach((customer) => {
      customer.emails.forEach(({ email }) => emailSet.add(email));
    });
    return Array.from(emailSet);
  }
);

export default selectCustomerEmails;
