import { Icon, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import CompactLabel from "../CompactLabel";
import selectJobById from "../../store/jobs/selectors/selectJobById";
import finishedJobStatuses from "../../constants/finishedJobStatuses";
import jobStatuses from "../../constants/jobStatuses";
import Separator from "../buildingBlocks/Separator";

interface Props {
  jobId: string;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  ghostLabel: {
    borderColor: "transparent",
    borderRadius: 4,
    borderWidth: 1,
    paddingEnd: 4,
    paddingStart: 4,
    textAlign: "center",
  },
  root: {
    alignItems: "center",
    flexDirection: "row",
  },
});

const JobStatusCompactLabels = ({ jobId, style }: Props) => {
  const theme = useTheme();

  const job = useSelector((state) => selectJobById(state, jobId));

  const renderLabel = () => {
    if (job.finished) {
      if (job.finished.status === finishedJobStatuses.completed.value) {
        return (
          <CompactLabel status="success">
            {finishedJobStatuses.completed.text}
          </CompactLabel>
        );
      }
      if (job.finished.status === finishedJobStatuses.dismissed.value) {
        return (
          <Text
            category="label"
            style={[
              styles.ghostLabel,
              {
                backgroundColor: theme["color-basic-transparent-default"],
                borderColor: theme["color-basic-600"],
                color: theme["color-basic-600"],
              },
            ]}
          >
            {finishedJobStatuses.dismissed.text}
          </Text>
        );
      }
      throw new Error(`Job status ${job.finished.status} is not valid`);
    } else {
      let status;
      let text;
      switch (job.status.id) {
        case jobStatuses.future.id:
          status = "info";
          text = jobStatuses.future.description;
          break;
        case jobStatuses.inProgress.id:
          status = "primary";
          text = jobStatuses.inProgress.description;
          break;
        case jobStatuses.late.id:
          status = "warning";
          text = jobStatuses.late.description;
          break;
        case jobStatuses.immediate.id:
          status = "danger";
          text = jobStatuses.immediate.description;
          break;
        default:
          throw new Error(`Job status ${job.status.id} is not valid`);
      }
      return <CompactLabel status={status}>{text}</CompactLabel>;
    }
  };
  return (
    <View style={[styles.root, style]}>
      {renderLabel()}
      {job.immediate &&
        (job.finished || job.status.id === jobStatuses.inProgress.id) && (
          <>
            <Separator horizontal size="small" />
            <Text category="label" status="danger" style={styles.ghostLabel}>
              {jobStatuses.immediate.description}
            </Text>
          </>
        )}
      {job.finished && job.processed && (
        <>
          <Separator horizontal size="small" />
          <Text category="label" status="primary" style={styles.ghostLabel}>
            Processed
          </Text>
        </>
      )}
    </View>
  );
};

JobStatusCompactLabels.defaultProps = {
  style: undefined,
};

export default JobStatusCompactLabels;
