import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import completeInboxItem from "../../api/functions/completeInboxItem";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import EditReminderModal from "../../components/modals/EditReminder";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCustomerById from "../../store/customers/selectors/selectCustomerById";
import selectInboxItemById from "../../store/inbox/selectors/selectInboxItemById";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectUserById from "../../store/users/selectors/selectUserById";
import { Reminder, StackParamList } from "../../types";
import getUserFullName from "../../utils/getUserFullName";

const IconTextContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledIcon = styled(Icon)`
  height: 24px;
  width: 24px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const ReminderDetails = () => {
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "ReminderDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "ReminderDetails">>();
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const { id } = useSelector(selectUser);

  const { inboxItemId } = route.params;

  const reminder = useSelector((state) =>
    selectInboxItemById(state, inboxItemId)
  ) as Reminder;

  const customer = useSelector((state) =>
    selectCustomerById(state, reminder.metadata.customerId)
  );
  const user = useSelector((state) =>
    selectUserById(state, reminder.metadata.userId)
  );

  const [editReminderModalVisible, setEditReminderModalVisible] = useState(
    false
  );

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            action={{
              onPress: () => setEditReminderModalVisible(true),
              text: "Edit",
            }}
            navigation={{
              icon: "arrow-back",
              onPress: navigation.goBack,
            }}
            title="Reminder details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              <Separator size="medium" />
              <StyledCard>
                <Separator size="medium" />
                <StyledText category="h6">{reminder.title}</StyledText>
                <Separator size="small" />
                <IconTextContainer>
                  <StyledIcon
                    name="calendar-outline"
                    style={{
                      tintColor: theme["text-basic-color"],
                    }}
                  />
                  <Separator horizontal size="small" />
                  <Text category="s1">
                    {dayjs(reminder.metadata.dateTime).format(dateTimeFormat)}
                  </Text>
                </IconTextContainer>
                <Separator size="small" />
                <IconTextContainer>
                  <StyledIcon
                    name="person-outline"
                    style={{
                      tintColor: theme["text-basic-color"],
                    }}
                  />
                  <Separator horizontal size="small" />
                  <Text category="s1">{customer.name}</Text>
                </IconTextContainer>
                <Separator size="medium" />
                <StyledDivider />
                <Separator size="medium" />
                <StyledButton
                  onPress={() => {
                    completeInboxItem(
                      { id: reminder.id, userId: id },
                      reminder
                    );
                    navigation.goBack();
                  }}
                >
                  Mark complete
                </StyledButton>
                <Separator size="medium" />
              </StyledCard>
              <Separator />
              <StyledCard title="Additional information">
                <StyledText appearance="hint" category="c1">
                  Assigned to
                </StyledText>
                <StyledText>{getUserFullName(user)}</StyledText>
                {Boolean(reminder.description) && (
                  <>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      Description
                    </StyledText>
                    <StyledText>{reminder.description}</StyledText>
                  </>
                )}
                <Separator size="medium" />
              </StyledCard>
              <Separator size={48} />
              <Separator />
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
      <EditReminderModal
        isVisible={editReminderModalVisible}
        onClose={() => setEditReminderModalVisible(false)}
        reminder={reminder}
      />
    </>
  );
};

export default ReminderDetails;
