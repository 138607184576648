import { ListItemProps, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import BaseListItem from "../Base";
import Flair from "../../Flair";
import selectById from "../../../store/sites/selectors/selectById";

interface Props
  extends Pick<
    ListItemProps,
    "accessoryLeft" | "accessoryRight" | "disabled" | "onPress"
  > {
  siteId: string;
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const SiteListItem = ({
  accessoryLeft,
  accessoryRight,
  disabled,
  onPress,
  siteId,
}: Props) => {
  const site = useSelector((state) => selectById(state, siteId));
  return (
    <BaseListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      description={site.address}
      disabled={disabled}
      onPress={onPress}
      title={() => (
        <View style={styles.titleContainer}>
          {site.name.split(" ").map((currentValue, index, array) => (
            <Text
              category="s2"
              // eslint-disable-next-line react/no-array-index-key
              key={`${currentValue}:${index}`}
              style={index === array.length - 1 && { paddingEnd: 8 }}
            >{`${currentValue}${index !== array.length - 1 ? " " : ""}`}</Text>
          ))}
          {site.isArchived && <Flair status="danger">archived</Flair>}
        </View>
      )}
    />
  );
};

export default SiteListItem;
