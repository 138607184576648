import {
  CheckBox,
  Divider,
  Icon,
  Layout,
  Spinner,
  Text,
} from "@ui-kitten/components";
import { Map } from "immutable";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";

import createInvoiceItem, {
  CreateInvoiceItemInput,
} from "../../../api/functions/createInvoiceItem";
import deleteInvoiceItem from "../../../api/functions/deleteInvoiceItem";
import finalizeInvoice from "../../../api/functions/finalizeInvoice";
import listInvoiceItems from "../../../api/functions/listInvoiceItems";
import listTaxRates from "../../../api/functions/listTaxRates";
import retrieveInvoice from "../../../api/functions/retrieveInvoice";
import sendInvoice from "../../../api/functions/sendInvoice";
import updateInvoice, {
  UpdateInvoiceInput,
} from "../../../api/functions/updateInvoice";
import updateInvoiceItem, {
  UpdateInvoiceItemInput,
} from "../../../api/functions/updateInvoiceItem";
import {
  PostmarkMessageSendingResponse,
  StripeExpandedInvoice,
  StripeExpandedInvoiceItem,
  StripeTaxRate,
} from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import { addNotification } from "../../InAppNotifications";
import InvoiceLineItemListItem from "../../listItems/InvoiceLineItem";
import StripeCustomerListItem from "../../listItems/StripeCustomer";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import PostmarkResponsesModal from "../PostmarkResponsesModal";
import StripeLineItemEditingModal from "../StripeLineItemEditing";
import TaxRateSelectModal from "../TaxRateSelect";

interface InvoiceEditingModalProps
  extends Pick<FullScreenModalProps, "isVisible"> {
  invoice: StripeExpandedInvoice;
  onClose: (stale?: boolean) => void;
  onSendInvoiceOnFinalizeChange: (checked: boolean) => void;
  sendInvoiceOnFinalize: boolean;
}

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledStripeCustomerListItem = styled(StripeCustomerListItem)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const SummaryRow = styled.View`
  flex-direction: row;
`;

const SummaryRowNameContainer = styled.View`
  align-items: flex-end;
  flex: 2;
`;

const SummaryRowValueContainer = styled.View`
  align-items: flex-end;
  flex: 1;
`;

const InvoiceEditingModal = ({
  invoice,
  isVisible,
  onClose,
  onSendInvoiceOnFinalizeChange,
  sendInvoiceOnFinalize,
}: InvoiceEditingModalProps) => {
  const [sessionInvoice, setSessionInvoice] = useState<StripeExpandedInvoice>(
    invoice
  );
  const [autoSavePending, setAutoSavePending] = useState(false);

  const [loading, setLoading] = useState(false);
  const [zeroTaxRateId, setZeroTaxRateId] = useState<string>();

  const [finalizeInvoicePending, setFinalizeInvoicePending] = useState(false);
  const [sendInvoicePending, setSendInvoicePending] = useState(false);

  const [stripeLineItemEditingModal, setStripeLineItemEditingModal] = useState<{
    isVisible: boolean;
    stripeLineItem?: StripeExpandedInvoiceItem;
    stripeLineItemIndex?: number;
  }>({
    isVisible: false,
  });
  const [taxRateSelectModalVisible, setTaxRateSelectModalVisible] = useState(
    false
  );
  const [postmarkModal, setPostmarkModal] = useState<{
    isVisible: boolean;
    responses: Array<PostmarkMessageSendingResponse>;
  }>({
    isVisible: false,
    responses: [],
  });

  const [invoiceStale, setInvoiceStale] = useState(false);

  const runListTaxRates = useCallback(async () => {
    try {
      let data: Array<StripeTaxRate>;
      let hasMore: boolean;
      do {
        const listTaxRatesResult = await listTaxRates({
          starting_after: data && data[data.length - 1].id,
        });
        data = data
          ? data.concat(listTaxRatesResult.data)
          : listTaxRatesResult.data;
        hasMore = listTaxRatesResult.has_more;
      } while (hasMore);
      setZeroTaxRateId(
        data[data.findIndex((taxRate) => taxRate.percentage === 0)].id
      );
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List tax rates failed",
      });
    }
  }, []);
  const runRetrieveInvoice = useCallback(async () => {
    try {
      let retrievedInvoice = await retrieveInvoice(invoice.id);
      while (retrievedInvoice.lines.has_more) {
        const invoiceLines = await listInvoiceItems({
          invoice: invoice.id,
          starting_after:
            retrievedInvoice.lines.data[retrievedInvoice.lines.data.length - 1]
              .id,
        });
        retrievedInvoice = Map(retrievedInvoice)
          .set("lines", {
            data: retrievedInvoice.lines.data.concat(invoiceLines.data),
            has_more: invoiceLines.has_more,
          })
          .toJS();
      }
      setSessionInvoice(retrievedInvoice);
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve invoice failed",
      });
    }
  }, [invoice.id]);
  const runCreateInvoiceItem = useCallback(
    async ({
      onSuccess,
      createInvoiceItemInput,
    }: {
      onSuccess: () => void;
      createInvoiceItemInput: CreateInvoiceItemInput;
    }) => {
      setAutoSavePending(true);
      try {
        await createInvoiceItem(createInvoiceItemInput);
        await runRetrieveInvoice();
        setInvoiceStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Create invoice item failed",
        });
      }
      setAutoSavePending(false);
    },
    [runRetrieveInvoice]
  );
  const runUpdateInvoiceItem = useCallback(
    async ({
      onSuccess,
      updateInvoiceItemInput,
    }: {
      onSuccess: () => void;
      updateInvoiceItemInput: UpdateInvoiceItemInput;
    }) => {
      setAutoSavePending(true);
      try {
        await updateInvoiceItem(updateInvoiceItemInput);
        await runRetrieveInvoice();
        setInvoiceStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Update invoice item failed",
        });
      }
      setAutoSavePending(false);
    },
    []
  );
  const runDeleteInvoiceItem = useCallback(
    async ({ id, onSuccess }: { id: string; onSuccess: () => void }) => {
      setAutoSavePending(true);
      try {
        await deleteInvoiceItem(id);
        await runRetrieveInvoice();
        setInvoiceStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Delete invoice item failed",
        });
      }
      setAutoSavePending(false);
    },
    []
  );
  const runUpdateInvoice = useCallback(
    async ({
      onSuccess,
      updateInvoiceInput,
    }: {
      onSuccess: () => void;
      updateInvoiceInput: UpdateInvoiceInput;
    }) => {
      setAutoSavePending(true);
      try {
        await updateInvoice(updateInvoiceInput);
        await runRetrieveInvoice();
        setInvoiceStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Update invoice failed",
        });
      }
      setAutoSavePending(false);
    },
    []
  );
  const runSendInvoice = useCallback(async () => {
    setSendInvoicePending(true);
    setPostmarkModal({
      isVisible: true,
      responses: [],
    });
    try {
      const responses = await sendInvoice({
        invoiceId: invoice.id,
      });
      setPostmarkModal((prevState) => ({
        ...prevState,
        responses,
      }));
    } catch (error) {
      addNotification({
        message: error.message,
        title: "Send invoice failed",
      });
    }
    setSendInvoicePending(false);
  }, [invoice]);

  useEffect(() => {
    if (isVisible && !zeroTaxRateId) {
      (async () => {
        setLoading(true);
        await runListTaxRates();
        setLoading(false);
      })();
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible) {
      setSessionInvoice(invoice);
      setInvoiceStale(false);
    }
  }, [isVisible]);

  const selectedPriceIds =
    invoice?.lines.data.map((lineItem) => lineItem.price.id) || [];

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Layout level="2" style={{ flex: 1 }}>
          <Header
            navigation={{ icon: "close", onPress: () => onClose(invoiceStale) }}
            title="Edit invoice"
          />
          <Divider />
          {zeroTaxRateId ? (
            <>
              <ScrollView>
                <Separator size="medium" />
                <StyledText category="h6">Customer</StyledText>
                <Separator size="medium" />
                <StyledStripeCustomerListItem
                  disabled
                  stripeCustomer={sessionInvoice.customer}
                />
                <Separator />
                <StyledText category="h6">Items</StyledText>
                {sessionInvoice?.lines.data.length > 0 && (
                  <>
                    <Separator size="medium" />
                    {sessionInvoice.lines.data.map((lineItem, index) => (
                      <Fragment key={lineItem.price.id}>
                        {index > 0 && <Separator size="small" />}
                        <Layout
                          style={{
                            borderRadius: 8,
                            marginEnd: 16,
                            marginStart: 16,
                          }}
                        >
                          <InvoiceLineItemListItem
                            accessoryRight={(imageProps) => (
                              <Icon {...imageProps} name="arrow-ios-forward" />
                            )}
                            lineItem={lineItem}
                            onPress={() =>
                              setStripeLineItemEditingModal({
                                isVisible: true,
                                stripeLineItem: lineItem,
                                stripeLineItemIndex: index,
                              })
                            }
                          />
                        </Layout>
                      </Fragment>
                    ))}
                  </>
                )}
                <Separator size="medium" />
                <StyledButton
                  onPress={() =>
                    setStripeLineItemEditingModal({
                      isVisible: true,
                    })
                  }
                  status="basic"
                >
                  Add product
                </StyledButton>
                <Separator />
                <StyledText category="h6">Taxes</StyledText>
                {sessionInvoice?.default_tax_rates.length > 0 && (
                  <>
                    <Separator size="medium" />
                    {sessionInvoice.default_tax_rates.map((taxRate, index) => (
                      <Fragment key={JSON.stringify(taxRate)}>
                        {index > 0 && <Separator size="small" />}
                        <StyledCard>
                          <Separator size="medium" />
                          <SummaryRow>
                            <SummaryRowNameContainer
                              style={{ alignItems: "flex-start" }}
                            >
                              <StyledText>{taxRate.display_name}</StyledText>
                            </SummaryRowNameContainer>
                            <SummaryRowValueContainer>
                              <StyledText>{`${taxRate.percentage}%`}</StyledText>
                            </SummaryRowValueContainer>
                          </SummaryRow>
                          <Separator size="medium" />
                        </StyledCard>
                      </Fragment>
                    ))}
                  </>
                )}
                <Separator size="medium" />
                <StyledButton
                  onPress={() => setTaxRateSelectModalVisible(true)}
                  status="basic"
                >
                  {`${
                    Array.isArray(sessionInvoice?.default_tax_rates) &&
                    sessionInvoice.default_tax_rates.length > 0
                      ? "Update"
                      : "Add"
                  } tax`}
                </StyledButton>
                <Separator />
                <SummaryRow>
                  <SummaryRowNameContainer>
                    <StyledText category="s1">Subtotal</StyledText>
                  </SummaryRowNameContainer>
                  <SummaryRowValueContainer>
                    <StyledText category="s1">
                      {formatCurrency(sessionInvoice.subtotal)}
                    </StyledText>
                  </SummaryRowValueContainer>
                </SummaryRow>
                {sessionInvoice.total_tax_amounts.map((tax) => (
                  <SummaryRow key={JSON.stringify(tax)}>
                    <SummaryRowNameContainer>
                      <StyledText>{`${tax.tax_rate.display_name} (${tax.tax_rate.percentage}%)`}</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText>{formatCurrency(tax.amount)}</StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                ))}
                <SummaryRow>
                  <SummaryRowNameContainer>
                    <StyledText category="s1">Total</StyledText>
                  </SummaryRowNameContainer>
                  <SummaryRowValueContainer>
                    <StyledText category="s1">
                      {formatCurrency(sessionInvoice.total)}
                    </StyledText>
                  </SummaryRowValueContainer>
                </SummaryRow>
                <Separator />
              </ScrollView>
              <Divider />
              <Layout>
                <SafeAreaView>
                  <SubmitButtonContainer>
                    <CheckBox
                      checked={sendInvoiceOnFinalize}
                      disabled={finalizeInvoicePending}
                      onChange={onSendInvoiceOnFinalizeChange}
                    >
                      Email invoice to customer
                    </CheckBox>
                    <Separator size="medium" />
                    <Button
                      disabled={!sessionInvoice}
                      loading={autoSavePending || finalizeInvoicePending}
                      onPress={async () => {
                        setFinalizeInvoicePending(true);
                        try {
                          setSessionInvoice(
                            await finalizeInvoice(sessionInvoice.id)
                          );
                          onClose(true);
                          if (sendInvoiceOnFinalize) {
                            runSendInvoice();
                          }
                        } catch (error) {
                          addNotification({
                            message: error?.message || error,
                            status: "danger",
                            title: "Finalize invoice failed",
                          });
                        }
                        setFinalizeInvoicePending(false);
                      }}
                    >
                      {autoSavePending ? "Saving draft..." : "Finalize invoice"}
                    </Button>
                  </SubmitButtonContainer>
                </SafeAreaView>
              </Layout>
            </>
          ) : (
            <EmptyScreenContainer>
              <Separator />
              {loading ? (
                <Spinner />
              ) : (
                <Text appearance="hint" category="s1">
                  No results found
                </Text>
              )}
            </EmptyScreenContainer>
          )}
        </Layout>
      </FullScreenModal>
      <StripeLineItemEditingModal
        isVisible={stripeLineItemEditingModal.isVisible}
        lineItem={stripeLineItemEditingModal.stripeLineItem}
        onClose={() =>
          setStripeLineItemEditingModal((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        onDelete={async (invoiceItem) => {
          await runDeleteInvoiceItem({
            id: invoiceItem.id,
            onSuccess: () =>
              setStripeLineItemEditingModal((prevState) => ({
                ...prevState,
                isVisible: false,
              })),
          });
        }}
        onSubmit={async (invoiceItem) => {
          if (invoiceItem.id) {
            await runUpdateInvoiceItem({
              onSuccess: () =>
                setStripeLineItemEditingModal((prevState) => ({
                  ...prevState,
                  isVisible: false,
                })),
              updateInvoiceItemInput: {
                id: invoiceItem.id,
                quantity: invoiceItem.quantity,
                tax_rates:
                  invoiceItem.price.metadata.taxesEnabled === "false"
                    ? [zeroTaxRateId]
                    : [],
              },
            });
          } else {
            await runCreateInvoiceItem({
              createInvoiceItemInput: {
                customer: invoice.customer.id,
                invoice: invoice.id,
                price: invoiceItem.price.id,
                quantity: invoiceItem.quantity,
                tax_rates:
                  invoiceItem.price.metadata.taxesEnabled === "false"
                    ? [zeroTaxRateId]
                    : [],
              },
              onSuccess: () =>
                setStripeLineItemEditingModal((prevState) => ({
                  ...prevState,
                  isVisible: false,
                })),
            });
          }
        }}
        pricesFilter={(price) => !selectedPriceIds.includes(price.id)}
      />
      <TaxRateSelectModal
        headerTitle={`${
          sessionInvoice?.default_tax_rates.length > 0 ? "Update" : "Add"
        } tax`}
        isVisible={taxRateSelectModalVisible}
        onClose={() => setTaxRateSelectModalVisible(false)}
        onSubmit={async (taxRates) => {
          const defaultTaxRates = taxRates.map((taxRate) => taxRate.id);
          await runUpdateInvoice({
            onSuccess: () => setTaxRateSelectModalVisible(false),
            updateInvoiceInput: {
              default_tax_rates: defaultTaxRates,
              id: sessionInvoice.id,
            },
          });
        }}
        selected={sessionInvoice?.default_tax_rates}
      />
      <PostmarkResponsesModal
        isVisible={postmarkModal.isVisible}
        loading={sendInvoicePending}
        onClose={() =>
          setPostmarkModal((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        postmarkMessageSendingResponses={postmarkModal.responses}
      />
    </>
  );
};

export default InvoiceEditingModal;
