import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectJobEntities";

const selectJobById = createSelector(
  [selectEntities, (_, id: string) => id],
  (entities, id) => entities[id]
);

export default selectJobById;
