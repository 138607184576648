import React, { memo } from "react";
import { StyleSheet, View } from "react-native";

const styles = {
  horizontal: StyleSheet.create({
    small: {
      width: 8,
    },
    medium: {
      width: 16,
    },
    large: {
      width: 24,
    },
  }),
  vertical: StyleSheet.create({
    small: {
      height: 8,
    },
    medium: {
      height: 16,
    },
    large: {
      height: 24,
    },
  }),
};

type SeparatorSize = "large" | "medium" | "small";

type SeparatorProps = {
  horizontal?: boolean;
  size?: SeparatorSize | number;
};

const Separator = ({ horizontal, size }: SeparatorProps) => {
  let style;
  if (typeof size === "string") {
    const orientation = horizontal ? "horizontal" : "vertical";
    style = styles[orientation][size];
  } else {
    style = {
      height: !horizontal ? size : undefined,
      width: horizontal ? size : undefined,
    };
  }
  return <View style={style} />;
};

Separator.defaultProps = {
  horizontal: false,
  size: "large",
};

export default memo(Separator);
