import { createSelector } from "@reduxjs/toolkit";

import selectSites from "../selectSites";

const selectCustomerSiteIds = createSelector(
  [selectSites, (_, customerId) => customerId],
  (sites, customerId) =>
    sites.ids.filter(
      (siteId) => sites.entities[siteId].customerId === customerId
    )
);

export default selectCustomerSiteIds;
