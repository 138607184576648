import { Layout, Text } from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Container from "../Container";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import logout from "../../utils/logout";
import { addNotification } from "../InAppNotifications";

const styles = StyleSheet.create({
  button: {
    alignSelf: "flex-start",
    marginStart: 16,
  },
  root: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const BillingGateKeeper = () => {
  const company = useSelector(selectCompany);
  const signedInUser = useSelector(selectUser);
  const [logoutPending, setLogoutPending] = useState(false);
  const componentMounted = useRef<boolean>();
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);
  if (!signedInUser || !company) {
    return null;
  }
  const runLogout = async () => {
    setLogoutPending(true);
    try {
      await logout();
    } catch (error) {
      addNotification({ message: error.message, status: "danger" });
    }
    if (componentMounted.current) {
      setLogoutPending(false);
    }
  };
  if (
    company &&
    company.billing &&
    (company.billing.status === "canceled" ||
      company.billing.status === "incomplete_expired")
  ) {
    return (
      <Layout style={styles.root}>
        <ScrollView>
          <Container>
            <Separator />
            <Text category="h6" style={styles.text}>
              Talarium subscription canceled.
            </Text>
            <Separator />
            <Button
              loading={logoutPending}
              onPress={runLogout}
              size="small"
              style={styles.button}
            >
              Logout
            </Button>
            <Separator />
          </Container>
        </ScrollView>
      </Layout>
    );
  }
  return null;
};

export default BillingGateKeeper;
