import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setInboxItemAsync } from "../../../store/inbox/slice";
import { InboxItem } from "../../../types";

interface CompleteInboxItemInput {
  id: string;
  userId: string;
}

const dispatchOptimisticResponse = (inboxItem: InboxItem, userId: string) => {
  const { dispatch } = store;
  const dateTime = new Date().toISOString();
  dispatch(
    setInboxItemAsync.pending({
      mutation: "completeInboxItem",
      inboxItem: {
        ...inboxItem,
        syncDateTime: dateTime,
        completed: {
          at: dateTime,
          by: userId,
        },
      },
    })
  );
};

const completeInboxItem = (
  { id, userId }: CompleteInboxItemInput,
  inboxItem: InboxItem
) => {
  dispatchOptimisticResponse(inboxItem, userId);
  client.mutate({
    mutation,
    variables: {
      id,
    },
  });
};

export default completeInboxItem;
