import { Text } from "@ui-kitten/components";
import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import { Job } from "../../types";
import Separator from "../buildingBlocks/Separator";
import Card from "../Card";
import UserListItem from "../listItems/User";
import GroupAssignees from "./GroupAssignees";
import UserAssignees from "./UserAssignees";

interface JobTeamCardProps {
  job: Job;
  onSendMessagePress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  divider: {
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const JobTeamCard: React.FC<JobTeamCardProps> = ({ job, style }) => {
  const userIds = [];
  const groupIds = [];
  job.assignedTo.forEach((assignee) => {
    if (assignee.type === "Group") {
      groupIds.push(assignee.id);
    } else if (assignee.type === "User") {
      userIds.push(assignee.id);
    }
  });

  return (
    <Card style={style} title="Team">
      <Separator size="medium" />
      <Text appearance="hint" category="c1" style={styles.text}>
        Job creator
      </Text>
      <Separator size="small" />
      <UserListItem disabled userId={job.created.by} />
      {userIds.length > 0 && (
        <>
          <Separator size="medium" />
          <Text appearance="hint" category="c1" style={styles.text}>
            Users
          </Text>
          <Separator size="small" />
          <UserAssignees assignedUserIds={userIds} job={job} />
        </>
      )}
      {groupIds.length > 0 && (
        <>
          <Separator size="medium" />
          <Text appearance="hint" category="c1" style={styles.text}>
            Groups
          </Text>
          <Separator size="small" />
          <GroupAssignees groupIds={groupIds} />
        </>
      )}
      <Separator size="medium" />
    </Card>
  );
};

export default JobTeamCard;
