const getNumberOfNotCompletedTasks = ({ tasks, taskCompletionRecords }) => {
  let count = tasks.length;
  tasks.forEach((task) => {
    taskCompletionRecords.forEach((record) => {
      if (record.taskId === task.id) count -= 1;
    });
  });
  return count;
};

export default getNumberOfNotCompletedTasks;
