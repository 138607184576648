import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import Button, { Props as ButtonProps } from "../buildingBlocks/Button";
import useHover from "../../hooks/useHover";

interface Props
  extends Pick<ButtonProps, "appearance" | "children" | "onPress" | "style"> {
  buttonContainerStyle?: StyleProp<ViewStyle>;
}

const HoverActivatedButton = ({
  appearance,
  buttonContainerStyle,
  children,
  onPress,
  style,
}: Props) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <View
      ref={hoverRef}
      style={[{ opacity: isHovered ? 1 : 0 }, buttonContainerStyle]}
    >
      <Button appearance={appearance} onPress={onPress} style={style}>
        {children}
      </Button>
    </View>
  );
};

HoverActivatedButton.defaultProps = {
  buttonContainerStyle: undefined,
};

export default HoverActivatedButton;
