import { createSelector } from "@reduxjs/toolkit";

import selectMapSelection from "../selectMapSelection";

const selectId = createSelector(
  [selectMapSelection],
  (mapSelection) => mapSelection.type
);

export default selectId;
