import gql from "graphql-tag";

import { dailyActivityReportFragment } from "../../../fragments";

export default gql`
  mutation deleteDailyActivityReport($id: ID!, $jobId: ID!) {
    deleteDailyActivityReport(id: $id, jobId: $jobId) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;
