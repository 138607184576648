import mutation from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const login = async (expoToken) => {
  try {
    const data = await client.mutate({
      errorPolicy: "all",
      mutation,
      variables: {
        expoToken,
      },
    });
    return data.login;
  } catch (error) {
    throw generateErrorMessage("login", error);
  }
};

export default login;
