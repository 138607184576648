import gql from "graphql-tag";

import { actionItemFragment } from "../../../fragments";

export default gql`
  mutation deleteActionItem($id: ID!) {
    deleteActionItem(id: $id) {
      ...actionItemFragment
    }
  }
  ${actionItemFragment}
`;
