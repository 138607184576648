import gql from "graphql-tag";

import { privateCompanySiteFragment } from "../../../fragments";

export default gql`
  mutation unlinkSiteFromCustomer($siteId: ID!) {
    unlinkSiteFromCustomer(siteId: $siteId) {
      ...privateCompanySiteFragment
    }
  }
  ${privateCompanySiteFragment}
`;
