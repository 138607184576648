import clockIn from "../../api/functions/clockIn";
import { getLocation } from "../../location/mapLocation";
import { CompanyUser } from "../../types";

interface Params {
  user: CompanyUser;
}

const runClockIn = async ({ user }: Params) => {
  const location = await getLocation({ title: "Clock in failed" });

  if (location) {
    clockIn(
      {
        coordinates: {
          accuracy: location.accuracy,
          lat: location.latitude,
          lng: location.longitude,
        },
        timestamp: new Date().toISOString(),
      },
      user
    );
  }
};

export default runClockIn;
