import { Tutorial, TutorialTypes } from "../types";

export const quickstartAdminAndSupervisorMobile: Tutorial = {
  title: "Quickstart for admins and supervisors",
  type: "quickstartAdminAndSupervisorMobile",
  description:
    "Get all the info you need to get up and running as an admin or supervisor, right away.",
  s3FileName: "quickstart-admin-mobile.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 20000, description: "Creating a user" },
    { timestamp: 160000, description: "Creating a location" },
    { timestamp: 330000, description: "Creating a job" },
    {
      timestamp: 550000,
      description:
        "Using the map and job list to observe job statuses in realtime",
    },
    {
      timestamp: 702000,
      description: "Scheduling",
    },
    {
      timestamp: 768000,
      description: "In-app messaging",
    },
    {
      timestamp: 795000,
      description: "Time keeping and time cards",
    },
  ],
};

export const quickstartEmployeeMobile: Tutorial = {
  title: "Quickstart for employees",
  type: "quickstartEmployeeMobile",
  description:
    "Get all the info you need to get up and running as an employee, right away.",
  s3FileName: "quickstart-employee-mobile.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 13000, description: "Jobs explained, checking your schedule" },
    { timestamp: 101000, description: "Clocking in" },
    { timestamp: 126000, description: "Starting a job" },
    {
      timestamp: 230000,
      description: "Creating a daily activity report for a job",
    },
    { timestamp: 340000, description: "Creating an incident report for a job" },
    { timestamp: 401000, description: "Abandoning or dismissing a job" },
    { timestamp: 455000, description: "Completing a job" },
    { timestamp: 494000, description: "Clocking out" },
  ],
};

export const clockingInWeb: Tutorial = {
  title: "Learn about clocking in",
  type: "clockingInWeb",
  description: "Learn how to clock in and why it's important.",
  s3FileName: "clocking-in-web.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 37000, description: "Clocking in and completing a job" },
    { timestamp: 290000, description: "Clocking out" },
  ],
};

export const groupsWeb: Tutorial = {
  title: "Learn about groups",
  type: "groupsWeb",
  description: "Learn about groups and how to create them.",
  s3FileName: "groups-web.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 34000, description: "Creating a group" },
    { timestamp: 60000, description: "Assigning a group to a job" },
  ],
};

export const jobSitesWeb: Tutorial = {
  title: "Learn about job locations",
  type: "jobSitesWeb",
  description: "Learn about locations and how to create them.",
  s3FileName: "job-sites-web.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 21000, description: "Creating a location" },
    { timestamp: 101000, description: "Creating a job at a location" },
  ],
};

export const listFeatureWeb: Tutorial = {
  title: "Learn about the job list on the map",
  type: "listFeatureWeb",
  description: "Learn how to utilize the job list.",
  s3FileName: "list-feature-web.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 16000, description: "Opening the jobs list" },
    { timestamp: 77000, description: "Filtering the jobs by job status" },
    { timestamp: 96000, description: "Inspecting the progress of a job" },
    {
      timestamp: 199000,
      description: "Opening the jobs list with the filter already set",
    },
  ],
};

export const usersWeb: Tutorial = {
  title: "Learn about users",
  type: "usersWeb",
  description: "Learn about users and how to create them.",
  s3FileName: "users-web.mp4",
  timestamps: [
    { timestamp: 0, description: "Intro" },
    { timestamp: 13000, description: "Creating a user" },
  ],
};

const tutorials: Record<TutorialTypes, Tutorial> = {
  quickstartAdminAndSupervisorMobile,
  quickstartEmployeeMobile,
  clockingInWeb,
  groupsWeb,
  jobSitesWeb,
  listFeatureWeb,
  usersWeb,
};

export default tutorials;
