import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Icon, Layout, TopNavigationAction } from "@ui-kitten/components";
import React, { useState } from "react";
import { Keyboard, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import UserListItem from "../../components/listItems/User";
import CreateUserModal from "../../components/modals/CreateUser";
import UserList from "../../components/UserList";
import companyUserRoles from "../../constants/companyUserRoles";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import selectUsers from "../../store/users/selectors/selectUsers";
import { StackParamList } from "../../types";
import isFeatureEnabled from "../../utils/isFeatureEnabled";
import sortUsers from "../../utils/sortUsers";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

const CompanyUsers = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "CompanyGroups">
  >();
  const company = useSelector(selectCompany);
  const signedInUserRole = useSelector(selectSignedInUserRole);
  const users = useSelector(selectUsers);
  const [createUserVisible, setCreateUserVisible] = useState(false);

  const timeCardsEnabled = isFeatureEnabled({ company, feature: "timeCards" });

  return (
    <>
      <Layout
        style={[
          styles.root,
          {
            paddingEnd: insets.right,
            paddingStart: insets.left,
            paddingTop: insets.top,
          },
        ]}
      >
        <UserList
          accessoryRight={() =>
            signedInUserRole === companyUserRoles.ADMIN ? (
              <TopNavigationAction
                data-test="createUserButton"
                icon={(props) => <Icon {...props} name="plus-outline" />}
                onPress={() => {
                  Keyboard.dismiss();
                  setCreateUserVisible(true);
                }}
              />
            ) : null
          }
          navigation={{
            icon: "arrow-back-outline",
            onPress: () => navigation.goBack(),
          }}
          renderItem={({ item: userId }) => (
            <UserListItem
              key={userId}
              data-test={`${userId}-userListItem`}
              showClockedInIndicator={timeCardsEnabled}
              userId={userId}
              onPress={() => navigation.navigate("UserDetails", { userId })}
            />
          )}
          showCount
          title="Users"
          userIds={users.ids.sort((a, b) =>
            sortUsers(users.entities[a], users.entities[b])
          )}
        />
      </Layout>
      <CreateUserModal
        isVisible={createUserVisible}
        onClose={() => setCreateUserVisible(false)}
        onSuccess={() => setCreateUserVisible(false)}
      />
    </>
  );
};

export default CompanyUsers;
