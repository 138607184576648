import React from "react";
import { View, StyleSheet } from "react-native";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  root: {
    height: "100vh",
    overflow: "hidden",
    width: "100vw",
  },
});

const FullHeightView = ({ children }) => {
  return <View style={styles.root}>{children}</View>;
};

FullHeightView.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FullHeightView;
