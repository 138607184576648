import dayjs from "dayjs";
import { useSelector } from "react-redux";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import selectReportsByJobId from "../../../store/jobs/selectors/selectReportsByJobId";
import isCompletionRecordCompleted from "../../../utils/isCompletionRecordCompleted";

const useMarkerProgress = ({ jobId }) => {
  // @ts-ignore
  const job = useSelector((state) => selectJobById(state, jobId));

  const reports = useSelector((state) =>
    // @ts-ignore
    selectReportsByJobId(state, { jobId })
  );

  const {
    status: { description },
  } = job;

  const jobDuration = dayjs
    .duration(dayjs(job.endDateTime).diff(dayjs(job.startDateTime)))
    .asMinutes();
  const elapsedDuration = dayjs
    .duration(dayjs().diff(dayjs(job.startDateTime)))
    .asMinutes();

  const percentComplete = elapsedDuration / jobDuration;

  const isInProgress = description === "In progress";

  const isSomeProgressDetails =
    (!isNaN(percentComplete) || job.tasks.length > 0) && isInProgress;

  const taskCompletionRecordsByTaskId = job.taskCompletionRecords.reduce(
    (acc, currentValue) => {
      return {
        ...acc,
        [currentValue.taskId]: currentValue,
      };
    },
    {}
  );

  const completedTasks = job.tasks.filter((task) => {
    const dar =
      reports?.entities[
        taskCompletionRecordsByTaskId[task.id]?.dailyActivityReportId
      ];
    return isCompletionRecordCompleted({
      checklistItem: task,
      completionRecord: taskCompletionRecordsByTaskId[task.id],
      completionRecordDailyActivityReport: dar,
    });
  });

  return {
    completedTasks,
    percentComplete,
    isInProgress,
    isSomeProgressDetails,
  };
};

export default useMarkerProgress;
