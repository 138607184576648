import { createSelector } from "@reduxjs/toolkit";

import { State } from "../../slice";

const selectUsers = createSelector(
  [(state: any): State => state.data.users],
  (users) => {
    const entities = {
      ...users.entities,
      ...users.optimistic.entities,
    };
    return {
      entities,
      ids: Object.keys(entities),
    };
  }
);

export default selectUsers;
