import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";
import mapToMediaInput from "../../utils/mapToMediaInput";

const dispatchOptimisticResponse = (
  { summary, media, timestamp },
  dailyActivityReport
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setReportPending({
      mutation: "adminUpdateDailyActivityReport",
      report: {
        ...dailyActivityReport,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        summary,
        media,
      },
    })
  );
};

const adminUpdateDailyActivityReport = (
  { summary, media, timestamp = new Date().toISOString() },
  dailyActivityReport
) => {
  const mediaInput = media.map(mapToMediaInput);
  dispatchOptimisticResponse(
    {
      summary,
      media: mediaInput,
      timestamp,
    },
    dailyActivityReport
  );
  client.mutate({
    mutation,
    variables: {
      input: deepCopy({
        id: dailyActivityReport.id,
        jobId: dailyActivityReport.jobId,
        summary,
        media: mediaInput,
        timestamp,
      }),
    },
    errorPolicy: "all",
  });
};

export default adminUpdateDailyActivityReport;
