import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

const adminClockOutUser = gql`
  mutation clockOut(
    $coordinates: CoordinatesInput!
    $timestamp: AWSDateTime!
    $id: ID!
    $userIdToClockOut: ID!
  ) {
    adminClockOutUser(
      coordinates: $coordinates
      timestamp: $timestamp
      id: $id
      userIdToClockOut: $userIdToClockOut
    ) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;

export default adminClockOutUser;
