type MapUserFilter = "all" | "colleagues";
type MapVisibilityFilter = "all" | "onlyMe";

const mapFilterConstants = {
  users: {
    colleagues: {
      value: "colleagues" as MapUserFilter,
      text: "Colleague",
    },
    all: {
      value: "all" as MapUserFilter,
      text: "All",
    },
  },
  assignees: {
    onlyMe: "onlyMe",
    all: "all",
  },
  visibility: {
    onlyMe: {
      value: "onlyMe" as MapVisibilityFilter,
      text: "Employee",
    },
    all: {
      value: "all" as MapVisibilityFilter,
      text: "Admin",
    },
  },
};

export default mapFilterConstants;
