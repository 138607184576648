import { Company } from "../../types";

const isFeatureEnabled = ({
  company,
  feature,
}: {
  company: Company;
  feature: keyof Company["features"];
}) => {
  if (company?.features) {
    return company.features[feature];
  }
  return true;
};

export default isFeatureEnabled;
