import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Icon, Layout, TopNavigationAction } from "@ui-kitten/components";
import React, { useState } from "react";
import { Keyboard, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import SiteListItem from "../../components/listItems/Site";
import SiteEditingModal from "../../components/modals/SiteEditing";
import SiteList from "../../components/SiteList";
import companyUserRoles from "../../constants/companyUserRoles";
import selectAccessibleSiteIds from "../../store/sites/selectors/selectAccessibleSites";
import selectSiteEntities from "../../store/sites/selectors/selectEntities";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import { StackParamList } from "../../types";
import sortSites from "../../utils/sortSites";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

const CompanySites = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "CompanySites">
  >();
  const signedInUserRole = useSelector(selectSignedInUserRole);
  const accessibleSiteIds = useSelector(selectAccessibleSiteIds);
  const siteEntities = useSelector(selectSiteEntities);
  const [createSiteVisible, setCreateSiteVisible] = useState(false);
  return (
    <>
      <Layout style={[styles.root, { paddingTop: insets.top }]}>
        <SiteList
          accessoryRight={() => {
            if (
              userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: signedInUserRole,
              })
            ) {
              return (
                <TopNavigationAction
                  icon={(props) => <Icon name="plus-outline" {...props} />}
                  onPress={() => {
                    Keyboard.dismiss();
                    setCreateSiteVisible(true);
                  }}
                />
              );
            }
            return null;
          }}
          data-test="listInSitesScreen"
          navigation={{
            icon: "arrow-back-outline",
            onPress: () => {
              Keyboard.dismiss();
              navigation.goBack();
            },
          }}
          renderItem={({ item: siteId }) => (
            <SiteListItem
              data-test={`${siteId}-siteListItem`}
              onPress={() => navigation.navigate("SiteDetails", { siteId })}
              siteId={siteId}
            />
          )}
          showCount
          siteIds={accessibleSiteIds.sort((a, b) =>
            sortSites(siteEntities[a], siteEntities[b])
          )}
          title="Locations"
        />
      </Layout>
      <SiteEditingModal
        isVisible={createSiteVisible}
        onClose={() => setCreateSiteVisible(false)}
        onSubmit={() => setCreateSiteVisible(false)}
      />
    </>
  );
};

export default CompanySites;
