import gql from "graphql-tag";

import { incidentReportFragment } from "../../../fragments";

const createIncidentReport = gql`
  mutation createIncidentReport(
    $id: ID!
    $timestamp: AWSDateTime!
    $jobId: ID!
  ) {
    createIncidentReport(id: $id, timestamp: $timestamp, jobId: $jobId) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default createIncidentReport;
