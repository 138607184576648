import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import {
  BottomNavigation,
  BottomNavigationTab,
  Divider,
  Icon,
  Layout,
  Text,
} from "@ui-kitten/components";
import React from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { Badge } from "react-native-paper";
import { useSelector } from "react-redux";

import selectUnreadMsgCount from "../../store/settings/selectors/selectUnreadMsgCount";
import selectClockedIn from "../../store/users/selectors/selectClockedIn";
import Container from "../Container";
import TimerText from "../TimerText";

const styles = StyleSheet.create({
  badge: {
    position: "absolute",
    bottom: 8,
    left: 16,
  },
  plusIcon: {
    height: 36,
    width: 36,
  },
  timeCardBadge: {
    position: "absolute",
    bottom: 12,
    left: 16,
  },
});

const BottomTabBar = ({ state, navigation }: BottomTabBarProps) => {
  const count = useSelector(selectUnreadMsgCount);
  const clockedIn = useSelector(selectClockedIn);

  const handleSelect = (index) => {
    const route = state.routes[index];
    const focused = state.index === index;
    const event = navigation.emit({
      type: "tabPress",
      target: route.key,
    });
    if (!focused && !event.defaultPrevented) {
      navigation.navigate(state.routeNames[index]);
    }
  };

  const bottomNavigationTabs = state.routeNames.map((routeName, index) => {
    switch (routeName) {
      case "UpcomingJobs": {
        return (
          <BottomNavigationTab
            icon={(imageProps) => <Icon name="map" {...imageProps} />}
            key={state.routes[index].key}
            title="Map"
          />
        );
      }
      case "HistoricalSearch": {
        return (
          <BottomNavigationTab
            icon={(imageProps) => <Icon name="calendar" {...imageProps} />}
            key={state.routes[index].key}
            title="Schedule"
          />
        );
      }
      case "ChannelList": {
        return (
          <BottomNavigationTab
            icon={(imageProps) => (
              <View>
                <Icon name="message-circle" {...imageProps} />
                <Badge visible={count !== 0} style={styles.badge}>
                  {count}
                </Badge>
              </View>
            )}
            key={state.routes[index].key}
            title="Messages"
          />
        );
      }
      case "TimeCard": {
        return (
          <BottomNavigationTab
            icon={(imageProps) => <Icon name="clock" {...imageProps} />}
            key={state.routes[index].key}
            title={(props) =>
              clockedIn ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TimerText {...props} dateTime={clockedIn.at} />
              ) : (
                <Text {...props}>Time</Text>
              )
            }
          />
        );
      }
      default:
        return null;
    }
  });

  return (
    <Layout>
      <Divider />
      <SafeAreaView>
        <Container>
          <BottomNavigation
            appearance="noIndicator"
            selectedIndex={state.index}
            onSelect={handleSelect}
          >
            {bottomNavigationTabs}
          </BottomNavigation>
        </Container>
      </SafeAreaView>
    </Layout>
  );
};

export default BottomTabBar;
