import React from "react";
import { useTheme } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  dot: {
    borderRadius: 8,
    height: 16,
    width: 16,
  },
});

interface UserStatusDotProps {
  online?: boolean;
}

const UserStatusDot = ({ online }: UserStatusDotProps) => {
  const theme = useTheme();
  return (
    <View
      style={[
        styles.dot,
        {
          backgroundColor: online
            ? theme["color-success-default"]
            : theme["color-basic-400"],
        },
      ]}
    />
  );
};

UserStatusDot.defaultProps = {
  online: false,
};

export default UserStatusDot;
