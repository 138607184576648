import {
  createAction,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import { ActionItem, OptimisticActionItem } from "../../../types";

const adapter = createEntityAdapter<ActionItem>();
const optimisticAdapter = createEntityAdapter<OptimisticActionItem>();

export interface State extends EntityState<ActionItem> {
  optimistic: EntityState<OptimisticActionItem>;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addActionItem = ({ actionItem, state }) => {
  const existingActionItem = state.entities[actionItem.id];
  if (
    existingActionItem === undefined ||
    actionItem.syncDateTime > existingActionItem.syncDateTime
  ) {
    adapter.upsertOne(state, actionItem);
  }
};

const name = "actionItems";

export const setActionItemPending = createAction<{
  actionItem: ActionItem;
  mutation: string;
}>(`${name}/setActionItem/pending`);
export const setActionItemFulfilled = createAction<{
  actionItem: ActionItem;
  mutation: string;
}>(`${name}/setActionItem/fulfilled`);
export const setActionItemRejected = createAction(
  `${name}/setActionItem/rejected`,
  (payload: { id: string; mutation: string }, error: Error) => ({
    error,
    payload,
  })
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setActionItem: (state, action) => {
      const { actionItem } = action.payload;
      addActionItem({ actionItem, state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActionItemPending, (state, action) => {
      const { actionItem, mutation } = action.payload;
      const existingOptimisticActionItem =
        state.optimistic.entities[actionItem.id];
      if (
        existingOptimisticActionItem === undefined ||
        actionItem.syncDateTime > existingOptimisticActionItem.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...actionItem,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setActionItemFulfilled, (state, action) => {
      const { actionItem, mutation } = action.payload;
      addActionItem({ actionItem, state });
      const existingOptimisticActionItem =
        state.optimistic.entities[actionItem.id];
      if (
        existingOptimisticActionItem !== undefined &&
        mutation === existingOptimisticActionItem.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, actionItem.id);
      }
    });
    builder.addCase(setActionItemRejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticActionItem = state.optimistic.entities[id];
      if (
        existingOptimisticActionItem !== undefined &&
        mutation === existingOptimisticActionItem.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { actionItems, lastSync } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, actionItems);
      } else {
        actionItems.forEach((actionItem) =>
          addActionItem({ actionItem, state })
        );
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const { setActionItem } = actions;

export default reducer;
