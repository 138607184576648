import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

const clockOut = gql`
  mutation clockOut(
    $coordinates: CoordinatesInput
    $timestamp: AWSDateTime!
    $id: ID!
  ) {
    clockOut(coordinates: $coordinates, timestamp: $timestamp, id: $id) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;

export default clockOut;
