import { Divider, Icon, Layout } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import styled from "styled-components/native";
import uuidv4 from "uuid/v4";
import * as yup from "yup";

import createCustomer from "../../../api/functions/createCustomer";
import linkSiteToCustomer from "../../../api/functions/linkSiteToCustomer";
import { setCustomer } from "../../../store/customers/slice";
import { Customer, CustomerEmail } from "../../../types";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import IconButton from "../../IconButton";
import { addNotification } from "../../InAppNotifications";
import BaseListItem from "../../listItems/Base";
import SectionItem from "../../SectionItem";
import AlertModal from "../Alert";
import EmailEditingModal from "../EmailEditing";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import TextInputModal from "../TextInput";

interface FormValues {
  emails: Array<CustomerEmail>;
  name: string;
  notes: string;
}

interface Props extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  onSuccess: (customer: Customer) => void;
}

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    justifyContent: "flex-start",
  },
  content: {
    flex: 1,
  },
  emailListItemAccessoryRight: {
    alignItems: "center",
    flexDirection: "row",
  },
  listItemDivider: {
    marginStart: 16,
  },
});

const CreateCustomerForSiteModal = ({
  isVisible,
  onClose,
  onSuccess,
}: Props) => {
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();

  const [createCustomerPending, setCreateCustomerPending] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      emails: [],
      name: "",
      notes: "",
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      const customerId = uuidv4();
      setCreateCustomerPending(true);
      try {
        const customer = await createCustomer({
          emails: values.emails,
          id: customerId,
          name: values.name,
          notes: values.notes || "",
        });
        dispatch(setCustomer({ customer }));
        await onSuccess(customer);
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Create customer failed",
        });
      }
      setCreateCustomerPending(false);
    },
  });

  const [alertVisible, setAlertVisible] = useState(false);
  const [nameEditingVisible, setNameEditingVisible] = useState(false);
  const [emailEditing, setEmailEditing] = useState<{
    index: number;
    visible: boolean;
  }>({
    index: 0,
    visible: false,
  });
  const [notesEditingVisible, setNotesEditingVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          navigation={{ icon: "close-outline", onPress: onClose }}
          title="New customer"
        />
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView contentContainerStyle={{ paddingBottom: insets.bottom }}>
            <Separator />
            <SectionItem title="Name">
              {formik.values.name ? (
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => setNameEditingVisible(true)}
                  title={formik.values.name}
                />
              ) : (
                <>
                  <Button
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="plus-outline" />
                    )}
                    appearance="ghost"
                    data-test="nameButtonInCustomerEditingModal"
                    onPress={() => setNameEditingVisible(true)}
                    size="small"
                    style={styles.button}
                  >
                    Add name
                  </Button>
                </>
              )}
            </SectionItem>
            <Separator />
            <SectionItem title="Emails">
              {formik.values.emails.length > 0 && (
                <>
                  {formik.values.emails.map((email, index) => (
                    <Fragment key={email.id}>
                      {index > 0 && <Divider style={styles.listItemDivider} />}
                      <BaseListItem
                        accessoryLeft={() => (
                          <IconButton
                            name="remove"
                            onPress={() =>
                              formik.setFieldValue(
                                "emails",
                                formik.values.emails.filter(
                                  (currentEmail) => currentEmail.id !== email.id
                                )
                              )
                            }
                            pack="MaterialIcons"
                            status="danger"
                            style={{ marginHorizontal: 8 }}
                          />
                        )}
                        accessoryRight={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="arrow-ios-forward-outline"
                          />
                        )}
                        onPress={() =>
                          setEmailEditing({ index, visible: true })
                        }
                        title={email.email}
                      />
                    </Fragment>
                  ))}
                  <Divider />
                </>
              )}
              <Button
                accessoryLeft={(imageProps) => (
                  <Icon {...imageProps} name="plus-outline" />
                )}
                appearance="ghost"
                data-test="addEmailButtonInCustomerEditingModal"
                onPress={() =>
                  setEmailEditing({
                    index: formik.values.emails.length,
                    visible: true,
                  })
                }
                size="small"
                style={styles.button}
              >
                Add email address
              </Button>
            </SectionItem>
            <Separator />
            <SectionItem title="Notes">
              {formik.values.notes ? (
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => setNotesEditingVisible(true)}
                  title={formik.values.notes}
                />
              ) : (
                <Button
                  accessoryLeft={(imageProps) => (
                    <Icon {...imageProps} name="plus-outline" />
                  )}
                  appearance="ghost"
                  data-test="notesButtonInCustomerEditingModal"
                  onPress={() => setNotesEditingVisible(true)}
                  size="small"
                  style={styles.button}
                >
                  Add notes
                </Button>
              )}
            </SectionItem>
            <Separator />
          </ScrollView>
        </Layout>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                loading={createCustomerPending}
                onPress={() => formik.handleSubmit()}
              >
                Add
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </FullScreenModal>
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard"
        isVisible={alertVisible}
        message="Discard new customer?"
        onCancel={() => setAlertVisible(false)}
        onClose={() => setAlertVisible(false)}
        onConfirm={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
      <TextInputModal
        autoCapitalize="words"
        headerTitle="Name"
        isVisible={nameEditingVisible}
        onClose={() => setNameEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("name", text);
          setNameEditingVisible(false);
        }}
        text={formik.values.name}
        validationSchema={yup.object({
          text: yup.string().required("Required"),
        })}
      />
      <EmailEditingModal
        email={formik.values.emails[emailEditing.index]}
        headerTitle="Email"
        isVisible={emailEditing.visible}
        onClose={() =>
          setEmailEditing((prevState) => ({ ...prevState, visible: false }))
        }
        onSubmit={(email) => {
          formik.setFieldValue(`emails[${emailEditing.index}]`, email);
          setEmailEditing((prevState) => ({ ...prevState, visible: false }));
        }}
      />
      <TextInputModal
        headerTitle="Notes"
        isVisible={notesEditingVisible}
        multiline
        onClose={() => setNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("notes", text);
          setNotesEditingVisible(false);
        }}
        text={formik.values.notes}
      />
    </>
  );
};

export default CreateCustomerForSiteModal;
