import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactText,
  useEffect,
  useState,
} from "react";

interface Context extends Omit<Props, "onComplete">, TourState {
  next: () => void;
  skip: () => void;
}

interface Props {
  onComplete: () => void;
  steps: Array<{
    content: ReactElement | ReactText;
    name: string;
  }>;
  visible: boolean;
}

interface TourState {
  index: number;
}

export const TourContext = createContext<Context | null>(null);

const Tour = ({
  children,
  onComplete,
  steps,
  visible,
}: PropsWithChildren<Props>) => {
  const [state, setState] = useState<TourState>({
    index: 0,
  });
  useEffect(() => {
    if (visible) {
      setState((prevState) => ({ ...prevState, index: 0 }));
    }
  }, [visible]);
  const next = () => {
    if (state.index < steps.length - 1) {
      setState((prevState) => ({ ...prevState, index: state.index + 1 }));
    } else {
      onComplete();
    }
  };
  return (
    <TourContext.Provider
      value={{ index: state.index, next, skip: onComplete, steps, visible }}
    >
      {children}
    </TourContext.Provider>
  );
};

export default Tour;
