import API, { GraphQLResult } from "@aws-amplify/api";

import { Company } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

const createStripeAccount = async () => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
    })) as GraphQLResult<{ createStripeAccount: Company }>;
    return data.createStripeAccount;
  } catch ({ errors }) {
    throw generateErrorMessage("createStripeAccount", errors);
  }
};

export default createStripeAccount;
