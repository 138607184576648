import {
  Divider,
  Icon,
  Input,
  Layout,
  Spinner,
  Text,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Keyboard, SafeAreaView } from "react-native";
import styled from "styled-components/native";

import listPrices from "../../../api/functions/listPrices";
import { StripePrice } from "../../../types";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import ProductListItem from "../../listItems/Product";
import CreateProductModal from "../CreateProduct";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface ProductSelectModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  createProductDisabled?: boolean;
  filter?: (price: StripePrice) => boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  onSelect: (price: StripePrice) => void;
}

const IconPlaceholder = styled.View`
  height: 24px;
  margin: 0 8px;
  width: 24px;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const FlatListContainer = styled(Layout)`
  flex: 1;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const ProductSelectModal = ({
  createProductDisabled = false,
  filter,
  headerTitle = "Add product",
  headerSubtitle,
  isVisible,
  onClose,
  onSelect,
}: ProductSelectModalProps) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [prices, setPrices] = useState<Array<StripePrice>>();

  const [searchText, setSearchText] = useState("");

  const [tempSelected, setTempSelected] = useState<StripePrice>();
  const [createProductModalVisible, setCreateProductModalVisible] = useState(
    false
  );
  const [submitPending, setSubmitPending] = useState(false);

  const runListPrices = useCallback(async () => {
    try {
      let data = [];
      let startingAfter: string;
      do {
        const listPricesResult = await listPrices({
          active: true,
          starting_after: startingAfter,
        });
        data = data.concat(listPricesResult.data);
        startingAfter = listPricesResult.has_more
          ? listPricesResult.data[listPricesResult.data.length - 1].id
          : null;
      } while (startingAfter);
      setPrices(data);
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List prices failed",
      });
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      (async () => {
        setLoading(true);
        setPrices(null);
        await runListPrices();
        setLoading(false);
      })();
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible) {
      setSearchText("");
      setTempSelected(null);
    }
  }, [isVisible]);

  const availablePrices = prices?.filter((price) => {
    if (filter) {
      return filter(price);
    }
    return (
      !searchText ||
      price.product.name.toLowerCase().includes(searchText.trim().toLowerCase())
    );
  });

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Header
          accessoryRight={
            !createProductDisabled &&
            (() => (
              <TopNavigationAction
                icon={(imageProps) => <Icon {...imageProps} name="plus" />}
                onPress={() => {
                  Keyboard.dismiss();
                  setCreateProductModalVisible(true);
                }}
              />
            ))
          }
          navigation={{ icon: "close-outline", onPress: onClose }}
          subtitle={headerSubtitle}
          title={headerTitle}
        />
        <Separator size="small" />
        <StyledInput
          onChangeText={setSearchText}
          placeholder="Search..."
          value={searchText}
        />
        <Separator size="small" />
        <Divider />
        <FlatListContainer level="2">
          <FlatList
            data={availablePrices}
            ItemSeparatorComponent={() => (
              <Layout>
                <StyledDivider />
              </Layout>
            )}
            keyExtractor={(price) => price.product.id}
            ListEmptyComponent={
              <ListEmptyContainer>
                <Separator />
                {loading ? (
                  <Spinner />
                ) : (
                  <Text appearance="hint" category="s1">
                    No results found
                  </Text>
                )}
              </ListEmptyContainer>
            }
            onRefresh={async () => {
              setRefreshing(true);
              await runListPrices();
              setRefreshing(false);
            }}
            refreshing={refreshing}
            renderItem={({ item: price }) => (
              <Layout>
                <ProductListItem
                  accessoryRight={(imageProps) => {
                    if (tempSelected && tempSelected.id === price.id) {
                      return (
                        <Icon
                          {...imageProps}
                          fill={theme["color-primary-default"]}
                          name="checkmark-outline"
                        />
                      );
                    }
                    return <IconPlaceholder />;
                  }}
                  onPress={() =>
                    setTempSelected(
                      !tempSelected || tempSelected.id !== price.id
                        ? price
                        : null
                    )
                  }
                  price={price}
                />
              </Layout>
            )}
          />
        </FlatListContainer>
        {!loading && prices?.length > 0 && (
          <>
            <Divider />
            <Layout>
              <SafeAreaView>
                <SubmitButtonContainer>
                  <Button
                    disabled={
                      !Array.isArray(availablePrices) ||
                      availablePrices.length === 0
                    }
                    loading={submitPending}
                    onPress={async () => {
                      setSubmitPending(true);
                      await onSelect(tempSelected);
                      setSubmitPending(false);
                    }}
                  >
                    Select
                  </Button>
                </SubmitButtonContainer>
              </SafeAreaView>
            </Layout>
          </>
        )}
      </FullScreenModal>
      <CreateProductModal
        isVisible={createProductModalVisible}
        onClose={() => setCreateProductModalVisible(false)}
        onSuccess={async (price) => {
          onSelect(price);
          setSubmitPending(true);
          await onSelect(price);
          setSubmitPending(false);
          setCreateProductModalVisible(false);
        }}
      />
    </>
  );
};

export default ProductSelectModal;
