import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Button, Divider, Layout, Text, useTheme } from "@ui-kitten/components";
import React, { useRef, useState } from "react";
import { ScrollView, SectionList, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import dayjs from "dayjs";

import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import selectTimeFormat from "../../store/settings/selectors/selectTimeFormat";
import { DateTimeRange, StackParamList } from "../../types";
import TimeRangePicker from "../../components/TimeRangePicker";
import {
  resetFilters,
  setCustomRangeDateTimes,
  setMode,
  setSelectedDate,
  setEmailActivities,
  setEmails,
  setQueryDateTimes,
} from "../../store/emailActivity/slice";
import selectEmailActivity from "../../store/emailActivity/selectors/selectEmailActivity";
import { addNotification } from "../../components/InAppNotifications";
import queryDateTimeData from "../../api/functions/queryDateTimeData";
import selectQueriedEmailActivities from "../../store/emailActivity/selectors/selectQueriedEmailActivities";
import useOnInitialFocus from "../../hooks/useOnInitialFocus";
import CustomerEmailPickerModal from "../../components/modals/CustomerEmailPicker";
import selectCustomerEmails from "../../store/customers/selectors/selectCustomerEmails";
import { ReduxEmailActivityListItem } from "../../components/listItems/EmailActivity";
import momentFormats from "../../constants/momentFormats";
import DottedDay from "../../components/calendar/DottedDay";

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  filterContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  filterButtonsContainer: {
    flexDirection: "row",
  },
  pillButton: {
    borderRadius: 100,
  },
  sectionHeader: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});

const EmailActivity = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "EmailActivity">
  >();
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const sectionListRef = useRef<SectionList>();
  const [loading, setLoading] = useState<boolean>(false);
  const emailActivity = useSelector(selectEmailActivity);
  const customerEmails = useSelector(selectCustomerEmails);
  const { sections } = useSelector(selectQueriedEmailActivities);
  const [showEmailSelectionModal, setShowEmailSelectionModal] = useState<
    boolean
  >(false);

  const timeFormat = useSelector(selectTimeFormat);

  const loadData = async ({
    endDateTime,
    startDateTime,
  }: Partial<DateTimeRange>): Promise<void> => {
    if (!endDateTime || !startDateTime) return;
    setLoading(true);
    dispatch(setQueryDateTimes({ endDateTime, startDateTime }));
    try {
      const emailActivities = await queryDateTimeData({
        from: startDateTime,
        to: endDateTime,
        type: "EmailActivity",
      });
      dispatch(setEmailActivities({ emailActivities }));
    } catch (e) {
      addNotification({
        message: e.message || "An unexpected error occurred",
        status: "danger",
        title: "Error loading email activities",
      });
    }
    setLoading(false);
  };

  useOnInitialFocus(() => {
    if (emailActivity.mode === "custom") {
      return loadData(emailActivity.queryDateTimes);
    }
    return loadData({
      startDateTime: dayjs(emailActivity.selectedDate)
        .startOf("week")
        .toISOString(),
      endDateTime: dayjs(emailActivity.selectedDate)
        .endOf("week")
        .toISOString(),
    });
  });

  const scrollToDay = (date: string) => {
    const formattedDate = dayjs(date).format(momentFormats.dateFormat);
    sections.forEach((item, index) => {
      if (item.title === formattedDate) {
        sectionListRef.current.scrollToLocation({
          sectionIndex: index,
          itemIndex: 0,
        });
      }
    });
  };

  const renderEmailActivityListItem = (id: string) => {
    return <ReduxEmailActivityListItem id={id} timestampFormat={timeFormat} />;
  };

  return (
    <>
      <Layout level="2" style={styles.flex}>
        <Layout>
          <Container>
            <View
              style={{
                paddingEnd: insets.right,
                paddingStart: insets.left,
                paddingTop: insets.top,
              }}
            >
              <HeaderWithTextAction
                navigation={{
                  icon: "arrow-back-outline",
                  onPress: navigation.goBack,
                }}
                title="Email activity"
              />
            </View>
          </Container>
        </Layout>
        <Divider />
        <Layout level="2" style={styles.flex}>
          <Container style={styles.flex}>
            <Separator size="small" />
            <TimeRangePicker
              customRange={emailActivity.customRangeDateTimes}
              loading={loading}
              mode={emailActivity.mode}
              onCustomRangeChanged={(customRange) => {
                dispatch(setCustomRangeDateTimes(customRange));
              }}
              onModeChanged={(mode) => {
                dispatch(setMode({ mode }));
              }}
              onQueryRangeChanged={loadData}
              onSelectedDateChanged={(dateTime) => {
                scrollToDay(dateTime);
                dispatch(setSelectedDate({ selectedDate: dateTime }));
              }}
              selectedDate={emailActivity.selectedDate}
              style={{ paddingEnd: 16, paddingStart: 16 }}
              renderCalendarDay={({ dateTime, onPress, selected }) => {
                const colors: Set<string> = new Set();
                const key = dayjs(dateTime).format(momentFormats.dateFormat);
                sections.forEach((item) => {
                  if (item.title === key) {
                    item.data.forEach((id) => {
                      const event = emailActivity.entities[id];
                      if (event.type === "Delivery")
                        colors.add(theme["color-success-default"]);
                      else if (
                        event.type === "Bounce" ||
                        event.type === "SpamComplaint"
                      )
                        colors.add(theme["color-danger-default"]);
                      else if (event.type === "Click" || event.type === "Open")
                        colors.add(theme["color-info-default"]);
                      else if (event.type === "SubscriptionChange")
                        colors.add(theme["color-warning-default"]);
                    });
                  }
                });
                return (
                  <DottedDay
                    colors={Array.from(colors)}
                    selected={selected}
                    dateTime={dateTime}
                    onPress={onPress}
                  />
                );
              }}
            />
            <Separator size="small" />
            <Divider />
            <Layout style={styles.flex}>
              <View>
                <ScrollView
                  contentContainerStyle={styles.filterContainer}
                  horizontal
                >
                  <View style={styles.filterButtonsContainer}>
                    <Button
                      appearance={
                        emailActivity.emails.length > 0 ? "filled" : "outline"
                      }
                      onPress={() => setShowEmailSelectionModal(true)}
                      size="tiny"
                      style={styles.pillButton}
                    >
                      {`Email address \u2022 ${
                        emailActivity.emails.length > 0
                          ? emailActivity.emails.length
                          : "All"
                      }`}
                    </Button>
                    <Separator horizontal size="small" />
                  </View>
                  <Separator horizontal size="small" />
                  <Button
                    disabled={emailActivity.emails.length === 0}
                    onPress={() => dispatch(resetFilters())}
                    size="tiny"
                    status="control"
                    style={styles.pillButton}
                  >
                    Clear
                  </Button>
                </ScrollView>
                <Divider />
              </View>
              <Layout style={styles.flex}>
                <SectionList
                  ref={sectionListRef}
                  onRefresh={async () => {
                    setRefreshing(true);
                    await loadData(emailActivity.queryDateTimes);
                    setRefreshing(false);
                  }}
                  refreshing={refreshing}
                  sections={sections}
                  keyExtractor={(item) => item}
                  ItemSeparatorComponent={Divider}
                  getItemLayout={sectionListGetItemLayout({
                    getItemHeight: () => 114,
                    getSeparatorHeight: () => 1,
                    getSectionHeaderHeight: () => 36,
                  })}
                  renderItem={({ item }) => renderEmailActivityListItem(item)}
                  renderSectionHeader={({ section }) => {
                    const { data, title } = section;
                    return (
                      <Layout level="3" style={styles.sectionHeader}>
                        <Text>{`${title} (${data.length})`}</Text>
                      </Layout>
                    );
                  }}
                  contentContainerStyle={[{ paddingBottom: insets.bottom }]}
                  stickySectionHeadersEnabled
                  ListEmptyComponent={
                    <View style={styles.listEmptyComponent}>
                      <Text category="h3">No email activity found</Text>
                    </View>
                  }
                />
              </Layout>
            </Layout>
          </Container>
        </Layout>
      </Layout>
      <CustomerEmailPickerModal
        isVisible={showEmailSelectionModal}
        onClose={() => setShowEmailSelectionModal(false)}
        emails={customerEmails}
        onSelect={(selectedEmail) => {
          if (emailActivity.emails.includes(selectedEmail)) {
            dispatch(
              setEmails({
                emails: emailActivity.emails.filter(
                  (email) => selectedEmail !== email
                ),
              })
            );
          } else {
            dispatch(
              setEmails({ emails: [...emailActivity.emails, selectedEmail] })
            );
          }

          setShowEmailSelectionModal(false);
        }}
        selected={emailActivity.emails}
      />
    </>
  );
};

export default EmailActivity;
