import _ from "lodash/lang";
import { memo, useContext, useEffect } from "react";
import { MarkerProps } from "react-native-maps";

import GoogleApi from "../../map/GoogleApi";

interface Props {
  identifier?: string;
}

const Marker = ({
  coordinate,
  identifier,
  onPress,
}: Props & Pick<MarkerProps, "coordinate" | "onPress">) => {
  const googleApi = useContext(GoogleApi);
  const map = googleApi && googleApi.map;
  const maps = googleApi && googleApi.maps;
  useEffect(() => {
    let GoogleMapsMarker;
    if (map && maps) {
      GoogleMapsMarker = new maps.Marker({
        position: { lat: coordinate.latitude, lng: coordinate.longitude },
      });
      if (onPress) {
        GoogleMapsMarker.addListener("click", () => {
          const OverlayView = new maps.OverlayView();
          OverlayView.draw = () => {};
          OverlayView.setMap(map);
          const projection = OverlayView.getProjection();
          const position = GoogleMapsMarker.getPosition();
          const point = projection.fromLatLngToContainerPixel(position);
          OverlayView.setMap(null);
          onPress({
            // @ts-ignore: Relevant data is being replicated.
            nativeEvent: {
              coordinate: {
                latitude: position.lat(),
                longitude: position.lng(),
              },
              position: { x: point.x, y: point.y },
              id: identifier,
            },
          });
        });
      }
      GoogleMapsMarker.setMap(map);
    }
    return () => {
      if (GoogleMapsMarker) {
        GoogleMapsMarker.setMap(null);
      }
    };
  }, [coordinate, identifier, map, maps, onPress]);
  return null;
};

Marker.defaultProps = {
  identifier: undefined,
};

export default memo(Marker, (prevProps, nextProps) =>
  _.isEqual(
    {
      coordinate: prevProps.coordinate,
      identifier: prevProps.identifier,
      onPress: prevProps.onPress,
    },
    {
      coordinate: nextProps.coordinate,
      identifier: nextProps.identifier,
      onPress: nextProps.onPress,
    }
  )
);
