import { Text } from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import markInvoiceUncollectible from "../../../api/functions/markInvoiceUncollectible";
import voidInvoice from "../../../api/functions/voidInvoice";
import { StripeExpandedInvoice, StripeInvoice } from "../../../types";
import Button from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import RadioListItem from "../../listItems/Radio";
import SimpleModal, { Props as SimpleModalProps } from "../Simple";

interface ChangeInvoiceStatusModalProps
  extends Pick<SimpleModalProps, "isVisible"> {
  invoice: StripeInvoice | StripeExpandedInvoice;
  onClose: () => void;
  onSuccess: (invoid: StripeExpandedInvoice) => void;
}

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  margin: 0 16px;
`;

const ChangeInvoiceStatusModal = ({
  invoice,
  isVisible,
  onClose,
  onSuccess,
}: ChangeInvoiceStatusModalProps) => {
  const [invoiceStatus, setInvoiceStatus] = useState<
    "uncollectible" | "void"
  >();
  const [invoiceStatusPending, setInvoiceStatusPending] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setInvoiceStatus(undefined);
    }
  }, [isVisible]);

  const runMarkInvoiceUncollectible = useCallback(async (invoiceId: string) => {
    setInvoiceStatusPending(true);
    try {
      onSuccess(await markInvoiceUncollectible(invoiceId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Mark invoice uncollectible failed",
      });
    }
    setInvoiceStatusPending(false);
  }, []);
  const runVoidInvoice = useCallback(async (invoiceId: string) => {
    setInvoiceStatusPending(true);
    try {
      onSuccess(await voidInvoice(invoiceId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Void invoice failed",
      });
    }
    setInvoiceStatusPending(false);
  }, []);

  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackPress={onClose}
    >
      <View onStartShouldSetResponder={() => true}>
        <Separator size="medium" />
        <StyledText appearance="hint" category="s2">
          Mark invoice as...
        </StyledText>
        <Separator size="medium" />
        {(invoice.status === "open" || invoice.status === "uncollectible") && (
          <RadioListItem
            checked={invoiceStatus === "void"}
            description="This invoice was accidentally finalized or contains a mistake"
            onChange={() => setInvoiceStatus("void")}
            title="Void"
          />
        )}
        {invoice.status === "open" && (
          <RadioListItem
            checked={invoiceStatus === "uncollectible"}
            description="Payment of this invoice is not expected. It is still possible to collect payment should your customer attempt to pay."
            onChange={() => setInvoiceStatus("uncollectible")}
            title="Uncollectible"
          />
        )}
        <Separator size="medium" />
        <StyledButton
          loading={invoiceStatusPending}
          onPress={() => {
            if (invoiceStatus === "uncollectible") {
              runMarkInvoiceUncollectible(invoice.id);
            } else if (invoiceStatus === "void") {
              runVoidInvoice(invoice.id);
            }
          }}
        >
          Update status
        </StyledButton>
        <Separator size="small" />
        <StyledButton appearance="ghost" onPress={onClose}>
          Cancel
        </StyledButton>
        <Separator size="medium" />
      </View>
    </SimpleModal>
  );
};

export default ChangeInvoiceStatusModal;
