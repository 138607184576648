import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setActionItemPending } from "../../../store/actionItems/slice";

interface CreateActionItemInput {
  description: string;
  id: string;
  dailyActivityReportRequired?: boolean;
}

const dispatchOptimisticResponse = ({
  description,
  id,
  dailyActivityReportRequired,
}: CreateActionItemInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  const timestamp = new Date().toISOString();
  dispatch(
    setActionItemPending({
      actionItem: {
        created: {
          at: timestamp,
          by: userId,
        },
        description,
        id,
        isDeleted: false,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        dailyActivityReportRequired,
      },
      mutation: "createActionItem",
    })
  );
};

const createActionItem = ({
  description,
  id,
  dailyActivityReportRequired,
}: CreateActionItemInput) => {
  dispatchOptimisticResponse({ description, id, dailyActivityReportRequired });
  client.mutate({
    mutation,
    variables: {
      description,
      id,
      dailyActivityReportRequired,
    },
  });
};

export default createActionItem;
