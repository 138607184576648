import gql from "graphql-tag";

const sendUnsubscribeRequest = gql`
    mutation sendUnsubscribeRequest {
        sendUnsubscribeRequest {
            to
            submittedAt
            messageId
            errorCode
            message
        }
    }
`;

export default sendUnsubscribeRequest;
