import dayjs from "dayjs";
import _ from "lodash";

import jobStatuses from "../../constants/jobStatuses";
import mapFilterConstants from "../../constants/mapFilter";
import { talariumBotUserId } from "../../constants/users";
import { Job } from "../../types";

interface Params {
  job: Job;
  jobAssigneeFilter: string;
  userId: string;
}

const getJobStatus = ({ job, jobAssigneeFilter, userId }: Params) => {
  let status = jobStatuses.future;
  const nowDayjs = dayjs();
  if (jobAssigneeFilter === mapFilterConstants.visibility.all.value) {
    if (job.finished) {
      status = jobStatuses.completed;
    } else if (job.startJobRecords.length > 0) {
      status = jobStatuses.inProgress;
    } else if (job.immediate) {
      status = jobStatuses.immediate;
    } else if (nowDayjs.isAfter(dayjs(job.startDateTime), "minutes")) {
      status = jobStatuses.late;
    }
  } else if (jobAssigneeFilter === mapFilterConstants.visibility.onlyMe.value) {
    if (job.finished) {
      status = jobStatuses.completed;
    } else if (
      job.startJobRecords[
        _.findIndex(
          job.startJobRecords,
          (startJobRecord) =>
            startJobRecord.record.by === userId ||
            startJobRecord.record.by === talariumBotUserId
        )
      ]
    ) {
      status = jobStatuses.inProgress;
    } else if (job.immediate) {
      status = jobStatuses.immediate;
    } else if (nowDayjs.isAfter(dayjs(job.startDateTime), "minutes")) {
      status = jobStatuses.late;
    }
  }
  return status;
};

export default getJobStatus;
