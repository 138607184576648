import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import selectActionItemById from "../../../store/actionItems/selectors/selectActionItemById";
import { setActionItemPending } from "../../../store/actionItems/slice";
import selectUser from "../../../store/auth/selectors/selectUser";

interface UpdateActionItemInput {
  description: string;
  id: string;
  dailyActivityReportRequired?: boolean;
}

const dispatchOptimisticResponse = ({
  description,
  id,
  dailyActivityReportRequired,
}: UpdateActionItemInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = selectUser(state);
  const actionItem = selectActionItemById(state, id);
  const timestamp = new Date().toISOString();
  dispatch(
    setActionItemPending({
      actionItem: {
        ...actionItem,
        description,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        dailyActivityReportRequired,
      },
      mutation: "updateActionItem",
    })
  );
};

const updateActionItem = ({
  description,
  id,
  dailyActivityReportRequired,
}: UpdateActionItemInput) => {
  dispatchOptimisticResponse({ description, id, dailyActivityReportRequired });
  client.mutate({
    mutation,
    variables: {
      description,
      id,
      dailyActivityReportRequired,
    },
  });
};

export default updateActionItem;
