import gql from "graphql-tag";

const deleteInvoiceItem = gql`
  mutation deleteInvoiceItem($id: ID!) {
    deleteInvoiceItem(id: $id) {
      deleted
      id
    }
  }
`;

export default deleteInvoiceItem;
