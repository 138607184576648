import gql from "graphql-tag";

import { stripeExpandedInvoiceFragment } from "../../../fragments";

const finalizeInvoice = gql`
  mutation finalizeInvoice($id: ID!) {
    finalizeInvoice(id: $id) {
      ...stripeExpandedInvoiceFragment
    }
  }
  ${stripeExpandedInvoiceFragment}
`;

export default finalizeInvoice;
