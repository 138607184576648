import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Divider, Text } from "@ui-kitten/components";
import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native";
import styled from "styled-components/native";

import Separator from "../../buildingBlocks/Separator";
import SimpleModal from "../Simple";

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const IndentedText = styled(Text)`
  padding: 0 16px 0 32px;
`;

const message = "";

const bugFixes = `
\u2022 Fix where multiline text inputs would cause strange behavior.
`;

const features = ``;

const ChangelogModal = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(async () => {
      try {
        const version = await AsyncStorage.getItem(
          Constants.expoConfig.version
        );
        if (!version) {
          setVisible(true);
        }
      } catch (error) {
        // Do nothing
      }
    }, 1000);
  }, []);

  const handleModalClose = async () => {
    setVisible(false);
    try {
      await AsyncStorage.setItem(
        Constants.expoConfig.version,
        Constants.expoConfig.version
      );
    } catch (error) {
      // Do nothing
    }
  };

  return (
    <SimpleModal
      isVisible={visible}
      onBackdropPress={() => null}
      onBackPress={handleModalClose}
    >
      <Separator size="medium" />
      <StyledText category="h6">{`${Constants.expoConfig.version} changes`}</StyledText>
      <Separator size="medium" />
      <Divider />
      <ScrollView>
        <Separator size="medium" />
        {Boolean(message) && (
          <>
            <StyledText>{message}</StyledText>
            <Separator size="medium" />
          </>
        )}
        {Boolean(bugFixes) && (
          <>
            <StyledText category="h6">Bug fixes</StyledText>
            <IndentedText>{bugFixes}</IndentedText>
            <Separator size="medium" />
          </>
        )}
        {Boolean(features) && (
          <>
            <StyledText category="h6">Features</StyledText>
            <IndentedText>{features}</IndentedText>
            <Separator size="medium" />
          </>
        )}
      </ScrollView>
      <Divider />
      <Separator size="medium" />
      <StyledButton onPress={handleModalClose}>Dismiss</StyledButton>
      <Separator size="medium" />
    </SimpleModal>
  );
};

export default ChangelogModal;
