import { Divider, Icon, Layout } from "@ui-kitten/components";
import { useFormik } from "formik";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";
import * as yup from "yup";

import linkSiteToCustomer from "../../../api/functions/linkSiteToCustomer";
import unlinkSiteFromCustomer from "../../../api/functions/unlinkSiteFromCustomer";
import updateCustomer from "../../../api/functions/updateCustomer";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import { CustomerEmail } from "../../../types";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import IconButton from "../../IconButton";
import { addNotification } from "../../InAppNotifications";
import BaseListItem from "../../listItems/Base";
import SiteListItem from "../../listItems/Site";
import SectionItem from "../../SectionItem";
import AlertModal from "../Alert";
import CreateSiteForCustomerModal from "../CreateSiteForCustomer";
import EmailEditingModal from "../EmailEditing";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import SiteSelectModal from "../SiteSelect";
import TextInputModal from "../TextInput";

interface FormValues {
  emails: Array<CustomerEmail>;
  name: string;
  notes: string;
  siteIds: Array<string>;
}

interface InitialValues extends Partial<FormValues> {
  id?: string;
}

interface UpdateCustomerModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  initialValues: InitialValues;
}

const getValues = (initialValues?: InitialValues): FormValues => ({
  emails: initialValues ? initialValues.emails : [],
  name: initialValues ? initialValues.name : "",
  notes: initialValues ? initialValues.notes : "",
  siteIds: initialValues ? initialValues.siteIds : [],
});

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    justifyContent: "flex-start",
  },
  content: {
    flex: 1,
  },
  customerRationaleContainer: {
    borderRadius: 8,
    borderWidth: 1,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  customerRationaleDescription: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  customerRationaleTitle: {
    fontSize: 18,
    paddingEnd: 16,
    paddingStart: 16,
  },
  listItemDivider: {
    marginStart: 16,
  },
});

const UpdateCustomerModal = ({
                               initialValues,
                               isVisible,
                               onClose,
                             }: UpdateCustomerModalProps) => {
  const insets = useSafeAreaInsets();
  const formik = useFormik<FormValues>({
    initialValues: getValues(initialValues),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      if (values.name) {
        updateCustomer({
          emails: values.emails,
          id: initialValues.id,
          name: values.name,
          notes: values.notes,
        });
        _.difference(values.siteIds, initialValues.siteIds).forEach((siteId) =>
          linkSiteToCustomer({ customerId: initialValues.id, siteId }),
        );
        _.difference(initialValues.siteIds, values.siteIds).forEach((siteId) =>
          unlinkSiteFromCustomer({ siteId }),
        );
        addNotification({
          status: "success",
          title: "Customer updated",
        });
        onClose();
      } else {
        addNotification({
          message: "Name cannot be empty",
          status: "danger",
          title: "Error",
        });
      }
    },
  });

  const [alertVisible, setAlertVisible] = useState(false);
  const [nameEditingVisible, setNameEditingVisible] = useState(false);
  const [siteEditingVisible, setSiteEditingVisible] = useState(false);
  const [sitesSelectVisible, setSitesSelectVisible] = useState(false);
  const [emailEditing, setEmailEditing] = useState<{
    index: number;
    visible: boolean;
  }>({
    index: 0,
    visible: false,
  });
  const [notesEditingVisible, setNotesEditingVisible] = useState(false);

  const handleBackPress = () => {
    if (formik.dirty) {
      setAlertVisible(true);
    } else {
      onClose();
    }
  };
  useAndroidBackHandler({ enabled: isVisible, onBackPress: handleBackPress });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getValues(initialValues),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          navigation={{
            "data-test": "closeButtonInCustomerEditingModal",
            icon: "close-outline",
            onPress: handleBackPress,
          }}
          title="Edit customer"
        />
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView contentContainerStyle={{ paddingBottom: insets.bottom }}>
            <Separator />
            <SectionItem title="Name">
              {formik.values.name ? (
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  data-test="nameButtonInCustomerEditingModal"
                  onPress={() => setNameEditingVisible(true)}
                  title={formik.values.name}
                />
              ) : (
                <Button
                  accessoryLeft={(imageProps) => (
                    <Icon {...imageProps} name="plus-outline" />
                  )}
                  appearance="ghost"
                  data-test="nameButtonInCustomerEditingModal"
                  onPress={() => setNameEditingVisible(true)}
                  size="small"
                  style={styles.button}
                >
                  Add name
                </Button>
              )}
            </SectionItem>
            <Separator />
            <SectionItem title="Locations">
              {formik.values.siteIds.length > 0 && (
                <>
                  {formik.values.siteIds.map((siteId, index) => (
                    <Fragment key={siteId}>
                      {index > 0 && <Divider style={styles.listItemDivider} />}
                      <SiteListItem
                        accessoryLeft={() => (
                          <IconButton
                            name="remove"
                            onPress={() =>
                              formik.setFieldValue(
                                "siteIds",
                                formik.values.siteIds.filter(
                                  (currentSiteId) => currentSiteId !== siteId,
                                ),
                              )
                            }
                            pack="MaterialIcons"
                            status="danger"
                            style={{ marginHorizontal: 8 }}
                          />
                        )}
                        disabled
                        siteId={siteId}
                      />
                    </Fragment>
                  ))}
                  <Divider />
                </>
              )}
              <Button
                accessoryLeft={(imageProps) => (
                  <Icon {...imageProps} name="search-outline" />
                )}
                appearance="ghost"
                onPress={() => setSitesSelectVisible(true)}
                size="small"
                style={styles.button}
              >
                Select locations
              </Button>
              <Button
                accessoryLeft={(imageProps) => (
                  <Icon {...imageProps} name="plus-outline" />
                )}
                appearance="ghost"
                data-test="createJobSiteButtonInCustomerEditingModal"
                onPress={() => setSiteEditingVisible(true)}
                size="small"
                style={styles.button}
              >
                Create location
              </Button>
            </SectionItem>
            <Separator />
            <SectionItem title="Emails">
              {formik.values.emails.length > 0 && (
                <>
                  {formik.values.emails.map((email, index) => (
                    <Fragment key={email.id}>
                      {index > 0 && <Divider style={styles.listItemDivider} />}
                      <BaseListItem
                        accessoryLeft={() => (
                          <IconButton
                            name="remove"
                            onPress={() =>
                              formik.setFieldValue(
                                "emails",
                                formik.values.emails.filter(
                                  (currentEmail) => currentEmail.id !== email.id,
                                ),
                              )
                            }
                            pack="MaterialIcons"
                            status="danger"
                            style={{ marginHorizontal: 8 }}
                          />
                        )}
                        accessoryRight={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="arrow-ios-forward-outline"
                          />
                        )}
                        onPress={() =>
                          setEmailEditing({ index, visible: true })
                        }
                        title={email.email}
                      />
                    </Fragment>
                  ))}
                  <Divider />
                </>
              )}
              <Button
                accessoryLeft={(imageProps) => (
                  <Icon {...imageProps} name="plus-outline" />
                )}
                appearance="ghost"
                data-test="addEmailButtonInCustomerEditingModal"
                onPress={() =>
                  setEmailEditing({
                    index: formik.values.emails.length,
                    visible: true,
                  })
                }
                size="small"
                style={styles.button}
              >
                Add email address
              </Button>
            </SectionItem>
            <Separator />
            <SectionItem title="Notes">
              {formik.values.notes ? (
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  onPress={() => setNotesEditingVisible(true)}
                  title={formik.values.notes}
                />
              ) : (
                <>
                  <Button
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="plus-outline" />
                    )}
                    appearance="ghost"
                    data-test="notesButtonInCustomerEditingModal"
                    onPress={() => setNotesEditingVisible(true)}
                    size="small"
                    style={styles.button}
                  >
                    Add notes
                  </Button>
                </>
              )}
            </SectionItem>
            <Separator />
          </ScrollView>
        </Layout>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button onPress={() => formik.handleSubmit()}>Done</Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </FullScreenModal>
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard"
        isVisible={alertVisible}
        message="Discard changes?"
        onCancel={() => setAlertVisible(false)}
        onClose={() => setAlertVisible(false)}
        onConfirm={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
      <TextInputModal
        autoCapitalize="words"
        headerTitle="Name"
        isVisible={nameEditingVisible}
        onClose={() => setNameEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("name", text);
          setNameEditingVisible(false);
        }}
        text={formik.values.name}
        validationSchema={yup.object({
          text: yup.string().required("Required"),
        })}
      />
      <CreateSiteForCustomerModal
        isVisible={siteEditingVisible}
        onClose={() => setSiteEditingVisible(false)}
        onSubmit={(siteId) => {
          formik.setFieldValue("siteIds", [...formik.values.siteIds, siteId]);
          setSiteEditingVisible(false);
        }}
      />
      <SiteSelectModal
        filter={
          initialValues?.id &&
          ((site) => !site.customerId || site.customerId === initialValues.id)
        }
        headerTitle="Locations"
        isVisible={sitesSelectVisible}
        multiSelect
        onClose={() => setSitesSelectVisible(false)}
        onSubmit={(siteIds) => {
          formik.setFieldValue("siteIds", siteIds);
          setSitesSelectVisible(false);
        }}
        selected={formik.values.siteIds}
      />
      <EmailEditingModal
        email={formik.values.emails[emailEditing.index]}
        headerTitle="Email"
        isVisible={emailEditing.visible}
        onClose={() =>
          setEmailEditing((prevState) => ({ ...prevState, visible: false }))
        }
        onSubmit={(email) => {
          formik.setFieldValue(`emails[${emailEditing.index}]`, email);
          setEmailEditing((prevState) => ({ ...prevState, visible: false }));
        }}
      />
      <TextInputModal
        headerTitle="Notes"
        isVisible={notesEditingVisible}
        multiline
        onClose={() => setNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("notes", text);
          setNotesEditingVisible(false);
        }}
        text={formik.values.notes}
      />
    </>
  );
};

export default UpdateCustomerModal;
