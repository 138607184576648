import { Button, Spinner } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  spinnerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const LoadingButton = ({ children, disabled, loading, ...props }) => {
  return (
    <Button
      {...props}
      accessoryLeft={(imageProps) => {
        const {
          style: { height, marginHorizontal, tintColor, width },
        } = imageProps;
        return (
          Boolean(loading) && (
            <View
              style={[
                styles.spinnerContainer,
                {
                  height,
                  marginHorizontal,
                  width,
                },
              ]}
            >
              <Spinner
                size="small"
                status="basic"
                style={{ color: tintColor }}
              />
            </View>
          )
        );
      }}
      disabled={disabled || loading}
    >
      {children}
    </Button>
  );
};

LoadingButton.defaultProps = {
  disabled: false,
};

LoadingButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default LoadingButton;
