import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  CheckBox,
  Divider,
  Icon,
  Layout,
  Spinner,
  Text,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as WebBrowser from "expo-web-browser";
import React, { useCallback, useEffect, useState } from "react";
import { RefreshControl, SafeAreaView, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components/native";
import deleteInvoice from "../../api/functions/deleteInvoice";
import finalizeInvoice from "../../api/functions/finalizeInvoice";
import retrieveInvoice from "../../api/functions/retrieveInvoice";
import sendInvoice from "../../api/functions/sendInvoice";
import sendInvoiceReceipt from "../../api/functions/sendInvoiceReceipt";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import InvoiceEmailActivityCard from "../../components/InvoiceEmailActivityCard";
import ListItem from "../../components/listItems/Base";
import InvoiceLineItemListItem from "../../components/listItems/InvoiceLineItem";
import TimelineListItem from "../../components/listItems/Timeline";
import AlertModal from "../../components/modals/Alert";
import ChangeInvoiceStatusModal from "../../components/modals/ChangeInvoiceStatus";
import InvoiceEditingModal from "../../components/modals/InvoiceEditing";
import PostmarkResponsesModal from "../../components/modals/PostmarkResponsesModal";
import StripeInvoiceStatusText from "../../components/StripeInvoiceStatusText";
import selectCustomerByStripeCustomerId from "../../store/customers/selectors/selectCustomerByStripeCustomerId";
import { setInvoicesStale } from "../../store/payments/slice";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import {
  PostmarkMessageSendingResponse,
  StackParamList,
  StripeExpandedInvoice,
} from "../../types";
import formatCurrency from "../../utils/formatCurrency";

dayjs.extend(relativeTime);

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const ListItemText = styled(Text)`
  padding: 0 8px;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const SummaryRow = styled.View`
  flex-direction: row;
`;

const SummaryRowNameContainer = styled.View`
  align-items: flex-end;
  flex: 2;
`;

const SummaryRowValueContainer = styled.View`
  align-items: flex-end;
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-left: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledInvoiceEmailActivityCard = styled(InvoiceEmailActivityCard)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const getInvoiceTitle = (invoice: StripeExpandedInvoice) => {
  if (invoice.number) {
    return invoice.number;
  }
  return `${invoice.customer.invoice_prefix}-DRAFT`;
};

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "InvoiceDetails">
  >();
  const {
    params: { invoiceId },
  } = useRoute<RouteProp<StackParamList, "InvoiceDetails">>();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [invoice, setInvoice] = useState<StripeExpandedInvoice>();

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshTimestamp, setRefreshTimestamp] = useState<string>();

  const [sendInvoiceOnFinalize, setSendInvoiceOnFinalize] = useState(true);

  const [invoiceEditingModalVisible, setInvoiceEditingModalVisible] = useState(
    false
  );
  const [postmarkModal, setPostmarkModal] = useState<{
    isVisible: boolean;
    responses: Array<PostmarkMessageSendingResponse>;
  }>({
    isVisible: false,
    responses: [],
  });

  const [deleteInvoicePending, setDeleteInvoicePending] = useState(false);
  const [finalizeInvoicePending, setFinalizeInvoicePending] = useState(false);
  const [sendInvoicePending, setSendInvoicePending] = useState(false);
  const [sendInvoiceReceiptPending, setSendInvoiceReceiptPending] = useState(
    false
  );

  const [
    changeInvoiceStatusModalVisible,
    setChangeInvoiceStatusModalVisible,
  ] = useState(false);
  const [deleteInvoiceModalVisible, setDeleteInvoiceModalVisible] = useState(
    false
  );

  const customer = useSelector((state) =>
    selectCustomerByStripeCustomerId(state, invoice?.customer?.id)
  );

  const runRetrieveInvoice = useCallback(async () => {
    try {
      setInvoice(await retrieveInvoice(invoiceId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve invoice failed",
      });
    }
  }, [invoiceId]);
  const runSendInvoice = useCallback(async () => {
    setSendInvoicePending(true);
    setPostmarkModal({
      isVisible: true,
      responses: [],
    });
    try {
      const responses = await sendInvoice({
        invoiceId,
      });
      setPostmarkModal((prevState) => ({
        ...prevState,
        responses,
      }));
    } catch (error) {
      addNotification({
        message: error.message,
        title: "Send invoice failed",
      });
    }
    setSendInvoicePending(false);
  }, [invoiceId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await runRetrieveInvoice();
      setLoading(false);
    })();
  }, [runRetrieveInvoice]);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            action={
              invoice?.status === "draft" && {
                onPress: () => setInvoiceEditingModalVisible(true),
                text: "Edit",
              }
            }
            navigation={{
              icon: "arrow-back",
              onPress: navigation.goBack,
            }}
            title="Invoice details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          refreshControl={
            <RefreshControl
              onRefresh={async () => {
                setRefreshing(true);
                await runRetrieveInvoice();
                setRefreshing(false);
                setRefreshTimestamp(new Date().toISOString());
              }}
              refreshing={refreshing}
            />
          }
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              {invoice ? (
                <>
                  <Separator size="medium" />
                  <StyledCard>
                    <Separator size="medium" />
                    <StyledText category="h6">
                      {getInvoiceTitle(invoice)}
                    </StyledText>
                    <View
                      style={{ alignItems: "center", flexDirection: "row" }}
                    >
                      <StyledText>
                        for{" "}
                        <Text category="s1">
                          {formatCurrency(invoice.total)}
                        </Text>
                      </StyledText>
                      <StripeInvoiceStatusText invoice={invoice} />
                    </View>
                    <Separator size="medium" />
                    {Boolean(invoice.status_transitions.paid_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(invoice.status_transitions.paid_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        title={
                          invoice.amount_due
                            ? `${formatCurrency(
                                invoice.amount_due
                              )} payment succeeded`
                            : "Invoice was finalized and automatically marked as paid because the amount due was $0.00"
                        }
                      />
                    )}
                    {Boolean(invoice.status_transitions.voided_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(invoice.status_transitions.voided_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        title="Invoice was voided"
                      />
                    )}
                    {Boolean(
                      invoice.status_transitions.marked_uncollectible_at
                    ) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(
                            invoice.status_transitions.marked_uncollectible_at
                          )
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        title="Invoice was marked as uncollectible"
                      />
                    )}
                    {Boolean(invoice.status_transitions.finalized_at) && (
                      <TimelineListItem
                        description={dayjs
                          .unix(invoice.status_transitions.finalized_at)
                          .format(dateTimeFormat)}
                        disabled
                        showBottomConnector
                        showTopConnector={Boolean(
                          invoice.status_transitions.marked_uncollectible_at ||
                            invoice.status_transitions.paid_at ||
                            invoice.status_transitions.voided_at
                        )}
                        title="Invoice was finalized"
                      />
                    )}
                    <TimelineListItem
                      description={dayjs
                        .unix(invoice.created)
                        .format(dateTimeFormat)}
                      disabled
                      showTopConnector={Boolean(
                        invoice.status_transitions.marked_uncollectible_at ||
                          invoice.status_transitions.finalized_at ||
                          invoice.status_transitions.paid_at ||
                          invoice.status_transitions.voided_at
                      )}
                      title={
                        invoice.quote
                          ? `Invoice was created by ${invoice.quote.number}`
                          : "Invoice was created"
                      }
                    />
                    {(invoice.status === "draft" ||
                      invoice.status === "open" ||
                      invoice.status === "paid" ||
                      invoice.status === "uncollectible") && (
                      <>
                        <Separator size="medium" />
                        <StyledDivider />
                        <Separator size="medium" />
                        {invoice.status === "draft" && (
                          <>
                            <StyledCheckBox
                              checked={sendInvoiceOnFinalize}
                              disabled={finalizeInvoicePending}
                              onChange={(checked) =>
                                setSendInvoiceOnFinalize(checked)
                              }
                            >
                              Email invoice to customer
                            </StyledCheckBox>
                            <Separator size="medium" />
                            <StyledButton
                              loading={finalizeInvoicePending}
                              onPress={async () => {
                                setFinalizeInvoicePending(true);
                                try {
                                  setInvoice(await finalizeInvoice(invoice.id));
                                  dispatch(
                                    setInvoicesStale({ invoicesStale: true })
                                  );
                                  if (sendInvoiceOnFinalize) {
                                    runSendInvoice();
                                  }
                                } catch (error) {
                                  addNotification({
                                    message: error.message,
                                    title: "Finalize invoice failed",
                                  });
                                }
                                setFinalizeInvoicePending(false);
                              }}
                            >
                              Finalize invoice
                            </StyledButton>
                          </>
                        )}
                        {invoice.status === "paid" && (
                          <StyledButton
                            onPress={async () => {
                              setSendInvoiceReceiptPending(true);
                              setPostmarkModal({
                                isVisible: true,
                                responses: [],
                              });
                              try {
                                const responses = await sendInvoiceReceipt({
                                  invoiceId,
                                });
                                setPostmarkModal((prevState) => ({
                                  ...prevState,
                                  responses,
                                }));
                              } catch (error) {
                                addNotification({
                                  message: error.message,
                                  title: "Send invoice receipt failed",
                                });
                              }
                              setSendInvoiceReceiptPending(false);
                            }}
                          >
                            Send receipt
                          </StyledButton>
                        )}
                        {(invoice.status === "open" ||
                          invoice.status === "uncollectible") && (
                          <StyledButton onPress={runSendInvoice}>
                            Send invoice
                          </StyledButton>
                        )}
                      </>
                    )}
                    <Separator size="medium" />
                  </StyledCard>
                  <Separator />
                  <StyledCard title="Summary">
                    {customer && (
                      <>
                        <ListItem
                          accessoryRight={(imageProps) => (
                            <Icon {...imageProps} name="arrow-ios-forward" />
                          )}
                          onPress={() =>
                            navigation.navigate("CustomerDetails", {
                              customerId: customer.id,
                            })
                          }
                          description={() => (
                            <ListItemText>{customer.name}</ListItemText>
                          )}
                          title={() => (
                            <ListItemText appearance="hint" category="c1">
                              Customer
                            </ListItemText>
                          )}
                        />
                      </>
                    )}
                    {invoice.quote && (
                      <>
                        <ListItem
                          accessoryRight={(imageProps) => (
                            <Icon {...imageProps} name="arrow-ios-forward" />
                          )}
                          onPress={() =>
                            navigation.navigate("QuoteDetails", {
                              quoteId: invoice.quote.id,
                            })
                          }
                          description={() => (
                            <ListItemText>{invoice.quote.number}</ListItemText>
                          )}
                          title={() => (
                            <ListItemText appearance="hint" category="c1">
                              Quote
                            </ListItemText>
                          )}
                        />
                      </>
                    )}
                    {Boolean(invoice.due_date) && (
                      <ListItem
                        description={() => (
                          <ListItemText>
                            {dayjs
                              .unix(invoice.due_date)
                              .format(dateTimeFormat)}
                          </ListItemText>
                        )}
                        disabled
                        title={() => (
                          <ListItemText appearance="hint" category="c1">
                            Due date
                          </ListItemText>
                        )}
                      />
                    )}
                    {Boolean(invoice.number) && (
                      <ListItem
                        description={() => (
                          <ListItemText>{invoice.number}</ListItemText>
                        )}
                        disabled
                        title={() => (
                          <ListItemText appearance="hint" category="c1">
                            Invoice number
                          </ListItemText>
                        )}
                      />
                    )}
                    <Separator size="small" />
                  </StyledCard>
                  <Separator />
                  {invoice.lines.data.length > 0 && (
                    <StyledCard title="Items">
                      {invoice.lines.data.map((lineItem) => (
                        <InvoiceLineItemListItem
                          disabled
                          key={lineItem.id}
                          lineItem={lineItem}
                        />
                      ))}
                      <Separator size="medium" />
                    </StyledCard>
                  )}
                  <Separator />
                  <SummaryRow>
                    <SummaryRowNameContainer>
                      <StyledText category="s1">Subtotal</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText category="s1">
                        {formatCurrency(invoice.subtotal)}
                      </StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                  {invoice.total_tax_amounts.map((totalTaxAmount) => (
                    <SummaryRow key={JSON.stringify(totalTaxAmount)}>
                      <SummaryRowNameContainer>
                        <StyledText>{`${totalTaxAmount.tax_rate.display_name} (${totalTaxAmount.tax_rate.percentage}%)`}</StyledText>
                      </SummaryRowNameContainer>
                      <SummaryRowValueContainer>
                        <StyledText>
                          {formatCurrency(totalTaxAmount.amount)}
                        </StyledText>
                      </SummaryRowValueContainer>
                    </SummaryRow>
                  ))}
                  <SummaryRow>
                    <SummaryRowNameContainer>
                      <StyledText category="s1">Total</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText category="s1">
                        {formatCurrency(invoice.total)}
                      </StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                  <SummaryRow>
                    <SummaryRowNameContainer>
                      <StyledText category="s1">Amount due</StyledText>
                    </SummaryRowNameContainer>
                    <SummaryRowValueContainer>
                      <StyledText category="s1">
                        {formatCurrency(invoice.amount_due)}
                      </StyledText>
                    </SummaryRowValueContainer>
                  </SummaryRow>
                  {invoice.status !== "draft" && (
                    <>
                      <Separator />
                      <StyledInvoiceEmailActivityCard
                        invoiceId={invoiceId}
                        refreshTimestamp={refreshTimestamp}
                      />
                    </>
                  )}
                  <Separator size={48} />
                  {invoice.status !== "draft" ? (
                    <>
                      {(invoice.status === "open" ||
                        invoice.status === "uncollectible") && (
                        <>
                          <StyledButton
                            appearance="ghost"
                            onPress={() =>
                              setChangeInvoiceStatusModalVisible(true)
                            }
                          >
                            Change invoice status
                          </StyledButton>
                          <Separator size="small" />
                        </>
                      )}
                      <StyledButton
                        appearance="ghost"
                        onPress={async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              invoice.invoice_pdf
                            );
                          } catch (error) {
                            addNotification({
                              message: error.message,
                              title: "Invoice PDF failed",
                            });
                          }
                        }}
                      >
                        Download invoice PDF
                      </StyledButton>
                      <Separator size="small" />
                      <StyledButton
                        appearance="ghost"
                        onPress={async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              invoice.hosted_invoice_url
                            );
                          } catch (error) {
                            addNotification({
                              message: error.message,
                              title: "View payment page failed",
                            });
                          }
                        }}
                      >
                        View payment page
                      </StyledButton>
                    </>
                  ) : (
                    <>
                      <Separator size="small" />
                      <StyledButton
                        appearance="ghost"
                        loading={deleteInvoicePending}
                        onPress={() => setDeleteInvoiceModalVisible(true)}
                        status="danger"
                      >
                        Delete draft
                      </StyledButton>
                    </>
                  )}
                  <Separator />
                  <InvoiceEditingModal
                    invoice={invoice}
                    isVisible={invoiceEditingModalVisible}
                    onClose={async (stale) => {
                      setInvoiceEditingModalVisible(false);
                      if (stale) {
                        dispatch(setInvoicesStale({ invoicesStale: true }));
                        setLoading(true);
                        setInvoice(null);
                        await runRetrieveInvoice();
                        setLoading(false);
                      }
                    }}
                    onSendInvoiceOnFinalizeChange={setSendInvoiceOnFinalize}
                    sendInvoiceOnFinalize={sendInvoiceOnFinalize}
                  />
                  <ChangeInvoiceStatusModal
                    invoice={invoice}
                    isVisible={changeInvoiceStatusModalVisible}
                    onClose={() => setChangeInvoiceStatusModalVisible(false)}
                    onSuccess={(updatedInvoice) => {
                      setInvoice(updatedInvoice);
                      dispatch(setInvoicesStale({ invoicesStale: true }));
                      setChangeInvoiceStatusModalVisible(false);
                    }}
                  />
                  <AlertModal
                    confirmText="Delete draft"
                    isVisible={deleteInvoiceModalVisible}
                    message="Are you sure you want to permanently delete this draft invoice? This cannot be undone."
                    onClose={() => setDeleteInvoiceModalVisible(false)}
                    onConfirm={async () => {
                      setDeleteInvoiceModalVisible(false);
                      setDeleteInvoicePending(true);
                      try {
                        await deleteInvoice(invoiceId);
                        dispatch(setInvoicesStale({ invoicesStale: true }));
                        navigation.goBack();
                      } catch (error) {
                        addNotification({
                          message: error?.message || error,
                          status: "danger",
                          title: "Delete invoice failed",
                        });
                      }
                      setDeleteInvoicePending(false);
                    }}
                  />
                  <PostmarkResponsesModal
                    isVisible={postmarkModal.isVisible}
                    loading={sendInvoicePending || sendInvoiceReceiptPending}
                    onClose={() =>
                      setPostmarkModal((prevState) => ({
                        ...prevState,
                        isVisible: false,
                      }))
                    }
                    postmarkMessageSendingResponses={postmarkModal.responses}
                  />
                </>
              ) : (
                <EmptyScreenContainer>
                  <Separator />
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Text appearance="hint" category="s1">
                      No results found
                    </Text>
                  )}
                </EmptyScreenContainer>
              )}
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
    </>
  );
};

export default InvoiceDetails;
