import { createSelector } from "@reduxjs/toolkit";

import selectEmailActivity from "../selectEmailActivity";

const selectEmailActivityEntities = createSelector(
  [selectEmailActivity],
  (emailActivities) => emailActivities.entities
);

export default selectEmailActivityEntities;
