import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectJobEntities";

const selectJobsByIds = createSelector(
  [selectEntities, (_, ids: Array<string>) => ids],
  (entities, ids) => ids.map((id) => entities[id])
);

export default selectJobsByIds;
