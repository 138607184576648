import polylineUtil from "@mapbox/polyline";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import mapSelectionTypes, {
  MapSelectionTypes,
} from "../../../constants/mapSelectionTypes";

interface Coordinates {
  latitude: number;
  longitude: number;
  altitude?: number;
  accuracy?: number;
  altitudeAccuracy?: number;
  heading?: number;
  speed?: number;
  timestamp: number;
}

export interface State {
  coordinates?: Coordinates;
  follow?: string;
  id?: string;
  polylineCoordinates?: Array<{ latitude: number; longitude: number }>;
  type?: MapSelectionTypes;
}

const initialState: State = {
  type: null,
  id: null,
  polylineCoordinates: null,
  follow: null,
  coordinates: null,
};

const slice = createSlice({
  name: "mapSelection",
  initialState,
  reducers: {
    followSelf: (state) => {
      state.follow = "self";
    },
    clearFollow: (state) => {
      state.follow = null;
    },
    selectJob: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.type = mapSelectionTypes.job;
      state.id = id;
      state.follow = null;
    },
    selectUser: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.type = mapSelectionTypes.user;
      state.id = id;
      state.follow = null;
    },
    setPolyline: (state, action: PayloadAction<{ polyline: string }>) => {
      const { polyline } = action.payload;
      const temp = polylineUtil.decode(polyline);
      state.polylineCoordinates = temp.map((coord) => ({
        latitude: coord[0],
        longitude: coord[1],
      }));
    },
    setCoordinates: (
      state,
      action: PayloadAction<{
        coordinates: Coordinates;
      }>
    ) => {
      const { coordinates } = action.payload;
      state.coordinates = coordinates;
    },
    clearMapSelection: (state) => {
      state.type = null;
      state.polylineCoordinates = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const {
  clearFollow,
  clearMapSelection,
  followSelf,
  setCoordinates,
  selectJob,
  setPolyline,
  selectUser,
} = actions;

export default reducer;
