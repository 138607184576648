import gql from "graphql-tag";

import { incidentReportFragment } from "../../../fragments";

const updateIncidentReport = gql`
  mutation updateIncidentReport($input: IncidentReportInput!) {
    updateIncidentReport(input: $input) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default updateIncidentReport;
