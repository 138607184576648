import gql from "graphql-tag";

import {
  emailActivityFragment,
  jobFragment,
  timecardFragment,
} from "../../../fragments";

export default gql`
  query queryDateTimeData(
    $from: AWSDateTime
    $to: AWSDateTime
    $type: DateTimeDataTypes
    $after: ID
  ) {
    queryDateTimeData(from: $from, to: $to, type: $type, after: $after) {
      __typename
      nextToken
      items {
        ... on Job {
          ...jobFragment
        }
        ... on Timecard {
          ...timecardFragment
        }
        ... on EmailActivity {
          ...emailActivityFragment
        }
      }
    }
  }
  ${jobFragment}
  ${timecardFragment}
  ${emailActivityFragment}
`;
