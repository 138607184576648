import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation adminUpdateCompanyUser(
    $firstName: String!
    $lastName: String!
    $role: CompanyUserRoles!
    $id: ID!
  ) {
    adminUpdateCompanyUser(
      firstName: $firstName
      lastName: $lastName
      role: $role
      id: $id
    ) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
