import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { sync } from "./actions";
import actionItemsReducer from "../actionItems/slice";
import { resetUser } from "../auth/slice";
import companyReducer from "../company";
import customersReducer from "../customers/slice";
import groupsReducer from "../groups/slice";
import jobsReducer from "../jobs/slice";
import sitesReducer from "../sites/slice";
import timeCardsReducer from "../timeCards/slice";
import usersReducer from "../users/slice";
import inboxReducer from "../inbox/slice";

const dataLoadingReducer = createReducer(false, (builder) => {
  builder.addCase(sync.pending, () => true);
  builder.addCase(sync.fulfilled, () => false);
  builder.addCase(sync.rejected, () => {
    return false;
  });
  builder.addCase(PURGE, () => false);
});

const lastSyncReducer = createReducer(null, (builder) => {
  builder.addCase(sync.fulfilled, (state, action) => {
    const { lastSync } = action.payload;
    return lastSync;
  });
  builder.addCase(resetUser.fulfilled, () => null);
  builder.addCase(PURGE, () => null);
});

const dataReducer = combineReducers({
  loading: dataLoadingReducer,
  lastSync: lastSyncReducer,
  actionItems: actionItemsReducer,
  company: companyReducer,
  customers: customersReducer,
  groups: groupsReducer,
  jobs: jobsReducer,
  sites: sitesReducer,
  timeCards: timeCardsReducer,
  users: usersReducer,
  inbox: inboxReducer,
});

export default dataReducer;
