import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { useEffect, useState } from "react";

dayjs.extend(duration);

const getTimeString = (dateTime: string): string => {
  const hours = Math.floor(dayjs().diff(dayjs(dateTime), "hour"));
  let stringHours = hours.toString(10);

  // add leading 0 if needed
  if (hours < 10) {
    stringHours = `${0}${stringHours}`;
  }
  return `${stringHours}${dayjs
    .duration(dayjs().diff(dayjs(dateTime)))
    .format(":mm:ss")}`;
};

const useTimerText = (dateTime: string) => {
  const [text, setText] = useState(getTimeString(dateTime));
  useEffect(() => {
    setText(getTimeString(dateTime));
    const interval = setInterval(() => setText(getTimeString(dateTime)), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dateTime]);
  return text;
};

export default useTimerText;
