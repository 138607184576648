import { Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, View } from "react-native";

import UiKittenTouchable from "../../UiKittenTouchable";

export interface Props {
  dateTime: string;
  onPress: (dateTime: string) => void;
  selected: boolean;
}

const styles = StyleSheet.create({
  button: {
    height: 40,
    width: 40,
  },
  date: { alignItems: "center" },
  dayOfMonth: {
    alignItems: "center",
    borderRadius: 8,
    height: 40,
    justifyContent: "center",
    overflow: "hidden",
    width: 40,
  },
});

const DefaultDay = ({ dateTime, onPress, selected }: Props) => {
  const theme = useTheme();
  const day = dayjs(dateTime);
  return (
    <View style={styles.date}>
      <UiKittenTouchable
        onPress={() => onPress(dateTime)}
        style={styles.button}
      >
        <View
          style={[
            styles.dayOfMonth,
            {
              backgroundColor: selected
                ? theme["color-primary-transparent-default"]
                : undefined,
              borderColor: selected
                ? theme["color-primary-default"]
                : undefined,
              borderWidth: selected ? 1 : 0,
            },
          ]}
        >
          <Text>{day.format("D")}</Text>
        </View>
      </UiKittenTouchable>
    </View>
  );
};

export default DefaultDay;
