import gql from "graphql-tag";

import { companyFragment } from "../../../fragments";

export default gql`
  mutation adminUpdateCompany(
    $name: String!
    $logo: MediaInput
    $jobSettings: JobSettingsInput
    $customersEnabled: Boolean
  ) {
    adminUpdateCompany(
      name: $name
      logo: $logo
      jobSettings: $jobSettings
      customersEnabled: $customersEnabled
    ) {
      ...companyFragment
    }
  }
  ${companyFragment}
`;
