import moment from "moment-timezone";
import { rrulestr } from "rrule";

import { Job } from "../../types";

const getEndTime = ({ nextStartDateTime, endDateTime, startDateTime }) => {
  const startMoment = moment.utc(startDateTime);
  const endMoment = moment.utc(endDateTime);
  const durationMinutes = endMoment.diff(startMoment, "minutes", true);

  return moment
    .utc(nextStartDateTime)
    .add(durationMinutes, "minutes")
    .toISOString();
};

const createNextRepeatingJob = ({
  nextId,
  job,
}: {
  nextId?: string;
  job: Job;
}): Job => {
  const { startDateTime, endDateTime } = job;
  if (!job.rrule) return null;
  const rrule = rrulestr(job.rrule);
  const startMoment = moment.utc(startDateTime);
  let nextStartDateTime = rrule.after(
    new Date(
      Date.UTC(
        startMoment.year(),
        startMoment.month(),
        startMoment.date(),
        startMoment.hours(),
        startMoment.minutes()
      )
    )
  );

  let nextEndDateTime = getEndTime({
    nextStartDateTime,
    endDateTime,
    startDateTime,
  });

  const currentMoment = moment.utc();
  const currentDateTime = currentMoment.toISOString();

  if (nextEndDateTime < currentDateTime) {
    nextStartDateTime = rrule.after(
      new Date(
        Date.UTC(
          currentMoment.year(),
          currentMoment.month(),
          currentMoment.date(),
          currentMoment.hours(),
          currentMoment.minutes()
        )
      ),
      true
    );

    nextEndDateTime = getEndTime({
      nextStartDateTime,
      endDateTime,
      startDateTime,
    });
  }

  const { siteId, assignedTo, tasks, created, updated, syncId } = job;
  return {
    id: nextId,
    syncId,
    siteId,
    assignedTo,
    created,
    updated,
    syncDateTime: currentDateTime,
    startDateTime: nextStartDateTime.toISOString(),
    endDateTime: nextEndDateTime,
    startJobRecords: [],
    taskCompletionRecords: [],
    tasks,
    isDeleted: false,
    rrule: job.rrule,
  };
};

export default createNextRepeatingJob;
