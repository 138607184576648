import { Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, Pressable } from "react-native";
import { useSelector } from "react-redux";

import jobStatuses from "../../../constants/jobStatuses";
import selectEntities from "../../../store/jobs/selectors/selectJobEntities";

const styles = StyleSheet.create({
  markerContainer: {
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    justifyContent: "center",
    paddingHorizontal: 12,
    paddingVertical: 2,
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
});

interface JobClusterMarkerProps {
  id: string;
  leaves: Array<string>;
  text: string;
  onPress: (id: string) => void;
}

const JobClusterMarker = ({
  id,
  leaves,
  onPress,
  text,
}: JobClusterMarkerProps) => {
  const theme = useTheme();
  const jobs = useSelector(selectEntities);
  let highestPriority = 0;
  let color = jobStatuses.future.colors.default;
  leaves.forEach((leafId) => {
    const { priority } = jobs[leafId].status;
    if (priority > highestPriority) {
      highestPriority = priority;
      color = jobs[leafId].status.colors.default;
    }
  });
  const handlePress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress(id);
  };
  return (
    <Pressable
      onPress={handlePress}
      style={[styles.markerContainer, { backgroundColor: theme[color] }]}
    >
      <Text>{text}</Text>
    </Pressable>
  );
};

export default JobClusterMarker;
