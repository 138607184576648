import React from "react";
import { View } from "react-native";
import { ListItem, useTheme, CheckBox } from "@ui-kitten/components";
import PropTypes from "prop-types";

const CheckboxListItem = ({ title, checked, onChange }) => {
  const theme = useTheme();

  return (
    <ListItem
      title={title}
      accessoryRight={(props) => (
        <View pointerEvents="none">
          <CheckBox {...props} checked={checked} />
        </View>
      )}
      onPress={onChange}
      titleStyle={{ color: theme["text-hint-color"] }}
    />
  );
};

CheckboxListItem.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxListItem;
