import { Text } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";

import { StripeExpandedQuoteLineItem } from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import ListItem, { ListItemProps } from "../Base";

interface QuoteLineItemListItemProps
  extends Pick<ListItemProps, "accessoryRight" | "disabled"> {
  line_item: StripeExpandedQuoteLineItem;
  onPress?: (line_item: StripeExpandedQuoteLineItem) => void;
}

const Root = styled.View`
  flex-direction: row;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const TitleDescriptionContainer = styled.View`
  flex: 1;
`;

const ValuesContainer = styled.View`
  align-items: flex-end;
`;

const QuoteLineItemListItem = ({
  accessoryRight,
  disabled,
  line_item,
  onPress,
}: QuoteLineItemListItemProps) => {
  return (
    <ListItem
      accessoryRight={accessoryRight}
      disabled={disabled}
      onPress={onPress && (() => onPress(line_item))}
      title={() => (
        <Root>
          <TitleDescriptionContainer>
            <StyledText>{line_item.price.product.name}</StyledText>
            <StyledText appearance="hint" category="c1">
              {`Qty: ${line_item.quantity}`}
            </StyledText>
          </TitleDescriptionContainer>
          <ValuesContainer>
            <StyledText>
              {formatCurrency(line_item.price.unit_amount)}
            </StyledText>
            {line_item.price.metadata.taxesEnabled === "false" && (
              <StyledText appearance="hint">No tax</StyledText>
            )}
          </ValuesContainer>
        </Root>
      )}
    />
  );
};

export default QuoteLineItemListItem;
