import { CheckBox, Divider, Icon, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

import useJobStatusColors from "../../../hooks/useJobStatusColors";
import useTimeRange from "../../../hooks/useTimeRange";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import selectTimeFormat from "../../../store/settings/selectors/selectTimeFormat";
import selectSiteById from "../../../store/sites/selectors/selectById";
import AssigneesText from "../../AssigneesText";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import JobStatusCompactLabels from "../../JobStatusCompactLabels";
import UiKittenTouchable from "../../UiKittenTouchable";

interface ScheduleJobListItemProps {
  assigneesVisible?: boolean;
  checked?: boolean;
  enableCheckbox?: boolean;
  jobId: string;
  onPress?: (jobId: string) => void;
  showFullDate?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const height = 214;

const styles = StyleSheet.create({
  assignees: {
    width: "66%",
  },
  endDateTime: {
    flex: 1,
    textAlign: "right",
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconTextContainer: { alignItems: "center", flexDirection: "row" },
  jobStatusDivider: {
    borderRadius: 1,
    height: 2,
  },
  root: {
    paddingLeft: 16,
    paddingRight: 16,
    flexDirection: "row",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  rrule: {
    flex: 1,
    textAlign: "right",
  },
  siteAddress: {
    flex: 2,
  },
  siteName: {
    flex: 2,
  },
  startDateTime: {
    flex: 1,
    textAlign: "right",
  },
});

const ScheduleJobListItem = ({
  assigneesVisible = true,
  checked = false,
  enableCheckbox = false,
  jobId,
  onPress = () => null,
  showFullDate = false,
  style,
}: ScheduleJobListItemProps) => {
  const theme = useTheme();

  const job = useSelector((state) => selectJobById(state, jobId));
  const site = useSelector((state) => selectSiteById(state, job.siteId));
  const timeFormat = useSelector(selectTimeFormat);

  const jobStatusColors = useJobStatusColors(job);
  const timeRange = useTimeRange(job);

  const { address, name } = site;
  const { assignedTo, endDateTime, startDateTime } = job;

  const formattedTime = !endDateTime
    ? dayjs(startDateTime).format(timeFormat)
    : `${dayjs(startDateTime).format(timeFormat)} - ${dayjs(endDateTime).format(
        timeFormat
      )}`;

  return (
    <UiKittenTouchable
      checked={checked}
      onPress={() => onPress(jobId)}
      style={style}
    >
      <Card style={styles.root}>
        {enableCheckbox && (
          <View
            style={[
              {
                justifyContent: "center",
                alignContent: "center",
                marginRight: 16,
              },
            ]}
          >
            <CheckBox checked={checked} />
          </View>
        )}
        <View style={[{ flex: 1 }]}>
          <Separator size="medium" />
          <JobStatusCompactLabels jobId={jobId} />
          <Separator size="medium" />
          <Text category="h6" numberOfLines={1} style={styles.siteName}>
            {name}
          </Text>
          <Text
            appearance="hint"
            category="s1"
            numberOfLines={1}
            style={styles.siteAddress}
          >
            {address}
          </Text>
          <Separator size="medium" />
          <Divider
            style={[
              styles.jobStatusDivider,
              { backgroundColor: jobStatusColors.default },
            ]}
          />
          <Separator size="medium" />
          <View style={styles.iconTextContainer}>
            <Icon
              name={showFullDate ? "calendar-outline" : "clock-outline"}
              style={[
                styles.icon,
                {
                  tintColor: theme["text-basic-color"],
                },
              ]}
            />
            <Separator horizontal size="small" />
            <Text category="s1">
              {showFullDate ? timeRange : formattedTime}
            </Text>
          </View>
          {assigneesVisible && (
            <>
              <Separator size="small" />
              <View style={styles.iconTextContainer}>
                <Icon
                  name="person-outline"
                  style={[
                    styles.icon,
                    {
                      tintColor: theme["text-basic-color"],
                    },
                  ]}
                />
                <Separator horizontal size="small" />
                <AssigneesText
                  assignees={assignedTo}
                  category="s1"
                  numberOfLines={1}
                  style={styles.assignees}
                />
              </View>
            </>
          )}
          <Separator size="medium" />
        </View>
      </Card>
    </UiKittenTouchable>
  );
};

export default ScheduleJobListItem;
