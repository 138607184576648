import uuidv4 from "uuid/v4";

import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setSitePending } from "../../../store/sites/slice";
import deepCopy from "../../utils/deepCopy";

const dispatchOptimisticResponse = ({
  id,
  coordinates,
  address,
  name,
  notes,
  privateNotes,
  geofence,
  timezone,
}) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  const timestamp = new Date().toISOString();
  dispatch(
    setSitePending({
      mutation: "adminCreateCompanySite",
      site: {
        id,
        syncDateTime: timestamp,
        created: {
          at: timestamp,
          by: userId,
        },
        updated: {
          at: timestamp,
          by: userId,
        },
        isArchived: false,
        coordinates,
        address,
        name,
        geofence,
        privateNotes,
        notes,
        timezone,
      },
    })
  );
};

const adminCreateCompanySite = ({
  id = uuidv4(),
  coordinates,
  address,
  name,
  notes,
  privateNotes,
  geofence,
  timezone,
}) => {
  dispatchOptimisticResponse({
    id,
    coordinates,
    address,
    name,
    notes,
    privateNotes,
    geofence,
    timezone,
  });
  client.mutate({
    mutation,
    variables: deepCopy({
      id,
      coordinates,
      address,
      name,
      notes,
      privateNotes,
      geofence,
      timezone,
    }),
    errorPolicy: "all",
  });
};

export default adminCreateCompanySite;
