import { JobSettings } from "../../../types";

const mapToJobSettingsInput = (jobSettings?: JobSettings) =>
  jobSettings
    ? { ...jobSettings }
    : {
        dailyActivityReportsEnabled: true,
        incidentReportsEnabled: true,
      };

export default mapToJobSettingsInput;
