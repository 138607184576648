/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";

import Tooltip, { Props as TooltipProps } from "../Tooltip";

export interface Props extends TooltipProps {
  visible: boolean;
}

const ConditionalTooltip = ({
  children,
  title,
  visible,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <>
      {visible && title ? (
        <Tooltip {...props} title={title}>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
};

export default ConditionalTooltip;
