/* eslint-disable react/jsx-props-no-spreading */
import { ListItemProps, useTheme } from "@ui-kitten/components";
import React from "react";

import BaseListItem from "../Base";
import UiKittenTouchable from "../../UiKittenTouchable";

interface Props extends ListItemProps {
  onPress?: () => void;
}

const LevelTwoListItem = ({ disabled, onPress, style, ...props }: Props) => {
  const theme = useTheme();
  return (
    <UiKittenTouchable disabled={disabled} onPress={onPress} style={style}>
      <BaseListItem
        {...props}
        disabled
        style={{
          backgroundColor: theme["background-basic-color-2"],
        }}
      />
    </UiKittenTouchable>
  );
};

export default LevelTwoListItem;
