import { RRule } from "rrule";
import moment from "moment-timezone";
import repeatByWeekday from "../../constants/repeatByWeekday";

const getFreq = (frequency) => {
  switch (frequency) {
    case "weekly":
      return RRule.WEEKLY;
    case "daily":
      return RRule.DAILY;
    default:
      throw new Error(`Frequency ${frequency} is unsupported`);
  }
};

const momentDaysToUtcDays = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];

const getRRule = ({ startDateTime, frequency, byWeekday }) => {
  if (!frequency) return null;

  const input = {};

  input.freq = getFreq(frequency);
  const startMoment = moment(startDateTime);
  const utcStartMoment = moment.utc(startDateTime);
  input.dtstart = new Date(
    Date.UTC(
      utcStartMoment.year(),
      utcStartMoment.month(),
      utcStartMoment.date(),
      utcStartMoment.hours(),
      utcStartMoment.minutes()
    )
  );
  if (Array.isArray(byWeekday) && byWeekday.length > 0) {
    input.byweekday = byWeekday.map((weekday) => {
      const momentDay = repeatByWeekday[weekday].momentValue;
      const localized = startMoment.clone().weekday(momentDay);
      const utcDay = localized.clone().tz("UTC").weekday();
      return momentDaysToUtcDays[utcDay];
    });
  }

  return new RRule(input).toString();
};

export default getRRule;
