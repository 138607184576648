import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";
import { useSelector } from "react-redux";

import BottomTabBar from "../../components/BottomTabBar";
import HistoricalSearchScreen from "../../screens/HistoricalSearch";
import Jobs from "../../screens/Jobs";
import Timecard from "../../screens/TimeCard";
import selectCompany from "../../store/company/selectors/selectCompany";
import { BottomTabParamList } from "../../types";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

const Tab = createBottomTabNavigator<BottomTabParamList>();

const MainBottomTab = () => {
  const company = useSelector(selectCompany);

  const timeCardsEnabled =
    !company || isFeatureEnabled({ company, feature: "timeCards" });

  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false, lazy: false }}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <Tab.Screen name="UpcomingJobs" component={Jobs} />
      <Tab.Screen name="HistoricalSearch" component={HistoricalSearchScreen} />

      {timeCardsEnabled && <Tab.Screen name="TimeCard" component={Timecard} />}
    </Tab.Navigator>
  );
};

export default MainBottomTab;
