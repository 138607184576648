import gql from "graphql-tag";

import { stripeExpandedQuoteFragment } from "../../../fragments";

const createQuote = gql`
  mutation createQuote(
    $customer: ID
    $default_tax_rates: [ID]
    $line_items: [StripeLineItemInput]
  ) {
    createQuote(
      customer: $customer
      default_tax_rates: $default_tax_rates
      line_items: $line_items
    ) {
      ...stripeExpandedQuoteFragment
    }
  }
  ${stripeExpandedQuoteFragment}
`;

export default createQuote;
