const repeatFrequencies = {
  weekly: {
    text: "Weekly",
    value: "weekly",
  },
  daily: {
    text: "Daily",
    value: "daily",
  },
};
export default repeatFrequencies;
