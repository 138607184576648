import { createDrawerNavigator } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Layout, Spinner } from "@ui-kitten/components";
import * as Notifications from "expo-notifications";
import React, { useEffect, useState } from "react";
import { Animated, StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import MainBottomTab from "../MainBottomTab";
import CustomDrawer from "../../components/Drawer";
import selectDataLoading from "../../store/data/selectors/selectDataLoading";
import { DrawerParamList, ScreenNames, StackParamList } from "../../types";

const Drawer = createDrawerNavigator<DrawerParamList>();

interface NavigationPayload {
  params?: any;
  route: ScreenNames;
}

const styles = StyleSheet.create({
  fadeRoot: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
  },
  loadingRoot: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

const MainDrawer = () => {
  const { navigate } = useNavigation<
    StackNavigationProp<StackParamList, "MainDrawer">
  >();
  const dataLoading = useSelector(selectDataLoading);
  const [renderContent, setRenderContent] = useState(false);
  const [loadingOverlayMounted, setLoadingOverlayMounted] = useState(true);
  const [fadeAnim] = useState(new Animated.Value(1));

  useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const { navigation } = response.notification.request.content.data;
        if (navigation) {
          const { route, params } = navigation as NavigationPayload;
          switch (route) {
            case "MainBottomTab":
              navigate("MainDrawer", { params, screen: route });
              break;
            case "ChannelList":
            case "HistoricalSearch":
            case "UpcomingJobs":
            case "TimeCard":
              navigate("MainDrawer", {
                params: {
                  params,
                  screen: route,
                },
                screen: "MainBottomTab",
              });
              break;
            default:
              navigate(route, params);
              break;
          }
        }
      }
    );
    return () => subscription.remove();
  });

  useEffect(() => {
    if (!dataLoading && !renderContent) {
      setRenderContent(true);
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => setLoadingOverlayMounted(false));
    }
  }, [dataLoading, fadeAnim, renderContent]);
  return (
    <>
      {renderContent && (
        <Drawer.Navigator
          drawerContent={({ navigation }) => (
            <CustomDrawer navigation={navigation} />
          )}
          screenOptions={{
            headerShown: false,
            swipeEnabled: false,
          }}
        >
          <Drawer.Screen name="MainBottomTab" component={MainBottomTab} />
        </Drawer.Navigator>
      )}
      {loadingOverlayMounted && (
        <Animated.View style={[styles.fadeRoot, { opacity: fadeAnim }]}>
          <Layout style={styles.loadingRoot}>
            <Spinner />
          </Layout>
        </Animated.View>
      )}
    </>
  );
};

export default MainDrawer;
