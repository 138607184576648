import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation updateCompanyUserStatus(
    $speed: Float
    $heading: Float
    $createdAt: AWSDateTime!
    $coordinates: CoordinatesInput!
  ) {
    updateCompanyUserStatus(
      speed: $speed
      heading: $heading
      createdAt: $createdAt
      coordinates: $coordinates
    ) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
