import { useNavigation } from "@react-navigation/native";
import { Divider, Icon, Spinner, Text } from "@ui-kitten/components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

import listQuotes from "../../api/functions/listQuotes";
import { StripeQuote } from "../../types";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Card from "../Card";
import QuoteListItem from "../listItems/Quote";

interface CustomerQuotesCardProps {
  stripeCustomerId: string;
  style?: StyleProp<ViewStyle>;
}

const SpinnerContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const CustomerQuotesCard = ({
  stripeCustomerId,
  style,
}: CustomerQuotesCardProps) => {
  const navigation = useNavigation();

  const [quotes, setQuotes] = useState<{
    data: Array<StripeQuote>;
    has_more: boolean;
  }>();

  const [listQuotesPending, setListQuotesPending] = useState(false);
  const [listQuotesError, setListQuotesError] = useState<Error>();

  const runListQuotes = useCallback(async (customer: string) => {
    setListQuotesPending(true);
    try {
      setQuotes(await listQuotes({ customer }));
    } catch (error) {
      setListQuotesError(error);
    }
    setListQuotesPending(false);
  }, []);
  const navigateToQuoteDetails = useCallback(
    (quote: { id: string }) => {
      navigation.navigate("QuoteDetails", { quoteId: quote.id });
    },
    [navigation]
  );

  useEffect(() => {
    runListQuotes(stripeCustomerId);
  }, [stripeCustomerId]);

  return (
    <Card style={style} title="Quotes">
      {listQuotesPending ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {listQuotesError || quotes?.data.length === 0 ? (
            <StyledText appearance="hint">
              {listQuotesError
                ? `Error: ${listQuotesError.message}`
                : "No quotes"}
            </StyledText>
          ) : (
            quotes?.data.map((quote, index) => (
              <Fragment key={quote.id}>
                {index > 0 && <StyledDivider />}
                <QuoteListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward" />
                  )}
                  hideCustomerName
                  onPress={navigateToQuoteDetails}
                  quote={quote}
                />
              </Fragment>
            ))
          )}
        </>
      )}
      {quotes?.has_more && (
        <>
          <StyledDivider />
          <Separator size="medium" />
          <StyledButton
            appearance="ghost"
            onPress={() =>
              navigation.navigate("Quotes", { customerId: stripeCustomerId })
            }
          >
            View all
          </StyledButton>
          <Separator size="medium" />
        </>
      )}
    </Card>
  );
};

export default CustomerQuotesCard;
