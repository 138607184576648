import { ListItemProps } from "@ui-kitten/components";
import React from "react";
import { useSelector } from "react-redux";

import selectCustomerByStripeCustomerId from "../../../store/customers/selectors/selectCustomerByStripeCustomerId";
import { Customer, StripeCustomer } from "../../../types";
import CustomerListItem from "../Customer";

interface Props
  extends Pick<
    ListItemProps,
    "accessoryLeft" | "accessoryRight" | "disabled" | "style"
  > {
  onPress?: (customer: Customer) => void;
  stripeCustomer: string | StripeCustomer;
}

const StripeCustomerListItem = ({
  accessoryLeft,
  accessoryRight,
  stripeCustomer,
  disabled,
  onPress,
  style,
}: Props) => {
  const stripeCustomerId =
    typeof stripeCustomer === "string" ? stripeCustomer : stripeCustomer.id;
  const customer = useSelector((state) =>
    selectCustomerByStripeCustomerId(state, stripeCustomerId)
  );
  return (
    <CustomerListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      customerId={customer.id}
      disabled={disabled}
      onPress={onPress}
      style={style}
    />
  );
};

export default StripeCustomerListItem;
