import gql from "graphql-tag";

import { customerFragment } from "../../../fragments";

export default gql`
  mutation archiveCustomer($id: ID!) {
    archiveCustomer(id: $id) {
      ...customerFragment
    }
  }
  ${customerFragment}
`;
