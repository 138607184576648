import { ListItemProps, Text } from "@ui-kitten/components";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { StyleSheet, View } from "react-native";

import BaseListItem from "../Base";
import selectGroupById from "../../../store/groups/selectors/selectGroupById";
import Flair from "../../Flair";

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingEnd: 8,
    paddingStart: 8,
  },
});

interface Props
  extends Pick<ListItemProps, "accessoryLeft" | "accessoryRight" | "disabled"> {
  groupId: string;
  onPress?: (groupId: string) => void;
}

const GroupListItem = ({
  accessoryLeft,
  accessoryRight,
  disabled,
  groupId,
  onPress,
}: Props) => {
  const group = useSelector((state) => selectGroupById(state, groupId));
  const membersCount = Array.isArray(group.members) ? group.members.length : 0;
  return (
    <BaseListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      description={`${membersCount} member${membersCount > 1 ? "s" : ""}`}
      disabled={disabled}
      onPress={() => {
        if (onPress) {
          onPress(groupId);
        }
      }}
      title={() => (
        <View style={styles.titleContainer}>
          <Text category="s2" style={{ paddingEnd: 8 }}>
            {group.name}
          </Text>
          {group.isDeleted && <Flair status="danger">deleted</Flair>}
        </View>
      )}
    />
  );
};

GroupListItem.defaultProps = {
  onPress: undefined,
};

export default memo(GroupListItem);
