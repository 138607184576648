import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

const createStartJobRecord = gql`
  mutation createStartJobRecord(
    $jobId: ID!
    $timestamp: AWSDateTime!
    $coordinates: CoordinatesInput!
  ) {
    createStartJobRecord(
      jobId: $jobId
      timestamp: $timestamp
      coordinates: $coordinates
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export default createStartJobRecord;
