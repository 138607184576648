import {
  combineReducers,
  configureStore,
  MiddlewareArray,
} from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import localforage from "localforage";
import { Platform } from "react-native";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import thunk from "redux-thunk";

import appInfoReducer from "./appInfo/slice";
import authReducer from "./auth/slice";
import dataReducer from "./data";
import historicalSearchReducer from "./historicalSearch/slice";
import mapSelectionReducer from "./mapSelection/slice";
import permissionsReducer from "./permissions/slice";
import settingsReducer, { SettingsState } from "./settings/slice";
import emailActivityReducer from "./emailActivity/slice";
import tutorialsReducer from "./tutorials/slice";
import paymentsReducer from "./payments/slice";

const storage = Platform.OS === "web" ? localforage : AsyncStorage;

export interface State {
  settings: SettingsState;
}

const rootReducer = combineReducers({
  appInfo: persistReducer(
    {
      blacklist: ["chatReady"],
      key: "appInfo",
      storage,
    },
    appInfoReducer
  ),
  auth: authReducer,
  data: persistReducer(
    {
      blacklist: ["loading"],
      key: "data",
      storage,
    },
    dataReducer
  ),
  emailActivity: emailActivityReducer,
  historicalSearch: historicalSearchReducer,
  mapSelection: mapSelectionReducer,
  payments: paymentsReducer,
  permissions: permissionsReducer,
  settings: settingsReducer,
  tutorials: tutorialsReducer,
});

const persistedReducer = persistReducer(
  {
    blacklist: ["auth", "data", "payments"],
    key: "root",
    stateReconciler: autoMergeLevel1,
    storage,
    version: 0,
  },
  rootReducer
);

const store = configureStore({
  devTools: {
    name: "talarium",
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = new MiddlewareArray().concat(thunk);
    if (__DEV__) {
      // https://github.com/reduxjs/redux-toolkit/blob/master/docs/api/getDefaultMiddleware.md
      middleware.push(getDefaultMiddleware()[1]); // immutableStateInvariant
      // middleware.push(logger);
      // middleware.push(getDefaultMiddleware()[2]); // serializableStateInvariant
    }
    return middleware;
  },
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
