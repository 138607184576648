import {
  Button,
  Divider,
  Icon,
  Layout,
  Text,
  Toggle,
  useTheme,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { rrulestr } from "rrule";
import uuidv4 from "uuid/v4";

import createJob from "../../../api/functions/createJob";
import updateJob from "../../../api/functions/updateJob";
import assigneeTypes from "../../../constants/assigneeTypes";
import repeatFrequencies from "../../../constants/repeatFrequencies";
import rruleFreqToRepeatFrequency from "../../../constants/rruleFreqToRepeatFrequency";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import selectSites from "../../../store/sites/selectors/selectSites";
import { Assignee, Task } from "../../../types";
import convertUtcRRuleToLocalWeekdays from "../../../utils/convertUtcRRuleToLocal";
import getLocalByWeekdayRRuleDescription from "../../../utils/getLocalByWeekdayRRuleDescription";
import getRRule from "../../../utils/getRRule";
import validateJobDateTimes from "../../../utils/validateJobDateTimes";
import TimeRangePicker from "../../BasicTimeRangePicker";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import ButtonListItem from "../../listItems/Button";
import GroupListItem from "../../listItems/Group";
import SiteListItem from "../../listItems/Site";
import TaskListItem from "../../listItems/Task/TaskListItem";
import UserListItem from "../../listItems/User";
import SectionItem from "../../SectionItem";
import AlertModal from "../Alert";
import AssigneesSelectModal from "../AssigneesSelectModal";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import RRuleModal from "../RRule";
import SiteEditingModal from "../SiteEditing";
import SiteSelectModal from "../SiteSelect";
import TaskEditingModal from "../TaskEditing";

interface FormValues {
  assignedTo: Array<Assignee>;
  endDateTime?: string;
  frequency?: string;
  immediate: boolean;
  siteId: string;
  startDateTime: string;
  tasks: {
    [taskId: string]: Task;
  };
  weekdays: Array<string>;
  includeCreatorInMessages?: boolean;
  autostart?: boolean;
}

interface InitialValues
  extends Partial<Omit<FormValues, "frequency" | "tasks" | "weekdays">> {
  id?: string;
  rrule?: string;
  tasks?: Array<Task>;
}

export interface Props
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  initialValues?: InitialValues;
}

const checkRequiredValues = ({ input }) => {
  if (!input.siteId) {
    throw new Error("Location required");
  }
  if (!input.immediate && !input.startDateTime) {
    throw new Error("Start time required");
  }
  if (!input.immediate && !input.endDateTime) {
    throw new Error("End time required");
  }
};

const getValues = (initialValues?: InitialValues): FormValues => ({
  assignedTo: initialValues?.assignedTo || [],
  endDateTime: initialValues?.endDateTime,
  frequency: initialValues?.rrule
    ? rruleFreqToRepeatFrequency[rrulestr(initialValues.rrule).origOptions.freq]
    : undefined,
  immediate: initialValues?.immediate || false,
  siteId: initialValues?.siteId,
  startDateTime: initialValues?.startDateTime,
  tasks: Array.isArray(initialValues?.tasks)
    ? initialValues.tasks.reduce(
        (accumulator, task) => ({ ...accumulator, [task.id]: task }),
        {}
      )
    : {},
  weekdays: initialValues?.rrule
    ? convertUtcRRuleToLocalWeekdays({
        utcRRule: initialValues.rrule,
      })
    : [],
  includeCreatorInMessages: initialValues?.includeCreatorInMessages ?? true,
  autostart: Boolean(initialValues?.autostart),
});

const styles = StyleSheet.create({
  addButtonContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  button: {
    justifyContent: "flex-start",
  },
  divider: {
    marginStart: 16,
  },
  horizontalMargins: {
    marginEnd: 16,
    marginStart: 16,
  },
  horizontalSeparator: {
    width: 8,
  },
  listAccessory: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginEnd: 8,
    marginStart: 8,
  },
  listItemAccessoryButton: {
    marginEnd: 8,
    marginStart: 8,
  },
  root: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  sectionSeparator: {
    height: 24,
  },
  sectionTitle: {
    paddingEnd: 16,
    paddingStart: 16,
    paddingBottom: 4,
  },
  separator: {
    height: 16,
  },
});

const JobEditingModal = ({ initialValues, isVisible, onClose }: Props) => {
  const insets = useSafeAreaInsets();
  const sites = useSelector(selectSites);
  const theme = useTheme();

  const updateExistingJob = Boolean(initialValues?.id);

  const formik = useFormik({
    initialValues: getValues(initialValues),
    onSubmit: (values) => {
      try {
        checkRequiredValues({ input: values });
        // immediate job values
        let endDateTime = null;
        let startDateTime = new Date().toISOString();
        if (!values.immediate) {
          const validatedTime = validateJobDateTimes({
            startDateTime: values.startDateTime,
            endDateTime: values.endDateTime,
          });
          endDateTime = validatedTime.endDateTime;
          startDateTime = validatedTime.startDateTime;
        }
        const input = {
          id: uuidv4(),
          siteId: values.siteId,
          assignedTo: values.assignedTo,
          tasks: Object.values(values.tasks),
          startDateTime,
          endDateTime,
          rrule: values.immediate
            ? null
            : getRRule({
                startDateTime,
                frequency: values.frequency,
                byWeekday: values.weekdays,
              }),
          immediate: values.immediate ? values.immediate : false,
          includeCreatorInMessages: values.includeCreatorInMessages,
          autostart: values.autostart,
        };
        if (updateExistingJob) {
          updateJob({
            ...input,
            id: initialValues.id,
          });
          addNotification({
            status: "success",
            title: "Job updated",
          });
        } else {
          createJob(input);
          addNotification({
            status: "success",
            title: "Job created",
          });
        }
        onClose();
      } catch (error) {
        addNotification({
          message: (error && error.message) || error,
          status: "danger",
          title: updateExistingJob ? "Update failed" : "Creation failed",
        });
      }
    },
  });

  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(
    false
  );
  const [rRuleEditingVisible, setRRuleEditingVisible] = useState(false);
  const [sitePickerVisible, setSitePickerVisible] = useState(false);
  const [createSiteVisible, setCreateSiteVisible] = useState(false);
  const [assigneesSelectVisible, setAssigneesSelectVisible] = useState(false);
  const [assigneesWarningVisible, setAssigneesWarningVisible] = useState(false);
  const [taskEditing, setTaskEditing] = useState({
    task: null,
    visible: false,
  });
  const siteCoordinates = {
    lat: sites.entities[formik.values.siteId]?.coordinates.lat,
    lng: sites.entities[formik.values.siteId]?.coordinates.lng,
  };

  const handleUrgencyToggle = () =>
    formik.setFieldValue("immediate", !formik.values.immediate);
  const handleBackPress = () => {
    if (formik.dirty) {
      setCloseConfirmationVisible(true);
    } else {
      onClose();
    }
  };
  useAndroidBackHandler({ enabled: isVisible, onBackPress: handleBackPress });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getValues(initialValues),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleJobSubmit = () => {
    const nowDateTime = new Date().toISOString();
    if (!updateExistingJob && formik.values.startDateTime < nowDateTime) {
      addNotification({
        status: "danger",
        title: "Error",
        message: "The start date and time must be in the future.",
      });
    } else if (formik.values.endDateTime < formik.values.startDateTime) {
      addNotification({
        status: "danger",
        title: "Error",
        message:
          "The start date and time must be before the end date and time.",
      });
    } else if (formik.values.assignedTo.length === 0) {
      setAssigneesWarningVisible(true);
    } else {
      formik.handleSubmit();
    }
  };

  const tasks = Object.values(formik.values.tasks);
  const handleRemoveAssignee = (id) => (
    <View style={styles.listAccessory}>
      <Button
        onPress={() =>
          formik.setFieldValue(
            "assignedTo",
            formik.values.assignedTo.filter((assignee) => assignee.id !== id)
          )
        }
        size="small"
        status="danger"
      >
        Remove
      </Button>
    </View>
  );
  const repeatDisabled = !formik.values.startDateTime;

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          navigation={{
            "data-test": "closeButtonInJobEditingModal",
            icon: "close-outline",
            onPress: handleBackPress,
          }}
          title={updateExistingJob ? "Edit job" : "Create job"}
          action={{
            "data-test": "submitButtonInJobEditingModal",
            loading: false,
            onPress: handleJobSubmit,
            text: updateExistingJob ? "Done" : "Add",
          }}
        />
        <Divider />
        <Layout level="2" style={styles.root}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: insets.bottom + 8 }}
          >
            <Separator />
            <SectionItem title="Site">
              {formik.values.siteId ? (
                <>
                  <SiteListItem
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="arrow-ios-forward-outline" />
                    )}
                    onPress={() => setSitePickerVisible(true)}
                    siteId={formik.values.siteId}
                  />
                </>
              ) : (
                <ButtonListItem
                  data-test="selectJobSiteButtonInJobEditingModal"
                  icon="search"
                  onPress={() => setSitePickerVisible(true)}
                  title="Select location"
                />
              )}
              <Divider style={styles.divider} />
              <ButtonListItem
                icon="plus"
                title="Create location"
                onPress={() => setCreateSiteVisible(true)}
              />
            </SectionItem>
            <Separator />
            <SectionItem title="Urgency">
              <ButtonListItem
                icon="alert-circle"
                title="ASAP"
                onPress={handleUrgencyToggle}
                accessoryRight={() => (
                  <Toggle
                    checked={formik.values.immediate}
                    onChange={handleUrgencyToggle}
                  />
                )}
              />
            </SectionItem>
            {!formik.values.immediate && (
              <>
                <Separator />
                <SectionItem title="TIME">
                  <TimeRangePicker
                    divider={<Divider style={styles.divider} />}
                    endDateTime={formik.values.endDateTime}
                    onValueChange={({ startDateTime, endDateTime }) => {
                      formik.setFieldValue("startDateTime", startDateTime);
                      formik.setFieldValue("endDateTime", endDateTime);
                    }}
                    startDateTime={formik.values.startDateTime}
                  />
                  <Divider style={styles.divider} />
                  <ButtonListItem
                    title="Repeats"
                    icon="repeat"
                    accessoryRight={() => (
                      <View style={styles.listAccessory}>
                        <Text
                          appearance={repeatDisabled ? "hint" : "default"}
                          ellipsizeMode="tail"
                          numberOfLines={1}
                        >
                          {repeatDisabled
                            ? "Start time required"
                            : _.upperFirst(
                                getLocalByWeekdayRRuleDescription({
                                  frequency: formik.values.frequency,
                                  byWeekday: formik.values.weekdays,
                                  startDateTime: formik.values.startDateTime,
                                })
                              )}
                        </Text>
                        {!repeatDisabled && (
                          <>
                            <View style={styles.horizontalSeparator} />
                            <Icon
                              name="arrow-ios-forward-outline"
                              fill={theme["text-hint-color"]}
                              height={24}
                              width={24}
                            />
                          </>
                        )}
                      </View>
                    )}
                    onPress={() => setRRuleEditingVisible(true)}
                    disabled={repeatDisabled}
                  />
                  <Divider style={styles.divider} />
                  <ButtonListItem
                    icon="clock"
                    title="Start automatically at start time"
                    onPress={() =>
                      formik.setFieldValue(
                        "autostart",
                        !formik.values.autostart
                      )
                    }
                    accessoryRight={() => (
                      <Toggle
                        checked={formik.values.autostart}
                        onChange={() =>
                          formik.setFieldValue(
                            "autostart",
                            !formik.values.autostart
                          )
                        }
                      />
                    )}
                  />
                </SectionItem>
              </>
            )}
            <Separator />
            <SectionItem title="ASSIGNED TO">
              {formik.values.assignedTo.length > 0 && (
                <>
                  {[...formik.values.assignedTo]
                    .sort((a, b) => {
                      if (
                        a.type === assigneeTypes.user &&
                        b.type === assigneeTypes.group
                      ) {
                        return 1;
                      }
                      if (
                        a.type === assigneeTypes.group &&
                        b.type === assigneeTypes.user
                      ) {
                        return -1;
                      }
                      return 0;
                    })
                    .map(({ id, type }, index) => {
                      if (type === assigneeTypes.user) {
                        return (
                          <Fragment key={id}>
                            {index > 0 && <Divider style={styles.divider} />}
                            <UserListItem
                              accessoryLeft={(imageProps) => (
                                <Icon {...imageProps} name="person-outline" />
                              )}
                              accessoryRight={() => handleRemoveAssignee(id)}
                              disabled
                              userId={id}
                            />
                          </Fragment>
                        );
                      }
                      return (
                        <Fragment key={id}>
                          {index > 0 && <Divider style={styles.divider} />}
                          <GroupListItem
                            accessoryLeft={(imageProps) => (
                              <Icon {...imageProps} name="people-outline" />
                            )}
                            accessoryRight={() => handleRemoveAssignee(id)}
                            disabled
                            groupId={id}
                          />
                        </Fragment>
                      );
                    })}
                  <Divider />
                </>
              )}
              <ButtonListItem
                data-test="assigneesButtonInJobEditingModal"
                onPress={() => setAssigneesSelectVisible(true)}
                title="Set assignees"
                icon="people"
                disabled={!formik.values.siteId}
                accessoryRight={() =>
                  !formik.values.siteId && (
                    <View style={styles.listAccessory}>
                      <Text
                        appearance="hint"
                        ellipsizeMode="tail"
                        numberOfLines={1}
                      >
                        Location required
                      </Text>
                    </View>
                  )
                }
              />
            </SectionItem>
            <Separator />
            <SectionItem title="CHECKLIST">
              {tasks.length > 0 && (
                <>
                  {tasks.map((task, index) => (
                    <View key={task.id}>
                      {index !== 0 && <Divider style={styles.divider} />}
                      <TaskListItem
                        accessoryRight={() => (
                          <Button
                            onPress={() => {
                              const nextValue = { ...formik.values.tasks };
                              delete nextValue[task.id];
                              formik.setFieldValue("tasks", nextValue);
                            }}
                            size="small"
                            status="danger"
                            style={styles.listItemAccessoryButton}
                          >
                            Delete
                          </Button>
                        )}
                        onPress={() => setTaskEditing({ task, visible: true })}
                        task={task}
                      />
                    </View>
                  ))}
                  <Divider />
                </>
              )}
              <ButtonListItem
                title="Add item"
                icon="plus"
                onPress={() =>
                  setTaskEditing({
                    task: null,
                    visible: true,
                  })
                }
              />
            </SectionItem>
            <Separator />
          </ScrollView>
        </Layout>
      </FullScreenModal>
      <AlertModal
        isVisible={closeConfirmationVisible}
        onClose={() => setCloseConfirmationVisible(false)}
        message="Discard your changes?"
        confirmText="Discard"
        onConfirm={() => {
          setCloseConfirmationVisible(false);
          onClose();
        }}
        cancelText="Keep editing"
        onCancel={() => setCloseConfirmationVisible(false)}
      />
      <AlertModal
        isVisible={assigneesWarningVisible}
        onClose={() => setAssigneesWarningVisible(false)}
        message="No assignees selected, do you want to continue?"
        confirmText="Yes"
        onConfirm={() => {
          setAssigneesWarningVisible(false);
          formik.handleSubmit();
        }}
        cancelText="No"
        onCancel={() => setAssigneesWarningVisible(false)}
      />
      <SiteSelectModal
        headerTitle="Choose a location"
        isVisible={sitePickerVisible}
        onClose={() => setSitePickerVisible(false)}
        onSubmit={(siteId) => {
          formik.setFieldValue("siteId", siteId);
          setSitePickerVisible(false);
        }}
        selected={formik.values.siteId}
      />
      <SiteEditingModal
        isVisible={createSiteVisible}
        onClose={() => setCreateSiteVisible(false)}
        onSubmit={(siteId) => {
          formik.setFieldValue("siteId", siteId);
          setCreateSiteVisible(false);
        }}
      />
      <AssigneesSelectModal
        isVisible={assigneesSelectVisible}
        onClose={() => setAssigneesSelectVisible(false)}
        onSelectedChange={(assignees) => {
          formik.setFieldValue("assignedTo", assignees);
          setAssigneesSelectVisible(false);
        }}
        selected={formik.values.assignedTo}
        destination={siteCoordinates}
      />
      <RRuleModal
        byWeekday={formik.values.weekdays}
        frequency={formik.values.frequency}
        isVisible={rRuleEditingVisible}
        onSubmit={({ frequency, weekdays }) => {
          if (frequency !== repeatFrequencies.weekly.value) {
            formik.setFieldValue("weekdays", []);
          } else {
            formik.setFieldValue("weekdays", weekdays);
          }
          formik.setFieldValue("frequency", frequency);
          setRRuleEditingVisible(false);
        }}
        onClose={() => setRRuleEditingVisible(false)}
      />
      <TaskEditingModal
        isVisible={taskEditing.visible}
        onClose={() =>
          setTaskEditing((prevState) => ({ ...prevState, visible: false }))
        }
        onSubmit={(task) => {
          formik.setFieldValue(`tasks[${task.id}]`, task);
          setTaskEditing((prevState) => ({ ...prevState, visible: false }));
        }}
        task={taskEditing.task}
      />
    </>
  );
};

JobEditingModal.defaultProps = {
  initialValues: undefined,
};

export default JobEditingModal;
