import { Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import selectCustomerByStripeCustomerId from "../../../store/customers/selectors/selectCustomerByStripeCustomerId";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import { StripeQuote } from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import Separator from "../../buildingBlocks/Separator";
import StripeQuoteStatusText from "../../StripeQuoteStatusText";
import ListItem, { ListItemProps } from "../Base";

interface QuoteListItemProps extends Pick<ListItemProps, "accessoryRight"> {
  hideCustomerName?: boolean;
  onPress?: (quote: StripeQuote) => void;
  quote: StripeQuote;
}

const TextContainer = styled.View`
  padding-left: 8px;
  padding-right: 8px;
`;

const TitleContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

const QuoteListItem = ({
  accessoryRight,
  hideCustomerName,
  onPress,
  quote,
}: QuoteListItemProps) => {
  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const customer = useSelector((state) =>
    selectCustomerByStripeCustomerId(state, quote.customer)
  );

  const currency = quote.currency || "usd";

  return (
    <ListItem
      accessoryRight={accessoryRight}
      onPress={onPress && (() => onPress(quote))}
      title={() => (
        <TextContainer>
          <TitleContainer>
            <Text category="s1">{`${formatCurrency(
              quote.amount_total
            )} ${currency.toUpperCase()}`}</Text>
            <Separator horizontal size="small" />
            <StripeQuoteStatusText quote={quote} />
          </TitleContainer>
          {!hideCustomerName && (
            <Text appearance="hint">{customer?.name || "\u2014"}</Text>
          )}
          <Text appearance="hint">
            {dayjs.unix(quote.created).format(dateTimeFormat)}
          </Text>
        </TextContainer>
      )}
    />
  );
};

export default QuoteListItem;
