import gql from "graphql-tag";

import { stripePriceFragment } from "../../../fragments";

const listPrices = gql`
  query listPrices($active: Boolean, $starting_after: ID) {
    listPrices(active: $active, starting_after: $starting_after) {
      data {
        ...stripePriceFragment
      }
      has_more
    }
  }
  ${stripePriceFragment}
`;

export default listPrices;
