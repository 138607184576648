import { useNavigation } from "@react-navigation/native";
import { Divider, Spinner, Text } from "@ui-kitten/components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import queryCustomerEmailActivity from "../../api/functions/queryCustomerEmailActivity";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { EmailActivity } from "../../types";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Card from "../Card";
import EmailActivityListItem from "../listItems/EmailActivity";

interface CustomerEmailActivityCardProps {
  customerId: string;
  style?: StyleProp<ViewStyle>;
}

const SpinnerContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const CustomerEmailActivityCard = ({
  customerId,
  style,
}: CustomerEmailActivityCardProps) => {
  const navigation = useNavigation();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [emailActivityFeed, setEmailActivityFeed] = useState<{
    items: Array<EmailActivity>;
    nextToken?: string;
  }>();

  const [
    queryCustomerEmailActivityPending,
    setQueryCustomerEmailActivityPending,
  ] = useState(false);
  const [
    queryCustomerEmailActivityError,
    setQueryCustomerEmailActivityError,
  ] = useState<Error>();

  const runQueryCustomerEmailActivity = useCallback(async (id: string) => {
    setQueryCustomerEmailActivityPending(true);
    try {
      setEmailActivityFeed(await queryCustomerEmailActivity({ id }));
    } catch (error) {
      setQueryCustomerEmailActivityError(error);
    }
    setQueryCustomerEmailActivityPending(false);
  }, []);

  useEffect(() => {
    runQueryCustomerEmailActivity(customerId);
  }, [customerId]);

  return (
    <Card style={style} title="Email activity">
      {queryCustomerEmailActivityPending ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {queryCustomerEmailActivityError ||
          emailActivityFeed?.items.length === 0 ? (
            <>
              <Separator size="medium" />
              <StyledText appearance="hint">
                {queryCustomerEmailActivityError
                  ? `Error: ${
                      queryCustomerEmailActivityError?.message ||
                      queryCustomerEmailActivityError
                    }`
                  : "No email activity"}
              </StyledText>
              <Separator size="medium" />
            </>
          ) : (
            <>
              {emailActivityFeed?.items.map((emailActivity, index) => (
                <Fragment key={emailActivity.id}>
                  {index > 0 && <StyledDivider />}
                  <EmailActivityListItem
                    emailActivity={emailActivity}
                    timestampFormat={dateTimeFormat}
                  />
                </Fragment>
              ))}
              {emailActivityFeed?.nextToken && (
                <>
                  <StyledDivider />
                  <Separator size="medium" />
                  <StyledButton
                    appearance="ghost"
                    onPress={() =>
                      navigation.navigate("CustomerEmailActivity", {
                        customerId,
                      })
                    }
                  >
                    View all
                  </StyledButton>
                  <Separator size="medium" />
                </>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default CustomerEmailActivityCard;
