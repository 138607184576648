import gql from "graphql-tag";

import { stripeExpandedInvoiceFragment } from "../../../fragments";

const voidInvoice = gql`
  mutation voidInvoice($id: ID!) {
    voidInvoice(id: $id) {
      ...stripeExpandedInvoiceFragment
    }
  }
  ${stripeExpandedInvoiceFragment}
`;

export default voidInvoice;
