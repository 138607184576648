import gql from "graphql-tag";

const getTutorialMedia = gql`
  query getTutorialMedia($id: ID!) {
    getTutorialMedia(id: $id) {
      url
    }
  }
`;

export default getTutorialMedia;
