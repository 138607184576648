import { createSelector } from "@reduxjs/toolkit";

import selectHistoricalSearch from "../selectHistoricalSearch";

const selectFilters = createSelector(
  [selectHistoricalSearch],
  (historicalSearch) => historicalSearch.filters
);

export default selectFilters;
