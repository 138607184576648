import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation adminCreateCompanyUser(
    $phone: AWSPhone
    $email: AWSEmail
    $firstName: String!
    $lastName: String!
    $role: CompanyUserRoles!
  ) {
    adminCreateCompanyUser(
      phone: $phone
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
    ) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
