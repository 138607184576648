import { CompanyUserRole } from "../../types";

interface Params {
  permittedRoles: Array<CompanyUserRole>;
  userRole?: CompanyUserRole;
}

const userRolePermitted = ({ permittedRoles, userRole }: Params) =>
  permittedRoles.includes(userRole);

export default userRolePermitted;
