import { useTheme } from "@ui-kitten/components";
import React, { useState } from "react";
import { FAB } from "react-native-paper";

import BottomTabActionsModal from "../modals/BottomTabActions";
import CreateUserModal from "../modals/CreateUser";
import DispatchUserJobStepperModal from "../modals/DispatchUserJobStepper";
import GroupEditingModal from "../modals/GroupEditing";
import JobEditingModal from "../modals/JobEditing";
import SiteEditingModal from "../modals/SiteEditing";

type CreationModalTypes =
  | "list"
  | "job"
  | "user"
  | "location"
  | "group"
  | "dispatch"
  | null;

const CreateFab = () => {
  const theme = useTheme();

  const [showCreation, setShowCreation] = useState<CreationModalTypes>(null);
  const [showActions, setShowActions] = useState<boolean>(false);

  const handleCloseCreationModal = () => setShowCreation(null);

  return (
    <>
      <FAB
        color="white"
        icon="plus"
        onPress={() => setShowActions(true)}
        style={{
          backgroundColor: theme["color-primary-default"],
        }}
      />
      <GroupEditingModal
        onClose={handleCloseCreationModal}
        isVisible={showCreation === "group"}
      />
      <SiteEditingModal
        isVisible={showCreation === "location"}
        onClose={handleCloseCreationModal}
        onSubmit={handleCloseCreationModal}
      />
      <CreateUserModal
        onSuccess={handleCloseCreationModal}
        onClose={handleCloseCreationModal}
        isVisible={showCreation === "user"}
      />
      <DispatchUserJobStepperModal
        isVisible={showCreation === "dispatch"}
        onClose={handleCloseCreationModal}
        onSuccess={() => {
          handleCloseCreationModal();
          setShowActions(false);
        }}
      />
      <JobEditingModal
        isVisible={showCreation === "job"}
        onClose={handleCloseCreationModal}
      />
      <BottomTabActionsModal
        isVisible={showActions}
        onClose={() => setShowActions(false)}
        onCreateLocation={() => {
          setShowCreation("location");
          setShowActions(false);
        }}
        onCreateGroup={() => {
          setShowCreation("group");
          setShowActions(false);
        }}
        onCreateJob={() => {
          setShowCreation("job");
          setShowActions(false);
        }}
        onCreateUser={() => {
          setShowCreation("user");
          setShowActions(false);
        }}
        onDispatch={() => {
          setShowCreation("dispatch");
          setShowActions(false);
        }}
      />
    </>
  );
};

export default CreateFab;
