import { Platform } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";

import useKeyboardVisible from "../useKeyboardVisible";

const useIosBottomSpace = () => {
  const insets = useSafeArea();
  const keyboardVisible = useKeyboardVisible();

  return Platform.OS === "ios" && !keyboardVisible ? insets.bottom : 0;
};

export default useIosBottomSpace;
