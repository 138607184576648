import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import { Customer, EntityState, OptimisticCustomer } from "../../../types";

const adapter = createEntityAdapter<Customer>();
const optimisticAdapter = createEntityAdapter<OptimisticCustomer>();

export interface State extends EntityState<Customer> {
  optimistic: EntityState<OptimisticCustomer>;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addCustomer = ({
  customer,
  state,
}: {
  customer: Customer;
  state: State;
}) => {
  const existingCustomer = state.entities[customer.id];
  if (
    existingCustomer === undefined ||
    customer.syncDateTime > existingCustomer.syncDateTime
  ) {
    adapter.upsertOne(state, customer);
  }
};

const name = "customer";

export const setCustomerAsync = {
  fulfilled: createAction<{
    customer: Customer;
    mutation: string;
  }>(`${name}/setCustomer/fulfilled`),
  pending: createAction<{
    customer: Customer;
    mutation: string;
  }>(`${name}/setCustomer/pending`),
  rejected: createAction(
    `${name}/setCustomer/rejected`,
    (payload: { id: string; mutation: string }, error: Error) => ({
      error,
      payload,
    })
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      const { customer } = action.payload;
      addCustomer({ customer, state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCustomerAsync.pending, (state, action) => {
      const { customer, mutation } = action.payload;
      const existingOptimisticCustomer = state.optimistic.entities[customer.id];
      if (
        existingOptimisticCustomer === undefined ||
        customer.syncDateTime > existingOptimisticCustomer.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...customer,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setCustomerAsync.fulfilled, (state, action) => {
      const { customer, mutation } = action.payload;
      addCustomer({ customer, state });
      const existingOptimisticCustomer = state.optimistic.entities[customer.id];
      if (
        existingOptimisticCustomer !== undefined &&
        mutation === existingOptimisticCustomer.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, customer.id);
      }
    });
    builder.addCase(setCustomerAsync.rejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticCustomer = state.optimistic.entities[id];
      if (
        existingOptimisticCustomer !== undefined &&
        mutation === existingOptimisticCustomer.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { customers, lastSync } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, customers);
      } else {
        customers.forEach((customer) => addCustomer({ customer, state }));
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const { setCustomer } = actions;

export default reducer;
