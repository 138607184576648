import Auth from "@aws-amplify/auth";
import {
  Divider,
  Icon,
  Text,
  TopNavigation,
  TopNavigationAction,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import * as yup from "yup";

import FullScreenModal from "../FullScreen";
import LoadingButton from "../../LoadingButton";
import initSession from "../../../utils/initSession";
import PasswordInput from "../../inputs/Password";

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
  },
  form: {
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  description: {
    paddingBottom: 16,
    paddingTop: 8,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingTop: 8,
  },
});

interface CompleteNewPasswordModalProps {
  isVisible: boolean;
  onClose: () => void;
  newUser: any;
}

const CompleteNewPasswordModal = ({
  isVisible,
  onClose,
  newUser,
}: CompleteNewPasswordModalProps) => {
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters.")
        .required("Password is required."),
    }),
    onSubmit: async (values, actions) => {
      const { password } = values;
      try {
        await Auth.completeNewPassword(newUser, password);
        initSession();
      } catch (error) {
        formik.setFieldError("password", error.message);
        actions.setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <TopNavigation
        title="Welcome"
        alignment="center"
        accessoryLeft={() => (
          <TopNavigationAction
            icon={(props) => <Icon {...props} name="close-outline" />}
            onPress={onClose}
          />
        )}
      />
      <Divider />
      <ScrollView contentContainerStyle={styles.form} style={styles.scroll}>
        <Text style={styles.description}>Set a new password.</Text>
        <PasswordInput
          onBlur={() => formik.setFieldTouched("password")}
          onChangeText={formik.handleChange("password")}
          value={formik.values.password}
          status={formik.errors.password && formik.touched.password && "danger"}
          caption={formik.touched.password && formik.errors.password}
        />
      </ScrollView>
      <Divider />
      <SafeAreaView>
        <View style={styles.footer}>
          <LoadingButton
            onPress={formik.handleSubmit}
            disabled={
              !formik.values.password || Object.values(formik.errors).length > 0
            }
            loading={formik.isSubmitting}
          >
            Change
          </LoadingButton>
        </View>
      </SafeAreaView>
    </FullScreenModal>
  );
};

export default CompleteNewPasswordModal;
