import React, { memo } from "react";

import useCompletionRecordUtils from "../../../hooks/useCompletionRecordUtils";
import { Task, TaskCompletionRecord } from "../../../types";
import TaskListItem, { TaskListItemProps } from "../Task/TaskListItem";

export interface TaskCompletionRecordListItemProps
  extends Pick<TaskListItemProps, "style"> {
  disableThumbnail?: boolean;
  jobId: string;
  onPress?: ({
    task,
    taskCompletionRecord,
  }: {
    task: Task;
    taskCompletionRecord: TaskCompletionRecord;
  }) => void;
  readonly?: boolean;
  reportsLoading?: boolean;
  task: Task;
  taskCompletionRecord: TaskCompletionRecord;
}

const TaskCompletionRecordListItem: React.FC<TaskCompletionRecordListItemProps> = ({
  disableThumbnail,
  jobId,
  onPress,
  readonly = false,
  reportsLoading = false,
  style,
  task,
  taskCompletionRecord,
}) => {
  const { ListItemProps } = useCompletionRecordUtils({
    checklistItem: task,
    completionRecord: taskCompletionRecord,
    disableThumbnail,
    jobId,
    readonly,
    reportsLoading,
  });

  return (
    <TaskListItem
      accessoryLeft={ListItemProps.accessoryLeft}
      accessoryRight={ListItemProps.accessoryRight}
      description={ListItemProps.description}
      disabled={readonly}
      onPress={() => onPress({ task, taskCompletionRecord })}
      style={style}
      task={task}
      title={ListItemProps.title}
    />
  );
};

export default memo(TaskCompletionRecordListItem);
