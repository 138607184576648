import { Spinner, Text } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

import createStripeAccount from "../../api/functions/createStripeAccount";
import createStripeAccountLink from "../../api/functions/createStripeAccountLink";
import createStripeLoginLink from "../../api/functions/createStripeLoginLink";
import retrieveStripeAccount from "../../api/functions/retrieveStripeAccount";
import { setCompany } from "../../store/company";
import selectCompany from "../../store/company/selectors/selectCompany";
import { StripeAccount } from "../../types";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Card, { CardProps } from "../Card";
import { addNotification } from "../InAppNotifications";

type StripeConnectAccountCardProps = Pick<CardProps, "style">;

const SpinnerContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const StripeConnectAccountCard = ({ style }: StripeConnectAccountCardProps) => {
  const dispatch = useDispatch();

  const company = useSelector(selectCompany);
  const stripeConnectAccountId = company?.stripeConnectAccountId;

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [
    retrieveStripeAccountFailed,
    setRetrieveStripeAccountFailed,
  ] = useState(false);
  const [stripeAccount, setStripeAccount] = useState<StripeAccount>();

  const runRetrieveStripeAccount = useCallback(async () => {
    setRetrieveStripeAccountFailed(false);
    try {
      setStripeAccount(await retrieveStripeAccount());
    } catch (error) {
      setRetrieveStripeAccountFailed(true);
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve Stripe account failed",
      });
    }
  }, []);
  const handleLaunchStripeButtonPress = useCallback(() => {
    if (stripeAccount) {
      if (stripeAccount.details_submitted) {
        (async () => {
          setButtonLoading(true);
          try {
            await WebBrowser.openBrowserAsync(
              (await createStripeLoginLink()).url
            );
          } catch (error) {
            addNotification({
              message: error?.message || error,
              status: "danger",
              title: "Create Stripe login link failed",
            });
          }
          setButtonLoading(false);
        })();
      } else {
        (async () => {
          setButtonLoading(true);
          try {
            await WebBrowser.openBrowserAsync(
              (await createStripeAccountLink()).url
            );
            setLoading(true);
            setStripeAccount(null);
            await runRetrieveStripeAccount();
            setLoading(false);
          } catch (error) {
            addNotification({
              message: error?.message || error,
              status: "danger",
              title: "Create Stripe account link failed",
            });
          }
          setButtonLoading(false);
        })();
      }
    } else {
      (async () => {
        setButtonLoading(true);
        try {
          dispatch(setCompany({ company: await createStripeAccount() }));
          try {
            await WebBrowser.openBrowserAsync(
              (await createStripeAccountLink()).url
            );
            setLoading(true);
            setStripeAccount(null);
            await runRetrieveStripeAccount();
            setLoading(false);
          } catch (error) {
            addNotification({
              message: error?.message || error,
              status: "danger",
              title: "Create Stripe account link failed",
            });
          }
        } catch (error) {
          addNotification({
            message: error?.message || error,
            status: "danger",
            title: "Create Stripe account failed",
          });
        }
        setButtonLoading(false);
      })();
    }
  }, [stripeAccount]);

  useEffect(() => {
    if (stripeConnectAccountId) {
      (async () => {
        setLoading(true);
        setStripeAccount(null);
        await runRetrieveStripeAccount();
        setLoading(false);
      })();
    }
  }, [stripeConnectAccountId]);

  return (
    <Card
      style={style}
      title={stripeAccount?.details_submitted ? "Billing" : "Enable billing"}
    >
      {loading ? (
        <SpinnerContainer>
          <Separator />
          <Spinner />
          <Separator />
        </SpinnerContainer>
      ) : (
        <>
          {retrieveStripeAccountFailed ? (
            <>
              <Separator />
              <StyledText
                appearance="hint"
                category="s2"
                style={{ textAlign: "center" }}
              >
                Failed to load
              </StyledText>
              <Separator />
            </>
          ) : (
            <>
              <Separator size={12} />
              <StyledText appearance="hint" category="p2">
                Talarium uses Stripe to handle payments and to keep your
                personal bank and details secure.
              </StyledText>
              <Separator size="small" />
              <StyledText appearance="hint" category="p2">
                Press{" "}
                <Text appearance="hint" category="s2" status="primary">
                  Launch Stripe
                </Text>{" "}
                to
                {` ${
                  stripeAccount?.details_submitted
                    ? "manage your Stripe account"
                    : "setup your payments on Stripe"
                }`}
                .
              </StyledText>
              <Separator />
              <StyledButton
                loading={buttonLoading}
                onPress={handleLaunchStripeButtonPress}
              >
                Launch Stripe
              </StyledButton>
              <Separator size="medium" />
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default StripeConnectAccountCard;
