import React from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import ClockInButton from "../../components/ClockInButton";
import selectClockedIn from "../../store/users/selectors/selectClockedIn";

type Props = {
  disabled?: boolean;
};

const styles = StyleSheet.create({
  button: {
    marginStart: 8,
  },
});

const useClockInProps = ({ disabled }: Props) => {
  const clockedIn = useSelector(selectClockedIn);
  return {
    ConditionalTooltipProps: {
      accessoryRight: () => (
        <ClockInButton appearance="ghost" size="tiny" style={styles.button} />
      ),
      title: "You must be clocked in to perform this action.",
      visible: !clockedIn && !disabled,
    },
    disabled: !clockedIn && !disabled,
  };
};

export default useClockInProps;
