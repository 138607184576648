import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripePrice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdatePriceInput
  extends Pick<Stripe.PriceUpdateParams, "active" | "metadata"> {
  id: string;
}

const updatePrice = async ({ active, id, metadata }: UpdatePriceInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        active,
        id,
        metadata,
      },
    })) as GraphQLResult<{ updatePrice: StripePrice }>;
    return data.updatePrice;
  } catch ({ errors }) {
    throw generateErrorMessage("updatePrice", errors);
  }
};

export default updatePrice;
