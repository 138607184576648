import gql from "graphql-tag";

import { privateCompanySiteFragment } from "../../../fragments";

export default gql`
  mutation linkSiteToCustomer($siteId: ID!, $customerId: ID!) {
    linkSiteToCustomer(siteId: $siteId, customerId: $customerId) {
      ...privateCompanySiteFragment
    }
  }
  ${privateCompanySiteFragment}
`;
