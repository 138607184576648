import { Button, Icon } from "@ui-kitten/components";
import * as ImagePicker from "expo-image-picker";
import * as IntentLauncher from "expo-intent-launcher";
import * as Linking from "expo-linking";
import React, { useState } from "react";
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import Separator from "../buildingBlocks/Separator";
import AlertModal from "../modals/Alert";
import { MediaOnDevice } from "../../types";
import getMediaMetadata from "../../utils/getMediaMetadata";
import resizeImage from "../../utils/resizeImage";

export interface Props {
  onAddMedia: (media: MediaOnDevice) => void;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 100,
  },
  buttonContainer: {
    flex: 1,
  },
  root: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  text: {
    alignSelf: "center",
  },
});

const AddMediaButtons = ({ onAddMedia, style }: Props) => {
  const [permissionsAlertVisible, setPermissionsAlertVisible] = useState(false);
  const handleImagePickerResult = async (
    imagePickerResult: ImagePicker.ImagePickerResult
  ) => {
    if (!imagePickerResult.canceled) {
      const { createdAt, id, mimeType, type } = getMediaMetadata(
        // @ts-ignore
        imagePickerResult
      );
      try {
        const resizedMedia = await resizeImage(imagePickerResult);
        onAddMedia({
          createdAt,
          description: "",
          height: resizedMedia.height,
          id,
          mimeType,
          type,
          uploaded: false,
          uri: resizedMedia.uri,
          width: resizedMedia.width,
        });
      } catch (error) {
        onAddMedia({
          createdAt,
          description: "",
          // @ts-ignore
          height: imagePickerResult.height,
          id,
          mimeType,
          type,
          uploaded: false,
          // @ts-ignore
          uri: imagePickerResult.uri,
          // @ts-ignore
          width: imagePickerResult.width,
        });
      }
    }
  };
  return (
    <>
      <View style={[styles.root, style]}>
        {Platform.OS !== "web" && (
          <>
            <View style={styles.buttonContainer}>
              <Button
                accessoryLeft={(props) => <Icon name="camera" {...props} />}
                onPress={async () => {
                  const {
                    granted: cameraPermissionsGranted,
                  } = await ImagePicker.requestCameraPermissionsAsync();
                  const {
                    granted: mediaLibraryPermissionsGranted,
                  } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                  if (
                    cameraPermissionsGranted &&
                    mediaLibraryPermissionsGranted
                  ) {
                    await handleImagePickerResult(
                      await ImagePicker.launchCameraAsync({
                        base64: true,
                        exif: true,
                        mediaTypes: ImagePicker.MediaTypeOptions.Images,
                      })
                    );
                  } else {
                    setPermissionsAlertVisible(true);
                  }
                }}
                status="basic"
              >
                Camera
              </Button>
            </View>
            <Separator horizontal size="small" />
          </>
        )}
        <View style={styles.buttonContainer}>
          <Button
            accessoryLeft={(props) => <Icon name="image" {...props} />}
            onPress={async () => {
              const {
                granted: mediaLibraryPermissionsGranted,
              } = await ImagePicker.requestMediaLibraryPermissionsAsync();
              if (mediaLibraryPermissionsGranted) {
                await handleImagePickerResult(
                  await ImagePicker.launchImageLibraryAsync({
                    base64: true,
                    exif: true,
                  })
                );
              } else {
                setPermissionsAlertVisible(true);
              }
            }}
            status="basic"
          >
            Photos
          </Button>
        </View>
      </View>
      <AlertModal
        isVisible={permissionsAlertVisible}
        onClose={() => setPermissionsAlertVisible(false)}
        message={`This feature requires camera access. Please enable Camera and ${
          Platform.OS === "android" ? "Storage" : "Photos"
        } for Talarium in your settings.`}
        confirmText="Open settings"
        onConfirm={async () => {
          setPermissionsAlertVisible(false);
          if (Platform.OS === "android") {
            await IntentLauncher.startActivityAsync(
              IntentLauncher.ActivityAction.APPLICATION_SETTINGS
            );
          } else {
            await Linking.openURL("app-settings:");
          }
        }}
        cancelText="Not now"
        onCancel={() => setPermissionsAlertVisible(false)}
      />
    </>
  );
};

AddMediaButtons.defaultProps = {
  style: undefined,
};

export default AddMediaButtons;
