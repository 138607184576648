import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setCompanyAsync } from "../../../store/company";
import mapToJobSettingsInput from "../../utils/mapToJobSettingsInput";
import mapToMediaInput from "../../utils/mapToMediaInput";
import { Company, JobSettings, Media } from "../../../types";

interface AdminUpdateCompanyInput {
  name: string;
  logo: Media;
  jobSettings?: JobSettings;
  customersEnabled?: boolean;
}

const dispatchOptimisticResponse = (
  { customersEnabled, jobSettings, logo, name }: AdminUpdateCompanyInput,
  company: Company
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  dispatch(
    setCompanyAsync.pending({
      company: {
        ...company,
        customersEnabled,
        jobSettings,
        logo,
        name,
        updated: {
          at: new Date().toISOString(),
          by: userId,
        },
      },
      mutation: "adminUpdateCompany",
    })
  );
};

const adminUpdateCompany = (
  { customersEnabled, jobSettings, logo, name }: AdminUpdateCompanyInput,
  company: Company
) => {
  const adminUpdateCompanyInputDeepCopy: AdminUpdateCompanyInput = {
    customersEnabled: customersEnabled !== undefined ? customersEnabled : true,
    jobSettings: mapToJobSettingsInput(jobSettings),
    logo: logo && mapToMediaInput(logo),
    name,
  };
  dispatchOptimisticResponse(adminUpdateCompanyInputDeepCopy, company);
  client.mutate({
    mutation,
    variables: adminUpdateCompanyInputDeepCopy,
    errorPolicy: "all",
  });
};

export default adminUpdateCompany;
