import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MainScreen from "./MainScreen";
import PoliceCaseScreen from "./PoliceCaseScreen";
import SubjectScreen from "./SubjectScreen";
import VehicleScreen from "./VehicleScreen";
import AlertModal from "../../Alert";
import FullScreenModal from "../../FullScreen";
import MultiActionAlertModal from "../../MultiActionAlert";
import useModalScreens from "../../useModalScreens";
import { addNotification } from "../../../InAppNotifications";
import JobTextInputModalScreen from "../../../modalScreens/JobTextInput";
import adminDeleteIncidentReport from "../../../../api/functions/adminDeleteIncidentReport";
import adminUpdateIncidentReport from "../../../../api/functions/adminUpdateIncidentReport";
import deleteIncidentReport from "../../../../api/functions/deleteIncidentReport";
import submitIncidentReport from "../../../../api/functions/submitIncidentReport";
import updateIncidentReport from "../../../../api/functions/updateIncidentReport";
import selectSignedInUserRole from "../../../../store/users/selectors/selectSignedInUserRole";
import { IncidentReport, Media, Subject, Vehicle } from "../../../../types";
import isMediaOnDevice from "../../../../utils/isMediaOnDevice";
import userRolePermitted from "../../../../utils/userRolePermitted";

type FormValues = Pick<
  IncidentReport,
  | "endDateTime"
  | "media"
  | "policeCase"
  | "startDateTime"
  | "subjects"
  | "summary"
  | "vehicles"
>;

interface Props {
  isVisible: boolean;
  onClosePress: () => void;
  onDelete: () => void;
  onSubmit: () => void;
  incidentReport: IncidentReport;
}

const screensConfig = [
  ["main"],
  ["policeCase", "subject", "summary", "vehicle"],
];

const mapToMediaInput = (media: Media) => ({
  id: media.id,
  description: media.description,
  createdAt: media.createdAt,
  type: media.type,
});

const mapToSubjectInput = (subject: Subject) => ({
  name: subject.name,
  sex: subject.sex,
  ethnicity: subject.ethnicity,
  race: subject.race,
  phoneNumber: subject.phoneNumber,
  address: subject.address,
  license: subject.license
    ? {
        region: subject.license.region,
        number: subject.license.number,
      }
    : null,
  description: subject.description,
  type: subject.type,
  height: subject.height,
  weight: subject.weight,
  dateOfBirth: subject.dateOfBirth,
  age: subject.age,
  hairColor: subject.hairColor,
  eyeColor: subject.eyeColor,
});

const mapToVehicleInput = (vehicle: Vehicle) => ({
  description: vehicle.description,
  make: vehicle.make,
  model: vehicle.model,
  year: vehicle.year,
  color: vehicle.color,
  license: vehicle.license
    ? {
        region: vehicle.license.region,
        number: vehicle.license.number,
      }
    : null,
  vin: vehicle.vin,
  registeredOwner: vehicle.registeredOwner,
  type: vehicle.type,
  status: vehicle.status,
});

const getValues = (incidentReport: FormValues): FormValues => ({
  endDateTime: incidentReport.endDateTime,
  media: incidentReport.media.map(mapToMediaInput),
  policeCase: incidentReport.policeCase ? {} : undefined,
  startDateTime: incidentReport.startDateTime,
  subjects: incidentReport.subjects.map(mapToSubjectInput),
  summary: incidentReport.summary,
  vehicles: incidentReport.vehicles.map(mapToVehicleInput),
});

const useReportMutations = () => {
  const userRole = useSelector(selectSignedInUserRole);
  if (
    userRolePermitted({ permittedRoles: ["Admin", "Supervisor"], userRole })
  ) {
    return {
      deleteMutation: adminDeleteIncidentReport,
      updateMutation: adminUpdateIncidentReport,
    };
  }
  return {
    deleteMutation: deleteIncidentReport,
    updateMutation: updateIncidentReport,
  };
};

const useIncidentReportForm = ({
  isVisible,
  incidentReport,
}: {
  isVisible: boolean;
  incidentReport: IncidentReport;
}) => {
  const { deleteMutation, updateMutation } = useReportMutations();
  const formik = useFormik<FormValues>({
    initialValues: getValues(incidentReport),
    onSubmit: (values) => {
      updateMutation(
        {
          startDateTime: values.startDateTime,
          endDateTime: values.endDateTime,
          summary: values.summary,
          policeCase: values.policeCase,
          subjects: values.subjects,
          vehicles: values.vehicles,
          media: values.media.filter(
            (media) => !isMediaOnDevice(media) || media.uploaded
          ),
        },
        incidentReport
      );
      formik.resetForm({
        values,
      });
    },
  });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getValues(incidentReport),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return { deleteMutation, formik };
};

const IncidentReportEditingModal = ({
  incidentReport,
  isVisible,
  onClosePress,
  onDelete,
  onSubmit,
}: Props) => {
  const { deleteMutation, formik } = useIncidentReportForm({
    isVisible,
    incidentReport,
  });
  const { navigation, jumpTo, goBack } = useModalScreens(screensConfig);

  const [deleteBeforeExitVisible, setDeleteBeforeExitVisible] = useState(false);
  const [
    unsavedMediaBeforeSubmitVisible,
    setUnsavedMediaBeforeSubmitVisible,
  ] = useState(false);
  const [
    unsavedMediaBeforeCloseVisible,
    setUnsavedMediaBeforeCloseVisible,
  ] = useState(false);

  const jumpToSubject = (index) => {
    jumpTo("subject", {
      index,
    });
  };
  const jumpToVehicle = (index) => {
    jumpTo("vehicle", {
      index,
    });
  };

  const runSubmitIncidentReport = () => {
    submitIncidentReport({}, incidentReport);
    addNotification({
      status: "success",
      title: "Report submitted",
    });
    onSubmit();
  };

  if (!formik.values) {
    return null;
  }

  const screen = navigation.screens[navigation.state.index];

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClosePress}>
        {screen.name === "main" && (
          <MainScreen
            formik={formik}
            incidentReport={incidentReport}
            onClosePress={() => {
              if (
                incidentReport.created.at !== incidentReport.updated.at ||
                formik.values.startDateTime ||
                formik.values.endDateTime ||
                formik.values.summary ||
                formik.values.policeCase ||
                formik.values.subjects.length > 0 ||
                formik.values.vehicles.length > 0 ||
                formik.values.media.length > 0
              ) {
                if (
                  formik.values.media.filter(
                    (media) => isMediaOnDevice(media) && !media.uploaded
                  ).length > 0
                ) {
                  setUnsavedMediaBeforeCloseVisible(true);
                } else {
                  onClosePress();
                }
              } else if (
                !incidentReport.startDateTime &&
                !incidentReport.endDateTime &&
                !incidentReport.summary &&
                !incidentReport.policeCase &&
                incidentReport.subjects.length === 0 &&
                incidentReport.vehicles.length === 0 &&
                incidentReport.media.length === 0
              ) {
                setDeleteBeforeExitVisible(true);
              }
            }}
            onDateTimesChange={formik.handleSubmit}
            onDeletePress={() => {
              deleteMutation(incidentReport);
              addNotification({
                status: "success",
                title: "Report deleted",
              });
              onDelete();
            }}
            onMediaChange={formik.handleSubmit}
            onPoliceCasePress={() => jumpTo("policeCase")}
            onSubjectPress={jumpToSubject}
            onSummaryPress={() => jumpTo("summary")}
            onSubmitPress={() => {
              if (
                formik.values.media.filter(
                  (media) => isMediaOnDevice(media) && !media.uploaded
                ).length > 0
              ) {
                setUnsavedMediaBeforeSubmitVisible(true);
              } else {
                runSubmitIncidentReport();
              }
            }}
            onVehiclePress={jumpToVehicle}
            visible
          />
        )}
        {screen.name === "policeCase" && (
          <PoliceCaseScreen
            onBackPress={goBack}
            onSubmit={(policeCase) => {
              formik.setFieldValue("policeCase", policeCase);
              formik.handleSubmit();
              goBack();
            }}
            policeCase={formik.values.policeCase}
            title={
              formik.values.policeCase ? "Edit police case" : "Add police case"
            }
            visible
          />
        )}
        {screen.name === "summary" && (
          <JobTextInputModalScreen
            initialText={formik.values.summary}
            jobId={incidentReport.jobId}
            onBackPress={goBack}
            onSubmitPress={(text) => {
              formik.setFieldValue("summary", text);
              formik.handleSubmit();
              goBack();
            }}
            title={formik.values.summary ? "Edit summary" : "Add summary"}
            visible
          />
        )}
        {screen.name === "subject" && (
          <SubjectScreen
            onBackPress={goBack}
            onDeletePress={() => {
              // eslint-disable-next-line no-restricted-globals
              if (screen.params && !isNaN(screen.params.index)) {
                const subjects = [...formik.values.subjects];
                subjects.splice(screen.params.index, 1);
                formik.setFieldValue("subjects", subjects);
                formik.handleSubmit();
                goBack();
              }
            }}
            onSubmitPress={(subject) => {
              // eslint-disable-next-line no-restricted-globals
              if (screen.params && !isNaN(screen.params.index)) {
                formik.setFieldValue(
                  `subjects[${screen.params.index}]`,
                  subject
                );
                formik.handleSubmit();
                goBack();
              }
            }}
            subject={
              screen.params && formik.values.subjects[screen.params.index]
            }
            visible
          />
        )}
        {screen.name === "vehicle" && (
          <VehicleScreen
            onBackPress={goBack}
            onDeletePress={() => {
              // eslint-disable-next-line no-restricted-globals
              if (screen.params && !isNaN(screen.params.index)) {
                const vehicles = [...formik.values.vehicles];
                vehicles.splice(screen.params.index, 1);
                formik.setFieldValue("vehicles", vehicles);
                formik.handleSubmit();
                goBack();
              }
            }}
            onSubmitPress={(vehicle) => {
              // eslint-disable-next-line no-restricted-globals
              if (screen.params && !isNaN(screen.params.index)) {
                formik.setFieldValue(
                  `vehicles[${screen.params.index}]`,
                  vehicle
                );
                formik.handleSubmit();
                goBack();
              }
            }}
            vehicle={
              screen.params && formik.values.vehicles[screen.params.index]
            }
            visible
          />
        )}
      </FullScreenModal>
      <AlertModal
        cancelText="Cancel"
        confirmText="Discard photos and exit"
        isVisible={unsavedMediaBeforeCloseVisible}
        message="Some photos you added have not been uploaded and will be discarded."
        onCancel={() => setUnsavedMediaBeforeCloseVisible(false)}
        onClose={() => setUnsavedMediaBeforeCloseVisible(false)}
        onConfirm={() => {
          setUnsavedMediaBeforeCloseVisible(false);
          onClosePress();
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Discard photos and submit"
        isVisible={unsavedMediaBeforeSubmitVisible}
        message="Some photos you added have not been uploaded and will be discarded."
        onCancel={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onClose={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onConfirm={() => {
          setUnsavedMediaBeforeSubmitVisible(false);
          onSubmit();
          runSubmitIncidentReport();
        }}
      />
      <MultiActionAlertModal
        actions={[
          {
            onPress: () => {
              setDeleteBeforeExitVisible(false);
              deleteMutation(incidentReport);
              addNotification({
                status: "success",
                title: "Report deleted",
              });
              onDelete();
            },
            text: "Delete and exit",
          },
          {
            appearance: "ghost",
            onPress: () => {
              setDeleteBeforeExitVisible(false);
              onClosePress();
            },
            text: "Keep report and exit",
          },
          {
            appearance: "ghost",
            onPress: () => setDeleteBeforeExitVisible(false),
            text: "Keep editing",
          },
        ]}
        isVisible={deleteBeforeExitVisible}
        message="Delete this report?"
        onClose={() => setDeleteBeforeExitVisible(false)}
      />
    </>
  );
};

export default IncidentReportEditingModal;
