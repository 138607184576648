import store from "../../../store";
import { setJobsPending } from "../../../store/jobs/slice";
import { Coordinates, Job } from "../../../types";
import createNextRepeatingJobFromRRule from "../../../utils/createNextRepeatingJobFromRRule";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import mutation from "./graphql";

interface DismissJobInput {
  timestamp?: string;
  coordinates: Coordinates;
  nextId?: string;
}

const dispatchOptimisticResponse = (
  { coordinates, nextId, timestamp }: DismissJobInput,
  job: Job
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;

  const jobs: Array<Job> = [
    {
      ...job,
      finished: {
        status: "dismissed",
        record: {
          at: timestamp,
          by: userId,
          coordinates,
        },
      },
      syncDateTime: timestamp,
    },
  ];

  if (nextId) {
    const nextJob = createNextRepeatingJobFromRRule({
      nextId,
      job,
    });
    jobs.push(nextJob);
  }

  dispatch(
    setJobsPending({
      jobs,
      mutation: "dismissJob",
    })
  );
};

const dismissJob = (
  {
    timestamp = new Date().toISOString(),
    coordinates,
    nextId,
  }: DismissJobInput,
  job: Job
) => {
  dispatchOptimisticResponse(
    {
      timestamp,
      coordinates,
      nextId,
    },
    job
  );
  client.mutate({
    mutation,
    variables: deepCopy({
      id: job.id,
      timestamp,
      coordinates,
      nextId,
    }),
  });
};

export default dismissJob;
