import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { PostmarkMessageSendingResponse } from "../../../types";

interface SendJobSummariesInput {
  jobIds: Array<string>;
}

const sendJobSummaries = async ({ jobIds }: SendJobSummariesInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        jobIds,
      },
    })) as GraphQLResult<{
      sendJobSummaries: Array<PostmarkMessageSendingResponse>;
    }>;
    return data.sendJobSummaries;
  } catch ({ errors }) {
    throw generateErrorMessage("sendJobSummaries", errors);
  }
};

export default sendJobSummaries;
