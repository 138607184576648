import { Card, Text, Button } from "@ui-kitten/components";
import React from "react";
import { View, StyleSheet } from "react-native";

import SimpleModal from "../Simple";
import { Separator } from "../../buildingBlocks";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
  },
});

interface BottomTabActionsModalProps {
  isVisible: boolean;
  onClose: () => void;
  onCreateLocation: () => void;
  onCreateGroup: () => void;
  onCreateJob: () => void;
  onCreateUser: () => void;
  onDispatch: () => void;
}

const BottomTabActionsModal = ({
  isVisible,
  onClose,
  onCreateLocation,
  onCreateGroup,
  onCreateJob,
  onCreateUser,
  onDispatch,
}: BottomTabActionsModalProps) => {
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackPress={onClose}
    >
      <Card
        header={(props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <View {...props}>
            <View style={[styles.header]}>
              <View>
                <Text category="h6">Actions</Text>
                <Text category="s1">What would you like to do?</Text>
              </View>
              <Button appearance="ghost" size="small" onPress={onClose}>
                Close
              </Button>
            </View>
          </View>
        )}
      >
        <Button
          appearance="ghost"
          data-test="createJobButtonInModal"
          onPress={onCreateJob}
        >
          Create job
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          data-test="createCustomerButtonInModal"
          onPress={onCreateLocation}
        >
          Create location
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          data-test="createUserButtonInModal"
          onPress={onCreateUser}
        >
          Create user
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          data-test="createGroupButtonInModal"
          onPress={onCreateGroup}
        >
          Create group
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          data-test="dispatchJobButtonInModal"
          onPress={onDispatch}
        >
          Dispatch user to location
        </Button>
      </Card>
    </SimpleModal>
  );
};

export default React.memo(BottomTabActionsModal);
