import {
  createAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { initSession, resetUser } from "../../auth/slice";
import companyUserRoles from "../../../constants/companyUserRoles";
import {
  DateTimeRange,
  EntityState,
  OptimisticEntity,
  Timecard,
} from "../../../types";

type OptimisticTimeCard = Timecard & OptimisticEntity;

const adapter = createEntityAdapter<Timecard>();
const optimisticAdapter = createEntityAdapter<OptimisticTimeCard>();

type Mode = "custom" | "week";

export interface State extends EntityState<Timecard> {
  optimistic: EntityState<OptimisticTimeCard>;
  userIds: Array<string>;
  queryDateTimes: Partial<DateTimeRange>;
  customRangeDateTimes: Partial<DateTimeRange>;
  mode: Mode;
  selectedDate: string;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
  userIds: [],
  queryDateTimes: {
    startDateTime: null,
    endDateTime: null,
  },
  customRangeDateTimes: {
    startDateTime: null,
    endDateTime: null,
  },
  selectedDate: new Date().toISOString(),
  mode: "week",
}) as State;

const name = "timeCards";

export const setTimeCard = {
  fulfilled: createAction<{
    deleted?: boolean;
    mutation: string;
    timeCard: Timecard;
  }>(`${name}/setTimeCard/fulfilled`),
  pending: createAction<{
    deleted?: boolean;
    mutation: string;
    timeCard: Timecard;
  }>(`${name}/setTimeCard/pending`),
  rejected: createAction<{ id: string; mutation: string }>(
    `${name}/setTimeCard/rejected`
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUserIds: (state, action) => {
      const { userIds }: { userIds: Array<string> } = action.payload;
      state.userIds = userIds;
    },
    setMode: (state, action) => {
      const { mode }: { mode: Mode } = action.payload;
      state.mode = mode;
    },
    setQueryDateTimes: (state, action) => {
      const {
        startDateTime,
        endDateTime,
      }: { startDateTime: string; endDateTime: string } = action.payload;
      state.queryDateTimes = { startDateTime, endDateTime };
    },
    setTimeCards: (state, action) => {
      const { timecards }: { timecards: Array<Timecard> } = action.payload;
      adapter.setAll(state, timecards);
    },
    setCustomRangeDateTimes: (state, action) => {
      const {
        startDateTime,
        endDateTime,
      }: { startDateTime: string; endDateTime: string } = action.payload;
      state.customRangeDateTimes = { startDateTime, endDateTime };
    },
    setSelectedDate: (state, action) => {
      const { selectedDate }: { selectedDate: string } = action.payload;
      state.selectedDate = selectedDate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTimeCard.pending, (state, action) => {
      const { deleted, mutation, timeCard } = action.payload;
      const existingOptimisticTimeCard = state.optimistic.entities[timeCard.id];
      if (
        existingOptimisticTimeCard === undefined ||
        timeCard.updated.at >= existingOptimisticTimeCard.updated.at
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...timeCard,
          deleted,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setTimeCard.fulfilled, (state, action) => {
      const { deleted, mutation, timeCard } = action.payload;
      const existingTimeCard = state.entities[timeCard.id];
      if (
        existingTimeCard === undefined ||
        timeCard.updated.at >= existingTimeCard.updated.at
      ) {
        if (deleted) {
          adapter.removeOne(state, timeCard.id);
        } else {
          adapter.upsertOne(state, timeCard);
        }
      }
      const existingOptimisticTimeCard = state.optimistic.entities[timeCard.id];
      if (
        existingOptimisticTimeCard !== undefined &&
        mutation === existingOptimisticTimeCard.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, timeCard.id);
      }
    });
    builder.addCase(setTimeCard.rejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticTimeCard = state.optimistic.entities[id];
      if (
        existingOptimisticTimeCard !== undefined &&
        mutation === existingOptimisticTimeCard.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(initSession.fulfilled, (state, action) => {
      const { user } = action.payload;
      switch (user.group) {
        case companyUserRoles.ADMIN:
        case companyUserRoles.SUPERVISOR:
          state.userIds = [];
          break;
        case companyUserRoles.EMPLOYEE:
          state.userIds = [user.id];
          break;
        default:
          state.userIds = [user.id];
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const {
  setUserIds,
  setMode,
  setQueryDateTimes,
  setTimeCards,
  setCustomRangeDateTimes,
  setSelectedDate,
} = actions;

export default reducer;
