import React from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { Divider, Layout, Text, useTheme } from "@ui-kitten/components";
import { DataTable } from "react-native-paper";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ExportToCsv, Options } from "export-to-csv";

import Separator from "../../../buildingBlocks/Separator";
import HeaderWithTextAction from "../../../buildingBlocks/HeaderWithTextAction";
import selectDateTimeFormat from "../../../../store/settings/selectors/selectDateTimeFormat";
import selectTimeCardEntities from "../../../../store/timeCards/selectors/selectTimeCardEntities";
import { Timecard } from "../../../../types";
import selectUsers from "../../../../store/users/selectors/selectUsers";
import getUserFullName from "../../../../utils/getUserFullName";
import Button from "../../../buildingBlocks/Button";

dayjs.extend(duration);
type Props = {
  onClosePress: () => void;
  timeCardIds: Array<string>;
};

const styles = StyleSheet.create({
  warningMessageBoldText: {
    fontWeight: "bold",
  },
  titleContainer: {
    paddingHorizontal: 16,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  buttonContainer: {
    padding: 8,
    flexDirection: "row",
  },
  buttonWrapper: {
    flex: 1,
  },
  warningMessageContainer: {
    borderRadius: 8,
    justifyContent: "center",
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
    marginTop: 8,
    minHeight: 48,
    overflow: "hidden",
    paddingEnd: 16,
    paddingStart: 16,
  },
});

interface HoursInfo {
  hours: number;
  timeCards: number;
  userId: string;
  name: string;
}

interface CSVData {
  hours: number;
  timeCards: number;
  name: string;
}

const options: Options = {
  headers: ["Full name", "# of time cards", "Total hours"],
  showLabels: true,
  filename: "Timesheet",
};
//   {
//     label: "Full Name",
//     key: "name",
//   },
//   { label: "Number of time cards", key: "timeCards" },
//   { label: "Hours", key: "hours" },
// ];

const MainScreen = ({ onClosePress, timeCardIds }: Props) => {
  const { startDateTime, endDateTime } = useSelector(
    (state: any) => state.data.timeCards.queryDateTimes
  );
  const timeCards = useSelector(selectTimeCardEntities);
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const users = useSelector(selectUsers).entities;
  const theme = useTheme();
  const csvData: CSVData[] = [];

  const renderCells = () => {
    const hoursMap: Record<string, HoursInfo> = {};

    timeCardIds.forEach((id) => {
      const card: Timecard = timeCards[id];
      const cardDuration: number = dayjs
        .duration(dayjs(card.quit.at).diff(card.started.at))
        .as("hours");
      const userId: string = card.created.by;
      if (hoursMap[userId]) {
        hoursMap[userId].hours += cardDuration;
        hoursMap[userId].timeCards += 1;
      } else {
        hoursMap[userId] = {
          hours: cardDuration,
          timeCards: 1,
          userId,
          name: getUserFullName(users[userId]),
        };
      }
    });

    return Object.values(hoursMap).map((hoursInfo) => {
      csvData.push({
        name: hoursInfo.name,
        timeCards: hoursInfo.timeCards,
        hours: parseFloat(hoursInfo.hours.toFixed(1)),
      });
      return (
        // @ts-ignore
        <DataTable.Row key={hoursInfo.userId}>
          {/* @ts-ignore */}
          <DataTable.Cell>{hoursInfo.name}</DataTable.Cell>
          {/* @ts-ignore */}
          <DataTable.Cell numeric>{hoursInfo.timeCards}</DataTable.Cell>
          {/* @ts-ignore */}
          <DataTable.Cell numeric>
            {parseFloat(hoursInfo.hours.toFixed(1))}
          </DataTable.Cell>
        </DataTable.Row>
      );
    });
  };

  const renderWarning = () => {
    if (Platform.OS !== "web") {
      return (
        <View
          style={[
            styles.warningMessageContainer,
            {
              backgroundColor: theme["color-warning-transparent-default"],
            },
          ]}
        >
          <Text>
            <Text
              style={[
                styles.warningMessageBoldText,
                { color: theme["color-warning-default"] },
              ]}
            >
              Download
            </Text>
            <Text style={{ color: theme["color-warning-default"] }}>
              {" is not supported on mobile."}
            </Text>
          </Text>
        </View>
      );
    }
    return null;
  };

  return (
    <>
      <HeaderWithTextAction
        navigation={{ icon: "close-outline", onPress: onClosePress }}
        title="Timesheet"
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView>
          <Separator size="small" />
          <Layout>
            <Separator size="small" />
            <View style={styles.titleContainer}>
              <Text category="h6">
                {`From ${dayjs(startDateTime).format(dateTimeFormat)}`}
              </Text>
              <Text category="h6">{`to ${dayjs(endDateTime).format(
                dateTimeFormat
              )}`}</Text>
            </View>
            {renderWarning()}
            <Separator size="small" />
            <Divider />
            <DataTable>
              {/* @ts-ignore */}
              <DataTable.Header>
                {/* @ts-ignore */}
                <DataTable.Title>Name</DataTable.Title>
                {/* @ts-ignore */}
                <DataTable.Title numeric># of time cards</DataTable.Title>
                {/* @ts-ignore */}
                <DataTable.Title numeric>Total hours</DataTable.Title>
              </DataTable.Header>
              {renderCells()}
            </DataTable>
          </Layout>
          <Separator />
        </ScrollView>
        <Divider />
        <SafeAreaView>
          <View style={styles.buttonContainer}>
            <View style={styles.buttonWrapper}>
              <Button
                appearance="ghost"
                ConditionalTooltipProps={{
                  title: "Not supported on mobile",
                  visible: Platform.OS !== "web",
                }}
                disabled={Platform.OS !== "web"}
                onPress={() => {
                  const csvExporter = new ExportToCsv(options);
                  csvExporter.generateCsv(csvData);
                }}
              >
                Download
              </Button>
            </View>
          </View>
        </SafeAreaView>
      </Layout>
    </>
  );
};

export default MainScreen;
