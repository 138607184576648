import { createSelector } from "@reduxjs/toolkit";

import formatSectionListData from "../../../../utils/formatSectionListData";
import selectTimeCardEntities from "../selectTimeCardEntities";

const selectQueriedTimeCards = createSelector(
  [
    selectTimeCardEntities,
    (state: any) => state.data.timeCards.queryDateTimes,
    (state: any) => state.data.timeCards.userIds,
  ],
  (timeCards, queryDateTimes, userIds) => {
    const timeCardArray = Object.values(timeCards);
    const { endDateTime, startDateTime } = queryDateTimes;
    const filteredCards =
      userIds.length > 0
        ? timeCardArray.filter((timeCard) =>
            userIds.includes(timeCard.started.by)
          )
        : timeCardArray;
    return formatSectionListData({
      data: filteredCards,
      startDateTimeExtractor: (item) => item.started.at,
      idExtractor: (item) => item.id,
      sortCompareFunction: (a, b) => a.started.at.localeCompare(b.started.at),
      to: endDateTime,
      from: startDateTime,
    });
  }
);

export default selectQueriedTimeCards;
