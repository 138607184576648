import { createSelector } from "@reduxjs/toolkit";

import { State } from "../../slice";

const selectJobs = (state): State => state.data.jobs;

const selectReports = createSelector(
  [selectJobs],
  (state) => state.reportsByJobId
);

const selectReportsByJobId = createSelector(
  [selectReports, (_, { jobId }) => jobId],
  (reportsByJobId, jobId) => {
    const reports = reportsByJobId[jobId];
    if (!reports) return undefined;
    const optimisticReports = reports.optimistic;
    const entities = {
      ...reports.entities,
      ...optimisticReports.entities,
    };
    return {
      entities,
      ids: Object.keys(entities),
    };
  }
);

export default selectReportsByJobId;
