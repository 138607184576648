import React, { useState } from "react";
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { Text, useTheme } from "@ui-kitten/components";
import { useDispatch, useSelector } from "react-redux";

import selectMapJobs from "../../../store/jobs/selectors/mapJobs";
import { setFilterStatus } from "../../../store/jobs/slice";
import { JobStatusId } from "../../../types";
import selectEntities from "../../../store/jobs/selectors/selectJobEntities";
import Separator from "../../buildingBlocks/Separator";

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    ...(Platform.OS === "web" && {
      cursor: "pointer",
    }),
  },
  cardContent: {
    alignItems: "center",
  },
  countContainer: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.32)",
    borderRadius: 8,
    height: 32,
    justifyContent: "center",
    width: 32,
  },
  text: {
    color: "white",
  },
});

export interface JobStatusCardProps {
  callback: (id: JobStatusId) => void;
  colors: {
    default: string;
    focus: string;
  };
  dense?: boolean;
  jobStatusId: JobStatusId;
  label: string;
}

const JobStatusCard = ({
  callback,
  colors,
  dense = false,
  jobStatusId,
  label,
}: JobStatusCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pressed, setPressed] = useState(false);

  const handlePressIn = () => setPressed(true);
  const handlePressOut = () => setPressed(false);

  const jobs = useSelector(selectEntities);
  const { accessible: accessibleJobs } = useSelector(selectMapJobs);
  const count = accessibleJobs.filter(
    (id) => jobs[id].status.id === jobStatusId
  ).length;
  const filterStatus = useSelector(
    (state: any) => state.data.jobs.filter.status
  );

  const handlePress = () => {
    if (filterStatus === jobStatusId) {
      dispatch(setFilterStatus({ status: null }));
    } else {
      dispatch(setFilterStatus({ status: jobStatusId }));
    }
    callback(jobStatusId);
  };

  const calculateBackgroundColor = () => {
    if (pressed) return theme["color-basic-transparent-active"];
    if (filterStatus && filterStatus !== jobStatusId)
      return theme["color-basic-transparent-active"];
    return theme[colors.default];
  };

  return (
    <TouchableWithoutFeedback
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onPress={handlePress}
    >
      <View
        style={[
          styles.card,
          {
            backgroundColor: calculateBackgroundColor(),
          },
        ]}
      >
        {!dense ? (
          <View style={styles.cardContent}>
            <Separator size="small" />
            <View style={styles.countContainer}>
              <Text
                category="h6"
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.text}
              >
                {count}
              </Text>
            </View>
            <Separator size="small" />
            <Text
              style={styles.text}
              category="label"
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {label}
            </Text>
            <Separator size="small" />
          </View>
        ) : (
          <View
            style={[
              styles.cardContent,
              { flexDirection: "row", paddingBottom: 8, paddingTop: 8 },
            ]}
          >
            <Separator horizontal size="medium" />
            <Text
              style={styles.text}
              category="s1"
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {label}
            </Text>
            <Separator horizontal size="small" />
            <View style={[styles.countContainer, { height: 24, width: 24 }]}>
              <Text
                category="s2"
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.text}
              >
                {count}
              </Text>
            </View>
            <Separator horizontal size="medium" />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default JobStatusCard;
