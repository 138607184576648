import { AsYouType } from "libphonenumber-js";
import * as yup from "yup";

const formikUsernameValidationSchema = (signInWithPhone: boolean) => {
  return yup.string().test((val: string) => {
    if (!val) return false;
    if (signInWithPhone) {
      const phoneNumber = new AsYouType("US");
      phoneNumber.input(val);
      return phoneNumber.isValid();
    }
    return val.trim().match(/\S+@\S+\.\S+/);
  });
};

export default formikUsernameValidationSchema;
