import gql from "graphql-tag";

import { stripeExpandedInvoiceItemFragment } from "../../../fragments";

const updateInvoiceItem = gql`
  mutation updateInvoiceItem($id: ID!, $quantity: Int!, $tax_rates: [ID]) {
    updateInvoiceItem(id: $id, quantity: $quantity, tax_rates: $tax_rates) {
      ...stripeExpandedInvoiceItemFragment
    }
  }
  ${stripeExpandedInvoiceItemFragment}
`;

export default updateInvoiceItem;
