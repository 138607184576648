import { createSelector } from "@reduxjs/toolkit";

import selectEmailActivityEntities from "../selectEmailActivityEntities";
import { EmailActivity } from "../../../../types";

const selectEmailActivityById = createSelector(
  [selectEmailActivityEntities, (_, id: string) => id],
  (emailActivities, id): EmailActivity => emailActivities[id]
);

export default selectEmailActivityById;
