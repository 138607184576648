import uuidv4 from "uuid/v4";

import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setJobsPending } from "../../../store/jobs/slice";
import createNextRepeatingJobFromRRule from "../../../utils/createNextRepeatingJobFromRRule";

const dispatchOptimisticResponse = (
  { timestamp, nextId, coordinates },
  job
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const nextJob = createNextRepeatingJobFromRRule({
    nextId,
    job,
  });

  const jobs = [
    {
      ...job,
      finished: {
        status: "completed",
        record: {
          at: timestamp,
          by: userId,
          coordinates,
        },
      },
      syncDateTime: timestamp,
    },
  ];
  if (nextJob) jobs.push(nextJob);

  dispatch(
    setJobsPending({
      jobs,
      mutation: "completeJob",
    })
  );
};

const completeJob = (
  { timestamp = new Date().toISOString(), coordinates, nextId = uuidv4() },
  job
) => {
  dispatchOptimisticResponse(
    {
      timestamp,
      coordinates,
      nextId,
    },
    job
  );
  client.mutate({
    mutation,
    variables: deepCopy({
      id: job.id,
      timestamp,
      coordinates,
      nextId,
    }),
    errorPolicy: "all",
  });
};

export default completeJob;
