import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { Divider, Icon, Layout } from "@ui-kitten/components";

import DateAndTimePickerModal from "../../DateAndTimePicker";
import Separator from "../../../buildingBlocks/Separator";
import HeaderWithTextAction from "../../../buildingBlocks/HeaderWithTextAction";
import { SectionItem } from "../../../buildingBlocks";
import BaseListItem from "../../../listItems/Base";
import useFormatDate from "../../../../hooks/useFormatDate";
import { Timecard } from "../../../../types";

type Props = {
  onChangeQuitLocationPress: () => void;
  onChangeStartedLocationPress: () => void;
  onClosePress: () => void;
  onQuitTimeChange: (dateTime: string) => void;
  onStartedTimeChange: (dateTime: string) => void;
  onSubmitPress: () => void;
  timeCard: Timecard;
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
});

const MainScreen = ({
  onChangeQuitLocationPress,
  onChangeStartedLocationPress,
  onClosePress,
  onQuitTimeChange,
  onStartedTimeChange,
  onSubmitPress,
  timeCard,
}: Props) => {
  const { formatDateTime } = useFormatDate();
  const [startedTimePickerVisible, setStartedTimePickerVisible] = useState(
    false
  );
  const [quitTimePickerVisible, setQuitTimePickerVisible] = useState(false);
  return (
    <>
      <HeaderWithTextAction
        action={{
          loading: false,
          onPress: onSubmitPress,
          text: "Done",
        }}
        navigation={{ icon: "close-outline", onPress: onClosePress }}
        title="Edit time card"
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView>
          <Separator />
          <SectionItem title="Started">
            <BaseListItem
              description="Tap to edit"
              onPress={() => setStartedTimePickerVisible(true)}
              title={formatDateTime(timeCard.started.at)}
            />
            <Divider style={styles.divider} />
            <BaseListItem
              accessoryRight={(imageProps) => (
                <Icon {...imageProps} name="arrow-ios-forward-outline" />
              )}
              onPress={onChangeStartedLocationPress}
              title="Change location"
            />
          </SectionItem>
          <Separator />
          <SectionItem title="Quit">
            <BaseListItem
              description="Tap to edit"
              onPress={() => setQuitTimePickerVisible(true)}
              title={formatDateTime(timeCard.quit.at)}
            />
            <Divider style={styles.divider} />
            <BaseListItem
              accessoryRight={(imageProps) => (
                <Icon {...imageProps} name="arrow-ios-forward-outline" />
              )}
              onPress={onChangeQuitLocationPress}
              title="Change location"
            />
          </SectionItem>
          <Separator />
        </ScrollView>
      </Layout>
      <DateAndTimePickerModal
        dateTime={new Date(timeCard.started.at)}
        onClose={() => setStartedTimePickerVisible(false)}
        onDone={(date) => {
          setStartedTimePickerVisible(false);
          onStartedTimeChange(date.toISOString());
        }}
        visible={startedTimePickerVisible}
      />
      <DateAndTimePickerModal
        dateTime={new Date(timeCard.quit.at)}
        onClose={() => setQuitTimePickerVisible(false)}
        onDone={(date) => {
          setQuitTimePickerVisible(false);
          onQuitTimeChange(date.toISOString());
        }}
        visible={quitTimePickerVisible}
      />
    </>
  );
};

export default MainScreen;
