import {
  Button,
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { SafeAreaView, SectionList, StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSelector } from "react-redux";
import getDistanceMatrix from "../../api/functions/getDistanceMatrix";
import assigneeTypes from "../../constants/assigneeTypes";
import useIosBottomSpace from "../../device/useIosBottomSpace";
import selectAvailableAssignees from "../../store/selectors/availableAssignees";
import selectUsers from "../../store/users/selectors/selectUsers";
import { Assignee, Coordinates, SimpleDistanceMatrixData } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import HeaderWithTextAction from "../buildingBlocks/HeaderWithTextAction";
import { addNotification } from "../InAppNotifications";
import GroupListItem from "../listItems/Group";

import UserListItem from "../listItems/User";

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  searchContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  headerText: {
    marginHorizontal: 16,
    marginVertical: 8,
  },
});

interface AssigneesSelectProps {
  actionItem?: {
    "data-test": string;
    disabled: boolean;
    onPress: () => void;
    text: string;
  };
  onChange: (assignees: Assignee[]) => void;
  selected: Assignee[];
  navigation?: {
    icon: string;
    onPress: () => void;
  } | null;
  subtitle?: string;
  title?: string;
  destination?: Coordinates;
}

const AssigneesSelect: React.FC<AssigneesSelectProps> = ({
  actionItem,
  navigation,
  onChange,
  selected,
  subtitle,
  title,
  destination,
}) => {
  const theme = useTheme();
  const iosBottomSpace = useIosBottomSpace();
  const [search, setSearch] = useState({
    query: "",
    results: [],
  });
  const [distanceMatrix, setDistanceMatrix] = useState<
    Record<string, SimpleDistanceMatrixData>
  >({});
  const [loadingDistanceMatrix, setLoadingDistanceMatrix] = useState<boolean>(
    false
  );
  const availableAssignees = useSelector((state) =>
    selectAvailableAssignees(state, distanceMatrix)
  );
  const users = useSelector(selectUsers);

  const getDistanceMatrixAsync = async () => {
    setLoadingDistanceMatrix(true);
    try {
      const userDistances: string[] = [];
      const origins = Object.values(users.entities)
        .filter(({ coordinates, clockedIn, isArchived }) => {
          return coordinates.lat && coordinates.lng && clockedIn && !isArchived;
        })
        .map(({ coordinates, id }) => {
          userDistances.push(id);
          return {
            lat: coordinates.lat,
            lng: coordinates.lng,
          };
        });
      if (origins.length > 0) {
        const matrix = await getDistanceMatrix({
          origins,
          destinations: [destination],
        });
        const tempDistanceMatrix: Record<string, SimpleDistanceMatrixData> = {};
        matrix.rows.forEach(({ elements }, index) => {
          const userId = userDistances[index];

          if (elements[0].status === "OK") {
            // eslint-disable-next-line prefer-destructuring
            tempDistanceMatrix[userId] = {
              durationValue: elements[0].duration_in_traffic.value,
              text: `${elements[0].duration_in_traffic.text} \u2022 ${elements[0].distance.text}`,
            };
          }
        });
        setDistanceMatrix(tempDistanceMatrix);
      }
    } catch (e) {
      addNotification({
        status: "danger",
        title: "Error getting distances for users",
        message: e.message || "Unexpected error",
      });
    }

    setLoadingDistanceMatrix(false);
  };

  useEffect(() => {
    if (destination) {
      getDistanceMatrixAsync().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/prop-types
  const renderHeader = ({ section: { type } }) => {
    if (type === assigneeTypes.user) {
      return (
        <Layout level="2">
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
              },
            ]}
          >
            <Text style={styles.headerText}>Users</Text>
            {destination && (
              <Button
                onPress={getDistanceMatrixAsync}
                style={[
                  {
                    marginRight: 8,
                  },
                ]}
                size="tiny"
                appearance="ghost"
                accessoryLeft={(props) => <Icon {...props} name="refresh" />}
              >
                Refresh
              </Button>
            )}
          </View>

          {/* @ts-ignore */}
          <ProgressBar
            color={theme["color-primary-default"]}
            indeterminate
            visible={loadingDistanceMatrix}
          />
          <Divider />
        </Layout>
      );
    }
    return (
      <Layout level="2">
        <Text style={styles.headerText}>Groups</Text>
        <Divider />
      </Layout>
    );
  };

  const handleItemPress = ({ id, type }) => {
    if (selected.filter((assignee) => assignee.id === id).length !== 0) {
      onChange(selected.filter((assignee) => assignee.id !== id));
    } else {
      onChange([
        ...selected.filter((assignee) => assignee.id !== id),
        {
          id,
          type,
        },
      ]);
    }
  };

  const getUserDescription = (id): string => {
    const user = users.entities[id];
    if (!user.clockedIn) return "User not clocked in";
    if (user.coordinates.lat === 0 || user.coordinates.lng === 0)
      return "User location not found";
    if (loadingDistanceMatrix) return "Loading travel time";
    if (distanceMatrix[id]) {
      return distanceMatrix[id].text;
    }
    return "Not able to get travel time";
  };

  const renderItem = (item) => {
    const { id } = item.item;
    const { type } = item.section;
    switch (type) {
      case assigneeTypes.group:
        return (
          <GroupListItem
            groupId={id}
            onPress={() => handleItemPress({ id, type: assigneeTypes.group })}
            accessoryRight={(props) =>
              selected.filter((assignee) => assignee.id === id).length !==
                0 && (
                <Icon
                  {...props}
                  name="checkmark-outline"
                  fill={theme["color-primary-default"]}
                />
              )
            }
          />
        );
      case assigneeTypes.user:
        return (
          <UserListItem
            showClockedInIndicator
            userId={id}
            description={destination && getUserDescription(id)}
            onPress={() => handleItemPress({ id, type: assigneeTypes.user })}
            accessoryRight={(props) =>
              !!selected.filter((assignee) => assignee.id === id).length && (
                <Icon
                  {...props}
                  name="checkmark-outline"
                  fill={theme["color-primary-default"]}
                />
              )
            }
          />
        );
      default:
        throw new Error(`Type ${type} not recognized`);
    }
  };
  const runSearch = (text) => {
    const query = text.trim().toLowerCase();
    const results = [];
    availableAssignees.forEach((section) => {
      if (section.type === assigneeTypes.group) {
        // @ts-ignore
        const groupResults = section.data.filter((group) =>
          group.name.toLowerCase().includes(query)
        );
        if (groupResults.length > 0) {
          results.push({
            type: assigneeTypes.group,
            data: groupResults,
          });
        }
      }
      if (section.type === assigneeTypes.user) {
        // @ts-ignore
        const userResults = section.data.filter((user) =>
          getUserFullName(user).toLowerCase().includes(query)
        );
        if (userResults.length > 0) {
          results.push({
            type: assigneeTypes.user,
            data: userResults,
          });
        }
      }
    });
    setSearch({
      query: text,
      results,
    });
  };

  return (
    <Layout style={styles.root}>
      {navigation !== null && (
        <SafeAreaView>
          <HeaderWithTextAction
            action={
              actionItem && {
                "data-test": actionItem["data-test"],
                disabled: actionItem.disabled,
                onPress: actionItem.onPress,
                text: actionItem.text,
              }
            }
            navigation={navigation}
            subtitle={subtitle}
            title={title}
          />
        </SafeAreaView>
      )}
      <View style={styles.searchContainer}>
        <Input
          data-test="inputInAssigneesSelect"
          clearButtonMode="while-editing"
          onChangeText={runSearch}
          placeholder="Search..."
          value={search.query}
        />
      </View>
      <Divider />
      <SectionList
        sections={!search.query ? availableAssignees : search.results}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        renderSectionHeader={renderHeader}
        contentContainerStyle={[{ paddingBottom: iosBottomSpace + 8 }]}
        keyboardShouldPersistTaps="handled"
      />
    </Layout>
  );
};

AssigneesSelect.defaultProps = {
  actionItem: undefined,
  subtitle: undefined,
  title: undefined,
  destination: null,
  navigation: null,
};

export default AssigneesSelect;
