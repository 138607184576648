import Storage from "@aws-amplify/storage";
import { Layout, Text, useTheme } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSelector } from "react-redux";

import createActionItemCompletionRecord from "../../../api/functions/createActionItemCompletionRecord";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import selectCognitoUser from "../../../store/auth/selectors/selectUser";
import { Coordinates, Job, MediaOnDevice } from "../../../types";
import useFadeAnimation from "../../../utils/useFadeAnimation";
import AddMediaButtons from "../../AddMediaButtons";
import Button from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";
import Container from "../../Container";
import { addNotification } from "../../InAppNotifications";
import Portal from "../../Portal";

interface ActionItemCompletionParams {
  actionItemId: string;
  coordinates: Coordinates;
}

type ActionItemCompletionModalProps = (
  | {
      isVisible: true;
      actionItemCompletionParams: ActionItemCompletionParams;
    }
  | {
      actionItemCompletionParams?: ActionItemCompletionParams;
      isVisible: false;
    }
) & {
  job: Job;
  onClose: () => void;
};

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 8,
    paddingEnd: 4,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  imagePickerButton: {
    borderRadius: 100,
  },
  imagePickerButtonContainer: {
    flex: 1,
  },
  imagePickerRow: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  imagePickerText: {
    textAlign: "center",
  },
  message: {
    paddingBottom: 26,
    paddingEnd: 24,
    paddingStart: 24,
    paddingTop: 20,
  },
  modal: {
    marginEnd: 16,
    marginStart: 16,
    borderRadius: 8,
    overflow: "hidden",
  },
  progressBarContainer: {
    paddingEnd: 24,
    paddingStart: 24,
  },
  progressTextContainer: {
    paddingEnd: 24,
    paddingTop: 4,
    alignItems: "flex-end",
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const ActionItemCompletionModal = ({
  actionItemCompletionParams,
  isVisible,
  job,
  onClose,
}: ActionItemCompletionModalProps) => {
  const theme = useTheme();
  const { componentRendered, fadeAnimation } = useFadeAnimation({
    visible: isVisible,
  });

  const { companyId } = useSelector(selectCognitoUser);

  const [step, setStep] = useState<"pickOption" | "uploading">();
  const [uploadState, setUploadState] = useState<{
    error?: Error;
    progress?: {
      loaded: number;
      total: number;
    };
  }>({});

  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  useEffect(() => {
    if (isVisible) {
      setStep("pickOption");
      setUploadState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  const renderProgressBar = () => {
    if (uploadState.progress) {
      const { progress } = uploadState;
      return (
        <ProgressBar
          color={theme["color-primary-default"]}
          progress={progress.loaded / progress.total}
        />
      );
    }
    return <ProgressBar color={theme["color-primary-default"]} indeterminate />;
  };
  const uploadMedia = async (mediaOnDevice: MediaOnDevice) => {
    setUploadState({});
    const { createdAt, description, id, mimeType, type, uri } = mediaOnDevice;
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch(uri);
      const blob = await response.blob();
      await Storage.put(`${companyId}/${id}`, blob, {
        contentType: mimeType,
        progressCallback: (progress) => {
          setUploadState({
            progress,
          });
        },
      });
      createActionItemCompletionRecord({
        actionItemId: actionItemCompletionParams.actionItemId,
        coordinates: actionItemCompletionParams.coordinates,
        jobId: job.id,
        media: {
          createdAt,
          description,
          id,
          type,
        },
        timestamp: new Date().toISOString(),
      });
      onClose();
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Upload failed",
      });
      onClose();
      // setUploadState({
      //   error,
      // });
    }
  };
  return (
    componentRendered && (
      <Portal>
        <Animated.View style={[styles.backdrop, { opacity: fadeAnimation }]}>
          <Container>
            <Layout style={styles.modal}>
              {step === "pickOption" && (
                <>
                  <Separator size="medium" />
                  <Text category="h6" style={styles.text}>
                    Add a photo?
                  </Text>
                  <Text style={styles.text}>
                    Take a new photo or choose an existing one.
                  </Text>
                  <Separator size="medium" />
                  <AddMediaButtons
                    onAddMedia={(media) => {
                      setStep("uploading");
                      uploadMedia(media);
                    }}
                  />
                  <Separator size="medium" />
                  <Button
                    onPress={() => {
                      createActionItemCompletionRecord({
                        actionItemId: actionItemCompletionParams.actionItemId,
                        coordinates: actionItemCompletionParams.coordinates,
                        jobId: job.id,
                        timestamp: new Date().toISOString(),
                      });
                      onClose();
                    }}
                    style={styles.button}
                  >
                    Complete without photo
                  </Button>
                  <Separator size="small" />
                  <Button
                    appearance="ghost"
                    onPress={onClose}
                    style={styles.button}
                  >
                    Cancel
                  </Button>
                  <Separator size="medium" />
                </>
              )}
              {step === "uploading" && (
                <>
                  <Text style={styles.message}>
                    {uploadState ? "Uploading" : "Initializing..."}
                  </Text>
                  <View style={styles.progressBarContainer}>
                    {renderProgressBar()}
                  </View>
                  <View style={styles.progressTextContainer}>
                    <Text appearance="hint" category="c1">
                      {uploadState && uploadState.progress
                        ? `${(
                            (uploadState.progress.loaded /
                              uploadState.progress.total) *
                            100
                          ).toFixed(0)}%`
                        : "0%"}
                    </Text>
                  </View>
                  <View style={styles.footer}>
                    <Button onPress={onClose} appearance="ghost">
                      Cancel
                    </Button>
                  </View>
                </>
              )}
            </Layout>
          </Container>
        </Animated.View>
      </Portal>
    )
  );
};

export default ActionItemCompletionModal;
