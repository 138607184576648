import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import adminDeleteDailyActivityReport from "../../../../api/functions/adminDeleteDailyActivityReport";
import adminUpdateDailyActivityReport from "../../../../api/functions/adminUpdateDailyActivityReport";
import deleteDailyActivityReport from "../../../../api/functions/deleteDailyActivityReport";
import submitDailyActivityReport from "../../../../api/functions/submitDailyActivityReport";
import updateDailyActivityReport from "../../../../api/functions/updateDailyActivityReport";
import useJob from "../../../../hooks/useJobUtils";
import { getLocation } from "../../../../location/mapLocation";
import selectSignedInUserRole from "../../../../store/users/selectors/selectSignedInUserRole";
import {
  CompanyUserRole,
  Coordinates,
  DailyActivityReport,
} from "../../../../types";
import isMediaOnDevice from "../../../../utils/isMediaOnDevice";
import { addNotification } from "../../../InAppNotifications";
import AlertModal from "../../Alert";
import FullScreenModal from "../../FullScreen";
import MultiActionAlertModal from "../../MultiActionAlert";
import useModalScreens from "../../useModalScreens";
import MainScreen from "./MainScreen";

type FormValues = Pick<DailyActivityReport, "media" | "summary">;

interface DailyActivityReportEditingModalProps {
  dailyActivityReport: DailyActivityReport;
  isVisible: boolean;
  onClosePress: () => void;
  onDelete: () => void;
  onSubmit: () => void;
}

const getValues = (dailyActivityReport: DailyActivityReport): FormValues => ({
  media: dailyActivityReport.media.map((media) => ({
    ...media,
  })),
  summary: dailyActivityReport.summary,
});

const useReportMutations = (userRole) => {
  if (userRole === "Admin" || userRole === "Supervisor") {
    return {
      deleteMutation: adminDeleteDailyActivityReport,
      updateMutation: adminUpdateDailyActivityReport,
    };
  }
  return {
    deleteMutation: deleteDailyActivityReport,
    updateMutation: updateDailyActivityReport,
  };
};

const useDailyActivityReportForm = ({
  dailyActivityReport,
  isVisible,
  userRole,
}: {
  dailyActivityReport: DailyActivityReport;
  isVisible: boolean;
  userRole: CompanyUserRole;
}) => {
  const { deleteMutation, updateMutation } = useReportMutations(userRole);
  const formik = useFormik<FormValues>({
    initialValues: getValues(dailyActivityReport),
    onSubmit: (values) => {
      updateMutation(
        {
          summary: values.summary,
          media: values.media.filter(
            (media) => !isMediaOnDevice(media) || media.uploaded
          ),
        },
        dailyActivityReport
      );
      formik.resetForm({
        values,
      });
    },
  });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getValues(dailyActivityReport),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return { deleteMutation, formik };
};

const screensConfig = [["main"], ["summary"]];

const DailyActivityReportEditingModal = ({
  dailyActivityReport,
  isVisible,
  onClosePress,
  onDelete,
  onSubmit,
}: DailyActivityReportEditingModalProps) => {
  const userRole = useSelector(selectSignedInUserRole);
  const {
    deleteActionItemCompletionRecord,
    deleteTaskCompletionRecord,
    getActionItemCompletionRecordByReportId,
    getTaskCompletionRecordByReportId,
  } = useJob(dailyActivityReport.jobId);
  const { deleteMutation, formik } = useDailyActivityReportForm({
    dailyActivityReport,
    isVisible,
    userRole,
  });
  const {
    navigation: { screens, state },
    jumpTo,
    goBack,
  } = useModalScreens(screensConfig);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(
    false
  );
  const [deleteBeforeExitVisible, setDeleteBeforeExitVisible] = useState(false);
  const [
    unsavedMediaBeforeSubmitVisible,
    setUnsavedMediaBeforeSubmitVisible,
  ] = useState(false);
  const [
    unsavedMediaBeforeCloseVisible,
    setUnsavedMediaBeforeCloseVisible,
  ] = useState(false);

  const runSubmitReport = async (coordinates: Coordinates) => {
    submitDailyActivityReport(
      {
        completed: {
          at: new Date().toISOString(),
          coordinates,
        },
      },
      dailyActivityReport
    );
    addNotification({
      status: "success",
      title: "Report submitted",
    });
    onSubmit();
  };

  if (!formik.values) {
    return null;
  }

  const screen = screens[state.index];

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClosePress}>
        <MainScreen
          dailyActivityReport={dailyActivityReport}
          formik={formik}
          onClosePress={() => {
            if (
              dailyActivityReport.created.at !==
                dailyActivityReport.updated.at ||
              formik.values.summary ||
              formik.values.media.length > 0
            ) {
              if (
                formik.values.media.filter(
                  (media) => isMediaOnDevice(media) && !media.uploaded
                ).length > 0
              ) {
                setUnsavedMediaBeforeCloseVisible(true);
              } else {
                onClosePress();
              }
            } else if (
              !dailyActivityReport.summary &&
              dailyActivityReport.media.length === 0
            ) {
              if (!dailyActivityReport.submitted) {
                setDeleteBeforeExitVisible(true);
              } else {
                onClosePress();
              }
            }
          }}
          onDeletePress={() => setDeleteConfirmationVisible(true)}
          onMediaUpdate={formik.handleSubmit}
          onSubmitPress={(coordinates) => {
            if (
              formik.values.media.filter(
                (media) => isMediaOnDevice(media) && !media.uploaded
              ).length > 0
            ) {
              setUnsavedMediaBeforeSubmitVisible(true);
            } else {
              runSubmitReport(coordinates);
            }
          }}
          onSummaryPress={() => jumpTo("summary")}
        />
      </FullScreenModal>
      <AlertModal
        cancelText="Cancel"
        confirmText="Discard photos and exit"
        isVisible={unsavedMediaBeforeCloseVisible}
        message="Some photos you added have not been uploaded and will be discarded."
        onCancel={() => setUnsavedMediaBeforeCloseVisible(false)}
        onClose={() => setUnsavedMediaBeforeCloseVisible(false)}
        onConfirm={() => {
          setUnsavedMediaBeforeCloseVisible(false);
          onClosePress();
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Discard photos and submit"
        isVisible={unsavedMediaBeforeSubmitVisible}
        message="Some photos you added have not been uploaded and will be discarded."
        onCancel={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onClose={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onConfirm={async () => {
          setUnsavedMediaBeforeSubmitVisible(false);
          const location = await getLocation({ title: "Submit report failed" });
          if (location) {
            runSubmitReport({
              lat: location.longitude,
              lng: location.longitude,
            });
          }
        }}
      />
      <AlertModal
        cancelText="Keep editing"
        confirmText="Delete"
        isVisible={deleteConfirmationVisible}
        message="Delete this report?"
        onCancel={() => setDeleteConfirmationVisible(false)}
        onClose={() => setDeleteConfirmationVisible(false)}
        onConfirm={() => {
          setDeleteConfirmationVisible(false);
          deleteMutation(dailyActivityReport);
          addNotification({
            status: "success",
            title: "Report deleted",
          });
          onDelete();
        }}
      />
      <MultiActionAlertModal
        actions={[
          {
            onPress: () => {
              setDeleteBeforeExitVisible(false);
              deleteMutation(dailyActivityReport);
              // If this report is linked to a task or action item then they need to be
              // automatically deleted once the report is submitted
              const taskCompletionRecord = getTaskCompletionRecordByReportId(
                dailyActivityReport.id
              );
              if (taskCompletionRecord) {
                deleteTaskCompletionRecord(taskCompletionRecord.taskId);
              }
              const actionItemCompletionRecord = getActionItemCompletionRecordByReportId(
                dailyActivityReport.id
              );
              if (actionItemCompletionRecord) {
                deleteActionItemCompletionRecord(
                  actionItemCompletionRecord.actionItemId
                );
              }
              addNotification({
                status: "success",
                title: "Report deleted",
              });
              onDelete();
            },
            text: "Delete and exit",
          },
          {
            appearance: "ghost",
            onPress: () => {
              setDeleteBeforeExitVisible(false);
              onClosePress();
            },
            text: "Keep report and exit",
          },
          {
            appearance: "ghost",
            onPress: () => setDeleteBeforeExitVisible(false),
            text: "Keep editing",
          },
        ]}
        isVisible={deleteBeforeExitVisible}
        message="Delete this report?"
        onClose={() => setDeleteBeforeExitVisible(false)}
      />
    </>
  );
};

export default DailyActivityReportEditingModal;
