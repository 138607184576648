import { useTheme } from "@ui-kitten/components";
import React, { createRef } from "react";
import { StyleSheet, View } from "react-native";
import DropdownAlert from "react-native-dropdownalert";

import InAppNotificationsMessage from "./Message";
import InAppNotificationsTitle from "./Title";
import { navigate } from "../../navigation/navigator";
import { ScreenNames } from "../../types";

const dropdownAlertRef = createRef<DropdownAlert | null>();

const mapStatusToType = (status) => {
  switch (status) {
    case "danger":
      return "error";
    case "info":
    case "success":
      return status;
    case "warning":
      return "warn";
    default:
      return null;
  }
};

interface Payload {
  navigation: {
    route: ScreenNames;
    params?: any;
  };
}

export const addNotification = ({
  message,
  payload,
  status,
  title,
}: {
  message?: string;
  payload?: Payload;
  status?: string;
  title?: string;
}) => {
  if (dropdownAlertRef.current) {
    dropdownAlertRef.current.closeAction();
    dropdownAlertRef.current.alertWithType(
      mapStatusToType(status),
      title || undefined,
      message || undefined,
      payload
    );
  }
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#121212",
    flexDirection: "row",
    padding: 16,
  },
  icon: {
    alignItems: "center",
    height: 24,
    marginHorizontal: 8,
    width: 24,
  },
  iconContainer: {
    justifyContent: "center",
  },
  root: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
  text: {
    color: "white",
  },
});

const InAppNotifications = () => {
  const theme = useTheme();

  const handleTap = (event) => {
    const { payload } = event;
    const { navigation } = payload;

    if (navigation) {
      const { route, params } = navigation;
      navigate(route, params);
    }
  };

  return (
    <View pointerEvents="box-none" style={styles.root}>
      <DropdownAlert
        containerStyle={styles.container}
        errorColor={theme["color-danger-default"]}
        infoColor={theme["color-info-default"]}
        onTap={handleTap}
        renderImage={() => null}
        renderMessage={(props, alertData) => (
          <InAppNotificationsMessage alertData={alertData} props={props} />
        )}
        renderTitle={(props, alertData) => (
          <InAppNotificationsTitle alertData={alertData} props={props} />
        )}
        ref={dropdownAlertRef}
        successColor={theme["color-success-default"]}
        warnColor={theme["color-warning-default"]}
      />
    </View>
  );
};

export default InAppNotifications;
