import { Text } from "@ui-kitten/components";
import _ from "lodash";
import React, { ReactElement, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import sendJobSummaries from "../../api/functions/sendJobSummaries";
import useRequeryJobs from "../../hooks/useRequeryJobs";
import selectCustomersByJobIds from "../../store/customers/selectors/selectCustomersByJobIds";
import { PostmarkMessageSendingResponse } from "../../types";

import Button, { Props as ButtonProps } from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import { addNotification } from "../InAppNotifications";
import ScheduleJobListItem from "../listItems/ScheduleJob";
import SendJobSummariesModal from "../modals/PostmarkResponsesModal";
import SimpleModal from "../modals/Simple";

interface ButtonOverrideProps {
  button: (buttonProps: { onPress: () => void }) => ReactElement;
}

interface DefaultButtonProps
  extends Pick<ButtonProps, "appearance" | "children" | "status" | "style"> {
  button?: never;
}

interface SendJobSummariesProps {
  jobIds: Array<string>;
}

interface SendJobSummariesState {
  loading: boolean;
  postmarkMessageSendingResponses?: Array<PostmarkMessageSendingResponse>;
  visible: boolean;
}

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  modal: {
    height: "50%",
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const SendJobSummariesButton = (
  props: SendJobSummariesProps & (ButtonOverrideProps | DefaultButtonProps),
) => {
  const { jobIds } = props;
  const requeryJobs = useRequeryJobs();
  const customersOfJobs = useSelector((state) =>
    selectCustomersByJobIds(state, jobIds),
  );
  const [sendJobSummariesState, setSendJobSummariesState] = useState<SendJobSummariesState>({
    loading: false,
    visible: false,
  });
  const [warningVisible, setWarningVisible] = useState(false);
  const unusableJobIds = customersOfJobs
    .filter(
      (customerOfJob) =>
        !customerOfJob.customer || customerOfJob.customer.emails.length === 0,
    )
    .map((customerOfJob) => customerOfJob.jobId);
  const usableJobIds = _.difference(jobIds, unusableJobIds);
  const runSendJobSummaries = async () => {
    setSendJobSummariesState({
      loading: true,
      visible: true,
      postmarkMessageSendingResponses: null,
    });
    try {
      const postmarkMessageSendingResponses = await sendJobSummaries({
        jobIds: usableJobIds,
      });
      setSendJobSummariesState((prevState) => ({
        ...prevState,
        loading: false,
        postmarkMessageSendingResponses,
      }));
      requeryJobs();
    } catch (error) {
      addNotification({
        message: error.message,
        status: "danger",
        title: "Failed to send job summaries",
      });
      setSendJobSummariesState((prevState) => ({
        ...prevState,
        loading: false,
        visible: false,
      }));
    }
  };
  const attemptSendJobSummaries = () => {
    if (unusableJobIds.length === jobIds.length) {
      addNotification({
        message:
          "None of the selected locations are linked to a customer with an email address.",
        status: "danger",
        title: "Cannot send job summaries",
      });
    } else if (unusableJobIds.length > 0) {
      setWarningVisible(true);
    } else {
      runSendJobSummaries();
    }
  };
  return (
    <>
      {props.button ? (
        props.button({ onPress: attemptSendJobSummaries })
      ) : (
        <Button
          appearance={props.appearance}
          onPress={attemptSendJobSummaries}
          status={props.status}
          style={props.style}
        >
          {props.children || "Send job summaries"}
        </Button>
      )}
      <SimpleModal
        isVisible={warningVisible}
        onBackdropPress={() => setWarningVisible(false)}
        onBackPress={() => setWarningVisible(false)}
        style={styles.modal}
      >
        <Separator size="medium" />
        <Text style={styles.text}>
          The following jobs will not be included because the locations are not
          linked to a customer with an email address.
        </Text>
        <Separator size="small" />
        <ScrollView>
          {unusableJobIds.map((jobId) => (
            <ScheduleJobListItem jobId={jobId} key={jobId} />
          ))}
        </ScrollView>
        <Separator size="small" />
        <Button
          onPress={() => {
            setWarningVisible(false);
            runSendJobSummaries();
          }}
          size="small"
          style={styles.button}
        >
          Continue
        </Button>
        <Separator size="small" />
        <Button
          appearance="ghost"
          onPress={() => setWarningVisible(false)}
          size="small"
          style={styles.button}
        >
          Cancel
        </Button>
        <Separator size="small" />
      </SimpleModal>
      <SendJobSummariesModal
        loading={sendJobSummariesState.loading}
        isVisible={sendJobSummariesState.visible}
        onClose={() =>
          setSendJobSummariesState((prevState) => ({
            ...prevState,
            visible: false,
          }))
        }
        postmarkMessageSendingResponses={
          sendJobSummariesState.postmarkMessageSendingResponses
        }
      />
    </>
  );
};

export default SendJobSummariesButton;
