import { Icon, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";
import Separator from "../../components/buildingBlocks/Separator";
import useCompanySettings from "../useCompanySettings";

const DescriptionRow = styled.View`
  align-items: center;
  flex-direction: row;
  padding-right: 6px;
  padding-left: 6px;
`;

const StyledIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const useChecklistItemUtils = (checklistItem: {
  description: string;
  dailyActivityReportRequired?: boolean;
}) => {
  const theme = useTheme();

  const { dailyActivityReportsEnabled } = useCompanySettings();

  const title = () => (
    <StyledText category="p1">{checklistItem.description}</StyledText>
  );

  const description = () => {
    if (
      dailyActivityReportsEnabled &&
      checklistItem.dailyActivityReportRequired
    ) {
      return (
        <>
          <Separator size={2} />
          <DescriptionRow>
            <StyledIcon fill={theme["text-hint-color"]} name="file-text" />
            <Separator horizontal size={4} />
            <Text appearance="hint" category="c1">
              Daily activity report required
            </Text>
          </DescriptionRow>
        </>
      );
    }
    return null;
  };

  return {
    ListItemProps: {
      description,
      title,
    },
  };
};

export default useChecklistItemUtils;
