/* eslint-disable react/jsx-props-no-spreading */
import {
  TopNavigationAction as UiKittenTopNavigationAction,
  TopNavigationActionProps,
} from "@ui-kitten/components";
import React from "react";
import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  root: {
    padding: 12,
  },
});

const TopNavigationAction = ({ style, ...props }: TopNavigationActionProps) => {
  return (
    <UiKittenTopNavigationAction
      // Seemingly difficult to press button on mobile.
      hitSlop={
        Platform.OS !== "web"
          ? {
              bottom: 8,
              left: 8,
              right: 8,
              top: 8,
            }
          : undefined
      }
      style={[styles.root, style]}
      {...props}
    />
  );
};

export default TopNavigationAction;
