import gql from "graphql-tag";

import { actionItemFragment } from "../../../fragments";

export default gql`
  mutation updateActionItem(
    $description: String!
    $id: ID!
    $dailyActivityReportRequired: Boolean
  ) {
    updateActionItem(
      description: $description
      id: $id
      dailyActivityReportRequired: $dailyActivityReportRequired
    ) {
      ...actionItemFragment
    }
  }
  ${actionItemFragment}
`;
