import gql from "graphql-tag";

import { customerFragment } from "../../../fragments";

export default gql`
  mutation restoreCustomer($id: ID!) {
    restoreCustomer(id: $id) {
      ...customerFragment
    }
  }
  ${customerFragment}
`;
