import { Text, useTheme } from "@ui-kitten/components";
import { ChildComponentProps } from "google-map-react";
import moment from "moment-timezone";
import React from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { useSelector } from "react-redux";

import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import selectUserById from "../../../store/users/selectors/selectUserById";

type Props = {
  id: string;
  onPress?: () => void;
  speed?: number;
  time: string;
} & ChildComponentProps;

const styles = StyleSheet.create({
  markerContainer: {
    borderRadius: 8,
    padding: 8,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  clickable: {
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    top: "100%",
    left: "50%",
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-100%" }],
  },
  tail: {
    width: 0,
    height: 0,
    borderLeftWidth: 4,
    borderLeftColor: "transparent",
    borderRightWidth: 4,
    borderRightColor: "transparent",
    borderTopWidth: 4,
  },
  whiteText: {
    color: "#FFFFFF",
  },
});

const UserDataInputMarker = ({ id, onPress, speed, time }: Props) => {
  const theme = useTheme();
  const user = useSelector((state) => selectUserById(state, id));
  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const color = theme["color-basic-700"];

  const handlePress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress();
  };
  return (
    <Pressable onPress={handlePress}>
      <View pointerEvents="box-none" style={styles.clickable}>
        <View style={[styles.markerContainer, { backgroundColor: color }]}>
          <Text
            style={styles.whiteText}
            category="s1"
            numberOfLines={1}
          >{`${user.firstName} ${user.lastName}`}</Text>
          <Text
            style={styles.whiteText}
            category="c1"
            numberOfLines={1}
          >{`${moment(time).format(dateTimeFormat)}${
            speed && speed >= 1
              ? ` \u2022 ${(speed * 2.237).toFixed(0)} mph`
              : " \u2022 Still"
          }`}</Text>
        </View>
        <View style={[styles.tail, { borderTopColor: color }]} />
      </View>
    </Pressable>
  );
};

UserDataInputMarker.defaultProps = {
  onPress: () => {},
  speed: 0,
};

export default UserDataInputMarker;
