import API, { GraphQLResult } from "@aws-amplify/api";

import { StripeTaxRate } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

const retrieveTaxRate = async (id: string) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        id,
      },
    })) as GraphQLResult<{
      retrieveTaxRate: StripeTaxRate;
    }>;
    return data.retrieveTaxRate;
  } catch ({ errors }) {
    throw generateErrorMessage("retrieveTaxRate", errors);
  }
};

export default retrieveTaxRate;
