import { Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { Pressable, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  markerContainer: {
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    justifyContent: "center",
    paddingHorizontal: 12,
    paddingVertical: 2,
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
});

interface SiteClusterMarkerProps {
  id: string;
  onPress: (id: string) => void;
  text: string;
}

const SiteClusterMarker = ({ id, onPress, text }: SiteClusterMarkerProps) => {
  const theme = useTheme();

  const handlePress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress(id);
  };
  return (
    <Pressable
      onPress={handlePress}
      style={[
        styles.markerContainer,
        { backgroundColor: theme["color-basic-600"] },
      ]}
    >
      <Text>{text}</Text>
    </Pressable>
  );
};

export default SiteClusterMarker;
