import { ButtonProps } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useState } from "react";

import Button from "../buildingBlocks/Button";
import { addNotification } from "../InAppNotifications";
import createBillingPortalSession from "../../api/functions/createBillingPortalSession";

const BillingPortalButton = ({
  appearance,
  size,
  status,
}: Pick<ButtonProps, "appearance" | "size" | "status">) => {
  const [
    createBillingPortalSessionPending,
    setCreateBillingPortalSessionPending,
  ] = useState(false);
  const runCreateBillingPortalSession = async () => {
    setCreateBillingPortalSessionPending(true);
    try {
      const { url } = await createBillingPortalSession(
        "https://app.talarium.io"
      );
      await WebBrowser.openBrowserAsync(url);
    } catch (e) {
      addNotification({
        message: e.message || "An unexpected error occurred",
        status: "danger",
        title: "Error",
      });
    }
    setCreateBillingPortalSessionPending(false);
  };
  return (
    <Button
      appearance={appearance}
      loading={createBillingPortalSessionPending}
      onPress={runCreateBillingPortalSession}
      size={size}
      status={status}
    >
      Launch portal
    </Button>
  );
};

export default BillingPortalButton;
