import gql from "graphql-tag";

import { customerFragment } from "../../../fragments";

export default gql`
  mutation createCustomer(
    $id: ID!
    $name: String!
    $notes: String!
    $emails: [CustomerEmailInput!]!
  ) {
    createCustomer(id: $id, name: $name, notes: $notes, emails: $emails) {
      ...customerFragment
    }
  }
  ${customerFragment}
`;
