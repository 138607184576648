import gql from "graphql-tag";

const createStripeLoginLink = gql`
  mutation createStripeLoginLink {
    createStripeLoginLink {
      url
      created
    }
  }
`;

export default createStripeLoginLink;
