import React from "react";

import { StripePrice } from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import ListItem, { ListItemProps } from "../Base";

interface ProductListItemProps extends Pick<ListItemProps, "accessoryRight"> {
  onPress: (price: StripePrice) => void;
  price: StripePrice;
}

const ProductListItem = ({
  accessoryRight,
  onPress,
  price,
}: ProductListItemProps) => (
  <ListItem
    accessoryRight={accessoryRight}
    description={`${formatCurrency(price.unit_amount, {
      currency: price.currency,
    })} ${price.currency.toUpperCase()}`}
    onPress={() => onPress(price)}
    title={price.product.name}
  />
);

export default ProductListItem;
