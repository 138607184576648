import API, { GraphQLResult } from "@aws-amplify/api";

import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

interface LoginLink {
  url: string;
  created: number;
}

const createStripeLoginLink = async () => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
    })) as GraphQLResult<{ createStripeLoginLink: LoginLink }>;
    return data.createStripeLoginLink;
  } catch ({ errors }) {
    throw generateErrorMessage("createStripeAccount", errors);
  }
};

export default createStripeLoginLink;
