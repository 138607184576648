import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedQuote } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export type CreateQuoteInput = Pick<
  Stripe.QuoteCreateParams,
  "customer" | "default_tax_rates" | "line_items"
>;

const createQuote = async ({
  customer,
  default_tax_rates,
  line_items,
}: CreateQuoteInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        customer,
        default_tax_rates,
        line_items,
      },
    })) as GraphQLResult<{ createQuote: StripeExpandedQuote }>;
    return data.createQuote;
  } catch ({ errors }) {
    throw generateErrorMessage("createQuote", errors);
  }
};

export default createQuote;
