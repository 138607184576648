import API, { GraphQLResult } from "@aws-amplify/api";

import { EmailActivity } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

interface QueryInvoiceEmailActivityInput {
  after?: string;
  id: string;
}

const queryInvoiceEmailActivity = async ({
  after,
  id,
}: QueryInvoiceEmailActivityInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        after,
        id,
      },
    })) as GraphQLResult<{
      queryInvoiceEmailActivity: {
        items: Array<EmailActivity>;
        nextToken?: string;
      };
    }>;
    return data.queryInvoiceEmailActivity;
  } catch ({ errors }) {
    throw generateErrorMessage("queryInvoiceEmailActivity", errors);
  }
};

export default queryInvoiceEmailActivity;
