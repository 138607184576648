import gql from "graphql-tag";

import { incidentReportFragment } from "../../../fragments";

const deleteIncidentReport = gql`
  mutation deleteIncidentReport($id: ID!, $jobId: ID!) {
    deleteIncidentReport(id: $id, jobId: $jobId) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default deleteIncidentReport;
