import API, { GraphQLResult } from "@aws-amplify/api";

import { StripePrice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

const retrievePrice = async (id: string) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        id,
      },
    })) as GraphQLResult<{
      retrievePrice: StripePrice;
    }>;
    return data.retrievePrice;
  } catch ({ errors }) {
    throw generateErrorMessage("retrievePrice", errors);
  }
};

export default retrievePrice;
