import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setUserPending } from "../../../store/users/slice";

const dispatchOptimisticResponse = ({ firstName, lastName, role }, user) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const timestamp = new Date().toISOString();
  dispatch(
    setUserPending({
      mutation: "adminUpdateCompanyUser",
      user: {
        ...user,
        firstName,
        lastName,
        updated: {
          at: timestamp,
          by: userId,
        },
        syncDateTime: timestamp,
        role,
      },
    })
  );
};

const adminUpdateCompanyUser = ({ firstName, lastName, role, id }, user) => {
  dispatchOptimisticResponse(
    {
      firstName,
      lastName,
      role,
      id,
    },
    user
  );
  client.mutate({
    mutation,
    variables: {
      firstName,
      lastName,
      role,
      id,
    },
    errorPolicy: "all",
  });
};

export default adminUpdateCompanyUser;
