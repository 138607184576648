import dayjs from "dayjs";
import * as Location from "expo-location";

import updateCompanyUserStatus from "../../api/functions/updateCompanyUserStatus";
import store from "../../store";
import { setCoordinates } from "../../store/mapSelection/slice";

const setStoreCoordinates = (locationObject: Location.LocationObject) => {
  const { coords, timestamp } = locationObject;
  const {
    accuracy,
    altitude,
    altitudeAccuracy,
    heading,
    latitude,
    longitude,
    speed,
  } = coords;
  store.dispatch(
    setCoordinates({
      coordinates: {
        accuracy,
        altitude,
        altitudeAccuracy,
        heading,
        latitude,
        longitude,
        speed,
        timestamp,
      },
    })
  );
};

export const getCurrentLocation = async (
  accuracy: Location.LocationAccuracy
) => {
  try {
    const locationObject = await Location.getCurrentPositionAsync({ accuracy });
    setStoreCoordinates(locationObject);
  } catch (error) {
    // Do nothing
  }
};

export const stopLocationTracking = () => null;

export const startLocationTracking = async () => {
  try {
    const { coords, timestamp } = await Location.getCurrentPositionAsync({
      accuracy: Location.LocationAccuracy.High,
    });
    const { accuracy, heading, latitude, longitude, speed } = coords;
    updateCompanyUserStatus({
      coordinates: {
        accuracy,
        lat: latitude,
        lng: longitude,
      },
      createdAt: dayjs(timestamp).toISOString(),
      heading,
      speed,
    });
  } catch (error) {
    // Do nothing
  }
};
