import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setGroupPending } from "../../../store/groups/slice";

const dispatchOptimisticResponse = ({ name, members }, group) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  const timestamp = new Date().toISOString();
  dispatch(
    setGroupPending({
      group: {
        ...group,
        name,
        members,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
      mutation: "adminUpdateCompanyGroup",
    })
  );
};

const adminUpdateCompanyGroup = ({ id, name, members }, group) => {
  dispatchOptimisticResponse(
    {
      id,
      name,
      members,
    },
    group
  );
  client.mutate({
    mutation,
    variables: {
      id,
      name,
      members,
    },
    errorPolicy: "all",
  });
};

export default adminUpdateCompanyGroup;
