import Pusher from "pusher-js";

import init from "./init";

if (__DEV__) Pusher.logToConsole = true;

const initPusherClient = () => {
  return init(Pusher);
};

export default initPusherClient;
