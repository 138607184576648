import store from "../../../store";
import { setJobPending } from "../../../store/jobs/slice";
import { Job } from "../../../types";
import client from "../../client";
import mutation from "./graphql";

interface DeleteTaskCompletionRecordInput {
  taskId: string;
}

const dispatchOptimisticResponse = (
  { taskId }: DeleteTaskCompletionRecordInput,
  job: Job
) => {
  const { dispatch } = store;
  dispatch(
    setJobPending({
      job: {
        ...job,
        taskCompletionRecords: job.taskCompletionRecords.filter(
          (taskCompletionRecord) => taskCompletionRecord.taskId !== taskId
        ),
        syncDateTime: new Date().toISOString(),
      },
      mutation: "deleteTaskCompletionRecord",
    })
  );
};

const deleteTaskCompletionRecord = (
  { taskId }: DeleteTaskCompletionRecordInput,
  job: Job
) => {
  dispatchOptimisticResponse(
    {
      taskId,
    },
    job
  );
  client.mutate({
    mutation,
    variables: {
      jobId: job.id,
      taskId,
    },
    errorPolicy: "all",
  });
};

export default deleteTaskCompletionRecord;
