import { createSelector } from "@reduxjs/toolkit";

import selectCustomers from "../selectCustomers";

const selectCustomerEntities = createSelector(
  [selectCustomers],
  (customers) => customers.entities
);

export default selectCustomerEntities;
