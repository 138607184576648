import { createSelector } from "@reduxjs/toolkit";

import selectCustomers from "../selectCustomers";
import selectSitesByJobIds from "../../../sites/selectors/selectSitesByJobIds";

const selectCustomersByJobIds = createSelector(
  [selectCustomers, selectSitesByJobIds],
  (customers, sitesOfJobs) =>
    sitesOfJobs.map((siteOfJob) => ({
      customer: customers.entities[siteOfJob.site.customerId],
      jobId: siteOfJob.jobId,
    }))
);

export default selectCustomersByJobIds;
