import gql from "graphql-tag";

const assigneeFragment = gql`
  fragment assigneeFragment on Assignee {
    id
    type
  }
`;

const userRecordFragment = gql`
  fragment userRecordFragment on UserRecord {
    at
    by
  }
`;

export const actionItemFragment = gql`
  fragment actionItemFragment on ActionItem {
    created {
      ...userRecordFragment
    }
    description
    id
    isDeleted
    syncDateTime
    updated {
      ...userRecordFragment
    }
    dailyActivityReportRequired
  }
  ${userRecordFragment}
`;

export const companyGroupFragment = gql`
  fragment companyGroupFragment on CompanyGroup {
    id
    members
    name
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isDeleted
  }
  ${userRecordFragment}
`;

const coordinatesFragment = gql`
  fragment coordinatesFragment on Coordinates {
    lat
    lng
    accuracy
  }
`;

const userLocationRecordFragment = gql`
  fragment userLocationRecordFragment on UserLocationRecord {
    at
    by
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

export const companyUserFragment = gql`
  fragment companyUserFragment on CompanyUser {
    id
    firstName
    lastName
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    coordinates {
      ...coordinatesFragment
    }
    locationUpdatedAt
    syncDateTime
    role
    speed
    heading
    isOnline
    isArchived
    clockedIn {
      ...userLocationRecordFragment
    }
    unmeteredUsage
  }
  ${coordinatesFragment}
  ${userLocationRecordFragment}
  ${userRecordFragment}
`;

export const customerFragment = gql`
  fragment customerFragment on Customer {
    id
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    name
    notes
    emails {
      id
      email
    }
    syncDateTime
    isArchived
    stripeCustomerId
  }
  ${userRecordFragment}
`;

const locationRecordFragment = gql`
  fragment locationRecordFragment on LocationRecord {
    at
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

const mediaFragment = gql`
  fragment mediaFragment on Media {
    id
    description
    createdAt
    type
  }
`;

export const dailyActivityReportFragment = gql`
  fragment dailyActivityReportFragment on DailyActivityReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    started {
      ...locationRecordFragment
    }
    completed {
      ...locationRecordFragment
    }
    summary
    media {
      ...mediaFragment
    }
    syncDateTime
  }
  ${locationRecordFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

export const incidentReportFragment = gql`
  fragment incidentReportFragment on IncidentReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    incidentReportStartDateTime: startDateTime
    incidentReportEndDateTime: endDateTime
    summary
    policeCase {
      agency
      number
    }
    subjects {
      name
      sex
      ethnicity
      race
      phoneNumber
      address
      license {
        number
        region
      }
      description
      type
      height
      weight
      dateOfBirth
      age
      hairColor
      eyeColor
    }
    vehicles {
      description
      make
      model
      year
      color
      license {
        number
        region
      }
      vin
      registeredOwner
      type
      status
    }
    media {
      createdAt
      description
      type
      id
    }
    syncDateTime
  }
  ${userRecordFragment}
`;

const taskFragment = gql`
  fragment taskFragment on Task {
    id
    description
    dailyActivityReportRequired
  }
`;

export const startJobRecordFragment = gql`
  fragment startJobRecordFragment on StartJobRecord {
    record {
      ...userLocationRecordFragment
    }
  }
  ${userLocationRecordFragment}
`;

export const taskCompletionRecordFragment = gql`
  fragment taskCompletionRecordFragment on TaskCompletionRecord {
    taskId
    record {
      ...userLocationRecordFragment
    }
    media {
      ...mediaFragment
    }
    dailyActivityReportId
  }
  ${mediaFragment}
  ${userLocationRecordFragment}
`;

export const jobFragment = gql`
  fragment jobFragment on Job {
    id
    startDateTime
    endDateTime
    siteId
    syncId
    assignedTo {
      ...assigneeFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    tasks {
      ...taskFragment
    }
    finished {
      status
      record {
        ...userLocationRecordFragment
      }
    }
    startJobRecords {
      ...startJobRecordFragment
    }
    taskCompletionRecords {
      ...taskCompletionRecordFragment
    }
    actionItemCompletionRecords {
      actionItemId
      record {
        ...userLocationRecordFragment
      }
      media {
        ...mediaFragment
      }
    }
    commuteRecords {
      at
      by
      coordinates {
        lat
        lng
      }
      duration {
        text
        value
      }
      distance {
        text
        value
      }
    }
    syncDateTime
    isDeleted
    rrule
    processed
    immediate
    lastSent {
      ...userRecordFragment
    }
    includeCreatorInMessages
    autostart
  }
  ${assigneeFragment}
  ${mediaFragment}
  ${startJobRecordFragment}
  ${taskCompletionRecordFragment}
  ${taskFragment}
  ${userLocationRecordFragment}
  ${userRecordFragment}
`;

export const locationHistory = gql`
  fragment locationHistory on LocationHistory {
    coordinates {
      ...coordinatesFragment
    }
    created {
      ...userRecordFragment
    }
    speed
    heading
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;

export const privateCompanySiteFragment = gql`
  fragment privateCompanySiteFragment on CompanySite {
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isArchived
    coordinates {
      ...coordinatesFragment
    }
    address
    name
    geofence
    notes
    privateNotes
    timezone
    customerId
    media {
      ...mediaFragment
    }
  }
  ${coordinatesFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

export const publicCompanySiteFragment = gql`
  fragment publicCompanySiteFragment on CompanySite {
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    isArchived
    coordinates {
      ...coordinatesFragment
    }
    address
    name
    geofence
    notes
    timezone
    customerId
    media {
      ...mediaFragment
    }
  }
  ${coordinatesFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

export const timecardFragment = gql`
  fragment timecardFragment on Timecard {
    id
    started {
      ...userLocationRecordFragment
    }
    quit {
      ...userLocationRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
  }
  ${userLocationRecordFragment}
  ${userRecordFragment}
`;

export const usageRecordFragment = gql`
  fragment usageRecordFragment on UsageRecord {
    timestamp
    userId
  }
`;

const jobSettingsFragment = gql`
  fragment jobSettingsFragment on JobSettings {
    dailyActivityReportsEnabled
    incidentReportsEnabled
  }
`;

const companyFeaturesFragment = gql`
  fragment companyFeaturesFragment on CompanyFeatures {
    jobSummaries
    messaging
    payments
    reminders
    reports
    timeCards
  }
`;

export const companyFragment = gql`
  fragment companyFragment on Company {
    name
    id
    syncDateTime
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    billing {
      customerId
      currentPeriodStart
      currentPeriodEnd
      subscriptionId
      mauSubscriptionItemId
      status
    }
    logo {
      ...mediaFragment
    }
    jobSettings {
      ...jobSettingsFragment
    }
    stripeConnectAccountId
    isDisabled
    features {
      ...companyFeaturesFragment
    }
    customersEnabled
  }
  ${companyFeaturesFragment}
  ${jobSettingsFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

export const emailActivityFragment = gql`
  fragment emailActivityFragment on EmailActivity {
    id
    title
    description
    content {
      id
      type
    }
    type
    recipient
    eventDateTime
  }
`;

export const inboxItemFragment = gql`
  fragment inboxItemFragment on InboxItem {
    id
    completed {
      ...userRecordFragment
    }
    createdAt
    title
    inboxItemDescription: description
    type
    syncDateTime
    metadata {
      customerId
      dateTime
      userId
    }
    createdBy
  }
  ${userRecordFragment}
`;

export const stripeCustomerFragment = gql`
  fragment stripeCustomerFragment on StripeCustomer {
    id
    invoice_prefix
  }
`;

export const stripeProductFragment = gql`
  fragment stripeProductFragment on StripeProduct {
    created
    id
    name
    updated
  }
`;

export const stripePriceFragment = gql`
  fragment stripePriceFragment on StripePrice {
    active
    created
    currency
    id
    metadata {
      taxesEnabled
    }
    product {
      ...stripeProductFragment
    }
    unit_amount
  }
  ${stripeProductFragment}
`;

export const stripeTaxRateFragment = gql`
  fragment stripeTaxRateFragment on StripeTaxRate {
    active
    created
    description
    display_name
    id
    inclusive
    percentage
  }
`;

export const stripeExpandedInvoiceItemFragment = gql`
  fragment stripeExpandedInvoiceItemFragment on StripeExpandedInvoiceItem {
    amount
    currency
    description
    id
    price {
      ...stripePriceFragment
    }
    quantity
  }
  ${stripePriceFragment}
`;

const stripeInvoiceItemFragment = gql`
  fragment stripeInvoiceItemFragment on StripeInvoiceItem {
    amount
    currency
    description
    id
    price {
      ...stripePriceFragment
    }
    quantity
  }
  ${stripePriceFragment}
`;

const stripeInvoiceStatusTransitionsFragment = gql`
  fragment stripeInvoiceStatusTransitionsFragment on StripeInvoiceStatusTransitions {
    finalized_at
    marked_uncollectible_at
    paid_at
    voided_at
  }
`;

export const stripeInvoiceFragment = gql`
  fragment stripeInvoiceFragment on StripeInvoice {
    amount_due
    created
    currency
    customer
    default_tax_rates {
      ...stripeTaxRateFragment
    }
    due_date
    hosted_invoice_url
    id
    invoice_pdf
    number
    paid
    status
    status_transitions {
      ...stripeInvoiceStatusTransitionsFragment
    }
    subtotal
    total
    total_tax_amounts {
      amount
      inclusive
      tax_rate
    }
  }
  ${stripeInvoiceStatusTransitionsFragment}
  ${stripeTaxRateFragment}
`;

const stripeExpandedQuoteLineItemFragment = gql`
  fragment stripeExpandedQuoteLineItemFragment on StripeExpandedQuoteLineItem {
    amount_subtotal
    amount_total
    currency
    description
    id
    price {
      ...stripePriceFragment
    }
    quantity
    taxes {
      rate {
        ...stripeTaxRateFragment
      }
    }
  }
  ${stripePriceFragment}
  ${stripeTaxRateFragment}
`;

const stripeQuoteStatusTransitionsFragment = gql`
  fragment stripeQuoteStatusTransitionsFragment on StripeQuoteStatusTransitions {
    accepted_at
    canceled_at
    finalized_at
  }
`;

export const stripeExpandedQuoteFragment = gql`
  fragment stripeExpandedQuoteFragment on StripeExpandedQuote {
    amount_subtotal
    amount_total
    created
    currency
    customer {
      ...stripeCustomerFragment
    }
    default_tax_rates {
      ...stripeTaxRateFragment
    }
    description
    discounts
    expires_at
    id
    invoice {
      ...stripeInvoiceFragment
    }
    line_items {
      data {
        ...stripeExpandedQuoteLineItemFragment
      }
      has_more
    }
    number
    status
    status_transitions {
      ...stripeQuoteStatusTransitionsFragment
    }
    total_details {
      amount_tax
      breakdown {
        taxes {
          amount
          rate {
            ...stripeTaxRateFragment
          }
        }
      }
    }
  }
  ${stripeCustomerFragment}
  ${stripeExpandedQuoteLineItemFragment}
  ${stripeInvoiceFragment}
  ${stripeQuoteStatusTransitionsFragment}
  ${stripeTaxRateFragment}
`;

export const stripeQuoteFragment = gql`
  fragment stripeQuoteFragment on StripeQuote {
    amount_subtotal
    amount_total
    created
    currency
    customer
    default_tax_rates
    discounts
    expires_at
    id
    number
    status
    status_transitions {
      ...stripeQuoteStatusTransitionsFragment
    }
    total_details {
      amount_tax
    }
  }
  ${stripeQuoteStatusTransitionsFragment}
`;

export const stripeExpandedInvoiceFragment = gql`
  fragment stripeExpandedInvoiceFragment on StripeExpandedInvoice {
    amount_due
    currency
    customer {
      ...stripeCustomerFragment
    }
    created
    default_tax_rates {
      ...stripeTaxRateFragment
    }
    due_date
    hosted_invoice_url
    id
    invoice_pdf
    lines {
      data {
        ...stripeExpandedInvoiceItemFragment
      }
      has_more
    }
    number
    paid
    quote {
      ...stripeQuoteFragment
    }
    status
    status_transitions {
      ...stripeInvoiceStatusTransitionsFragment
    }
    subtotal
    total
    total_tax_amounts {
      amount
      inclusive
      tax_rate {
        ...stripeTaxRateFragment
      }
    }
  }
  ${stripeCustomerFragment}
  ${stripeExpandedInvoiceItemFragment}
  ${stripeInvoiceStatusTransitionsFragment}
  ${stripeQuoteFragment}
  ${stripeTaxRateFragment}
`;
