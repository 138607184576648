import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { Fragment, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import adminArchiveCompanyUser from "../../api/functions/adminArchiveCompanyUser";
import adminClockOutUser from "../../api/functions/adminClockOutUser";
import adminRestoreCompanyUser from "../../api/functions/adminRestoreCompanyUser";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import Label from "../../components/Label";
import BaseListItem from "../../components/listItems/Base";
import ScheduleJobListItem from "../../components/listItems/ScheduleJob";
import StaticMapView from "../../components/map/StaticMapView";
import UserMarker from "../../components/markers/User";
import AdminSetPasswordModal from "../../components/modals/AdminSetPassword";
import AlertModal from "../../components/modals/Alert";
import EditUserModal from "../../components/modals/EditUserModal";
import UserLocationHistoryModal from "../../components/modals/UserLocationHistory";
import SectionItem from "../../components/SectionItem";
import UiKittenTouchable from "../../components/UiKittenTouchable";
import UserMapCircle from "../../components/UserMapCircle";
import UserRecord from "../../components/UserRecord";
import UserStatusDot from "../../components/UserStatusDot";
import assigneeTypes from "../../constants/assigneeTypes";
import companyUserRoles from "../../constants/companyUserRoles";
import useIosBottomSpace from "../../device/useIosBottomSpace";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectGroups from "../../store/groups/selectors/selectGroups";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../store/jobs/selectors/selectJobEntities";
import selectUserById from "../../store/users/selectors/selectUserById";
import { StackParamList } from "../../types";
import getDeltaFromZoom from "../../utils/getDeltaFromZoom";
import isFeatureEnabled from "../../utils/isFeatureEnabled";
import { navigateWithGoogleMaps } from "../../utils/map";
import sortDateTimes from "../../utils/sortDateTimes";

const zoom = 15;
const delta = getDeltaFromZoom(zoom);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  labelContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  lastNameContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  listItemAction: {
    marginEnd: 16,
    marginStart: 16,
  },
  listItemAccessory: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  mapContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  mapFrame: {
    borderRadius: 4,
    overflow: "hidden",
  },
  nameContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingEnd: 16,
    paddingStart: 16,
  },
  navigateButton: {
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  snackbar: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const UserDetails = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "UserDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "UserDetails">>();
  const { userId } = route.params;
  const user = useSelector((state) => selectUserById(state, userId));
  const signedInUser = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const accessibleJobs = useSelector(selectMapJobs).accessible;
  const jobs = useSelector(selectJobEntities);
  const groups = useSelector(selectGroups).entities;
  const iosBottomSpace = useIosBottomSpace();
  const theme = useTheme();

  const [archiveConfirmationVisible, setArchiveConfirmationVisible] = useState(
    false
  );
  const [restoreConfirmationVisible, setRestoreConfirmationVisible] = useState(
    false
  );
  const [editVisible, setEditVisible] = useState(false);
  const [showLocationHistory, setShowLocationHistory] = useState(false);
  const [adminPasswordChangeVisible, setAdminPasswordChangeVisible] = useState(
    false
  );

  const handleLocationHistoryPress = () => setShowLocationHistory(true);
  const handleLocationHistoryClose = () => setShowLocationHistory(false);
  const handleClockOutUser = () => {
    if (!user.clockedIn)
      addNotification({
        title: "Error",
        message: "User is not clocked in.",
        status: "danger",
      });
    else {
      adminClockOutUser(
        {
          coordinates: user.coordinates,
          timestamp: new Date().toISOString(),
          userIdToClockOut: user.id,
          id: uuidv4(),
        },
        user
      );
    }
  };
  const handleJobPress = (id) => {
    navigation.navigate("JobDetails", { jobId: id });
  };
  const renderJobs = () => {
    const usersJobIds = {};
    accessibleJobs.forEach((jobId) => {
      const { assignedTo } = jobs[jobId];
      assignedTo.forEach((assignee) => {
        if (
          (assignee.type === assigneeTypes.user && assignee.id === userId) ||
          (assignee.type === assigneeTypes.group &&
            groups[assignee.id].members.includes(userId))
        ) {
          usersJobIds[jobId] = jobId;
        }
      });
    });

    const sortedJobIds = Object.keys(usersJobIds).sort((a, b) =>
      sortDateTimes(b, a)
    );

    if (sortedJobIds.length > 0) {
      return sortedJobIds.map((jobId, index) => (
        <Fragment key={jobId}>
          {index > 0 && <Divider style={styles.divider} />}
          <ScheduleJobListItem
            jobId={jobId}
            key={jobId}
            onPress={handleJobPress}
            showFullDate
          />
        </Fragment>
      ));
    }
    return <ListItem title="No accessible jobs" disabled />;
  };

  const timeCardsEnabled =
    !company || isFeatureEnabled({ company, feature: "timeCards" });

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              action={{
                loading: false,
                onPress: () => setEditVisible(true),
                text: "Edit",
                "data-test": "editDetailsEditButton",
              }}
              isActionVisible={signedInUser.group === companyUserRoles.ADMIN}
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="User details"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: iosBottomSpace + 8 }}
          >
            <Container>
              <Layout>
                <Separator size="medium" />
                <View style={styles.nameContainer}>
                  <Text category="h6">{`${user.firstName} `}</Text>
                  <View style={styles.lastNameContainer}>
                    <Text category="h6">{user.lastName}</Text>
                    {timeCardsEnabled && (
                      <>
                        <Separator horizontal size="small" />
                        <UserStatusDot online={Boolean(user.clockedIn)} />
                      </>
                    )}
                  </View>
                </View>
                <Text category="p1" style={styles.text}>
                  {user.role}
                </Text>
                {user.isArchived && (
                  <>
                    <Separator size="medium" />
                    <View style={styles.labelContainer}>
                      <Label status="danger">Archived</Label>
                    </View>
                  </>
                )}
                <Separator size="medium" />
                <UiKittenTouchable
                  onPress={() =>
                    navigateWithGoogleMaps({
                      destination: {
                        latitude: user.coordinates.lat,
                        longitude: user.coordinates.lng,
                      },
                    })
                  }
                  style={styles.listItemAction}
                >
                  <BaseListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="navigation-2-outline" />
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="external-link-outline" />
                    )}
                    disabled
                    style={{
                      backgroundColor: theme["background-basic-color-2"],
                    }}
                    title="Navigate"
                  />
                </UiKittenTouchable>
                {timeCardsEnabled &&
                  signedInUser.group !== companyUserRoles.EMPLOYEE && (
                    <>
                      <Separator size="small" />
                      <UiKittenTouchable
                        onPress={handleLocationHistoryPress}
                        style={styles.listItemAction}
                      >
                        <BaseListItem
                          accessoryLeft={(imageProps) => (
                            <Icon
                              {...imageProps}
                              pack="MaterialIcons"
                              name="timeline"
                            />
                          )}
                          accessoryRight={(imageProps) => (
                            <Icon
                              {...imageProps}
                              name="arrow-ios-forward-outline"
                            />
                          )}
                          disabled
                          style={{
                            backgroundColor: theme["background-basic-color-2"],
                          }}
                          title="Location history"
                        />
                      </UiKittenTouchable>
                      <Separator size="small" />
                      <UiKittenTouchable
                        onPress={handleClockOutUser}
                        style={styles.listItemAction}
                      >
                        <BaseListItem
                          accessoryLeft={(imageProps) => (
                            <Icon {...imageProps} name="clock-outline" />
                          )}
                          disabled
                          style={{
                            backgroundColor: theme["background-basic-color-2"],
                          }}
                          title="Clock out user"
                        />
                      </UiKittenTouchable>
                    </>
                  )}
                <Separator size="medium" />
              </Layout>
              {signedInUser.group === companyUserRoles.ADMIN && (
                <>
                  <Separator />
                  <SectionItem title="Jobs">{renderJobs()}</SectionItem>
                </>
              )}
              {timeCardsEnabled &&
                Boolean(
                  user.clockedIn &&
                    user.coordinates &&
                    signedInUser.group !== companyUserRoles.EMPLOYEE
                ) && (
                  <>
                    <Separator />
                    <View style={styles.mapContainer}>
                      <Layout level="2" style={styles.mapFrame}>
                        <StaticMapView
                          aspectRatio={{ height: 1, width: 3 }}
                          region={{
                            latitude: user.coordinates.lat,
                            longitude: user.coordinates.lng,
                            latitudeDelta: delta,
                            longitudeDelta: delta,
                          }}
                        >
                          {user.coordinates.accuracy && (
                            <UserMapCircle
                              center={{
                                latitude: user.coordinates.lat,
                                longitude: user.coordinates.lng,
                              }}
                              radius={user.coordinates.accuracy}
                            />
                          )}
                          <UserMarker
                            id={userId}
                            lat={user.coordinates.lat}
                            lng={user.coordinates.lng}
                          />
                        </StaticMapView>
                      </Layout>
                    </View>
                  </>
                )}
              {signedInUser.group === companyUserRoles.ADMIN && (
                <>
                  <Separator />
                  <SectionItem status="danger" title="DANGER ZONE">
                    <ListItem
                      accessoryRight={() => (
                        <View style={styles.listItemAccessory}>
                          <Button
                            data-test="changePasswordButton"
                            onPress={() => setAdminPasswordChangeVisible(true)}
                            size="small"
                            status="danger"
                          >
                            Change
                          </Button>
                        </View>
                      )}
                      description="You will need to notify them of the password change."
                      disabled
                      title="Change their password"
                    />
                    <Divider style={styles.divider} />
                    {!user.isArchived ? (
                      <ListItem
                        accessoryRight={() => (
                          <View style={styles.listItemAccessory}>
                            <Button
                              data-test="archiveUserButton"
                              onPress={() => {
                                if (signedInUser.id === user.id) {
                                  addNotification({
                                    message: "You cannot archive yourself.",
                                    status: "danger",
                                  });
                                } else {
                                  setArchiveConfirmationVisible(true);
                                }
                              }}
                              size="small"
                              status="danger"
                            >
                              Archive
                            </Button>
                          </View>
                        )}
                        description="Disables the user's account thus terminating their access to Talarium. Their information is not be deleted."
                        disabled
                        title="Archive this user"
                      />
                    ) : (
                      <ListItem
                        accessoryRight={() => (
                          <View style={styles.listItemAccessory}>
                            <Button
                              data-test="restoreUserButton"
                              onPress={() => {
                                setRestoreConfirmationVisible(true);
                              }}
                              size="small"
                              status="danger"
                            >
                              Restore
                            </Button>
                          </View>
                        )}
                        description="Re-enables the user's account and grants them access Talarium."
                        disabled
                        title="Restore this user"
                      />
                    )}
                  </SectionItem>
                </>
              )}
              <Separator />
              <UserRecord
                label="Created:"
                style={styles.text}
                userRecord={user.created}
              />
              <Separator size="small" />
              <UserRecord
                label="Updated:"
                style={styles.text}
                userRecord={user.updated}
              />
              <Separator />
            </Container>
          </ScrollView>
        </Layout>
      </Layout>
      <UserLocationHistoryModal
        userId={userId}
        onClose={handleLocationHistoryClose}
        isVisible={showLocationHistory}
      />
      <AdminSetPasswordModal
        isVisible={adminPasswordChangeVisible}
        onCancelPress={() => setAdminPasswordChangeVisible(false)}
        onPasswordChange={() => setAdminPasswordChangeVisible(false)}
        userId={userId}
      />
      <EditUserModal
        isVisible={editVisible}
        onClose={() => setEditVisible(false)}
        user={user}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Archive"
        isVisible={archiveConfirmationVisible}
        message="Archive this user?"
        onCancel={() => setArchiveConfirmationVisible(false)}
        onClose={() => setArchiveConfirmationVisible(false)}
        onConfirm={() => {
          adminArchiveCompanyUser(user);
          addNotification({
            status: "success",
            title: "User archived",
          });
          setArchiveConfirmationVisible(false);
          navigation.goBack();
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Restore"
        isVisible={restoreConfirmationVisible}
        message="Restore this user?"
        onCancel={() => setRestoreConfirmationVisible(false)}
        onClose={() => setRestoreConfirmationVisible(false)}
        onConfirm={() => {
          adminRestoreCompanyUser(user);
          addNotification({
            status: "success",
            title: "User restored",
          });
          setRestoreConfirmationVisible(false);
        }}
      />
    </>
  );
};

export default UserDetails;
