import { useEffect } from "react";
import { BackHandler } from "react-native";

const useAndroidBackHandler = ({
  enabled,
  onBackPress,
}: {
  enabled: boolean;
  onBackPress?: () => void;
}) => {
  useEffect(() => {
    let backHandler;
    if (enabled && onBackPress) {
      backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
        onBackPress();
        return true;
      });
    }
    return () => {
      if (backHandler) {
        backHandler.remove();
      }
    };
  });
};

export default useAndroidBackHandler;
