import {
  Button,
  Icon,
  Layout,
  ListItem,
  Spinner as UkSpinner,
  Text,
} from "@ui-kitten/components";
import React, { memo, useEffect, useRef, useState } from "react";
import { Animated, ScrollView, StyleSheet, View } from "react-native";

import Container from "../../Container";
import PendingMutationListItem from "../../listItems/PendingMutation";
import Portal from "../../Portal";
import appsyncClient from "../../../api/client";

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    bottom: 0,
    end: 0,
    justifyContent: "center",
    position: "absolute",
    start: 0,
    top: 0,
  },
  content: {
    marginBottom: 8,
    marginTop: 12,
  },
  footer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  listAccessory: {
    marginHorizontal: 8,
  },
  message: {
    marginEnd: 16,
    marginStart: 16,
  },
  modal: {
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 16,
    maxWidth: 600,
  },
  modalContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  scroll: {
    maxHeight: 168,
  },
  scrollContent: {
    paddingVertical: 8,
  },
});

// Created a standalone Spinner SPECIFICALLY FOR THIS MODAL because the Spinner
// resets its position whenever a re-render occurs. This pure component allows
// the spinner to keep spinning.
const Spinner = memo(() => (
  <View style={styles.listAccessory}>
    <UkSpinner />
  </View>
));

interface Props {
  isVisible: boolean;
  title: string;
  continueDescription: string;
  onCancel: () => void;
  onContinue: () => void;
  onEmptyOutbox: () => void;
}

const PendingMutationsModal = ({
  isVisible,
  title,
  continueDescription,
  onCancel,
  onContinue,
  onEmptyOutbox,
}: Props) => {
  const [componentRendered, setComponentRendered] = useState(isVisible);
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const componentMounted = useRef(false);

  const [offline, setOffline] = useState(() => {
    const {
      offline: { online, outbox },
      // @ts-ignore
    } = appsyncClient.cache.store.getState();
    return {
      online,
      outbox,
    };
  });
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (isVisible && !componentRendered) {
      setComponentRendered(true);
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else if (!isVisible && componentRendered) {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        if (componentMounted.current) {
          setComponentRendered(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  useEffect(() => {
    let interval;
    if (isVisible) {
      const updateOffline = () => {
        const {
          offline: { online, outbox },
          // @ts-ignore
        } = appsyncClient.cache.store.getState();
        setOffline({ online, outbox });
      };
      updateOffline();
      interval = setInterval(updateOffline, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isVisible]);
  const numberOfPendingMutations = offline.outbox.length;
  useEffect(() => {
    if (isVisible && numberOfPendingMutations === 0) {
      onEmptyOutbox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, numberOfPendingMutations]);
  if (!componentRendered) {
    return null;
  }
  return (
    <Portal>
      <Animated.View style={[styles.backdrop, { opacity: fadeAnimation }]}>
        <Container>
          <View style={styles.modalContainer}>
            <Layout style={styles.modal}>
              <View style={styles.content}>
                {offline.online ? (
                  <ListItem
                    accessoryLeft={Spinner}
                    title={() => <Text style={styles.message}>{title}</Text>}
                  />
                ) : (
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="alert-circle-outline" />
                    )}
                    title={() => (
                      <Text style={styles.message}>
                        Some changes have not been uploaded to the server. A
                        sync will eventually occur when the internet is
                        reachable.
                      </Text>
                    )}
                  />
                )}
              </View>
              {offline.outbox.length === 0 && (
                <Text style={styles.message}>No pending network requests.</Text>
              )}
              <ScrollView
                contentContainerStyle={styles.scrollContent}
                style={styles.scroll}
              >
                {offline.outbox.map((pendingMutation) => (
                  <PendingMutationListItem
                    key={pendingMutation.meta.transaction}
                    pendingMutation={pendingMutation}
                  />
                ))}
              </ScrollView>
              <View style={styles.footer}>
                <Button onPress={onContinue}>{continueDescription}</Button>
                <View style={styles.footerSeparator} />
                <Button onPress={onCancel} appearance="ghost">
                  Cancel
                </Button>
              </View>
            </Layout>
          </View>
        </Container>
      </Animated.View>
    </Portal>
  );
};

export default PendingMutationsModal;
