import { useTheme } from "@ui-kitten/components";
import React, { PropsWithChildren, useState } from "react";
import {
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

interface Props {
  disabled?: boolean;
  onPress?: (event) => void;
  style?: StyleProp<ViewStyle>;
  checked?: boolean;
}

const styles = StyleSheet.create({
  pressedOverlay: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
    ...(Platform.OS === "web" && { cursor: "pointer" }),
  },
  root: {
    borderRadius: 8,
    overflow: "hidden",
  },
});

const UiKittenTouchable = ({
  children,
  disabled,
  onPress,
  style,
  checked,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const [pressed, setPressed] = useState(false);

  return (
    <View style={[styles.root, style]}>
      {children}
      {!disabled && (
        <Pressable
          onPress={onPress}
          onPressIn={() => setPressed(true)}
          onPressOut={() => setPressed(false)}
          style={[
            styles.pressedOverlay,
            (pressed || checked) && {
              backgroundColor: theme["color-basic-transparent-active"],
            },
          ]}
        />
      )}
    </View>
  );
};

UiKittenTouchable.defaultProps = {
  disabled: false,
  onPress: () => null,
  style: undefined,
  checked: false,
};

export default UiKittenTouchable;
