import { Text, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";

import { StripeQuote } from "../../types";

interface StripeQuoteStatusTextProps {
  quote: Pick<StripeQuote, "status">;
}

const StyledText = styled(Text)`
  border-radius: 8px;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
`;

const StripeQuoteStatusText = ({ quote }: StripeQuoteStatusTextProps) => {
  const theme = useTheme();
  let status;
  switch (quote.status) {
    case "accepted": {
      status = "success";
      break;
    }
    case "canceled": {
      status = "danger";
      break;
    }
    case "draft": {
      status = "basic";
      break;
    }
    case "open": {
      status = "primary";
      break;
    }
    default: {
      break;
    }
  }
  return (
    <StyledText
      category="s1"
      status={status}
      style={{
        backgroundColor: theme[`color-${status}-transparent-default`],
      }}
    >
      {quote.status.charAt(0).toUpperCase() + quote.status.slice(1)}
    </StyledText>
  );
};

export default StripeQuoteStatusText;
