import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Layout,
  Text,
  ThemeType,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import archiveCustomer from "../../api/functions/archiveCustomer";
import completeInboxItem from "../../api/functions/completeInboxItem";
import restoreCustomer from "../../api/functions/restoreCustomer";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import CustomerEmailActivityCard from "../../components/CustomerEmailActivityCard";
import CustomerInvoicesCard from "../../components/CustomerInvoicesCard";
import CustomerQuotesCard from "../../components/CustomerQuotesCard";
import CustomerRemindersCard from "../../components/CustomerRemindersCard";
import { addNotification } from "../../components/InAppNotifications";
import Label from "../../components/Label";
import ScheduleJobListItem from "../../components/listItems/ScheduleJob";
import SiteListItem from "../../components/listItems/Site";
import AlertModal from "../../components/modals/Alert";
import EditReminderModal from "../../components/modals/EditReminder";
import QuoteEditingModal from "../../components/modals/QuoteEditing";
import UpdateCustomerModal from "../../components/modals/UpdateCustomer";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectCustomerWithSiteIdsById from "../../store/customers/selectors/selectCustomerWithSiteIdsById";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../store/jobs/selectors/selectJobEntities";
import selectSites from "../../store/sites/selectors/selectSites";
import { StackParamList } from "../../types";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

const ArchivedMessageContainer = styled(View)<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme["color-primary-transparent-100"]};
  border: 1px solid
    ${({ theme }) => theme["color-primary-transparent-default-border"]};
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCustomerEmailActivityCard = styled(CustomerEmailActivityCard)`
  margin: 0 16px;
`;

const StyledCustomerInvoicesCard = styled(CustomerInvoicesCard)`
  margin: 0 16px;
`;

const StyledCustomerQuotesCard = styled(CustomerQuotesCard)`
  margin: 0 16px;
`;

const StyledCustomerRemindersCard = styled(CustomerRemindersCard)`
  margin: 0 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const UnarchiveButtonContainer = styled(View)`
  flex-direction: row;
`;

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  labelContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  listItem: {
    marginEnd: 16,
    marginStart: 16,
  },
  listItemDivider: {
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  safeAreaView: {
    flex: 1,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  userRecord: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const CustomerDetails = () => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "CustomerDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "CustomerDetails">>();
  const customerId = route.params?.customerId;
  const company = useSelector(selectCompany);
  const customer = useSelector((state) =>
    selectCustomerWithSiteIdsById(state, customerId)
  );
  const accessibleJobs = (useSelector(selectMapJobs) as {
    accessible: Array<string>;
    visible: Array<string>;
  }).accessible;
  const jobEntities = useSelector(selectJobEntities);
  const sites = useSelector(selectSites);
  const { id } = useSelector(selectUser);

  const [customerEditingVisible, setCustomerEditingVisible] = useState(false);
  const [archiveConfirmationVisible, setArchiveConfirmationVisible] = useState(
    false
  );
  const [restoreConfirmationVisible, setRestoreConfirmationVisible] = useState(
    false
  );
  const [quoteEditingModalVisible, setQuoteEditingModalVisible] = useState(
    false
  );
  const [editReminderModalVisible, setEditReminderModalVisible] = useState(
    false
  );

  const accessibleCustomerJobIds = accessibleJobs.filter(
    (jobId) =>
      sites.entities[jobEntities[jobId].siteId].customerId === customerId
  );
  const renderJobs = () => {
    if (accessibleCustomerJobIds.length > 0) {
      return accessibleCustomerJobIds.map((jobId, index) => {
        return (
          <View key={jobId}>
            {index > 0 && <Divider style={styles.listItemDivider} />}
            <ScheduleJobListItem
              jobId={jobId}
              onPress={() => navigation.navigate("JobDetails", { jobId })}
            />
          </View>
        );
      });
    }
    return (
      <>
        <Separator size="medium" />
        <StyledText appearance="hint">No upcoming jobs</StyledText>
        <Separator size="medium" />
      </>
    );
  };

  const paymentsEnabled = isFeatureEnabled({ company, feature: "payments" });
  const remindersEnabled = isFeatureEnabled({ company, feature: "reminders" });

  return (
    <>
      <Layout style={styles.root}>
        <View
          style={[
            styles.safeAreaView,
            {
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            },
          ]}
        >
          <Container>
            <HeaderWithTextAction
              action={{
                "data-test": "editButtonInCustomerDetailsScreen",
                onPress: () => setCustomerEditingVisible(true),
                text: "Edit",
              }}
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="Customer details"
            />
          </Container>
          <Divider />
          <Layout level="2" style={styles.content}>
            <ScrollView
              contentContainerStyle={{ paddingBottom: insets.bottom }}
            >
              <Container>
                <Separator size="medium" />
                {customer.isArchived && (
                  <>
                    <ArchivedMessageContainer theme={theme}>
                      <Separator size="medium" />
                      <StyledText category="s1">
                        This customer has been archived
                      </StyledText>
                      <Separator size="medium" />
                      <UnarchiveButtonContainer>
                        <StyledButton
                          onPress={() => {
                            restoreCustomer({ id: customerId });
                            addNotification({
                              status: "success",
                              title: "Customer restored",
                            });
                          }}
                        >
                          Unarchive
                        </StyledButton>
                      </UnarchiveButtonContainer>
                      <Separator size="medium" />
                    </ArchivedMessageContainer>
                    <Separator />
                  </>
                )}
                <StyledCard>
                  <Separator size="medium" />
                  <StyledText category="h6">{customer.name}</StyledText>
                  {customer.isArchived && (
                    <>
                      <Separator size="medium" />
                      <View style={styles.labelContainer}>
                        <Label status="danger">Archived</Label>
                      </View>
                    </>
                  )}
                  {customer.emails.length > 0 && (
                    <>
                      <Separator size="medium" />
                      <StyledText appearance="hint" category="c1">
                        Emails
                      </StyledText>
                      {customer.emails.map((email) => (
                        <StyledText key={email.id}>{email.email}</StyledText>
                      ))}
                    </>
                  )}
                  {Boolean(customer.notes) && (
                    <>
                      <Separator size="medium" />
                      <StyledText appearance="hint" category="c1">
                        Notes
                      </StyledText>
                      <StyledText>{customer.notes}</StyledText>
                    </>
                  )}
                  <Separator size="medium" />
                  {(paymentsEnabled || remindersEnabled) && (
                    <>
                      <StyledDivider />
                      <Separator size="medium" />
                      {paymentsEnabled && (
                        <StyledButton
                          onPress={() => setQuoteEditingModalVisible(true)}
                        >
                          Create quote
                        </StyledButton>
                      )}
                      {paymentsEnabled && remindersEnabled && (
                        <Separator size="small" />
                      )}
                      {remindersEnabled && (
                        <>
                          <StyledButton
                            onPress={() => setEditReminderModalVisible(true)}
                            status="basic"
                          >
                            Create reminder
                          </StyledButton>
                        </>
                      )}
                      <Separator size="medium" />
                    </>
                  )}
                </StyledCard>
                {customer.siteIds.length > 0 && (
                  <>
                    <Separator />
                    <StyledCard title="Locations">
                      {customer.siteIds.map((siteId, index) => (
                        <Fragment key={siteId}>
                          {index > 0 && (
                            <Divider style={styles.listItemDivider} />
                          )}
                          <SiteListItem
                            accessoryRight={(imageProps) => (
                              <Icon
                                {...imageProps}
                                name="arrow-ios-forward-outline"
                              />
                            )}
                            onPress={() =>
                              navigation.navigate("SiteDetails", { siteId })
                            }
                            siteId={siteId}
                          />
                        </Fragment>
                      ))}
                    </StyledCard>
                  </>
                )}
                <Separator />
                <StyledCard title="Jobs">
                  {renderJobs()}
                  <StyledDivider />
                  <Separator size="medium" />
                  <StyledButton
                    appearance="ghost"
                    onPress={() => {
                      const now = dayjs();
                      navigation.navigate("MainDrawer", {
                        params: {
                          params: {
                            customerIds: [customerId],
                            endDateTime: now.toISOString(),
                            siteIds: [],
                            startDateTime: now
                              .subtract(1, "day")
                              .startOf("minute")
                              .toISOString(),
                            userIds: [],
                          },
                          screen: "HistoricalSearch",
                        },
                        screen: "MainBottomTab",
                      });
                    }}
                  >
                    View history
                  </StyledButton>
                  <Separator size="medium" />
                </StyledCard>
                {remindersEnabled && (
                  <>
                    <Separator />
                    <StyledCustomerRemindersCard
                      customerId={customerId}
                      onListItemCheckboxChange={(inboxItem) =>
                        completeInboxItem(
                          {
                            id: inboxItem.id,
                            userId: id,
                          },
                          inboxItem
                        )
                      }
                      onListItemPress={(inboxItem) => {
                        if (inboxItem.type === "Reminder") {
                          navigation.navigate("ReminderDetails", {
                            inboxItemId: inboxItem.id,
                          });
                        } else {
                          navigation.navigate("InboxItemDetails", {
                            inboxItemId: inboxItem.id,
                          });
                        }
                      }}
                      title="Upcoming & Overdue reminders"
                    />
                  </>
                )}
                {paymentsEnabled && (
                  <>
                    <Separator />
                    <StyledCustomerQuotesCard
                      stripeCustomerId={customer.stripeCustomerId}
                    />
                    <Separator />
                    <StyledCustomerInvoicesCard
                      stripeCustomerId={customer.stripeCustomerId}
                    />
                  </>
                )}
                <Separator />
                <StyledCustomerEmailActivityCard customerId={customerId} />
                {!customer.isArchived && (
                  <>
                    <Separator size={48} />
                    <StyledButton
                      appearance="ghost"
                      onPress={() => setArchiveConfirmationVisible(true)}
                      status="danger"
                    >
                      Archive
                    </StyledButton>
                  </>
                )}
                <Separator />
              </Container>
            </ScrollView>
          </Layout>
        </View>
      </Layout>
      <UpdateCustomerModal
        initialValues={customer}
        isVisible={customerEditingVisible}
        onClose={() => setCustomerEditingVisible(false)}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Archive"
        isVisible={archiveConfirmationVisible}
        message="Archive this customer?"
        onCancel={() => setArchiveConfirmationVisible(false)}
        onClose={() => setArchiveConfirmationVisible(false)}
        onConfirm={() => {
          archiveCustomer({ id: customerId });
          addNotification({
            status: "success",
            title: "Customer archived",
          });
          setArchiveConfirmationVisible(false);
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Restore"
        isVisible={restoreConfirmationVisible}
        message="Restore this customer?"
        onCancel={() => setRestoreConfirmationVisible(false)}
        onClose={() => setRestoreConfirmationVisible(false)}
        onConfirm={() => {
          restoreCustomer({ id: customerId });
          addNotification({
            status: "success",
            title: "Customer restored",
          });
          setRestoreConfirmationVisible(false);
        }}
      />
      {paymentsEnabled && (
        <QuoteEditingModal
          isVisible={quoteEditingModalVisible}
          onClose={(quotesStale) => {
            setQuoteEditingModalVisible(false);
            // if (quotesStale) {
            //   loadQuotes();
            // }
          }}
        />
      )}
      {remindersEnabled && (
        <EditReminderModal
          initialValues={{
            customerId,
            userId: id,
          }}
          isVisible={editReminderModalVisible}
          onClose={() => setEditReminderModalVisible(false)}
        />
      )}
    </>
  );
};

export default CustomerDetails;
