import { UserLocationRecord } from "../../../types";
import mapToCoordinatesInput from "../mapToCoordinatesInput";

const mapToUserLocationRecordInput = (userLocationRecord: UserLocationRecord) =>
  userLocationRecord && {
    at: userLocationRecord.at,
    by: userLocationRecord.by,
    coordinates: mapToCoordinatesInput(userLocationRecord.coordinates),
  };

export default mapToUserLocationRecordInput;
