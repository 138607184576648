import { Layout, LayoutProps, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

import Separator from "../buildingBlocks/Separator";

export interface CardProps extends Pick<LayoutProps, "children" | "style"> {
  title?: string;
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 8,
  },
  title: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const Card = ({ children, style, title }: CardProps) => (
  <>
    {Boolean(title) && (
      <>
        <Separator size="medium" />
        <Text category="s2" style={styles.title}>
          {title.toUpperCase()}
        </Text>
        <Separator size="small" />
      </>
    )}
    <Layout style={[styles.root, style]}>{children}</Layout>
  </>
);

export default Card;
