import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedInvoiceItem } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

export type ListInvoicesInput = Pick<
  Stripe.InvoiceItemListParams,
  "invoice" | "starting_after"
>;

const listInvoiceItems = async (input?: ListInvoicesInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: input && {
        invoice: input.invoice,
        starting_after: input.starting_after,
      },
    })) as GraphQLResult<{
      listInvoiceItems: {
        data: Array<StripeExpandedInvoiceItem>;
        has_more: boolean;
      };
    }>;
    return data.listInvoiceItems;
  } catch ({ errors }) {
    throw generateErrorMessage("listInvoiceItems", errors);
  }
};

export default listInvoiceItems;
