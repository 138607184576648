import { Divider, Input, Layout, Text, useTheme } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import styled from "styled-components/native";

import { StripeExpandedLineItem, StripePrice } from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import ListItem from "../../listItems/Base";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import ProductSelectModal from "../ProductSelect";

interface FormValues {
  price: StripePrice;
  quantity: number;
}

interface PartialStripeLineItem
  extends Pick<StripeExpandedLineItem, "price" | "quantity"> {
  id?: string;
}

interface StripeLineItemEditingModalProps extends FullScreenModalProps {
  lineItem: StripeExpandedLineItem;
  onDelete: (lineItem: StripeExpandedLineItem) => void;
  onSubmit: (lineItem: PartialStripeLineItem) => void;
  pricesFilter?: (price: StripePrice) => boolean;
}

const Root = styled(Layout)`
  flex: 1;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const StripeLineItemEditingModal = ({
  isVisible,
  lineItem,
  onClose,
  onDelete,
  onSubmit,
  pricesFilter,
}: StripeLineItemEditingModalProps) => {
  const theme = useTheme();

  const [productSelectModalVisible, setProductSelectModalVisible] = useState(
    false
  );

  const [deletePending, setDeletePending] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      price: null,
      quantity: 1,
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      if (!values.price) {
        addNotification({
          message: "No product specified",
          status: "danger",
          title: "Update quote failed",
        });
        return;
      }
      if (values.quantity === 0) {
        addNotification({
          message: "Quantity cannot be 0",
          status: "danger",
          title: "Update quote failed",
        });
        return;
      }
      setSubmitPending(true);
      await onSubmit({
        id: lineItem?.id,
        price: values.price,
        quantity: values.quantity,
      });
      setSubmitPending(false);
    },
  });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: {
          price: lineItem?.price,
          quantity: lineItem?.quantity || 1,
        },
      });
      if (!lineItem) {
        setProductSelectModalVisible(true);
      }
    }
  }, [isVisible]);

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Header
          navigation={{
            icon: "close",
            onPress: onClose,
          }}
          title={lineItem ? "Edit item" : "Add item"}
        />
        <Divider />
        <Root level="2">
          <ScrollView
            contentInsetAdjustmentBehavior="automatic"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <SafeAreaView>
              <Separator size="medium" />
              <StyledText category="h6">Product</StyledText>
              <Separator size="small" />
              {formik.values.price ? (
                <>
                  <ListItem
                    description={`${formatCurrency(
                      formik.values.price.unit_amount
                    )}${
                      formik.values.price.metadata.taxesEnabled !== "false"
                        ? ""
                        : " \u2022 No tax"
                    }`}
                    style={{ marginEnd: 16, marginStart: 16 }}
                    title={formik.values.price.product.name}
                  />
                  {!lineItem && (
                    <>
                      <Separator size="medium" />
                      <StyledButton
                        onPress={() => setProductSelectModalVisible(true)}
                        status="basic"
                      >
                        Change product
                      </StyledButton>
                    </>
                  )}
                </>
              ) : (
                <StyledButton
                  appearance="ghost"
                  onPress={() => setProductSelectModalVisible(true)}
                >
                  Add product
                </StyledButton>
              )}
              <Separator />
              <StyledText category="h6">Quantity</StyledText>
              <Separator size={8} />
              <View style={{ flexDirection: "row" }}>
                <StyledInput
                  keyboardType="numeric"
                  onChangeText={(text) =>
                    formik.setFieldValue("quantity", parseInt(text) || 0)
                  }
                  style={{ backgroundColor: theme["background-basic-color-1"] }}
                  value={formik.values.quantity.toString()}
                />
              </View>
              {lineItem && (
                <>
                  <Separator size={48} />
                  <StyledButton
                    appearance="ghost"
                    disabled={submitPending}
                    loading={deletePending}
                    onPress={async () => {
                      setDeletePending(true);
                      await onDelete(lineItem);
                      setDeletePending(false);
                    }}
                    status="danger"
                  >
                    Remove product
                  </StyledButton>
                </>
              )}
              <Separator />
            </SafeAreaView>
          </ScrollView>
        </Root>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                disabled={deletePending}
                loading={submitPending}
                onPress={() => formik.handleSubmit()}
              >
                Save
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </FullScreenModal>
      <ProductSelectModal
        filter={pricesFilter}
        headerTitle="Find or add a product"
        isVisible={productSelectModalVisible}
        onClose={() => {
          setProductSelectModalVisible(false);
          if (!formik.values.price) {
            onClose();
          }
        }}
        onSelect={async (price) => {
          formik.setFieldValue("price", price);
          setProductSelectModalVisible(false);
        }}
      />
    </>
  );
};

export default StripeLineItemEditingModal;
