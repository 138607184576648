import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { FlatList, Keyboard, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

import createStripeCustomer from "../../../api/functions/createStripeCustomer";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import useCustomerList from "../../../hooks/useCustomerList";
import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";
import { setCustomer } from "../../../store/customers/slice";
import { Customer } from "../../../types";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import CustomerListItem from "../../listItems/Customer";
import CreateCustomerModal from "../CreateCustomer";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface StripeCustomerSelectModalProps extends FullScreenModalProps {
  createCustomerDisabled?: boolean;
  headerSubtitle?: string;
  headerTitle?: string;
  onSubmit: (customer: Customer) => void;
  selected?: string;
}

const IconPlaceholder = styled.View`
  height: 24px;
  margin: 0 8px;
  width: 24px;
`;

const InputContainer = styled.View`
  padding: 8px 16px;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const StripeCustomerSelectModal = ({
  createCustomerDisabled = false,
  headerSubtitle,
  headerTitle,
  isVisible,
  onClose,
  onSubmit,
  selected,
}: StripeCustomerSelectModalProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });

  const { customerIds, searchText, setSearchText } = useCustomerList({
    includeArchived: false,
  });

  const [tempSelected, setTempSelected] = useState<string>();
  const tempSelectedCustomer = useSelector((state) =>
    selectCustomerById(state, tempSelected)
  );
  const [createCustomerModalVisible, setCreateCustomerModalVisible] = useState(
    false
  );
  const [submitPending, setSubmitPending] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setSearchText("");
      setTempSelected(selected);
    }
  }, [isVisible]);

  const selectCustomer = (customer) =>
    setTempSelected(tempSelected !== customer.id ? customer.id : null);
  const handleSubmit = async (customer) => {
    setSubmitPending(true);
    if (!customer.stripeCustomerId) {
      // Create a Stripe token for the selected customer if they do not
      // yet have one
      try {
        const updatedCustomer = await createStripeCustomer(customer.id);
        dispatch(setCustomer({ customer: updatedCustomer }));
        await onSubmit(updatedCustomer);
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Create Stripe customer failed",
        });
      }
    } else {
      await onSubmit(customer);
    }
    setSubmitPending(false);
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Header
          accessoryRight={
            !createCustomerDisabled &&
            (() => (
              <TopNavigationAction
                icon={(imageProps) => <Icon {...imageProps} name="plus" />}
                onPress={() => {
                  Keyboard.dismiss();
                  setCreateCustomerModalVisible(true);
                }}
              />
            ))
          }
          navigation={{ icon: "close-outline", onPress: onClose }}
          subtitle={headerSubtitle}
          title={headerTitle}
        />
        <InputContainer>
          <Input
            clearButtonMode="while-editing"
            onChangeText={setSearchText}
            placeholder="Search..."
            value={searchText}
          />
        </InputContainer>
        <Divider />
        <Root level="2">
          <FlatList
            data={customerIds}
            ItemSeparatorComponent={() => (
              <Layout>
                <StyledDivider />
              </Layout>
            )}
            keyExtractor={(item) => item}
            ListEmptyComponent={
              <ListEmptyContainer>
                <Separator />
                <Text appearance="hint" category="s1">
                  No results found
                </Text>
              </ListEmptyContainer>
            }
            renderItem={({ item: customerId }) => (
              <Layout>
                <CustomerListItem
                  accessoryRight={(imageProps) => {
                    if (tempSelected === customerId) {
                      return (
                        <Icon
                          {...imageProps}
                          fill={theme["color-primary-default"]}
                          name="checkmark-outline"
                        />
                      );
                    }
                    return <IconPlaceholder />;
                  }}
                  customerId={customerId}
                  disabled={submitPending}
                  onPress={selectCustomer}
                />
              </Layout>
            )}
          />
        </Root>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                disabled={customerIds.length === 0}
                loading={submitPending}
                onPress={() => handleSubmit(tempSelectedCustomer)}
              >
                Select
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </FullScreenModal>
      {!createCustomerDisabled && (
        <CreateCustomerModal
          isVisible={createCustomerModalVisible}
          onClose={() => setCreateCustomerModalVisible(false)}
          onSuccess={async (customer) => {
            selectCustomer(customer);
            await handleSubmit(customer);
            setCreateCustomerModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default StripeCustomerSelectModal;
