import { Button, Calendar, Card, Input, Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, ViewProps, Platform } from "react-native";
import { useSelector } from "react-redux";
import * as yup from "yup";

import SimpleModal from "../Simple";
import { Separator } from "../../buildingBlocks";
import selectTime from "../../../store/settings/selectors/selectTime";

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = StyleSheet.create({
  calendarStyle: {
    flex: 1,
  },
  calendarContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingEnd: 16,
    paddingStart: 16,
  },
  headerContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  timeContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: Platform.OS === "web" ? "flex-start" : "space-between",
    paddingEnd: 16,
    paddingStart: 16,
  },
  clockContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timeComponent: {
    width: 56,
  },
  pmButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  amButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

type AMPM = "am" | "pm";

interface FormValues {
  hours: string;
  minutes: string;
  ampm?: AMPM;
}

interface DateAndTimePickerModalProps {
  dateTime: Date;
  visible: boolean;
  onClose: () => void;
  onDone: (Date) => void;
}

const DateAndTimePickerModal = ({
  dateTime,
  visible,
  onClose,
  onDone,
}: DateAndTimePickerModalProps) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(dateTime);
  const useAmpm = useSelector(selectTime).useAmpmTime;
  const hoursInput = useRef(null);
  const minutesInput = useRef(null);

  const clearInputFocus = () => {
    if (hoursInput.current) hoursInput.current.blur();
    if (minutesInput.current) minutesInput.current.blur();
  };

  const getInitialValues = () => {
    if (useAmpm) {
      return {
        hours: dayjs(dateTime).format("h"),
        minutes: dayjs(dateTime).format("mm"),
        ampm: dayjs(dateTime).format("a") as AMPM,
      };
    }
    return {
      hours: dayjs(dateTime).format("HH"),
      minutes: dayjs(dateTime).format("mm"),
    };
  };

  const formik = useFormik<FormValues>({
    initialValues: getInitialValues(),
    validationSchema: yup.object({
      hours: yup.string().test("is-hours", "Not valid time", (value) => {
        if (!value) return false;
        if (useAmpm) {
          if (value.length === 1) return value.match(/[1-9]/gm);
          if (value.length === 2)
            return value.match(/[1][0-2]/gm) || value.match(/[0][1-9]/gm);
          return false;
        }

        if (value.length === 1) return value.match(/[0-9]/gm);
        if (value.length === 2)
          return value.match(/[0-1][0-9]/gm) || value.match(/[2][0-3]/gm);
        return false;
      }),
      minutes: yup
        .string()
        .required()
        .matches(/[0-5][0-9]/gm),
    }),
    onSubmit: ({ hours, minutes, ampm }) => {
      let numHours = Number(hours);

      // convert from 12 to 24 hour format
      if (useAmpm) {
        if (numHours === 12) numHours -= 12;
        if (ampm === "pm") numHours += 12;
      }

      const numMinutes = Number(minutes);
      const selectedDateAndTime = dayjs(selectedDateTime)
        .hour(numHours)
        .minute(numMinutes)
        .second(0)
        .millisecond(0);
      onDone(selectedDateAndTime);
    },
  });

  useEffect(() => {
    if (visible) {
      formik.resetForm({
        values: getInitialValues(),
      });
      setSelectedDateTime(dateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <SimpleModal
      isVisible={visible}
      onBackdropPress={() => {}}
      onBackPress={onClose}
    >
      <View style={styles.headerContainer}>
        <Separator size="medium" />
        <Text category="h6">Select a date and time</Text>
        <Text category="s1">{`Using local timezone${
          Platform.OS !== "android" ? ` (${dayjs.tz.guess()})` : ""
        }`}</Text>
        <Separator size="medium" />
      </View>
      <View style={styles.timeContainer}>
        <Text category="h6">Time</Text>
        <Separator horizontal />
        <View style={styles.clockContainer}>
          <Input
            data-test="hoursInputInDateAndTimePickerModal"
            ref={hoursInput}
            keyboardType="number-pad"
            onChangeText={formik.handleChange("hours")}
            value={formik.values.hours}
            maxLength={2}
            style={styles.timeComponent}
            textAlign="center"
            status={formik.touched.hours && formik.errors.hours && "danger"}
            onFocus={() => {
              formik.setFieldValue("hours", "");
              formik.setFieldTouched("hours");
            }}
            contextMenuHidden
          />
          <Text category="h6"> : </Text>
          <Input
            data-test="minutesInputInDateAndTimePickerModal"
            ref={minutesInput}
            keyboardType="number-pad"
            onChangeText={formik.handleChange("minutes")}
            value={formik.values.minutes}
            maxLength={2}
            style={styles.timeComponent}
            textAlign="center"
            status={formik.touched.minutes && formik.errors.minutes && "danger"}
            onFocus={() => {
              formik.setFieldValue("minutes", "");
              formik.setFieldTouched("minutes");
            }}
            contextMenuHidden
          />
          {useAmpm && (
            <>
              <Separator size="small" horizontal />
              <Button
                appearance={formik.values.ampm === "am" ? "filled" : "outline"}
                data-test="amButtonInDateAndTimePickerModal"
                onPress={() => {
                  formik.setFieldValue("ampm", "am");
                  clearInputFocus();
                }}
                size="small"
                style={styles.amButton}
              >
                AM
              </Button>
              <Button
                appearance={formik.values.ampm === "pm" ? "filled" : "outline"}
                data-test="pmButtonInDateAndTimePickerModal"
                onPress={() => {
                  formik.setFieldValue("ampm", "pm");
                  clearInputFocus();
                }}
                size="small"
                style={styles.pmButton}
              >
                PM
              </Button>
            </>
          )}
        </View>
      </View>
      <Separator size="medium" />
      <View style={styles.calendarContainer}>
        <Calendar
          style={styles.calendarStyle}
          date={selectedDateTime}
          onSelect={(newDate) => {
            setSelectedDateTime(newDate);
            clearInputFocus();
          }}
          min={dayjs().subtract(5, "y").toDate()}
          max={dayjs().add(5, "y").toDate()}
          onVisibleDateChange={clearInputFocus}
        />
      </View>
      <Separator size="medium" />
      <View style={styles.footerContainer}>
        <Button
          status="basic"
          onPress={() => {
            onClose();
            clearInputFocus();
          }}
        >
          Cancel
        </Button>
        <Separator horizontal size="small" />
        <Button
          data-test="submitButtonInDateAndTimePickerModal"
          onPress={() => formik.handleSubmit()}
        >
          Done
        </Button>
      </View>
      <Separator size="small" />
    </SimpleModal>
  );
};

export default DateAndTimePickerModal;
