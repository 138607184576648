import gql from "graphql-tag";

import { incidentReportFragment } from "../../../fragments";

const submitIncidentReport = gql`
  mutation submitIncidentReport(
    $id: ID!
    $timestamp: AWSDateTime!
    $jobId: ID!
  ) {
    submitIncidentReport(id: $id, timestamp: $timestamp, jobId: $jobId) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default submitIncidentReport;
