import { createSelector } from "@reduxjs/toolkit";

import selectTimeCards from "../selectTimeCards";

const selectTimeCardById = createSelector(
  [selectTimeCards, (_, id: string) => id],
  (timeCards, id) => timeCards.entities[id]
);

export default selectTimeCardById;
