import { Divider, Layout, Text } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useEffect, useState } from "react";
import { SafeAreaView, SectionList, StyleSheet, View } from "react-native";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { useSelector } from "react-redux";
import selectCompany from "../../../store/company/selectors/selectCompany";
import isCustomersEnabled from "../../../utils/isCustomersEnabled";

import FullScreenModal from "../FullScreen";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import ScheduleJobListItem, {
  height as listItemHeight,
} from "../../listItems/ScheduleJob";
import SendJobSummariesButton from "../../SendJobSummariesButton";
import selectScheduleJobs from "../../../store/jobs/selectors/selectScheduleJobs";
import formatSectionListData from "../../../utils/formatSectionListData";
import createJobSummary from "../../../api/functions/createJobSummary";
import { addNotification } from "../../InAppNotifications";

const styles = StyleSheet.create({
  buttonContainer: {
    padding: 8,
    flexDirection: "row",
  },
  buttonWrapper: {
    flex: 1,
  },
  indicator: {
    justifyContent: "center",
    alignItems: "center",
  },
  listButton: {
    borderRadius: 100,
    marginLeft: 8,
  },
  root: {
    flex: 1,
  },
  settingsButtonContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  warningMessageBoldText: {
    fontWeight: "bold",
  },
  warningMessageContainer: {
    borderRadius: 8,
    justifyContent: "center",
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
    marginTop: 8,
    minHeight: 48,
    overflow: "hidden",
    paddingEnd: 16,
    paddingStart: 16,
  },
});

interface JobExportModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSettingsPress: () => void;
}

const JobExportModal = ({
  isVisible,
  onClose,
  onSettingsPress,
}: JobExportModalProps) => {
  const [selected, setSelected] = useState({});
  const [createJobSummaryPending, setCreateJobSummaryPending] = useState(false);

  const company = useSelector(selectCompany);
  const jobs = useSelector(selectScheduleJobs);

  useEffect(() => {
    if (isVisible) setSelected({});
  }, [isVisible]);

  const customersEnabled = isCustomersEnabled(company);

  const handleJobPress = (id) => {
    if (selected[id]) {
      const temp = { ...selected };
      delete temp[id];
      setSelected(temp);
    } else setSelected({ ...selected, [id]: id });
  };

  const runCreateJobSummary = async () => {
    if (Object.keys(selected).length === 0) {
      addNotification({
        message: "No jobs have been selected.",
        status: "danger",
        title: "Cannot create job summary",
      });
    } else {
      setCreateJobSummaryPending(true);
      try {
        const jobSummaryId = await createJobSummary({
          jobIds: jobs.filter((job) => selected[job.id]).map((job) => job.id),
        });
        await WebBrowser.openBrowserAsync(
          `https://customer.talarium.io/${jobSummaryId}`
        );
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Cannot view job summary",
        });
      }
      setCreateJobSummaryPending(false);
    }
  };

  const handleDeselectAll = () => setSelected({});
  const handleSelectAll = () => {
    const selection = jobs.reduce(
      (acc, next) => ({ ...acc, [next.id]: next.id }),
      {}
    );
    setSelected(selection);
  };

  const { sections } = formatSectionListData({
    data: jobs,
    startDateTimeExtractor: (item) => item.startDateTime,
    idExtractor: (item) => item.id,
    sortCompareFunction: (a, b) =>
      a.startDateTime.localeCompare(b.startDateTime),
  });

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <Header
        navigation={{ icon: "close-outline", onPress: onClose }}
        title="Send"
        subtitle={`${Object.keys(selected).length} Selected`}
      />
      {customersEnabled ? (
        <>
          <View style={styles.buttonContainer}>
            <Button
              style={styles.listButton}
              disabled={Object.keys(selected).length === jobs.length}
              size="tiny"
              onPress={handleSelectAll}
            >
              Select All
            </Button>
            <Button
              style={styles.listButton}
              disabled={Object.keys(selected).length === 0}
              size="tiny"
              onPress={handleDeselectAll}
            >
              Deselect All
            </Button>
          </View>
          <Divider />
          <SectionList
            getItemLayout={sectionListGetItemLayout({
              getItemHeight: () => listItemHeight,
              getSeparatorHeight: () => 1,
              getSectionHeaderHeight: () => 36,
            })}
            ItemSeparatorComponent={Divider}
            keyExtractor={(item) => item}
            renderItem={({ item: id }) => (
              <ScheduleJobListItem
                onPress={handleJobPress}
                jobId={id}
                enableCheckbox
                checked={Boolean(selected[id])}
              />
            )}
            renderSectionHeader={({ section: { data, title } }) => (
              <Layout
                level="3"
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                }}
              >
                <Text>{`${title} (${data.length})`}</Text>
              </Layout>
            )}
            sections={sections}
            stickySectionHeadersEnabled
          />
          <Divider />
          <SafeAreaView>
            <View style={styles.buttonContainer}>
              <View style={styles.buttonWrapper}>
                <Button
                  appearance="ghost"
                  disabled={createJobSummaryPending}
                  loading={createJobSummaryPending}
                  onPress={runCreateJobSummary}
                >
                  View job summary
                </Button>
              </View>
              <Separator horizontal size="small" />
              <View style={styles.buttonWrapper}>
                <SendJobSummariesButton
                  appearance="ghost"
                  jobIds={Object.keys(selected)}
                />
              </View>
            </View>
          </SafeAreaView>
        </>
      ) : (
        <>
          <Divider />
          <View>
            <Separator />
            <Text style={styles.text}>
              In order to send job summaries, customers must be enabled.
            </Text>
            <Separator size="medium" />
            <View style={styles.settingsButtonContainer}>
              <Button onPress={() => onSettingsPress()}>Settings</Button>
            </View>
            <Separator />
          </View>
        </>
      )}
    </FullScreenModal>
  );
};

export default JobExportModal;
