import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

if (Platform.OS === "android") {
  Notifications.setNotificationChannelAsync("jobs", {
    importance: Notifications.AndroidImportance.HIGH,
    name: "Jobs",
  });
  Notifications.setNotificationChannelAsync("messages", {
    importance: Notifications.AndroidImportance.HIGH,
    name: "Messages",
  });
}

const storageKey = "@expo_token";

export const getToken = async () => {
  try {
    return await AsyncStorage.getItem(storageKey);
  } catch (error) {
    return null;
  }
};

export const setToken = async (token) => {
  try {
    await AsyncStorage.setItem(storageKey, token);
  } catch (error) {
    // Do nothing
  }
};
