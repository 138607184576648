import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation login($expoToken: ID) {
    login(expoToken: $expoToken) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
