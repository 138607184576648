import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setActionItemPending } from "../../../store/actionItems/slice";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectActionItemById from "../../../store/actionItems/selectors/selectActionItemById";

interface DeleteActionItemInput {
  id: string;
}

const dispatchOptimisticResponse = ({ id }: DeleteActionItemInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = selectUser(state);
  const actionItem = selectActionItemById(state, id);
  const timestamp = new Date().toISOString();
  dispatch(
    setActionItemPending({
      actionItem: {
        ...actionItem,
        isDeleted: true,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
      mutation: "deleteActionItem",
    })
  );
};

const deleteActionItem = ({ id }: DeleteActionItemInput) => {
  dispatchOptimisticResponse({ id });
  client.mutate({
    mutation,
    variables: { id },
  });
};

export default deleteActionItem;
