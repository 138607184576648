import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Divider,
  Layout,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import adminDeleteDailyActivityReport from "../../../api/functions/adminDeleteDailyActivityReport";
import deleteDailyActivityReport from "../../../api/functions/deleteDailyActivityReport";
import HeaderWithTextAction from "../../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../../components/buildingBlocks/Separator";
import Container from "../../../components/Container";
import MediaListItem from "../../../components/listItems/Media";
import AlertModal from "../../../components/modals/Alert";
import DailyActivityReportEditingModal from "../../../components/modals/ReportEditing/DailyActivity";
import MediaViewerModal from "../../../components/modals/MediaViewer";
import SectionItem from "../../../components/SectionItem";
import TimeRange from "../../../components/TimeRange";
import companyUserRoles from "../../../constants/companyUserRoles";
import useIosBottomSpace from "../../../device/useIosBottomSpace";
import useJobUtils from "../../../hooks/useJobUtils";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import selectClockedIn from "../../../store/users/selectors/selectClockedIn";
import selectUserById from "../../../store/users/selectors/selectUserById";
import getUserFullName from "../../../utils/getUserFullName";
import useClockInProps from "../../../hooks/useClockInProps";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  summary: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  timeRange: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const useReportMutations = () => {
  const { group } = useSelector(selectUser);
  if (group === "Admin" || group === "Supervisor") {
    return {
      deleteMutation: adminDeleteDailyActivityReport,
    };
  }
  return {
    deleteMutation: deleteDailyActivityReport,
  };
};

const DailyActivityReportDetails = ({ dailyActivityReport }) => {
  const insets = useSafeAreaInsets();
  const iosBottomSpace = useIosBottomSpace();
  const navigation = useNavigation();
  const theme = useTheme();
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const { deleteMutation } = useReportMutations();
  const { group: userRole } = useSelector(selectUser);
  const createdByUser = useSelector((state) =>
    selectUserById(state, dailyActivityReport.created.by)
  );
  const completedByUser = useSelector((state) =>
    selectUserById(state, dailyActivityReport.submitted.by)
  );
  const updatedByUser = useSelector((state) =>
    selectUserById(state, dailyActivityReport.updated.by)
  );
  const clockedIn = useSelector(selectClockedIn);

  const {
    deleteActionItemCompletionRecord,
    deleteTaskCompletionRecord,
    getActionItemCompletionRecordByReportId,
    getTaskCompletionRecordByReportId,
  } = useJobUtils(dailyActivityReport.jobId);

  const { ConditionalTooltipProps } = useClockInProps({ disabled: false });
  const [mediaViewer, setMediaViewer] = useState({
    mediaIndex: null,
    visible: false,
  });
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(
    false
  );
  const [editingVisible, setEditingVisible] = useState(false);
  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              action={
                userRole !== companyUserRoles.EMPLOYEE
                  ? {
                      ConditionalTooltipProps,
                      "data-test": "editButtonInDailyActivityReportDetails",
                      disabled: !clockedIn,
                      loading: false,
                      onPress: () => setEditingVisible(true),
                      text: "Edit",
                    }
                  : undefined
              }
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="Daily activity report details"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: iosBottomSpace + 8 }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Container>
              <Layout>
                <Separator size="medium" />
                <Text category="h6" style={styles.text}>
                  {`Submitted by ${getUserFullName(completedByUser)}`}
                </Text>
                <Text style={styles.text}>
                  {moment(dailyActivityReport.submitted.at).format(
                    dateTimeFormat
                  )}
                </Text>
                <Separator size="medium" />
                <Divider style={styles.divider} />
                <Separator size="medium" />
                <TimeRange
                  endDateTime={dailyActivityReport.completed.at}
                  startDateTime={dailyActivityReport.started.at}
                  style={styles.timeRange}
                  textStyle={{ color: theme["text-hint-color"] }}
                />
                {Boolean(dailyActivityReport.summary) && (
                  <>
                    <Separator size="small" />
                    <Text style={styles.summary}>
                      {dailyActivityReport.summary}
                    </Text>
                  </>
                )}
                <Separator size="medium" />
              </Layout>
              <Divider />
              {dailyActivityReport.media.length > 0 && (
                <>
                  <Separator />
                  <SectionItem title="MEDIA">
                    {dailyActivityReport.media.map((media, index) => (
                      <Fragment key={media.id}>
                        {index > 0 && <Divider style={styles.divider} />}
                        <MediaListItem
                          media={media}
                          onPress={() =>
                            setMediaViewer({ mediaIndex: index, visible: true })
                          }
                        />
                      </Fragment>
                    ))}
                  </SectionItem>
                </>
              )}
              <Separator />
              <SectionItem status="danger" title="DANGER ZONE">
                <ListItem
                  accessoryRight={() => (
                    <Button
                      data-test="deleteButtonInDailyActivityReportDetails"
                      onPress={() => setDeleteConfirmationVisible(true)}
                      size="small"
                      status="danger"
                    >
                      Delete
                    </Button>
                  )}
                  description="Once you delete a report there is no going back."
                  disabled
                  title="Delete this report"
                />
              </SectionItem>
              <Separator />
              <Text appearance="hint" style={styles.text}>
                Created:
              </Text>
              <Text appearance="hint" style={styles.text}>{`${getUserFullName(
                createdByUser
              )} \u2022 ${moment(dailyActivityReport.created.at).format(
                dateTimeFormat
              )}`}</Text>
              <Separator size="small" />
              <Text appearance="hint" style={styles.text}>
                Last updated:
              </Text>
              <Text appearance="hint" style={styles.text}>{`${getUserFullName(
                updatedByUser
              )} \u2022 ${moment(dailyActivityReport.updated.at).format(
                dateTimeFormat
              )}`}</Text>
              <Separator />
            </Container>
          </ScrollView>
        </Layout>
      </Layout>
      <MediaViewerModal
        isVisible={mediaViewer.visible}
        media={dailyActivityReport.media[mediaViewer.mediaIndex]}
        onClose={() =>
          setMediaViewer((prevState) => ({ ...prevState, visible: false }))
        }
      />
      <DailyActivityReportEditingModal
        dailyActivityReport={dailyActivityReport}
        isVisible={editingVisible}
        onClosePress={() => setEditingVisible(false)}
        onDelete={() => {
          setEditingVisible(false);
          navigation.goBack();
        }}
        onSubmit={() => setEditingVisible(false)}
      />
      <AlertModal
        confirmText="Delete report"
        isVisible={deleteConfirmationVisible}
        message="Are you sure you want to delete this report?"
        onCancel={() => setDeleteConfirmationVisible(false)}
        onClose={() => setDeleteConfirmationVisible(false)}
        onConfirm={() => {
          deleteMutation(dailyActivityReport);
          // If this report is linked to a task or action item then they need to be
          // automatically deleted once the report is submitted
          const taskCompletionRecord = getTaskCompletionRecordByReportId(
            dailyActivityReport.id
          );
          if (taskCompletionRecord) {
            deleteTaskCompletionRecord(taskCompletionRecord.taskId);
          }
          const actionItemCompletionRecord = getActionItemCompletionRecordByReportId(
            dailyActivityReport.id
          );
          if (actionItemCompletionRecord) {
            deleteActionItemCompletionRecord(
              actionItemCompletionRecord.actionItemId
            );
          }
          setDeleteConfirmationVisible(false);
          navigation.navigate("JobDetails", {
            jobId: dailyActivityReport.jobId,
          });
        }}
      />
    </>
  );
};

DailyActivityReportDetails.propTypes = {
  dailyActivityReport: PropTypes.shape({
    completed: PropTypes.shape({
      at: PropTypes.string,
    }),
    created: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
    id: PropTypes.string,
    jobId: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape({})),
    started: PropTypes.shape({
      at: PropTypes.string,
    }),
    submitted: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
    summary: PropTypes.string,
    updated: PropTypes.shape({
      at: PropTypes.string,
      by: PropTypes.string,
    }),
  }).isRequired,
};

export default DailyActivityReportDetails;
