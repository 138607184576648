import React, { memo } from "react";
import useChecklistItemUtils from "../../../hooks/useChecklistItemUtils";

import { ActionItem } from "../../../types";
import ListItem, { ListItemProps as UiKittenListItemProps } from "../Base";

export interface ActionItemListItemProps
  extends Pick<
    UiKittenListItemProps,
    | "accessoryLeft"
    | "accessoryRight"
    | "description"
    | "disabled"
    | "style"
    | "title"
  > {
  actionItem: ActionItem;
  onPress?: (actionItem: ActionItem) => void;
}

const ActionItemListItem: React.FC<ActionItemListItemProps> = ({
  accessoryLeft,
  accessoryRight,
  actionItem,
  description,
  disabled,
  onPress,
  style,
  title,
}) => {
  const { ListItemProps } = useChecklistItemUtils(actionItem);

  return (
    <ListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      description={description || ListItemProps.description}
      disabled={disabled}
      onPress={() => onPress(actionItem)}
      style={style}
      title={title || ListItemProps.title}
    />
  );
};

export default memo(ActionItemListItem);
