import { createSelector } from "@reduxjs/toolkit";
import { SimpleDistanceMatrixData } from "../../../types";

import selectAllGroups from "../../groups/selectors/selectAll";
import selectAllUsers from "../../users/selectors/selectAll";
import assigneeTypes from "../../../constants/assigneeTypes";

const selectAvailableAssignees = createSelector(
  [
    selectAllGroups,
    selectAllUsers,
    (_, userDistanceMatrix: Record<string, SimpleDistanceMatrixData>) =>
      userDistanceMatrix,
  ],
  (groups, users, userDistanceMatrix) => {
    const filteredGroups = groups.filter((group) => !group.isDeleted);
    const filteredUsers = users.filter((user) => !user.isArchived);
    filteredGroups.sort((a, b) => a.name.localeCompare(b.name));
    filteredUsers.sort((a, b) => {
      if (a.clockedIn && !b.clockedIn) {
        return -1;
      }
      if (!a.clockedIn && b.clockedIn) {
        return 1;
      }
      if (a.clockedIn && b.clockedIn) {
        const aDurationValue = userDistanceMatrix[a.id]?.durationValue;
        const bDurationValue = userDistanceMatrix[b.id]?.durationValue;
        if (aDurationValue && !bDurationValue) {
          return -1;
        }
        if (!aDurationValue && bDurationValue) {
          return 1;
        }
        if (aDurationValue && bDurationValue) {
          return aDurationValue - bDurationValue;
        }
      }
      return (
        a.lastName.localeCompare(b.lastName) ||
        a.firstName.localeCompare(b.firstName)
      );
    });
    const assignees = [];
    if (filteredGroups)
      assignees.push({
        type: assigneeTypes.group,
        data: filteredGroups,
      });
    if (filteredUsers.length) {
      assignees.push({
        type: assigneeTypes.user,
        data: filteredUsers,
      });
    }

    return assignees;
  }
);

export default selectAvailableAssignees;
