import gql from "graphql-tag";

const sendInvoiceReceipt = gql`
  mutation sendInvoiceReceipt($invoiceId: ID!) {
    sendInvoiceReceipt(invoiceId: $invoiceId) {
      to
      submittedAt
      messageId
      errorCode
      message
    }
  }
`;

export default sendInvoiceReceipt;
