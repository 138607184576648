import {
  Divider,
  Icon,
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  Text,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Keyboard, ScrollView, StyleSheet, View } from "react-native";
import uuidv4 from "uuid/v4";
import * as yup from "yup";

import AlertModal from "../Alert";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import LocationSettingModal from "../LocationSetting";
import TextInputModal from "../TextInput";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import BaseListItem from "../../listItems/Base";
import StaticMapView from "../../map/StaticMapView";
import Marker from "../../markers/Marker";
import SectionItem from "../../SectionItem";
import SiteMapCircle from "../../SiteMapCircle";
import adminCreateCompanySite from "../../../api/functions/adminCreateCompanySite";
import geocode from "../../../api/functions/geocode";
import timeZones from "../../../constants/timeZones";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import { Coordinates } from "../../../types";
import isNumber from "../../../utils/isNumber";

interface FormValues {
  address: string;
  coordinates: Coordinates;
  geofence: number;
  name: string;
  notes: string;
  privateNotes: string;
  timezone: string;
}

interface Props extends FullScreenModalProps {
  onSubmit: (siteId: string) => void;
}

const getFormattedTimezone = (timezone) => timezone.replace("_", " ");

const styles = StyleSheet.create({
  button: {
    justifyContent: "flex-start",
  },
  content: {
    flex: 1,
  },
  description: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  divider: {
    marginStart: 16,
  },
  fakeLabel: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  listItemAccessory: {
    flexDirection: "row",
    alignItems: "center",
  },
  listItemText: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  mapContainer: {
    borderRadius: 4,
    overflow: "hidden",
  },
  mapHintOverlay: {
    position: "absolute",
    end: 0,
    start: 0,
    top: 0,
    alignItems: "center",
  },
  mapHintTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginEnd: 8,
    marginStart: 8,
    marginTop: 8,
    paddingBottom: 4,
    paddingEnd: 8,
    paddingStart: 8,
    paddingTop: 4,
    borderRadius: 4,
  },
  searchAddressLayout: {
    alignItems: "flex-end",
    paddingBottom: 4,
    paddingEnd: 16,
    paddingTop: 4,
  },
});

const CreateSiteForCustomerModal = ({
                                      isVisible,
                                      onClose,
                                      onSubmit,
                                    }: Props) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      address: "",
      coordinates: undefined,
      geofence: 50,
      name: "",
      notes: "",
      privateNotes: "",
      timezone: moment.tz.guess(),
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      address: yup.string().required("Required"),
      coordinates: yup.object({
        latitude: yup.number(),
        longitude: yup.number(),
      }),
      geofence: yup
        .number()
        .required("Required")
        .min(50, "Must be greater than or equal to 50")
        .max(1000, "Must be less than or equal to 1000"),
    }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      const siteId = uuidv4();
      adminCreateCompanySite({
        id: siteId,
        address: values.address,
        coordinates: {
          lat: values.coordinates.lat,
          lng: values.coordinates.lng,
        },
        geofence: values.geofence,
        name: values.name,
        notes: values.notes,
        privateNotes: values.privateNotes,
        timezone: values.timezone,
      });
      addNotification({
        status: "success",
        title: "Location created",
      });
      onSubmit(siteId);
    },
  });

  const [timezones] = useState(() => {
    const availableTimezones = Object.values(timeZones);
    if (!availableTimezones.includes(formik.values.timezone)) {
      availableTimezones.unshift(formik.values.timezone);
    }
    return availableTimezones;
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const [geocodePending, setGeocodePending] = useState(false);
  const [locationSettingVisible, setLocationSettingVisible] = useState(false);
  const [notesEditingVisible, setNotesEditingVisible] = useState(false);
  const [privateNotesEditingVisible, setPrivateNotesEditingVisible] = useState(
    false,
  );

  const handleBackPress = () => {
    Keyboard.dismiss();
    if (formik.dirty) {
      setAlertVisible(true);
    } else {
      onClose();
    }
  };
  useAndroidBackHandler({ enabled: isVisible, onBackPress: handleBackPress });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const searchAddress = async () => {
    setGeocodePending(true);
    try {
      const geocodeResponse = await geocode(formik.values.address);
      formik.setFieldValue("address", geocodeResponse.address);
      formik.setFieldValue("coordinates", geocodeResponse.coordinates);
    } catch (error) {
      addNotification({
        message: (error && error.message) || error,
        status: "danger",
        title: "Error",
      });
    }
    setGeocodePending(false);
  };
  const getAddressHelperText = () => {
    if (formik.touched.address && formik.errors.address) {
      return formik.errors.address;
    }
    if (formik.touched.coordinates && formik.errors.coordinates) {
      return "Please use search";
    }
    return null;
  };
  const getAddressStatus = () => {
    if (
      (formik.touched.address && formik.errors.address) ||
      (formik.touched.coordinates && formik.errors.coordinates)
    ) {
      return "danger";
    }
    return "basic";
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          action={{
            onPress: formik.handleSubmit,
            text: "Add",
          }}
          navigation={{
            icon: "close-outline",
            onPress: handleBackPress,
          }}
          title="New site"
        />
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Separator />
            <SectionItem>
              <Separator size="small" />
              <Input
                autoCapitalize="words"
                caption={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : null
                }
                data-test="nameInputInSiteEditingModal"
                disabled={formik.isSubmitting}
                label="Name"
                onChangeText={formik.handleChange("name")}
                onFocus={() => {
                  formik.setFieldTouched("name");
                }}
                status={
                  formik.touched.name && formik.errors.name ? "danger" : "basic"
                }
                style={styles.input}
                value={formik.values.name}
              />
              <Separator size="small" />
            </SectionItem>
            <Separator />
            <SectionItem title="PLACE">
              <Separator size="small" />
              <Text appearance="hint" category="c1" style={styles.description}>
                Search for an address below. We will provide you with the
                necessary location data after which you can make changes if
                needed.
              </Text>
              <Separator size="medium" />
              <Input
                data-test="addressInputInSiteEditingModal"
                autoCapitalize="words"
                caption={getAddressHelperText()}
                disabled={formik.isSubmitting}
                label="Address"
                onChangeText={formik.handleChange("address")}
                onFocus={() => {
                  formik.setFieldTouched("address");
                }}
                status={getAddressStatus()}
                style={styles.input}
                value={formik.values.address}
              />
              <View style={styles.searchAddressLayout}>
                <Button
                  data-test="searchAddressButtonInSiteEditingModal"
                  disabled={!formik.values.address || formik.isSubmitting}
                  loading={geocodePending}
                  onPress={() => {
                    Keyboard.dismiss();
                    searchAddress();
                  }}
                >
                  Search
                </Button>
              </View>
              {formik.values.coordinates && (
                <>
                  <Separator size="medium" />
                  <Text
                    appearance="hint"
                    category="label"
                    style={styles.fakeLabel}
                  >
                    Location
                  </Text>
                  <View
                    style={{
                      paddingEnd: 16,
                      paddingStart: 16,
                    }}
                  >
                    <Layout level="3" style={styles.mapContainer}>
                      <StaticMapView
                        region={{
                          latitude: formik.values.coordinates.lat,
                          latitudeDelta: 0.01,
                          longitude: formik.values.coordinates.lng,
                          longitudeDelta: 0.01,
                        }}
                        onPress={() => null}
                      >
                        <Marker
                          coordinate={{
                            latitude: formik.values.coordinates.lat,
                            longitude: formik.values.coordinates.lng,
                          }}
                          draggable={!formik.isSubmitting}
                          onDragEnd={(event) =>
                            formik.setFieldValue(
                              "coordinates",
                              event.nativeEvent.coordinate,
                            )
                          }
                        />
                        {isNumber(formik.values.geofence) &&
                          !formik.errors.geofence && (
                            <SiteMapCircle
                              center={{
                                latitude: formik.values.coordinates.lat,
                                longitude: formik.values.coordinates.lng,
                              }}
                              radius={formik.values.geofence}
                            />
                          )}
                      </StaticMapView>
                      <View style={styles.mapHintOverlay}>
                        <Layout style={styles.mapHintTextContainer}>
                          <Text category="c1">
                            Press on the map to change the location.
                          </Text>
                        </Layout>
                      </View>
                    </Layout>
                  </View>
                  <Separator size="medium" />
                  <Input
                    value={formik.values.geofence.toString()}
                    onChangeText={(text) => {
                      formik.setFieldValue(
                        "geofence",
                        isNumber(text) ? parseInt(text, 10) : text,
                      );
                    }}
                    caption={
                      formik.touched.geofence && formik.errors.geofence
                        ? formik.errors.geofence
                        : null
                    }
                    disabled={formik.isSubmitting}
                    keyboardType="number-pad"
                    label="Geofence"
                    onFocus={() => {
                      formik.setFieldTouched("geofence");
                    }}
                    status={
                      formik.touched.geofence && formik.errors.geofence
                        ? "danger"
                        : "basic"
                    }
                    style={styles.input}
                  />
                  <Separator size="medium" />
                  <Select
                    selectedIndex={
                      new IndexPath(timezones.indexOf(formik.values.timezone))
                    }
                    onSelect={(index: IndexPath) =>
                      formik.setFieldValue("timezone", timezones[index.row])
                    }
                    value={getFormattedTimezone(formik.values.timezone)}
                    disabled={formik.isSubmitting}
                    label="Time zone"
                    style={styles.input}
                  >
                    {timezones.map((timezone) => (
                      <SelectItem
                        key={timezone}
                        title={getFormattedTimezone(timezone)}
                      />
                    ))}
                  </Select>
                  <Separator size="small" />
                </>
              )}
              <Separator size="small" />
            </SectionItem>
            <Separator />
            <SectionItem title="NOTES">
              <BaseListItem
                accessoryRight={(imageProps) => (
                  <Icon {...imageProps} name="arrow-ios-forward-outline" />
                )}
                description={() => (
                  <Text
                    appearance={formik.values.notes ? "default" : "hint"}
                    category={formik.values.notes ? "p1" : "c1"}
                    style={styles.listItemText}
                  >
                    {formik.values.notes || "Tap to edit"}
                  </Text>
                )}
                onPress={() => setNotesEditingVisible(true)}
                title={() => (
                  <Text
                    appearance="hint"
                    category="label"
                    style={styles.listItemText}
                  >
                    General
                  </Text>
                )}
              />
              <Divider style={styles.divider} />
              <BaseListItem
                accessoryRight={(imageProps) => (
                  <Icon {...imageProps} name="arrow-ios-forward-outline" />
                )}
                description={() => (
                  <Text
                    appearance={formik.values.privateNotes ? "default" : "hint"}
                    category={formik.values.privateNotes ? "p1" : "c1"}
                    style={styles.listItemText}
                  >
                    {formik.values.privateNotes || "Tap to edit"}
                  </Text>
                )}
                onPress={() => setPrivateNotesEditingVisible(true)}
                title={() => (
                  <Text
                    appearance="hint"
                    category="label"
                    style={styles.listItemText}
                  >
                    Private
                  </Text>
                )}
              />
            </SectionItem>
            <Separator />
          </ScrollView>
        </Layout>
      </FullScreenModal>
      <LocationSettingModal
        initialCoordinates={formik.values.coordinates}
        isVisible={locationSettingVisible}
        onClose={() => setLocationSettingVisible(false)}
        onSubmit={(coordinates) => {
          formik.setFieldValue("coordinates", coordinates);
          setLocationSettingVisible(false);
        }}
      />
      <TextInputModal
        headerTitle="Notes"
        isVisible={notesEditingVisible}
        multiline
        onClose={() => setNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("notes", text);
          setNotesEditingVisible(false);
        }}
        text={formik.values.notes}
      />
      <TextInputModal
        headerTitle="Private notes"
        isVisible={privateNotesEditingVisible}
        multiline
        onClose={() => setPrivateNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("privateNotes", text);
          setPrivateNotesEditingVisible(false);
        }}
        text={formik.values.privateNotes}
      />
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard"
        isVisible={alertVisible}
        message="Discard new site?"
        onCancel={() => setAlertVisible(false)}
        onClose={() => setAlertVisible(false)}
        onConfirm={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
    </>
  );
};

export default CreateSiteForCustomerModal;
