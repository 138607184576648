import React from "react";

import FullScreenModal from "../FullScreen";
import MainScreen from "./MainScreen";

interface TimeSheetModalProps {
  isVisible: boolean;
  onClose: () => void;
  timeCardIds: Array<string>;
}

const TimesheetModal = ({
  isVisible,
  onClose,
  timeCardIds,
}: TimeSheetModalProps) => (
  <FullScreenModal isVisible={isVisible} onClose={onClose}>
    <MainScreen onClosePress={onClose} timeCardIds={timeCardIds} />
  </FullScreenModal>
);

export default TimesheetModal;
