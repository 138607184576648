import {
  Button,
  Divider,
  IndexPath,
  Input,
  Layout,
  ListItem,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import _ from "lodash";
import React, { memo, useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import HeaderWithTextAction from "../../../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../../../buildingBlocks/Separator";
import { addNotification } from "../../../../InAppNotifications";
import SectionItem from "../../../../SectionItem";
import regions from "../../../../../constants/regions";
import { statuses, types } from "../../../../../constants/vehicle";
import useIosBottomSpace from "../../../../../device/useIosBottomSpace";
import useAndroidBackHandler from "../../../../../device/useAndroidBackHandler";
import { Vehicle } from "../../../../../types";

const regionArray = Object.values(regions);
const statusArray = Object.values(statuses);
const typeArray = Object.values(types);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  form: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  multilineInput: {
    height: 60,
  },
  scrollContent: {
    paddingTop: 8,
  },
  select: {
    marginBottom: 4,
    marginEnd: 16,
    marginStart: 16,
  },
});

const initialValues = {
  description: "",
  make: "",
  model: "",
  year: "",
  color: "",
  license: {
    region: null,
    number: "",
  },
  vin: "",
  registeredOwner: "",
  type: null,
  status: null,
};

interface VehicleScreenProps {
  onBackPress: () => void;
  onDeletePress: () => void;
  onSubmitPress: (Vehicle) => void;
  vehicle?: Vehicle;
  visible: boolean;
}

const VehicleScreen = ({
  onBackPress,
  onDeletePress,
  onSubmitPress,
  visible,
  vehicle,
}: VehicleScreenProps) => {
  const iosBottomSpace = useIosBottomSpace();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (_.isEqual(values, initialValues)) {
        addNotification({
          message: "Cannot be empty.",
          status: "danger",
          title: "Error",
        });
      } else {
        const formattedVehicle = {
          description: values.description || null,
          make: values.make || null,
          model: values.model || null,
          // eslint-disable-next-line radix
          year: values.year ? parseInt(values.year) : null,
          color: values.color || null,
          license:
            values.license.region && values.license.number
              ? values.license
              : null,
          vin: values.vin || null,
          registeredOwner: values.registeredOwner || null,
          type: values.type,
          status: values.status,
        };
        onSubmitPress(formattedVehicle);
      }
    },
  });
  useAndroidBackHandler({ enabled: visible, onBackPress });
  useEffect(() => {
    if (visible) {
      if (vehicle) {
        formik.resetForm({
          values: {
            description: vehicle.description || "",
            make: vehicle.make || "",
            model: vehicle.model || "",
            year: vehicle.year ? vehicle.year.toString() : "",
            color: vehicle.color || "",
            license: {
              region: vehicle.license && vehicle.license.region,
              number: (vehicle.license && vehicle.license.number) || "",
            },
            vin: vehicle.vin || "",
            registeredOwner: vehicle.registeredOwner || "",
            type: vehicle.type,
            status: vehicle.status,
          },
        });
      } else {
        formik.resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <HeaderWithTextAction
        action={{
          "data-test": "submitButtonInVehicleModalScreen",
          loading: false,
          onPress: formik.handleSubmit,
          text: vehicle ? "Done" : "Add",
        }}
        navigation={{ icon: "arrow-back-outline", onPress: onBackPress }}
        title={vehicle ? "Edit vehicle" : "New vehicle"}
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView
          contentContainerStyle={[
            styles.scrollContent,
            { paddingBottom: iosBottomSpace + 8 },
          ]}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Separator />
          <SectionItem title="GENERAL">
            <View style={styles.form}>
              <Input
                data-test="descriptionInputInVehicleModalScreen"
                label="Description"
                multiline
                numberOfLines={3}
                onChangeText={formik.handleChange("description")}
                onFocus={() => {
                  formik.setFieldTouched("description");
                }}
                style={styles.input}
                textAlignVertical="top"
                textStyle={styles.multilineInput}
                defaultValue={formik.values.description}
              />
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.type
                    ? new IndexPath(
                        _.findIndex(
                          typeArray,
                          (type) => type.value === formik.values.type
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue("type", typeArray[index.row].value)
                }
                value={
                  types[formik.values.type]
                    ? types[formik.values.type].text
                    : null
                }
                label="Type"
                placeholder="Select option"
                style={styles.select}
              >
                {typeArray.map((type) => (
                  <SelectItem key={type.value} title={type.text} />
                ))}
              </Select>
              <Separator size="small" />
              <Select
                selectedIndex={
                  formik.values.status
                    ? new IndexPath(
                        _.findIndex(
                          statusArray,
                          (status) => status.value === formik.values.status
                        )
                      )
                    : null
                }
                onSelect={(index: any) =>
                  formik.setFieldValue("status", statusArray[index.row].value)
                }
                value={
                  statuses[formik.values.status]
                    ? statuses[formik.values.status].text
                    : null
                }
                label="Status"
                placeholder="Select option"
                style={styles.select}
              >
                {statusArray.map((status) => (
                  <SelectItem key={status.value} title={status.text} />
                ))}
              </Select>
              <Separator size="small" />
              <Input
                value={formik.values.registeredOwner}
                onChangeText={formik.handleChange("registeredOwner")}
                autoCapitalize="words"
                label="Registered owner"
                onFocus={() => {
                  formik.setFieldTouched("registeredOwner");
                }}
                style={styles.input}
              />
            </View>
          </SectionItem>
          <Separator />
          <SectionItem title="UNIT">
            <View style={styles.form}>
              <Input
                value={formik.values.color}
                onChangeText={formik.handleChange("color")}
                label="Color"
                onFocus={() => {
                  formik.setFieldTouched("color");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.year}
                onChangeText={formik.handleChange("year")}
                keyboardType="number-pad"
                label="Year"
                onFocus={() => {
                  formik.setFieldTouched("year");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.make}
                onChangeText={formik.handleChange("make")}
                label="Make"
                onFocus={() => {
                  formik.setFieldTouched("make");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.model}
                onChangeText={formik.handleChange("model")}
                label="Model"
                onFocus={() => {
                  formik.setFieldTouched("model");
                }}
                style={styles.input}
              />
              <Separator size="small" />
              <Input
                value={formik.values.vin}
                onChangeText={formik.handleChange("vin")}
                autoCapitalize="characters"
                label="VIN"
                onFocus={() => {
                  formik.setFieldTouched("vin");
                }}
                style={styles.input}
              />
            </View>
          </SectionItem>
          <Separator />
          <SectionItem title="VEHICLE LICENSE">
            <View style={styles.form}>
              <Input
                autoCapitalize="characters"
                label="Plate #"
                onFocus={() => {
                  formik.setFieldTouched("license.number");
                }}
                onChangeText={formik.handleChange("license.number")}
                style={styles.input}
                value={formik.values.license.number}
              />
              <Separator size="small" />
              <Select
                label="State"
                onSelect={(index: any) =>
                  formik.setFieldValue(
                    "license.region",
                    regionArray[index.row].value
                  )
                }
                placeholder="Select option"
                selectedIndex={
                  formik.values.license.region
                    ? new IndexPath(
                        _.findIndex(
                          regionArray,
                          (region) =>
                            region.value === formik.values.license.region
                        )
                      )
                    : null
                }
                style={styles.select}
                value={
                  regions[formik.values.license.region]
                    ? regions[formik.values.license.region].text
                    : null
                }
              >
                {regionArray.map((region) => (
                  <SelectItem key={region.value} title={region.text} />
                ))}
              </Select>
            </View>
          </SectionItem>
          {Boolean(vehicle) && (
            <>
              <Separator />
              <SectionItem status="danger" title="DANGER ZONE">
                <ListItem
                  accessoryRight={() => (
                    <View>
                      <Button
                        onPress={onDeletePress}
                        size="small"
                        status="danger"
                      >
                        Delete
                      </Button>
                    </View>
                  )}
                  description="Once you delete a vehicle there is no going back."
                  disabled
                  title="Delete this vehicle"
                />
              </SectionItem>
            </>
          )}
          <Separator />
        </ScrollView>
      </Layout>
    </>
  );
};

VehicleScreen.defaultProps = {
  vehicle: null,
};

export default memo(
  VehicleScreen,
  (prevProps, nextProps) => !prevProps.visible && !nextProps.visible
);
