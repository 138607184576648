import { useTheme } from "@ui-kitten/components";

import jobStatuses from "../../constants/jobStatuses";
import finishedJobStatuses from "../../constants/finishedJobStatuses";
import { FormattedJob } from "../../types";

interface ReturnValue {
  default: string;
  transparent: string;
}

const useJobStatusColors = (job: FormattedJob): ReturnValue => {
  const theme = useTheme();
  if (job.finished) {
    if (job.finished.status === finishedJobStatuses.completed.value) {
      return {
        default: theme["color-success-default"],
        transparent: theme["color-success-transparent-default"],
      };
    }
    if (job.finished.status === finishedJobStatuses.dismissed.value) {
      return {
        default: theme["color-basic-600"],
        transparent: theme["color-basic-transparent-default"],
      };
    }
    throw new Error(`Finished job status ${job.finished.status} is not valid`);
  } else {
    switch (job.status.id) {
      case jobStatuses.future.id:
        return {
          default: theme["color-info-default"],
          transparent: theme["color-info-transparent-default"],
        };
      case jobStatuses.inProgress.id:
        return {
          default: theme["color-primary-default"],
          transparent: theme["color-primary-transparent-default"],
        };
      case jobStatuses.late.id:
        return {
          default: theme["color-warning-default"],
          transparent: theme["color-warning-transparent-default"],
        };
      case jobStatuses.immediate.id:
        return {
          default: theme["color-danger-default"],
          transparent: theme["color-danger-transparent-default"],
        };
      default:
        throw new Error(`Job status ${job.status.id} is not valid`);
    }
  }
};

export default useJobStatusColors;
