import API, { GraphQLResult } from "@aws-amplify/api";

import { Customer } from "../../../types";
import deepCopy from "../../utils/deepCopy";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

interface CreateCustomerInput {
  id: string;
  name: string;
  notes: string;
  emails: Array<CustomerEmailInput>;
}

interface CustomerEmailInput {
  id: string;
  email: string;
}

const createCustomer = async ({
  emails,
  id,
  name,
  notes,
}: CreateCustomerInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: deepCopy({
        emails,
        id,
        name,
        notes,
      }),
    })) as GraphQLResult<{ createCustomer: Customer }>;
    return data.createCustomer;
  } catch ({ errors }) {
    throw generateErrorMessage("createCustomer", errors);
  }
};

export default createCustomer;
