import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeTaxRate } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

export type ListTaxRatesInput = Pick<
  Stripe.TaxRateListParams,
  "active" | "starting_after"
>;

const listTaxRates = async ({ active, starting_after }: ListTaxRatesInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        active,
        starting_after,
      },
    })) as GraphQLResult<{
      listTaxRates: {
        data: Array<StripeTaxRate>;
        has_more: boolean;
      };
    }>;
    return data.listTaxRates;
  } catch ({ errors }) {
    throw generateErrorMessage("listTaxRates", errors);
  }
};

export default listTaxRates;
