import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import selectUser from "../../../store/auth/selectors/selectUser";
import { setJobPending } from "../../../store/jobs/slice";
import selectJobById from "../../../store/jobs/selectors/selectJobById";

interface DeleteActionItemCompletionRecordInput {
  actionItemId: string;
  jobId: string;
}

const dispatchOptimisticResponse = ({
  actionItemId,
  jobId,
}: DeleteActionItemCompletionRecordInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = selectUser(state);
  const job = selectJobById(state, jobId);
  const timestamp = new Date().toISOString();
  dispatch(
    setJobPending({
      job: {
        ...job,
        actionItemCompletionRecords: job.actionItemCompletionRecords.filter(
          (actionCompletionRecord) =>
            actionCompletionRecord.actionItemId !== actionItemId ||
            actionCompletionRecord.record.by !== userId
        ),
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
      mutation: "deleteActionItemCompletionRecord",
    })
  );
};

const deleteActionItemCompletionRecord = ({
  actionItemId,
  jobId,
}: DeleteActionItemCompletionRecordInput) => {
  dispatchOptimisticResponse({ actionItemId, jobId });
  client.mutate({
    mutation,
    variables: {
      actionItemId,
      jobId,
    },
  });
};

export default deleteActionItemCompletionRecord;
