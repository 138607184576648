import { Divider, Input, Layout, Text } from "@ui-kitten/components";
import currency from "currency.js";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";

import createPrice from "../../../api/functions/createPrice";
import { StripePrice } from "../../../types";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import { addNotification } from "../../InAppNotifications";
import ToggleListItem from "../../listItems/Toggle";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface FormValues {
  name: string;
  taxesEnabled: boolean;
  unitAmount: string;
}

interface CreateProductModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  onSuccess: (price: StripePrice) => void;
}

const Root = styled(Layout)`
  flex: 1;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledToggleListItem = styled(ToggleListItem)`
  margin: 0 16px;
`;

const createProductErrorTitle = "Create product failed";

const CreateProductModal = ({
  isVisible,
  onClose,
  onSuccess,
}: CreateProductModalProps) => {
  const [saveProductPending, setSaveProductPending] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      taxesEnabled: true,
      unitAmount: "",
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      if (values.name) {
        setSaveProductPending(true);
        const unitAmount = parseFloat(values.unitAmount);
        if (Number.isNaN(unitAmount)) {
          addNotification({
            message: "Price is not a number",
            status: "danger",
            title: createProductErrorTitle,
          });
        }
        const price = await createPrice({
          currency: "usd",
          metadata: {
            taxesEnabled: formik.values.taxesEnabled ? "true" : "false",
          },
          product_data: {
            name: values.name,
          },
          unit_amount: currency(unitAmount).intValue,
        });
        await onSuccess(price);
        setSaveProductPending(false);
      } else {
        addNotification({
          message: "Name cannot be empty",
          status: "danger",
          title: createProductErrorTitle,
        });
      }
    },
  });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <Root level="2">
        <HeaderWithTextAction
          navigation={{
            icon: "close-outline",
            onPress: onClose,
          }}
          title="New product"
        />
        <Divider />
        <ScrollView>
          <Separator size="medium" />
          <StyledCard title="Product information">
            <StyledInput
              label="Name"
              onChangeText={(text) => formik.setFieldValue("name", text)}
              value={formik.values.name}
            />
            <Separator size="medium" />
            <StyledInput
              accessoryLeft={() => <StyledText>$</StyledText>}
              keyboardType="numeric"
              label="Price"
              onChangeText={(text) => formik.setFieldValue("unitAmount", text)}
              value={formik.values.unitAmount}
            />
            <Separator size="medium" />
          </StyledCard>
          <Separator />
          <StyledToggleListItem
            checked={!formik.values.taxesEnabled}
            onChange={() =>
              formik.setFieldValue("taxesEnabled", !formik.values.taxesEnabled)
            }
            title="Tax-exempt"
          />
          <Separator />
        </ScrollView>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                loading={saveProductPending}
                onPress={() => formik.handleSubmit()}
              >
                Save product
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </Root>
    </FullScreenModal>
  );
};

export default CreateProductModal;
