import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "@ui-kitten/components";

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
  },
  text: {
    flex: 1,
    textAlign: "center",
  },
});

const DaysOfWeek = () => {
  return (
    <View style={styles.root}>
      <Text category="c1" style={styles.text}>
        Sun
      </Text>
      <Text category="c1" style={styles.text}>
        Mon
      </Text>
      <Text category="c1" style={styles.text}>
        Tue
      </Text>
      <Text category="c1" style={styles.text}>
        Wed
      </Text>
      <Text category="c1" style={styles.text}>
        Thu
      </Text>
      <Text category="c1" style={styles.text}>
        Fri
      </Text>
      <Text category="c1" style={styles.text}>
        Sat
      </Text>
    </View>
  );
};

export default DaysOfWeek;
