import store from "../../../store";
import { setInboxItemAsync } from "../../../store/inbox/slice";
import { InboxItemTypes, Reminder } from "../../../types";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import mutation from "./graphql";

interface CreateInboxItemInput {
  description?: string;
  id: string;
  metadata: {
    customerId: string;
    dateTime: string;
    userId: string;
  };
  title: string;
  type: InboxItemTypes;
}

const dispatchOptimisticResponse = ({
  description,
  id,
  metadata,
  title,
  type,
}: CreateInboxItemInput) => {
  const { dispatch, getState } = store;
  const state = getState();
  const userId = state.auth.user.id;
  const timestamp = new Date().toISOString();
  const reminder: Reminder = {
    createdAt: timestamp,
    createdBy: userId,
    description,
    id,
    metadata: {
      customerId: metadata.customerId,
      dateTime: metadata.dateTime,
      userId: metadata.userId,
    },
    title,
    type,
    syncDateTime: timestamp,
  };
  dispatch(
    setInboxItemAsync.pending({
      mutation: "createInboxItem",
      inboxItem: reminder,
    })
  );
};

const createInboxItem = ({
  description,
  id,
  metadata,
  title,
  type,
}: CreateInboxItemInput) => {
  dispatchOptimisticResponse({ description, id, metadata, title, type });
  client.mutate({
    mutation,
    variables: deepCopy({
      description,
      id,
      metadata,
      title,
      type,
    }),
  });
};

export default createInboxItem;
