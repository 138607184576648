import gql from "graphql-tag";

import { stripeQuoteFragment } from "../../../fragments";

const listQuotes = gql`
  query listQuotes(
    $customer: ID
    $starting_after: ID
    $status: StripeQuoteStatus
  ) {
    listQuotes(
      customer: $customer
      starting_after: $starting_after
      status: $status
    ) {
      data {
        ...stripeQuoteFragment
      }
      has_more
    }
  }
  ${stripeQuoteFragment}
`;

export default listQuotes;
