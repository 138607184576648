import React, { useState, useEffect, memo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  Divider,
  Input,
  InputProps,
  Layout,
  Text,
  useTheme,
} from "@ui-kitten/components";
import { SectionList, StyleSheet, View } from "react-native";
import sectionListGetItemLayout from "react-native-section-list-get-item-layout";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";

import FilterHorizontalList from "../../FilterHorizontalList";
import FullScreenModal from "../FullScreen";
import { navigate } from "../../../navigation/navigator";
import navigationRoutes from "../../../constants/navigationRoutes";
import selectMapJobs from "../../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../../store/jobs/selectors/selectJobEntities";
import selectSiteEntities from "../../../store/sites/selectors/selectEntities";
import {
  setFilterStatus,
  setFilterUnassignedOnly,
} from "../../../store/jobs/slice";
import formatSectionListData from "../../../utils/formatSectionListData";
import ScheduleJobListItem, {
  height as listItemHeight,
} from "../../listItems/ScheduleJob";
import { Header } from "../../buildingBlocks";
import Separator from "../../buildingBlocks/Separator";

const RootLayout = styled(Layout)`
  flex: 1;
`;

const StyledScheduleJobListItem = styled(ScheduleJobListItem)`
  margin-left: 16px;
  margin-right: 16px;
`;

const styles = StyleSheet.create({
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  sectionHeader: {
    borderRadius: 8,
    marginEnd: 16,
    marginStart: 16,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  warningMessageBoldText: {
    fontWeight: "bold",
  },
  warningMessageContainer: {
    borderRadius: 8,
    justifyContent: "center",
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
    marginTop: 0,
    minHeight: 48,
    overflow: "hidden",
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const MemoizedInput = memo((props: InputProps) => {
  const theme = useTheme();
  return (
    <Input
      {...props}
      style={[
        styles.input,
        { backgroundColor: theme["background-basic-color-1"] },
      ]}
    />
  );
});

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const UpcomingJobsListModal = ({ isVisible, onClose }: Props) => {
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();

  const { visible: visibleJobs } = useSelector(selectMapJobs);
  const jobs = useSelector(selectJobEntities);
  const sites = useSelector(selectSiteEntities);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const formattedQuery = query.toLowerCase();
    const tempResults = [];
    visibleJobs.forEach((jobId) => {
      const site = sites[jobs[jobId].siteId];

      if (
        site.name.toLowerCase().includes(formattedQuery) ||
        site.address.toLowerCase().includes(formattedQuery)
      ) {
        tempResults.push(jobId);
      }
    });
    setResults(tempResults);
  }, [query, visibleJobs, jobs, sites]);
  useEffect(() => {
    if (isVisible) {
      setQuery("");
    } else {
      batch(() => {
        dispatch(setFilterStatus({ status: null }));
        dispatch(setFilterUnassignedOnly({ unassignedOnly: false }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const { sections } = formatSectionListData({
    data: query
      ? results.map((id) => jobs[id])
      : visibleJobs.map((id) => jobs[id]),
    startDateTimeExtractor: (item) => item.startDateTime,
    idExtractor: (item) => item.id,
    sortCompareFunction: (a, b) =>
      a.startDateTime.localeCompare(b.startDateTime),
  });

  const handleClose = () => {
    onClose();
  };

  const handleJobPress = (id) => {
    onClose();
    navigate(navigationRoutes.JobDetails, { jobId: id });
  };

  const renderListItem = ({ item }) => (
    <StyledScheduleJobListItem
      data-test={`${item}-scheduleJobListItem`}
      jobId={item}
      key={item}
      onPress={handleJobPress}
    />
  );

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={handleClose}>
        <Header
          navigation={{ icon: "close-outline", onPress: handleClose }}
          title="List of map jobs"
        />
        <Divider />
        <RootLayout level="2">
          <SectionList
            contentContainerStyle={[
              { paddingBottom: 16 + insets.bottom, paddingTop: 16 },
            ]}
            getItemLayout={sectionListGetItemLayout({
              getItemHeight: () => listItemHeight,
              getSeparatorHeight: () => 1,
              getSectionHeaderHeight: () => 36,
            })}
            ItemSeparatorComponent={() => <Separator size="medium" />}
            keyExtractor={(item) => item}
            ListEmptyComponent={
              <View style={styles.listEmptyComponent}>
                <Text category="h3">No jobs found</Text>
              </View>
            }
            ListHeaderComponent={
              <>
                <FilterHorizontalList />
                <Separator size="medium" />
                <MemoizedInput
                  clearButtonMode="while-editing"
                  data-test="inputInUpcomingJobsModal"
                  onChangeText={setQuery}
                  placeholder="Search..."
                  value={query}
                />
                <Separator size="medium" />
              </>
            }
            renderItem={renderListItem}
            renderSectionHeader={({ section }) => {
              const { title } = section;
              return (
                <Layout level="3" style={styles.sectionHeader}>
                  <Text category="s1">{title}</Text>
                </Layout>
              );
            }}
            sections={sections}
            SectionSeparatorComponent={() => <Separator size="medium" />}
            stickySectionHeadersEnabled
          />
        </RootLayout>
      </FullScreenModal>
    </>
  );
};

export default UpcomingJobsListModal;
