import { createSelector } from "@reduxjs/toolkit";

import selectGroups from "../selectGroups";

const selectEntities = createSelector(
  [selectGroups],
  (groups) => groups.entities
);

export default selectEntities;
