import { Divider, Icon, Input, useTheme } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { FlatList, StyleSheet } from "react-native";

import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import CustomerListItem from "../../listItems/Customer";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import useCustomerList from "../../../hooks/useCustomerList";

interface BaseProps extends FullScreenModalProps {
  headerSubtitle?: string;
  headerTitle?: string;
}

interface SingleSelectProps extends BaseProps {
  multiSelect?: false;
  onSubmit: (customerId?: string) => void;
  selected?: string;
}

interface MultiSelectProps extends BaseProps {
  multiSelect: true;
  onSubmit: (customerIds: Array<string>) => void;
  selected: Array<string>;
}

type Props = SingleSelectProps | MultiSelectProps;

const isMultiSelect = (props: Props): props is MultiSelectProps =>
  props.multiSelect;

const styles = StyleSheet.create({
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
});

const CustomerSelectModal = (props: Props) => {
  const theme = useTheme();
  const { headerSubtitle, headerTitle, isVisible, onClose } = props;
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  const { customerIds, searchText, setSearchText } = useCustomerList();
  const [tempSelected, setTempSelected] = useState<Array<string>>([]);

  useEffect(() => {
    if (isVisible && isMultiSelect(props)) {
      setTempSelected(props.selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <HeaderWithTextAction
        action={
          isMultiSelect(props) && {
            onPress: () => props.onSubmit(tempSelected),
            text: "Done",
          }
        }
        navigation={{ icon: "close-outline", onPress: onClose }}
        subtitle={headerSubtitle}
        title={headerTitle}
      />
      <Separator size="small" />
      <Input
        clearButtonMode="while-editing"
        data-test="inputInCustomerSelectModal"
        onChangeText={setSearchText}
        placeholder="Search..."
        style={styles.input}
        value={searchText}
      />
      <Separator size="small" />
      <Divider />
      <FlatList
        data={customerIds}
        data-test="listInCustomerSelectModal"
        extraData={tempSelected}
        keyExtractor={(item) => item}
        renderItem={({ item: customerId }) => (
          <CustomerListItem
            accessoryRight={(imageProps) => {
              if (isMultiSelect(props)) {
                if (tempSelected.includes(customerId)) {
                  return (
                    <Icon
                      {...imageProps}
                      fill={theme["color-primary-default"]}
                      name="checkmark-outline"
                    />
                  );
                }
              } else if (customerId === props.selected) {
                return (
                  <Icon
                    {...imageProps}
                    fill={theme["color-primary-default"]}
                    name="checkmark-outline"
                  />
                );
              }
              return null;
            }}
            customerId={customerId}
            data-test={`${customerId}-customerListItem`}
            onPress={() => {
              if (isMultiSelect(props)) {
                if (tempSelected.includes(customerId)) {
                  setTempSelected(
                    tempSelected.filter(
                      (currentCustomerId) => currentCustomerId !== customerId
                    )
                  );
                } else {
                  setTempSelected([
                    ...tempSelected.filter(
                      (currentCustomerId) => currentCustomerId !== customerId
                    ),
                    customerId,
                  ]);
                }
              } else {
                props.onSubmit(
                  customerId !== props.selected ? customerId : null
                );
              }
            }}
          />
        )}
      />
    </FullScreenModal>
  );
};

export default CustomerSelectModal;
