import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FlatList, Keyboard, ScrollView, StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { Stripe } from "stripe";
import styled from "styled-components/native";

import listQuotes from "../../api/functions/listQuotes";
import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import HeaderContainer from "../../components/HeaderContainer";
import IconButton from "../../components/IconButton";
import { addNotification } from "../../components/InAppNotifications";
import ListItem from "../../components/listItems/Base";
import QuoteListItem from "../../components/listItems/Quote";
import StripeCustomerListItem from "../../components/listItems/StripeCustomer";
import QuoteEditingModal from "../../components/modals/QuoteEditing";
import StripeCustomerSelectModal from "../../components/modals/StripeCustomerSelect";
import selectQuotesStale from "../../store/payments/selectors/selectQuotesStale";
import { setQuotesStale } from "../../store/payments/slice";
import { StackParamList, StripeQuote } from "../../types";

const FlatListContainer = styled.View`
  flex: 1;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const Row = styled.View`
  align-items: center;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledStripeCustomerListItem = styled(StripeCustomerListItem)`
  margin: 0 16px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const Quotes = () => {
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Quotes">
  >();
  const { params } = useRoute<RouteProp<StackParamList, "Quotes">>();
  const customerId = params?.customerId;
  const theme = useTheme();

  const stale = useSelector(selectQuotesStale);

  const [listQuotesParams, setListQuotesParams] = useState<{
    customer?: string;
    status?: Stripe.Quote.Status;
  }>({
    customer: customerId,
  });
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [quotes, setQuotes] = useState<{
    data: Array<StripeQuote>;
    has_more: boolean;
  }>();

  const [
    stripeCustomerSelectModalVisible,
    setStripeCustomerSelectModalVisible,
  ] = useState(false);
  const [quoteEditingModalVisible, setQuoteEditingModalVisible] = useState(
    false
  );

  const runListQuotes = useCallback(async () => {
    try {
      setQuotes(
        await listQuotes({
          customer: listQuotesParams.customer,
          status: listQuotesParams.status,
        })
      );
      dispatch(setQuotesStale({ quotesStale: false }));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List quotes failed",
      });
    }
    setLoading(false);
  }, [listQuotesParams]);
  const loadQuotes = useCallback(async () => {
    setLoading(true);
    setQuotes(null);
    await runListQuotes();
    setLoading(false);
  }, [runListQuotes]);
  const navigateToQuoteDetails = useCallback(
    (quote: StripeQuote) =>
      navigation.navigate("QuoteDetails", { quoteId: quote.id }),
    [navigation]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      setQuotes(null);
      await runListQuotes();
      setLoading(false);
    })();
  }, [runListQuotes]);
  useEffect(() => {
    if (isFocused && stale) {
      (async () => {
        setLoading(true);
        setQuotes(null);
        await runListQuotes();
        setLoading(false);
      })();
    }
  }, [isFocused, stale]);
  useEffect(() => {
    if (customerId) {
      setListQuotesParams((prevState) => ({
        ...prevState,
        customer: customerId,
      }));
    }
  }, [customerId]);

  const fetchingNextPage = useRef(false);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <Header
            navigation={{
              icon: "arrow-back",
              onPress: navigation.goBack,
            }}
            title="Quotes"
          />
        </HeaderContainer>
        <Divider />
        <FlatListContainer>
          <FlatList
            contentContainerStyle={[
              styles.flatListContentContainerStyle,
              {
                paddingBottom: insets.bottom,
                paddingEnd: insets.right,
                paddingStart: insets.left,
              },
            ]}
            data={quotes?.data}
            ItemSeparatorComponent={() => (
              <Layout>
                <StyledDivider />
              </Layout>
            )}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={
              <ListEmptyContainer>
                <Separator />
                {loading ? (
                  <Spinner />
                ) : (
                  <Text appearance="hint" category="s1">
                    No results found
                  </Text>
                )}
              </ListEmptyContainer>
            }
            ListHeaderComponent={
              <>
                <ScrollView
                  contentContainerStyle={styles.scrollViewContentContainerStyle}
                  horizontal
                >
                  <StyledButton
                    appearance={!listQuotesParams.status ? "filled" : "outline"}
                    onPress={() =>
                      setListQuotesParams((prevState) => ({
                        ...prevState,
                        status: undefined,
                      }))
                    }
                    status="control"
                  >
                    All
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={
                      listQuotesParams.status === "draft" ? "filled" : "outline"
                    }
                    onPress={() =>
                      setListQuotesParams((prevState) => ({
                        ...prevState,
                        status: "draft",
                      }))
                    }
                    status="control"
                  >
                    Drafts
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={
                      listQuotesParams.status === "open" ? "filled" : "outline"
                    }
                    onPress={() =>
                      setListQuotesParams((prevState) => ({
                        ...prevState,
                        status: "open",
                      }))
                    }
                    status="control"
                  >
                    Outstanding
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={
                      listQuotesParams.status === "accepted"
                        ? "filled"
                        : "outline"
                    }
                    onPress={() =>
                      setListQuotesParams((prevState) => ({
                        ...prevState,
                        status: "accepted",
                      }))
                    }
                    status="control"
                  >
                    Accepted
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={
                      listQuotesParams.status === "canceled"
                        ? "filled"
                        : "outline"
                    }
                    onPress={() =>
                      setListQuotesParams((prevState) => ({
                        ...prevState,
                        status: "canceled",
                      }))
                    }
                    status="control"
                  >
                    Canceled
                  </StyledButton>
                </ScrollView>
                <Separator size="small" />
                {listQuotesParams.customer ? (
                  <Row>
                    <View style={{ flex: 1 }}>
                      <StyledStripeCustomerListItem
                        accessoryLeft={(imageProps) => (
                          <Icon {...imageProps} name="person" />
                        )}
                        onPress={() =>
                          setStripeCustomerSelectModalVisible(true)
                        }
                        stripeCustomer={listQuotesParams.customer}
                      />
                    </View>
                    <IconButton
                      appearance="ghost"
                      name="close"
                      onPress={() =>
                        setListQuotesParams((prevState) => ({
                          ...prevState,
                          customer: undefined,
                        }))
                      }
                      status="basic"
                    />
                    <Separator horizontal size="medium" />
                  </Row>
                ) : (
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="person" />
                    )}
                    onPress={() => setStripeCustomerSelectModalVisible(true)}
                    style={{ marginEnd: 16, marginStart: 16 }}
                    title="Filter by customer"
                  />
                )}
                <Separator size="medium" />
              </>
            }
            onEndReached={async () => {
              if (quotes.has_more && !fetchingNextPage.current) {
                fetchingNextPage.current = true;
                try {
                  const listQuotesResult = await listQuotes({
                    customer: listQuotesParams.customer,
                    starting_after: quotes.data[quotes.data.length - 1].id,
                    status: listQuotesParams.status,
                  });
                  setQuotes((prevState) => ({
                    data: prevState.data.concat(listQuotesResult.data),
                    has_more: listQuotesResult.has_more,
                  }));
                } catch (error) {
                  addNotification({
                    message: error?.message || error,
                    status: "danger",
                    title: "List quotes failed",
                  });
                }
                fetchingNextPage.current = false;
              }
            }}
            onRefresh={async () => {
              setRefreshing(true);
              await runListQuotes();
              setRefreshing(false);
            }}
            refreshing={refreshing}
            renderItem={({ item: quote }) => (
              <Layout>
                <QuoteListItem onPress={navigateToQuoteDetails} quote={quote} />
              </Layout>
            )}
          />
        </FlatListContainer>
        <FAB
          icon="plus"
          onPress={() => {
            Keyboard.dismiss();
            setQuoteEditingModalVisible(true);
          }}
          style={{
            backgroundColor: theme["color-primary-default"],
            position: "absolute",
            margin: 16,
            right: 0,
            bottom: insets.bottom,
          }}
        />
      </Root>
      <QuoteEditingModal
        isVisible={quoteEditingModalVisible}
        onClose={(quotesStale) => {
          setQuoteEditingModalVisible(false);
          if (quotesStale) {
            loadQuotes();
          }
        }}
      />
      <StripeCustomerSelectModal
        headerTitle="Filter quotes by customer"
        isVisible={stripeCustomerSelectModalVisible}
        onClose={() => setStripeCustomerSelectModalVisible(false)}
        onSubmit={(customer) => {
          setListQuotesParams((prevState) => ({
            ...prevState,
            customer: customer.stripeCustomerId,
          }));
          setStripeCustomerSelectModalVisible(false);
        }}
      />
    </>
  );
};

export default Quotes;
