import * as ImageManipulator from "expo-image-manipulator";
import { SaveFormat } from "expo-image-manipulator";

const resizeImage = (
  image,
  maximalValuesPerDimension = { width: 450, height: 600 }
) => {
  // 1. define the maximal dimension and the allowed value for it
  const largestDimension = image.width > image.height ? "width" : "height";
  const initialValueOfLargestDimension = image[largestDimension];
  const maximalAllowedValueOfLargestDimension =
    maximalValuesPerDimension[largestDimension];
  const targetValueOfLargestDimension =
    initialValueOfLargestDimension > maximalAllowedValueOfLargestDimension
      ? maximalAllowedValueOfLargestDimension
      : initialValueOfLargestDimension;
  // 2. resize the photo w/ that target value for that dimension (keeping the aspect ratio)
  return ImageManipulator.manipulateAsync(
    image.uri,
    [
      { resize: { [largestDimension]: targetValueOfLargestDimension } },
      { rotate: 360 },
    ],
    { base64: true, format: SaveFormat.PNG }
  );
};

export default resizeImage;
