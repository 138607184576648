import { createSelector } from "@reduxjs/toolkit";

import selectCustomerById from "../selectCustomerById";
import selectCustomerSiteIds from "../../../sites/selectors/selectCustomerSiteIds";

const selectCustomerWithSiteIdsById = createSelector(
  [selectCustomerById, selectCustomerSiteIds],
  (customer, siteIds) => ({ ...customer, siteIds })
);

export default selectCustomerWithSiteIdsById;
