import gql from "graphql-tag";

import { privateCompanySiteFragment } from "../../../fragments";

export default gql`
  mutation adminCreateCompanySite(
    $id: ID!
    $coordinates: CoordinatesInput!
    $address: String!
    $name: String!
    $notes: String!
    $privateNotes: String!
    $geofence: Int!
    $timezone: String!
  ) {
    adminCreateCompanySite(
      id: $id
      coordinates: $coordinates
      address: $address
      name: $name
      notes: $notes
      privateNotes: $privateNotes
      geofence: $geofence
      timezone: $timezone
    ) {
      ...privateCompanySiteFragment
    }
  }
  ${privateCompanySiteFragment}
`;
