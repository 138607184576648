/* eslint-disable react/jsx-props-no-spreading */
import { Button as UiKittenButton, ButtonProps } from "@ui-kitten/components";
import React from "react";

import ConditionalTooltip, {
  Props as ConditionalTooltipPropsType,
} from "../ConditionalTooltip";
import SpinnerAccessory from "../SpinnerAccessory";

export interface Props extends ButtonProps {
  ConditionalTooltipProps?: Omit<ConditionalTooltipPropsType, "children">;
  loading?: boolean;
}

const Button = ({
  accessoryLeft,
  accessoryRight,
  appearance = "filled",
  ConditionalTooltipProps,
  children,
  disabled,
  loading,
  onPress,
  size = "medium",
  status = "primary",
  style,
}: Props) => {
  return (
    <ConditionalTooltip {...ConditionalTooltipProps}>
      <UiKittenButton
        accessoryLeft={loading ? SpinnerAccessory : accessoryLeft}
        accessoryRight={accessoryRight}
        appearance={appearance}
        disabled={disabled || loading}
        onPress={onPress}
        size={size}
        status={status}
        style={style}
      >
        {children}
      </UiKittenButton>
    </ConditionalTooltip>
  );
};

Button.defaultProps = {
  ConditionalTooltipProps: undefined,
  loading: undefined,
};

export default Button;
