import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";

interface CreateJobSummaryInput {
  jobIds: Array<string>;
}

const createJobSummary = async ({ jobIds }: CreateJobSummaryInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        jobIds,
      },
    })) as GraphQLResult<{
      createJobSummary: string;
    }>;
    return data.createJobSummary;
  } catch ({ errors }) {
    console.log("createJobSummary", errors);
    throw generateErrorMessage("createJobSummary", errors);
  }
};

export default createJobSummary;
