export const ethnicity = {
  HISPANIC: {
    text: "Hispanic",
    value: "HISPANIC",
  },
  NON_HISPANIC: {
    text: "Non-hispanic",
    value: "NON_HISPANIC",
  },
  UNKNOWN: {
    text: "Unknown",
    value: "UNKNOWN",
  },
};

export const eyeColor = {
  BROWN: {
    text: "Brown",
    value: "BROWN",
  },
  BLUE: {
    text: "Blue",
    value: "BLUE",
  },
  GREEN: {
    text: "Green",
    value: "GREEN",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const hairColor = {
  BLONDE: {
    text: "Blonde",
    value: "BLONDE",
  },
  BROWN: {
    text: "Brown",
    value: "BROWN",
  },
  BLACK: {
    text: "Black",
    value: "BLACK",
  },
  RED: {
    text: "Red",
    value: "RED",
  },
  BALD: {
    text: "Bald",
    value: "BALD",
  },
  GREY: {
    text: "Grey",
    value: "GREY",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const race = {
  WHITE: {
    text: "White",
    value: "WHITE",
  },
  BLACK: {
    text: "Black",
    value: "BLACK",
  },
  ASIAN: {
    text: "Asian",
    value: "ASIAN",
  },
  NATIVE_AMERICAN: {
    text: "Native American",
    value: "NATIVE_AMERICAN",
  },
  UNKNOWN: {
    text: "Unknown",
    value: "UNKNOWN",
  },
};

export const sex = {
  MALE: {
    text: "Male",
    value: "MALE",
  },
  FEMALE: {
    text: "Female",
    value: "FEMALE",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const type = {
  VICTIM: {
    text: "Victim",
    value: "VICTIM",
  },
  INTERVIEWED: {
    text: "Interviewed",
    value: "INTERVIEWED",
  },
  REPORTING_PERSON: {
    text: "Reporting person",
    value: "REPORTING_PERSON",
  },
  PARENT_GUARDIAN: {
    text: "Parent/Guardian",
    value: "PARENT_GUARDIAN",
  },
  WITNESS: {
    text: "Witness",
    value: "WITNESS",
  },
  DECEASED: {
    text: "Deceased",
    value: "DECEASED",
  },
  MISSING_RUNAWAY: {
    text: "Missing/Runaway",
    value: "MISSING_RUNAWAY",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};
