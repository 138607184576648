const getColorScheme = (appearance) => {
  if (appearance.useSystemColorScheme) {
    const colorScheme = appearance.systemColorScheme;
    if (colorScheme === "no-preference") {
      return "light";
    }
    return colorScheme;
  }
  return appearance.manualColorScheme;
};

export default getColorScheme;
