import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectEntities";
import selectUser from "../../../auth/selectors/selectUser";

const selectSignedInUser = createSelector(
  [selectUser, selectEntities],
  (user, entities) => entities[user && user.id]
);

export default selectSignedInUser;
