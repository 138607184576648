import {
  CheckBox,
  Divider,
  Icon,
  Layout,
  Spinner,
  Text,
} from "@ui-kitten/components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";

import createQuote, {
  CreateQuoteInput,
} from "../../../api/functions/createQuote";
import finalizeQuote from "../../../api/functions/finalizeQuote";
import listTaxRates from "../../../api/functions/listTaxRates";
import sendQuote from "../../../api/functions/sendQuote";
import updateQuote, {
  UpdateQuoteInput,
} from "../../../api/functions/updateQuote";
import {
  StripeExpandedQuoteLineItem,
  StripeExpandedQuote,
  StripeTaxRate,
  PostmarkMessageSendingResponse,
} from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import { addNotification } from "../../InAppNotifications";
import QuoteLineItemListItem from "../../listItems/QuoteLineItem";
import StripeCustomerListItem from "../../listItems/StripeCustomer";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import SendJobSummariesModal from "../PostmarkResponsesModal";
import StripeCustomerSelectModal from "../StripeCustomerSelect";
import StripeLineItemEditingModal from "../StripeLineItemEditing";
import TaxRateSelectModal from "../TaxRateSelect";

interface QuoteEditingModalProps
  extends Pick<FullScreenModalProps, "isVisible"> {
  onClose: (stale?: boolean) => void;
  quote?: StripeExpandedQuote;
}

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const SummaryRow = styled.View`
  flex-direction: row;
`;

const SummaryRowNameContainer = styled.View`
  align-items: flex-end;
  flex: 2;
`;

const SummaryRowValueContainer = styled.View`
  align-items: flex-end;
  flex: 1;
`;

const mapLineItemToLineItemInput = ({
  lineItem,
  zeroTaxRateId,
}: {
  lineItem: StripeExpandedQuoteLineItem;
  zeroTaxRateId?: string;
}) => ({
  price: lineItem.price.id,
  quantity: lineItem.quantity,
  tax_rates:
    lineItem.price.metadata.taxesEnabled === "false" ? [zeroTaxRateId] : [],
});

const QuoteEditingModal = ({
  isVisible,
  onClose,
  quote,
}: QuoteEditingModalProps) => {
  const [sessionQuote, setSessionQuote] = useState<StripeExpandedQuote>(quote);
  const [autoSavePending, setAutoSavePending] = useState(false);

  const [loading, setLoading] = useState(false);
  const [zeroTaxRateId, setZeroTaxRateId] = useState<string>();

  const [finalizeQuotePending, setFinalizeQuotePending] = useState(false);

  const [sendQuoteOnFinalize, setSendQuoteOnFinalize] = useState(false);
  const [customerSelectModalVisible, setCustomerSelectModalVisible] = useState(
    false
  );
  const [stripeLineItemEditingModal, setStripeLineItemEditingModal] = useState<{
    isVisible: boolean;
    stripeLineItem?: StripeExpandedQuoteLineItem;
    stripeLineItemIndex?: number;
  }>({
    isVisible: false,
  });
  const [taxRateSelectModalVisible, setTaxRateSelectModalVisible] = useState(
    false
  );

  const [sendQuotePending, setSendQuotePending] = useState(false);
  const [postmarkModalVisible, setPostmarkModalVisible] = useState(false);
  const [postmarkResponses, setPostmarkResponses] = useState<
    PostmarkMessageSendingResponse[]
  >([]);

  const [quoteStale, setQuoteStale] = useState(false);

  const runListTaxRates = useCallback(async () => {
    try {
      let data: Array<StripeTaxRate>;
      let hasMore: boolean;
      do {
        const listTaxRatesResult = await listTaxRates({
          starting_after: data && data[data.length - 1].id,
        });
        data = data
          ? data.concat(listTaxRatesResult.data)
          : listTaxRatesResult.data;
        hasMore = listTaxRatesResult.has_more;
      } while (hasMore);
      setZeroTaxRateId(
        data[data.findIndex((taxRate) => taxRate.percentage === 0)].id
      );
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List tax rates failed",
      });
    }
  }, []);
  const runCreateQuote = useCallback(
    async ({
      createQuoteInput,
      onSuccess,
    }: {
      createQuoteInput: CreateQuoteInput;
      onSuccess: () => void;
    }) => {
      setAutoSavePending(true);
      try {
        setSessionQuote(await createQuote(createQuoteInput));
        setQuoteStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Create quote failed",
        });
      }
      setAutoSavePending(false);
    },
    []
  );
  const runUpdateQuote = useCallback(
    async ({
      onSuccess,
      updateQuoteInput,
    }: {
      onSuccess: () => void;
      updateQuoteInput: UpdateQuoteInput;
    }) => {
      setAutoSavePending(true);
      try {
        setSessionQuote(await updateQuote(updateQuoteInput));
        setQuoteStale(true);
        onSuccess();
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: "Update quote failed",
        });
      }
      setAutoSavePending(false);
    },
    []
  );
  const runSendQuote = useCallback(async (quoteId: string) => {
    setPostmarkResponses([]);
    setPostmarkModalVisible(true);
    setSendQuotePending(true);
    try {
      setPostmarkResponses(
        await sendQuote({
          quoteId,
        })
      );
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Send quote failed",
      });
    }
    setSendQuotePending(false);
  }, []);

  useEffect(() => {
    if (isVisible && !zeroTaxRateId) {
      (async () => {
        setLoading(true);
        await runListTaxRates();
        setLoading(false);
      })();
    }
  }, [isVisible]);
  useEffect(() => {
    if (isVisible) {
      setSessionQuote(quote);
      setQuoteStale(false);
      setSendQuoteOnFinalize(false);
    }
  }, [isVisible]);

  const finalizeQuoteDisabled =
    !sessionQuote?.customer || sessionQuote?.line_items.data.length === 0;

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Layout level="2" style={{ flex: 1 }}>
          <Header
            navigation={{ icon: "close", onPress: () => onClose(quoteStale) }}
            title="Edit quote"
          />
          <Divider />
          {zeroTaxRateId ? (
            <>
              <ScrollView>
                <Separator size="medium" />
                <StyledText category="h6">Customer</StyledText>
                {sessionQuote?.customer ? (
                  <>
                    <Separator size="medium" />
                    <StripeCustomerListItem
                      disabled
                      stripeCustomer={sessionQuote.customer}
                      style={{
                        marginEnd: 16,
                        marginStart: 16,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Separator size="medium" />
                    <StyledButton
                      onPress={() => setCustomerSelectModalVisible(true)}
                      status="control"
                    >
                      Add customer
                    </StyledButton>
                  </>
                )}
                <Separator />
                <StyledText category="h6">Items</StyledText>
                {sessionQuote?.line_items.data.length > 0 && (
                  <>
                    <Separator size="medium" />
                    {sessionQuote.line_items.data.map((lineItem, index) => (
                      <Fragment key={lineItem.price.id}>
                        {index > 0 && <Separator size="small" />}
                        <Layout
                          style={{
                            borderRadius: 8,
                            marginEnd: 16,
                            marginStart: 16,
                          }}
                        >
                          <QuoteLineItemListItem
                            accessoryRight={(imageProps) => (
                              <Icon {...imageProps} name="arrow-ios-forward" />
                            )}
                            line_item={lineItem}
                            onPress={() =>
                              setStripeLineItemEditingModal({
                                isVisible: true,
                                stripeLineItem: lineItem,
                                stripeLineItemIndex: index,
                              })
                            }
                          />
                        </Layout>
                      </Fragment>
                    ))}
                  </>
                )}
                <Separator size="medium" />
                <StyledButton
                  onPress={() =>
                    setStripeLineItemEditingModal({
                      isVisible: true,
                      stripeLineItem: null,
                      stripeLineItemIndex:
                        sessionQuote?.line_items.data.length || 0,
                    })
                  }
                  status="control"
                >
                  Add item
                </StyledButton>
                <Separator />
                <StyledText category="h6">Taxes</StyledText>
                {sessionQuote?.default_tax_rates.length > 0 && (
                  <>
                    <Separator size="medium" />
                    {sessionQuote.default_tax_rates.map((taxRate, index) => (
                      <Fragment key={JSON.stringify(taxRate)}>
                        {index > 0 && <Separator size="small" />}
                        <StyledCard>
                          <Separator size="medium" />
                          <SummaryRow>
                            <SummaryRowNameContainer
                              style={{ alignItems: "flex-start" }}
                            >
                              <StyledText>{taxRate.display_name}</StyledText>
                            </SummaryRowNameContainer>
                            <SummaryRowValueContainer>
                              <StyledText>{`${taxRate.percentage}%`}</StyledText>
                            </SummaryRowValueContainer>
                          </SummaryRow>
                          <Separator size="medium" />
                        </StyledCard>
                      </Fragment>
                    ))}
                  </>
                )}
                <Separator size="medium" />
                <StyledButton
                  onPress={() => setTaxRateSelectModalVisible(true)}
                  status="control"
                >
                  {`${
                    Array.isArray(sessionQuote?.default_tax_rates) &&
                    sessionQuote.default_tax_rates.length > 0
                      ? "Update"
                      : "Add"
                  } tax`}
                </StyledButton>
                {sessionQuote && (
                  <>
                    <Separator />
                    <SummaryRow>
                      <SummaryRowNameContainer>
                        <StyledText category="s1">Subtotal</StyledText>
                      </SummaryRowNameContainer>
                      <SummaryRowValueContainer>
                        <StyledText category="s1">
                          {formatCurrency(sessionQuote.amount_subtotal)}
                        </StyledText>
                      </SummaryRowValueContainer>
                    </SummaryRow>
                    {sessionQuote.total_details.breakdown.taxes.map((tax) => (
                      <SummaryRow key={JSON.stringify(tax)}>
                        <SummaryRowNameContainer>
                          <StyledText>{`${tax.rate.display_name} (${tax.rate.percentage}%)`}</StyledText>
                        </SummaryRowNameContainer>
                        <SummaryRowValueContainer>
                          <StyledText>{formatCurrency(tax.amount)}</StyledText>
                        </SummaryRowValueContainer>
                      </SummaryRow>
                    ))}
                    <SummaryRow>
                      <SummaryRowNameContainer>
                        <StyledText category="s1">Total</StyledText>
                      </SummaryRowNameContainer>
                      <SummaryRowValueContainer>
                        <StyledText category="s1">
                          {formatCurrency(sessionQuote.amount_total)}
                        </StyledText>
                      </SummaryRowValueContainer>
                    </SummaryRow>
                  </>
                )}
                <Separator />
              </ScrollView>
              <Divider />
              <Layout>
                <SafeAreaView>
                  <SubmitButtonContainer>
                    <Separator size="small" />
                    <CheckBox
                      checked={sendQuoteOnFinalize}
                      disabled={finalizeQuoteDisabled || finalizeQuotePending}
                      onChange={(checked) => setSendQuoteOnFinalize(checked)}
                    >
                      Email quote PDF to customer
                    </CheckBox>
                    <Separator />
                    <Button
                      disabled={finalizeQuoteDisabled}
                      loading={autoSavePending || finalizeQuotePending}
                      onPress={async () => {
                        setFinalizeQuotePending(true);
                        try {
                          setSessionQuote(await finalizeQuote(sessionQuote.id));
                          onClose(true);
                          if (sendQuoteOnFinalize) {
                            runSendQuote(sessionQuote.id);
                          }
                        } catch (error) {
                          addNotification({
                            message: error?.message || error,
                            status: "danger",
                            title: "Finalize quote failed",
                          });
                        }
                        setFinalizeQuotePending(false);
                      }}
                    >
                      {autoSavePending ? "Saving draft..." : "Finalize quote"}
                    </Button>
                  </SubmitButtonContainer>
                </SafeAreaView>
              </Layout>
            </>
          ) : (
            <EmptyScreenContainer>
              <Separator />
              {loading ? (
                <Spinner />
              ) : (
                <Text appearance="hint" category="s1">
                  No results found
                </Text>
              )}
            </EmptyScreenContainer>
          )}
        </Layout>
      </FullScreenModal>
      <StripeCustomerSelectModal
        headerTitle="Add customer"
        isVisible={customerSelectModalVisible}
        onClose={() => setCustomerSelectModalVisible(false)}
        onSubmit={async (selectedCustomer) => {
          const customer = selectedCustomer.stripeCustomerId;
          if (sessionQuote) {
            await runUpdateQuote({
              onSuccess: () => setCustomerSelectModalVisible(false),
              updateQuoteInput: {
                customer,
                id: sessionQuote.id,
              },
            });
          } else {
            await runCreateQuote({
              createQuoteInput: {
                customer,
              },
              onSuccess: () => setCustomerSelectModalVisible(false),
            });
          }
        }}
        selected={sessionQuote?.customer?.id}
      />
      <StripeLineItemEditingModal
        isVisible={stripeLineItemEditingModal.isVisible}
        lineItem={stripeLineItemEditingModal.stripeLineItem}
        onClose={() =>
          setStripeLineItemEditingModal((prevState) => ({
            ...prevState,
            isVisible: false,
          }))
        }
        onDelete={async () => {
          const updatedLineItems = [...sessionQuote.line_items.data];
          updatedLineItems.splice(
            stripeLineItemEditingModal.stripeLineItemIndex,
            1
          );
          await runUpdateQuote({
            onSuccess: () =>
              setStripeLineItemEditingModal((prevState) => ({
                ...prevState,
                isVisible: false,
              })),
            updateQuoteInput: {
              id: sessionQuote.id,
              line_items: updatedLineItems.map((lineItem) =>
                mapLineItemToLineItemInput({
                  lineItem,
                  zeroTaxRateId,
                })
              ),
            },
          });
        }}
        onSubmit={async (partialLineItem) => {
          if (sessionQuote) {
            await runUpdateQuote({
              onSuccess: () =>
                setStripeLineItemEditingModal((prevState) => ({
                  ...prevState,
                  isVisible: false,
                })),
              updateQuoteInput: {
                id: sessionQuote.id,
                line_items: Object.assign([], sessionQuote.line_items.data, {
                  [stripeLineItemEditingModal.stripeLineItemIndex]: partialLineItem,
                }).map((lineItem) =>
                  mapLineItemToLineItemInput({ lineItem, zeroTaxRateId })
                ),
              },
            });
          } else {
            await runCreateQuote({
              onSuccess: () =>
                setStripeLineItemEditingModal((prevState) => ({
                  ...prevState,
                  isVisible: false,
                })),
              createQuoteInput: {
                line_items: [
                  mapLineItemToLineItemInput({
                    lineItem: partialLineItem,
                    zeroTaxRateId,
                  }),
                ],
              },
            });
          }
        }}
      />
      <TaxRateSelectModal
        headerTitle={`${
          sessionQuote?.default_tax_rates.length > 0 ? "Update" : "Add"
        } tax`}
        isVisible={taxRateSelectModalVisible}
        onClose={() => setTaxRateSelectModalVisible(false)}
        onSubmit={async (taxRates) => {
          const defaultTaxRates = taxRates.map((taxRate) => taxRate.id);
          if (sessionQuote) {
            await runUpdateQuote({
              onSuccess: () => setTaxRateSelectModalVisible(false),
              updateQuoteInput: {
                default_tax_rates: defaultTaxRates,
                id: sessionQuote.id,
              },
            });
          } else {
            await runCreateQuote({
              createQuoteInput: {
                default_tax_rates: defaultTaxRates,
              },
              onSuccess: () => setTaxRateSelectModalVisible(false),
            });
          }
        }}
        selected={sessionQuote?.default_tax_rates}
      />
      <SendJobSummariesModal
        isVisible={postmarkModalVisible}
        loading={sendQuotePending}
        onClose={() => setPostmarkModalVisible(false)}
        postmarkMessageSendingResponses={postmarkResponses}
      />
    </>
  );
};

export default QuoteEditingModal;
