import gql from "graphql-tag";

import {
  dailyActivityReportFragment,
  incidentReportFragment,
  jobFragment,
} from "../../../fragments";

const queryJobAccessories = gql`
  query queryJobAccessories($jobId: ID!, $after: ID) {
    queryJobAccessories(jobId: $jobId, after: $after) {
      items {
        ... on DailyActivityReport {
          ...dailyActivityReportFragment
        }
        ... on IncidentReport {
          ...incidentReportFragment
        }
        ... on Job {
          ...jobFragment
        }
      }
      nextToken
    }
  }
  ${dailyActivityReportFragment}
  ${incidentReportFragment}
  ${jobFragment}
`;

export default queryJobAccessories;
