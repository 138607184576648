import React, { useState } from "react";

import NewPasswordScreen from "./NewPasswordScreen";
import UsernameScreen from "./UsernameScreen";
import FullScreenModal from "../FullScreen";
import useModalScreens from "../useModalScreens";

const screensConfig = [["username"], ["newPassword"]];

interface ResetPasswordModalProps {
  isVisible: boolean;
  onClosePress: () => void;
  onSuccess: () => void;
}

const ResetPasswordModal = ({
  isVisible,
  onClosePress,
  onSuccess,
}: ResetPasswordModalProps) => {
  const { goBack, jumpTo, navigation } = useModalScreens(screensConfig);

  const [user, setUser] = useState<string>("");

  const screen = navigation.screens[navigation.state.index];

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClosePress}>
      {screen.name === "username" && (
        <UsernameScreen
          onClosePress={onClosePress}
          onCodeDelivery={(username) => {
            setUser(username);
            jumpTo("newPassword");
          }}
          visible
        />
      )}
      {screen.name === "newPassword" && (
        <NewPasswordScreen
          onBackPress={goBack}
          onSuccess={onSuccess}
          username={user}
          visible
        />
      )}
    </FullScreenModal>
  );
};

export default ResetPasswordModal;
