import { RRule } from "rrule";
import moment from "moment-timezone";

import repeatByWeekday from "../../constants/repeatByWeekday";

const getFreq = (frequency) => {
  switch (frequency) {
    case "weekly":
      return RRule.WEEKLY;
    case "daily":
      return RRule.DAILY;
    default:
      throw new Error(`Frequency ${frequency} is unsupported`);
  }
};

const momentDaysToUtcDays = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];

const getLocalByWeekdayRRuleDescription = ({
  startDateTime,
  frequency,
  byWeekday,
}) => {
  if (!frequency) return "Never";

  const input = {};

  input.freq = getFreq(frequency);
  const startMoment = moment.utc(startDateTime);
  input.dtstart = new Date(
    Date.UTC(
      startMoment.year(),
      startMoment.month(),
      startMoment.date(),
      startMoment.hours(),
      startMoment.minutes()
    )
  );
  if (Array.isArray(byWeekday) && byWeekday.length > 0) {
    input.byweekday = byWeekday.map((weekday) => {
      const day = repeatByWeekday[weekday].momentValue;
      return momentDaysToUtcDays[day];
    });
  }

  return new RRule(input).toText();
};

export default getLocalByWeekdayRRuleDescription;
