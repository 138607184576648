import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";

interface AppVersion {
  version?: string;
}

interface LiveConnectionStatus {
  liveConnectionStatus: {
    current?: string;
    previous?: string;
  };
}

export interface State extends LiveConnectionStatus, AppVersion {
  chatReady?: boolean;
}

const initialState: State = {
  version: null,
  liveConnectionStatus: {
    current: null,
    previous: null,
  },
  chatReady: false,
};

const slice = createSlice({
  name: "appInfo",
  initialState,
  reducers: {
    setAppVersion: (state: State, action: PayloadAction<AppVersion>) => {
      const { version } = action.payload;
      state.version = version;
    },
    setLiveConnectionStatus: (
      state: State,
      action: PayloadAction<LiveConnectionStatus>
    ) => {
      const { liveConnectionStatus } = action.payload;
      state.liveConnectionStatus = liveConnectionStatus;
    },
    setChatReady: (state, action: PayloadAction<{ chatReady: boolean }>) => {
      const { chatReady } = action.payload;
      state.chatReady = chatReady;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(resetUser.fulfilled, (state) => {
      state.chatReady = false;
    });
  },
});

const { reducer, actions } = slice;

export const { setAppVersion, setLiveConnectionStatus, setChatReady } = actions;

export default reducer;
