import lodash from "lodash/array";
import { useState } from "react";

const useModalScreens = (screensConfig: Array<Array<string>>) => {
  const [navigation, setNavigation] = useState(() => ({
    state: {
      index: 0,
      routes: screensConfig.map((_, index) => ({
        key: index.toString(),
      })),
    },
    screens: screensConfig.map((screens) => ({
      name: screens[0],
      params: null,
    })),
  }));

  const jumpTo = (name: string, params?: object) => {
    setNavigation((prevState) => {
      const index = lodash.findIndex(screensConfig, (o) => o.includes(name));
      const screens = [...prevState.screens];
      screens[index] = {
        name,
        params,
      };
      return {
        state: {
          ...prevState.state,
          index,
        },
        screens,
      };
    });
  };

  const goBack = () => {
    setNavigation((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        index: prevState.state.index - 1,
      },
    }));
  };

  return { navigation, jumpTo, goBack };
};

export default useModalScreens;
