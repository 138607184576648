import { Button, Icon, useTheme } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet } from "react-native";

import UserList from "../UserList";
import UserListItem from "../listItems/User";

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});

const UsersSelect = ({
  actionItem,
  availableUserIds,
  navigation,
  onChange,
  selectedUserIds,
  title,
}) => {
  const theme = useTheme();
  return (
    <UserList
      accessoryRight={() =>
        actionItem && (
          <Button
            data-test="usersListActionButton"
            appearance="ghost"
            disabled={actionItem.disabled}
            onPress={actionItem.onPress}
            style={styles.button}
          >
            {actionItem.text}
          </Button>
        )
      }
      navigation={navigation}
      renderItem={({ item: userId }) => {
        return (
          <UserListItem
            userId={userId}
            accessoryRight={(props) =>
              selectedUserIds.includes(userId) && (
                <Icon
                  {...props}
                  name="checkmark-outline"
                  fill={theme["color-primary-default"]}
                />
              )
            }
            onPress={() => {
              if (selectedUserIds.includes(userId)) {
                onChange(selectedUserIds.filter((id) => id !== userId));
              } else {
                onChange([
                  ...selectedUserIds.filter((id) => id !== userId),
                  userId,
                ]);
              }
            }}
          />
        );
      }}
      title={title}
      userIds={availableUserIds}
    />
  );
};

UsersSelect.propTypes = {
  actionItem: PropTypes.shape({
    disabled: PropTypes.bool,
    onPress: PropTypes.func,
    text: PropTypes.string,
  }),
  availableUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  navigation: PropTypes.shape({
    icon: PropTypes.string,
    onPress: PropTypes.func,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

UsersSelect.defaultProps = {
  actionItem: undefined,
  title: undefined,
};

export default UsersSelect;
