import React from "react";
import { useSelector } from "react-redux";

import DailyActivityReportEditingModal from "./DailyActivity";
import IncidentReportEditingModal from "./Incident";
import selectReportByIdAndJobId from "../../../store/jobs/selectors/selectReportByIdAndJobId";

interface Props {
  isVisible: boolean;
  jobId?: string;
  onClosePress: () => void;
  onDelete: () => void;
  onSubmit: () => void;
  reportId?: string;
}

const ReportEditingModal = ({
  isVisible,
  jobId,
  onClosePress,
  onDelete,
  onSubmit,
  reportId,
}: Props) => {
  const report = useSelector((state) =>
    selectReportByIdAndJobId(state, { jobId, id: reportId })
  );
  switch (report && report.__typename) {
    case "DailyActivityReport":
      return (
        <DailyActivityReportEditingModal
          // @ts-ignore
          dailyActivityReport={report}
          isVisible={isVisible}
          onClosePress={onClosePress}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
      );
    case "IncidentReport":
      return (
        <IncidentReportEditingModal
          // @ts-ignore
          incidentReport={report}
          isVisible={isVisible}
          onClosePress={onClosePress}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
      );
    default:
      return null;
  }
};

ReportEditingModal.defaultProps = {
  jobId: undefined,
  reportId: undefined,
};

export default ReportEditingModal;
