import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Card, Button, Text } from "@ui-kitten/components";

import SimpleModal from "../Simple";
import { Separator } from "../../buildingBlocks";
import { TutorialTypes } from "../../../types";
import TutorialModal from "../Tutorial";
import tutorials from "../../../tutorials";

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
  },
});

interface TutorialsProps {
  visible: boolean;
  onClose: () => void;
}

const TutorialsModal = ({ visible, onClose }: TutorialsProps) => {
  const [tutorial, setTutorial] = useState<{
    type: TutorialTypes;
    show: boolean;
  }>({
    type: "quickstartEmployeeMobile",
    show: false,
  });

  return (
    <>
      <SimpleModal
        isVisible={visible}
        onBackdropPress={onClose}
        onBackPress={onClose}
      >
        <Card
          disabled
          header={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <View {...props}>
              <View style={[styles.header]}>
                <View>
                  <Text category="h6">Tutorials</Text>
                  <Text category="s1">
                    Which tutorial would you like to watch?
                  </Text>
                </View>
                <Button appearance="ghost" size="small" onPress={onClose}>
                  Close
                </Button>
              </View>
            </View>
          )}
        >
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "quickstartAdminAndSupervisorMobile",
                show: true,
              })
            }
          >
            Quickstart for admins and supervisors
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({ type: "quickstartEmployeeMobile", show: true })
            }
          >
            Quickstart for employees
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "clockingInWeb",
                show: true,
              })
            }
          >
            Learn about clocking in
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "groupsWeb",
                show: true,
              })
            }
          >
            Learn about groups
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "usersWeb",
                show: true,
              })
            }
          >
            Learn about users
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "jobSitesWeb",
                show: true,
              })
            }
          >
            Learn about locations
          </Button>
          <Separator size="small" />
          <Button
            appearance="ghost"
            onPress={() =>
              setTutorial({
                type: "listFeatureWeb",
                show: true,
              })
            }
          >
            Learn about the job list on the map
          </Button>
        </Card>
      </SimpleModal>
      <TutorialModal
        visible={tutorial.show}
        onClose={() => setTutorial((prev) => ({ ...prev, show: false }))}
        tutorial={tutorials[tutorial.type]}
      />
    </>
  );
};

export default TutorialsModal;
