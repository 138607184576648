import {
  Text,
  TextProps,
  Tooltip as UiKittenTooltip,
  TooltipProps,
} from "@ui-kitten/components";
import React, { ReactElement, ReactText, useState } from "react";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

export interface Props
  extends Pick<
    TooltipProps,
    "accessoryRight" | "onBackdropPress" | "placement" | "visible"
  > {
  anchorStyle?: StyleProp<ViewStyle>;
  children: ReactElement;
  title: ReactElement | ReactText;
}

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: "center",
  },
  tooltip: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});

const Tooltip = ({
  accessoryRight,
  anchorStyle,
  children,
  onBackdropPress,
  placement = "bottom",
  title,
  visible: controlledVisible,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const renderTitle = (textProps: TextProps) => {
    if (typeof title === "number" || typeof title === "string") {
      return (
        <View style={styles.titleContainer}>
          <Text style={textProps.style}>{title}</Text>
        </View>
      );
    }
    return title;
  };
  return (
    <UiKittenTooltip
      accessoryRight={accessoryRight}
      anchor={() => (
        <View style={anchorStyle}>
          {children}
          {typeof controlledVisible !== "boolean" && (
            <Pressable
              onPress={() => setVisible(true)}
              onStartShouldSetResponderCapture={() => true}
              style={StyleSheet.absoluteFill}
            />
          )}
        </View>
      )}
      onBackdropPress={() => {
        setVisible(false);
        if (onBackdropPress) {
          onBackdropPress();
        }
      }}
      placement={placement}
      style={styles.tooltip}
      visible={
        typeof controlledVisible === "boolean" ? controlledVisible : visible
      }
    >
      {renderTitle}
    </UiKittenTooltip>
  );
};

Tooltip.defaultProps = {
  anchorStyle: undefined,
};

export default Tooltip;
