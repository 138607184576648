import React from "react";
import { TextProps, Text } from "@ui-kitten/components";
import useTimerText from "../../hooks/useTimerText";

interface TimerTextProps extends TextProps {
  dateTime: string;
}

const TimerText = ({ dateTime, ...props }: TimerTextProps) => {
  const timerText = useTimerText(dateTime);
  return <Text {...props}>{timerText}</Text>;
};

export default React.memo(TimerText);
