import gql from "graphql-tag";

const sendInvoice = gql`
  mutation sendInvoice($invoiceId: ID!) {
    sendInvoice(invoiceId: $invoiceId) {
      to
      submittedAt
      messageId
      errorCode
      message
    }
  }
`;

export default sendInvoice;
