import gql from "graphql-tag";

import { timecardFragment } from "../../../fragments";

const adminEditTimecard = gql`
  mutation adminUpdateTimecard(
    $id: ID!
    $started: UserLocationRecordInput!
    $quit: UserLocationRecordInput!
  ) {
    adminUpdateTimecard(id: $id, started: $started, quit: $quit) {
      ...timecardFragment
    }
  }
  ${timecardFragment}
`;

export default adminEditTimecard;
