import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";
import AWSAppSyncClient from "aws-appsync";
import { Platform } from "react-native";

import config from "../../config/appsync";

// @ts-ignore
const client = new AWSAppSyncClient(config);

if (Platform.OS === "web") {
  const online = window.navigator.onLine;
  // @ts-ignore
  if (online && !client.cache.store.getState().offline.online) {
    // @ts-ignore
    client.cache.store.dispatch({
      type: OFFLINE_STATUS_CHANGED,
      payload: { online },
    });
  }
}

export default client;
