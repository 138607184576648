import * as geolib from "geolib";

import { getLocation } from "../../location/mapLocation";
import { Coordinates } from "../../types";

interface Params {
  coordinates: Coordinates;
  geofence: number;
}

const getUserDistanceInfo = async ({ coordinates, geofence }: Params) => {
  const location = await getLocation();
  if (!location) {
    throw new Error("Your location could not be determined.");
  }
  const distance = geolib.getDistance(location, coordinates, 0.1);
  const maximumAllowedDistanceFromGeofence =
    geofence + (location.accuracy || 0);
  const distanceFromGeofence = distance - maximumAllowedDistanceFromGeofence;
  return {
    distance,
    distanceFromGeofence: distanceFromGeofence > 0 ? distanceFromGeofence : 0,
    isWithinGeofence: distance <= maximumAllowedDistanceFromGeofence,
    userCoordinates: location,
  };
};

export default getUserDistanceInfo;
