import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeInvoice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

export type ListInvoicesInput = Pick<
  Stripe.InvoiceListParams,
  "customer" | "due_date" | "starting_after" | "status"
>;

const listInvoices = async (input?: ListInvoicesInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: input && {
        customer: input.customer,
        due_date: input.due_date,
        starting_after: input.starting_after,
        status: input.status,
      },
    })) as GraphQLResult<{
      listInvoices: {
        data: Array<StripeInvoice>;
        has_more: boolean;
      };
    }>;
    return data.listInvoices;
  } catch ({ errors }) {
    throw generateErrorMessage("listInvoices", errors);
  }
};

export default listInvoices;
