import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

interface Data {
  getUpcomingInvoice: {
    response: string;
  };
}

const getUpcomingInvoice = async () => {
  try {
    const {
      data: {
        getUpcomingInvoice: { response },
      },
    } = await client.query<Data>({
      fetchPolicy: "no-cache",
      query,
    });
    return JSON.parse(response);
  } catch (errors) {
    throw generateErrorMessage("getUpcomingInvoice", errors);
  }
};

export default getUpcomingInvoice;
