import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { MediaUrl } from "../../../types";

interface GetTutorialMediaResponse {
  getTutorialMedia: MediaUrl;
}

const getTutorialMedia = async (id: string) => {
  try {
    const { data } = await client.query<GetTutorialMediaResponse>({
      query,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    return data.getTutorialMedia;
  } catch (error) {
    throw generateErrorMessage("getTutorialMedia", error);
  }
};

export default getTutorialMedia;
