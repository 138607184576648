import Auth from "@aws-amplify/auth";
import axios from "axios";

import config from "../../../config";

const init = (Pusher) => {
  const authorizer = (channel) => ({
    authorize: async (socketId, callback) => {
      try {
        const accessToken = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        const response = await axios.post(
          config.pusher.url,
          {
            accessToken,
            socketId,
            channelName: channel.name,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        callback(null, { auth: response.data.auth });
      } catch (error) {
        callback(error);
      }
    },
  });
  return new Pusher(config.pusher.appKey, {
    cluster: config.pusher.appCluster,
    authorizer,
  });
};

export default init;
