import API, { GraphQLResult } from "@aws-amplify/api";

import { StripeAccount } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

const retrieveStripeAccount = async () => {
  try {
    const { data } = (await API.graphql({
      query,
    })) as GraphQLResult<{ retrieveStripeAccount: StripeAccount }>;
    return data.retrieveStripeAccount;
  } catch ({ errors }) {
    throw generateErrorMessage("retrieveStripeAccount", errors);
  }
};

export default retrieveStripeAccount;
