import dayjs, { Dayjs } from "dayjs";
import React, { ReactElement, useMemo } from "react";
import { StyleSheet, View } from "react-native";

import getWeekBounds from "../../../utils/getWeekBounds";
import DefaultDay, { Props as DefaultDayProps } from "../DefaultDay";

export interface Props {
  dateTime: string;
  onDatePress?: (dateTime: string) => void;
  renderDay?: (props: DefaultDayProps) => ReactElement;
}

export const markedDateFormat = "YYYY-MM-DD";

const getWeek = (weekStartDateTime: string) => {
  const day = dayjs(weekStartDateTime);
  const week: Array<Dayjs> = [];
  for (let i = 0; i < 7; i += 1) {
    week.push(day.add(i, "day"));
  }
  return week;
};

const styles = StyleSheet.create({
  dateContainer: {
    flex: 1,
  },
  root: {
    flexDirection: "row",
  },
});

const Week = ({ dateTime, onDatePress, renderDay }: Props) => {
  const weekBounds = getWeekBounds(dateTime);
  const week = useMemo(() => getWeek(weekBounds.startDateTime), [
    weekBounds.startDateTime,
  ]);

  return (
    <View style={styles.root}>
      {week.map((day) => {
        return (
          <View key={day.toISOString()} style={styles.dateContainer}>
            {renderDay ? (
              renderDay({
                dateTime: day.toISOString(),
                onPress: onDatePress,
                selected: day.isSame(dateTime, "day"),
              })
            ) : (
              <DefaultDay
                dateTime={day.toISOString()}
                onPress={onDatePress}
                selected={day.isSame(dateTime, "day")}
              />
            )}
          </View>
        );
      })}
    </View>
  );
};

Week.defaultProps = {
  onDatePress: () => null,
  renderDay: undefined,
};

export default Week;
