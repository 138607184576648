import { Layout } from "@ui-kitten/components";
import React, {
  PropsWithChildren,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Animated,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";

import Portal from "../../Portal";
import SafeAreaView from "../../SafeAreaView";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";

export type Props = PropsWithChildren<{
  isVisible: boolean;
  onBackdropPress: () => void;
  onBackPress: () => void;
  style?: StyleProp<ViewStyle>;
}>;

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
  },
  modal: {
    borderRadius: 8,
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 600,
    overflow: "hidden",
    width: "100%",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    paddingEnd: 16,
    paddingStart: 16,
  },
  safeAreaView: {
    flex: 1,
  },
});

const SimpleModal = ({
  children,
  isVisible,
  onBackdropPress,
  onBackPress,
  style,
}: Props) => {
  const [componentRendered, setComponentRendered] = useState(isVisible);
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const componentMounted = useRef(false);
  useAndroidBackHandler({ enabled: isVisible, onBackPress });
  useEffect(() => {
    if (isVisible && !componentRendered) {
      setComponentRendered(true);
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else if (!isVisible && componentRendered) {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {
        if (componentMounted.current) {
          setComponentRendered(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  if (!componentRendered) {
    return null;
  }
  return (
    <Portal>
      <TouchableWithoutFeedback onPress={onBackdropPress}>
        <Animated.View style={[styles.backdrop, { opacity: fadeAnimation }]}>
          <SafeAreaView style={styles.safeAreaView}>
            <View style={styles.modalContainer}>
              <Layout
                onStartShouldSetResponder={() => true}
                style={[styles.modal, style]}
              >
                {children}
              </Layout>
            </View>
          </SafeAreaView>
        </Animated.View>
      </TouchableWithoutFeedback>
    </Portal>
  );
};

SimpleModal.defaultProps = {
  style: undefined,
};

export default memo(SimpleModal);
