import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

const logout = gql`
  mutation logout($expoToken: ID) {
    logout(expoToken: $expoToken) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;

export default logout;
