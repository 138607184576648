import gql from "graphql-tag";

export default gql`
  query geocode($address: String!) {
    geocode(address: $address) {
      address
      coordinates {
        lat
        lng
      }
    }
  }
`;
