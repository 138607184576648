import { Divider } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  middle: {
    marginHorizontal: 16,
  },
});

const EnhancedDivider = ({ type }) => <Divider style={styles[type]} />;

EnhancedDivider.defaultProps = {
  type: "full",
};

EnhancedDivider.propTypes = {
  type: PropTypes.oneOf(["full", "middle"]),
};

export default EnhancedDivider;
