import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import moment from "moment-timezone";
import { Platform } from "react-native";
import uuidv4 from "uuid/v4";

import { MediaType } from "../../types";

const getMediaMetadata = (
  imageInfo: ImageInfo
): { createdAt: string; id: string; mimeType: string; type: MediaType } => {
  const exifDateTime =
    imageInfo.exif &&
    (imageInfo.exif.DateTime ||
      imageInfo.exif.DateTimeOriginal ||
      imageInfo.exif.DateTimeDigitized);
  const createdAt = exifDateTime
    ? moment(exifDateTime, "YYYY:MM:DD hh:mm:ss").toISOString()
    : new Date().toISOString();
  if (Platform.OS === "web") {
    const mimeType = imageInfo.uri.substring(
      imageInfo.uri.indexOf(":") + 1,
      imageInfo.uri.indexOf(";")
    );
    const [type, subtype] = mimeType.split("/");
    return {
      createdAt,
      id: `${uuidv4()}.${subtype}`,
      mimeType,
      type: type as MediaType,
    };
  }
  const mediaId = imageInfo.uri.split("/").pop();
  const mimeType = `${imageInfo.type}/${mediaId.substring(
    mediaId.indexOf(".") + 1
  )}`;
  return {
    createdAt,
    id: mediaId,
    mimeType,
    type: imageInfo.type,
  };
};

export default getMediaMetadata;
