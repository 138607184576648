import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setGroupPending } from "../../../store/groups/slice";

const dispatchOptimisticResponse = ({ name, id, members }) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const timestamp = new Date().toISOString();
  dispatch(
    setGroupPending({
      group: {
        id,
        members,
        name,
        syncDateTime: timestamp,
        created: {
          at: timestamp,
          by: userId,
        },
        updated: {
          at: timestamp,
          by: userId,
        },
        isDeleted: false,
      },
      mutation: "adminCreateCompanyGroup",
    })
  );
};

const adminCreateCompanyGroup = ({ name, id, members }) => {
  dispatchOptimisticResponse({ name, id, members });
  client.mutate({
    mutation,
    variables: {
      name,
      id,
      members,
    },
    errorPolicy: "all",
  });
};

export default adminCreateCompanyGroup;
