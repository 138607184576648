import React from "react";

import JobStatusCard, { JobStatusCardProps } from "../JobStatusCard";
import jobStatuses from "../../../constants/jobStatuses";

type LateStatusJobCardProps = Partial<
  Pick<JobStatusCardProps, "callback" | "dense">
>;

const LateStatusJobCard = ({
  callback = () => {},
  dense,
}: LateStatusJobCardProps) => (
  <JobStatusCard
    callback={callback}
    colors={jobStatuses.late.colors}
    dense={dense}
    jobStatusId={jobStatuses.late.id}
    label="Late"
  />
);

export default LateStatusJobCard;
