import { Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import selectUserById from "../../../store/users/selectors/selectUserById";
import getUserFullName from "../../../utils/getUserFullName";
import Separator from "../../buildingBlocks/Separator";
import Flair from "../../Flair";
import UserStatusDot from "../../UserStatusDot";
import ListItem, { ListItemProps } from "../Base";

interface UserListItemProps
  extends Pick<
    ListItemProps,
    | "accessoryLeft"
    | "accessoryRight"
    | "disabled"
    | "onPress"
    | "style"
    | "titleProps"
  > {
  userId: string;
  description?: string;
  showClockedInIndicator?: boolean;
  showRole?: boolean;
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const UserListItem = ({
  accessoryLeft,
  accessoryRight,
  disabled,
  onPress,
  userId,
  description = null,
  showClockedInIndicator = false,
  showRole,
  style,
  titleProps,
}: UserListItemProps) => {
  const isTalariumBot = userId === "talarium";
  const user = useSelector((state) => selectUserById(state, userId));
  return (
    <ListItem
      data-test={`${userId}-userListItem`}
      // accessoryLeft={
      //   showClockedInIndicator
      //     ? () => <UserStatusDot online={Boolean(user.clockedIn)} />
      //     : accessoryLeft
      // }
      accessoryRight={!isTalariumBot && accessoryRight}
      description={description !== null ? description : showRole && user.role}
      disabled={disabled}
      onPress={onPress}
      style={style}
      title={() => (
        <View style={styles.titleContainer}>
          <Text category="s2" {...titleProps}>
            {isTalariumBot ? "Talarium Bot" : getUserFullName(user)}
          </Text>
          <Separator horizontal size="small" />
          {!isTalariumBot && user.isArchived && (
            <Flair status="danger">archived</Flair>
          )}
        </View>
      )}
    />
  );
};

export default UserListItem;
