import { Company } from "../../../../types";

const selectCompany = (state): Company => {
  const { entity, optimisticEntity } = state.data.company;
  if (optimisticEntity) {
    return optimisticEntity;
  }
  return entity;
};

export default selectCompany;
