const sortUsers = (a, b) => {
  const lastNameComparison = a.lastName.localeCompare(b.lastName);
  const firstNameComparison = a.firstName.localeCompare(b.firstName);
  const createdAtComparison = a.created.at.localeCompare(b.created.at);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  if (firstNameComparison !== 0) {
    return firstNameComparison;
  }
  return createdAtComparison;
};

export default sortUsers;
