import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const queryLocationHistory = async ({ userId, to, from }) => {
  const results = [];
  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { data, errors } = await client.query({
      fetchPolicy: "no-cache",
      query,
      variables: {
        userId,
        to,
        from,
        after: nextToken,
      },
    });
    if (errors) {
      throw generateErrorMessage("queryLocationHistory", errors);
    } else {
      const { adminQueryLocationHistory: result } = data;
      results.push(...result.items);
      nextToken = result.nextToken;
    }
  } while (nextToken);

  return results;
};

export default queryLocationHistory;
