import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { Coordinates } from "../../../types";

interface Variables {
  address: string;
}

interface GeocodeResponse {
  address: string;
  coordinates: Coordinates;
}

interface Data {
  geocode: GeocodeResponse;
}

const geocode = async (address: string) => {
  try {
    const { data } = await client.query<Data, Variables>({
      query,
      variables: {
        address,
      },
    });
    return data.geocode;
  } catch (error) {
    throw generateErrorMessage("geocode", error);
  }
};

export default geocode;
