import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = (incidentReport) => {
  const { dispatch } = store;
  dispatch(
    setReportPending({
      deleted: true,
      mutation: "deleteIncidentReport",
      report: {
        ...incidentReport,
        isDeleted: true,
      },
    })
  );
};

const deleteIncidentReport = (incidentReport) => {
  dispatchOptimisticResponse(incidentReport);
  client.mutate({
    mutation,
    variables: {
      id: incidentReport.id,
      jobId: incidentReport.jobId,
    },
    errorPolicy: "all",
  });
};

export default deleteIncidentReport;
