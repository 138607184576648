import { ButtonProps, Text, TextProps, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";
import Separator from "../buildingBlocks/Separator";

type Props = Pick<ButtonProps, "status"> & Pick<TextProps, "children">;

const styles = StyleSheet.create({
  root: {
    borderRadius: 4,
    overflow: "hidden",
    paddingBottom: 2,
    paddingEnd: 12,
    paddingStart: 12,
    paddingTop: 2,
    textAlign: "center",
  },
});

const CompactLabel = ({ children, status }: Props) => {
  const theme = useTheme();
  return (
    <View style={{ alignItems: "center", flexDirection: "row" }}>
      <View
        style={{
          backgroundColor: theme[`color-${status}-default`],
          borderRadius: 12,
          height: 12,
          width: 12,
        }}
      />
      <Separator horizontal size="small" />
      <Text category="s1">{children}</Text>
    </View>
  );
};

CompactLabel.defaultProps = {
  status: "primary",
};

export default CompactLabel;
