import React, { useState } from "react";
import { Input, InputProps, Icon } from "@ui-kitten/components";
import { Pressable } from "react-native";

const PasswordInput = (props: InputProps) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const renderIcon = (iconProps) => (
    <Pressable onPress={toggleSecureEntry}>
      <Icon {...iconProps} name={secureTextEntry ? "eye-off" : "eye"} />
    </Pressable>
  );

  return (
    <Input
      {...props}
      autoCapitalize="none"
      label="Password"
      accessoryRight={renderIcon}
      secureTextEntry={secureTextEntry}
      autoCompleteType="password"
      textContentType="password"
      autoCorrect={false}
    />
  );
};

export default PasswordInput;
