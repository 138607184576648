import gql from "graphql-tag";

const sendQuote = gql`
  mutation sendQuote($quoteId: ID!) {
    sendQuote(quoteId: $quoteId) {
      to
      submittedAt
      messageId
      errorCode
      message
    }
  }
`;

export default sendQuote;
