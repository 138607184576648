import {
  createAction,
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { initSession, resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import companyUserRoles from "../../../constants/companyUserRoles";
import mapFilterConstants from "../../../constants/mapFilter";
import {
  CompanyUser,
  EntityState,
  OptimisticCompanyUser,
} from "../../../types";

const adapter = createEntityAdapter<CompanyUser>();
const optimisticAdapter = createEntityAdapter<OptimisticCompanyUser>();

export interface State extends EntityState<CompanyUser> {
  filter: {
    visibility: "all" | "colleagues";
  };
  optimistic: EntityState<OptimisticCompanyUser>;
}

const initialState = adapter.getInitialState({
  filter: {
    visibility: mapFilterConstants.users.colleagues.value,
  },
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addUser = ({
  state,
  user,
}: {
  state: Draft<State>;
  user: CompanyUser;
}) => {
  const existingUser = state.entities[user.id];
  if (!existingUser || user.syncDateTime > existingUser.syncDateTime) {
    adapter.upsertOne(state, user);
  }
};

const name = "users";

type SetUserPayload = {
  mutation: string;
  user: CompanyUser;
};

export const setUserPending = createAction<SetUserPayload>(
  `${name}/setUser/pending`
);
export const setUserFulfilled = createAction<SetUserPayload>(
  `${name}/setUser/fulfilled`
);
export const setUserRejected = createAction(
  `${name}/setUser/rejected`,
  (payload: { id: string; mutation: string }, error: Error) => ({
    error,
    payload,
  })
);

const usersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setVisibilityFilter: (state, action) => {
      const { visibility } = action.payload;
      state.filter.visibility = visibility;
    },
    setUser: (state, action: PayloadAction<{ user: CompanyUser }>) => {
      const { user } = action.payload;
      addUser({ state, user });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserPending, (state, action) => {
      const { mutation, user } = action.payload;
      const existingOptimisticUser = state.optimistic.entities[user.id];
      if (
        existingOptimisticUser === undefined ||
        user.syncDateTime > existingOptimisticUser.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...user,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setUserFulfilled, (state, action) => {
      const { mutation, user } = action.payload;
      addUser({ state, user });
      const existingOptimisticUser = state.optimistic.entities[user.id];
      if (
        existingOptimisticUser !== undefined &&
        mutation === existingOptimisticUser.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, user.id);
      }
    });
    builder.addCase(setUserRejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticUser = state.optimistic.entities[id];
      if (
        existingOptimisticUser !== undefined &&
        mutation === existingOptimisticUser.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { lastSync, users } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, users);
      } else {
        adapter.upsertMany(state, users);
      }
    });
    builder.addCase(initSession.fulfilled, (state, action) => {
      const { user } = action.payload;
      switch (user.group) {
        case companyUserRoles.ADMIN:
          state.filter.visibility = mapFilterConstants.users.all.value;
          break;
        case companyUserRoles.SUPERVISOR:
          state.filter.visibility = mapFilterConstants.users.all.value;
          break;
        case companyUserRoles.EMPLOYEE:
          state.filter.visibility = mapFilterConstants.users.colleagues.value;
          break;
        default:
          state.filter.visibility = mapFilterConstants.users.colleagues.value;
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = usersSlice;

export const { setUser, setVisibilityFilter } = actions;

export default reducer;
