const momentFormats = {
  dateTimeFormat: "HH:mm ddd, MMM D, YYYY",
  timeFormat: "HH:mm",
  ampmDateTimeFormat: "h:mm A ddd, MMM D, YYYY",
  ampmTimeFormat: "h:mm A",
  dateFormat: "ddd, MMM D, YYYY",
  isoDateFormat: "YYYY-MM-DD",
  isoTimeFormat: "HH:mm:ss.SSS",
};

export default momentFormats;
