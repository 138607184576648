const mapToIncidentReport = (incidentReportFragment) =>
  incidentReportFragment && {
    __typename: incidentReportFragment.__typename,
    id: incidentReportFragment.id,
    jobId: incidentReportFragment.jobId,
    submitted: incidentReportFragment.submitted,
    updated: incidentReportFragment.updated,
    created: incidentReportFragment.created,
    startDateTime: incidentReportFragment.incidentReportStartDateTime,
    endDateTime: incidentReportFragment.incidentReportEndDateTime,
    summary: incidentReportFragment.summary,
    policeCase: incidentReportFragment.policeCase,
    subjects: incidentReportFragment.subjects,
    vehicles: incidentReportFragment.vehicles,
    media: incidentReportFragment.media,
    isDeleted: incidentReportFragment.isDeleted,
    siteId: incidentReportFragment.incidentReportSiteId,
    syncDateTime: incidentReportFragment.syncDateTime,
  };

export default mapToIncidentReport;
