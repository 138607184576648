import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectJobEntities";
import selectUpcomingJobIds from "../selectUpcomingJobIds";
import selectAll from "../../../groups/selectors/selectAll";
import assigneeTypes from "../../../../constants/assigneeTypes";
import jobStatuses from "../../../../constants/jobStatuses";
import mapFilterConstants from "../../../../constants/mapFilter";
import selectUser from "../../../auth/selectors/selectUser";
import { CompanyGroup, FormattedJob } from "../../../../types";

const getMyGroups = ({ groups, id }) => {
  const myGroups = [];
  groups.forEach((group) => {
    if (group.members.includes(id)) myGroups.push(group.id);
  });
  return myGroups;
};

const isCommonElement = (arr1, arr2) =>
  arr1.some((item) => arr2.includes(item));

const isJobAccessible = ({
  formattedJob,
  filter,
  id,
  groups,
}: {
  formattedJob: FormattedJob;
  filter: any;
  id: string;
  groups: CompanyGroup[];
}) => {
  const { assignedTo, isDeleted, finished } = formattedJob;
  if (isDeleted || finished) return false;

  switch (filter.assignees) {
    case mapFilterConstants.assignees.onlyMe: {
      const myGroupIds = getMyGroups({ groups, id });
      const userAssignees = [];
      const groupAssignees = [];
      assignedTo.forEach((assignee) => {
        if (assignee.type === assigneeTypes.user)
          userAssignees.push(assignee.id);
        else if (assignee.type === assigneeTypes.group)
          groupAssignees.push(assignee.id);
      });
      return (
        userAssignees.includes(id) ||
        isCommonElement(groupAssignees, myGroupIds) ||
        (formattedJob.startJobRecords.filter(({ record }) => record.by === id)
          .length === 1 &&
          formattedJob.status === jobStatuses.inProgress)
      );
    }

    case mapFilterConstants.assignees.all:
      if (filter.unassignedOnly) {
        return formattedJob.assignedTo.length === 0;
      }
      return true;
    default:
    // do nothing
  }

  return true;
};

const isJobVisible = ({ accessibleJob, filter }) => {
  switch (filter.status) {
    case null:
      return true;
    case jobStatuses.future.id:
      return accessibleJob.status.id === jobStatuses.future.id;
    case jobStatuses.inProgress.id:
      return accessibleJob.status.id === jobStatuses.inProgress.id;
    case jobStatuses.late.id:
      return accessibleJob.status.id === jobStatuses.late.id;
    case jobStatuses.immediate.id:
      return accessibleJob.status.id === jobStatuses.immediate.id;
    default:
      return false;
  }
};

export interface SelectMapJobsReturn {
  accessible: string[];
  visible: string[];
  unassigned: string[];
}

const selectMapJobs = createSelector(
  [
    selectUpcomingJobIds,
    selectEntities,
    (state) => state.data.jobs.filter,
    selectUser,
    selectAll,
  ],
  (upcomingJobIds, jobs, filter, currentUser, groups): SelectMapJobsReturn => {
    if (!currentUser)
      return {
        accessible: [],
        visible: [],
        unassigned: [],
      };
    const accessibleIds: string[] = [];
    const unassignedIds: string[] = [];
    upcomingJobIds.forEach((id) => {
      if (
        isJobAccessible({
          formattedJob: jobs[id],
          filter,
          id: currentUser.id,
          groups,
        })
      ) {
        accessibleIds.push(id);
      }

      if (
        jobs[id].assignedTo.length === 0 &&
        !jobs[id].isDeleted &&
        !jobs[id].finished
      ) {
        unassignedIds.push(id);
      }
    });
    const visibleIds = accessibleIds.filter((id) =>
      isJobVisible({ accessibleJob: jobs[id], filter })
    );
    return {
      accessible: accessibleIds,
      visible: visibleIds,
      unassigned: unassignedIds,
    };
  }
);

export default selectMapJobs;
