import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setTimeCard } from "../../../store/timeCards/slice";
import { Timecard } from "../../../types";

const dispatchOptimisticResponse = (timeCard: Timecard) => {
  const { dispatch } = store;
  dispatch(
    setTimeCard.pending({
      deleted: true,
      mutation: "adminDeleteTimecard",
      timeCard: {
        ...timeCard,
      },
    })
  );
};

const adminDeleteTimecard = (timeCard: Timecard) => {
  dispatchOptimisticResponse(timeCard);
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      id: timeCard.id,
    },
  });
};

export default adminDeleteTimecard;
