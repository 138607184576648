import createUsageRecord from "../../api/functions/createUsageRecord";
import store from "../../store";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectUserById from "../../store/users/selectors/selectUserById";

const runCreateUsageRecord = () => {
  const state = store.getState();
  const signedInUser = selectUser(state);
  const company = selectCompany(state);
  const companyUser = selectUserById(state, signedInUser && signedInUser.id);
  if (
    company.billing &&
    company.billing.mauSubscriptionItemId &&
    companyUser &&
    !companyUser.unmeteredUsage
  ) {
    createUsageRecord({ timestamp: new Date().toISOString() });
  }
};

export default runCreateUsageRecord;
