import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  mutation createActionItemCompletionRecord(
    $actionItemId: ID!
    $coordinates: CoordinatesInput!
    $jobId: ID!
    $timestamp: AWSDateTime!
    $media: MediaInput
    $dailyActivityReportId: ID
  ) {
    createActionItemCompletionRecord(
      actionItemId: $actionItemId
      coordinates: $coordinates
      jobId: $jobId
      timestamp: $timestamp
      media: $media
      dailyActivityReportId: $dailyActivityReportId
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;
