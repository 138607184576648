import React, { ReactElement } from "react";

import ConditionalTooltip from "../buildingBlocks/ConditionalTooltip";
import useClockInProps from "../../hooks/useClockInProps";

type Props = {
  children: ReactElement;
  disabled?: boolean;
};

const ClockInTooltip = ({ children, disabled }: Props) => {
  const {
    ConditionalTooltipProps: { accessoryRight, title, visible },
  } = useClockInProps({ disabled });
  return (
    <ConditionalTooltip
      accessoryRight={accessoryRight}
      title={title}
      visible={visible}
    >
      {children}
    </ConditionalTooltip>
  );
};

ClockInTooltip.defaultProps = {
  disabled: undefined,
};

export default ClockInTooltip;
