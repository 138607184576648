import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { Coordinates, Job } from "../../../types";

interface CreateCommuteRecordInput {
  jobId: string;
  coordinates: Coordinates;
  timestamp: string;
}

const createCommuteRecord = async ({
  jobId,
  coordinates,
  timestamp,
}: CreateCommuteRecordInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        jobId,
        coordinates,
        timestamp,
      },
    })) as GraphQLResult<{ createCommuteRecord: Job }>;
    return data.createCommuteRecord;
  } catch ({ errors }) {
    throw generateErrorMessage("createCommuteRecord", errors);
  }
};

export default createCommuteRecord;
