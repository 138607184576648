import gql from "graphql-tag";

import { companyGroupFragment } from "../../../fragments";

export default gql`
  mutation adminDeleteCompanyGroup($id: ID!) {
    adminDeleteCompanyGroup(id: $id) {
      ...companyGroupFragment
    }
  }
  ${companyGroupFragment}
`;
