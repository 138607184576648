import { ButtonProps, Text, TextProps, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

type Props = Pick<ButtonProps, "status"> & Pick<TextProps, "children">;

const styles = StyleSheet.create({
  root: {
    borderRadius: 4,
    color: "white",
    overflow: "hidden",
    paddingEnd: 4,
    paddingStart: 4,
    textAlign: "center",
  },
});

const Flair = ({ children, status }: Props) => {
  const theme = useTheme();
  return (
    <Text
      category="label"
      style={[
        styles.root,
        {
          backgroundColor: theme[`color-${status}-default`],
        },
      ]}
    >
      {children}
    </Text>
  );
};

Flair.defaultProps = {
  status: "primary",
};

export default Flair;
