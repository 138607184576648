import { Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import {
  CommuteRecord,
  FinishedStatusRecord,
  StartJobRecord,
} from "../../../types";
import TimelineListItem from "../Timeline";
import UserListItem from "../User";

interface UserAssigneeListItemProps {
  commuteRecord?: CommuteRecord;
  finishedStatusRecord?: FinishedStatusRecord;
  startJobRecord?: StartJobRecord;
  userId: string;
}

const StyledText = styled(Text)`
  margin-left: 8px;
  margin-right: 8px;
`;

const UserAssigneeListItem = ({
  commuteRecord,
  finishedStatusRecord,
  startJobRecord,
  userId,
}: UserAssigneeListItemProps) => {
  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const userActivityRecords: Array<{
    description: string;
    timestamp: string;
  }> = [];
  if (commuteRecord) {
    userActivityRecords.push({
      description: "Commute started",
      timestamp: commuteRecord.at,
    });
  }
  if (startJobRecord) {
    userActivityRecords.push({
      description: "Job started",
      timestamp: startJobRecord.record.at,
    });
  }
  if (finishedStatusRecord) {
    userActivityRecords.push({
      description: `Job ${finishedStatusRecord.status}`,
      timestamp: finishedStatusRecord.record.at,
    });
  }

  return (
    <>
      <UserListItem disabled userId={userId} />
      {userActivityRecords
        .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
        .map((userActivityRecord, index, array) => (
          <TimelineListItem
            description={dayjs(userActivityRecord.timestamp).format(
              dateTimeFormat
            )}
            disabled
            key={userActivityRecord.description}
            showBottomConnector={array.length > 1 && index < array.length - 1}
            showTopConnector={array.length > 1 && index !== 0}
            title={() => (
              <StyledText category="p2">
                {userActivityRecord.description}
              </StyledText>
            )}
          />
        ))}
    </>
  );
};

export default UserAssigneeListItem;
