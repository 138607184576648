import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import AlertModal from "../Alert";

momentDurationFormatSetup(moment);

const CompleteJobEarlyModal = ({
  isVisible,
  jobEndDateTime,
  onCancel,
  onClose,
  onConfirm,
}) => {
  const [duration, setDuration] = useState(null);
  useEffect(() => {
    if (isVisible) {
      setDuration(
        moment
          .duration(moment(jobEndDateTime).diff(moment(), "minutes"), "minute")
          .format("y [years], w [weeks], d [days], h [hours] [and] m [minutes]")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <AlertModal
      cancelText="Cancel"
      confirmText="Complete job"
      isVisible={isVisible}
      message={`You will be completing this job ${duration} early. Are you sure you want to complete this job?`}
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

CompleteJobEarlyModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  jobEndDateTime: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CompleteJobEarlyModal;
