import gql from "graphql-tag";

import { stripeExpandedQuoteFragment } from "../../../fragments";

const finalizeQuote = gql`
  mutation finalizeQuote($id: ID!) {
    finalizeQuote(id: $id) {
      ...stripeExpandedQuoteFragment
    }
  }
  ${stripeExpandedQuoteFragment}
`;

export default finalizeQuote;
