import { createSelector } from "@reduxjs/toolkit";

import selectSites from "../selectSites";
import selectJobsByIds from "../../../jobs/selectors/selectJobsByIds";
import { Job } from "../../../../types";

const selectSitesByJobIds = createSelector(
  [selectSites, selectJobsByIds],
  (sites, jobs: Array<Job>) =>
    jobs.map((job) => ({ jobId: job.id, site: sites.entities[job.siteId] }))
);

export default selectSitesByJobIds;
