import moment from "moment-timezone";

const validateJobDateTimes = ({ startDateTime, endDateTime }) => {
  const startMoment = moment.utc(startDateTime).seconds(0).millisecond(0);
  if (!endDateTime)
    return {
      startDateTime: startDateTime.toISOString(),
      endDateTime: null,
    };
  if (startDateTime >= endDateTime)
    throw new Error("End date time must be after start date time");
  const endMoment = moment.utc(endDateTime).seconds(0).millisecond(0);
  const durationDays = endMoment.diff(startMoment, "days", true);
  if (durationDays >= 1) throw new Error("Duration must be less than 1 day");
  return {
    startDateTime: startMoment.toISOString(),
    endDateTime: endMoment.toISOString(),
  };
};

export default validateJobDateTimes;
