import { Button, Divider, Input, Layout } from "@ui-kitten/components";
import React, { memo, useEffect, useState } from "react";
import { Keyboard, ScrollView, StyleSheet } from "react-native";

import Separator from "../../../../buildingBlocks/Separator";
import HeaderWithTextAction from "../../../../buildingBlocks/HeaderWithTextAction";
import useAndroidBackHandler from "../../../../../device/useAndroidBackHandler";
import useIosBottomSpace from "../../../../../device/useIosBottomSpace";
import { PoliceCase } from "../../../../../types";

interface Props {
  onBackPress: () => void;
  onSubmit: (policeCase: PoliceCase) => void;
  title: string;
  policeCase?: PoliceCase;
  visible: boolean;
}

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  scrollContent: {
    paddingTop: 8,
  },
  sectionItem: {
    paddingBottom: 8,
    paddingTop: 8,
  },
});

const PoliceCaseScreen = ({
  onBackPress,
  onSubmit,
  title,
  visible,
  policeCase,
}: Props) => {
  const iosBottomSpace = useIosBottomSpace();
  const [{ number, agency }, setPoliceCase] = useState({
    number: "",
    agency: "",
  });
  useAndroidBackHandler({ enabled: visible, onBackPress });
  useEffect(() => {
    if (visible) {
      setPoliceCase({
        number: policeCase ? policeCase.number : "",
        agency: policeCase ? policeCase.agency : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <HeaderWithTextAction
        action={{
          "data-test": "submitButtonInPoliceCaseModalScreen",
          loading: false,
          onPress: () => {
            if (number || agency) {
              onSubmit({ number: number || null, agency: agency || null });
            } else {
              onBackPress();
            }
          },
          text: "Done",
        }}
        navigation={{ icon: "arrow-back-outline", onPress: onBackPress }}
        title={title}
      />
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView
          contentContainerStyle={[
            styles.scrollContent,
            { paddingBottom: iosBottomSpace + 8 },
          ]}
          keyboardShouldPersistTaps="handled"
        >
          <Separator />
          <Divider />
          <Layout style={styles.sectionItem}>
            <Input
              autoCapitalize="characters"
              data-test="numberInputInPoliceCaseModalScreen"
              label="Number"
              onChangeText={(text) =>
                setPoliceCase((prevState) => ({ ...prevState, number: text }))
              }
              style={styles.input}
              value={number}
            />
            <Separator size="small" />
            <Input
              data-test="agencyInputInPoliceCaseModalScreen"
              label="Agency"
              onChangeText={(text) =>
                setPoliceCase((prevState) => ({ ...prevState, agency: text }))
              }
              style={styles.input}
              value={agency}
            />
          </Layout>
          <Divider />
          {Boolean(policeCase) && (
            <>
              <Separator />
              <Button
                onPress={() => {
                  Keyboard.dismiss();
                  onSubmit(null);
                }}
                status="danger"
                style={styles.button}
              >
                Delete
              </Button>
            </>
          )}
          <Separator />
        </ScrollView>
      </Layout>
    </>
  );
};

PoliceCaseScreen.defaultProps = {
  policeCase: null,
};

export default memo(
  PoliceCaseScreen,
  (prevProps, nextProps) => !prevProps.visible && !nextProps.visible
);
