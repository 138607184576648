import React from "react";
import { Keyboard, StyleSheet } from "react-native";

import Button, { Props as ButtonProps } from "../Button";
import Header, { Props as HeaderProps } from "../Header";

export interface HeaderWithTextActionActionProps
  extends Pick<
    ButtonProps,
    "ConditionalTooltipProps" | "disabled" | "loading"
  > {
  "data-test"?: string;
  onPress: () => void;
  text: string;
}

interface Props extends HeaderProps {
  action?: HeaderWithTextActionActionProps;
  isActionVisible?: boolean;
}

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});

const HeaderWithTextAction = ({
  navigation,
  title,
  action,
  subtitle,
  isActionVisible,
}: Props) => {
  const renderAction = () => {
    if (!action || !isActionVisible) {
      return null;
    }
    return (
      <Button
        appearance="ghost"
        ConditionalTooltipProps={action.ConditionalTooltipProps}
        data-test={action["data-test"]}
        disabled={action.disabled}
        loading={action.loading}
        onPress={() => {
          Keyboard.dismiss();
          action.onPress();
        }}
        style={styles.button}
      >
        {action.text}
      </Button>
    );
  };

  return (
    <Header
      navigation={navigation}
      title={title}
      accessoryRight={renderAction}
      subtitle={subtitle}
    />
  );
};

HeaderWithTextAction.defaultProps = {
  action: null,
  isActionVisible: true,
};

export default HeaderWithTextAction;
