import { createSelector } from "@reduxjs/toolkit";

import selectUserEntities from "../selectEntities";

const selectUserById = createSelector(
  [selectUserEntities, (_, id: string) => id],
  (entities, id) => entities[id]
);

export default selectUserById;
