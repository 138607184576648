import { createSelector } from "@reduxjs/toolkit";

import selectCustomers from "../selectCustomers";

const selectStripeCustomers = createSelector([selectCustomers], (customers) => {
  const stripeCustomers = {
    entities: {},
    ids: [],
  };
  customers.ids.forEach((customerId) => {
    const customer = customers.entities[customerId];
    if (customer.stripeCustomerId) {
      stripeCustomers.entities[customer.stripeCustomerId] = customer;
      stripeCustomers.ids.push(customer.stripeCustomerId);
    }
  });
  return stripeCustomers;
});

export default selectStripeCustomers;
