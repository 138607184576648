import { Icon, useTheme } from "@ui-kitten/components";
import React, { forwardRef } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import MapView, { Region } from "react-native-maps";

import DefaultMapView from "../DefaultMapView";
import useIosBottomSpace from "../../../device/useIosBottomSpace/index";

type Props = {
  initialRegion?: Region;
  onRegionChangeComplete: (region: Region) => void;
  style?: StyleProp<ViewStyle>;
};

const styles = StyleSheet.create({
  map: {
    flex: 1,
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
});

const MarkerPositioningMapView = forwardRef<MapView, Props>(
  ({ initialRegion, onRegionChangeComplete, style }: Props, ref) => {
    const theme = useTheme();
    const iosBottomSpace = useIosBottomSpace();
    return (
      <View style={style}>
        <DefaultMapView
          initialRegion={initialRegion}
          onRegionChangeComplete={onRegionChangeComplete}
          pitchEnabled={false}
          ref={ref}
          rotateEnabled={false}
          style={styles.map}
        />
        <View pointerEvents="none" style={styles.overlay}>
          <View style={{ marginBottom: iosBottomSpace + 40 }}>
            <Icon
              name="pin"
              fill={theme["color-danger-default"]}
              height={40}
              width={40}
            />
          </View>
        </View>
      </View>
    );
  }
);

MarkerPositioningMapView.defaultProps = {
  initialRegion: undefined,
  style: undefined,
};

export default MarkerPositioningMapView;
