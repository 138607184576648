import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import React, { useState } from "react";
import { FlatList, Keyboard, ScrollView, StyleSheet, View } from "react-native";
import { FAB } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import completeInboxItem from "../../api/functions/completeInboxItem";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import IconButton from "../../components/IconButton";
import ListItem from "../../components/listItems/Base";
import InboxListItem from "../../components/listItems/InboxItem";
import EditReminderModal from "../../components/modals/EditReminder";
import UserSelectModal from "../../components/modals/UserSelect";
import useInboxList from "../../hooks/useInboxList";
import selectUser from "../../store/auth/selectors/selectUser";
import selectUserById from "../../store/users/selectors/selectUserById";
import { StackParamList } from "../../types";
import getUserFullName from "../../utils/getUserFullName";

const AssigneeFilterButton = ({
  onPress,
  userId,
}: {
  onPress: () => void;
  userId: string;
}) => {
  const user = useSelector((state) => selectUserById(state, userId));

  return (
    <ListItem
      accessoryLeft={(imageProps) => <Icon {...imageProps} name="person" />}
      description={
        user &&
        (() => (
          <Text category="c1" status="primary" style={{ marginHorizontal: 8 }}>
            {getUserFullName(user)}
          </Text>
        ))
      }
      onPress={onPress}
      style={{ marginEnd: 16, marginStart: 16 }}
      title="Assigned user"
    />
  );
};

const FilterHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
`;

const FilterRow = styled.View`
  align-items: center;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  filterContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  filterButtonsContainer: {
    flexDirection: "row",
  },
  listButton: {
    borderRadius: 100,
  },
  listEmptyComponent: {
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  fab: {
    position: "absolute",
    right: 16,
  },
  container: {
    borderRadius: 8,
    borderWidth: 1,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  description: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  title: {
    fontSize: 18,
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const InboxScreen = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Inbox">
  >();
  const theme = useTheme();

  const { id } = useSelector(selectUser);

  const [filterUserId, setFilterUserId] = useState(id);
  const [showsTodayOnly, setShowsTodayOnly] = useState(false);

  const [userSelectModalVisible, setUserSelectModalVisible] = useState(false);
  const [editReminderModalVisible, setEditReminderModalVisible] = useState(
    false
  );

  const { inboxItemIds } = useInboxList({
    showsTodayOnly,
    userId: filterUserId,
  });

  return (
    <>
      <Layout level="2" style={styles.flex}>
        <Layout>
          <Container>
            <View
              style={{
                paddingEnd: insets.right,
                paddingStart: insets.left,
                paddingTop: insets.top,
              }}
            >
              <HeaderWithTextAction
                navigation={{
                  icon: "arrow-back-outline",
                  onPress: navigation.goBack,
                }}
                title="Inbox"
                subtitle={`${inboxItemIds.length} ${
                  inboxItemIds.length === 1 ? "item" : "items"
                }`}
              />
            </View>
          </Container>
        </Layout>
        <Divider />
        <Container style={styles.flex}>
          <FlatList
            contentContainerStyle={[{ paddingBottom: insets.bottom }]}
            data={inboxItemIds}
            ItemSeparatorComponent={Divider}
            keyExtractor={(item) => item}
            ListEmptyComponent={
              <View style={styles.listEmptyComponent}>
                <Text category="h3">No inbox items</Text>
              </View>
            }
            ListHeaderComponent={
              <View>
                <Separator size="small" />
                <ScrollView
                  contentContainerStyle={styles.scrollViewContentContainerStyle}
                  horizontal
                >
                  <StyledButton
                    appearance={!showsTodayOnly ? "filled" : "outline"}
                    onPress={() => setShowsTodayOnly(false)}
                    status="basic"
                  >
                    All inbox items
                  </StyledButton>
                  <Separator horizontal size="small" />
                  <StyledButton
                    appearance={showsTodayOnly ? "filled" : "outline"}
                    onPress={() => setShowsTodayOnly(true)}
                    status="basic"
                  >
                    Today's inbox items
                  </StyledButton>
                </ScrollView>
                <Separator size="small" />
                <FilterHeaderContainer>
                  <Icon
                    name="options-2-outline"
                    style={{
                      height: 24,
                      tintColor: theme["text-hint-color"],
                      width: 24,
                    }}
                  />
                  <Separator horizontal size="small" />
                  <Text category="s1">Filter by</Text>
                </FilterHeaderContainer>
                <Separator size="small" />
                <FilterRow>
                  <View style={{ flex: 1 }}>
                    <AssigneeFilterButton
                      onPress={() => setUserSelectModalVisible(true)}
                      userId={filterUserId}
                    />
                  </View>
                  {Boolean(filterUserId) && (
                    <>
                      <IconButton
                        appearance="ghost"
                        name="close"
                        onPress={() => setFilterUserId(null)}
                        status="basic"
                      />
                      <Separator horizontal size="medium" />
                    </>
                  )}
                </FilterRow>
                <Separator size="medium" />
              </View>
            }
            renderItem={({ item: id }) => (
              <Layout>
                <InboxListItem
                  key={id}
                  inboxItemId={id}
                  onCheckboxChange={(inboxItem) =>
                    completeInboxItem(
                      { id: inboxItem.id, userId: id },
                      inboxItem
                    )
                  }
                  onPress={(inboxItem) => {
                    if (inboxItem.type === "Reminder") {
                      navigation.navigate("ReminderDetails", {
                        inboxItemId: inboxItem.id,
                      });
                    } else {
                      navigation.navigate("InboxItemDetails", {
                        inboxItemId: inboxItem.id,
                      });
                    }
                  }}
                />
              </Layout>
            )}
          />
        </Container>
      </Layout>
      <FAB
        icon="plus"
        onPress={() => {
          Keyboard.dismiss();
          setEditReminderModalVisible(true);
        }}
        style={{
          backgroundColor: theme["color-primary-default"],
          position: "absolute",
          margin: 16,
          right: 0,
          bottom: insets.bottom,
        }}
      />
      <UserSelectModal
        filterByRole={["Admin", "Supervisor"]}
        headerTitle="Select a user"
        isVisible={userSelectModalVisible}
        onClose={() => setUserSelectModalVisible(false)}
        onSubmit={(userId) => {
          setFilterUserId(userId);
          setUserSelectModalVisible(false);
        }}
      />
      <EditReminderModal
        isVisible={editReminderModalVisible}
        initialValues={{
          userId: id,
        }}
        onClose={() => setEditReminderModalVisible(false)}
      />
    </>
  );
};

export default InboxScreen;
