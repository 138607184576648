import PropTypes from "prop-types";
import React from "react";

import FullScreenModal from "../FullScreen";
import SitePicker from "../../SitePicker";

const SitePickerModal = ({
                           isVisible,
                           onClose,
                           siteIds,
                           onSelect,
                           selected,
                         }) => {
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <SitePicker
        navigation={{
          icon: "close-outline",
          onPress: onClose,
        }}
        onSelect={onSelect}
        selected={selected}
        siteIds={siteIds}
        title="Choose a location"
      />
    </FullScreenModal>
  );
};

SitePickerModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  siteIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
};

SitePickerModal.defaultProps = {
  selected: null,
};

export default SitePickerModal;
