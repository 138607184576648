import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { RefreshControl, SafeAreaView, ScrollView, View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import retrieveTaxRate from "../../api/functions/retrieveTaxRate";
import updateTaxRate from "../../api/functions/updateTaxRate";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import AlertModal from "../../components/modals/Alert";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { StackParamList, StripeTaxRate } from "../../types";

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const TaxRateDetails = () => {
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "TaxRateDetails">
  >();
  const {
    params: { taxRateId },
  } = useRoute<RouteProp<StackParamList, "TaxRateDetails">>();
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [taxRate, setTaxRate] = useState<StripeTaxRate>();

  const [archivePending, setArchivePending] = useState(false);
  const [unarchivePending, setUnarchivePending] = useState(false);

  const [archiveTaxRateModalVisible, setArchiveTaxRateModalVisible] = useState(
    false
  );

  const [taxRatesStale, setTaxRatesStale] = useState(false);

  const runRetrieveTaxRate = useCallback(async () => {
    try {
      setTaxRate(await retrieveTaxRate(taxRateId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve tax rate failed",
      });
    }
  }, [taxRateId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await runRetrieveTaxRate();
      setLoading(false);
    })();
  }, [runRetrieveTaxRate]);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            navigation={{
              icon: "arrow-back-outline",
              onPress: () =>
                navigation.navigate("TaxRates", { stale: taxRatesStale }),
            }}
            title="Tax rate details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          refreshControl={
            <RefreshControl
              onRefresh={async () => {
                setRefreshing(true);
                await runRetrieveTaxRate();
                setRefreshing(false);
              }}
              refreshing={refreshing}
            />
          }
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              {taxRate ? (
                <>
                  <Separator size="medium" />
                  <StyledCard>
                    <Separator size="medium" />
                    <View
                      style={{ alignItems: "center", flexDirection: "row" }}
                    >
                      <StyledText category="h5">{`${taxRate.display_name} at ${taxRate.percentage}%`}</StyledText>
                      <Separator horizontal size="small" />
                      <Text
                        category="s1"
                        status={taxRate.active ? "success" : "basic"}
                        style={{
                          backgroundColor:
                            theme[
                              taxRate.active
                                ? "color-success-transparent-default"
                                : "color-basic-transparent-default"
                            ],
                          borderRadius: 8,
                          overflow: "hidden",
                          paddingEnd: 8,
                          paddingStart: 8,
                        }}
                      >
                        {taxRate.active ? "Active" : "Archived"}
                      </Text>
                    </View>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      ID
                    </StyledText>
                    <StyledText>{taxRate.id}</StyledText>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      Created
                    </StyledText>
                    <StyledText>
                      {dayjs.unix(taxRate.created).format(dateTimeFormat)}
                    </StyledText>
                    <Separator size="medium" />
                  </StyledCard>
                  <Separator size={48} />
                  {taxRate.active ? (
                    <StyledButton
                      appearance="ghost"
                      loading={archivePending}
                      onPress={() => setArchiveTaxRateModalVisible(true)}
                      status="basic"
                    >
                      Archive
                    </StyledButton>
                  ) : (
                    <StyledButton
                      appearance="ghost"
                      loading={unarchivePending}
                      onPress={async () => {
                        setUnarchivePending(true);
                        try {
                          setTaxRate(
                            await updateTaxRate({
                              active: true,
                              id: taxRateId,
                            })
                          );
                          setTaxRatesStale(true);
                        } catch (error) {
                          addNotification({
                            message: error?.message || error,
                            status: "danger",
                            title: "Unarchive tax rate failed",
                          });
                        }
                        setUnarchivePending(false);
                      }}
                      status="basic"
                    >
                      Unarchive
                    </StyledButton>
                  )}
                  <Separator />
                </>
              ) : (
                <EmptyScreenContainer>
                  <Separator />
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Text appearance="hint" category="s1">
                      No results found
                    </Text>
                  )}
                </EmptyScreenContainer>
              )}
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
      <AlertModal
        confirmText="Archive tax rate"
        isVisible={archiveTaxRateModalVisible}
        message="Archived tax rates cannot be used with new quotes, but will still work for quotes that already have it set."
        onCancel={() => setArchiveTaxRateModalVisible(false)}
        onClose={() => setArchiveTaxRateModalVisible(false)}
        onConfirm={async () => {
          setArchiveTaxRateModalVisible(false);
          setArchivePending(true);
          try {
            setTaxRate(
              await updateTaxRate({
                active: false,
                id: taxRateId,
              })
            );
            setTaxRatesStale(true);
          } catch (error) {
            addNotification({
              message: error?.message || error,
              status: "danger",
              title: "Archive tax rate failed",
            });
          }
          setArchivePending(false);
        }}
      />
    </>
  );
};

export default TaxRateDetails;
