import { createAction } from "@reduxjs/toolkit";

import {
  ActionItem,
  Company,
  CompanyGroup,
  CompanySite,
  CompanyUser,
  Customer,
  InboxItem,
  Job,
} from "../../../types";

type SyncPayload = {
  actionItems: Array<ActionItem>;
  company?: Company;
  customers: Array<Customer>;
  groups: Array<CompanyGroup>;
  jobs: Array<Job>;
  lastSync: {
    dateTime: string;
    fullSync: boolean;
    mostRecentSyncDateTime: string;
  };
  sites: Array<CompanySite>;
  users: Array<CompanyUser>;
  inboxItems: Array<InboxItem>;
};

// eslint-disable-next-line import/prefer-default-export
export const sync = {
  fulfilled: createAction<SyncPayload>("data/sync/fulfilled"),
  pending: createAction<SyncPayload>("data/sync/pending"),
  rejected: createAction(
    "data/sync/rejected",
    (payload, errors: Array<Error>) => ({
      errors,
      payload,
    })
  ),
};
