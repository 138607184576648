import { Divider } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import UsersSelect from "../../../UsersSelect";
import useAndroidBackHandler from "../../../../device/useAndroidBackHandler";

const UsersSelectScreen = ({
  availableUserIds,
  initialSelectedUserIds,
  navigation,
  submit,
  visible,
}) => {
  const [selected, setSelected] = useState([]);
  useAndroidBackHandler({ enabled: visible, onBackPress: navigation.onPress });
  useEffect(() => {
    if (visible) {
      setSelected(initialSelectedUserIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <>
      <Divider />
      <UsersSelect
        actionItem={{
          onPress: () => submit.onPress(selected),
          text: submit.text,
        }}
        availableUserIds={availableUserIds}
        navigation={navigation}
        onChange={(selectedUserIds) => setSelected(selectedUserIds)}
        selectedUserIds={selected}
        title="Select users"
      />
    </>
  );
};

UsersSelectScreen.propTypes = {
  availableUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialSelectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  navigation: PropTypes.shape({
    icon: PropTypes.string,
    onPress: PropTypes.func,
  }).isRequired,
  submit: PropTypes.shape({
    onPress: PropTypes.func,
    text: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default UsersSelectScreen;
