import mutation from "./graphql";
import client from "../../client";

const createUsageRecord = ({ timestamp }: { timestamp: string }) => {
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      timestamp,
    },
  });
};

export default createUsageRecord;
