import { createSelector } from "@reduxjs/toolkit";

import selectSiteEntities from "../selectEntities";
import selectSiteIds from "../selectIds";
import selectMapJobs from "../../../jobs/selectors/mapJobs";
import selectJobEntities from "../../../jobs/selectors/selectJobEntities";
import companyUserRoles from "../../../../constants/companyUserRoles";

const selectAccessibleSiteIds = createSelector(
  [
    selectMapJobs,
    selectSiteEntities,
    selectJobEntities,
    (state) => state.auth.user,
    selectSiteIds,
  ],
  ({ accessible }, sites, jobs, currentUser, siteIds) => {
    if (currentUser.group !== companyUserRoles.EMPLOYEE) {
      return siteIds;
    }

    const accessibleSiteIds = {};
    accessible.forEach((jobId) => {
      const { siteId } = jobs[jobId];
      accessibleSiteIds[siteId] = siteId;
    });
    return Object.keys(accessibleSiteIds);
  }
);

export default selectAccessibleSiteIds;
