import { Layout } from "@ui-kitten/components";
import React, { memo, useEffect, useState } from "react";
import { Image, StyleProp, ViewStyle } from "react-native";

import getMedia from "../../api/functions/getMedia";
import { Media, MediaOnDevice } from "../../types";
import isMediaOnDevice from "../../utils/isMediaOnDevice";

interface Props {
  height: number;
  media: Media | MediaOnDevice;
  style?: StyleProp<ViewStyle>;
  width: number;
}

const MediaThumbnail = ({ height, media, style = undefined, width }: Props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  useEffect(() => {
    (async () => {
      if (isMediaOnDevice(media)) {
        setThumbnailUrl(media.uri);
      } else {
        try {
          const mediaResponse = await getMedia(media.id);
          setThumbnailUrl(mediaResponse.url);
        } catch (error) {
          // Do nothing
        }
      }
    })();
  }, [media]);
  return (
    <Layout level="2" style={[style, { height, width }]}>
      {thumbnailUrl && (
        <Image
          source={{ uri: thumbnailUrl }}
          style={{ height, width, resizeMode: "cover" }}
        />
      )}
    </Layout>
  );
};

export default MediaThumbnail;
