import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeExpandedInvoice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdateInvoiceInput
  extends Pick<Stripe.InvoiceUpdateParams, "default_tax_rates"> {
  id: string;
}

const updateInvoice = async ({ default_tax_rates, id }: UpdateInvoiceInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        default_tax_rates,
        id,
      },
    })) as GraphQLResult<{ updateInvoice: StripeExpandedInvoice }>;
    return data.updateInvoice;
  } catch ({ errors }) {
    throw generateErrorMessage("updateInvoice", errors);
  }
};

export default updateInvoice;
