import * as Notifications from "expo-notifications";
import { useEffect } from "react";

import useAppActive from "../../device/useAppActive";

const useDismissAllNotifications = () => {
  const appActive = useAppActive();
  useEffect(() => {
    if (appActive) {
      (async () => {
        try {
          await Notifications.dismissAllNotificationsAsync();
          await Notifications.setBadgeCountAsync(0);
        } catch (error) {
          // Do nothing
        }
      })();
    }
  }, [appActive]);
};

export default useDismissAllNotifications;
