/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";

import BaseListItem from "../Base";

const SubjectListItem = ({ index, subject, ...props }) => {
  return (
    <BaseListItem {...props} title={subject.name || `Subject #${index + 1}`} />
  );
};

SubjectListItem.propTypes = {
  index: PropTypes.number.isRequired,
  subject: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default SubjectListItem;
