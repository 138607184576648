import { Icon, ListItemProps, TextProps } from "@ui-kitten/components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import selectGroupById from "../../../store/groups/selectors/selectGroupById";

import ListItem from "../Base";
import UserListItem from "../User";

interface GroupAssigneeListItemProps extends Pick<ListItemProps, "disabled"> {
  groupId: string;
}

const titleProps: TextProps = {
  category: "p2",
};

const GroupAssigneeListItem = ({ groupId }: GroupAssigneeListItemProps) => {
  const group = useSelector((state) => selectGroupById(state, groupId));

  const [expanded, setExpanded] = useState(false);

  const expandable = group.members.length > 0;

  return (
    <>
      <ListItem
        accessoryRight={
          expandable &&
          ((imageProps) => (
            <Icon
              {...imageProps}
              name={expanded ? "arrow-ios-upward" : "arrow-ios-downward"}
            />
          ))
        }
        disabled={!expandable}
        onPress={() => setExpanded(!expanded)}
        title={group.name}
      />
      {expandable &&
        expanded &&
        group.members.map((userId) => (
          <UserListItem
            disabled
            key={userId}
            userId={userId}
            titleProps={titleProps}
          />
        ))}
    </>
  );
};

export default GroupAssigneeListItem;
