import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { DateTimeDataResult, DateTimeDataType } from "../../../types";

interface Variables {
  from?: string;
  to?: string;
  type?: DateTimeDataType;
  after?: string;
}

interface Data {
  queryDateTimeData: DateTimeDataConnection;
}

interface DateTimeDataConnection {
  items: Array<DateTimeDataResult>;
  nextToken?: string;
}

const queryDateTimeData = async ({ type, to, from }: Variables) => {
  const results: Array<DateTimeDataResult> = [];
  let nextToken: string | null = null;
  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await client.query<Data, Variables>({
        fetchPolicy: "no-cache",
        query,
        variables: {
          type,
          to,
          from,
          after: nextToken,
        },
      });
      const { queryDateTimeData: result } = data;
      results.push(...result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  } catch (errors) {
    throw generateErrorMessage("queryDateTimeData", errors);
  }
};

export default queryDateTimeData;
