import Amplify from "@aws-amplify/core";
import * as eva from "@eva-design/eva";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import {
  ApplicationProvider,
  IconRegistry,
  Layout,
  Spinner,
} from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import Constants from "expo-constants";
import { useKeepAwake } from "expo-keep-awake";
import React, { useEffect } from "react";
import { LogBox, Platform, StatusBar, StyleSheet } from "react-native";
import "react-native-reanimated";
import "react-native-get-random-values";
import {
  DarkTheme,
  DefaultTheme,
  Portal,
  Provider as PaperProvider,
} from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppDisabler from "./src/components/AppDisabler";

import AppHeader from "./src/components/AppHeader";
import BillingGateKeeper from "./src/components/BillingGateKeeper";
import CompanyDisabledGateKeeper from "./src/components/CompanyDisabledGateKeeper";
import CompanyFeaturesChangedGateKeeper from "./src/components/CompanyFeaturesChangedGatekeeper";
import FullHeightView from "./src/components/FullHeightView";
import GateKeeper from "./src/components/GateKeeper";
import MaterialCommunityIconsPack from "./src/components/icons/MaterialCommunityIconsPack";
import MaterialIconsPack from "./src/components/icons/MaterialIconsPack";
import Notifications from "./src/components/InAppNotifications";
import KeyboardAvoidingView from "./src/components/KeyboardAvoidingView";
import config from "./src/config";
import useCustomColorScheme from "./src/device/useColorScheme";
import useColorSchemeListener from "./src/hooks/useColorSchemeListener";
import useOtaUpdates from "./src/hooks/useOtaUpdates";
import usePeriodicPoll from "./src/hooks/usePeriodicPoll";
import "./src/location/useLocation";
import { isMountedRef } from "./src/navigation/navigator";
import RootNavigation from "./src/navigation/RootNavigation";
import useDismissAllNotifications from "./src/notifications/useDismissAllNotifications";
import store, { persistor } from "./src/store";
import selectAppVersion from "./src/store/appInfo/selectors/selectAppVersion";
import { setAppVersion } from "./src/store/appInfo/slice";
import selectUser from "./src/store/auth/selectors/selectUser";
import { systemColorSchemeChanged } from "./src/store/settings/slice";
import useSubscriptions from "./src/subscriptions/usePusherSubscriptions";
import theme from "./src/theme";
import initSession from "./src/utils/initSession";

if (Platform.OS === "android") {
  LogBox.ignoreLogs(["Setting a timer"]);
}

Amplify.configure(config.aws);

const styles = StyleSheet.create({
  gatekeeper: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

// Uncomment to clear redux-persist.
// persistor.purge();

const App = () => {
  useKeepAwake();
  useOtaUpdates();
  useDismissAllNotifications();
  const dispatch = useDispatch();
  useColorSchemeListener((colorScheme) =>
    dispatch(systemColorSchemeChanged({ colorScheme }))
  );
  const user = useSelector(selectUser);
  const authenticating = useSelector((state: any) => state.auth.authenticating);
  useEffect(() => {
    // @ts-ignore
    isMountedRef.current = true;
    return () => {
      // @ts-ignore
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const { version } = Constants.expoConfig;
    const appVersion = selectAppVersion(store.getState());
    if (version && appVersion !== version) {
      persistor.purge();
      store.dispatch(setAppVersion({ version }));
    }
    initSession().then();
  }, [dispatch]);
  usePeriodicPoll();
  useSubscriptions();
  return !authenticating ? (
    <AppDisabler>
      <AppHeader />
      <RootNavigation user={user} />
    </AppDisabler>
  ) : (
    <Layout style={styles.loading}>
      <Spinner />
    </Layout>
  );
};

const AppUiProvider = () => {
  const colorScheme = useCustomColorScheme();
  return (
    <>
      <StatusBar
        barStyle={colorScheme === "light" ? "dark-content" : "light-content"}
        backgroundColor={colorScheme === "light" ? "white" : "black"}
      />
      <SafeAreaProvider>
        <IconRegistry
          icons={[EvaIconsPack, MaterialCommunityIconsPack, MaterialIconsPack]}
        />
        <KeyboardAvoidingView>
          <ApplicationProvider
            {...eva}
            theme={{ ...eva[colorScheme], ...theme }}
          >
            <PaperProvider
              theme={colorScheme === "light" ? DefaultTheme : DarkTheme}
            >
              <FullHeightView>
                <ActionSheetProvider>
                  <Portal.Host>
                    <App />
                  </Portal.Host>
                </ActionSheetProvider>
              </FullHeightView>
            </PaperProvider>
            <GateKeeper style={styles.gatekeeper} />
            <BillingGateKeeper />
            <CompanyDisabledGateKeeper />
            <CompanyFeaturesChangedGateKeeper />
            <Notifications />
          </ApplicationProvider>
        </KeyboardAvoidingView>
      </SafeAreaProvider>
    </>
  );
};

const AppStoreProvider = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppUiProvider />
      </PersistGate>
    </Provider>
  );
};

// expose store when run in Cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

export default AppStoreProvider;
