import { createSelector } from "@reduxjs/toolkit";

import selectSettings from "../selectSettings";

const selectSignInWithPhone = createSelector(
  [selectSettings],
  (settings) => settings.signInWithPhone
);

export default selectSignInWithPhone;
