import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

type Props = PropsWithChildren<{
  height: number;
  width: number;
}>;

const styles = StyleSheet.create({
  contentContainer: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
});

/**
 * At the time of writing 'aspectRatio' is style attribute that is not recognized on web. A custom implementation was
 * made that attempts to mimic its behavior.
 */
const AspectRatioView = ({ children, height, width }: Props) => (
  <View style={{ paddingTop: `${(height / width) * 100}%` }}>
    <View style={styles.contentContainer}>{children}</View>
  </View>
);

export default AspectRatioView;
