import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import AlertModal from "../Alert";
import FullScreenModal from "../FullScreen";
import useModalScreens from "../useModalScreens";
import { Timecard } from "../../../types";
import MainScreen from "./MainScreen";
import LocationSetter from "../../modalScreens/LocationSetter";
import adminUpdateTimecard from "../../../api/functions/adminUpdateTimecard";
import { addNotification } from "../../InAppNotifications";

type Props = {
  isVisible: boolean;
  onClose: () => void;
  timeCard: Timecard;
};

const screensConfig = [["main"], ["locationSetter"]];

const mapToFormikInitialValues = (timeCard: Timecard): Timecard => ({
  ...timeCard,
  created: {
    ...timeCard.created,
  },
  quit: {
    ...timeCard.quit,
    coordinates: {
      ...timeCard.quit.coordinates,
    },
  },
  started: {
    ...timeCard.started,
    coordinates: {
      ...timeCard.started.coordinates,
    },
  },
  updated: {
    ...timeCard.updated,
  },
});

const TimeCardEditingModal = ({ isVisible, onClose, timeCard }: Props) => {
  const { navigation, jumpTo, goBack } = useModalScreens(screensConfig);

  const formik = useFormik({
    initialValues: mapToFormikInitialValues(timeCard),
    onSubmit: (values) => {
      if (values.quit.at < values.started.at) {
        addNotification({
          message: "Quit time cannot be before started time.",
          status: "danger",
          title: "Update failed",
        });
      } else {
        adminUpdateTimecard(
          { quit: values.quit, started: values.started },
          timeCard
        );
        addNotification({
          status: "success",
          title: "Time card updated",
        });
        onClose();
      }
    },
  });

  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(
    false
  );

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: mapToFormikInitialValues(timeCard),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const closeCloseConfirmation = () => setCloseConfirmationVisible(false);
  const closeEverything = () => {
    closeCloseConfirmation();
    onClose();
  };
  const attemptCloseEverything = () => {
    if (formik.dirty) {
      setCloseConfirmationVisible(true);
    } else {
      closeEverything();
    }
  };

  const screen = navigation.screens[navigation.state.index];

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        {screen.name === "main" && (
          <MainScreen
            onChangeQuitLocationPress={() =>
              jumpTo("locationSetter", { field: "quit.coordinates" })
            }
            onChangeStartedLocationPress={() =>
              jumpTo("locationSetter", {
                field: "started.coordinates",
              })
            }
            onClosePress={attemptCloseEverything}
            onQuitTimeChange={(dateTime) =>
              formik.setFieldValue("quit.at", dateTime)
            }
            onStartedTimeChange={(dateTime) =>
              formik.setFieldValue("started.at", dateTime)
            }
            onSubmitPress={formik.handleSubmit}
            timeCard={formik.values}
          />
        )}
        {screen.name === "locationSetter" && (
          <LocationSetter
            initialCoordinates={_.get(formik.values, screen.params?.field)}
            onBackPress={goBack}
            onSubmitPress={(coordinates) => {
              formik.setFieldValue(screen.params?.field, coordinates);
              goBack();
            }}
            visible
          />
        )}
      </FullScreenModal>
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard changes"
        isVisible={closeConfirmationVisible}
        message="Discard your changes?"
        onCancel={closeCloseConfirmation}
        onClose={closeCloseConfirmation}
        onConfirm={closeEverything}
      />
    </>
  );
};

export default TimeCardEditingModal;
