import Auth from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Button,
  Divider,
  Input,
  Layout,
  Icon,
  ListItem,
  Text,
  useTheme,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AsYouType } from "libphonenumber-js";

import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import LoadingButton from "../../components/LoadingButton";
import AlertModal from "../../components/modals/Alert";
import CompleteNewPasswordModal from "../../components/modals/CompleteNewPassword";
import ResetPasswordModal from "../../components/modals/ResetPassword";
import selectSignInWithPhone from "../../store/settings/selectors/selectSignInWithPhone";
import { setSignInWithPhone } from "../../store/settings/slice";
import initSession from "../../utils/initSession";
import getAppVersion from "../../utils/getAppVersion";
import formikUsernameValidationSchema from "../../utils/formikUsernameValidationSchema";
import PasswordInput from "../../components/inputs/Password";

interface PreviousSignIn {
  username: string;
  type: "email" | "phone";
}

const previousSignInStorageKey = "@talarium:previousSignIn";

const storePreviousSignIn = async (previousSignIn: PreviousSignIn) => {
  try {
    await AsyncStorage.setItem(
      previousSignInStorageKey,
      JSON.stringify(previousSignIn)
    );
  } catch (error) {
    // Do nothing
  }
};

const styles = StyleSheet.create({
  appVersion: {
    paddingEnd: 16,
    paddingStart: 16,
    textAlign: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
  form: {
    flex: 1,
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  header: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 24,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  inputAccessoryLeft: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  root: {
    flex: 1,
  },
  safeAreaView: {
    flex: 1,
  },
  scrollContent: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  listItem: {
    borderRadius: 8,
    borderWidth: 1,
  },
  signInHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: 16,
  },
  edgeMargin: {
    marginHorizontal: 16,
  },
});

const SignIn = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const signInWithPhone = useSelector(selectSignInWithPhone);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: "",
    confirmText: null,
    onConfirm: null,
    cancelText: null,
    onCancel: null,
  });
  const [signInStep, setSignInStep] = useState<"method" | "signIn">("method");
  const [completeNewPassword, setCompleteNewPassword] = useState({
    visible: false,
    user: null,
  });
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: formikUsernameValidationSchema(signInWithPhone),
      password: yup.string().required(),
    }),
    onSubmit: async (values, actions) => {
      try {
        let usernameInput: string;
        if (signInWithPhone) {
          const phoneNumber = new AsYouType("US");
          phoneNumber.input(values.username);
          usernameInput = phoneNumber.getNumber().number as string;
        } else {
          usernameInput = values.username.trim();
        }
        const phoneNumber = new AsYouType("US");
        phoneNumber.input(values.username);
        const user = await Auth.signIn(usernameInput, values.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setCompleteNewPassword({ visible: true, user });
        } else {
          storePreviousSignIn({
            username: values.username,
            type: signInWithPhone ? "phone" : "email",
          });
          initSession();
        }
      } catch (error) {
        const switchToResetPassword = () => {
          setAlert((prevState) => ({ ...prevState, isVisible: false }));
          setResetPasswordVisible(true);
        };
        if (error.code === "PasswordResetRequiredException") {
          setAlert({
            isVisible: true,
            message: error.message,
            confirmText: "OK",
            onConfirm: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
            cancelText: "Reset password",
            onCancel: switchToResetPassword,
          });
        } else if (error.code === "NotAuthorizedException") {
          setAlert({
            isVisible: true,
            message: error.message,
            confirmText: "OK",
            onConfirm: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
            cancelText: "Reset password",
            onCancel: switchToResetPassword,
          });
        } else if (error.code === "UserNotFoundException") {
          setAlert({
            isVisible: true,
            message: error.message,
            confirmText: "OK",
            onConfirm: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
            cancelText: "Cancel",
            onCancel: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
          });
        } else {
          setAlert({
            isVisible: true,
            message: error.message,
            confirmText: "OK",
            onConfirm: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
            cancelText: "Cancel",
            onCancel: () =>
              setAlert((prevState) => ({ ...prevState, isVisible: false })),
          });
        }
      }
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const previousSignInJson = await AsyncStorage.getItem(
          previousSignInStorageKey
        );
        if (previousSignInJson !== null) {
          const previousSignIn: PreviousSignIn = JSON.parse(previousSignInJson);
          formik.setFieldValue("username", previousSignIn.username);
          switch (previousSignIn.type) {
            case "email": {
              dispatch(setSignInWithPhone({ signInWithPhone: false }));
              break;
            }
            case "phone": {
              dispatch(setSignInWithPhone({ signInWithPhone: true }));
              break;
            }
            default: {
              break;
            }
          }
          setSignInStep("signIn");
        }
      } catch (error) {
        // Do nothing
      }
    })();
  }, []);

  const handlePhoneSignIn = () => {
    dispatch(setSignInWithPhone({ signInWithPhone: true }));
    setSignInStep("signIn");
  };

  const handleEmailSignIn = () => {
    dispatch(setSignInWithPhone({ signInWithPhone: false }));
    setSignInStep("signIn");
  };

  const formEmpty = !(formik.values.username && formik.values.password);
  return (
    <>
      <Layout style={styles.root}>
        <SafeAreaView style={styles.safeAreaView}>
          <Container>
            <View style={styles.header}>
              <Text category="h4">Sign in</Text>
            </View>
          </Container>
          <Divider />
          <ScrollView contentContainerStyle={styles.scrollContent}>
            <Container>
              <Separator size="small" />
              {signInStep === "method" && (
                <View style={styles.edgeMargin}>
                  <Text category="h5">Choose a sign in method</Text>
                  <Separator />
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon
                        {...imageProps}
                        name="cellphone"
                        pack="MaterialCommunityIcons"
                      />
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="arrow-ios-forward-outline" />
                    )}
                    onPress={handlePhoneSignIn}
                    style={[
                      styles.listItem,
                      { borderColor: theme["border-basic-color-3"] },
                    ]}
                    title="Sign in with phone number"
                  />
                  <Separator size="small" />
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon
                        {...imageProps}
                        name="at"
                        pack="MaterialCommunityIcons"
                      />
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="arrow-ios-forward-outline" />
                    )}
                    data-test="emailButtonInSignInScreen"
                    onPress={handleEmailSignIn}
                    style={[
                      styles.listItem,
                      { borderColor: theme["border-basic-color-3"] },
                    ]}
                    title="Sign in with email"
                  />
                  <Separator />
                  <Text appearance="hint" style={styles.appVersion}>
                    {getAppVersion()}
                  </Text>
                </View>
              )}
              {signInStep === "signIn" && (
                <View data-test="signInStep">
                  <View style={styles.signInHeader}>
                    {signInWithPhone ? (
                      <Text category="h5">Sign in with phone number</Text>
                    ) : (
                      <Text category="h5">Sign in with email</Text>
                    )}
                    <Button
                      appearance="ghost"
                      data-test="backButtonInSignInScreen"
                      onPress={() => {
                        setSignInStep("method");
                        formik.resetForm();
                      }}
                      size="small"
                    >
                      Back
                    </Button>
                  </View>
                  <Separator />
                  <Input
                    autoCapitalize="none"
                    blurOnSubmit={false}
                    disabled={formik.isSubmitting}
                    autoCompleteType={signInWithPhone ? "tel" : "email"}
                    textContentType={
                      signInWithPhone ? "telephoneNumber" : "emailAddress"
                    }
                    keyboardType={
                      signInWithPhone ? "number-pad" : "email-address"
                    }
                    data-test="username"
                    label={signInWithPhone ? "Phone number" : "Email"}
                    onBlur={() => {
                      formik.setFieldTouched("username");
                    }}
                    onChangeText={formik.handleChange("username")}
                    returnKeyType="next"
                    style={styles.input}
                    status={
                      formik.errors.username &&
                      formik.touched.username &&
                      "danger"
                    }
                    placeholder={
                      signInWithPhone ? "(xxx) xxx-xxxx" : "example@domain.com"
                    }
                    value={
                      signInWithPhone
                        ? new AsYouType("US").input(formik.values.username)
                        : formik.values.username
                    }
                    autoCorrect={false}
                  />
                  <Separator size="small" />
                  <PasswordInput
                    data-test="password"
                    disabled={formik.isSubmitting}
                    onFocus={() => {
                      formik.setFieldTouched("password");
                    }}
                    onChangeText={formik.handleChange("password")}
                    style={styles.input}
                    value={formik.values.password}
                  />
                </View>
              )}
              <Separator />
            </Container>
          </ScrollView>
          <Divider />
          <Container>
            <View
              style={[
                styles.footer,
                {
                  flexDirection:
                    signInStep === "signIn" ? "row" : "row-reverse",
                },
              ]}
            >
              {signInStep === "signIn" && (
                <Button
                  appearance="ghost"
                  disabled={formik.isSubmitting}
                  onPress={() => setResetPasswordVisible(true)}
                >
                  Forgot password?
                </Button>
              )}
              <View>
                <LoadingButton
                  loading={formik.isSubmitting}
                  onPress={formik.handleSubmit}
                  disabled={
                    formEmpty ||
                    formik.isSubmitting ||
                    Object.values(formik.errors).length > 0
                  }
                  data-test="signInButton"
                >
                  Sign in
                </LoadingButton>
              </View>
            </View>
          </Container>
        </SafeAreaView>
      </Layout>
      <ResetPasswordModal
        isVisible={resetPasswordVisible}
        onClosePress={() => setResetPasswordVisible(false)}
        onSuccess={() => {
          setResetPasswordVisible(false);
          addNotification({ title: "Your password was successfully updated." });
        }}
      />
      <CompleteNewPasswordModal
        isVisible={completeNewPassword.visible}
        onClose={() => setCompleteNewPassword({ visible: false, user: null })}
        newUser={completeNewPassword.user}
      />
      <AlertModal
        isVisible={alert.isVisible}
        onClose={() =>
          setAlert((prevState) => ({ ...prevState, isVisible: false }))
        }
        message={alert.message}
        confirmText={alert.confirmText}
        onConfirm={alert.onConfirm}
        cancelText={alert.cancelText}
        onCancel={alert.onCancel}
      />
    </>
  );
};

export default SignIn;
