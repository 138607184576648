import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Layout, ListItem, Text } from "@ui-kitten/components";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import SectionItem from "../../components/SectionItem";
import regions from "../../constants/regions";
import { statuses, types } from "../../constants/vehicle";
import selectReportByIdAndJobId from "../../store/jobs/selectors/selectReportByIdAndJobId";
import { StackParamList } from "../../types";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  text: {
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const VehicleDetails = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "VehicleDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "VehicleDetails">>();
  const { reportId, index, jobId } = route.params;
  const incidentReport = useSelector((state) =>
    selectReportByIdAndJobId(state, { id: reportId, jobId })
  );
  if ("vehicles" in incidentReport) {
    const vehicle = incidentReport.vehicles[index];
    const renderGeneral = Boolean(
      vehicle.description ||
        vehicle.registeredOwner ||
        vehicle.status ||
        vehicle.type
    );
    const renderUnit = Boolean(
      vehicle.color ||
        vehicle.make ||
        vehicle.model ||
        vehicle.vin ||
        vehicle.year
    );
    const renderLicense = Boolean(vehicle.license);
    return (
      <Layout style={styles.root}>
        <View
          style={{
            paddingEnd: insets.right,
            paddingStart: insets.left,
            paddingTop: insets.top,
          }}
        >
          <HeaderWithTextAction
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Vehicle details"
          />
        </View>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={[
              styles.scrollContent,
              { paddingBottom: insets.bottom + 8 },
            ]}
          >
            {renderGeneral && (
              <>
                <Separator />
                <SectionItem title="GENERAL">
                  {vehicle.description && (
                    <ListItem
                      title="Description"
                      description={vehicle.description}
                      disabled
                    />
                  )}
                  {vehicle.type && (
                    <>
                      {Boolean(vehicle.description) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Type"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {types[vehicle.type].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {vehicle.status && (
                    <>
                      {Boolean(vehicle.description || vehicle.type) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Status"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {statuses[vehicle.status].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {vehicle.registeredOwner && (
                    <>
                      {Boolean(
                        vehicle.description || vehicle.type || vehicle.status
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Registered owner"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {vehicle.registeredOwner}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                </SectionItem>
              </>
            )}
            {renderUnit && (
              <>
                <Separator />
                <SectionItem title="PROFILE">
                  {vehicle.color && (
                    <ListItem
                      title="Color"
                      accessoryRight={() => (
                        <Text style={styles.text}>{vehicle.color}</Text>
                      )}
                      disabled
                    />
                  )}
                  {vehicle.year && (
                    <>
                      {Boolean(vehicle.year) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Year"
                        accessoryRight={() => (
                          <Text style={styles.text}>{vehicle.year}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {vehicle.make && (
                    <>
                      {Boolean(vehicle.color || vehicle.year) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Make"
                        accessoryRight={() => (
                          <Text style={styles.text}>{vehicle.make}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {vehicle.model && (
                    <>
                      {Boolean(
                        vehicle.color || vehicle.year || vehicle.make
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Model"
                        accessoryRight={() => (
                          <Text style={styles.text}>{vehicle.model}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {vehicle.vin && (
                    <>
                      {Boolean(
                        vehicle.color ||
                          vehicle.year ||
                          vehicle.make ||
                          vehicle.model
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="VIN"
                        accessoryRight={() => (
                          <Text style={styles.text}>{vehicle.vin}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                </SectionItem>
              </>
            )}
            {renderLicense && (
              <>
                <Separator />
                <SectionItem title="VEHICLE LICENSE">
                  <ListItem
                    title="Plate #"
                    accessoryRight={() => (
                      <Text style={styles.text}>{vehicle.license.number}</Text>
                    )}
                    disabled
                  />
                  <Divider style={styles.divider} />
                  <ListItem
                    title="State"
                    accessoryRight={() => (
                      <Text style={styles.text}>
                        {regions[vehicle.license.region].text}
                      </Text>
                    )}
                    disabled
                  />
                </SectionItem>
              </>
            )}
            <Separator />
          </ScrollView>
        </Layout>
      </Layout>
    );
  }
  throw new Error("Vehicle not found.");
};

export default VehicleDetails;
