import React, { ReactNode } from "react";
import { Portal as RnPaperPortal } from "react-native-paper";

import KeyboardAvoidingView from "../KeyboardAvoidingView";

interface Props {
  children: ReactNode;
}

const Portal = ({ children }: Props) => (
  <RnPaperPortal>
    <KeyboardAvoidingView>{children}</KeyboardAvoidingView>
  </RnPaperPortal>
);

export default Portal;
