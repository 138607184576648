import dayjs from "dayjs";

const getWeekBounds = (dateTime: string) => {
  const day = dayjs(dateTime);
  return {
    endDateTime: day.endOf("week").toISOString(),
    startDateTime: day.startOf("week").toISOString(),
  };
};

export default getWeekBounds;
