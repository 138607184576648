import React, { useEffect, useState } from "react";

import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import AssigneesSelect from "../../AssigneesSelect";
import { Assignee, Coordinates } from "../../../types";

interface Props extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  onSelectedChange: (assignees: Array<Assignee>) => void;
  selected: Array<Assignee>;
  destination?: Coordinates;
}

const AssigneesSelectModal = ({
  isVisible,
  onClose,
  onSelectedChange,
  selected,
  destination,
}: Props) => {
  const [tempSelected, setTempSelected] = useState<Array<Assignee>>(selected);
  useEffect(() => {
    if (isVisible) {
      setTempSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <AssigneesSelect
        title="Select assignees"
        onChange={setTempSelected}
        navigation={{ icon: "close-outline", onPress: onClose }}
        selected={tempSelected}
        actionItem={{
          "data-test": "submitButtonInAssigneesSelectModal",
          onPress: () => onSelectedChange(tempSelected),
          text: "Done",
          disabled: false,
        }}
        destination={destination}
      />
    </FullScreenModal>
  );
};

AssigneesSelectModal.defaultProps = {
  destination: null,
};

export default AssigneesSelectModal;
