import gql from "graphql-tag";

import { dailyActivityReportFragment } from "../../../fragments";

export default gql`
  mutation updateDailyActivityReport($input: DailyActivityReportInput!) {
    updateDailyActivityReport(input: $input) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;
