import { ListItem } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import selectGroupById from "../../../store/groups/selectors/selectGroupById";
import selectJobById from "../../../store/jobs/selectors/selectJobById";
import selectSiteById from "../../../store/sites/selectors/selectById";
import selectUserById from "../../../store/users/selectors/selectUserById";
import getDateTimeRangeString from "../../../utils/getDateTimeRangeString";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import selectTimeFormat from "../../../store/settings/selectors/selectTimeFormat";

const getGroupId = (operation) => {
  const { operationName, variables } = operation;
  switch (operationName) {
    case "adminCreateCompanyGroup":
    case "adminDeleteCompanyGroup":
    case "adminUpdateCompanyGroup":
      return variables.id;
    default:
      return null;
  }
};

const getJobId = (operation) => {
  const { operationName, variables } = operation;
  switch (operationName) {
    case "adminDeleteDailyActivityReport":
    case "adminDeleteIncidentReport":
    case "createDailyActivityReport":
    case "createIncidentReport":
    case "createStartJobRecord":
    case "createTaskCompletionRecord":
    case "deleteDailyActivityReport":
    case "deleteIncidentReport":
    case "deleteStartJobRecord":
    case "deleteTaskCompletionRecord":
    case "submitDailyActivityReport":
    case "submitIncidentReport":
      return variables.jobId;
    case "adminUpdateDailyActivityReport":
    case "adminUpdateIncidentReport":
    case "updateDailyActivityReport":
    case "updateIncidentReport":
      return variables.input.jobId;
    case "completeJob":
    case "createJob":
    case "deleteJob":
    case "dismissJob":
    case "updateJob":
      return variables.id;
    default:
      return null;
  }
};

const getSiteId = ({ job, operation }) => {
  const { operationName, variables } = operation;
  switch (operationName) {
    case "adminArchiveCompanySite":
    case "adminCreateCompanySite":
    case "adminRestoreCompanySite":
    case "adminUpdateCompanySite":
      return variables.id;
    case "completeJob":
    case "createJob":
    case "createStartJobRecord":
    case "createTaskCompletionRecord":
    case "deleteJob":
    case "deleteStartJobRecord":
    case "deleteTaskCompletionRecord":
    case "dismissJob":
    case "updateJob":
      return job && job.siteId;
    default:
      return null;
  }
};

const getUserId = (operation) => {
  const { operationName, variables } = operation;
  switch (operationName) {
    case "adminArchiveCompanyUser":
    case "adminCreateCompanyUser":
    case "adminRestoreCompanyUser":
    case "adminUpdateCompanyUser":
      return variables.id;
    default:
      return null;
  }
};

const noAdditionalDataMessage = "No additional data available";

const getJobSiteDescription = ({ dateTimeFormat, job, site, timeFormat }) => {
  return `${site.name} \u2022 ${
    job
      ? getDateTimeRangeString({
        dateTimeFormat,
        end: job.endDateTime,
        start: job.startDateTime,
        timeFormat,
      })
      : noAdditionalDataMessage
  }`;
};

const getOperationDisplayMetadata = ({
                                       dateTimeFormat,
                                       group,
                                       job,
                                       operation,
                                       site,
                                       timeFormat,
                                       user,
                                     }) => {
  const { operationName } = operation;
  const displayMetadata = {
    description: noAdditionalDataMessage,
    title: "[undefined]",
  };
  switch (operationName) {
    case "adminArchiveCompanySite":
      displayMetadata.title = "Location archived";
      if (site) {
        displayMetadata.description = site.name;
      }
      break;
    case "adminArchiveCompanyUser":
      displayMetadata.title = "User archived";
      if (user) {
        displayMetadata.description = `${user.firstName} ${user.lastName}`;
      }
      break;
    case "adminCreateCompanyGroup":
      displayMetadata.title = "Group created";
      if (group) {
        displayMetadata.description = group.name;
      }
      break;
    case "adminCreateCompanySite":
      displayMetadata.title = "Location created";
      if (site) {
        displayMetadata.description = site.name;
      }
      break;
    case "adminDeleteCompanyGroup":
      displayMetadata.title = "Company deleted";
      if (group) {
        displayMetadata.description = group.name;
      }
      break;
    case "adminDeleteDailyActivityReport":
      displayMetadata.title = "Daily activity report deleted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "adminDeleteIncidentReport":
      displayMetadata.title = "Incident report deleted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "adminDeleteTimecard":
      displayMetadata.title = "Time card deleted";
      break;
    case "adminRestoreCompanySite":
      displayMetadata.title = "Location restored";
      if (site) {
        displayMetadata.description = site.name;
      }
      break;
    case "adminRestoreCompanyUser":
      displayMetadata.title = "User restored";
      if (user) {
        displayMetadata.description = `${user.firstName} ${user.lastName}`;
      }
      break;
    case "adminUpdateCompany":
      displayMetadata.title = "Company updated";
      break;
    case "adminUpdateCompanyGroup":
      displayMetadata.title = "Group updated";
      if (group) {
        displayMetadata.description = group.name;
      }
      break;
    case "adminUpdateCompanySite":
      displayMetadata.title = "Location updated";
      if (site) {
        displayMetadata.description = site.name;
      }
      break;
    case "adminUpdateCompanyUser":
      displayMetadata.title = "User updated";
      if (user) {
        displayMetadata.description = `${user.firstName} ${user.lastName}`;
      }
      break;
    case "adminUpdateDailyActivityReport":
      displayMetadata.title = "Daily activity report updated";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "adminUpdateIncidentReport":
      displayMetadata.title = "Incident report updated";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "adminUpdateTimecard":
      displayMetadata.title = "Time card updated";
      break;
    case "clockIn":
      displayMetadata.title = "Clock in";
      break;
    case "clockOut":
      displayMetadata.title = "Clock out";
      break;
    case "completeJob":
      displayMetadata.title = "Job completed";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createDailyActivityReport":
      displayMetadata.title = "Daily activity report created";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createIncidentReport":
      displayMetadata.title = "Incident report created";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createJob":
      displayMetadata.title = "Job created";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createStartJobRecord":
      displayMetadata.title = "Job started";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createTaskCompletionRecord":
      displayMetadata.title = "Task completed";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "createUsageRecord":
      displayMetadata.title = "Usage record created";
      break;
    case "deleteDailyActivityReport":
      displayMetadata.title = "Daily activity report deleted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "deleteIncidentReport":
      displayMetadata.title = "Incident report deleted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "deleteJob":
      displayMetadata.title = "Job deleted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "deleteStartJobRecord":
      displayMetadata.title = "Job start undone";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "deleteTaskCompletionRecord":
      displayMetadata.title = "Task completion undone";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "dismissJob": {
      displayMetadata.title = "Job dismissed";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    }
    case "login":
      displayMetadata.title = "Status set to online";
      break;
    case "markJobAsProcessed":
      displayMetadata.title = "Jobs marked as processed";
      break;
    case "markJobAsNotProcessed":
      displayMetadata.title = "Jobs marked as not processed";
      break;
    case "submitDailyActivityReport":
      displayMetadata.title = "Daily activity report submitted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "submitIncidentReport":
      displayMetadata.title = "Incident report submitted";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "updateCompanyUserStatus":
      displayMetadata.title = "User status updated";
      break;
    case "updateDailyActivityReport":
      displayMetadata.title = "Daily activity report updated";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "updateIncidentReport":
      displayMetadata.title = "Incident report updated";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    case "updateJob":
      displayMetadata.title = "Job updated";
      if (site) {
        displayMetadata.description = getJobSiteDescription({
          dateTimeFormat,
          job,
          site,
          timeFormat,
        });
      }
      break;
    default:
      break;
  }
  return displayMetadata;
};

const PendingMutationListItem = ({ pendingMutation }) => {
  const {
    meta: {
      offline: {
        effect: { operation },
      },
    },
  } = pendingMutation;
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const timeFormat = useSelector(selectTimeFormat);
  const group = useSelector((state) =>
    selectGroupById(state, getGroupId(operation)),
  );
  const job = useSelector((state) => selectJobById(state, getJobId(operation)));
  const site = useSelector((state) =>
    selectSiteById(state, getSiteId({ job, operation })),
  );
  const user = useSelector((state) =>
    selectUserById(state, getUserId(operation)),
  );
  const { description, title } = getOperationDisplayMetadata({
    dateTimeFormat,
    group,
    job,
    operation,
    site,
    timeFormat,
    user,
  });
  return <ListItem description={description} title={title} />;
};

PendingMutationListItem.propTypes = {
  pendingMutation: PropTypes.shape({
    meta: PropTypes.shape({
      offline: PropTypes.shape({
        effect: PropTypes.shape({
          operation: PropTypes.shape({
            operationName: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
};

export default PendingMutationListItem;
