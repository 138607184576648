import { Coordinates, Job, Media } from "../../../types";
import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setJobPending } from "../../../store/jobs/slice";

interface CreateTaskCompletionRecordInput {
  taskId: string;
  timestamp: string;
  coordinates: Coordinates;
  media?: Media;
  dailyActivityReportId?: string;
}

const dispatchOptimisticResponse = (
  {
    taskId,
    timestamp,
    coordinates,
    media,
    dailyActivityReportId,
  }: CreateTaskCompletionRecordInput,
  job: Job
) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setJobPending({
      job: {
        ...job,
        taskCompletionRecords: [
          ...job.taskCompletionRecords.filter(
            (taskCompletionRecord) => taskCompletionRecord.taskId !== taskId
          ),
          {
            taskId,
            record: {
              at: timestamp,
              by: userId,
              coordinates,
            },
            media: media && {
              createdAt: media.createdAt,
              description: media.description,
              id: media.id,
              type: media.type,
            },
            dailyActivityReportId,
          },
        ],
        syncDateTime: timestamp,
      },
      mutation: "createTaskCompletionRecord",
    })
  );
};

const createTaskCompletionRecord = (
  {
    taskId,
    timestamp = new Date().toISOString(),
    coordinates,
    media,
    dailyActivityReportId,
  }: CreateTaskCompletionRecordInput,
  job: Job
) => {
  dispatchOptimisticResponse(
    {
      taskId,
      timestamp,
      coordinates,
      media,
      dailyActivityReportId,
    },
    job
  );
  client.mutate({
    mutation,
    variables: deepCopy({
      jobId: job.id,
      taskId,
      timestamp,
      coordinates,
      media,
      dailyActivityReportId,
    }),
    errorPolicy: "all",
  });
};

export default createTaskCompletionRecord;
