import { useNavigation } from "@react-navigation/native";
import { Divider, Layout, Text } from "@ui-kitten/components";
import * as Device from "expo-device";
import { DeviceType } from "expo-device";
import { truncate } from "lodash/truncate";
import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import uuidv4 from "uuid/v4";

import createJob from "../../../api/functions/createJob";
import navigationRoutes from "../../../constants/navigationRoutes";
import selectSiteById from "../../../store/sites/selectors/selectById";
import selectSites from "../../../store/sites/selectors/selectSites";
import { Assignee } from "../../../types";
import sortSites from "../../../utils/sortSites";
import AssigneesSelect from "../../AssigneesSelect";
import HeaderWithTextAction, {
  HeaderWithTextActionActionProps,
} from "../../buildingBlocks/HeaderWithTextAction";
import { addNotification } from "../../InAppNotifications";
import SitePicker from "../../SitePicker";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

const styles = StyleSheet.create({
  header: {
    padding: 16,
  },
  addButtonContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  button: {
    justifyContent: "flex-start",
  },
  divider: {
    marginStart: 16,
  },
  horizontalMargins: {
    marginEnd: 16,
    marginStart: 16,
  },
  horizontalSeparator: {
    width: 8,
  },
  listAccessory: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginEnd: 8,
    marginStart: 8,
  },
  listItemAccessoryButton: {
    marginEnd: 8,
    marginStart: 8,
  },
  root: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  sectionSeparator: {
    height: 24,
  },
  sectionTitle: {
    paddingEnd: 16,
    paddingStart: 16,
    paddingBottom: 4,
  },
  separator: {
    height: 16,
  },
});

interface DispatchUserJobStepperModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  onSuccess: () => void;
}

const DispatchUserJobStepperModal = ({
  isVisible,
  onClose,
  onSuccess,
}: DispatchUserJobStepperModalProps) => {
  const sites = useSelector(selectSites);
  const [deviceType, setDeviceType] = useState<Device.DeviceType>(
    DeviceType.UNKNOWN
  );
  const [step, setStep] = useState<"pickAssignees" | "pickSite">("pickSite");
  const [siteId, setSiteId] = useState<string>("");
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const site = useSelector((state) => selectSiteById(state, siteId));
  const navigation = useNavigation();

  useEffect(() => {
    if (isVisible) {
      setSiteId("");
      setAssignees([]);
      setStep("pickSite");
      (async () => {
        try {
          setDeviceType(await Device.getDeviceTypeAsync());
        } catch (error) {
          // Do nothing
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const getNextAction = (): HeaderWithTextActionActionProps => {
    if (step === "pickAssignees") {
      return {
        "data-test": "submitButtonInDispatchJobModal",
        disabled: assignees.length === 0,
        text: "Dispatch",
        onPress: () => {
          const input = {
            id: uuidv4(),
            siteId,
            assignedTo: assignees,
            tasks: [],
            startDateTime: new Date().toISOString(),
            endDateTime: null,
            rrule: null,
            immediate: true,
          };
          createJob(input);
          onSuccess();
          addNotification({
            status: "success",
            title: "Success",
            message: `User${
              assignees.length === 1 ? "" : "s"
            } has been dispatched.`,
          });
          navigation.navigate(navigationRoutes.JobDetails, { jobId: input.id });
        },
      };
    }
    return undefined;
  };
  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          navigation={
            step === "pickSite"
              ? { icon: "close-outline", onPress: () => onClose() }
              : { icon: "arrow-back", onPress: () => setStep("pickSite") }
          }
          title={
            deviceType !== DeviceType.PHONE
              ? "Dispatch user to location"
              : truncate("Dispatch user to location", { length: 20 })
          }
          action={getNextAction()}
        />
        <Divider />
        <Layout level="2" style={styles.root}>
          {step === "pickSite" && (
            <>
              <Text style={styles.header} category="h5">
                What location should the user go to?
              </Text>
              <Divider />
              <SitePicker
                data-test="sitePickerInDispatchJobModal"
                onSelect={(id) => {
                  setSiteId(id);
                  setStep("pickAssignees");
                }}
                selected={siteId}
                siteIds={sites.ids
                  .filter((id) => !sites.entities[id].isArchived)
                  .sort((a, b) =>
                    sortSites(sites.entities[a], sites.entities[b])
                  )}
              />
            </>
          )}
          {step === "pickAssignees" && (
            <>
              <Text style={styles.header} category="h5">
                Who do you want to dispatch?
              </Text>
              <Divider />
              <AssigneesSelect
                data-test="assigneesSelectInDispatchJobModal"
                selected={assignees}
                onChange={setAssignees}
                destination={
                  site && {
                    lat: site.coordinates.lat,
                    lng: site.coordinates.lng,
                  }
                }
              />
            </>
          )}
        </Layout>
      </FullScreenModal>
    </>
  );
};

export default DispatchUserJobStepperModal;
