import { createSelector } from "@reduxjs/toolkit";

import selectSettings from "../selectSettings";

const selectTime = createSelector(
  [selectSettings],
  (settings) => settings.time
);

export default selectTime;
