/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";

import BaseListItem from "../Base";

const VehicleListItem = ({ index, vehicle, ...props }) => {
  let title = "";
  if (vehicle.year) {
    title = title.concat(vehicle.year);
  }
  if (vehicle.make) {
    if (title) {
      title = title.concat(` ${vehicle.make}`);
    } else {
      title = title.concat(vehicle.make);
    }
  }
  if (vehicle.model) {
    if (title) {
      title = title.concat(` ${vehicle.model}`);
    } else {
      title = title.concat(vehicle.model);
    }
  }
  return <BaseListItem {...props} title={title || `Vehicle #${index + 1}`} />;
};

VehicleListItem.propTypes = {
  index: PropTypes.number.isRequired,
  vehicle: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
};

export default VehicleListItem;
