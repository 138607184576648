import {
  createAction,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import { InboxItem, EntityState, OptimisticInboxItem } from "../../../types";

const adapter = createEntityAdapter<InboxItem>();
const optimisticAdapter = createEntityAdapter<OptimisticInboxItem>();

export interface State extends EntityState<InboxItem> {
  optimistic: EntityState<OptimisticInboxItem>;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addInboxItem = ({
  inboxItem,
  state,
}: {
  inboxItem: InboxItem;
  state: State;
}) => {
  const existingInboxItem = state.entities[inboxItem.id];
  if (
    existingInboxItem === undefined ||
    inboxItem.syncDateTime >= existingInboxItem.syncDateTime
  ) {
    adapter.upsertOne(state, inboxItem);
  }
};

const name = "inbox";

export const setInboxItemAsync = {
  fulfilled: createAction<{
    inboxItem: InboxItem;
    mutation: string;
  }>(`${name}/setInboxItem/fulfilled`),
  pending: createAction<{
    inboxItem: InboxItem;
    mutation: string;
  }>(`${name}/setInboxItem/pending`),
  rejected: createAction(
    `${name}/setInboxItem/rejected`,
    (payload: { id: string; mutation: string }, error: Error) => ({
      error,
      payload,
    })
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setInboxItem: (state, action: PayloadAction<{ inboxItem: InboxItem }>) => {
      const { inboxItem } = action.payload;
      addInboxItem({ inboxItem, state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setInboxItemAsync.pending, (state, action) => {
      const { inboxItem, mutation } = action.payload;
      const existingOptimisticInboxItem =
        state.optimistic.entities[inboxItem.id];
      if (
        existingOptimisticInboxItem === undefined ||
        inboxItem.syncDateTime >= existingOptimisticInboxItem.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...inboxItem,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(
      setInboxItemAsync.fulfilled,
      (
        state,
        action: PayloadAction<{ inboxItem: InboxItem; mutation: string }>
      ) => {
        const { inboxItem, mutation } = action.payload;
        addInboxItem({ inboxItem, state });
        const existingOptimisticInboxItem =
          state.optimistic.entities[inboxItem.id];
        if (
          existingOptimisticInboxItem !== undefined &&
          mutation === existingOptimisticInboxItem.mutation
        ) {
          optimisticAdapter.removeOne(state.optimistic, inboxItem.id);
        }
      }
    );
    builder.addCase(setInboxItemAsync.rejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticInboxItem = state.optimistic.entities[id];
      if (
        existingOptimisticInboxItem !== undefined &&
        mutation === existingOptimisticInboxItem.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { inboxItems, lastSync } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, inboxItems);
      } else {
        inboxItems.forEach((inboxItem) => addInboxItem({ inboxItem, state }));
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const { setInboxItem } = actions;

export default reducer;
