const sortSites = (a, b) => {
  const nameComparison = a.name.localeCompare(b.name);
  const addressComparison = a.address.localeCompare(b.address);
  const createdAtComparison = a.created.at.localeCompare(b.created.at);
  if (nameComparison !== 0) {
    return nameComparison;
  }
  if (addressComparison !== 0) {
    return addressComparison;
  }
  return createdAtComparison;
};

export default sortSites;
