import { makeStyles, Theme } from "@material-ui/core/styles";
import { Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React, { useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import uuidv4 from "uuid/v4";

import Separator from "../buildingBlocks/Separator";
import HoverActivatedButton from "../HoverActivatedButton";
import { addNotification } from "../InAppNotifications";
import BaseListItem from "../listItems/Base";
import ScheduleJobListItem, {
  height as ScheduleJobListItemHeight,
} from "../listItems/ScheduleJob";
import JobEditingModal, {
  Props as JobEditingModalProps,
} from "../modals/JobEditing";
import queryDateTimeData from "../../api/functions/queryDateTimeData";
import selectGroups from "../../store/groups/selectors/selectGroups";
import selectHistoricalSearch from "../../store/historicalSearch/selectors/selectHistoricalSearch";
import selectScheduleJobs from "../../store/jobs/selectors/selectScheduleJobs";
import selectUsers from "../../store/users/selectors/selectUsers";
import { Timecard } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import sortGroups from "../../utils/sortGroups";
import sortUsers from "../../utils/sortUsers";

dayjs.extend(isSameOrBefore);

const dateKeyFormatTemplate = "YYYY-MM-DD";
const dateDisplayFormatTemplate = "ddd D";
const gridCellHeight = ScheduleJobListItemHeight + 16; // bottomPadding: 4, topPadding: 4
const gridCellWidth = 352;

interface Props {
  onJobPress: (jobId: string) => void;
  refreshing: boolean;
}

const useStyles = makeStyles<Theme, { layoutBackgroundColor: string }>({
  gridColumnHeader: {
    backgroundColor: (props) => props.layoutBackgroundColor,
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  gridRowHeader: {
    backgroundColor: (props) => props.layoutBackgroundColor,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    scrollbarWidth: "none",
    width: gridCellWidth,
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

const styles = StyleSheet.create({
  buttonContainer: {
    height: "100%",
    padding: 8,
  },
  date: {
    textAlign: "center",
  },
  listItemContainer: {
    padding: 8,
  },
  root: {
    flex: 1,
  },
});

const ScheduleGridView = ({ onJobPress, refreshing }: Props) => {
  const theme = useTheme();
  const classes = useStyles({
    layoutBackgroundColor: theme["background-basic-color-1"],
  });
  const groups = useSelector(selectGroups);
  const historicalSearch = useSelector(selectHistoricalSearch);
  const jobs = useSelector(selectScheduleJobs);
  const users = useSelector(selectUsers);

  const [jobEditingModal, setJobEditingModal] = useState<{
    initialValues: Pick<
      JobEditingModalProps["initialValues"],
      "assignedTo" | "endDateTime" | "startDateTime"
    >;
    visible: boolean;
  }>({
    initialValues: {},
    visible: false,
  });
  const [timeCards, setTimeCards] = useState<{
    loading: boolean;
    totalHoursByUser: {
      [userId: string]: number;
    };
  }>({
    loading: false,
    totalHoursByUser: {},
  });

  useEffect(() => {
    if (historicalSearch.queryDateTimeRange) {
      (async () => {
        setTimeCards({
          loading: true,
          totalHoursByUser: {},
        });
        try {
          const results = await queryDateTimeData({
            from: historicalSearch.queryDateTimeRange.startDateTime,
            to: historicalSearch.queryDateTimeRange.endDateTime,
            type: "Timecard",
          });
          const totalHoursByUser = {};
          results.forEach((dateTimeDataResult) => {
            const timeCard = dateTimeDataResult as Timecard;
            const userId = timeCard.started.by;
            if (totalHoursByUser[userId]) {
              totalHoursByUser[userId] += dayjs
                .duration(dayjs(timeCard.quit.at).diff(timeCard.started.at))
                .as("hours");
            } else {
              totalHoursByUser[userId] = dayjs
                .duration(dayjs(timeCard.quit.at).diff(timeCard.started.at))
                .as("hours");
            }
          });
          setTimeCards({
            loading: false,
            totalHoursByUser,
          });
        } catch (e) {
          setTimeCards((prevState) => ({ ...prevState, loading: false }));
          addNotification({
            message: e.message || "An unexpected error occurred",
            status: "danger",
            title: "Error querying for time cards",
          });
        }
      })();
    }
  }, [historicalSearch.queryDateTimeRange]);

  const assigneeFilterEnabled =
    historicalSearch.filters.groupIds.length > 0 ||
    historicalSearch.filters.userIds.length > 0;
  const visibleGroupIds = (assigneeFilterEnabled
    ? historicalSearch.filters.groupIds
    : groups.ids
  )
    .filter((groupId) => !groups.entities[groupId].isDeleted)
    .sort((a, b) => sortGroups(groups.entities[a], groups.entities[b]));
  const visibleUserIds = (assigneeFilterEnabled
    ? historicalSearch.filters.userIds
    : users.ids
  )
    .filter((userId) => !users.entities[userId].isArchived)
    .sort((a, b) => sortUsers(users.entities[a], users.entities[b]));

  if (!historicalSearch.queryDateTimeRange) {
    return null;
  }

  const today = dayjs();

  const queryRangeStartDay = dayjs(
    historicalSearch.queryDateTimeRange.startDateTime
  );
  const queryRangeEndDay = dayjs(
    historicalSearch.queryDateTimeRange.endDateTime
  );

  let unassignedHours = 0;
  const scheduledHoursByAssignee = {};

  const gridCells = []; // Passed into the GridLayout component
  let dayIterator = queryRangeStartDay.clone();
  const dateComponents = [];
  const gridDates = [];
  const indexOfGridDates = {};
  // Compute how many days will be shown based on the query range
  do {
    const formattedDate = dayIterator.format(dateKeyFormatTemplate);
    dateComponents.push(
      <View
        key={formattedDate}
        style={{
          alignItems: "center",
          borderBottomColor: theme["border-basic-color-3"],
          borderBottomWidth: 1,
          borderEndColor: theme["border-basic-color-3"],
          borderEndWidth: 1,
          height: 48,
          justifyContent: "center",
          width: gridCellWidth,
        }}
      >
        <Text
          category="s1"
          status={today.isSame(formattedDate, "day") ? "primary" : "basic"}
        >
          {dayIterator.format("ddd D")}
        </Text>
      </View>
    );
    indexOfGridDates[formattedDate] = gridDates.length;
    gridDates.push(formattedDate);
    dayIterator = dayIterator.add(1, "day");
  } while (dayIterator.isSameOrBefore(queryRangeEndDay, "day"));
  const totalColumnsCount = gridDates.length;

  const unassignedJobsByDate = {};
  let unassignedRowHeight = 1;
  const jobsByAssigneeAndDate = {};
  const rowHeightByAssignee = [...users.ids, ...groups.ids].reduce(
    (accumulator, assigneeId) => ({
      ...accumulator,
      [assigneeId]: 1,
    }),
    {}
  );
  jobs.forEach((job) => {
    const dateKey = dayjs(job.startDateTime).format(dateKeyFormatTemplate);
    if (job.assignedTo.length > 0) {
      job.assignedTo.forEach((assignee) => {
        const assigneeAndDateKey = `${assignee.id}:${dateKey}`;
        if (jobsByAssigneeAndDate[assigneeAndDateKey]) {
          jobsByAssigneeAndDate[assigneeAndDateKey].push(job);
        } else {
          jobsByAssigneeAndDate[assigneeAndDateKey] = [job];
        }
        if (
          jobsByAssigneeAndDate[assigneeAndDateKey].length + 1 >
          rowHeightByAssignee[assignee.id]
        ) {
          rowHeightByAssignee[assignee.id] =
            jobsByAssigneeAndDate[assigneeAndDateKey].length + 1;
        }
        if (scheduledHoursByAssignee[assignee.id]) {
          scheduledHoursByAssignee[assignee.id] += dayjs
            .duration(dayjs(job.endDateTime).diff(job.startDateTime))
            .as("hours");
        } else {
          scheduledHoursByAssignee[assignee.id] = dayjs
            .duration(dayjs(job.endDateTime).diff(job.startDateTime))
            .as("hours");
        }
      });
    } else {
      if (unassignedJobsByDate[dateKey]) {
        unassignedJobsByDate[dateKey].push(job);
      } else {
        unassignedJobsByDate[dateKey] = [job];
      }
      if (unassignedJobsByDate[dateKey].length + 1 > unassignedRowHeight) {
        unassignedRowHeight = unassignedJobsByDate[dateKey].length + 1;
      }
      unassignedHours += dayjs
        .duration(dayjs(job.endDateTime).diff(job.startDateTime))
        .as("hours");
    }
  });
  const assigneeComponents = [];
  let rowsUsed = 0;
  gridDates.forEach((dateKey) => {
    for (let i = 0; i < unassignedRowHeight; i += 1) {
      if (unassignedJobsByDate[dateKey] && unassignedJobsByDate[dateKey][i]) {
        const job = unassignedJobsByDate[dateKey][i];
        gridCells.push(
          <div
            data-grid={{
              h: 1,
              w: 1,
              x: indexOfGridDates[dateKey],
              y: rowsUsed + i,
            }}
            // key={`${dateKey}:${job.id}`}
            key={uuidv4()}
            style={{
              backgroundColor: today.isSame(dateKey, "day")
                ? theme["color-primary-transparent-default"]
                : undefined,
              borderColor: theme["border-basic-color-4"],
              borderBottomStyle: "solid",
              borderBottomWidth: i === unassignedRowHeight - 1 ? 1 : 0,
              borderRightStyle: "solid",
              borderRightWidth: 1,
            }}
          >
            <View style={styles.listItemContainer}>
              <ScheduleJobListItem jobId={job.id} onPress={onJobPress} />
            </View>
          </div>
        );
      } else {
        gridCells.push(
          <div
            data-grid={{
              h: 1,
              w: 1,
              x: indexOfGridDates[dateKey],
              y: rowsUsed + i,
            }}
            // key={`${dateKey}:create:${i}`}
            key={uuidv4()}
            style={{
              backgroundColor: today.isSame(dateKey, "day")
                ? theme["color-primary-transparent-default"]
                : undefined,
              borderColor: theme["border-basic-color-4"],
              borderBottomStyle: "solid",
              borderBottomWidth: i === unassignedRowHeight - 1 ? 1 : 0,
              borderRightStyle: "solid",
              borderRightWidth: 1,
            }}
          >
            <HoverActivatedButton
              buttonContainerStyle={styles.buttonContainer}
              onPress={() => {
                const dateDay = dayjs(dateKey);
                setJobEditingModal({
                  initialValues: {
                    endDateTime: dateDay.hour(15).toISOString(),
                    startDateTime: dateDay.hour(14).toISOString(),
                  },
                  visible: true,
                });
              }}
              style={{ flex: 1 }}
            >{`Create job on ${dayjs(dateKey).format(
              dateDisplayFormatTemplate
            )}`}</HoverActivatedButton>
          </div>
        );
      }
    }
  });
  assigneeComponents.push(
    <View
      key="unassigned"
      style={{
        borderBottomWidth: 1,
        borderColor: theme["border-basic-color-3"],
        borderEndWidth: 1,
        height: unassignedRowHeight * gridCellHeight,
      }}
    >
      <Separator size="small" />
      <BaseListItem
        disabled
        description={`Scheduled hours: ${unassignedHours.toFixed(1)}h`}
        title="Unassigned jobs"
      />
    </View>
  );
  rowsUsed += unassignedRowHeight;
  visibleUserIds.forEach((userId) => {
    const user = users.entities[userId];
    const rowsUsedForAssignee = rowHeightByAssignee[userId];
    gridDates.forEach((formattedDate) => {
      const key = `${userId}:${formattedDate}`;
      for (let i = 0; i < rowsUsedForAssignee; i += 1) {
        if (jobsByAssigneeAndDate[key] && jobsByAssigneeAndDate[key][i]) {
          const job = jobsByAssigneeAndDate[key][i];
          gridCells.push(
            <div
              data-grid={{
                h: 1,
                w: 1,
                x: indexOfGridDates[formattedDate],
                y: rowsUsed + i,
              }}
              // key={`${key}:${job.id}`}
              key={uuidv4()}
              style={{
                backgroundColor: today.isSame(formattedDate, "day")
                  ? theme["color-primary-transparent-default"]
                  : undefined,
                borderColor: theme["border-basic-color-4"],
                borderBottomStyle: "solid",
                borderBottomWidth: i === rowsUsedForAssignee - 1 ? 1 : 0,
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
            >
              <View style={styles.listItemContainer}>
                <ScheduleJobListItem jobId={job.id} onPress={onJobPress} />
              </View>
            </div>
          );
        } else {
          gridCells.push(
            <div
              data-grid={{
                h: 1,
                w: 1,
                x: indexOfGridDates[formattedDate],
                y: rowsUsed + i,
              }}
              // key={`${key}:create:${i}`}
              key={uuidv4()}
              style={{
                backgroundColor: today.isSame(formattedDate, "day")
                  ? theme["color-primary-transparent-default"]
                  : undefined,
                borderColor: theme["border-basic-color-4"],
                borderBottomStyle: "solid",
                borderBottomWidth: i === rowsUsedForAssignee - 1 ? 1 : 0,
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
            >
              <HoverActivatedButton
                buttonContainerStyle={styles.buttonContainer}
                onPress={() => {
                  const dateDay = dayjs(formattedDate);
                  setJobEditingModal({
                    initialValues: {
                      assignedTo: [{ id: userId, type: "User" }],
                      endDateTime: dateDay.hour(15).toISOString(),
                      startDateTime: dateDay.hour(14).toISOString(),
                    },
                    visible: true,
                  });
                }}
                style={{ flex: 1 }}
              >{`Create job for ${getUserFullName(user)} on ${dayjs(
                formattedDate
              ).format(dateDisplayFormatTemplate)}`}</HoverActivatedButton>
            </div>
          );
        }
      }
    });
    assigneeComponents.push(
      <View
        key={userId}
        style={{
          borderBottomWidth: 1,
          borderColor: theme["border-basic-color-3"],
          borderEndWidth: 1,
          height: rowsUsedForAssignee * gridCellHeight,
        }}
      >
        <Separator size="small" />
        <BaseListItem
          accessoryLeft={(imageProps) => <Icon {...imageProps} name="person" />}
          description={() => (
            <View style={{ paddingEnd: 8, paddingStart: 8 }}>
              <Text appearance="hint" category="c1">{`Scheduled hours: ${(
                scheduledHoursByAssignee[userId] || 0
              ).toFixed(1)}h`}</Text>
              <Text appearance="hint" category="c1">{`Clocked in hours: ${
                timeCards.loading
                  ? "loading..."
                  : `${(timeCards.totalHoursByUser[userId] || 0).toFixed(1)}h`
              }`}</Text>
            </View>
          )}
          disabled
          title={getUserFullName(user)}
        />
      </View>
    );
    rowsUsed += rowsUsedForAssignee;
  });
  visibleGroupIds.forEach((groupId) => {
    const group = groups.entities[groupId];
    const rowsUsedForAssignee = rowHeightByAssignee[groupId];
    gridDates.forEach((formattedDate) => {
      const key = `${groupId}:${formattedDate}`;
      for (let i = 0; i < rowsUsedForAssignee; i += 1) {
        if (jobsByAssigneeAndDate[key] && jobsByAssigneeAndDate[key][i]) {
          const job = jobsByAssigneeAndDate[key][i];
          gridCells.push(
            <div
              data-grid={{
                h: 1,
                w: 1,
                x: indexOfGridDates[formattedDate],
                y: rowsUsed + i,
              }}
              // key={`${key}:${job.id}`}
              key={uuidv4()}
              style={{
                backgroundColor: today.isSame(formattedDate, "day")
                  ? theme["color-primary-transparent-default"]
                  : undefined,
                borderColor: theme["border-basic-color-4"],
                borderBottomStyle: "solid",
                borderBottomWidth: i === rowsUsedForAssignee - 1 ? 1 : 0,
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
            >
              <View style={styles.listItemContainer}>
                <ScheduleJobListItem jobId={job.id} onPress={onJobPress} />
              </View>
            </div>
          );
        } else {
          gridCells.push(
            <div
              data-grid={{
                h: 1,
                w: 1,
                x: indexOfGridDates[formattedDate],
                y: rowsUsed + i,
              }}
              // key={`${key}:create:${i}`}
              key={uuidv4()}
              style={{
                backgroundColor: today.isSame(formattedDate, "day")
                  ? theme["color-primary-transparent-default"]
                  : undefined,
                borderColor: theme["border-basic-color-4"],
                borderBottomStyle: "solid",
                borderBottomWidth: i === rowsUsedForAssignee - 1 ? 1 : 0,
                borderRightStyle: "solid",
                borderRightWidth: 1,
              }}
            >
              <HoverActivatedButton
                buttonContainerStyle={styles.buttonContainer}
                onPress={() => {
                  const dateDay = dayjs(formattedDate);
                  setJobEditingModal({
                    initialValues: {
                      assignedTo: [{ id: groupId, type: "Group" }],
                      endDateTime: dateDay.hour(15).toISOString(),
                      startDateTime: dateDay.hour(14).toISOString(),
                    },
                    visible: true,
                  });
                }}
                style={{ flex: 1 }}
              >{`Create job for ${group.name} on ${dayjs(formattedDate).format(
                dateDisplayFormatTemplate
              )}`}</HoverActivatedButton>
            </div>
          );
        }
      }
    });
    assigneeComponents.push(
      <View
        key={groupId}
        style={{
          borderBottomWidth: 1,
          borderColor: theme["border-basic-color-3"],
          borderEndWidth: 1,
          height: rowsUsedForAssignee * gridCellHeight,
        }}
      >
        <Separator size="small" />
        <BaseListItem
          accessoryLeft={(imageProps) => <Icon {...imageProps} name="people" />}
          description={`Scheduled hours: ${(
            scheduledHoursByAssignee[groupId] || 0
          ).toFixed(1)}h`}
          disabled
          title={group.name}
        />
      </View>
    );
    rowsUsed += rowsUsedForAssignee;
  });

  return (
    <>
      <ScrollSync>
        <Layout level="2" style={styles.root}>
          <View style={{ flexDirection: "row" }}>
            <Layout
              style={{
                borderBottomColor: theme["border-basic-color-3"],
                borderBottomWidth: 1,
                borderEndColor: theme["border-basic-color-3"],
                borderEndWidth: 1,
                height: 48,
                width: gridCellWidth,
              }}
            />
            <ScrollSyncPane group="horizontal">
              <div className={classes.gridColumnHeader}>{dateComponents}</div>
            </ScrollSyncPane>
          </View>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <ScrollSyncPane group="vertical">
              <div className={classes.gridRowHeader}>{assigneeComponents}</div>
            </ScrollSyncPane>
            <ScrollSyncPane group={["horizontal", "vertical"]}>
              <View style={{ flex: 1, overflow: "scroll" }}>
                <GridLayout
                  className="layout"
                  cols={totalColumnsCount}
                  compactType={null}
                  isDraggable={false}
                  isResizable={false}
                  margin={[0, 0]}
                  rowHeight={gridCellHeight}
                  useCSSTransforms={false}
                  width={totalColumnsCount * gridCellWidth}
                >
                  {!refreshing ? gridCells : []}
                </GridLayout>
              </View>
            </ScrollSyncPane>
          </View>
        </Layout>
      </ScrollSync>
      <JobEditingModal
        initialValues={jobEditingModal.initialValues}
        isVisible={jobEditingModal.visible}
        onClose={() =>
          setJobEditingModal((prevState) => ({
            ...prevState,
            visible: false,
          }))
        }
      />
    </>
  );
};

export default ScheduleGridView;
