import { Divider, Input, Layout, Text } from "@ui-kitten/components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  FlatList,
  Keyboard,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";

import Header from "../buildingBlocks/Header";
import Separator from "../buildingBlocks/Separator";
import Container from "../Container";
import useIosBottomSpace from "../../device/useIosBottomSpace";
import selectEntities from "../../store/users/selectors/selectEntities";
import getUserFullName from "../../utils/getUserFullName";

const styles = StyleSheet.create({
  listContent: {
    paddingTop: 8,
  },
  root: {
    flex: 1,
  },
  searchContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  titleContainer: {
    flexDirection: "row",
  },
});

const UserList = ({
  accessoryRight,
  navigation,
  renderItem,
  showCount,
  title,
  userIds,
}) => {
  const iosBottomSpace = useIosBottomSpace();
  const [search, setSearch] = useState({
    query: "",
    results: [],
  });
  const users = useSelector(selectEntities);
  const runSearch = (text) => {
    const query = text.trim().toLowerCase();
    setSearch({
      query: text,
      results: userIds.filter((userId) => {
        const user = users[userId];
        return user && getUserFullName(user).toLowerCase().includes(query);
      }),
    });
  };
  return (
    <Layout
      onResponderGrant={Keyboard.dismiss}
      onStartShouldSetResponder={() => true}
      style={styles.root}
    >
      <Container>
        <SafeAreaView>
          <Header
            navigation={navigation}
            title={(textProps) =>
              showCount ? (
                <View style={styles.titleContainer}>
                  <Text {...textProps}>{title}</Text>
                  <Separator horizontal size="small" />
                  <Text appearance="hint">
                    {search.query ? search.results.length : userIds.length}
                  </Text>
                </View>
              ) : (
                <Text {...textProps}>{title}</Text>
              )
            }
            accessoryRight={accessoryRight}
          />
        </SafeAreaView>
        <View style={styles.searchContainer}>
          <Input
            data-test="usersListSearchInput"
            value={search.query}
            onChangeText={runSearch}
            clearButtonMode="while-editing"
            placeholder="Search..."
          />
        </View>
      </Container>
      <Divider />
      <FlatList
        data={!search.query ? userIds : search.results}
        keyExtractor={(userId) => userId}
        renderItem={({ index, item, separator }) => (
          <Container>{renderItem({ index, item, separator })}</Container>
        )}
        contentContainerStyle={[
          styles.listContent,
          { paddingBottom: iosBottomSpace + 8 },
        ]}
        keyboardShouldPersistTaps="handled"
      />
    </Layout>
  );
};

UserList.propTypes = {
  accessoryRight: PropTypes.func,
  navigation: PropTypes.shape({
    icon: PropTypes.string,
    onPress: PropTypes.func,
  }).isRequired,
  renderItem: PropTypes.func.isRequired,
  showCount: PropTypes.bool,
  title: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

UserList.defaultProps = {
  accessoryRight: null,
  showCount: false,
};

export default UserList;
