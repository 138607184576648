import { Divider, Input, Layout } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";

import updateProduct from "../../../api/functions/updateProduct";
import { StripeProduct } from "../../../types";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import { addNotification } from "../../InAppNotifications";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface FormValues {
  name: string;
}

interface ProductEditingModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  product: StripeProduct;
  onSuccess: (product: StripeProduct) => void;
}

const Root = styled(Layout)`
  flex: 1;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  margin-left: 16px;
  margin-right: 16px;
`;

const UpdateProductModal = ({
  isVisible,
  onClose,
  onSuccess,
  product,
}: ProductEditingModalProps) => {
  const [saveProductPending, setSaveProductPending] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: product.name,
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      if (values.name) {
        setSaveProductPending(true);
        try {
          const updatedProduct = await updateProduct({
            id: product.id,
            name: values.name,
          });
          addNotification({
            status: "success",
            title: "Product updated",
          });
          onSuccess(updatedProduct);
        } catch (error) {
          addNotification({
            message: error?.message || error,
            status: "danger",
            title: "Update product failed",
          });
        }
        setSaveProductPending(false);
      } else {
        addNotification({
          message: "Name cannot be empty",
          status: "danger",
          title: "Update product failed",
        });
      }
    },
  });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: {
          name: product.name,
        },
      });
    }
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <Root level="2">
        <HeaderWithTextAction
          navigation={{
            icon: "close-outline",
            onPress: onClose,
          }}
          title="Edit product"
        />
        <Divider />
        <ScrollView>
          <Separator size="medium" />
          <StyledCard title="Product information">
            <StyledInput
              clearButtonMode="while-editing"
              label="Name"
              onChangeText={(text) => formik.setFieldValue("name", text)}
              value={formik.values.name}
            />
            <Separator size="medium" />
          </StyledCard>
          <Separator />
        </ScrollView>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                loading={saveProductPending}
                onPress={() => formik.handleSubmit()}
              >
                Save product
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </Root>
    </FullScreenModal>
  );
};

export default UpdateProductModal;
