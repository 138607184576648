import { Layout, Text } from "@ui-kitten/components";
import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import { Company, CompanyFeatures } from "../../types";
import logout from "../../utils/logout";

import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Container from "../Container";
import { addNotification } from "../InAppNotifications";

const getFilteredCompanyFeatures = (
  companyFeatures: CompanyFeatures
): CompanyFeatures => ({
  jobSummaries: companyFeatures.jobSummaries,
  locationTracking: companyFeatures.locationTracking,
  messaging: companyFeatures.messaging,
  payments: companyFeatures.payments,
  reminders: companyFeatures.reminders,
  reports: companyFeatures.reports,
  timeCards: companyFeatures.timeCards,
});

const styles = StyleSheet.create({
  button: {
    alignSelf: "flex-start",
    marginStart: 16,
  },
  root: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
    top: 0,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const CompanyFeaturesChangedGateKeeper = () => {
  const company = useSelector(selectCompany);
  const signedInUser = useSelector(selectUser);

  const [gateTriggered, setGateTriggered] = useState(false);
  const [logoutPending, setLogoutPending] = useState(false);

  const componentMounted = useRef<boolean>();
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const companyFeatures = company?.features;
  const previousCompanyFeatures = usePrevious(company?.features);

  useEffect(() => {
    if (
      companyFeatures &&
      previousCompanyFeatures &&
      !isEqual(
        getFilteredCompanyFeatures(companyFeatures),
        getFilteredCompanyFeatures(previousCompanyFeatures)
      )
    ) {
      setGateTriggered(true);
    }
  }, [companyFeatures]);

  if (!signedInUser || !company) {
    return null;
  }

  const runLogout = async () => {
    setLogoutPending(true);
    try {
      await logout();
    } catch (error) {
      addNotification({ message: error.message, status: "danger" });
    }
    if (componentMounted.current) {
      setLogoutPending(false);
    }
  };

  if (gateTriggered) {
    return (
      <Layout style={styles.root}>
        <SafeAreaView>
          <Container>
            <Separator />
            <Text category="h6" style={styles.text}>
              Usable features in app has been updated. Please restart the app.
            </Text>
            <Separator />
            <Button
              loading={logoutPending}
              onPress={runLogout}
              size="small"
              style={styles.button}
            >
              Logout
            </Button>
            <Separator />
          </Container>
        </SafeAreaView>
      </Layout>
    );
  }
  return null;
};

export default CompanyFeaturesChangedGateKeeper;
