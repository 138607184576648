import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Layout,
  Spinner,
  Text,
  ThemeType,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { RefreshControl, SafeAreaView, ScrollView, View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import retrievePrice from "../../api/functions/retrievePrice";
import updatePrice from "../../api/functions/updatePrice";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import { addNotification } from "../../components/InAppNotifications";
import UpdateProductModal from "../../components/modals/UpdateProduct";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { StackParamList, StripePrice } from "../../types";
import formatCurrency from "../../utils/formatCurrency";

const ArchivedMessageContainer = styled(View)<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme["color-primary-transparent-100"]};
  border: 1px solid
    ${({ theme }) => theme["color-primary-transparent-default-border"]};
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

const EmptyScreenContainer = styled.View`
  align-items: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const UnarchiveButtonContainer = styled(View)`
  flex-direction: row;
`;

const ProductDetails = () => {
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "ProductDetails">
  >();
  const {
    params: { priceId },
  } = useRoute<RouteProp<StackParamList, "ProductDetails">>();
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [price, setPrice] = useState<StripePrice>();

  const [archivePending, setArchivePending] = useState(false);
  const [unarchivePending, setUnarchivePending] = useState(false);

  const [productEditingModalVisible, setProductEditingModalVisible] = useState(
    false
  );

  const [productsStale, setProductsStale] = useState(false);

  const runRetrievePrice = useCallback(async () => {
    try {
      setPrice(await retrievePrice(priceId));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve price failed",
      });
    }
  }, [priceId]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await runRetrievePrice();
      setLoading(false);
    })();
  }, [runRetrievePrice]);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            action={
              price && {
                onPress: () => setProductEditingModalVisible(true),
                text: "Edit",
              }
            }
            navigation={{
              icon: "arrow-back-outline",
              onPress: () =>
                navigation.navigate("Products", { stale: productsStale }),
            }}
            title="Product details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          refreshControl={
            <RefreshControl
              onRefresh={async () => {
                setRefreshing(true);
                await runRetrievePrice();
                setRefreshing(false);
              }}
              refreshing={refreshing}
            />
          }
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              {price ? (
                <>
                  <Separator size="medium" />
                  {!price.active && (
                    <>
                      <ArchivedMessageContainer theme={theme}>
                        <Separator size="medium" />
                        <StyledText category="s1">
                          This product has been archived
                        </StyledText>
                        <StyledText>
                          This product can’t be added to new quotes.
                        </StyledText>
                        <Separator size="medium" />
                        <UnarchiveButtonContainer>
                          <StyledButton
                            loading={unarchivePending}
                            onPress={async () => {
                              setUnarchivePending(true);
                              try {
                                setPrice(
                                  await updatePrice({
                                    active: true,
                                    id: price.id,
                                  })
                                );
                                setProductsStale(true);
                              } catch (error) {
                                addNotification({
                                  message: error?.message || error,
                                  status: "danger",
                                  title: "Update price failed",
                                });
                              }
                              setUnarchivePending(false);
                            }}
                          >
                            Unarchive
                          </StyledButton>
                        </UnarchiveButtonContainer>
                        <Separator size="medium" />
                      </ArchivedMessageContainer>
                      <Separator />
                    </>
                  )}
                  <StyledCard>
                    <Separator size="medium" />
                    <StyledText category="h5">{price.product.name}</StyledText>
                    <StyledText category="s1">{`${formatCurrency(
                      price.unit_amount
                    )} ${price.currency.toUpperCase()}`}</StyledText>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      ID
                    </StyledText>
                    <StyledText>{price.product.id}</StyledText>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      Created
                    </StyledText>
                    <StyledText>
                      {dayjs.unix(price.product.created).format(dateTimeFormat)}
                    </StyledText>
                    <Separator size="medium" />
                    <StyledText appearance="hint" category="c1">
                      Tax-exempt
                    </StyledText>
                    <StyledText>
                      {price.metadata.taxesEnabled !== "false"
                        ? "Disabled"
                        : "Enabled"}
                    </StyledText>
                    <Separator size="medium" />
                  </StyledCard>
                  <Separator size={48} />
                  {price.active && (
                    <>
                      <StyledButton
                        appearance="ghost"
                        loading={archivePending}
                        onPress={async () => {
                          setArchivePending(true);
                          try {
                            setPrice(
                              await updatePrice({
                                active: false,
                                id: price.id,
                              })
                            );
                            setProductsStale(true);
                          } catch (error) {
                            addNotification({
                              message: error?.message || error,
                              status: "danger",
                              title: "Update price failed",
                            });
                          }
                          setArchivePending(false);
                        }}
                        status="basic"
                      >
                        Archive
                      </StyledButton>
                      <Separator size="small" />
                    </>
                  )}
                  <Separator />
                </>
              ) : (
                <EmptyScreenContainer>
                  <Separator />
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Text appearance="hint" category="s1">
                      No results found
                    </Text>
                  )}
                </EmptyScreenContainer>
              )}
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
      {price && (
        <UpdateProductModal
          isVisible={productEditingModalVisible}
          onClose={() => setProductEditingModalVisible(false)}
          onSuccess={(updatedProduct) => {
            setPrice((prevState) => ({
              ...prevState,
              product: updatedProduct,
            }));
            setProductsStale(true);
            setProductEditingModalVisible(false);
          }}
          product={price.product}
        />
      )}
    </>
  );
};

export default ProductDetails;
