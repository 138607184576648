import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import selectCompany from "../../store/company/selectors/selectCompany";

import selectInboxItems from "../../store/inbox/selectors/selectInboxItems/input";
import { Company, EntityState, InboxItem } from "../../types";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

dayjs.extend(isSameOrBefore);

const inboxItemPassesCustomerFilter = ({
  customerId,
  inboxItem,
}: {
  customerId?: string;
  inboxItem: InboxItem;
}) => !customerId || customerId === inboxItem.metadata?.customerId;

const inboxItemPassesUserFilter = ({
  inboxItem,
  userId,
}: {
  inboxItem: InboxItem;
  userId?: string;
}) =>
  !userId ||
  !inboxItem.metadata?.userId ||
  userId === inboxItem.metadata?.userId;

const inboxItemPassesTodayOnlyFilter = ({
  inboxItem,
  showsTodayOnly,
}: {
  inboxItem: InboxItem;
  showsTodayOnly?: boolean;
}) => {
  if (!showsTodayOnly) {
    return true;
  }
  const dateTime =
    inboxItem.type === "Reminder"
      ? inboxItem.metadata.dateTime
      : inboxItem.createdAt;
  return dayjs(dateTime).isSameOrBefore(dayjs(), "day");
};

const filterInboxItemIds = ({
  company,
  customerId,
  inboxItems,
  showsTodayOnly,
  userId,
}: {
  company: Company;
  customerId?: string;
  inboxItems: EntityState<InboxItem>;
  showsTodayOnly?: boolean;
  userId?: string;
}) => {
  const remindersEnabled =
    Boolean(company) && isFeatureEnabled({ company, feature: "reminders" });
  return inboxItems.ids.filter((inboxItemId) => {
    const inboxItem = inboxItems.entities[inboxItemId];
    return (
      remindersEnabled &&
      !inboxItem.completed &&
      inboxItemPassesCustomerFilter({ customerId, inboxItem }) &&
      inboxItemPassesUserFilter({ inboxItem, userId }) &&
      inboxItemPassesTodayOnlyFilter({ inboxItem, showsTodayOnly })
    );
  });
};

const useInboxList = (params?: {
  customerId?: string;
  showsTodayOnly?: boolean;
  userId?: string;
}) => {
  const company = useSelector(selectCompany);
  const inboxItems = useSelector(selectInboxItems);
  const filteredInboxItemIds = useMemo(
    () =>
      filterInboxItemIds({
        company,
        customerId: params?.customerId,
        inboxItems,
        showsTodayOnly: params?.showsTodayOnly,
        userId: params?.userId,
      }).sort((a, b) => {
        const inboxItemA = inboxItems.entities[a];
        const inboxItemB = inboxItems.entities[b];

        const inboxItemADateTime =
          inboxItemA.type === "Reminder"
            ? inboxItemA.metadata.dateTime
            : inboxItemA.createdAt;
        const inboxItemBDateTime =
          inboxItemB.type === "Reminder"
            ? inboxItemB.metadata.dateTime
            : inboxItemB.createdAt;

        return inboxItemADateTime.localeCompare(inboxItemBDateTime);
      }),
    [params?.customerId, inboxItems, params?.showsTodayOnly, params?.userId]
  );
  return { inboxItemIds: filteredInboxItemIds };
};

export default useInboxList;
