import React, { memo } from "react";

import useCompletionRecordUtils from "../../../hooks/useCompletionRecordUtils";
import { ActionItem, ActionItemCompletionRecord } from "../../../types";
import ActionItemListItem, { ActionItemListItemProps } from "../ActionItem";

export interface ActionItemCompletionRecordListItemProps
  extends Pick<ActionItemListItemProps, "style"> {
  actionItem: ActionItem;
  actionItemCompletionRecord: ActionItemCompletionRecord;
  disableThumbnail?: boolean;
  jobId: string;
  onPress?: ({
    actionItem,
    actionItemCompletionRecord,
  }: {
    actionItem: ActionItem;
    actionItemCompletionRecord: ActionItemCompletionRecord;
  }) => void;
  readonly?: boolean;
  reportsLoading?: boolean;
}

const ActionItemCompletionRecordListItem: React.FC<ActionItemCompletionRecordListItemProps> = ({
  actionItem,
  actionItemCompletionRecord,
  disableThumbnail,
  jobId,
  onPress,
  readonly,
  reportsLoading = false,
  style,
}) => {
  const { ListItemProps } = useCompletionRecordUtils({
    checklistItem: actionItem,
    completionRecord: actionItemCompletionRecord,
    disableThumbnail,
    jobId,
    readonly,
    reportsLoading,
  });

  return (
    <ActionItemListItem
      accessoryLeft={ListItemProps.accessoryLeft}
      accessoryRight={ListItemProps.accessoryRight}
      actionItem={actionItem}
      description={ListItemProps.description}
      onPress={() => onPress({ actionItem, actionItemCompletionRecord })}
      style={style}
      title={ListItemProps.title}
    />
  );
};

export default memo(ActionItemCompletionRecordListItem);
