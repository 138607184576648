import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { Coordinates, DistanceMatrixData } from "../../../types";

interface Data {
  getDistanceMatrix: DistanceMatrixData;
}

const getDistanceMatrix = async ({
  origins,
  destinations,
}: {
  origins: Coordinates[];
  destinations: Coordinates[];
}) => {
  try {
    const { data } = await client.query<Data>({
      query,
      variables: {
        origins,
        destinations,
      },
    });
    return data.getDistanceMatrix;
  } catch (error) {
    throw generateErrorMessage("getDistanceMatrix", error);
  }
};

export default getDistanceMatrix;
