import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Layout,
  ListItem,
  Text,
} from "@ui-kitten/components";
import moment from "moment-timezone";
import momentDurationFormatSetup from "moment-duration-format";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import adminDeleteTimecard from "../../api/functions/adminDeleteTimecard";
import { SectionItem } from "../../components/buildingBlocks";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import LevelTwoListItem from "../../components/listItems/LevelTwo";
import StaticMapView from "../../components/map/StaticMapView";
import Marker from "../../components/markers/Marker";
import AlertModal from "../../components/modals/Alert";
import TimeCardEditingModal from "../../components/modals/TimeCardEditing";
import UserMapCircle from "../../components/UserMapCircle";
import UserRecord from "../../components/UserRecord";
import companyUserRolesExtended from "../../constants/companyUserRolesExtended";
import useFormatDate from "../../hooks/useFormatDate";
import selectTimeCardById from "../../store/timeCards/selectors/selectTimeCardById";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import selectUserById from "../../store/users/selectors/selectUserById";
import { StackParamList } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import getDeltaFromZoom from "../../utils/getDeltaFromZoom";

momentDurationFormatSetup(moment);

const zoom = 17;
const delta = getDeltaFromZoom(zoom);

const styles = StyleSheet.create({
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  content: {
    flex: 1,
  },
  listAccessory: {
    marginEnd: 8,
    marginStart: 8,
  },
  mapContainer: {
    borderRadius: 8,
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  root: {
    flex: 1,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const TimeCardDetails = () => {
  const insets = useSafeAreaInsets();
  const { formatDateTime } = useFormatDate();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "TimeCardDetails">
  >();
  const {
    params: { timeCardId },
  } = useRoute<RouteProp<StackParamList, "TimeCardDetails">>();
  const timeCard = useSelector((state) =>
    selectTimeCardById(state, timeCardId)
  );
  const userId = timeCard?.started.by;
  const user = useSelector((state) => selectUserById(state, userId));
  const signedInUserRole = useSelector(selectSignedInUserRole);

  const [timeCardEditingVisible, setTimeCardEditingVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(
    false
  );

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              action={{
                loading: false,
                onPress: () => setTimeCardEditingVisible(true),
                text: "Edit",
              }}
              isActionVisible={
                signedInUserRole !== companyUserRolesExtended.EMPLOYEE.value
              }
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="Time card details"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={{ paddingBottom: insets.bottom }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            {timeCard && (
              <Container>
                <Layout>
                  <Separator size="medium" />
                  <Text appearance="hint" category="label" style={styles.text}>
                    {getUserFullName(user).toUpperCase()}
                  </Text>
                  <Text category="h6" style={styles.text}>
                    {`${moment
                      .duration(
                        moment(timeCard.quit.at).diff(timeCard.started.at)
                      )
                      .as("hours")
                      .toFixed(1)} hours`}
                  </Text>
                  <Separator size="medium" />
                  <Divider />
                  <Separator size="small" />
                  <LevelTwoListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="person-outline" />
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="arrow-ios-forward-outline" />
                    )}
                    onPress={() =>
                      navigation.navigate("UserDetails", {
                        userId: timeCard.started.by,
                      })
                    }
                    style={styles.button}
                    title="User details"
                  />
                  <Separator size="small" />
                </Layout>
                <Divider />
                <Separator />
                <SectionItem title="STARTED">
                  <ListItem title={formatDateTime(timeCard.started.at)} />
                  <Layout level="2" style={styles.mapContainer}>
                    <StaticMapView
                      region={{
                        latitude: timeCard.started.coordinates.lat,
                        latitudeDelta: delta,
                        longitude: timeCard.started.coordinates.lng,
                        longitudeDelta: delta,
                      }}
                    >
                      {timeCard.started.coordinates.accuracy && (
                        <UserMapCircle
                          center={{
                            latitude: timeCard.started.coordinates.lat,
                            longitude: timeCard.started.coordinates.lng,
                          }}
                          radius={timeCard.started.coordinates.accuracy}
                        />
                      )}
                      <Marker
                        coordinate={{
                          latitude: timeCard.started.coordinates.lat,
                          longitude: timeCard.started.coordinates.lng,
                        }}
                      />
                    </StaticMapView>
                  </Layout>
                </SectionItem>
                <Separator />
                <SectionItem title="QUIT">
                  <ListItem title={formatDateTime(timeCard.quit.at)} />
                  <Layout level="2" style={styles.mapContainer}>
                    <StaticMapView
                      region={{
                        latitude: timeCard.quit.coordinates.lat,
                        latitudeDelta: delta,
                        longitude: timeCard.quit.coordinates.lng,
                        longitudeDelta: delta,
                      }}
                    >
                      {timeCard.quit.coordinates.accuracy && (
                        <UserMapCircle
                          center={{
                            latitude: timeCard.quit.coordinates.lat,
                            longitude: timeCard.quit.coordinates.lng,
                          }}
                          radius={timeCard.quit.coordinates.accuracy}
                        />
                      )}
                      <Marker
                        coordinate={{
                          latitude: timeCard.quit.coordinates.lat,
                          longitude: timeCard.quit.coordinates.lng,
                        }}
                      />
                    </StaticMapView>
                  </Layout>
                </SectionItem>
                {signedInUserRole !==
                  companyUserRolesExtended.EMPLOYEE.value && (
                  <>
                    <Separator />
                    <SectionItem status="danger" title="DANGER ZONE">
                      <ListItem
                        accessoryRight={() => (
                          <View style={styles.listAccessory}>
                            <Button
                              data-test="deleteButtonInTimeCardDetails"
                              onPress={() => setDeleteConfirmationVisible(true)}
                              size="small"
                              status="danger"
                            >
                              Delete
                            </Button>
                          </View>
                        )}
                        description="Once you delete a time card there is no going back."
                        disabled
                        title="Delete this time card"
                      />
                    </SectionItem>
                  </>
                )}
                <Separator />
                <UserRecord
                  label="Created:"
                  style={styles.text}
                  userRecord={timeCard.created}
                />
                <Separator size="small" />
                <UserRecord
                  label="Updated:"
                  style={styles.text}
                  userRecord={timeCard.updated}
                />
                <Separator />
              </Container>
            )}
          </ScrollView>
        </Layout>
      </Layout>
      <TimeCardEditingModal
        isVisible={timeCardEditingVisible}
        onClose={() => setTimeCardEditingVisible(false)}
        timeCard={timeCard}
      />
      <AlertModal
        confirmText="Delete"
        isVisible={deleteConfirmationVisible}
        message="Delete this time card?"
        onCancel={() => null}
        onClose={() => setDeleteConfirmationVisible(false)}
        onConfirm={() => {
          adminDeleteTimecard(timeCard);
          addNotification({
            status: "success",
            title: "Time card deleted",
          });
          navigation.goBack();
        }}
      />
    </>
  );
};

export default TimeCardDetails;
