import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Icon,
  Layout,
  Spinner,
  Text,
  ThemeType,
  useTheme,
} from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/native";

import createStripeAccountLink from "../../api/functions/createStripeAccountLink";
import listInvoices from "../../api/functions/listInvoices";
import retrieveStripeAccount from "../../api/functions/retrieveStripeAccount";
import Button from "../../components/buildingBlocks/Button";
import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import IconButton from "../../components/IconButton";
import { addNotification } from "../../components/InAppNotifications";
import ListItem from "../../components/listItems/Base";
import InvoiceListItem from "../../components/listItems/Invoice";
import LevelTwoListItem from "../../components/listItems/LevelTwo";
import StripeCustomerListItem from "../../components/listItems/StripeCustomer";
import StripeCustomerSelectModal from "../../components/modals/StripeCustomerSelect";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectInvoicesStale from "../../store/payments/selectors/selectInvoicesStale";
import { setInvoicesStale } from "../../store/payments/slice";
import { StackParamList, StripeAccount, StripeInvoice } from "../../types";
import isCustomersEnabled from "../../utils/isCustomersEnabled";

const ButtonContainer = styled.View`
  flex-direction: row;
  padding: 0 16px;
`;

const FlatListContainer = styled.View`
  flex: 1;
`;

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const PaymentsDisabledMessageContainer = styled(View)<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme["color-primary-transparent-100"]};
  border: 1px solid
    ${({ theme }) => theme["color-primary-transparent-default-border"]};
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const Row = styled.View`
  align-items: center;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  border-radius: 100px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledStripeButton = styled(Button)`
  margin: 0 16px;
`;

const StyledStripeButtonContainer = styled.View`
  flex-direction: row;
`;

const StyledStripeCustomerListItem = styled(StripeCustomerListItem)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const styles = StyleSheet.create({
  scrollViewContentContainerStyle: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  flatListContentContainerStyle: {
    marginEnd: "auto",
    marginStart: "auto",
    maxWidth: 960,
    width: "100%",
  },
});

const Invoices = () => {
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Invoices">
  >();
  const { params } = useRoute<RouteProp<StackParamList, "Invoices">>();
  const customerId = params?.customerId;
  const theme = useTheme();

  const company = useSelector(selectCompany);
  const stale = useSelector(selectInvoicesStale);

  const [stripeAccount, setStripeAccount] = useState<StripeAccount>();

  const [listInvoicesParams, setListInvoicesParams] = useState<{
    customer?: string;
    status?: "draft" | "open" | "paid";
  }>({
    customer: customerId,
  });

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [invoices, setInvoices] = useState<{
    data: Array<StripeInvoice>;
    has_more: boolean;
  }>();

  const [launchStripePending, setLaunchStripePending] = useState(false);

  const [
    stripeCustomerSelectModalVisible,
    setStripeCustomerSelectModalVisible,
  ] = useState(false);

  const runRetrieveStripeAccount = useCallback(async () => {
    try {
      setStripeAccount(await retrieveStripeAccount());
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "Retrieve Stripe account failed",
      });
    }
  }, []);
  const runListInvoices = useCallback(async () => {
    try {
      setInvoices(
        await listInvoices({
          customer: listInvoicesParams.customer,
          status: listInvoicesParams.status,
        })
      );
      dispatch(setInvoicesStale({ invoicesStale: false }));
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: "List invoices failed",
      });
    }
  }, [listInvoicesParams]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setInvoices(null);
      await runRetrieveStripeAccount();
      await runListInvoices();
      setLoading(false);
    })();
  }, [runListInvoices]);
  useEffect(() => {
    if (isFocused && stale) {
      (async () => {
        setLoading(true);
        setInvoices(null);
        await runRetrieveStripeAccount();
        await runListInvoices();
        setLoading(false);
      })();
    }
  }, [isFocused, stale]);
  useEffect(() => {
    if (customerId) {
      setListInvoicesParams((prevState) => ({
        ...prevState,
        customer: customerId,
      }));
    }
  }, [customerId]);

  const fetchingNextPage = useRef(false);

  const customersEnabled = isCustomersEnabled(company);
  const invoicesEnabled = Boolean(stripeAccount?.details_submitted);

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <Header
            navigation={{ icon: "arrow-back", onPress: navigation.goBack }}
            title="Payments"
          />
        </HeaderContainer>
        <Divider />
        {customersEnabled ? (
          <>
            <FlatListContainer>
              <FlatList
                contentContainerStyle={[
                  styles.flatListContentContainerStyle,
                  {
                    paddingBottom: insets.bottom + 8,
                    paddingEnd: insets.right,
                    paddingStart: insets.left,
                  },
                ]}
                data={invoicesEnabled ? invoices?.data : []}
                keyExtractor={(item) => item.id}
                ItemSeparatorComponent={() => (
                  <Layout>
                    <StyledDivider />
                  </Layout>
                )}
                ListEmptyComponent={
                  <ListEmptyContainer>
                    <Separator />
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Text appearance="hint" category="s1">
                        No results found
                      </Text>
                    )}
                  </ListEmptyContainer>
                }
                ListHeaderComponent={
                  <>
                    <Separator size="small" />
                    {stripeAccount && !stripeAccount.details_submitted && (
                      <>
                        <PaymentsDisabledMessageContainer theme={theme}>
                          <Separator size="medium" />
                          <StyledText category="h6">Enable payments</StyledText>
                          <Separator size="medium" />
                          <StyledText>
                            Talarium uses Stripe to handle payments and to keep
                            your personal bank and details secure.
                          </StyledText>
                          <Separator size="small" />
                          <StyledText>
                            Press{" "}
                            <Text style={{ fontWeight: "bold" }}>
                              Launch Stripe
                            </Text>{" "}
                            to setup your payments on Stripe.
                          </StyledText>
                          <Separator size="medium" />
                          <StyledStripeButtonContainer>
                            <StyledStripeButton
                              loading={launchStripePending}
                              onPress={async () => {
                                setLaunchStripePending(true);
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    (await createStripeAccountLink()).url
                                  );
                                  setLoading(true);
                                  setStripeAccount(null);
                                  await runRetrieveStripeAccount();
                                  setLoading(false);
                                } catch (error) {
                                  addNotification({
                                    message: error?.message || error,
                                    status: "danger",
                                    title: "Create Stripe account link failed",
                                  });
                                }
                                setLaunchStripePending(false);
                              }}
                            >
                              Launch Stripe
                            </StyledStripeButton>
                          </StyledStripeButtonContainer>
                          <Separator size="medium" />
                        </PaymentsDisabledMessageContainer>
                        <Separator size="medium" />
                      </>
                    )}
                    <LevelTwoListItem
                      accessoryRight={(imageProps) => (
                        <Icon {...imageProps} name="arrow-ios-forward" />
                      )}
                      onPress={() => navigation.navigate("Quotes")}
                      title="Quotes"
                    />
                    <LevelTwoListItem
                      accessoryRight={(imageProps) => (
                        <Icon {...imageProps} name="arrow-ios-forward" />
                      )}
                      onPress={() => navigation.navigate("Products")}
                      title="Products"
                    />
                    <Separator size="medium" />
                    <ScrollView
                      contentContainerStyle={
                        styles.scrollViewContentContainerStyle
                      }
                      horizontal
                    >
                      <StyledButton
                        appearance={
                          !listInvoicesParams.status ? "filled" : "outline"
                        }
                        onPress={() =>
                          setListInvoicesParams((prevState) => ({
                            ...prevState,
                            status: null,
                          }))
                        }
                        status="basic"
                      >
                        All invoices
                      </StyledButton>
                      <Separator horizontal size="small" />
                      <StyledButton
                        appearance={
                          listInvoicesParams.status === "draft"
                            ? "filled"
                            : "outline"
                        }
                        onPress={() =>
                          setListInvoicesParams((prevState) => ({
                            ...prevState,
                            status: "draft",
                          }))
                        }
                        status="basic"
                      >
                        Draft
                      </StyledButton>
                      <Separator horizontal size="small" />
                      <StyledButton
                        appearance={
                          listInvoicesParams.status === "open"
                            ? "filled"
                            : "outline"
                        }
                        onPress={() =>
                          setListInvoicesParams((prevState) => ({
                            ...prevState,
                            status: "open",
                          }))
                        }
                        status="basic"
                      >
                        Outstanding
                      </StyledButton>
                      <Separator horizontal size="small" />
                      <StyledButton
                        appearance={
                          listInvoicesParams.status === "paid"
                            ? "filled"
                            : "outline"
                        }
                        onPress={() =>
                          setListInvoicesParams((prevState) => ({
                            ...prevState,
                            status: "paid",
                          }))
                        }
                        status="basic"
                      >
                        Paid
                      </StyledButton>
                    </ScrollView>
                    <Separator size="small" />
                    {listInvoicesParams.customer ? (
                      <Row>
                        <View style={{ flex: 1 }}>
                          <StyledStripeCustomerListItem
                            accessoryLeft={(imageProps) => (
                              <Icon {...imageProps} name="person" />
                            )}
                            onPress={() =>
                              setStripeCustomerSelectModalVisible(true)
                            }
                            stripeCustomer={listInvoicesParams.customer}
                          />
                        </View>
                        <IconButton
                          appearance="ghost"
                          name="close"
                          onPress={() =>
                            setListInvoicesParams((prevState) => ({
                              ...prevState,
                              customer: null,
                            }))
                          }
                          status="basic"
                        />
                        <Separator horizontal size="medium" />
                      </Row>
                    ) : (
                      <ListItem
                        accessoryLeft={(imageProps) => (
                          <Icon {...imageProps} name="person" />
                        )}
                        onPress={() =>
                          setStripeCustomerSelectModalVisible(true)
                        }
                        style={{ marginEnd: 16, marginStart: 16 }}
                        title="Filter by customer"
                      />
                    )}
                    <Separator size="medium" />
                  </>
                }
                onEndReached={async () => {
                  if (invoices.has_more && !fetchingNextPage.current) {
                    fetchingNextPage.current = true;
                    try {
                      const listInvoicesResult = await listInvoices({
                        customer: listInvoicesParams.customer,
                        starting_after:
                          invoices.data[invoices.data.length - 1].id,
                        status: listInvoicesParams.status,
                      });
                      setInvoices((prevState) => ({
                        data: prevState.data.concat(listInvoicesResult.data),
                        has_more: listInvoicesResult.has_more,
                      }));
                    } catch (error) {
                      addNotification({
                        message: error?.message || error,
                        status: "danger",
                        title: "List quotes failed",
                      });
                    }
                    fetchingNextPage.current = false;
                  }
                }}
                onRefresh={async () => {
                  setRefreshing(true);
                  await runRetrieveStripeAccount();
                  await runListInvoices();
                  setRefreshing(false);
                }}
                refreshing={refreshing}
                renderItem={({ item }) => (
                  <Layout>
                    <InvoiceListItem
                      invoice={item}
                      onPress={(invoice) =>
                        navigation.navigate("InvoiceDetails", {
                          invoiceId: invoice.id,
                        })
                      }
                    />
                  </Layout>
                )}
              />
            </FlatListContainer>
          </>
        ) : (
          <Container>
            <Separator />
            <StyledText>
              In order to utilize payments, customers must be enabled.
            </StyledText>
            <Separator size="medium" />
            <ButtonContainer>
              <Button onPress={() => navigation.navigate("Settings")}>
                Settings
              </Button>
            </ButtonContainer>
            <Separator />
          </Container>
        )}
      </Root>
      <StripeCustomerSelectModal
        headerTitle="Filter quotes by customer"
        isVisible={stripeCustomerSelectModalVisible}
        onClose={() => setStripeCustomerSelectModalVisible(false)}
        onSubmit={(customer) => {
          setListInvoicesParams((prevState) => ({
            ...prevState,
            customer: customer.stripeCustomerId,
          }));
          setStripeCustomerSelectModalVisible(false);
        }}
      />
    </>
  );
};

export default Invoices;
