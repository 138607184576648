import gql from "graphql-tag";

const createStripeAccountLink = gql`
  mutation createStripeAccountLink {
    createStripeAccountLink {
      expires_at
      url
      created
    }
  }
`;

export default createStripeAccountLink;
