import mutation from "./graphql";
import client from "../../client";

const adminSetCompanyUserPassword = ({ id, password }) => {
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      id,
      password,
    },
  });
};

export default adminSetCompanyUserPassword;
