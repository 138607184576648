import {
  Button,
  Divider,
  Icon,
  Input,
  Layout,
  ListItem,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { FormikProps } from "formik";

import AlertModal from "../../Alert";
import HeaderWithTextAction from "../../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../../buildingBlocks/Separator";
import SectionItem from "../../../SectionItem";
import sortUsers from "../../../../utils/sortUsers";
import useAndroidBackHandler from "../../../../device/useAndroidBackHandler";
import { CompanyUser, EntityState } from "../../../../types";

const styles = StyleSheet.create({
  buttonContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  viewPager: {
    flex: 1,
  },
  page: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  list: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  form: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  separator: {
    height: 16,
  },
  sectionSeparator: {
    height: 24,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  searchInput: {
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
    marginTop: 8,
  },
  deleteButton: {
    marginEnd: 8,
    marginStart: 8,
  },
});

interface GroupMainScreenProps {
  title: string;
  submitText: string;
  formik: FormikProps<{ members: string[]; name: string }>;
  users: EntityState<CompanyUser>;
  onClose: () => void;
  onAddUsersPress: () => void;
  visible: boolean;
}

const MainScreen = ({
  title,
  submitText,
  formik,
  users,
  onClose,
  onAddUsersPress,
  visible,
}: GroupMainScreenProps) => {
  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(
    false
  );
  const sortedMembers = [...formik.values.members].sort((a, b) => {
    const userA = users.entities[a];
    const userB = users.entities[b];
    return sortUsers(userA, userB);
  });
  const handleClose = () => {
    if (formik.dirty) {
      setCloseConfirmationVisible(true);
    } else {
      onClose();
    }
  };
  useAndroidBackHandler({ enabled: visible, onBackPress: handleClose });
  return (
    <>
      <HeaderWithTextAction
        navigation={{ icon: "close-outline", onPress: handleClose }}
        title={title}
        action={{
          loading: false,
          // onPress: formik.dirty ? formik.handleSubmit : () => onClose(),
          onPress: formik.handleSubmit,
          text: submitText,
          "data-test": "submitGroupEditButton",
        }}
      />
      <Divider />
      <Layout level="2" style={styles.page}>
        <ScrollView contentContainerStyle={styles.form} style={styles.scroll}>
          <Separator />
          <Divider />
          <Layout style={styles.section}>
            <Input
              data-test="groupNameInput"
              value={formik.values.name}
              onChangeText={formik.handleChange("name")}
              autoCapitalize="words"
              caption={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
              disabled={formik.isSubmitting}
              label="Name"
              onFocus={() => {
                formik.setFieldTouched("name");
              }}
              status={
                formik.touched.name && formik.errors.name ? "danger" : "basic"
              }
              style={styles.input}
            />
          </Layout>
          <Divider />
          <Separator />
          <SectionItem title="USERS">
            <View style={styles.buttonContainer}>
              <Button
                data-test="addUsersToGroupButton"
                accessoryLeft={(imageProps) => (
                  <Icon {...imageProps} name="person-add-outline" />
                )}
                onPress={onAddUsersPress}
                size="small"
              >
                Add users
              </Button>
            </View>
            {formik.values.members.length > 0 && (
              <>
                <Divider />
                <Layout>
                  {sortedMembers.map((userId) => {
                    const user = users.entities[userId];
                    return (
                      <ListItem
                        accessoryRight={() => (
                          <Button
                            data-test={`${user.id}-removeFromGroupButton`}
                            onPress={() =>
                              formik.setFieldValue(
                                "members",
                                formik.values.members.filter(
                                  (id) => id !== user.id
                                )
                              )
                            }
                            size="small"
                            status="danger"
                            style={styles.deleteButton}
                          >
                            Remove
                          </Button>
                        )}
                        description={user.role}
                        disabled
                        key={user.id}
                        title={`${user.firstName} ${user.lastName}`}
                      />
                    );
                  })}
                </Layout>
                <Divider />
              </>
            )}
          </SectionItem>
          <Separator />
        </ScrollView>
      </Layout>
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard"
        isVisible={closeConfirmationVisible}
        message="Discard your changes?"
        onCancel={() => setCloseConfirmationVisible(false)}
        onClose={() => setCloseConfirmationVisible(false)}
        onConfirm={() => {
          setCloseConfirmationVisible(false);
          onClose();
        }}
      />
    </>
  );
};

export default MainScreen;
