import React from "react";

import JobStatusCard, { JobStatusCardProps } from "../JobStatusCard";
import jobStatuses from "../../../constants/jobStatuses";

type FutureStatusJobCardProps = Partial<
  Pick<JobStatusCardProps, "callback" | "dense">
>;

const FutureStatusJobCard = ({
  callback = () => {},
  dense,
}: FutureStatusJobCardProps) => (
  <JobStatusCard
    callback={callback}
    colors={jobStatuses.future.colors}
    dense={dense}
    jobStatusId={jobStatuses.future.id}
    label="Future"
  />
);

export default FutureStatusJobCard;
