import Storage from "@aws-amplify/storage";
import { Button, Icon, Text, useTheme } from "@ui-kitten/components";
import * as ImagePicker from "expo-image-picker";
import * as IntentLauncher from "expo-intent-launcher";
import * as Linking from "expo-linking";
import React, { useState, useEffect } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSelector } from "react-redux";

import SimpleModal from "../Simple";
import resizeImage from "../../../utils/resizeImage";
import Separator from "../../buildingBlocks/Separator";
import AlertModal from "../Alert";
import getMediaMetadata from "../../../utils/getMediaMetadata";
import selectUser from "../../../store/auth/selectors/selectUser";
import { Media } from "../../../types";
import getMedia from "../../../api/functions/getMedia";

const styles = StyleSheet.create({
  uploadContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginHorizontal: 12,
  },
  imageButtons: {
    flex: 1,
    marginHorizontal: 4,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  fakeListItem: {
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    paddingEnd: 16,
    paddingStart: 16,
  },
  input: {
    marginBottom: 8,
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  sectionSeparator: {
    height: 24,
  },
  separator: {
    height: 8,
  },
  updateButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingEnd: 16,
  },
  listAccessory: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginEnd: 8,
    marginStart: 8,
  },
  content: {
    marginBottom: 26,
    marginTop: 20,
  },
  footer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  message: {
    marginEnd: 16,
    marginStart: 16,
  },
  image: {
    width: 280,
    height: 280,
    resizeMode: "contain",
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 16,
  },
  progressBar: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onUpload: (media: Media) => void;
  media?: Media;
  onDelete: () => void;
}

const ImageEditingModal = ({
  isVisible,
  onClose,
  onUpload,
  media,
  onDelete,
}: Props) => {
  const theme = useTheme();
  const authenticatedUser = useSelector(selectUser);
  const [permissionsAlertVisible, setPermissionsAlertVisible] = useState(false);
  const [upload, setUpload] = useState({ error: null, progress: null });
  const [pickedMedia, setPickedMedia] = useState(null);
  const [s3url, setS3url] = useState(null);
  const uploadMedia = async (mediaToUpload) => {
    setUpload({ error: null, progress: 0 });
    try {
      // eslint-disable-next-line
      const response = await fetch(mediaToUpload.uri);
      const blob = await response.blob();
      const { companyId } = authenticatedUser;
      await Storage.put(`${companyId}/${mediaToUpload.id}`, blob, {
        contentType: mediaToUpload.mimeType,
        progressCallback: (progress) => {
          setUpload((prevState) => ({
            ...prevState,
            progress: progress.loaded / progress.total,
          }));
        },
      });
      onUpload({
        id: mediaToUpload.id,
        description: "Company Logo",
        type: mediaToUpload.type,
        createdAt: mediaToUpload.createdAt,
      });
    } catch (error) {
      setUpload({
        error,
        progress: null,
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      setPickedMedia(null);
      setUpload({ error: null, progress: null });
    }
  }, [isVisible]);

  useEffect(() => {
    const getS3Url = async () => {
      const { url } = await getMedia(media.id);
      setS3url(url);
    };
    if (media) {
      getS3Url();
    } else {
      setS3url(null);
    }
  }, [media]);

  const imageUri = pickedMedia?.uri || s3url;

  return (
    <>
      <SimpleModal
        isVisible={isVisible}
        onBackPress={onClose}
        onBackdropPress={() => {}}
      >
        <View onStartShouldSetResponder={() => true}>
          <View style={styles.imageContainer}>
            {imageUri ? (
              <Image source={{ uri: imageUri }} style={styles.image} />
            ) : (
              <Text>Upload an image to brand your Talarium application.</Text>
            )}
          </View>
          <View style={styles.progressBar}>
            {/* @ts-ignore */}
            <ProgressBar
              visible={upload.progress !== null}
              progress={upload.progress}
              color={theme["color-primary-default"]}
            />
          </View>
          <View style={styles.uploadContainer}>
            <Button
              style={styles.imageButtons}
              appearance="ghost"
              accessoryLeft={(imageProps) => (
                <Icon {...imageProps} name="image-2" />
              )}
              onPress={async () => {
                const {
                  granted: mediaLibraryPermissionsGranted,
                } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (mediaLibraryPermissionsGranted) {
                  const imagePickerMedia = await ImagePicker.launchImageLibraryAsync(
                    {
                      exif: true,
                      quality: 0.5,
                    }
                  );
                  if (imagePickerMedia.canceled) return;
                  setPickedMedia(imagePickerMedia);
                } else {
                  setPermissionsAlertVisible(true);
                }
              }}
              disabled={upload.progress !== null}
            >
              Browse
            </Button>
            <Button
              style={styles.imageButtons}
              appearance="ghost"
              status="danger"
              accessoryLeft={(imageProps) => (
                <Icon {...imageProps} name="trash-2" />
              )}
              onPress={onDelete}
              disabled={!media || upload.progress !== null}
            >
              Delete
            </Button>
          </View>
          <View style={styles.footer}>
            <Button
              onPress={async () => {
                const resizedImage = await resizeImage(pickedMedia, {
                  width: 560,
                  height: 280,
                });
                const mediaMetadata = getMediaMetadata(pickedMedia);
                await uploadMedia({ ...resizedImage, ...mediaMetadata });
              }}
              disabled={!pickedMedia || upload.progress !== null}
            >
              Save
            </Button>
            <Separator size="small" />
            <Button onPress={onClose} appearance="ghost">
              Cancel
            </Button>
          </View>
        </View>
      </SimpleModal>
      <AlertModal
        isVisible={permissionsAlertVisible}
        onClose={() => setPermissionsAlertVisible(false)}
        message={`This feature requires camera access. Please enable Camera and ${
          Platform.OS === "android" ? "Storage" : "Photos"
        } for Talarium in your settings.`}
        confirmText="Open settings"
        onConfirm={() => {
          setPermissionsAlertVisible(false);
          if (Platform.OS === "android") {
            IntentLauncher.startActivityAsync(
              IntentLauncher.ActivityAction.APPLICATION_SETTINGS
            );
          } else {
            Linking.openURL("app-settings:");
          }
        }}
        cancelText="Not now"
        onCancel={() => setPermissionsAlertVisible(false)}
      />
    </>
  );
};

ImageEditingModal.defaultProps = {
  media: null,
};

export default ImageEditingModal;
