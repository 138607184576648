/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, PropsWithChildren, useEffect, useRef } from "react";
import { Animated, StyleSheet } from "react-native";
import ReactNativeMapsMapView, { MapViewProps } from "react-native-maps";

import MapView from "../MapView";
import googleMapStyles from "../../../constants/googleMapStyles/index";
import useColorScheme from "../../../device/useColorScheme";

type Props = PropsWithChildren<MapViewProps>;

const styles = StyleSheet.create({
  map: {
    flex: 1,
  },
});

const DefaultMapView = forwardRef<ReactNativeMapsMapView, Props>(
  ({ style, ...props }: Props, ref) => {
    const colorScheme = useColorScheme();
    const fadeAnimation = useRef(new Animated.Value(0)).current;
    useEffect(() => {
      Animated.timing(fadeAnimation, {
        toValue: 1,
        delay: 100,
        duration: 200,
        useNativeDriver: true,
      }).start();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <Animated.View style={[style, { opacity: fadeAnimation }]}>
        <MapView
          {...props}
          customMapStyle={
            colorScheme === "light"
              ? googleMapStyles.light
              : googleMapStyles.dark
          }
          provider="google"
          ref={ref}
          style={styles.map}
          toolbarEnabled={false}
        />
      </Animated.View>
    );
  }
);

export default DefaultMapView;
