import Slider from "@react-native-community/slider";
import dayjs from "dayjs";
import {
  Divider,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import React, { useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FAB, IconButton } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import FullScreenModal from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import DefaultMapView from "../../map/DefaultMapView";
import { addNotification } from "../../InAppNotifications";
import UserDataInputMarker from "../../markers/UserDataInput";
import TimeRangePicker from "../../BasicTimeRangePicker";
import UserMapCircle from "../../UserMapCircle";
import queryLocationHistory from "../../../api/functions/adminQueryLocationHistory";
import useInterval from "../../../hooks/useInterval";
import { Region } from "../../../types";
import getDeltaFromZoom from "../../../utils/getDeltaFromZoom";
import BaseListItem from "../../listItems/Base";

const defaultDelta = getDeltaFromZoom(15);

const styles = StyleSheet.create({
  controlButtonsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  controlsContainer: {
    borderRadius: 8,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  controlsOverlay: {
    alignItems: "center",
    bottom: 0,
    end: 0,
    justifyContent: "center",
    position: "absolute",
    start: 0,
    top: 0,
  },
  divider: {
    marginStart: 16,
  },
  fab: {
    alignSelf: "flex-end",
    elevation: 0,
    marginEnd: 16,
  },
  map: {
    flex: 1,
  },
  mapContainer: {
    flex: 1,
  },
  mapFooter: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
  },
  sliderContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
});

type UserLocationHistoryModalProps = {
  isVisible: boolean;
  onClose: () => void;
  userId: string;
};

const UserLocationHistoryModal = ({
  isVisible,
  onClose,
  userId,
}: UserLocationHistoryModalProps) => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const [initialRegion, setInitialRegion] = useState<Region | null>(null);
  const [times, setTimes] = useState({
    endDateTime: null,
    startDateTime: null,
  });
  const [keepMarkerCentered, setKeepMarkerCentered] = useState(false);
  const [locations, setLocations] = useState({
    data: [],
    loading: false,
    playing: false,
    selectedIndex: 0,
    sliderValue: 0,
  });
  const map = useRef(null);

  const attemptSetCamera = (location) => {
    if (keepMarkerCentered && location && map.current) {
      const {
        coordinate: { latitude, longitude },
      } = location;
      map.current.setCamera({ center: { latitude, longitude } });
    }
  };
  const setSelectedIndex = (index) => {
    setLocations((prevState) => ({
      ...prevState,
      playing: false,
      selectedIndex: index,
      sliderValue: index,
    }));
    attemptSetCamera(locations.data[index]);
  };

  const playProgress = () => {
    if (locations.playing) {
      if (locations.selectedIndex === locations.data.length - 1) {
        setLocations((prevState) => ({
          ...prevState,
          playing: false,
        }));
      } else {
        const nextIndex = locations.selectedIndex + 1;
        setLocations((prevState) => ({
          ...prevState,
          selectedIndex: nextIndex,
          sliderValue: nextIndex,
        }));
      }
    }
  };
  useInterval(playProgress, 500);
  const handleClose = () => {
    onClose();
  };

  const handlePlay = () =>
    setLocations((prevState) => ({ ...prevState, playing: true }));
  const handlePause = () =>
    setLocations((prevState) => ({ ...prevState, playing: false }));
  const handleGoToFront = () => setSelectedIndex(0);
  const handleGoToBack = () => setSelectedIndex(locations.data.length - 1);
  const handleSkipForward = () => setSelectedIndex(locations.selectedIndex + 1);
  const handleSkipBackward = () =>
    setSelectedIndex(locations.selectedIndex - 1);
  const handleTimeRangeChange = async ({ endDateTime, startDateTime }) => {
    setTimes({ endDateTime, startDateTime });
    if (!endDateTime || !startDateTime) {
      return;
    }
    if (endDateTime < startDateTime) {
      addNotification({
        message: "End time must be after start time.",
        status: "danger",
        title: "Error",
      });
      return;
    }
    setLocations({
      data: [],
      loading: true,
      playing: false,
      selectedIndex: 0,
      sliderValue: 0,
    });
    try {
      const response = await queryLocationHistory({
        userId,
        to: endDateTime,
        from: startDateTime,
      });
      const points = response.reverse().map((point) => ({
        ...point,
        coordinate: {
          latitude: point.coordinates.lat,
          longitude: point.coordinates.lng,
          accuracy: point.coordinates.accuracy,
        },
      }));
      setLocations((prevState) => ({ ...prevState, data: points }));
      if (points.length > 0) {
        const { coordinate } = points[0];
        if (!initialRegion) {
          setInitialRegion({
            latitude: coordinate.latitude,
            latitudeDelta: defaultDelta,
            longitude: coordinate.longitude,
            longitudeDelta: defaultDelta,
          });
        } else if (map.current) {
          map.current.animateCamera({
            center: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
          });
        }
      }

      if (points.length === 0) {
        addNotification({
          message: "No location data found. Try a different time.",
          status: "danger",
          title: "Error",
        });
      }
    } catch (error) {
      addNotification({
        message: error?.message,
        status: "danger",
        title: "Error",
      });
    }
    setLocations((prevState) => ({ ...prevState, loading: false }));
  };

  const location = locations.data[locations.selectedIndex];
  const goToFrontDisabled =
    locations.data.length === 0 || locations.selectedIndex === 0;
  const goToBackDisabled =
    locations.data.length === 0 ||
    locations.selectedIndex === locations.data.length - 1;
  const skipForwardDisabled =
    locations.data.length === 0 ||
    locations.selectedIndex === locations.data.length - 1;
  const skipBackwardDisabled =
    locations.data.length === 0 || locations.selectedIndex === 0;
  const playDisabled = locations.data.length === 0;

  return (
    <FullScreenModal onClose={handleClose} isVisible={isVisible}>
      <HeaderWithTextAction
        navigation={{ icon: "close-outline", onPress: handleClose }}
        title="Location History"
      />
      <Divider />
      <Layout level="2" style={styles.mapContainer}>
        {initialRegion && (
          <DefaultMapView
            initialRegion={initialRegion}
            loadingEnabled
            onPanDrag={() => setKeepMarkerCentered(false)}
            ref={map}
            rotateEnabled
            showsCompass
            showsPointsOfInterest={false}
            style={styles.map}
            zoomTapEnabled={false}
          >
            {location && (
              <UserDataInputMarker
                lat={location.coordinate.latitude}
                lng={location.coordinate.longitude}
                coordinate={location.coordinate}
                time={location.created.at}
                id={location.created.by}
                speed={location.speed}
              />
            )}
            {location?.coordinate.accuracy && (
              <UserMapCircle
                center={location.coordinate}
                radius={location.coordinate.accuracy}
              />
            )}
          </DefaultMapView>
        )}
        <View
          style={{
            end: 0,
            position: "absolute",
            start: 0,
            top: 0,
          }}
        >
          <Separator size="small" />
          <Layout
            style={{
              borderRadius: 8,
              marginEnd: 16,
              marginStart: 16,
              overflow: "hidden",
            }}
          >
            <TimeRangePicker
              divider={<Divider style={styles.divider} />}
              onValueChange={handleTimeRangeChange}
              startDateTime={times.startDateTime}
              endDateTime={times.endDateTime}
            />
          </Layout>
          <Separator size="small" />
          <View
            style={{ flexDirection: "row", paddingEnd: 16, paddingStart: 16 }}
          >
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "day")
                    .startOf("minute")
                    .toISOString(),
                };
                handleTimeRangeChange(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past day
                </Text>
              )}
            />
            <Separator horizontal size="small" />
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "week")
                    .startOf("minute")
                    .toISOString(),
                };
                handleTimeRangeChange(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past week
                </Text>
              )}
            />
            <Separator horizontal size="small" />
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "month")
                    .startOf("minute")
                    .toISOString(),
                };
                handleTimeRangeChange(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past month
                </Text>
              )}
            />
          </View>
        </View>
        <View
          pointerEvents="box-none"
          style={[styles.mapFooter, { bottom: insets.bottom }]}
        >
          <FAB
            color={
              theme[
                keepMarkerCentered
                  ? "color-primary-default"
                  : "text-basic-color"
              ]
            }
            icon="map-marker"
            onPress={() => {
              setKeepMarkerCentered(!keepMarkerCentered);
              if (!keepMarkerCentered && map.current) {
                const {
                  coordinate: { latitude, longitude },
                } = location;
                map.current.animateCamera({
                  center: { latitude, longitude },
                });
              }
            }}
            style={[
              styles.fab,
              { backgroundColor: theme["background-basic-color-1"] },
            ]}
            visible={Boolean(initialRegion)}
          />
          <Separator size="small" />
          <Layout style={styles.controlsContainer}>
            <View style={styles.controlButtonsContainer}>
              <IconButton
                icon="skip-previous"
                onPress={handleGoToFront}
                disabled={goToFrontDisabled}
                color={theme["text-basic-color"]}
              />
              <IconButton
                icon="chevron-left"
                onPress={handleSkipBackward}
                disabled={skipBackwardDisabled}
                color={theme["text-basic-color"]}
              />
              {locations.playing ? (
                <IconButton
                  icon="pause"
                  onPress={handlePause}
                  color={theme["text-basic-color"]}
                />
              ) : (
                <IconButton
                  icon="play"
                  onPress={handlePlay}
                  disabled={playDisabled}
                  color={theme["text-basic-color"]}
                />
              )}
              <IconButton
                icon="chevron-right"
                onPress={handleSkipForward}
                disabled={skipForwardDisabled}
                color={theme["text-basic-color"]}
              />
              <IconButton
                icon="skip-next"
                onPress={handleGoToBack}
                disabled={goToBackDisabled}
                color={theme["text-basic-color"]}
              />
            </View>
            <Separator size="small" />
            <View style={styles.sliderContainer}>
              <Slider
                maximumTrackTintColor={theme["background-basic-color-2"]}
                maximumValue={locations.data.length - 1}
                minimumTrackTintColor={theme["color-primary-active"]}
                onSlidingComplete={setSelectedIndex}
                onSlidingStart={() =>
                  setLocations((prevState) => ({
                    ...prevState,
                    playing: false,
                  }))
                }
                onValueChange={(value) => {
                  if (!locations.playing) {
                    setLocations((prevState) => ({
                      ...prevState,
                      selectedIndex: value,
                    }));
                    attemptSetCamera(locations.data[value]);
                  }
                }}
                step={1}
                thumbTintColor={theme["color-primary-default"]}
                value={locations.sliderValue}
              />
            </View>
            <Separator size="small" />
            {locations.loading && (
              <Layout style={styles.controlsOverlay}>
                <Spinner />
              </Layout>
            )}
          </Layout>
          <Separator size={36} />
        </View>
      </Layout>
    </FullScreenModal>
  );
};

export default UserLocationHistoryModal;
