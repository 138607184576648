import { createSelector } from "@reduxjs/toolkit";

import { State } from "../../slice";

const selectTimeCards = (state): State => state.data.timeCards;

const selectOptimisticTimeCards = createSelector(
  [selectTimeCards],
  (timeCards) => {
    const entities = {
      ...timeCards.entities,
      ...timeCards.optimistic.entities,
    };
    return {
      entities,
      ids: Object.keys(entities),
    };
  }
);

export default selectOptimisticTimeCards;
