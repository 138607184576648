import {
  Divider,
  Icon,
  ListItem,
  Text,
  TopNavigation,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import {
  Animated,
  Image,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

import Separator from "../../buildingBlocks/Separator";
import Container from "../../Container";
import MediaActions from "../../MediaActions";
import Portal from "../../Portal";
import getMedia from "../../../api/functions/getMedia";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";
import { Media, MediaOnDevice } from "../../../types";
import useFadeAnimation from "../../../utils/useFadeAnimation";

interface BaseProps {
  isVisible: boolean;
  media?: Media | MediaOnDevice;
  onClose: () => void;
}

interface ReadOnlyProps extends BaseProps {
  editable?: false;
  onDeletePress?: never;
  onDescriptionPress?: never;
}

interface EditableProps extends BaseProps {
  editable: true;
  onDeletePress: (mediaId: string) => void;
  onDescriptionPress: (mediaId: string) => void;
}

export type Props = ReadOnlyProps | EditableProps;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  description: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
    backgroundColor: "rgba(255, 255, 255, 0.12)",
  },
  fillSpace: {
    flex: 1,
  },
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.38)",
  },
  horizontalSeparator: {
    width: 8,
  },
  image: {
    flex: 1,
    resizeMode: "contain",
  },
  imageContainer: {
    flex: 1,
    justifyContent: "center",
  },
  imageFooter: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  listItem: {
    backgroundColor: "transparent",
  },
  mediaActions: {
    flexDirection: "row",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  root: {
    flex: 1,
    backgroundColor: "#000",
  },
  scrollContent: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  timestamp: {
    paddingEnd: 8,
    paddingStart: 8,
    color: "rgba(255, 255, 255, 0.6)",
  },
  topNavigation: {
    backgroundColor: "transparent",
  },
  uiOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    start: 0,
    top: 0,
    justifyContent: "space-between",
  },
});

const MediaViewerModal = ({
  editable,
  isVisible,
  media,
  onClose,
  onDeletePress,
  onDescriptionPress,
}: Props) => {
  const theme = useTheme();
  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const {
    componentRendered: modalRendered,
    fadeAnimation: modalFadeAnimation,
  } = useFadeAnimation({ visible: isVisible });
  const [url, setUrl] = useState(null);
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  useEffect(() => {
    if (isVisible && media) {
      if ("uri" in media) {
        setUrl(media.uri);
      } else {
        setUrl(null);
        (async () => {
          const mediaResponse = await getMedia(media.id);
          setUrl(mediaResponse.url);
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const renderHeader = () => {
    return (
      <View style={styles.header}>
        <SafeAreaView>
          <TopNavigation
            alignment="center"
            accessoryLeft={() => (
              <TopNavigationAction
                icon={(props) => (
                  <Icon
                    {...props}
                    name="close-outline"
                    fill={theme["text-control-color"]}
                  />
                )}
                onPress={onClose}
              />
            )}
            style={styles.topNavigation}
          />
        </SafeAreaView>
      </View>
    );
  };
  const renderMetadata = () => {
    return (
      media && (
        <ListItem
          description={() => (
            <Text category="c2" style={styles.timestamp}>
              {moment(media.createdAt).format(dateTimeFormat)}
            </Text>
          )}
          disabled
          style={styles.listItem}
          title={
            media.description
              ? () => (
                  <Text
                    style={[
                      styles.description,
                      {
                        color: `rgba(255, 255, 255, ${
                          media.description ? "0.87" : "0.6"
                        })`,
                      },
                    ]}
                  >
                    {media.description}
                  </Text>
                )
              : undefined
          }
        />
      )
    );
  };
  const renderActions = () => {
    if (media && editable) {
      return (
        <>
          <Divider style={styles.divider} />
          <MediaActions
            media={media}
            onDeletePress={onDeletePress}
            onDescriptionPress={onDescriptionPress}
            style={styles.mediaActions}
          />
        </>
      );
    }
    return null;
  };
  const renderFooter = () => {
    return (
      <View style={styles.imageFooter}>
        <SafeAreaView>
          <Separator size="small" />
          {renderMetadata()}
          <Separator size="small" />
          {renderActions()}
        </SafeAreaView>
      </View>
    );
  };
  const renderMedia = () => {
    if (!media) {
      return renderHeader();
    }
    return (
      <>
        {url && <Image source={{ uri: url }} style={styles.image} />}
        <View style={styles.uiOverlay}>
          {renderHeader()}
          {renderFooter()}
        </View>
      </>
    );
  };
  return (
    modalRendered && (
      <>
        <StatusBar barStyle="light-content" backgroundColor="black" />
        <Portal>
          <Animated.View style={[styles.root, { opacity: modalFadeAnimation }]}>
            <Container style={styles.container}>{renderMedia()}</Container>
          </Animated.View>
        </Portal>
      </>
    )
  );
};

MediaViewerModal.defaultProps = {
  editable: false,
  media: undefined,
  onDeletePress: undefined,
  onDescriptionPress: undefined,
};

export default MediaViewerModal;
