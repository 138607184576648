import { CheckBox, Icon, Text, useTheme } from "@ui-kitten/components";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import Separator from "../../components/buildingBlocks/Separator";
import MediaThumbnail from "../../components/MediaThumbnail";
import selectReportByIdAndJobId from "../../store/jobs/selectors/selectReportByIdAndJobId";
import selectUserById from "../../store/users/selectors/selectUserById";
import { DailyActivityReport, Media, UserLocationRecord } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import isCompletionRecordCompleted from "../../utils/isCompletionRecordCompleted";
import useCompanySettings from "../useCompanySettings";

const DescriptionRow = styled.View`
  align-items: center;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 6px;
`;

const DescriptionRowIcon = styled(Icon)`
  height: 16px;
  width: 16px;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledMediaThumbnail = styled(MediaThumbnail)`
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const useCompletionRecordUtils = ({
  checklistItem,
  completionRecord,
  disableThumbnail,
  jobId,
  readonly,
  reportsLoading,
}: {
  checklistItem: {
    description: string;
    dailyActivityReportRequired?: boolean;
  };
  completionRecord: {
    record: UserLocationRecord;
    media?: Media;
    dailyActivityReportId?: string;
  };
  disableThumbnail: boolean;
  jobId: string;
  readonly: boolean;
  reportsLoading: boolean;
}) => {
  const theme = useTheme();

  const { dailyActivityReportsEnabled } = useCompanySettings();

  const completionRecordDailyActivityReport = useSelector((state) =>
    selectReportByIdAndJobId(state, {
      id: completionRecord.dailyActivityReportId,
      jobId,
    })
  );
  const completionRecordUser = useSelector((state) =>
    selectUserById(state, completionRecord.record.by)
  );

  const markedCompleted = isCompletionRecordCompleted({
    checklistItem,
    completionRecord,
    completionRecordDailyActivityReport,
  });

  const title = () => (
    <StyledText
      category="p1"
      style={
        markedCompleted && !readonly && { textDecorationLine: "line-through" }
      }
    >
      {checklistItem.description}
    </StyledText>
  );

  const description = () => {
    const renderDailyActivityReport = () => {
      if (
        dailyActivityReportsEnabled &&
        (checklistItem.dailyActivityReportRequired ||
          completionRecord.dailyActivityReportId)
      ) {
        let dailyActivityReportStatus:
          | "completed"
          | "failed to load"
          | "in progress"
          | "required";
        if (completionRecord.dailyActivityReportId) {
          if (completionRecordDailyActivityReport) {
            if (completionRecordDailyActivityReport.submitted) {
              dailyActivityReportStatus = "completed";
            } else {
              dailyActivityReportStatus = "in progress";
            }
          } else {
            dailyActivityReportStatus = "failed to load";
          }
        } else {
          dailyActivityReportStatus = "required";
        }
        return (
          <>
            <Separator size={2} />
            <DescriptionRow>
              <DescriptionRowIcon
                fill={theme["text-hint-color"]}
                name="file-text"
              />
              <Separator horizontal size={4} />
              <Text
                appearance="hint"
                category="c1"
                // "status" overwrites "appearance" prop so must be deliberately set to undefined
                status={
                  !reportsLoading &&
                  dailyActivityReportStatus === "failed to load"
                    ? "danger"
                    : undefined
                }
              >
                {reportsLoading &&
                dailyActivityReportStatus === "failed to load"
                  ? "Loading..."
                  : `Daily activity report ${dailyActivityReportStatus}`}
              </Text>
            </DescriptionRow>
          </>
        );
      }
      return null;
    };
    const renderUser = () => {
      if (completionRecord) {
        return (
          <>
            <Separator size={2} />
            <DescriptionRow>
              <DescriptionRowIcon
                fill={theme["text-hint-color"]}
                name="person"
              />
              <Separator horizontal size={4} />
              <Text appearance="hint" category="c1">
                {getUserFullName(completionRecordUser)}
              </Text>
            </DescriptionRow>
          </>
        );
      }
      return null;
    };

    return (
      <View>
        {renderDailyActivityReport()}
        {renderUser()}
      </View>
    );
  };

  const accessoryLeft =
    !readonly &&
    (() => (
      <View pointerEvents="none">
        <StyledCheckBox checked={markedCompleted} />
      </View>
    ));

  const accessoryRight = useCallback(() => {
    if (disableThumbnail) {
      return null;
    }
    return (
      <>
        {completionRecord?.media && (
          <StyledMediaThumbnail
            height={48}
            media={completionRecord.media}
            width={48}
          />
        )}
      </>
    );
  }, [completionRecord?.media]);

  return {
    completionRecordDailyActivityReport,
    completionRecordUser,
    ListItemProps: {
      accessoryLeft,
      accessoryRight,
      description,
      title,
    },
    markedCompleted,
  };
};

export default useCompletionRecordUtils;
