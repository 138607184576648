import {
  Icon,
  Text,
  TopNavigation,
  TopNavigationProps,
} from "@ui-kitten/components";
import React from "react";
import { Keyboard, StyleSheet, View } from "react-native";

import TopNavigationAction from "../../TopNavigationAction";

export interface Props
  extends Pick<TopNavigationProps, "accessoryRight" | "subtitle"> {
  navigation: {
    "data-test"?: string;
    icon: string;
    onPress: () => void;
  };
  title?: string;
}

const styles = StyleSheet.create({
  topNavigation: {
    paddingHorizontal: 4,
    paddingVertical: 0,
  },
  topNavigationAction: {
    marginHorizontal: 0,
    padding: 12,
  },
  topNavigationActionOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingEnd: 12,
  },
});

const Header = ({ accessoryRight, navigation, subtitle, title }: Props) => {
  const renderAccessoryRight = () => {
    if (!accessoryRight) {
      return null;
    }
    return (
      <View style={styles.topNavigationActionOverlay}>{accessoryRight()}</View>
    );
  };
  return (
    <View>
      <TopNavigation
        accessoryLeft={() => (
          <TopNavigationAction
            data-test={navigation["data-test"]}
            icon={(imageProps) => (
              <Icon {...imageProps} name={navigation.icon} />
            )}
            onPress={() => {
              Keyboard.dismiss();
              navigation.onPress();
            }}
            style={styles.topNavigationAction}
          />
        )}
        alignment="center"
        subtitle={subtitle}
        style={styles.topNavigation}
        title={() => <Text category="h5">{title}</Text>}
      />
      {renderAccessoryRight()}
    </View>
  );
};

export default Header;
