import query from "./graphql";
import client from "../../client";
import mapToIncidentReport from "../../utils/mapToIncidentReport";
import store from "../../../store";
import { setJobAccessories } from "../../../store/jobs/slice";

const queryJobAccessories = async (jobId) => {
  const { dispatch } = store;
  const jobAccessoryResults = {
    job: null,
    jobId,
    reports: [],
    startJobRecords: [],
    taskCompletionRecords: [],
  };
  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { data } = await client.query({
      query,
      variables: {
        jobId,
        after: nextToken,
      },
      fetchPolicy: "no-cache",
    });
    const { queryJobAccessories: queryResult } = data;
    queryResult.items.forEach((item) => {
      switch (item.__typename) {
        case "DailyActivityReport":
          jobAccessoryResults.reports.push(item);
          break;
        case "IncidentReport":
          jobAccessoryResults.reports.push(mapToIncidentReport(item));
          break;
        case "Job":
          jobAccessoryResults.job = item;
          break;
        default:
          break;
      }
    });
    nextToken = queryResult.nextToken;
  } while (nextToken);
  dispatch(setJobAccessories(jobAccessoryResults));
};

export default queryJobAccessories;
