import { ScreenNames } from "../../types";

const navigationRoutes: Record<ScreenNames, ScreenNames> = {
  Inbox: "Inbox",
  CustomerDetails: "CustomerDetails",
  EmailActivity: "EmailActivity",
  Customers: "Customers",
  Billing: "Billing",
  Channel: "Channel",
  ChannelList: "ChannelList",
  CompanyGroups: "CompanyGroups",
  CompanySites: "CompanySites",
  CompanyUsers: "CompanyUsers",
  GroupDetails: "GroupDetails",
  HistoricalSearch: "HistoricalSearch",
  JobDetails: "JobDetails",
  MainBottomTab: "MainBottomTab",
  MainDrawer: "MainDrawer",
  ReportDetails: "ReportDetails",
  Settings: "Settings",
  SignIn: "SignIn",
  SiteDetails: "SiteDetails",
  SubjectDetails: "SubjectDetails",
  Thread: "Thread",
  TimeCard: "TimeCard",
  TimeCardDetails: "TimeCardDetails",
  UpcomingJobs: "UpcomingJobs",
  UserDetails: "UserDetails",
  VehicleDetails: "VehicleDetails",
};

export default navigationRoutes;
