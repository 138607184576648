import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { PostmarkMessageSendingResponse } from "../../../types";

interface SendInvoiceReceiptInput {
  invoiceId: string;
}

const sendInvoiceReceipt = async ({ invoiceId }: SendInvoiceReceiptInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        invoiceId,
      },
    })) as GraphQLResult<{
      sendInvoiceReceipt: PostmarkMessageSendingResponse[];
    }>;
    return data.sendInvoiceReceipt;
  } catch ({ errors }) {
    throw generateErrorMessage("sendInvoiceReceipt", errors);
  }
};

export default sendInvoiceReceipt;
