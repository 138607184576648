import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setUserPending } from "../../../store/users/slice";

const dispatchOptimisticResponse = (user) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const timestamp = new Date().toISOString();
  dispatch(
    setUserPending({
      mutation: "adminRestoreCompanyUser",
      user: {
        ...user,
        syncDateTime: timestamp,
        isArchived: false,
        updated: {
          at: timestamp,
          by: userId,
        },
      },
    })
  );
};

const adminRestoreCompanyUser = (user) => {
  dispatchOptimisticResponse(user);
  client.mutate({
    mutation,
    variables: {
      id: user.id,
    },
    errorPolicy: "all",
  });
};

export default adminRestoreCompanyUser;
