import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import { setJobPending } from "../../../store/jobs/slice";
import deepCopy from "../../utils/deepCopy";

const dispatchOptimisticResponse = ({
  id,
  siteId,
  assignedTo,
  tasks,
  startDateTime,
  endDateTime,
  rrule,
  immediate,
  includeCreatorInMessages,
  autostart,
}) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId, companyId } = state.auth.user;
  const timestamp = new Date().toISOString();
  dispatch(
    setJobPending({
      job: {
        id,
        startDateTime,
        endDateTime,
        siteId,
        syncId: companyId,
        assignedTo,
        updated: {
          at: timestamp,
          by: userId,
        },
        created: {
          at: timestamp,
          by: userId,
        },
        tasks,
        finished: null,
        startJobRecords: [],
        taskCompletionRecords: [],
        syncDateTime: timestamp,
        isDeleted: false,
        rrule,
        immediate,
        includeCreatorInMessages,
        autostart,
      },
      mutation: "createJob",
    })
  );
};

const createJob = ({
  id,
  siteId,
  assignedTo,
  tasks,
  startDateTime,
  endDateTime,
  rrule,
  immediate,
  includeCreatorInMessages = true,
  autostart = false,
}) => {
  const input = {
    id,
    siteId,
    assignedTo,
    tasks,
    startDateTime,
    endDateTime,
    rrule,
    immediate,
    includeCreatorInMessages,
    autostart,
  };
  dispatchOptimisticResponse(input);
  client.mutate({
    mutation,
    variables: deepCopy(input),
    errorPolicy: "all",
  });
};

export default createJob;
