import moment from "moment-timezone";

import privateQuery from "./graphql/private";
import publicQuery from "./graphql/public";
import client from "../../client";
import roles from "../../../constants/companyUserRoles";
import store from "../../../store";
import { sync } from "../../../store/data/actions";
import getUserRole from "../../../utils/getUserRole";
import {
  Company,
  CompanyGroup,
  CompanySite,
  CompanyUser,
  Customer,
  Job,
  InboxItem,
} from "../../../types";
import { addNotification } from "../../../components/InAppNotifications";

interface Variables {
  after?: string;
  lastSync?: string;
}

export type SyncCompanyResult =
  | Company
  | CompanyGroup
  | CompanySite
  | CompanyUser
  | Customer
  | Job
  | InboxItem;

export interface SyncCompanyResultsConnection {
  items: Array<SyncCompanyResult>;
  nextToken?: string;
}

interface Data {
  syncCompany: SyncCompanyResultsConnection;
}

let syncInProgress = false;

const syncCompany = async () => {
  if (syncInProgress) {
    return;
  }
  syncInProgress = true;
  const { dispatch, getState } = store;
  dispatch(sync.pending());
  const { lastSync } = getState().data;
  let syncCompanyDateTime;
  if (lastSync) {
    const { dateTime, mostRecentSyncDateTime } = lastSync;
    if (moment().diff(moment(dateTime), "minutes") < 30) {
      syncCompanyDateTime = mostRecentSyncDateTime;
    }
  }
  const syncCompanyResults = {
    actionItems: [],
    company: null,
    customers: [],
    groups: [],
    jobs: [],
    lastSync: null,
    sites: [],
    users: [],
    inboxItems: [],
  };

  const role = await getUserRole();
  const query = role === roles.EMPLOYEE ? publicQuery : privateQuery;

  let nextToken = null;
  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await client.query<Data, Variables>({
        query,
        variables: {
          lastSync: syncCompanyDateTime,
          after: nextToken,
        },
        fetchPolicy: "no-cache",
      });
      const { syncCompany: queryResult } = data;
      if (Array.isArray(queryResult.items) && queryResult.items.length > 0) {
        const mostRecentlyUpdatedQueryItem = queryResult.items[0];
        if (
          !syncCompanyResults.lastSync ||
          syncCompanyResults.lastSync.localeCompare(
            mostRecentlyUpdatedQueryItem.syncDateTime
          ) < 0
        ) {
          syncCompanyResults.lastSync =
            mostRecentlyUpdatedQueryItem.syncDateTime;
        }
      }
      queryResult.items.forEach(
        (item: SyncCompanyResult & { __typename: string }) => {
          switch (item.__typename) {
            case "ActionItem":
              syncCompanyResults.actionItems.push(item);
              break;
            case "Company":
              syncCompanyResults.company = item;
              break;
            case "CompanyGroup":
              syncCompanyResults.groups.push(item);
              break;
            case "CompanySite":
              syncCompanyResults.sites.push(item);
              break;
            case "CompanyUser":
              syncCompanyResults.users.push(item);
              break;
            case "Customer":
              syncCompanyResults.customers.push(item);
              break;
            case "InboxItem":
              syncCompanyResults.inboxItems.push({
                ...item,
                // @ts-ignore: aliasing was implemented to fix GraphQL FieldsConflict error
                description: item.inboxItemDescription,
              });
              break;
            case "Job":
              syncCompanyResults.jobs.push(item);
              break;
            default:
              break;
          }
        }
      );
      nextToken = queryResult.nextToken;
    } while (nextToken);
    dispatch(
      sync.fulfilled({
        ...syncCompanyResults,
        lastSync: {
          fullSync: !syncCompanyDateTime,
          dateTime: new Date().toISOString(),
          mostRecentSyncDateTime: syncCompanyResults.lastSync,
        },
      })
    );
  } catch (error) {
    addNotification({
      message: (error && error.message) || error,
      status: "danger",
      title: "Sync failed",
    });
    dispatch(sync.rejected(undefined, error));
  }
  syncInProgress = false;
};

export default syncCompany;
