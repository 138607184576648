import { Howl } from "howler";

const alertSound = new Howl({
  html5: true,
  loop: true,
  src: [require("../../../assets/alert_sound.mp3")],
});

const useAlertSound = () => {
  const playAlertSound = () => {
    if (!alertSound.playing()) {
      alertSound.play();
    }
  };
  const stopAlertSound = () => alertSound.stop();
  return { playAlertSound, stopAlertSound };
};

export default useAlertSound;
