import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeQuote } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

export type ListQuotesInput = Pick<
  Stripe.QuoteListParams,
  "customer" | "starting_after" | "status"
>;

const listQuotes = async (input?: ListQuotesInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: input && {
        customer: input.customer,
        starting_after: input.starting_after,
        status: input.status,
      },
    })) as GraphQLResult<{
      listQuotes: {
        data: Array<StripeQuote>;
        has_more: boolean;
      };
    }>;
    return data.listQuotes;
  } catch ({ errors }) {
    throw generateErrorMessage("listQuotes", errors);
  }
};

export default listQuotes;
