import {
  createAction,
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { initSession, resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import companyUserRoles from "../../../constants/companyUserRoles";
import mapFilterConstants from "../../../constants/mapFilter";
import {
  DailyActivityReport,
  EntityState,
  IncidentReport,
  Job,
  JobStatusId,
  OptimisticDailyActivityReport,
  OptimisticIncidentReport,
  OptimisticJob,
} from "../../../types";

const adapter = createEntityAdapter<Job>();
const optimisticAdapter = createEntityAdapter<OptimisticJob>();
export const historicalAdapter = createEntityAdapter<{ id: string }>();
type Report = DailyActivityReport | IncidentReport;
type OptimisticReport =
  | OptimisticDailyActivityReport
  | OptimisticIncidentReport;
const reportAdapter = createEntityAdapter<Report>();
const optimisticReportAdapter = createEntityAdapter<OptimisticReport>();

const initialReportsState = reportAdapter.getInitialState({
  optimistic: optimisticReportAdapter.getInitialState(),
});

const reportsByJobId: {
  [jobId: string]: typeof initialReportsState;
} = {};

interface ReportsState extends EntityState<Report> {
  optimistic: EntityState<OptimisticReport>;
}

export type AssigneeFilterTypes = "all" | "onlyMe";

export interface MapFilter {
  status?: JobStatusId;
  assignees: AssigneeFilterTypes;
  unassignedOnly: boolean;
}

export interface State extends EntityState<Job> {
  filter: MapFilter;
  historical: EntityState<{ id: string }>;
  optimistic: EntityState<OptimisticJob>;
  reportsByJobId: {
    [jobId: string]: ReportsState;
  };
}

const initialState = adapter.getInitialState({
  filter: {
    status: null,
    assignees: mapFilterConstants.visibility.onlyMe.value,
    unassignedOnly: false,
  },
  historical: historicalAdapter.getInitialState(),
  optimistic: optimisticAdapter.getInitialState(),
  reportsByJobId,
}) as State;

const addJob = ({ job, state }: { job: Job; state: Draft<State> }) => {
  const existingJob = state.entities[job.id];
  if (
    existingJob === undefined ||
    job.syncDateTime > existingJob.syncDateTime
  ) {
    adapter.upsertOne(state, job);
  }
};
const removeOptimisticReport = ({
  id,
  jobId,
  mutation,
  state,
}: {
  id: string;
  jobId: string;
  mutation: string;
  state: Draft<State>;
}) => {
  if (state.reportsByJobId[jobId]) {
    const existingOptimisticReport =
      state.reportsByJobId[jobId].optimistic.entities[id];
    if (
      existingOptimisticReport &&
      mutation === existingOptimisticReport.mutation
    ) {
      optimisticReportAdapter.removeOne(
        state.reportsByJobId[jobId].optimistic,
        id
      );
    }
  }
};

const name = "jobs";

export const queryFinishedJobs = {
  pending: createAction(`${name}/queryFinishedJobs/pending`),
  fulfilled: createAction<{
    jobs: Array<Job>;
  }>(`${name}/queryFinishedJobs/fulfilled`),
  rejected: createAction(
    `${name}/queryFinishedJobs/rejected`,
    (payload, error: Error) => ({
      error,
      payload,
    })
  ),
};
export const setJobPending = createAction<{ job: Job; mutation: string }>(
  `${name}/setJob/pending`
);
export const setJobFulfilled = createAction<{ job: Job; mutation: string }>(
  `${name}/setJob/fulfilled`
);
export const setJobRejected = createAction(
  `${name}/setJob/rejected`,
  (payload, error) => ({ error, payload })
);
export const setJobsPending = createAction<{
  jobs: Array<Job>;
  mutation: string;
}>(`${name}/setJobs/pending`);
export const setJobsFulfilled = createAction<{
  jobs: Array<Job>;
  mutation: string;
}>(`${name}/setJobs/fulfilled`);
export const setJobsRejected = createAction(
  `${name}/setJobs/rejected`,
  (payload: { ids: Array<string>; mutation: string }, error) => ({
    error,
    payload,
  })
);
export const setReportPending = createAction<{
  deleted: boolean;
  mutation: string;
  report: DailyActivityReport | IncidentReport;
}>(`${name}/setReport/pending`);
export const setReportFulfilled = createAction<{
  deleted: boolean;
  mutation: string;
  report: DailyActivityReport | IncidentReport;
}>(`${name}/setReport/fulfilled`);
export const setReportRejected = createAction(
  `${name}/setReport/rejected`,
  (payload, error) => ({
    error,
    payload,
  })
);

const setPendingJob = ({
  job,
  mutation,
  state,
}: {
  job: Job;
  mutation: string;
  state: Draft<State>;
}) => {
  const existingOptimisticJob = state.optimistic.entities[job.id];
  if (
    existingOptimisticJob === undefined ||
    job.syncDateTime > existingOptimisticJob.syncDateTime
  ) {
    optimisticAdapter.upsertOne(state.optimistic, {
      ...job,
      mutation,
      pending: true,
    });
  }
};

const setFulfilledJob = ({
  job,
  mutation,
  state,
}: {
  job: Job;
  mutation: string;
  state: Draft<State>;
}) => {
  addJob({ job, state });
  const existingOptimisticJob = state.optimistic.entities[job.id];
  if (
    existingOptimisticJob !== undefined &&
    mutation === existingOptimisticJob.mutation
  ) {
    optimisticAdapter.removeOne(state.optimistic, job.id);
  }
};

const setRejectedJob = ({
  id,
  mutation,
  state,
}: {
  id: string;
  mutation: string;
  state: Draft<State>;
}) => {
  const existingOptimisticJob = state.optimistic.entities[id];
  if (
    existingOptimisticJob !== undefined &&
    mutation === existingOptimisticJob.mutation
  ) {
    optimisticAdapter.removeOne(state.optimistic, id);
  }
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setFilterAssignees: (
      state,
      action: PayloadAction<{ assignees: AssigneeFilterTypes }>
    ) => {
      const { assignees } = action.payload;
      state.filter.assignees = assignees;
      if (assignees === "onlyMe") {
        state.filter.unassignedOnly = false;
      }
    },
    setFilterStatus: (
      state,
      action: PayloadAction<{ status: JobStatusId }>
    ) => {
      const { status } = action.payload;
      state.filter.status = status;
    },
    setFilterUnassignedOnly: (
      state,
      action: PayloadAction<{ unassignedOnly: boolean }>
    ) => {
      const { unassignedOnly } = action.payload;
      state.filter.unassignedOnly = unassignedOnly;
    },
    setJob: (state, action) => {
      const { job } = action.payload;
      addJob({ job, state });
    },
    setJobAccessories: (state, action) => {
      const { job, jobId, reports } = action.payload;
      if (job !== null) addJob({ job, state });
      if (state.reportsByJobId[jobId]) {
        reportAdapter.setAll(state.reportsByJobId[jobId], reports);
      } else {
        state.reportsByJobId[jobId] = {
          ids: reports.map((report) => report.id),
          entities: reports.reduce(
            (accumulator, report) => ({
              ...accumulator,
              [report.id]: report,
            }),
            {}
          ),
          optimistic: optimisticReportAdapter.getInitialState() as EntityState<
            OptimisticReport
          >,
        };
      }
    },
    setJobs: (state, action) => {
      const { jobs } = action.payload;
      jobs.forEach((job) => {
        addJob({ job, state });
      });
    },
    refreshJobStatuses: (state) => {
      // It is absolutely critical that this slice be updated one way or another
      // so that the 'selectJobs' selector gets re-run. So in the case that
      // there are no changes we force an update by returning a new reference to
      // the slice.
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setJobPending, (state, action) => {
      const { job, mutation } = action.payload;
      setPendingJob({ state, job, mutation });
    });
    builder.addCase(setJobFulfilled, (state, action) => {
      const { job, mutation } = action.payload;
      setFulfilledJob({ state, job, mutation });
    });
    builder.addCase(setJobRejected, (state, action) => {
      const { id, mutation } = action.payload;
      setRejectedJob({ state, id, mutation });
    });
    builder.addCase(setJobsPending, (state, action) => {
      const { jobs, mutation } = action.payload;
      jobs.forEach((job) => setPendingJob({ state, job, mutation }));
    });
    builder.addCase(setJobsFulfilled, (state, action) => {
      const { jobs, mutation } = action.payload;
      jobs.forEach((job) => setFulfilledJob({ state, job, mutation }));
    });
    builder.addCase(setJobsRejected, (state, action) => {
      const { ids, mutation } = action.payload;
      ids.forEach((id) => setRejectedJob({ state, id, mutation }));
    });
    builder.addCase(setReportPending, (state, action) => {
      const { deleted, mutation, report } = action.payload;
      const { id, jobId, syncDateTime } = report;
      const reportEntity = {
        ...report,
        deleted,
        mutation,
        pending: true,
      };
      if (state.reportsByJobId[jobId]) {
        const existingOptimisticReport =
          state.reportsByJobId[jobId].optimistic.entities[id];
        // The reason it uses >= instead of > is because a deleted report is
        // GONE. The returned object from a deletion is the object as it was
        // right before it was deleted.
        if (
          !existingOptimisticReport ||
          syncDateTime >= existingOptimisticReport.syncDateTime
        ) {
          optimisticReportAdapter.upsertOne(
            state.reportsByJobId[jobId].optimistic,
            reportEntity
          );
        }
      } else {
        state.reportsByJobId[jobId] = {
          ids: [],
          entities: {},
          optimistic: {
            ids: [id],
            entities: {
              [id]: reportEntity,
            },
          },
        };
      }
    });
    builder.addCase(setReportFulfilled, (state, action) => {
      const { deleted, mutation, report } = action.payload;
      const { id, jobId, syncDateTime } = report;
      if (deleted) {
        const existingReport = state.reportsByJobId[jobId].entities[id];
        // The reason it uses >= instead of > is because a deleted report is
        // GONE. The returned object from a deletion is the object as it was
        // right before it was deleted.
        if (existingReport && syncDateTime >= existingReport.syncDateTime) {
          reportAdapter.removeOne(state.reportsByJobId[jobId], id);
        }
      } else {
        const existingReport = state.reportsByJobId[jobId].entities[id];
        if (!existingReport || syncDateTime > existingReport.syncDateTime) {
          reportAdapter.upsertOne(state.reportsByJobId[jobId], report);
        }
      }
      removeOptimisticReport({ id, jobId, mutation, state });
    });
    builder.addCase(setReportRejected, (state, action) => {
      const { id, jobId, mutation } = action.payload;
      removeOptimisticReport({ id, jobId, mutation, state });
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { jobs, lastSync: { fullSync } } = action.payload;
      if (fullSync) {
        adapter.setAll(state, jobs);
      } else {
        jobs.forEach((job) => addJob({job, state}));
      }
    });
    builder.addCase(queryFinishedJobs.fulfilled, (state, action) => {
      adapter.removeMany(state, state.historical.ids);
      state.historical.ids.forEach((id) => delete state.reportsByJobId[id]);
      historicalAdapter.removeAll(state.historical);
      const { jobs } = action.payload;
      jobs.forEach((job) => {
        const { id } = job;
        historicalAdapter.addOne(state.historical, { id });
        addJob({ job, state });
      });
    });
    builder.addCase(initSession.fulfilled, (state, action) => {
      const { user } = action.payload;
      switch (user.group) {
        case companyUserRoles.ADMIN:
          state.filter.assignees = mapFilterConstants.visibility.all.value;
          break;
        case companyUserRoles.SUPERVISOR:
          state.filter.assignees = mapFilterConstants.visibility.all.value;
          break;
        case companyUserRoles.EMPLOYEE:
          state.filter.assignees = mapFilterConstants.visibility.onlyMe.value;
          break;
        default:
          state.filter.assignees = mapFilterConstants.visibility.onlyMe.value;
      }

      const { entities, ids } = state;
      // this keeps the store from growing out of control over time
      const unusedJobIds = [];
      ids.forEach((jobId) => {
        const job = entities[jobId];
        if (!job.syncId) {
          unusedJobIds.push(jobId);
          delete state.reportsByJobId[jobId];
        }
      });

      if (unusedJobIds.length > 0) {
        adapter.removeMany(state, unusedJobIds);
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const {
  refreshJobStatuses,
  setFilterUnassignedOnly,
  setFilterAssignees,
  setFilterStatus,
  setJobs,
  setJob,
  setJobAccessories,
} = actions;

export default reducer;
