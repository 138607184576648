import { Text } from "@ui-kitten/components";
import React, { Fragment } from "react";
import { StyleSheet, View } from "react-native";

import SimpleModal from "../Simple";
import Button, { Props as ButtonProps } from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";

interface ActionsProps
  extends Pick<ButtonProps, "appearance" | "onPress" | "status"> {
  text;
}

type Props = {
  actions: Array<ActionsProps>;
  isVisible: boolean;
  message: string;
  onClose: () => void;
};

const styles = StyleSheet.create({
  content: {
    marginBottom: 26,
    marginTop: 20,
  },
  footer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  footerSeparator: {
    height: 4,
  },
  message: {
    marginEnd: 16,
    marginStart: 16,
  },
});

const MultiActionAlertModal = ({
  actions,
  isVisible,
  message,
  onClose,
}: Props) => {
  return (
    <SimpleModal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackPress={onClose}
    >
      <View onStartShouldSetResponder={() => true}>
        <View style={styles.content}>
          <Text style={styles.message}>{message}</Text>
        </View>
        <View style={styles.footer}>
          {actions.map((action, index) => (
            <Fragment key={action.text}>
              {index > 0 && <Separator size="small" />}
              <Button
                appearance={action.appearance}
                onPress={action.onPress}
                status={action.status}
              >
                {action.text}
              </Button>
            </Fragment>
          ))}
        </View>
      </View>
    </SimpleModal>
  );
};

export default React.memo(MultiActionAlertModal);
