import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import selectSites from "../../store/sites/selectors/selectSites";
import { CompanySite, EntityState } from "../../types";

const sitePassesArchivedFilter = ({
  includeArchived,
  site,
}: {
  includeArchived: boolean;
  site: CompanySite;
}) => includeArchived || !site.isArchived;

const sitePassesCustomFilter = ({
  filter,
  site,
}: {
  filter?: (site: CompanySite) => boolean;
  site: CompanySite;
}) => !filter || filter(site);

const sitePassesSearchTextFilter = ({
  searchText,
  site,
}: {
  searchText: string;
  site: CompanySite;
}) => {
  const sanitizedSearchText = searchText.trim().toLowerCase();

  return (
    !searchText ||
    site.name.toLowerCase().includes(sanitizedSearchText) ||
    site.address.toLowerCase().includes(sanitizedSearchText)
  );
};

const filterSiteIds = ({
  filter,
  includeArchived,
  searchText,
  sites,
}: {
  filter?: (site: CompanySite) => boolean;
  includeArchived: boolean;
  searchText: string;
  sites: EntityState<CompanySite>;
}) => {
  const filteredSiteIds = sites.ids.filter((siteId) => {
    const site = sites.entities[siteId];
    return (
      sitePassesArchivedFilter({ includeArchived, site }) &&
      sitePassesCustomFilter({ filter, site }) &&
      sitePassesSearchTextFilter({ searchText, site })
    );
  });
  return filteredSiteIds.sort((firstSiteId, secondSiteId) => {
    const firstSite = sites.entities[firstSiteId];
    const secondSite = sites.entities[secondSiteId];
    return firstSite.name.localeCompare(secondSite.name);
  });
};

const useSiteList = (params?: {
  includeArchived: boolean;
  filter: (site: CompanySite) => boolean;
}) => {
  const [searchText, setSearchText] = useState("");
  const sites = useSelector(selectSites);
  const filteredSiteIds = useMemo(
    () =>
      filterSiteIds({
        filter: params?.filter,
        includeArchived: Boolean(params?.includeArchived),
        searchText,
        sites,
      }),
    [params, searchText, sites]
  );
  return { searchText, setSearchText, siteIds: filteredSiteIds };
};

export default useSiteList;
