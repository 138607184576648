import { Radio } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";

import ListItem, { ListItemProps } from "../Base";

interface RadioListItemProps
  extends Pick<ListItemProps, "description" | "style" | "title"> {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const RadioListItem = ({
  checked,
  description,
  onChange,
  style,
  title,
}: RadioListItemProps) => (
  <ListItem
    accessoryRight={(props) => (
      <View pointerEvents="none">
        <Radio {...props} checked={checked} />
      </View>
    )}
    description={description}
    onPress={() => onChange(!checked)}
    style={style}
    title={title}
  />
);

export default RadioListItem;
