import React from "react";

import JobStatusCard, { JobStatusCardProps } from "../JobStatusCard";
import jobStatuses from "../../../constants/jobStatuses";

type ImmediateStatusJobCardProps = Partial<
  Pick<JobStatusCardProps, "callback" | "dense">
>;

const ImmediateStatusJobCard = ({
  callback = () => {},
  dense,
}: ImmediateStatusJobCardProps) => (
  <JobStatusCard
    callback={callback}
    colors={jobStatuses.inProgress.colors}
    dense={dense}
    jobStatusId={jobStatuses.inProgress.id}
    label="In Progress"
  />
);

export default ImmediateStatusJobCard;
