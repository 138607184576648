import dayjs from "dayjs";
import momentFormats from "../../constants/momentFormats";

interface Params {
  data: any;
  startDateTimeExtractor: (item: any) => string;
  idExtractor: (item: any) => string;
  sortCompareFunction: (a: any, b: any) => number;
  to?: string;
  from?: string;
}

interface Section {
  title: string;
  data: Array<string>;
}

export interface SectionData {
  sections: Array<Section>;
  itemIds: Array<string>;
}

const formatSectionListData = ({
  data,
  startDateTimeExtractor,
  idExtractor,
  sortCompareFunction,
  to,
  from,
}: Params): SectionData => {
  const dates = {};
  const sections: Array<Section> = [];
  const itemIds: Array<string> = [];
  data.sort(sortCompareFunction);

  let i;
  for (i = 0; i < data.length; i += 1) {
    const item = data[i];
    const startDateTime = startDateTimeExtractor(item);
    if (from && to && (startDateTime < from || startDateTime > to)) continue;
    const itemId = idExtractor(item);
    itemIds.push(itemId);
    const dateKey = dayjs(startDateTime).format(momentFormats.dateFormat);
    if (!dates[dateKey]) dates[dateKey] = [itemId];
    else dates[dateKey].push(itemId);
  }
  Object.keys(dates).forEach((date) => {
    sections.push({
      title: date,
      data: dates[date],
    });
  });
  return {
    sections,
    itemIds,
  };
};

export default formatSectionListData;
