import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import { setReportPending } from "../../../store/jobs/slice";

const dispatchOptimisticResponse = ({ id, timestamp, jobId, started }) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  dispatch(
    setReportPending({
      mutation: "createDailyActivityReport",
      report: {
        __typename: "DailyActivityReport",
        syncDateTime: timestamp,
        id,
        jobId,
        submitted: null,
        updated: {
          at: timestamp,
          by: userId,
        },
        created: {
          at: timestamp,
          by: userId,
        },
        started,
        completed: null,
        summary: "",
        media: [],
      },
    })
  );
};

const createDailyActivityReport = ({
  id,
  timestamp = new Date().toISOString(),
  jobId,
  started,
}) => {
  dispatchOptimisticResponse({
    id,
    timestamp,
    jobId,
    started,
  });
  client.mutate({
    mutation,
    variables: deepCopy({
      id,
      timestamp,
      jobId,
      started,
    }),
    errorPolicy: "all",
  });
};

export default createDailyActivityReport;
