import gql from "graphql-tag";

import { stripeTaxRateFragment } from "../../../fragments";

const retrieveTaxRate = gql`
  query retrieveTaxRate($id: ID!) {
    retrieveTaxRate(id: $id) {
      ...stripeTaxRateFragment
    }
  }
  ${stripeTaxRateFragment}
`;

export default retrieveTaxRate;
