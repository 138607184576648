import { useTheme } from "@ui-kitten/components";
import * as Location from "expo-location";
import * as geolib from "geolib";
import GoogleMapReact, {
  ChangeEventValue,
  ClickEventValue,
} from "google-map-react";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { View } from "react-native";
import ReactNativeMapsMapView, {
  Camera,
  MapViewProps,
} from "react-native-maps";
import { useSelector } from "react-redux";

import GoogleApi from "../GoogleApi";
import Circle from "../../Circle";
import CurrentLocationMarker from "../../markers/CurrentLocation";
import TrafficLayer from "../../TrafficLayer";
import config from "../../../config";
import useAppActive from "../../../device/useAppActive";
import selectLocationPermission from "../../../store/permissions/selectors/selectLocationPermission";
import getZoomFromLongitudeDelta from "../../../utils/getZoomFromLongitudeDelta";

interface Props extends MapViewProps {
  children?: ReactNode;
}

const defaultCenter = { lat: 0, lng: 0 };
const defaultZoom = 15;

const validCoordinates = (coordinates) =>
  coordinates &&
  typeof coordinates.lat === "number" &&
  typeof coordinates.lng === "number";

const validBounds = (bounds) => {
  const { ne, nw, se, sw } = bounds;
  return (
    validCoordinates(ne) &&
    validCoordinates(nw) &&
    validCoordinates(se) &&
    validCoordinates(sw)
  );
};

const MapView = forwardRef<ReactNativeMapsMapView, Props>(
  (
    {
      children,
      customMapStyle,
      initialRegion,
      onPanDrag,
      onPress,
      onRegionChangeComplete,
      onUserLocationChange,
      region,
      rotateEnabled,
      scrollEnabled,
      showsPointsOfInterest,
      showsTraffic,
      showsUserLocation,
      style,
      zoomEnabled,
    }: Props,
    ref
  ) => {
    const theme = useTheme();
    const appActive = useAppActive();
    const locationPermission = useSelector(selectLocationPermission);

    const [context, setContext] = useState(null);
    const [userCoordinates, setUserCoordinates] = useState<{
      accuracy: number;
      altitude: number;
      heading: number;
      latitude: number;
      longitude: number;
      speed: number;
      timestamp: number;
    }>();

    const mapRef = useRef(null);

    useEffect(() => {
      if (appActive && locationPermission) {
        (async () => {
          try {
            const locationObject = await Location.getCurrentPositionAsync({
              accuracy: Location.LocationAccuracy.High,
            });
            setUserCoordinates({
              accuracy: locationObject.coords.accuracy,
              altitude: locationObject.coords.altitude,
              heading: locationObject.coords.heading,
              latitude: locationObject.coords.latitude,
              longitude: locationObject.coords.longitude,
              speed: locationObject.coords.speed,
              timestamp: locationObject.timestamp,
            });
          } catch (error) {
            // Do nothing
          }
        })();
      }
    }, [appActive, locationPermission]);
    useEffect(() => {
      if (showsUserLocation && userCoordinates && onUserLocationChange) {
        // @ts-ignore: I have no idea how to we'd implement any of the missing attributes.
        onUserLocationChange({
          nativeEvent: {
            // @ts-ignore
            coordinate: userCoordinates,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showsUserLocation, userCoordinates]);

    // @ts-ignore: These are the only three functions that are used. I will add more as needed.
    useImperativeHandle(ref, () => ({
      animateCamera: (camera) => {
        if (mapRef.current) {
          if (camera.center) {
            mapRef.current.panTo({
              lat: camera.center.latitude,
              lng: camera.center.longitude,
            });
          }
          if (typeof camera.zoom === "number") {
            mapRef.current.setZoom(camera.zoom);
          }
        }
      },
      fitToCoordinates: (coordinates, options) => {
        if (mapRef.current) {
          const { maxLat, maxLng, minLat, minLng } = geolib.getBounds(
            coordinates
          );
          mapRef.current.fitBounds(
            {
              east: maxLng,
              north: maxLat,
              south: minLat,
              west: minLng,
            },
            options?.edgePadding
          );
        }
      },
      getCamera: async (): Promise<Camera> => {
        const center = mapRef.current.getCenter();
        return {
          altitude: undefined,
          center: {
            latitude: center.lat(),
            longitude: center.lng(),
          },
          heading: mapRef.current.getHeading(),
          pitch: mapRef.current.getTilt(),
          zoom: mapRef.current.getZoom(),
        };
      },
      setCamera: (camera) => {
        if (mapRef.current) {
          if (camera.center) {
            mapRef.current.panTo({
              lat: camera.center.latitude,
              lng: camera.center.longitude,
            });
          }
          if (typeof camera.zoom === "number") {
            mapRef.current.setZoom(camera.zoom);
          }
        }
      },
    }));
    const handleClick = ({ lat, lng, x, y, event }: ClickEventValue) => {
      if (onPress) {
        onPress({
          ...event,
          nativeEvent: {
            ...event.nativeEvent,
            coordinate: { latitude: lat, longitude: lng },
            position: { x, y },
          },
        });
      }
    };
    const handleIdle = ({ center, bounds }: ChangeEventValue) => {
      if (onRegionChangeComplete && validBounds(bounds)) {
        onRegionChangeComplete({
          latitude: center.lat,
          longitude: center.lng,
          latitudeDelta: Math.abs(bounds.ne.lat - bounds.sw.lat),
          longitudeDelta: Math.abs(bounds.ne.lng - bounds.sw.lng),
        });
      }
    };
    return (
      <GoogleApi.Provider value={context}>
        <View style={style}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.googleMaps }}
            center={region && { lat: region.latitude, lng: region.longitude }}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            onChange={handleIdle}
            onClick={handleClick}
            onGoogleApiLoaded={({ map, maps }) => {
              mapRef.current = map;
              setContext({ map, maps });
              if (initialRegion) {
                const zoom = getZoomFromLongitudeDelta(
                  initialRegion.longitudeDelta
                );
                map.panTo({
                  lat: initialRegion.latitude,
                  lng: initialRegion.longitude,
                });
                map.setZoom(zoom);
              }
            }}
            onDrag={onPanDrag}
            options={() => ({
              clickableIcons: false,
              draggable: scrollEnabled,
              fullscreenControl: false,
              mapTypeControl: false,
              panControl: false,
              rotateControl: rotateEnabled,
              scaleControl: false,
              scrollwheel: zoomEnabled,
              streetViewControl: false,
              styles: showsPointsOfInterest
                ? customMapStyle
                : [
                    ...customMapStyle,
                    { featureType: "poi", stylers: [{ visibility: "off" }] },
                  ],
              zoomControl: false,
            })}
            yesIWantToUseGoogleMapApiInternals
            zoom={region && getZoomFromLongitudeDelta(region.longitudeDelta)}
          >
            {showsTraffic && <TrafficLayer />}
            {showsUserLocation && userCoordinates && (
              <CurrentLocationMarker
                lat={userCoordinates.latitude}
                lng={userCoordinates.longitude}
              />
            )}
            {showsUserLocation && userCoordinates && (
              <Circle
                radius={userCoordinates.accuracy}
                center={{
                  latitude: userCoordinates.latitude,
                  longitude: userCoordinates.longitude,
                }}
                fillColor={theme["color-info-transparent-300"]}
              />
            )}
            {Boolean(context) && children}
          </GoogleMapReact>
        </View>
      </GoogleApi.Provider>
    );
  }
);

MapView.defaultProps = {
  children: undefined,
};

export default MapView;
