import Auth from "@aws-amplify/auth";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import { getRole } from "../getUserRole";
import login from "../../api/functions/login";
import { setToken } from "../../notifications";
import store from "../../store";
import { initSession as init } from "../../store/auth/slice";

const initSession = async () => {
  const { dispatch } = store;
  dispatch(init.pending());
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    try {
      let token;
      if (Platform.OS !== "web") {
        // Try to get the notifications setup but don't prevent initSession from
        // finishing if they say no to permissions.
        const { status } = await Notifications.requestPermissionsAsync();
        if (status === "granted") {
          // Workaround for Notifications.getExpoPushTokenAsync() not working in
          // dev builds (https://github.com/expo/expo/issues/18570#issuecomment-1235788144)
          if (__DEV__) {
            const appConfig = require("../../../app.json");
            const projectId = appConfig?.expo?.extra?.eas?.projectId;
            token = (
              await Notifications.getExpoPushTokenAsync({
                projectId,
              })
            ).data;
          } else {
            token = (
              await Notifications.getExpoPushTokenAsync({
                projectId: "e81af7d2-8720-414c-9acb-4d062d3d43e8",
              })
            ).data;
          }
          await setToken(token);
        }
      }
      await login(token);
    } catch (error) {
      // Do nothing
    }
    const groups =
      currentUser.signInUserSession.accessToken.payload["cognito:groups"];
    const id = currentUser.username;
    const user = {
      id,
      companyId: currentUser.attributes["custom:company_id"],
      group: getRole(groups),
    };
    dispatch(init.fulfilled({ user }));
  } catch (error) {
    dispatch(init.rejected({ error }));
  }
};

export default initSession;
