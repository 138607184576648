import { TooltipProps, Text } from "@ui-kitten/components";
import React, { ReactElement, useContext } from "react";
import { StyleSheet, View } from "react-native";

import { TourContext } from "../Tour";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Tooltip from "../buildingBlocks/Tooltip";
import useColorScheme from "../../device/useColorScheme";

interface Props extends Pick<TooltipProps, "placement"> {
  children: ReactElement;
  name: string;
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.38)",
  },
  content: {
    maxWidth: 300,
    minWidth: 200,
  },
  footer: {
    alignItems: "center",
    flexDirection: "row",
  },
});

const TourStep = ({ children, name, placement }: Props) => {
  const colorScheme = useColorScheme();
  const context = useContext(TourContext);
  const renderTourStep = () => {
    if (!context) {
      return children;
    }
    const { index, next, skip, steps, visible } = context;
    if (!visible) {
      return children;
    }
    const currentStep = steps[index];
    const isLastStep = index === steps.length - 1;
    return (
      <Tooltip
        onBackdropPress={skip}
        placement={placement}
        title={
          <View style={styles.content}>
            {typeof currentStep.content === "number" ||
            typeof currentStep.content === "string" ? (
              <>
                <Separator size={4} />
                <Text category="s1">{currentStep.content}</Text>
              </>
            ) : (
              currentStep.content
            )}
            <Separator size="medium" />
            <View style={styles.footer}>
              {steps.length > 1 && (
                <Text
                  style={{ color: colorScheme === "light" ? "white" : "black" }}
                >{`${index + 1} of ${steps.length}`}</Text>
              )}
              <View style={{ flex: 1 }} />
              <Button onPress={next} size="small">
                {isLastStep ? "Done" : "Next"}
              </Button>
            </View>
          </View>
        }
        visible={currentStep.name === name}
      >
        {children}
      </Tooltip>
    );
  };
  return <>{renderTourStep()}</>;
};

export default TourStep;
