import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

const clockIn = gql`
  mutation clockIn($coordinates: CoordinatesInput, $timestamp: AWSDateTime!) {
    clockIn(coordinates: $coordinates, timestamp: $timestamp) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;

export default clockIn;
