import React from "react";
import { Platform, ScrollView, StyleSheet, View } from "react-native";
import { useSafeArea } from "react-native-safe-area-context";

import useKeyboardVisible from "../../../device/useKeyboardVisible";

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
});

interface ContentProps {
  children: React.ReactNode;
  scrollable: boolean;
}

const Content = ({ children, scrollable }: ContentProps) => {
  const keyboardVisible = useKeyboardVisible();
  const insets = useSafeArea();
  const iosContentContainerStyle =
    Platform.OS === "ios"
      ? {
          paddingBottom: 24 + (keyboardVisible ? 0 : insets.bottom),
        }
      : null;

  return scrollable ? (
    <ScrollView
      contentContainerStyle={iosContentContainerStyle}
      style={styles.flex}
    >
      {children}
    </ScrollView>
  ) : (
    <View style={[styles.flex, iosContentContainerStyle]}>{children}</View>
  );
};

export default Content;
