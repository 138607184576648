/* eslint-disable @typescript-eslint/naming-convention */
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Layout,
  Spinner,
  Text,
  useTheme,
} from "@ui-kitten/components";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import getUpcomingInvoice from "../../api/functions/getUpcomingInvoice";
import BillingPortalButton from "../../components/BillingPortalButton";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import selectCompany from "../../store/company/selectors/selectCompany";
import {
  StackParamList,
  StripeBillingScheme,
  StripeInvoiceItem,
} from "../../types";

const styles = StyleSheet.create({
  billingPortalContainer: {
    borderRadius: 8,
    borderWidth: 1,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  billingPortalButtonContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  billingPortalDescription: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  billingPortalTitle: {
    fontSize: 18,
    paddingEnd: 16,
    paddingStart: 16,
  },
  borderlessDataTableRow: {
    borderBottomWidth: 0,
  },
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  invoiceAmount: {
    flex: 1,
    textAlign: "right",
  },
  invoiceDescription: {
    flex: 3,
  },
  invoiceQuantity: {
    flex: 1,
    textAlign: "right",
  },
  invoiceRow: {
    alignItems: "center",
    flexDirection: "row",
    minHeight: 48,
    paddingEnd: 16,
    paddingStart: 16,
  },
  invoiceUnitPrice: {
    flex: 1,
    textAlign: "right",
  },
  root: {
    flex: 1,
  },
  spinnerContainer: {
    alignItems: "center",
  },
  summaryDescription: {
    flex: 1,
  },
  summaryHeaderRow: {
    alignItems: "center",
    flexDirection: "row",
    minHeight: 48,
    paddingEnd: 16,
    paddingStart: 16,
  },
  summaryRow: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  summaryValue: {
    flex: 5,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  tierDescription: {
    paddingStart: 32,
  },
  totalAmount: {
    flex: 1,
    textAlign: "right",
  },
  totalDescription: {
    flex: 5,
    textAlign: "right",
  },
  totalRow: {
    alignItems: "center",
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const dateTimeShortForm = "MMM D";
const dateTimeLongForm = "MMM D, YYYY";

const getCurrencyString = ({
  amount,
  currency,
}: {
  amount?: number;
  currency: string;
}) =>
  new Intl.NumberFormat("en-US", {
    currency,
    style: "currency",
  }).format(amount ? amount / 100 : 0);

const getPeriodString = ({ end, start }: { end: number; start: number }) => {
  const startMoment = moment.unix(start);
  const endMoment = moment.unix(end);
  if (startMoment.isSame(endMoment, "year")) {
    return `${startMoment.format(dateTimeShortForm)} - ${endMoment.format(
      dateTimeLongForm
    )}`;
  }
  return `${startMoment.format(dateTimeLongForm)} - ${endMoment.format(
    dateTimeLongForm
  )}`;
};

interface SubscriptionItem {
  billingScheme: StripeBillingScheme;
  id: string;
  name: string;
  quantity: number;
}

interface PerUnitSubscriptionItem extends SubscriptionItem {
  amount: number;
  unitAmount: number;
}

interface TieredSubscriptionItem extends SubscriptionItem {
  tiers: Array<{
    amount: number;
    id: string;
    name: string;
    quantity: number;
    unitAmount: number;
  }>;
}

const isPerUnitSubscriptionItem = (
  subscriptionItem: PerUnitSubscriptionItem | TieredSubscriptionItem
): subscriptionItem is PerUnitSubscriptionItem =>
  subscriptionItem.billingScheme === "per_unit";

const renderSubscriptionItem = ({
  currency,
  subscriptionItem,
}: {
  currency: string;
  subscriptionItem: PerUnitSubscriptionItem | TieredSubscriptionItem;
}) => {
  return (
    <Fragment key={subscriptionItem.id}>
      <View style={styles.invoiceRow}>
        <Text category="s1" style={styles.invoiceDescription}>
          {subscriptionItem.name}
        </Text>
        <Text style={styles.invoiceQuantity}>{subscriptionItem.quantity}</Text>
        <Text style={styles.invoiceUnitPrice}>
          {isPerUnitSubscriptionItem(subscriptionItem) &&
            getCurrencyString({
              amount: subscriptionItem.unitAmount,
              currency,
            })}
        </Text>
        <Text category="s1" style={styles.invoiceAmount}>
          {isPerUnitSubscriptionItem(subscriptionItem) &&
            getCurrencyString({
              amount: subscriptionItem.amount,
              currency,
            })}
        </Text>
      </View>
      <Divider style={styles.divider} />
      {!isPerUnitSubscriptionItem(subscriptionItem) &&
        subscriptionItem.tiers.map((tier) => (
          <Fragment key={tier.id}>
            <View style={styles.invoiceRow}>
              <View style={styles.invoiceDescription}>
                <Text style={styles.tierDescription}>{tier.name}</Text>
              </View>
              <Text style={styles.invoiceQuantity}>{tier.quantity}</Text>
              <Text style={styles.invoiceUnitPrice}>
                {getCurrencyString({
                  amount: tier.unitAmount,
                  currency,
                })}
              </Text>
              <Text category="s1" style={styles.invoiceAmount}>
                {getCurrencyString({
                  amount: tier.amount,
                  currency,
                })}
              </Text>
            </View>
            <Divider style={styles.divider} />
          </Fragment>
        ))}
    </Fragment>
  );
};

const renderSubscriptions = ({
  currency,
  lines,
}: {
  currency: string;
  lines: {
    data: Array<StripeInvoiceItem>;
  };
}) => {
  const sections: {
    [period: string]: {
      period: string;
      subscriptionItems: {
        [id: string]: PerUnitSubscriptionItem | TieredSubscriptionItem;
      };
    };
  } = {};
  lines.data.forEach((line) => {
    const period = getPeriodString(line.period);
    if (!sections[period]) {
      sections[period] = {
        period,
        subscriptionItems: {},
      };
    }
    if (!sections[period].subscriptionItems[line.subscription_item]) {
      const subscriptionItem = {
        billingScheme: line.price.billing_scheme,
        name: `${line.price.product.name}${
          line.price.product.unit_label
            ? ` (per ${line.price.product.unit_label})`
            : ""
        }`,
        quantity: line.quantity,
      };
      if (line.price.billing_scheme === "per_unit") {
        sections[period].subscriptionItems[line.subscription_item] = {
          ...subscriptionItem,
          amount: line.amount,
          id: line.id,
          unitAmount: line.price.unit_amount,
        };
      } else {
        const firstTier = line.price.tiers[0];
        sections[period].subscriptionItems[line.subscription_item] = {
          ...subscriptionItem,
          id: line.subscription_item,
          tiers: [
            {
              amount: line.amount,
              id: line.id,
              name: `First ${firstTier.up_to}`,
              quantity: line.quantity,
              unitAmount: firstTier.unit_amount,
            },
          ],
        };
      }
    } else {
      const subscriptionItem =
        sections[period].subscriptionItems[line.subscription_item];
      if (!isPerUnitSubscriptionItem(subscriptionItem)) {
        subscriptionItem.quantity += line.quantity;
        const tier = line.price.tiers[subscriptionItem.tiers.length];
        const previousTier =
          line.price.tiers[subscriptionItem.tiers.length - 1];
        subscriptionItem.tiers.push({
          amount: line.amount,
          id: line.id,
          name: tier.up_to
            ? `${previousTier.up_to + 1} to ${tier.up_to}`
            : `${previousTier.up_to + 1} and above`,
          quantity: line.quantity,
          unitAmount: tier.unit_amount,
        });
      }
    }
  });
  return Object.values(sections).map((section) => (
    <Fragment key={section.period}>
      <View style={styles.invoiceRow}>
        <Text appearance="hint">{section.period}</Text>
      </View>
      <Divider style={styles.divider} />
      {Object.values(section.subscriptionItems).map((subscriptionItem) =>
        renderSubscriptionItem({ currency, subscriptionItem })
      )}
    </Fragment>
  ));
};

const Billing = () => {
  const insets = useSafeAreaInsets();
  const company = useSelector(selectCompany);
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "Billing">
  >();
  const theme = useTheme();

  const [upcomingInvoice, setUpcomingInvoice] = useState({
    data: null,
    loading: false,
  });

  useEffect(() => {
    (async () => {
      setUpcomingInvoice((prevState) => ({ ...prevState, loading: true }));
      try {
        const data = await getUpcomingInvoice();
        setUpcomingInvoice({ data, loading: false });
      } catch (error) {
        addNotification({ message: error.message, status: "danger" });
        setUpcomingInvoice((prevState) => ({ ...prevState, loading: false }));
      }
    })();
  }, []);

  const renderContent = () => {
    if (upcomingInvoice.loading) {
      return (
        <View style={styles.spinnerContainer}>
          <Separator />
          <Spinner />
          <Separator />
        </View>
      );
    }
    if (upcomingInvoice.data) {
      const {
        amount_due,
        currency,
        customer_address,
        customer_email,
        customer_name,
        customer_phone,
        lines,
        subtotal,
        total,
        total_discount_amounts,
        total_tax_amounts,
      } = upcomingInvoice.data;
      return (
        <>
          <View style={styles.summaryHeaderRow}>
            <Text appearance="hint" category="label">
              SUMMARY
            </Text>
          </View>
          <Divider style={styles.divider} />
          <Separator size="medium" />
          <View style={styles.summaryRow}>
            <Text appearance="hint" style={styles.summaryDescription}>
              Bill to
            </Text>
            <Text style={styles.summaryValue}>{customer_email}</Text>
          </View>
          <Separator size="small" />
          <View style={styles.summaryRow}>
            <Text appearance="hint" style={styles.summaryDescription}>
              Name
            </Text>
            <Text
              appearance={customer_name ? "default" : "hint"}
              style={styles.summaryValue}
            >
              {customer_name || "No name"}
            </Text>
          </View>
          <Separator size="small" />
          <View style={styles.summaryRow}>
            <Text appearance="hint" style={styles.summaryDescription}>
              Address
            </Text>
            <View style={styles.summaryValue}>
              {customer_address ? (
                <>
                  <Text>{customer_address.line1}</Text>
                  <Text>{`${customer_address.city}, ${customer_address.state} ${customer_address.postal_code} ${customer_address.country}`}</Text>
                </>
              ) : (
                <Text appearance="hint">No address</Text>
              )}
            </View>
          </View>
          <Separator size={4} />
          <View style={styles.summaryRow}>
            <Text appearance="hint" style={styles.summaryDescription}>
              Phone
            </Text>
            <Text
              appearance={customer_phone ? "default" : "hint"}
              style={styles.summaryValue}
            >
              {customer_phone || "No phone"}
            </Text>
          </View>
          <Separator />
          <View style={styles.invoiceRow}>
            <Text
              appearance="hint"
              category="label"
              style={styles.invoiceDescription}
            >
              DESCRIPTION
            </Text>
            <Text
              appearance="hint"
              category="label"
              style={styles.invoiceQuantity}
            >
              QTY
            </Text>
            <Text
              appearance="hint"
              category="label"
              style={styles.invoiceUnitPrice}
            >
              UNIT PRICE
            </Text>
            <Text
              appearance="hint"
              category="label"
              style={styles.invoiceAmount}
            >
              AMOUNT
            </Text>
          </View>
          <Divider style={styles.divider} />
          {renderSubscriptions({ currency, lines })}
          <Separator size="medium" />
          <View style={styles.totalRow}>
            <Text category="s1" style={styles.totalDescription}>
              Subtotal
            </Text>
            <Text category="s1" style={styles.totalAmount}>
              {getCurrencyString({
                amount: subtotal,
                currency,
              })}
            </Text>
          </View>
          {total_discount_amounts.map((total_discount_amount) => (
            <Fragment key={total_discount_amount.discount.id}>
              <Separator size="small" />
              <View style={styles.totalRow}>
                <Text appearance="hint" style={styles.totalDescription}>
                  {total_discount_amount.discount.coupon.name}
                </Text>
                <Text category="s1" style={styles.totalAmount}>
                  {`-${getCurrencyString({
                    amount: total_discount_amount.amount,
                    currency,
                  })}`}
                </Text>
              </View>
            </Fragment>
          ))}
          {total_tax_amounts.map((total_tax_amount) => (
            <Fragment key={total_tax_amount.tax_rate.id}>
              <Separator size="small" />
              <View style={styles.totalRow}>
                <Text appearance="hint" style={styles.totalDescription}>
                  {`${total_tax_amount.tax_rate.display_name} – ${total_tax_amount.tax_rate.jurisdiction} (${total_tax_amount.tax_rate.percentage}%)`}
                </Text>
                <Text category="s1" style={styles.totalAmount}>
                  {getCurrencyString({
                    amount: total_tax_amount.amount,
                    currency,
                  })}
                </Text>
              </View>
            </Fragment>
          ))}
          <Separator size="small" />
          <View style={styles.totalRow}>
            <Text category="s1" style={styles.totalDescription}>
              Total
            </Text>
            <Text category="s1" style={styles.totalAmount}>
              {getCurrencyString({
                amount: total,
                currency,
              })}
            </Text>
          </View>
          <Separator size="small" />
          <View style={styles.totalRow}>
            <Text category="s1" style={styles.totalDescription}>
              Amount due
            </Text>
            <Text category="s1" style={styles.totalAmount}>
              {getCurrencyString({
                amount: amount_due,
                currency,
              })}
            </Text>
          </View>
        </>
      );
    }
    return null;
  };

  return (
    <Layout style={styles.root}>
      <Container>
        <View
          style={{
            paddingEnd: insets.right,
            paddingStart: insets.left,
            paddingTop: insets.top,
          }}
        >
          <HeaderWithTextAction
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Billing"
          />
        </View>
      </Container>
      <Divider />
      <Layout level="2" style={styles.content}>
        <ScrollView>
          <Container>
            <Layout>
              <Separator size="medium" />
              <View
                style={[
                  styles.billingPortalContainer,
                  {
                    backgroundColor: theme["color-info-transparent-100"],
                    borderColor: theme["color-info-transparent-default-border"],
                  },
                ]}
              >
                <Separator />
                <Text style={styles.billingPortalTitle}>Billing portal</Text>
                <Separator size={4} />
                <Text style={styles.billingPortalDescription}>
                  Update your payment method and billing information, cancel
                  your subscription, or view your payment history.
                </Text>
                <Separator />
                <View style={styles.billingPortalButtonContainer}>
                  <BillingPortalButton size="small" />
                </View>
                <Separator />
              </View>
              <Separator />
              <Text category="h6" style={styles.text}>
                Upcoming invoice
              </Text>
              <Separator size={4} />
              <Text
                category="p1"
                style={styles.text}
              >{`Will be billed on ${moment
                .unix(company.billing.currentPeriodEnd)
                .format(dateTimeLongForm)}`}</Text>
              <Separator size="medium" />
              {renderContent()}
              <Separator />
            </Layout>
          </Container>
        </ScrollView>
      </Layout>
    </Layout>
  );
};

export default Billing;
