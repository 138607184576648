import { Toggle } from "@ui-kitten/components";
import React, { useCallback } from "react";
import { StyleSheet } from "react-native";

import ListItem, { ListItemProps } from "../Base";

interface ToggleListItemProps extends Pick<ListItemProps, "title"> {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const styles = StyleSheet.create({
  toggle: {
    marginHorizontal: 8,
  },
});

const ToggleListItem = ({
  checked,
  onChange,
  style,
  title,
}: ToggleListItemProps) => {
  const accessoryRight = useCallback(
    () => (
      <Toggle checked={checked} onChange={onChange} style={styles.toggle} />
    ),
    [checked]
  );
  return (
    <ListItem
      accessoryRight={accessoryRight}
      disabled
      style={style}
      title={title}
    />
  );
};

export default ToggleListItem;
