import { CheckBox } from "@ui-kitten/components";
import React, { Fragment } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import useActionItemList from "../../../hooks/useActionItemList";
import useJobUtils from "../../../hooks/useJobUtils";
import { ActionItem, ActionItemCompletionRecord, Job } from "../../../types";
import ActionItemListItem from "../../listItems/ActionItem";
import ActionItemCompletionRecordListItem from "../../listItems/ActionItemCompletionRecord";

interface CompletableActionItemsListProps {
  job: Job;
  onActionItemPress: (actionItem: ActionItem) => void;
  onActionItemCompletionRecordPress: ({
    actionItem,
    actionItemCompletionRecord,
  }: {
    actionItem: ActionItem;
    actionItemCompletionRecord: ActionItemCompletionRecord;
  }) => void;
  reportsLoading?: boolean;
}

const StyledCheckBox = styled(CheckBox)`
  margin-left: 16px;
  margin-right: 16px;
`;

const CompletableActionItemsList = ({
  job,
  onActionItemPress,
  onActionItemCompletionRecordPress,
  reportsLoading,
}: CompletableActionItemsListProps) => {
  const { actionItems } = useActionItemList();
  const { getActionItemCompletionRecordByActionItemId } = useJobUtils(job.id);

  const accessoryLeft = () => (
    <View pointerEvents="none">
      <StyledCheckBox />
    </View>
  );

  const renderListItem = (actionItem: ActionItem) => {
    const actionItemCompletionRecord = getActionItemCompletionRecordByActionItemId(
      actionItem.id
    );
    if (actionItemCompletionRecord) {
      return (
        <ActionItemCompletionRecordListItem
          actionItem={actionItem}
          actionItemCompletionRecord={actionItemCompletionRecord}
          jobId={job.id}
          onPress={onActionItemCompletionRecordPress}
          reportsLoading={reportsLoading}
        />
      );
    }
    return (
      <ActionItemListItem
        accessoryLeft={accessoryLeft}
        actionItem={actionItem}
        onPress={onActionItemPress}
      />
    );
  };

  return (
    <>
      {actionItems.ids.map((actionItemId) => (
        <Fragment key={actionItemId}>
          {renderListItem(actionItems.entities[actionItemId])}
        </Fragment>
      ))}
    </>
  );
};

export default CompletableActionItemsList;
