import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripePrice } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface CreatePriceInput
  extends Pick<
    Stripe.PriceCreateParams,
    "currency" | "metadata" | "unit_amount"
  > {
  product_data: Pick<Stripe.PriceCreateParams.ProductData, "name">;
}

const createPrice = async ({
  currency,
  metadata,
  product_data,
  unit_amount,
}: CreatePriceInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        currency,
        metadata,
        product_data,
        unit_amount,
      },
    })) as GraphQLResult<{ createPrice: StripePrice }>;
    return data.createPrice;
  } catch ({ errors }) {
    throw generateErrorMessage("createPrice", errors);
  }
};

export default createPrice;
