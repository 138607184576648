import { createSelector } from "@reduxjs/toolkit";

import { State } from "../../slice";

const selectSites = createSelector(
  [(state: any): State => state.data.sites],
  (sites) => {
    const entities = {
      ...sites.entities,
      ...sites.optimistic.entities,
    };
    return {
      entities,
      ids: Object.keys(entities),
    };
  }
);

export default selectSites;
