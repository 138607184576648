import { createSelector } from "@reduxjs/toolkit";

import selectSignedInUser from "../selectSignedInUser";

const selectSignedInUserRole = createSelector(
  [selectSignedInUser],
  (signedInUser) => signedInUser?.role
);

export default selectSignedInUserRole;
