import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeTaxRate } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export type CreateTaxRateInput = Pick<
  Stripe.TaxRateCreateParams,
  "display_name" | "inclusive" | "percentage"
>;

const createTaxRate = async ({
  display_name,
  inclusive,
  percentage,
}: CreateTaxRateInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        display_name,
        inclusive,
        percentage,
      },
    })) as GraphQLResult<{ createTaxRate: StripeTaxRate }>;
    return data.createTaxRate;
  } catch ({ errors }) {
    throw generateErrorMessage("createTaxRate", errors);
  }
};

export default createTaxRate;
