import { CompanyUserRole } from "../../types";

interface CompanyUserRoleConstants {
  [key: string]: {
    description: string;
    permissions: Array<string>;
    value: CompanyUserRole;
  };
}

const companyUserRolesExtended: CompanyUserRoleConstants = {
  EMPLOYEE: {
    value: "Employee",
    description:
      "Restricted access. Employees are not able to see other users on the map or edit the company account in any way. Intended for people who only need to complete work and not manage other users.",
    permissions: [
      "Only employee view on jobs screen",
      "Starting and completing jobs",
      "Report creation",
      "Other users only visible if they are assigned to same job",
      "Create time cards",
      "Search for only their own records",
      "Send messages to any users in company",
    ],
  },
  SUPERVISOR: {
    value: "Supervisor",
    description:
      "Limited access. Supervisors can create and update anything besides other users. More limited, but should be able to manage employee workflows.",
    permissions: [
      "All employee permissions",
      "View all other users on map",
      "Update other's time cards",
      "Search for any company records",
      "Delete jobs",
      "Create jobs, locations, users, and groups",
    ],
  },
  ADMIN: {
    value: "Admin",
    description:
      "Full access. Admins have the ability to create, edit, or delete everything. Full access to change company data. Only role with billing access.",
    permissions: [
      "All supervisor permissions",
      "Delete or archive jobs, locations, groups, and users",
      "Update company settings",
      "Access to billing settings on web app",
    ],
  },
};

export default companyUserRolesExtended;
