import { createSelector } from "@reduxjs/toolkit";

import formatSectionListData from "../../../../utils/formatSectionListData";
import selectEmailActivityEntities from "../selectEmailActivityEntities";
import { EmailActivity } from "../../../../types";

const selectQueriedEmailActivities = createSelector(
  [
    selectEmailActivityEntities,
    (state: any) => state.emailActivity.queryDateTimes,
    (state: any) => state.emailActivity.emails,
  ],
  (emailActivities, queryDateTimes, emails) => {
    const emailActivitiesArray = Object.values(emailActivities);
    const { endDateTime, startDateTime } = queryDateTimes;
    const filteredItems =
      emails.length > 0
        ? emailActivitiesArray.filter((emailActivity) =>
            emails.includes(emailActivity.recipient.trim().toLowerCase())
          )
        : emailActivitiesArray;
    return formatSectionListData({
      data: filteredItems,
      startDateTimeExtractor: (item: EmailActivity) => item.eventDateTime,
      idExtractor: (item: EmailActivity) => item.id,
      sortCompareFunction: (a: EmailActivity, b: EmailActivity) =>
        a.eventDateTime.localeCompare(b.eventDateTime),
      to: endDateTime,
      from: startDateTime,
    });
  }
);

export default selectQueriedEmailActivities;
