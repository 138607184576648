import Auth from "@aws-amplify/auth";
import { Divider, Input, Text } from "@ui-kitten/components";
import { useFormik } from "formik";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import * as yup from "yup";

import Header from "../../../buildingBlocks/Header";
import Separator from "../../../buildingBlocks/Separator";
import { addNotification } from "../../../InAppNotifications";
import LoadingButton from "../../../LoadingButton";
import useAndroidBackHandler from "../../../../device/useAndroidBackHandler";
import PasswordInput from "../../../inputs/Password";

const styles = StyleSheet.create({
  description: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  scrollContent: {
    paddingTop: 8,
  },
});

const getDeliveryDescription = () => {
  return `We've sent you a code, enter it below to reset your password.`;
};

interface NewPasswordScreenProps {
  onBackPress: () => void;
  onSuccess: () => void;
  username: string;
  visible: boolean;
}

const NewPasswordScreen = ({
  onBackPress,
  onSuccess,
  username,
  visible,
}: NewPasswordScreenProps) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      recoveryCode: "",
    },
    validationSchema: yup.object({
      recoveryCode: yup.string().required("Recovery code is required."),
      password: yup
        .string()
        .min(8, "Password must be at least 8 characters.")
        .required("Password is required."),
    }),
    onSubmit: async (values, actions) => {
      try {
        await Auth.forgotPasswordSubmit(
          username,
          values.recoveryCode,
          values.password
        );
        onSuccess();
      } catch (error) {
        addNotification({
          message: (error && error.message) || error,
          status: "danger",
          title: "Error",
        });
      }
      actions.setSubmitting(false);
    },
  });
  useAndroidBackHandler({ enabled: visible, onBackPress });
  if (!username) {
    return null;
  }
  return (
    <>
      <Header
        navigation={{ icon: "close-outline", onPress: onBackPress }}
        title="Reset password"
      />
      <Divider />
      <ScrollView contentContainerStyle={styles.scrollContent}>
        <Separator size="small" />
        <Text style={styles.description}>{getDeliveryDescription()}</Text>
        <Separator size="medium" />
        <Input
          keyboardType="number-pad"
          label="Code"
          onBlur={() => formik.setFieldTouched("recoveryCode")}
          onChangeText={formik.handleChange("recoveryCode")}
          style={styles.input}
          value={formik.values.recoveryCode}
          status={
            formik.errors.recoveryCode &&
            formik.touched.recoveryCode &&
            "danger"
          }
          caption={formik.touched.recoveryCode && formik.errors.recoveryCode}
          textContentType="oneTimeCode"
        />
        <Separator size="medium" />
        <PasswordInput
          onBlur={() => formik.setFieldTouched("password")}
          onChangeText={formik.handleChange("password")}
          style={styles.input}
          value={formik.values.password}
          status={formik.errors.password && formik.touched.password && "danger"}
          caption={formik.touched.password && formik.errors.password}
        />
      </ScrollView>
      <Divider />
      <View style={styles.footer}>
        <LoadingButton
          onPress={formik.handleSubmit}
          disabled={
            !formik.values.recoveryCode ||
            !formik.values.password ||
            Object.values(formik.errors).length > 0
          }
          loading={formik.isSubmitting}
        >
          Set new password
        </LoadingButton>
      </View>
    </>
  );
};

export default NewPasswordScreen;
