import { Layout } from "@ui-kitten/components";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Animated, Dimensions, Easing, StyleSheet } from "react-native";

import Container from "../../Container";
import Portal from "../../Portal";
import SafeAreaView from "../../SafeAreaView";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";

export interface Props {
  isVisible: boolean;
  onClose: () => void;
  onEntered?: () => void;
  onExited?: () => void;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fullScreen: {
    margin: 0,
  },
  modalBackdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#000",
  },
  modalSlider: {
    ...StyleSheet.absoluteFillObject,
  },
  root: {
    flex: 1,
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
    marginTop: 16,
    overflow: "hidden",
  },
  safeAreaView: {
    flex: 1,
  },
});

const FullScreenModal = ({
  children,
  isVisible,
  onClose,
  onEntered,
  onExited,
}: PropsWithChildren<Props>) => {
  const windowHeight = Dimensions.get("window").height;
  const [componentMounted, setComponentMounted] = useState(isVisible);
  const translateYValue = useRef(new Animated.Value(windowHeight)).current;
  const opacity = useRef(new Animated.Value(0)).current;
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  useEffect(() => {
    if (isVisible) {
      setComponentMounted(true);
      Animated.parallel([
        Animated.timing(translateYValue, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
          easing: Easing.inOut(Easing.cubic),
        }),
        Animated.timing(opacity, {
          toValue: 0.4,
          duration: 500,
          useNativeDriver: true,
          easing: Easing.inOut(Easing.cubic),
        }),
      ]).start(() => onEntered());
    } else {
      Animated.parallel([
        Animated.timing(translateYValue, {
          toValue: windowHeight,
          duration: 500,
          useNativeDriver: true,
          easing: Easing.inOut(Easing.cubic),
        }),
        Animated.timing(opacity, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
          easing: Easing.inOut(Easing.cubic),
        }),
      ]).start(() => {
        setComponentMounted(false);
        onExited();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  if (!componentMounted) {
    return null;
  }
  return (
    <Portal>
      <Animated.View
        style={[
          styles.modalBackdrop,
          {
            opacity,
          },
        ]}
      />
      <Animated.View
        style={[
          styles.modalSlider,
          {
            transform: [
              {
                translateY: translateYValue,
              },
            ],
          },
        ]}
      >
        <Container style={styles.container}>
          <SafeAreaView
            edges={["left", "right", "top"]}
            style={styles.safeAreaView}
          >
            <Layout style={styles.root}>{children}</Layout>
          </SafeAreaView>
        </Container>
      </Animated.View>
    </Portal>
  );
};

FullScreenModal.defaultProps = {
  onEntered: () => null,
  onExited: () => null,
};

export default FullScreenModal;
