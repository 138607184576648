import React, { ReactNode } from "react";
import {
  KeyboardAvoidingView as RnKeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";

interface Props {
  children?: ReactNode;
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
});

const KeyboardAvoidingView = ({ children }: Props) => {
  return (
    <>
      {Platform.OS === "android" ? (
        children
      ) : (
        <RnKeyboardAvoidingView
          behavior="padding"
          style={styles.keyboardAvoidingView}
        >
          {children}
        </RnKeyboardAvoidingView>
      )}
    </>
  );
};

KeyboardAvoidingView.defaultProps = {
  children: undefined,
};

export default KeyboardAvoidingView;
