import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Layout, ListItem, Text } from "@ui-kitten/components";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import SectionItem from "../../components/SectionItem";
import regions from "../../constants/regions";
import {
  ethnicity,
  eyeColor,
  hairColor,
  race,
  sex,
  type,
} from "../../constants/subject";
import selectReportByIdAndJobId from "../../store/jobs/selectors/selectReportByIdAndJobId";
import { StackParamList } from "../../types";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  text: {
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const SubjectDetails = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "SubjectDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "SubjectDetails">>();
  const { reportId, index, jobId } = route.params;
  const incidentReport = useSelector((state) =>
    selectReportByIdAndJobId(state, { jobId, id: reportId })
  );
  if ("subjects" in incidentReport) {
    const subject = incidentReport.subjects[index];
    const renderGeneral = Boolean(
      subject.address ||
        subject.description ||
        subject.name ||
        subject.phoneNumber ||
        subject.type
    );
    const renderProfile = Boolean(
      subject.age ||
        subject.dateOfBirth ||
        subject.ethnicity ||
        subject.eyeColor ||
        subject.hairColor ||
        subject.height ||
        subject.race ||
        subject.sex ||
        subject.weight
    );
    const renderDriversLicense = Boolean(subject.license);
    return (
      <Layout style={styles.root}>
        <View
          style={{
            paddingEnd: insets.right,
            paddingStart: insets.left,
            paddingTop: insets.top,
          }}
        >
          <HeaderWithTextAction
            navigation={{
              icon: "arrow-back-outline",
              onPress: navigation.goBack,
            }}
            title="Subject details"
          />
        </View>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentContainerStyle={[
              styles.scrollContent,
              { paddingBottom: insets.bottom + 8 },
            ]}
          >
            {renderGeneral && (
              <>
                <Separator />
                <SectionItem title="GENERAL">
                  {subject.name && (
                    <ListItem
                      title="Name"
                      accessoryRight={() => (
                        <Text style={styles.text}>{subject.name}</Text>
                      )}
                      disabled
                    />
                  )}
                  {subject.type && (
                    <>
                      {Boolean(subject.name) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Type"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {type[subject.type].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.description && (
                    <>
                      {Boolean(subject.name || subject.type) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Description"
                        description={subject.description}
                        disabled
                      />
                    </>
                  )}
                  {subject.address && (
                    <>
                      {Boolean(
                        subject.name || subject.type || subject.description
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Address"
                        accessoryRight={() => (
                          <Text style={styles.text}>{subject.address}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.phoneNumber && (
                    <>
                      {Boolean(
                        subject.name ||
                          subject.type ||
                          subject.description ||
                          subject.address
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Phone"
                        accessoryRight={() => (
                          <Text style={styles.text}>{subject.phoneNumber}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                </SectionItem>
              </>
            )}
            {renderProfile && (
              <>
                <Separator />
                <SectionItem title="PROFILE">
                  {subject.age && (
                    <ListItem
                      title="Age"
                      accessoryRight={() => (
                        <Text style={styles.text}>{subject.age}</Text>
                      )}
                      disabled
                    />
                  )}
                  {subject.dateOfBirth && (
                    <>
                      {Boolean(subject.age) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Date of birth"
                        accessoryRight={() => (
                          <Text style={styles.text}>{subject.dateOfBirth}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.race && (
                    <>
                      {Boolean(subject.age || subject.dateOfBirth) && (
                        <Divider style={styles.divider} />
                      )}
                      <ListItem
                        title="Race"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {race[subject.race].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.ethnicity && (
                    <>
                      {Boolean(
                        subject.age || subject.dateOfBirth || subject.race
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Ethnicity"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {ethnicity[subject.ethnicity].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.height && (
                    <>
                      {Boolean(
                        subject.age ||
                          subject.dateOfBirth ||
                          subject.race ||
                          subject.ethnicity
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Height"
                        accessoryRight={() => (
                          <Text style={styles.text}>{subject.height}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.weight && (
                    <>
                      {Boolean(
                        subject.age ||
                          subject.dateOfBirth ||
                          subject.race ||
                          subject.ethnicity ||
                          subject.height
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Weight"
                        accessoryRight={() => (
                          <Text style={styles.text}>{subject.weight}</Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.hairColor && (
                    <>
                      {Boolean(
                        subject.age ||
                          subject.dateOfBirth ||
                          subject.race ||
                          subject.ethnicity ||
                          subject.height ||
                          subject.weight
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Hair color"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {hairColor[subject.hairColor].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.eyeColor && (
                    <>
                      {Boolean(
                        subject.age ||
                          subject.dateOfBirth ||
                          subject.race ||
                          subject.ethnicity ||
                          subject.height ||
                          subject.weight ||
                          subject.hairColor
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Eye color"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {eyeColor[subject.eyeColor].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                  {subject.sex && (
                    <>
                      {Boolean(
                        subject.age ||
                          subject.dateOfBirth ||
                          subject.race ||
                          subject.ethnicity ||
                          subject.height ||
                          subject.weight ||
                          subject.hairColor ||
                          subject.eyeColor
                      ) && <Divider style={styles.divider} />}
                      <ListItem
                        title="Sex"
                        accessoryRight={() => (
                          <Text style={styles.text}>
                            {sex[subject.sex].text}
                          </Text>
                        )}
                        disabled
                      />
                    </>
                  )}
                </SectionItem>
              </>
            )}
            {renderDriversLicense && (
              <>
                <Separator />
                <SectionItem title={"DRIVER'S LICENSE"}>
                  <ListItem
                    title="License #"
                    accessoryRight={() => (
                      <Text style={styles.text}>{subject.license.number}</Text>
                    )}
                    disabled
                  />
                  <Divider style={styles.divider} />
                  <ListItem
                    title="State"
                    accessoryRight={() => (
                      <Text style={styles.text}>
                        {regions[subject.license.region].text}
                      </Text>
                    )}
                    disabled
                  />
                </SectionItem>
              </>
            )}
            <Separator />
          </ScrollView>
        </Layout>
      </Layout>
    );
  }
  throw new Error("No subject found.");
};

export default SubjectDetails;
