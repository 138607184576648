import gql from "graphql-tag";

import { dailyActivityReportFragment } from "../../../fragments";

export default gql`
  mutation adminDeleteDailyActivityReport($id: ID!, $jobId: ID!) {
    adminDeleteDailyActivityReport(id: $id, jobId: $jobId) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;
