import { Button, Divider, Layout, Text } from "@ui-kitten/components";
import dayjs, { OpUnitType, QUnitType } from "dayjs";
import React, { ReactElement } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { ProgressBar } from "react-native-paper";

import BasicTimeRangePicker from "../BasicTimeRangePicker";
import Separator from "../buildingBlocks/Separator";
import { Props as DefaultDayProps } from "../calendar/DefaultDay";
import WeeklyCalendar from "../calendar/WeeklyCalendar";
import BaseListItem from "../listItems/Base";
import getWeekBounds from "../../utils/getWeekBounds";
import { addNotification } from "../InAppNotifications";

interface DateTimeRange {
  endDateTime: string;
  startDateTime: string;
}

export type Mode = "custom" | "week";

interface Props {
  customRange: Partial<DateTimeRange>;
  loading?: boolean;
  maxSpan?: {
    unit: OpUnitType | QUnitType;
    value: number;
  };
  mode: Mode;
  onCustomRangeChanged: (state: Partial<DateTimeRange>) => void;
  onModeChanged: (mode: Mode) => void;
  onQueryRangeChanged: (queryRange: DateTimeRange) => void;
  onSelectedDateChanged: (selectedDate: string) => void;
  renderCalendarDay?: (props: DefaultDayProps) => ReactElement;
  selectedDate: string;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  basicTimeRangePickerContainer: {
    borderRadius: 8,
    overflow: "hidden",
  },
  basicTimeRangePickerDivider: {
    marginStart: 16,
  },
  modeSelector: {
    flexDirection: "row",
  },
  roundedButton: {
    borderRadius: 100,
  },
  weeklyCalendarContainer: {
    borderRadius: 8,
    overflow: "hidden",
  },
});

const TimeRangePicker = ({
  customRange,
  loading,
  maxSpan,
  mode,
  onCustomRangeChanged,
  onModeChanged,
  onQueryRangeChanged,
  onSelectedDateChanged,
  renderCalendarDay,
  selectedDate,
  style,
}: Props) => {
  return (
    <View style={style}>
      <View style={styles.modeSelector}>
        <Button
          appearance={mode === "week" ? "filled" : "ghost"}
          onPress={() => {
            onModeChanged("week");
            const weekBounds = getWeekBounds(selectedDate);
            onQueryRangeChanged({
              endDateTime: weekBounds.endDateTime,
              startDateTime: weekBounds.startDateTime,
            });
          }}
          size="tiny"
          style={styles.roundedButton}
        >
          Week
        </Button>
        <Separator horizontal size="small" />
        <Button
          appearance={mode === "custom" ? "filled" : "ghost"}
          onPress={() => {
            onModeChanged("custom");
            if (customRange.startDateTime && customRange.endDateTime) {
              if (
                !maxSpan ||
                dayjs(customRange.endDateTime).diff(
                  customRange.startDateTime,
                  maxSpan.unit,
                  true
                ) <= maxSpan.value
              ) {
                onQueryRangeChanged({
                  endDateTime: customRange.endDateTime,
                  startDateTime: customRange.startDateTime,
                });
              } else {
                addNotification({
                  message: `Cannot query a larger span than ${maxSpan.value} ${
                    maxSpan.unit
                  }${maxSpan.value > 1 ? "s" : ""}`,
                  status: "danger",
                  title: "Error",
                });
              }
            }
          }}
          size="tiny"
          style={styles.roundedButton}
        >
          Custom
        </Button>
      </View>
      <Separator size="small" />
      {mode === "custom" && (
        <>
          <Layout style={styles.basicTimeRangePickerContainer}>
            <BasicTimeRangePicker
              divider={<Divider style={styles.basicTimeRangePickerDivider} />}
              endDateTime={customRange.endDateTime}
              onValueChange={(value) => {
                const startDateTime =
                  value.startDateTime &&
                  dayjs(value.startDateTime).startOf("minute").toISOString();
                const endDateTime =
                  value.endDateTime &&
                  dayjs(value.endDateTime).endOf("minute").toISOString();
                onCustomRangeChanged({
                  endDateTime,
                  startDateTime,
                });
                if (startDateTime && endDateTime) {
                  if (
                    !maxSpan ||
                    dayjs(endDateTime).diff(
                      startDateTime,
                      maxSpan.unit,
                      true
                    ) <= maxSpan.value
                  ) {
                    onQueryRangeChanged({ endDateTime, startDateTime });
                  } else {
                    addNotification({
                      message: `Cannot query a larger span than ${
                        maxSpan.value
                      } ${maxSpan.unit}${maxSpan.value > 1 ? "s" : ""}`,
                      status: "danger",
                      title: "Error",
                    });
                  }
                }
              }}
              startDateTime={customRange.startDateTime}
            />
          </Layout>
          <Separator size="small" />
          <View style={{ flexDirection: "row" }}>
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "day")
                    .startOf("minute")
                    .toISOString(),
                };
                onCustomRangeChanged(dateTimeRange);
                onQueryRangeChanged(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past day
                </Text>
              )}
            />
            <Separator horizontal size="small" />
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "week")
                    .startOf("minute")
                    .toISOString(),
                };
                onCustomRangeChanged(dateTimeRange);
                onQueryRangeChanged(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past week
                </Text>
              )}
            />
            <Separator horizontal size="small" />
            <BaseListItem
              onPress={() => {
                const now = dayjs();
                const dateTimeRange = {
                  endDateTime: now.endOf("minute").toISOString(),
                  startDateTime: now
                    .subtract(1, "month")
                    .startOf("minute")
                    .toISOString(),
                };
                onCustomRangeChanged(dateTimeRange);
                onQueryRangeChanged(dateTimeRange);
              }}
              style={{ flex: 1 }}
              title={() => (
                <Text category="s2" style={{ textAlign: "center" }}>
                  Past month
                </Text>
              )}
            />
          </View>
        </>
      )}
      {mode === "week" && (
        <Layout style={styles.weeklyCalendarContainer}>
          <WeeklyCalendar
            dateTime={selectedDate}
            onDateTimeChange={(dateTime) => {
              onSelectedDateChanged(dateTime);
              const weekBounds = getWeekBounds(dateTime);
              if (
                weekBounds.startDateTime !==
                getWeekBounds(selectedDate).startDateTime
              ) {
                onQueryRangeChanged({
                  endDateTime: weekBounds.endDateTime,
                  startDateTime: weekBounds.startDateTime,
                });
              }
            }}
            renderDay={renderCalendarDay}
          />
        </Layout>
      )}
      <Separator size="small" />
      {/* @ts-ignore */}
      <ProgressBar visible={loading} indeterminate color="#3366FF" />
    </View>
  );
};

TimeRangePicker.defaultProps = {
  loading: false,
  maxSpan: undefined,
  renderCalendarDay: undefined,
  style: undefined,
};

export default TimeRangePicker;
