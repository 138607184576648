import { RRule, rrulestr } from "rrule";
import moment from "moment-timezone";
import repeatByWeekday from "../../constants/repeatByWeekday";

const momentDaysToWeekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const rruleToWeekday = {
  [RRule.SU]: "sunday",
  [RRule.MO]: "monday",
  [RRule.TU]: "tuesday",
  [RRule.WE]: "wednesday",
  [RRule.TH]: "thursday",
  [RRule.FR]: "friday",
  [RRule.SA]: "saturday",
};

const convertUtcRRuleToLocalWeekdays = ({ utcRRule }) => {
  const rrule = rrulestr(utcRRule);
  let weekdays = [];

  const startDateTime = rrule.origOptions.dtstart;
  const byWeekday = rrule.origOptions.byweekday;
  const startMoment = moment.utc(startDateTime);

  if (Array.isArray(byWeekday)) {
    weekdays = byWeekday.map((weekday) => {
      const day = rruleToWeekday[weekday];
      const utcDayMoment = startMoment
        .clone()
        .weekday(repeatByWeekday[day].momentValue);
      const localizedDay = moment(utcDayMoment.toISOString()).weekday();
      return momentDaysToWeekdays[localizedDay];
    });
  }

  return weekdays;
};

export default convertUtcRRuleToLocalWeekdays;
