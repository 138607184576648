import gql from "graphql-tag";

const retrieveStripeAccount = gql`
  query retrieveStripeAccount {
    retrieveStripeAccount {
      charges_enabled
      details_submitted
      id
      payouts_enabled
    }
  }
`;

export default retrieveStripeAccount;
