import { Divider, Text, Layout, useTheme } from "@ui-kitten/components";
import React, { PropsWithChildren } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

interface Props {
  caption?: string;
  layoutStyle?: StyleProp<ViewStyle>;
  status?: string;
  title?: string;
}

const styles = StyleSheet.create({
  caption: {
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 4,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
});

const SectionItem = ({
  caption,
  children,
  layoutStyle,
  status,
  title,
}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  return (
    <>
      {Boolean(title) && (
        <Text
          appearance="hint"
          category="s2"
          status={status}
          style={styles.sectionTitle}
        >
          {title.toUpperCase()}
        </Text>
      )}
      <Divider
        style={
          status ? { backgroundColor: theme[`text-${status}-color`] } : null
        }
      />
      <Layout style={layoutStyle}>{children}</Layout>
      <Divider
        style={
          status ? { backgroundColor: theme[`text-${status}-color`] } : null
        }
      />
      {Boolean(caption) && (
        <Text appearance="hint" category="c1" style={styles.caption}>
          {caption}
        </Text>
      )}
    </>
  );
};

SectionItem.defaultProps = {
  caption: undefined,
  layoutStyle: undefined,
  status: undefined,
  title: undefined,
};

export default SectionItem;
