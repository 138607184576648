import { MaterialIcons } from "@expo/vector-icons";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Button,
  Divider,
  Icon,
  Layout,
  ListItem,
  Text,
  TopNavigation,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import adminArchiveCompanySite from "../../api/functions/adminArchiveCompanySite";
import adminRestoreCompanySite from "../../api/functions/adminRestoreCompanySite";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import EnhancedDivider from "../../components/buildingBlocks/EnhancedDivider";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import Label from "../../components/Label";
import CustomerListItem from "../../components/listItems/Customer";
import LevelTwoListItem from "../../components/listItems/LevelTwo";
import MediaListItem from "../../components/listItems/Media";
import ScheduleJobListItem from "../../components/listItems/ScheduleJob";
import StaticMapView from "../../components/map/StaticMapView";
import Marker from "../../components/markers/Marker";
import AlertModal from "../../components/modals/Alert";
import JobEditingModal from "../../components/modals/JobEditing";
import MediaViewerModal from "../../components/modals/MediaViewer";
import SiteEditingModal from "../../components/modals/SiteEditing";
import SafeAreaView from "../../components/SafeAreaView";
import SectionItem from "../../components/SectionItem";
import SiteMapCircle from "../../components/SiteMapCircle";
import UserRecord from "../../components/UserRecord";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectAccessibleSites from "../../store/sites/selectors/selectAccessibleSites";
import selectById from "../../store/sites/selectors/selectById";
import selectMapJobs from "../../store/jobs/selectors/mapJobs";
import selectJobEntities from "../../store/jobs/selectors/selectJobEntities";
import selectSignedInUserRole from "../../store/users/selectors/selectSignedInUserRole";
import { StackParamList } from "../../types";
import isCustomersEnabled from "../../utils/isCustomersEnabled";
import { navigateWithGoogleMaps } from "../../utils/map";
import userRolePermitted from "../../utils/userRolePermitted";
import LegacySiteNotes from "../../components/LegacySiteNotes";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  labelContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  listItemAccessoryRight: {
    marginEnd: 8,
    marginStart: 8,
  },
  listItemAction: {
    marginEnd: 16,
    marginStart: 16,
  },
  mapContainer: {
    paddingBottom: 12,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 12,
  },
  mapFrame: {
    borderRadius: 4,
    overflow: "hidden",
  },
  root: {
    flex: 1,
  },
  section: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  sectionSeparator: {
    height: 24,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  separator: {
    height: 8,
  },
  snackbar: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  topNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const SiteDetails = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "SiteDetails">
  >();
  const theme = useTheme();
  const {
    params: { siteId },
  } = useRoute<RouteProp<StackParamList, "SiteDetails">>();
  const company = useSelector(selectCompany);
  const site = useSelector((state) => selectById(state, siteId));
  const accessibleSites = useSelector(selectAccessibleSites);
  const accessibleJobs = (useSelector(selectMapJobs) as {
    accessible: Array<string>;
    visible: Array<string>;
  }).accessible;
  const jobs = useSelector(selectJobEntities);
  const signedInUserRole = useSelector(selectSignedInUserRole);

  const [archiveConfirmationVisible, setArchiveConfirmationVisible] = useState(
    false
  );
  const [restoreConfirmationVisible, setRestoreConfirmationVisible] = useState(
    false
  );
  const [editVisible, setEditVisible] = useState(false);
  const [createJobVisible, setCreateJobVisible] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [mediaViewer, setMediaViewer] = useState({
    mediaId: null,
    visible: false,
  });

  useEffect(() => {
    setTimeout(() => setMapVisible(true), 300);
  }, []);

  const customersEnabled = isCustomersEnabled(company);
  const siteCoordinates = {
    latitude: site.coordinates.lat,
    longitude: site.coordinates.lng,
  };

  if (!accessibleSites.includes(siteId)) {
    return (
      <View
        style={{
          paddingBottom: insets.bottom,
          paddingEnd: insets.right,
          paddingStart: insets.left,
          paddingTop: insets.top,
        }}
      >
        <View>
          <TopNavigation
            title="Location details"
            alignment="center"
            accessoryLeft={() => (
              <TopNavigationAction
                icon={(props) => <Icon {...props} name="arrow-back-outline" />}
                onPress={() => navigation.goBack()}
              />
            )}
          />
        </View>
        <Divider />
        <Layout level="2" style={styles.content}>
          <Text>
            {" "}
            Your permissions do not allow you to see the content of the site
            details. If you need access, please contact an administrator for
            your company.{" "}
          </Text>
        </Layout>
      </View>
    );
  }

  const handleJobPress = (id) => {
    navigation.navigate("JobDetails", { jobId: id });
  };

  const renderJobs = () => {
    const jobIds = accessibleJobs.filter(
      (jobId) => siteId === jobs[jobId].siteId
    );

    if (jobIds.length > 0) {
      return jobIds.map((jobId, index) => {
        return (
          <View key={jobId}>
            {index > 0 && <EnhancedDivider type="middle" />}
            <ScheduleJobListItem
              jobId={jobId}
              onPress={handleJobPress}
              showFullDate
            />
          </View>
        );
      });
    }
    return <ListItem title="No accessible jobs" disabled />;
  };

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <HeaderWithTextAction
              action={
                userRolePermitted({
                  permittedRoles: ["Admin", "Supervisor"],
                  userRole: signedInUserRole,
                })
                  ? {
                      onPress: () => setEditVisible(true),
                      text: "Edit",
                    }
                  : undefined
              }
              navigation={{
                icon: "arrow-back-outline",
                onPress: navigation.goBack,
              }}
              title="Location details"
            />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentInsetAdjustmentBehavior="automatic"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Container>
              <SafeAreaView>
                <Layout>
                  <Separator size="medium" />
                  <Text category="h6" style={styles.text}>
                    {site.name}
                  </Text>
                  <Text category="p1" style={styles.text}>
                    {site.address}
                  </Text>
                  {site.isArchived && (
                    <>
                      <Separator size="medium" />
                      <View style={styles.labelContainer}>
                        <Label status="danger">Archived</Label>
                      </View>
                    </>
                  )}
                  <Separator size="medium" />
                  {userRolePermitted({
                    permittedRoles: ["Admin", "Supervisor"],
                    userRole: signedInUserRole,
                  }) && (
                    <>
                      <LevelTwoListItem
                        accessoryLeft={(imageProps) => (
                          <Icon {...imageProps} name="plus-outline" />
                        )}
                        accessoryRight={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="arrow-ios-forward-outline"
                          />
                        )}
                        data-test="createJobButtonInJobSiteDetailsScreen"
                        onPress={() => setCreateJobVisible(true)}
                        style={styles.listItemAction}
                        title="Create job"
                      />
                      <Separator size="small" />
                      <LevelTwoListItem
                        accessoryLeft={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="history"
                            pack="MaterialIcons"
                          />
                        )}
                        accessoryRight={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="arrow-ios-forward-outline"
                          />
                        )}
                        data-test="jobHistoryButtonInJobSiteDetailsScreen"
                        onPress={() => {
                          const now = dayjs();
                          navigation.navigate("MainDrawer", {
                            params: {
                              params: {
                                customerIds: [],
                                endDateTime: now.toISOString(),
                                siteIds: [siteId],
                                startDateTime: now
                                  .subtract(1, "day")
                                  .startOf("minute")
                                  .toISOString(),
                                userIds: [],
                              },
                              screen: "HistoricalSearch",
                            },
                            screen: "MainBottomTab",
                          });
                        }}
                        style={styles.listItemAction}
                        title="Job history"
                      />
                      <Separator size="small" />
                    </>
                  )}
                  <LevelTwoListItem
                    accessoryLeft={() => (
                      <View style={{ marginHorizontal: 8 }}>
                        <MaterialIcons
                          color={theme["text-hint-color"]}
                          name="my-location"
                          size={24}
                        />
                      </View>
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="arrow-ios-forward-outline" />
                    )}
                    onPress={() =>
                      navigation.navigate("UpcomingJobs", site.coordinates)
                    }
                    style={styles.listItemAction}
                    title="Locate"
                  />
                  <Separator size="small" />
                  <LevelTwoListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="navigation-2-outline" />
                    )}
                    accessoryRight={(imageProps) => (
                      <Icon {...imageProps} name="external-link-outline" />
                    )}
                    onPress={() =>
                      navigateWithGoogleMaps({
                        destination: {
                          latitude: site.coordinates.lat,
                          longitude: site.coordinates.lng,
                        },
                      })
                    }
                    style={styles.listItemAction}
                    title="Navigate"
                  />
                  <Separator size="small" />
                </Layout>
                <Divider />
                {Boolean(site.customerId) &&
                  customersEnabled &&
                  userRolePermitted({
                    permittedRoles: ["Admin", "Supervisor"],
                    userRole: signedInUserRole,
                  }) && (
                    <>
                      <Separator />
                      <SectionItem title="Customer">
                        <CustomerListItem
                          accessoryRight={(imageProps) => (
                            <Icon
                              {...imageProps}
                              name="arrow-ios-forward-outline"
                            />
                          )}
                          customerId={site.customerId}
                          onPress={() =>
                            navigation.navigate("CustomerDetails", {
                              customerId: site.customerId,
                            })
                          }
                        />
                      </SectionItem>
                    </>
                  )}
                {Boolean(site.notes || site.privateNotes) && (
                  <>
                    <Separator />
                    <SectionItem title="NOTES">
                      <LegacySiteNotes site={site} />
                    </SectionItem>
                  </>
                )}
                {Array.isArray(site.media) && site.media.length > 0 && (
                  <>
                    <Separator />
                    <SectionItem title="Media">
                      {site.media.map((media, index) => (
                        <Fragment key={media.id}>
                          {index > 0 && <Divider style={{ marginStart: 16 }} />}
                          <MediaListItem
                            media={media}
                            onPress={(mediaId) =>
                              setMediaViewer({ mediaId, visible: true })
                            }
                          />
                        </Fragment>
                      ))}
                    </SectionItem>
                  </>
                )}
                <Separator />
                <SectionItem title="Jobs">{renderJobs()}</SectionItem>
                <Separator />
                <View style={styles.mapContainer}>
                  <Layout level="2" style={styles.mapFrame}>
                    {mapVisible && (
                      <StaticMapView
                        initialRegion={{
                          ...siteCoordinates,
                          latitudeDelta: 0.01,
                          longitudeDelta: 0.01,
                        }}
                      >
                        <Marker coordinate={siteCoordinates} />
                        <SiteMapCircle
                          center={siteCoordinates}
                          radius={site.geofence}
                        />
                      </StaticMapView>
                    )}
                  </Layout>
                </View>
                {userRolePermitted({
                  permittedRoles: ["Admin"],
                  userRole: signedInUserRole,
                }) && (
                  <>
                    <Separator />
                    <SectionItem status="danger" title="DANGER ZONE">
                      {!site.isArchived ? (
                        <ListItem
                          accessoryRight={() => (
                            <Button
                              data-test="archiveButtonInJobSiteDetailsScreen"
                              onPress={() =>
                                setArchiveConfirmationVisible(true)
                              }
                              size="small"
                              status="danger"
                            >
                              Archive
                            </Button>
                          )}
                          description="This site will not show up as a site when creating a job."
                          disabled
                          title="Archive this site"
                        />
                      ) : (
                        <ListItem
                          accessoryRight={() => (
                            <Button
                              data-test="restoreButtonInJobSiteDetailsScreen"
                              onPress={() =>
                                setRestoreConfirmationVisible(true)
                              }
                              size="small"
                              status="danger"
                            >
                              Restore
                            </Button>
                          )}
                          description="This site will show up as an option when creating a job."
                          disabled
                          title="Restore this site"
                        />
                      )}
                    </SectionItem>
                  </>
                )}
                <Separator />
                <UserRecord
                  label="Created:"
                  style={styles.text}
                  userRecord={site.created}
                />
                <Separator size="small" />
                <UserRecord
                  label="Updated:"
                  style={styles.text}
                  userRecord={site.updated}
                />
                <Separator />
              </SafeAreaView>
            </Container>
          </ScrollView>
        </Layout>
      </Layout>
      <MediaViewerModal
        isVisible={mediaViewer.visible}
        media={
          site.media &&
          site.media[
            _.findIndex(site.media, (media) => media.id === mediaViewer.mediaId)
          ]
        }
        onClose={() =>
          setMediaViewer((prevState) => ({ ...prevState, visible: false }))
        }
      />
      <SiteEditingModal
        initialValues={site}
        isVisible={editVisible}
        onClose={() => setEditVisible(false)}
        onSubmit={() => setEditVisible(false)}
      />
      <JobEditingModal
        initialValues={{ siteId }}
        isVisible={createJobVisible}
        onClose={() => setCreateJobVisible(false)}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Archive"
        isVisible={archiveConfirmationVisible}
        message="Archive this location?"
        onCancel={() => setArchiveConfirmationVisible(false)}
        onClose={() => setArchiveConfirmationVisible(false)}
        onConfirm={() => {
          adminArchiveCompanySite(site);
          addNotification({
            status: "success",
            title: "Location archived",
          });
          setArchiveConfirmationVisible(false);
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Restore"
        isVisible={restoreConfirmationVisible}
        message="Restore this location?"
        onCancel={() => setRestoreConfirmationVisible(false)}
        onClose={() => setRestoreConfirmationVisible(false)}
        onConfirm={() => {
          adminRestoreCompanySite(site);
          addNotification({
            status: "success",
            title: "Location restored",
          });
          setRestoreConfirmationVisible(false);
        }}
      />
    </>
  );
};

export default SiteDetails;
