import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import selectUser from "../../../auth/selectors/selectUser";
import { FormattedJob, Job } from "../../../../types";
import getJobStatus from "../../../../utils/getJobStatus";

interface SelectJobsOutput {
  entities: Record<string, FormattedJob>;
  ids: Array<string>;
}

const selectJobs = createSelector(
  [
    (state) => state.data.jobs,
    (state) => state.data.jobs.optimistic,
    (state) => state.data.jobs.filter.assignees,
    selectUser,
  ],
  (jobs, optimisticJobs, jobAssigneeFilter, user): SelectJobsOutput => {
    if (!user) {
      return {
        entities: {},
        ids: [],
      };
    }
    const { id } = user;
    const combinedJobs: Record<string, Job> = {
      ...jobs.entities,
      ...optimisticJobs.entities,
    };
    const entities: Record<string, FormattedJob> = Object.values(
      combinedJobs
    ).reduce((acc, job) => {
      return {
        ...acc,
        [job.id]: {
          ...job,
          status: getJobStatus({ job, jobAssigneeFilter, userId: id }),
        },
      };
    }, {});
    return {
      entities,
      ids: _.union([...jobs.ids, ...optimisticJobs.ids]),
    };
  }
);

export default selectJobs;
