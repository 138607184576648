import { Text, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";
import { StripeInvoice } from "../../types";

interface StripeInvoiceStatusTextProps {
  invoice: Pick<StripeInvoice, "status">;
}

const StyledText = styled(Text)`
  border-radius: 8px;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
`;

const StripeInvoiceStatusText = ({ invoice }: StripeInvoiceStatusTextProps) => {
  const theme = useTheme();
  let status;
  switch (invoice.status) {
    case "draft": {
      status = "basic";
      break;
    }
    case "open": {
      status = "primary";
      break;
    }
    case "paid": {
      status = "success";
      break;
    }
    case "uncollectible": {
      status = "basic";
      break;
    }
    case "void": {
      status = "basic";
      break;
    }
    default: {
      break;
    }
  }
  return (
    <StyledText
      category="s1"
      status={status}
      style={{
        backgroundColor: theme[`color-${status}-transparent-default`],
      }}
    >
      {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
    </StyledText>
  );
};

export default StripeInvoiceStatusText;
