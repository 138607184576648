import mutation from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const createBillingPortalSession = async (returnUrl) => {
  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        returnUrl,
      },
    });
    return data.createBillingPortalSession;
  } catch (error) {
    throw generateErrorMessage("createBillingPortalSession", error);
  }
};

export default createBillingPortalSession;
