import { useTheme } from "@ui-kitten/components";
import { ChildComponentProps } from "google-map-react";
import React, { FC, useState } from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  dot: {
    borderRadius: 6,
    height: 12,
    width: 12,
  },
  root: {
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: 8,
    cursor: "auto",
    height: 16,
    justifyContent: "center",
    width: 16,
  },
});

const CurrentLocationMarker: FC<ChildComponentProps> = () => {
  const theme = useTheme();
  const [layout, setLayout] = useState<{ height: number; width: number }>();
  return (
    <View
      onLayout={(event) => {
        const { height, width } = event.nativeEvent.layout;
        setLayout({ height, width });
      }}
      style={[
        styles.root,
        layout && {
          transform: [
            { translateX: -1 * (layout.width / 2) },
            { translateY: -1 * (layout.height / 2) },
          ],
        },
      ]}
    >
      <View
        style={[styles.dot, { backgroundColor: theme["color-info-500"] }]}
      />
    </View>
  );
};

export default CurrentLocationMarker;
