import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import AlertModal from "../Alert";

momentDurationFormatSetup(moment);

const StartJobEarlyAlertModal = ({
  isVisible,
  jobStartDateTime,
  onCancel,
  onClose,
  onConfirm,
}) => {
  const [duration, setDuration] = useState(null);
  useEffect(() => {
    if (isVisible) {
      setDuration(
        moment
          .duration(
            moment(jobStartDateTime).diff(moment(), "minutes"),
            "minute"
          )
          .format("y [years], w [weeks], d [days], h [hours] [and] m [minutes]")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <AlertModal
      cancelText="Cancel"
      confirmText="Start job"
      isVisible={isVisible}
      message={`You will be starting this job ${duration} early. Are you sure you want to start this job?`}
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

StartJobEarlyAlertModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  jobStartDateTime: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default StartJobEarlyAlertModal;
