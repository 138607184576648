import { Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, View } from "react-native";

import DaysOfWeek from "../DaysOfWeek";
import Week, { Props as WeekProps } from "../Week";
import Button from "../../buildingBlocks/Button";
import Separator from "../../buildingBlocks/Separator";
import IconButton from "../../IconButton";

interface Props extends Pick<WeekProps, "dateTime" | "renderDay"> {
  onDateTimeChange?: (dateTime: string) => void;
}

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  extraLabel: {
    flex: 1,
  },
  month: {
    paddingStart: 16,
  },
  pagination: {
    alignItems: "center",
    flexDirection: "row",
    marginStart: 4,
    marginEnd: 4,
  },
});

const WeeklyCalendar = ({ dateTime, onDateTimeChange, renderDay }: Props) => {
  const goToNextWeek = () =>
    onDateTimeChange(
      dayjs(dateTime).add(1, "week").startOf("day").toISOString()
    );
  const goToPreviousWeek = () =>
    onDateTimeChange(
      dayjs(dateTime).subtract(1, "week").startOf("day").toISOString()
    );
  const now = dayjs();
  return (
    <View>
      <Separator size={4} />
      <View style={styles.header}>
        <View style={styles.label}>
          <Text style={styles.month}>
            {dayjs(dateTime).format("MMMM YYYY")}
          </Text>
        </View>
        <View style={styles.pagination}>
          {!dayjs(dateTime).isSame(now, "week") && (
            <>
              <Button
                appearance="ghost"
                onPress={() =>
                  onDateTimeChange(now.startOf("day").toISOString())
                }
                size="small"
              >
                Today
              </Button>
              <Separator horizontal size="small" />
            </>
          )}
          <IconButton
            appearance="ghost"
            name="arrow-ios-back-outline"
            onPress={goToPreviousWeek}
          />
          <Separator horizontal size="small" />
          <IconButton
            appearance="ghost"
            name="arrow-ios-forward-outline"
            onPress={goToNextWeek}
          />
        </View>
      </View>
      <Separator size="small" />
      <DaysOfWeek />
      <Separator size="small" />
      <Week
        dateTime={dateTime}
        onDatePress={onDateTimeChange}
        renderDay={renderDay}
      />
      <Separator size={4} />
    </View>
  );
};

WeeklyCalendar.defaultProps = {
  onDateTimeChange: () => null,
};

export default WeeklyCalendar;
