import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";
import { setCustomerAsync } from "../../../store/customers/slice";

interface UpdateCustomerInput {
  id: string;
  name: string;
  notes: string;
  emails: Array<CustomerEmailInput>;
}

interface CustomerEmailInput {
  id: string;
  email: string;
}

const updateCustomer = ({ emails, id, name, notes }: UpdateCustomerInput) => {
  const nowDateTime = new Date().toISOString();
  const state = store.getState();
  const user = selectUser(state);
  const customer = selectCustomerById(state, id);
  store.dispatch(
    setCustomerAsync.pending({
      customer: {
        ...customer,
        emails,
        name,
        notes,
        syncDateTime: nowDateTime,
        updated: {
          at: nowDateTime,
          by: user.id,
        },
      },
      mutation: "updateCustomer",
    })
  );
  client.mutate({
    mutation,
    variables: deepCopy({
      emails,
      id,
      name,
      notes,
    }),
  });
};

export default updateCustomer;
