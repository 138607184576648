import { createSelector } from "@reduxjs/toolkit";
import { TutorialStatuses, TutorialTypes } from "../../../../types";
import { TutorialState } from "../../slice";

const selectTutorialStatus = createSelector(
  [
    (state: any) => state.tutorials as TutorialState,
    (_, tutorial: TutorialTypes) => tutorial,
  ],
  (tutorials, tutorial): TutorialStatuses => tutorials[tutorial]
);

export default selectTutorialStatus;
