import mutation from "./graphql";
import client from "../../client";
import store from "../../../store";
import selectUser from "../../../store/auth/selectors/selectUser";
import selectCustomerById from "../../../store/customers/selectors/selectCustomerById";
import { setCustomerAsync } from "../../../store/customers/slice";

interface RestoreCustomerInput {
  id: string;
}

const restoreCustomer = ({ id }: RestoreCustomerInput) => {
  const nowDateTime = new Date().toISOString();
  const state = store.getState();
  const user = selectUser(state);
  const customer = selectCustomerById(state, id);
  store.dispatch(
    setCustomerAsync.pending({
      customer: {
        ...customer,
        isArchived: false,
        syncDateTime: nowDateTime,
        updated: {
          at: nowDateTime,
          by: user.id,
        },
      },
      mutation: "restoreCustomer",
    })
  );
  client.mutate({
    mutation,
    variables: {
      id,
    },
  });
};

export default restoreCustomer;
