import gql from "graphql-tag";

import { stripeProductFragment } from "../../../fragments";

const updateProduct = gql`
  mutation updateProduct($id: ID!, $name: String) {
    updateProduct(id: $id, name: $name) {
      ...stripeProductFragment
    }
  }
  ${stripeProductFragment}
`;

export default updateProduct;
