import {
  Divider,
  Icon,
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  Text,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import {
  Keyboard,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import uuidv4 from "uuid/v4";
import * as yup from "yup";
import selectCompany from "../../../store/company/selectors/selectCompany";
import isCustomersEnabled from "../../../utils/isCustomersEnabled";

import AlertModal from "../Alert";
import CreateCustomerForSiteModal from "../CreateCustomerForSite";
import CustomerSelectModal from "../CustomerSelect";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import LocationSettingModal from "../LocationSetting";
import MediaViewerModal from "../MediaViewer";
import TextInputModal from "../TextInput";
import AddMediaButtons from "../../AddMediaButtons";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import BaseListItem from "../../listItems/Base";
import CustomerListItem from "../../listItems/Customer";
import MediaListItem from "../../listItems/Media";
import StaticMapView from "../../map/StaticMapView";
import Marker from "../../markers/Marker";
import SectionItem from "../../SectionItem";
import SiteMapCircle from "../../SiteMapCircle";
import adminCreateCompanySite from "../../../api/functions/adminCreateCompanySite";
import adminUpdateCompanySite from "../../../api/functions/adminUpdateCompanySite";
import geocode from "../../../api/functions/geocode";
import linkSiteToCustomer from "../../../api/functions/linkSiteToCustomer";
import unlinkSiteFromCustomer from "../../../api/functions/unlinkSiteFromCustomer";
import timeZones from "../../../constants/timeZones";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import useFormikMediaHelpers from "../../../hooks/useFormikMediaHelpers";
import { Coordinates, Media, MediaOnDevice } from "../../../types";
import isMediaOnDevice from "../../../utils/isMediaOnDevice";
import isNumber from "../../../utils/isNumber";

interface FormValues {
  address: string;
  coordinates: Coordinates;
  customerId?: string;
  geofence: number;
  media: Array<Media | MediaOnDevice>;
  name: string;
  notes: string;
  privateNotes: string;
  timezone: string;
}

interface InitialValues extends Partial<FormValues> {
  id?: string;
}

interface Props extends FullScreenModalProps {
  initialValues?: InitialValues;
  onSubmit: (siteId: string) => void;
}

const getFormattedTimezone = (timezone) => timezone.replace("_", " ");

const getValues = (initialValues?: InitialValues): FormValues => ({
  address: initialValues?.address || "",
  coordinates: initialValues?.coordinates
    ? { ...initialValues.coordinates }
    : undefined,
  customerId: initialValues?.customerId,
  geofence: initialValues?.geofence || 50,
  media: initialValues?.media || [],
  name: initialValues?.name || "",
  notes: initialValues?.notes || "",
  privateNotes: initialValues?.privateNotes || "",
  timezone: initialValues?.timezone || moment.tz.guess(),
});

const styles = StyleSheet.create({
  button: {
    justifyContent: "flex-start",
  },
  content: {
    flex: 1,
  },
  description: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  divider: {
    marginStart: 16,
  },
  fakeLabel: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  list: {
    paddingTop: 8,
  },
  listItemAccessory: {
    flexDirection: "row",
    alignItems: "center",
  },
  listItemText: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  mapContainer: {
    borderRadius: 4,
    overflow: "hidden",
  },
  mapHintOverlay: {
    position: "absolute",
    end: 0,
    start: 0,
    top: 0,
    alignItems: "center",
  },
  mapHintTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginEnd: 8,
    marginStart: 8,
    marginTop: 8,
    paddingBottom: 4,
    paddingEnd: 8,
    paddingStart: 8,
    paddingTop: 4,
    borderRadius: 4,
  },
  searchAddressLayout: {
    alignItems: "flex-end",
    paddingBottom: 4,
    paddingEnd: 16,
    paddingTop: 4,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  subheader: {
    height: 64,
    flexDirection: "row",
    alignItems: "center",
  },
});

const SiteEditingModal = ({
  initialValues,
  isVisible,
  onClose,
  onSubmit,
}: Props) => {
  const company = useSelector(selectCompany);

  const updateExistingSite = Boolean(initialValues?.id);
  const formik = useFormik<FormValues>({
    initialValues: getValues(initialValues),
    validationSchema: yup.object({
      address: yup.string().required("Required"),
      coordinates: yup.object({
        latitude: yup.number(),
        longitude: yup.number(),
      }),
      geofence: yup
        .number()
        .required("Required")
        .min(50, "Must be greater than or equal to 50")
        .max(2000, "Must be less than or equal to 2000"),
      name: yup.string().required("Required"),
    }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      let siteId;
      if (updateExistingSite) {
        siteId = initialValues.id;
        adminUpdateCompanySite({
          address: values.address,
          coordinates: {
            lat: values.coordinates.lat,
            lng: values.coordinates.lng,
          },
          geofence: values.geofence,
          id: siteId,
          media: values.media
            .filter((media) => !isMediaOnDevice(media) || media.uploaded)
            .map((media) => ({
              id: media.id,
              description: media.description,
              createdAt: media.createdAt,
              type: media.type,
            })),
          name: values.name,
          notes: values.notes,
          privateNotes: values.privateNotes,
          timezone: values.timezone,
        });
        if (initialValues.customerId !== formik.values.customerId) {
          if (formik.values.customerId) {
            linkSiteToCustomer({
              customerId: formik.values.customerId,
              siteId,
            });
          } else {
            unlinkSiteFromCustomer({ siteId });
          }
        }
        addNotification({
          status: "success",
          title: "Location updated",
        });
      } else {
        siteId = uuidv4();
        adminCreateCompanySite({
          id: siteId,
          address: values.address,
          coordinates: {
            lat: values.coordinates.lat,
            lng: values.coordinates.lng,
          },
          geofence: values.geofence,
          name: values.name,
          notes: values.notes,
          privateNotes: values.privateNotes,
          timezone: values.timezone,
        });
        if (formik.values.customerId) {
          linkSiteToCustomer({
            customerId: formik.values.customerId,
            siteId,
          });
        }
        addNotification({
          status: "success",
          title: "Location created",
        });
      }
      onSubmit(siteId);
    },
  });
  const {
    AddMediaButtonsProps,
    DeleteMediaAlertModalProps,
    EditMediaDescriptionModalProps,
    MediaListItemProps,
    MediaViewerModalProps,
  } = useFormikMediaHelpers(formik);

  const [timezones] = useState(() => {
    const availableTimezones = Object.values(timeZones);
    if (!availableTimezones.includes(formik.values.timezone)) {
      availableTimezones.unshift(formik.values.timezone);
    }
    return availableTimezones;
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const [geocodePending, setGeocodePending] = useState(false);
  const [createCustomerVisible, setCreateCustomerVisible] = useState(false);
  const [customerSelectVisible, setCustomerSelectVisible] = useState(false);
  const [locationSettingVisible, setLocationSettingVisible] = useState(false);
  const [notesEditingVisible, setNotesEditingVisible] = useState(false);
  const [privateNotesEditingVisible, setPrivateNotesEditingVisible] = useState(
    false
  );
  const [
    unsavedMediaBeforeSubmitVisible,
    setUnsavedMediaBeforeSubmitVisible,
  ] = useState(false);

  const unsavedMediaExists =
    formik.values.media.filter(
      (media) => isMediaOnDevice(media) && !media.uploaded
    ).length > 0;
  const handleBackPress = () => {
    Keyboard.dismiss();
    if (formik.dirty) {
      setAlertVisible(true);
    } else {
      onClose();
    }
  };
  useAndroidBackHandler({ enabled: isVisible, onBackPress: handleBackPress });
  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getValues(initialValues),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const customersEnabled = isCustomersEnabled(company);

  const searchAddress = async () => {
    setGeocodePending(true);
    try {
      const geocodeResponse = await geocode(formik.values.address);
      formik.setFieldValue("address", geocodeResponse.address);
      formik.setFieldValue("coordinates", geocodeResponse.coordinates);
    } catch (error) {
      addNotification({
        message: (error && error.message) || error,
        status: "danger",
        title: "Error",
      });
    }
    setGeocodePending(false);
  };
  const getAddressHelperText = () => {
    if (formik.touched.address && formik.errors.address) {
      return formik.errors.address;
    }
    if (formik.touched.coordinates && formik.errors.coordinates) {
      return "Please use search";
    }
    return null;
  };
  const getAddressStatus = () => {
    if (
      (formik.touched.address && formik.errors.address) ||
      (formik.touched.coordinates && formik.errors.coordinates)
    ) {
      return "danger";
    }
    return "basic";
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <HeaderWithTextAction
          action={{
            "data-test": "submitButtonInSiteEditingModal",
            onPress: () => {
              if (unsavedMediaExists) {
                setUnsavedMediaBeforeSubmitVisible(true);
              } else {
                formik.handleSubmit();
              }
            },
            text: updateExistingSite ? "Done" : "Add",
          }}
          navigation={{
            "data-test": "closeButtonInSiteEditingModal",
            icon: "close-outline",
            onPress: handleBackPress,
          }}
          title={updateExistingSite ? "Edit location" : "New location"}
        />
        <Divider />
        <Layout level="2" style={styles.content}>
          <ScrollView
            contentInsetAdjustmentBehavior="automatic"
            keyboardShouldPersistTaps="handled"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <SafeAreaView>
              <Separator />
              <SectionItem>
                <Separator size="small" />
                <Input
                  autoCapitalize="words"
                  caption={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                  data-test="nameInputInSiteEditingModal"
                  disabled={formik.isSubmitting}
                  label="Name"
                  onBlur={formik.handleBlur("name")}
                  onChangeText={formik.handleChange("name")}
                  status={
                    formik.touched.name && formik.errors.name
                      ? "danger"
                      : "basic"
                  }
                  style={styles.input}
                  value={formik.values.name}
                />
                <Separator size="small" />
              </SectionItem>
              {customersEnabled && (
                <>
                  <Separator />
                  <SectionItem title="Customer">
                    {formik.values.customerId ? (
                      <>
                        <CustomerListItem
                          accessoryRight={(imageProps) => (
                            <Icon
                              {...imageProps}
                              name="arrow-ios-forward-outline"
                            />
                          )}
                          customerId={formik.values.customerId}
                          onPress={() => setCustomerSelectVisible(true)}
                        />
                        <Divider />
                      </>
                    ) : (
                      <Button
                        accessoryLeft={(imageProps) => (
                          <Icon {...imageProps} name="search-outline" />
                        )}
                        appearance="ghost"
                        data-test="selectCustomerButtonInSiteEditingModal"
                        onPress={() => setCustomerSelectVisible(true)}
                        size="small"
                        style={styles.button}
                      >
                        Select customer
                      </Button>
                    )}
                    <Button
                      accessoryLeft={(imageProps) => (
                        <Icon {...imageProps} name="plus-outline" />
                      )}
                      appearance="ghost"
                      data-test="createCustomerButtonInSiteEditingModal"
                      onPress={() => setCreateCustomerVisible(true)}
                      size="small"
                      style={styles.button}
                    >
                      Create customer
                    </Button>
                  </SectionItem>
                </>
              )}
              <Separator />
              <SectionItem title="PLACE">
                <Separator size="small" />
                <Text
                  appearance="hint"
                  category="c1"
                  style={styles.description}
                >
                  Search for an address below. We will provide you with the
                  necessary location data after which you can make changes if
                  needed.
                </Text>
                <Separator size="medium" />
                <Input
                  autoCapitalize="words"
                  caption={getAddressHelperText()}
                  data-test="addressInputInSiteEditingModal"
                  disabled={formik.isSubmitting}
                  label="Address"
                  onBlur={formik.handleBlur("address")}
                  onChangeText={formik.handleChange("address")}
                  status={getAddressStatus()}
                  style={styles.input}
                  value={formik.values.address}
                />
                <View style={styles.searchAddressLayout}>
                  <Button
                    data-test="searchAddressButtonInSiteEditingModal"
                    disabled={!formik.values.address || formik.isSubmitting}
                    loading={geocodePending}
                    onPress={() => {
                      Keyboard.dismiss();
                      searchAddress();
                    }}
                  >
                    Search
                  </Button>
                </View>
                {formik.values.coordinates && (
                  <>
                    <Separator size="medium" />
                    <Text
                      appearance="hint"
                      category="label"
                      style={styles.fakeLabel}
                    >
                      Location
                    </Text>
                    <View
                      style={{
                        paddingEnd: 16,
                        paddingStart: 16,
                      }}
                    >
                      <Layout level="3" style={styles.mapContainer}>
                        <StaticMapView
                          region={{
                            latitude: formik.values.coordinates.lat,
                            latitudeDelta: 0.01,
                            longitude: formik.values.coordinates.lng,
                            longitudeDelta: 0.01,
                          }}
                          onPress={() => setLocationSettingVisible(true)}
                        >
                          <Marker
                            coordinate={{
                              latitude: formik.values.coordinates.lat,
                              longitude: formik.values.coordinates.lng,
                            }}
                            draggable={!formik.isSubmitting}
                            onDragEnd={(event) =>
                              formik.setFieldValue(
                                "coordinates",
                                event.nativeEvent.coordinate
                              )
                            }
                          />
                          {isNumber(formik.values.geofence) &&
                            !formik.errors.geofence && (
                              <SiteMapCircle
                                center={{
                                  latitude: formik.values.coordinates.lat,
                                  longitude: formik.values.coordinates.lng,
                                }}
                                radius={formik.values.geofence}
                              />
                            )}
                        </StaticMapView>
                        <View style={styles.mapHintOverlay}>
                          <Layout style={styles.mapHintTextContainer}>
                            <Text category="c1">
                              Press on the map to change the location.
                            </Text>
                          </Layout>
                        </View>
                      </Layout>
                    </View>
                    <Separator size="medium" />
                    <Input
                      value={formik.values.geofence.toString()}
                      onChangeText={(text) => {
                        formik.setFieldValue(
                          "geofence",
                          isNumber(text) ? parseInt(text, 10) : text
                        );
                      }}
                      caption={
                        formik.touched.geofence && formik.errors.geofence
                          ? formik.errors.geofence
                          : null
                      }
                      disabled={formik.isSubmitting}
                      keyboardType="number-pad"
                      label="Geofence"
                      onBlur={formik.handleBlur("geofence")}
                      status={
                        formik.touched.geofence && formik.errors.geofence
                          ? "danger"
                          : "basic"
                      }
                      style={styles.input}
                    />
                    <Separator size="medium" />
                    <Select
                      selectedIndex={
                        new IndexPath(timezones.indexOf(formik.values.timezone))
                      }
                      onSelect={(index: IndexPath) =>
                        formik.setFieldValue("timezone", timezones[index.row])
                      }
                      value={getFormattedTimezone(formik.values.timezone)}
                      disabled={formik.isSubmitting}
                      label="Time zone"
                      style={styles.input}
                    >
                      {timezones.map((timezone) => (
                        <SelectItem
                          key={timezone}
                          title={getFormattedTimezone(timezone)}
                        />
                      ))}
                    </Select>
                    <Separator size="small" />
                  </>
                )}
                <Separator size="small" />
              </SectionItem>
              <Separator />
              <SectionItem title="NOTES">
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  data-test="notesButtonInSiteEditingModal"
                  description={() => (
                    <Text
                      appearance={formik.values.notes ? "default" : "hint"}
                      category={formik.values.notes ? "p1" : "c1"}
                      style={styles.listItemText}
                    >
                      {formik.values.notes || "Tap to edit"}
                    </Text>
                  )}
                  onPress={() => setNotesEditingVisible(true)}
                  title={() => (
                    <Text
                      appearance="hint"
                      category="label"
                      style={styles.listItemText}
                    >
                      General
                    </Text>
                  )}
                />
                <Divider style={styles.divider} />
                <BaseListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="arrow-ios-forward-outline" />
                  )}
                  data-test="privateNotesButtonInSiteEditingModal"
                  description={() => (
                    <Text
                      appearance={
                        formik.values.privateNotes ? "default" : "hint"
                      }
                      category={formik.values.privateNotes ? "p1" : "c1"}
                      style={styles.listItemText}
                    >
                      {formik.values.privateNotes || "Tap to edit"}
                    </Text>
                  )}
                  onPress={() => setPrivateNotesEditingVisible(true)}
                  title={() => (
                    <Text
                      appearance="hint"
                      category="label"
                      style={styles.listItemText}
                    >
                      Private
                    </Text>
                  )}
                />
              </SectionItem>
              {updateExistingSite && (
                <>
                  <Separator />
                  <SectionItem title="Media">
                    <Separator size="small" />
                    <AddMediaButtons
                      onAddMedia={AddMediaButtonsProps.onAddMedia}
                    />
                    <Separator size="small" />
                    {formik.values.media.length > 0 && (
                      <>
                        <Divider />
                        {formik.values.media.map((media, index) => (
                          <Fragment key={media.id}>
                            {index > 0 && (
                              <Divider style={{ marginStart: 16 }} />
                            )}
                            <MediaListItem
                              editable
                              media={media}
                              onDeletePress={MediaListItemProps.onDeletePress}
                              onDescriptionPress={
                                MediaListItemProps.onDescriptionPress
                              }
                              onPress={MediaListItemProps.onPress}
                              onUpload={MediaListItemProps.onUpload}
                            />
                          </Fragment>
                        ))}
                      </>
                    )}
                  </SectionItem>
                </>
              )}
              <Separator />
            </SafeAreaView>
          </ScrollView>
        </Layout>
      </FullScreenModal>
      <CustomerSelectModal
        headerTitle="Choose a customer"
        isVisible={customerSelectVisible}
        onClose={() => setCustomerSelectVisible(false)}
        onSubmit={(customerId) => {
          formik.setFieldValue("customerId", customerId);
          setCustomerSelectVisible(false);
        }}
        selected={formik.values.customerId}
      />
      <CreateCustomerForSiteModal
        isVisible={createCustomerVisible}
        onClose={() => setCreateCustomerVisible(false)}
        onSuccess={(customer) => {
          formik.setFieldValue("customerId", customer.id);
          setCreateCustomerVisible(false);
        }}
      />
      <LocationSettingModal
        initialCoordinates={formik.values.coordinates}
        isVisible={locationSettingVisible}
        onClose={() => setLocationSettingVisible(false)}
        onSubmit={(coordinates) => {
          formik.setFieldValue("coordinates", coordinates);
          setLocationSettingVisible(false);
        }}
      />
      <TextInputModal
        headerTitle="Notes"
        isVisible={notesEditingVisible}
        multiline
        onClose={() => setNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("notes", text);
          setNotesEditingVisible(false);
        }}
        text={formik.values.notes}
      />
      <TextInputModal
        headerTitle="Private notes"
        isVisible={privateNotesEditingVisible}
        multiline
        onClose={() => setPrivateNotesEditingVisible(false)}
        onSubmit={(text) => {
          formik.setFieldValue("privateNotes", text);
          setPrivateNotesEditingVisible(false);
        }}
        text={formik.values.privateNotes}
      />
      <MediaViewerModal
        editable={MediaViewerModalProps.editable}
        isVisible={MediaViewerModalProps.isVisible}
        media={MediaViewerModalProps.media}
        onClose={MediaViewerModalProps.onClose}
        onDeletePress={MediaViewerModalProps.onDeletePress}
        onDescriptionPress={MediaViewerModalProps.onDescriptionPress}
      />
      <TextInputModal
        headerTitle={EditMediaDescriptionModalProps.headerTitle}
        isVisible={EditMediaDescriptionModalProps.isVisible}
        multiline
        onClose={EditMediaDescriptionModalProps.onClose}
        onSubmit={EditMediaDescriptionModalProps.onSubmit}
        text={EditMediaDescriptionModalProps.text}
      />
      <AlertModal
        cancelText={DeleteMediaAlertModalProps.cancelText}
        confirmText={DeleteMediaAlertModalProps.confirmText}
        isVisible={DeleteMediaAlertModalProps.isVisible}
        message={DeleteMediaAlertModalProps.message}
        onCancel={DeleteMediaAlertModalProps.onCancel}
        onClose={DeleteMediaAlertModalProps.onClose}
        onConfirm={DeleteMediaAlertModalProps.onConfirm}
      />
      <AlertModal
        cancelText="Keep editing"
        confirmText="Discard"
        isVisible={alertVisible}
        message="Discard new site?"
        onCancel={() => setAlertVisible(false)}
        onClose={() => setAlertVisible(false)}
        onConfirm={() => {
          setAlertVisible(false);
          onClose();
        }}
      />
      <AlertModal
        cancelText="Cancel"
        confirmText="Discard photos and submit"
        isVisible={unsavedMediaBeforeSubmitVisible}
        message="Some photos you added have not been uploaded and will be discarded."
        onCancel={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onClose={() => setUnsavedMediaBeforeSubmitVisible(false)}
        onConfirm={() => {
          setUnsavedMediaBeforeSubmitVisible(false);
          formik.handleSubmit();
        }}
      />
    </>
  );
};

SiteEditingModal.defaultProps = {
  initialValues: undefined,
};

export default SiteEditingModal;
