import { Text } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";

import { StripeExpandedInvoiceItem } from "../../../types";
import formatCurrency from "../../../utils/formatCurrency";
import ListItem, { ListItemProps } from "../Base";

interface InvoiceLineItemListItemProps
  extends Pick<ListItemProps, "accessoryRight" | "disabled"> {
  lineItem: StripeExpandedInvoiceItem;
  onPress?: (line_item: StripeExpandedInvoiceItem) => void;
}

const Root = styled.View`
  flex-direction: row;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const TitleDescriptionContainer = styled.View`
  flex: 1;
`;

const ValuesContainer = styled.View`
  align-items: flex-end;
`;

const InvoiceLineItemListItem = ({
  accessoryRight,
  disabled,
  lineItem,
  onPress,
}: InvoiceLineItemListItemProps) => {
  return (
    <ListItem
      accessoryRight={accessoryRight}
      disabled={disabled}
      onPress={onPress && (() => onPress(lineItem))}
      title={() => (
        <Root>
          <TitleDescriptionContainer>
            <StyledText>{lineItem.price.product.name}</StyledText>
            <StyledText appearance="hint" category="c1">
              {`Qty: ${lineItem.quantity}`}
            </StyledText>
          </TitleDescriptionContainer>
          <ValuesContainer>
            <StyledText>{formatCurrency(lineItem.amount)}</StyledText>
            {lineItem.price.metadata.taxesEnabled === "false" && (
              <StyledText appearance="hint">0% tax</StyledText>
            )}
          </ValuesContainer>
        </Root>
      )}
    />
  );
};

export default InvoiceLineItemListItem;
