import { createSelector } from "@reduxjs/toolkit";

import selectTimeCards from "../selectTimeCards";

const selectTimeCardEntities = createSelector(
  [selectTimeCards],
  (timeCards) => timeCards.entities
);

export default selectTimeCardEntities;
