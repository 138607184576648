import { Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import selectEmailActivityById from "../../../store/emailActivity/selectors/selectedEmailActivityById";
import { EmailActivity } from "../../../types";
import Separator from "../../buildingBlocks/Separator";
import ListItem from "../Base";

interface EmailActivityListItemProps {
  emailActivity: EmailActivity;
  hideContentType?: boolean;
  timestampFormat: string;
}

const LabelText = styled(Text)`
  border-radius: 8px;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
`;

const TextContainer = styled.View`
  padding-left: 8px;
  padding-right: 8px;
`;

const TitleContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

const styles = StyleSheet.create({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  title: {
    flex: 1,
  },
  subtitle: {
    flex: 0.8,
  },
  row: {
    alignItems: "baseline",
    flexDirection: "row",
  },
  chipContainer: {},
});

const EmailActivityListItem = ({
  emailActivity,
  hideContentType = false,
  timestampFormat,
}: EmailActivityListItemProps) => {
  const theme = useTheme();

  const getLabel = (): { status: string; text: string } => {
    const { type } = emailActivity;
    let label = {
      status: "success",
      text: "Delivered",
    };
    if (type === "Delivery") {
      label = {
        status: "success",
        text: "Delivered",
      };
    }
    if (type === "Bounce") {
      label = {
        status: "danger",
        text: "Bounce",
      };
    }
    if (type === "SpamComplaint") {
      label = {
        status: "danger",
        text: "Spam complaint",
      };
    }
    if (type === "Click") {
      label = {
        status: "info",
        text: "Link clicked",
      };
    }
    if (type === "Open") {
      label = {
        status: "info",
        text: "Email opened",
      };
    }
    if (type === "SubscriptionChange") {
      label = {
        status: "warning",
        text: "Subscription changed",
      };
    }

    return label;
  };

  const label = getLabel();

  return (
    <ListItem
      disabled
      title={() => (
        <TextContainer>
          <TitleContainer>
            <Text category="s1" numberOfLines={1} style={styles.title}>
              {`To: ${emailActivity.recipient}`}
            </Text>
            <Separator horizontal size="small" />
            <LabelText
              category="s1"
              status={label.status}
              style={{
                backgroundColor:
                  theme[`color-${label.status}-transparent-default`],
              }}
            >
              {label.text}
            </LabelText>
          </TitleContainer>
          {!hideContentType && (
            <Text>
              {emailActivity.content.type.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </Text>
          )}
          <Text appearance="hint">
            {dayjs(emailActivity.eventDateTime).format(timestampFormat)}
          </Text>
          <Text appearance="hint" numberOfLines={1} style={styles.subtitle}>
            {emailActivity.description}
          </Text>
        </TextContainer>
      )}
    />
  );
};

interface ReduxEmailActivityListItemProps {
  id: string;
  timestampFormat: string;
}

export const ReduxEmailActivityListItem = ({
  id,
  timestampFormat,
}: ReduxEmailActivityListItemProps) => {
  const emailActivity = useSelector((state) =>
    selectEmailActivityById(state, id)
  );
  if (!emailActivity) {
    return null;
  }
  return (
    <EmailActivityListItem
      emailActivity={emailActivity}
      timestampFormat={timestampFormat}
    />
  );
};

export default EmailActivityListItem;
