import { ButtonProps, Spinner } from "@ui-kitten/components";
import React from "react";
import { useSelector } from "react-redux";
import { StyleSheet, View } from "react-native";

import Button from "../buildingBlocks/Button";
import { Props as ConditionalTooltipProps } from "../buildingBlocks/ConditionalTooltip";
import selectClockedIn from "../../store/users/selectors/selectClockedIn";
import useClockInProps from "../../hooks/useClockInProps";

const styles = StyleSheet.create({
  spinnerContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props extends ButtonProps {
  ConditionalTooltipProps?: Pick<ConditionalTooltipProps, "anchorStyle">;
  loading?: boolean;
}

const ClockInEnabledButton = ({
  ConditionalTooltipProps: overrideConditionalTooltipProps,
  disabled,
  loading,
  ...props
}: Props) => {
  const clockedIn = useSelector(selectClockedIn);
  const {
    ConditionalTooltipProps: controlledConditionalTooltipProps,
  } = useClockInProps({ disabled });
  return (
    <Button
      {...props}
      ConditionalTooltipProps={{
        ...controlledConditionalTooltipProps,
        ...overrideConditionalTooltipProps,
      }}
      disabled={!clockedIn || disabled}
      accessoryLeft={(imageProps) => {
        return (
          Boolean(loading) && (
            <View style={[imageProps, styles.spinnerContainer]}>
              <Spinner size="small" status="basic" />
            </View>
          )
        );
      }}
    />
  );
};

ClockInEnabledButton.defaultProps = {
  ConditionalTooltipProps: undefined,
  loading: false,
};

export default ClockInEnabledButton;
