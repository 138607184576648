import { Divider, Input, Layout, useTheme } from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import { StyleSheet, TextInput, TextInputProps } from "react-native";

import FullScreenModal from "../FullScreen";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import SafeAreaView from "../../SafeAreaView";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";

interface FormValues {
  text: string;
}

export interface Props
  extends Pick<
    TextInputProps,
    "autoCapitalize" | "keyboardType" | "multiline"
  > {
  children?: ReactNode;
  headerSubtitle?: string;
  headerTitle?: string;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (text: string) => void;
  text?: string;
  validationSchema?: any;
}

const styles = StyleSheet.create({
  content: {
    borderRadius: 8,
    borderWidth: 1,
    flex: 1,
    marginEnd: 16,
    marginStart: 16,
    overflow: "hidden",
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  multilineTextInput: {
    flex: 1,
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
    textAlignVertical: "top",
  },
  safeAreaView: {
    flex: 1,
  },
});

const TextInputModal = ({
  autoCapitalize,
  children,
  headerSubtitle,
  headerTitle,
  isVisible,
  keyboardType,
  multiline,
  onClose,
  onSubmit,
  text,
  validationSchema,
}: Props) => {
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  const formik = useFormik<FormValues>({
    initialValues: {
      text,
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      onSubmit(values.text);
    },
    validationSchema,
  });
  const theme = useTheme();

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: {
          text,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <HeaderWithTextAction
        action={{
          "data-test": "submitButtonInTextEditingModal",
          onPress: formik.handleSubmit,
          text: "Done",
        }}
        navigation={{ icon: "close-outline", onPress: onClose }}
        subtitle={headerSubtitle}
        title={headerTitle}
      />
      <Divider />
      <SafeAreaView
        edges={["bottom", "left", "right"]}
        style={styles.safeAreaView}
      >
        <Separator />
        {multiline ? (
          <Layout
            level={focused ? "1" : "2"}
            style={[
              styles.content,
              {
                borderColor:
                  theme[
                    focused
                      ? "color-primary-default-border"
                      : "border-basic-color-3"
                  ],
              },
            ]}
          >
            <TextInput
              autoCapitalize={autoCapitalize}
              data-test="inputInTextEditingModal"
              keyboardType={keyboardType}
              multiline
              onBlur={() => setFocused(false)}
              onChangeText={formik.handleChange("text")}
              onFocus={() => setFocused(true)}
              style={[
                styles.multilineTextInput,
                { color: theme["text-basic-color"] },
              ]}
              defaultValue={formik.values.text}
            />
          </Layout>
        ) : (
          <Input
            autoCapitalize={autoCapitalize}
            caption={formik.touched.text && formik.errors.text}
            data-test="inputInTextEditingModal"
            keyboardType={keyboardType}
            onChangeText={formik.handleChange("text")}
            status={
              formik.touched.text && formik.errors.text ? "danger" : "basic"
            }
            style={styles.input}
            value={formik.values.text}
          />
        )}
        {children}
        <Separator />
      </SafeAreaView>
    </FullScreenModal>
  );
};

TextInputModal.defaultProps = {
  headerSubtitle: undefined,
  headerTitle: undefined,
  multiline: false,
  text: undefined,
  validationSchema: undefined,
};

export default TextInputModal;
