import gql from "graphql-tag";

import { timecardFragment } from "../../../fragments";

const adminDeleteTimecard = gql`
  mutation adminDeleteTimecard($id: ID!) {
    adminDeleteTimecard(id: $id) {
      ...timecardFragment
    }
  }
  ${timecardFragment}
`;

export default adminDeleteTimecard;
