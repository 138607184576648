import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  mutation deleteActionItemCompletionRecord($actionItemId: ID!, $jobId: ID!) {
    deleteActionItemCompletionRecord(
      actionItemId: $actionItemId
      jobId: $jobId
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;
