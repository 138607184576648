import { ButtonProps, Spinner, Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";

import Separator from "../buildingBlocks/Separator";
import SafeAreaView from "../SafeAreaView";

interface Props extends Pick<ButtonProps, "status"> {
  description?: string;
  loading?: boolean;
  onPress?: () => void;
  title: string;
}

const styles = StyleSheet.create({
  description: {
    color: "rgba(255, 255, 255, 0.6)",
    textAlign: "center",
  },
  spinnerContainer: {
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    start: 16,
    top: 0,
  },
  title: {
    color: "rgba(255, 255, 255, 0.87)",
    textAlign: "center",
  },
});

const AppHeaderMessage = ({
  description,
  loading,
  onPress,
  status = "basic",
  title,
}: Props) => {
  const theme = useTheme();
  return (
    <SafeAreaView style={{ backgroundColor: theme[`color-${status}-default`] }}>
      <TouchableWithoutFeedback disabled={loading} onPress={onPress}>
        <View>
          <Separator size={4} />
          <Text category="c1" style={styles.title}>
            {title}
          </Text>
          {Boolean(description) && (
            <Text appearance="hint" category="c1" style={styles.description}>
              {description}
            </Text>
          )}
          <Separator size={4} />
          {loading && (
            <View style={styles.spinnerContainer}>
              <Spinner status="basic" />
            </View>
          )}
        </View>
      </TouchableWithoutFeedback>
    </SafeAreaView>
  );
};

AppHeaderMessage.defaultProps = {
  description: undefined,
  loading: undefined,
  onPress: () => null,
};

export default AppHeaderMessage;
