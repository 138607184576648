import React, { PropsWithChildren } from "react";
import {
  SafeAreaView as RnSafeAreaView,
  StyleProp,
  ViewStyle,
} from "react-native";
import {
  NativeSafeAreaViewProps,
  SafeAreaView as SafeAreaEdges,
} from "react-native-safe-area-context";

interface Props extends Pick<NativeSafeAreaViewProps, "edges"> {
  style?: StyleProp<ViewStyle>;
}

const SafeAreaView = ({ children, edges, style }: PropsWithChildren<Props>) => {
  if (edges) {
    return (
      <SafeAreaEdges edges={edges} style={style}>
        {children}
      </SafeAreaEdges>
    );
  }
  return <RnSafeAreaView style={style}>{children}</RnSafeAreaView>;
};

SafeAreaView.defaultProps = {
  excludeInset: undefined,
  style: undefined,
};

export default SafeAreaView;
