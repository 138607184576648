import { Divider } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { Fragment, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

import AppHeaderMessage from "../AppHeaderMessage";
import ClockInAppHeaderMessage from "../ClockInAppHeaderMessage";
import { addNotification } from "../InAppNotifications";
import JobPickerFromIdsModal from "../modals/JobPickerFromIds";
import createBillingPortalSession from "../../api/functions/createBillingPortalSession";
import navigationRoutes from "../../constants/navigationRoutes";
import { navigate } from "../../navigation/navigator";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectSignedInUser from "../../store/users/selectors/selectSignedInUser";
import selectUserInProgressJobs from "../../store/jobs/selectors/selectUserInProgressJobs";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  content: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    color: "white",
  },
});

const AppHeader = () => {
  const insets = useSafeAreaInsets();
  const [jobPickerVisible, setJobPickerVisible] = useState(false);
  const [
    createBillingPortalSessionPending,
    setCreateBillingPortalSessionPending,
  ] = useState(false);

  const company = useSelector(selectCompany);
  const signedInUser = useSelector(selectSignedInUser);
  const inProgressJobs = useSelector((state) =>
    selectUserInProgressJobs(state, signedInUser && signedInUser.id)
  );

  const inProgressJobsCount = inProgressJobs.ids.length;
  useEffect(() => {
    if (inProgressJobsCount === 0) {
      setJobPickerVisible(false);
    }
  }, [inProgressJobsCount]);

  const timeCardsEnabled =
    company && isFeatureEnabled({ company, feature: "timeCards" });

  const messages = [];
  if (
    userRolePermitted({
      permittedRoles: ["Admin"],
      userRole: signedInUser?.role,
    }) &&
    company &&
    company.billing &&
    (company.billing.status === "past_due" ||
      company.billing.status === "incomplete")
  ) {
    const runCreateBillingPortalSession = async () => {
      setCreateBillingPortalSessionPending(true);
      try {
        const { url } = await createBillingPortalSession(
          "https://app.talarium.io"
        );
        await WebBrowser.openBrowserAsync(url);
      } catch (e) {
        addNotification({
          message: e.message || "An unexpected error occurred",
          status: "danger",
          title: "Error",
        });
      }
      setCreateBillingPortalSessionPending(false);
    };
    messages.push({
      component: (
        <AppHeaderMessage
          description={
            createBillingPortalSessionPending
              ? "Please wait..."
              : "Press to view"
          }
          loading={createBillingPortalSessionPending}
          onPress={runCreateBillingPortalSession}
          status="warning"
          title={`Talarium subscription: Payment ${
            company.billing.status === "past_due" ? "past due" : "failed"
          }`}
        />
      ),
      key: "billing",
    });
  }
  if (signedInUser && timeCardsEnabled) {
    if (signedInUser.clockedIn) {
      if (inProgressJobsCount > 0) {
        messages.push({
          component: (
            <>
              <AppHeaderMessage
                description="Press to view"
                onPress={() => {
                  if (inProgressJobsCount > 1) {
                    setJobPickerVisible(true);
                  } else {
                    navigate(navigationRoutes.JobDetails, {
                      jobId: inProgressJobs.ids[0],
                    });
                  }
                }}
                status="primary"
                title={`You have ${inProgressJobsCount} job${
                  inProgressJobsCount > 1 ? "s" : ""
                } in progress`}
              />
              {inProgressJobsCount > 1 && (
                <JobPickerFromIdsModal
                  isVisible={jobPickerVisible}
                  onClose={() => setJobPickerVisible(false)}
                  ids={inProgressJobs.ids as Array<string>}
                  onSelect={(jobId) => {
                    setJobPickerVisible(false);
                    navigate(navigationRoutes.JobDetails, { jobId });
                  }}
                />
              )}
            </>
          ),
          key: "inProgressJobs",
        });
      }
    } else {
      messages.push({
        component: <ClockInAppHeaderMessage />,
        key: "warning",
      });
    }
  }

  if (messages.length > 0) {
    return (
      <View style={[styles.container, { marginBottom: -1 * insets.top }]}>
        {messages.map((message, index) => (
          <Fragment key={message.key}>
            {index > 0 && <Divider style={styles.divider} />}
            {message.component}
          </Fragment>
        ))}
      </View>
    );
  }
  return null;
};

export default AppHeader;
