import React from "react";
import { StripeTaxRate } from "../../../types";
import ListItem, { ListItemProps } from "../Base";

interface TaxRateListItemProps
  extends Pick<ListItemProps, "accessoryRight" | "accessoryLeft"> {
  onPress?: (taxRate: StripeTaxRate) => void;
  taxRate: StripeTaxRate;
}

const TaxRateListItem = ({
  accessoryLeft,
  accessoryRight,
  onPress,
  taxRate,
}: TaxRateListItemProps) => {
  return (
    <ListItem
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
      description={`${taxRate.percentage}%`}
      onPress={onPress && (() => onPress(taxRate))}
      title={taxRate.display_name}
    />
  );
};

export default TaxRateListItem;
