const deepCopy = (inObject) => {
  if (
    typeof inObject !== "object" ||
    inObject === null ||
    inObject === undefined
  ) {
    return inObject;
  }
  const outObject = Array.isArray(inObject) ? [] : {};

  Object.keys(inObject).forEach((key) => {
    if (key !== "__typename") {
      outObject[key] = deepCopy(inObject[key]);
    }
  });

  return outObject;
};

export default deepCopy;
