import { createSelector } from "@reduxjs/toolkit";

import { State } from "../../slice";

const selectInbox = (state): State => state.data.inbox;

const selectInboxItems = createSelector([selectInbox], (inbox) => {
  const entities = {
    ...inbox.entities,
    ...inbox.optimistic.entities,
  };
  return {
    entities,
    ids: Object.keys(entities),
  };
});

export default selectInboxItems;
