import { createSelector } from "@reduxjs/toolkit";

import selectSettings from "../selectSettings";

const selectAppearance = createSelector(
  [selectSettings],
  (settings) => settings.appearance
);

export default selectAppearance;
