import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigationAction,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import Header from "../../components/buildingBlocks/Header";
import Separator from "../../components/buildingBlocks/Separator";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import ActionItemListItem from "../../components/listItems/ActionItem";
import ActionItemEditingModal from "../../components/modals/ActionItemEditing";
import useActionItemList from "../../hooks/useActionItemList";
import { ActionItem, StackParamList } from "../../types";

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  divider: {
    marginStart: 16,
  },
  input: {
    marginEnd: 16,
    marginStart: 16,
  },
  root: {
    flex: 1,
  },
  titleContainer: {
    flexDirection: "row",
  },
});

const ActionItemList = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "ActionItemList">
  >();

  const { actionItems, searchText, setSearchText } = useActionItemList();
  const [actionItemEditingModal, setActionItemEditingModal] = useState<{
    actionItem?: ActionItem;
    visible: boolean;
  }>({
    visible: false,
  });

  return (
    <>
      <Layout style={styles.root}>
        <Container>
          <View
            style={{
              paddingEnd: insets.right,
              paddingStart: insets.left,
              paddingTop: insets.top,
            }}
          >
            <Header
              accessoryRight={() => (
                <TopNavigationAction
                  icon={(imageProps) => (
                    <Icon {...imageProps} name="plus-outline" />
                  )}
                  onPress={() =>
                    setActionItemEditingModal({
                      actionItem: undefined,
                      visible: true,
                    })
                  }
                />
              )}
              navigation={{
                onPress: navigation.goBack,
                icon: "arrow-back-outline",
              }}
              title="Additional checklist items"
            />
            <Separator size="small" />
            <Input
              onChangeText={setSearchText}
              placeholder="Search..."
              style={styles.input}
              value={searchText}
            />
            <Separator size="small" />
          </View>
        </Container>
        <Divider />
        <Layout level="2" style={styles.root}>
          <Container style={styles.content}>
            <Layout style={styles.content}>
              <FlatList
                contentContainerStyle={{ paddingBottom: insets.bottom + 8 }}
                data={actionItems.ids}
                keyExtractor={(item) => item}
                ItemSeparatorComponent={() => (
                  <Divider style={styles.divider} />
                )}
                renderItem={({ item: actionItemId }) => (
                  <ActionItemListItem
                    actionItem={actionItems.entities[actionItemId]}
                    onPress={(actionItem) => {
                      setActionItemEditingModal({
                        actionItem,
                        visible: true,
                      });
                    }}
                  />
                )}
              />
            </Layout>
          </Container>
        </Layout>
      </Layout>
      <ActionItemEditingModal
        actionItem={actionItemEditingModal.actionItem}
        isVisible={actionItemEditingModal.visible}
        onClose={() =>
          setActionItemEditingModal((prevState) => ({
            ...prevState,
            visible: false,
          }))
        }
        onDelete={() => {
          setActionItemEditingModal((prevState) => ({
            ...prevState,
            visible: false,
          }));
          addNotification({
            message: "Action item deleted",
            status: "success",
          });
        }}
        onSubmit={() => {
          setActionItemEditingModal((prevState) => ({
            ...prevState,
            visible: false,
          }));
          addNotification({
            message: `Action item ${
              actionItemEditingModal.actionItem ? "updated" : "created"
            }`,
            status: "success",
          });
        }}
      />
    </>
  );
};

export default ActionItemList;
