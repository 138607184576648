import { createSelector } from "@reduxjs/toolkit";

import selectActionItemEntities from "../selectActionItemEntities";

const selectActionItemById = createSelector(
  [selectActionItemEntities, (_, id: string) => id],
  (entities, id) => entities[id]
);

export default selectActionItemById;
