import { Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

import BaseListItem from "../listItems/Base";
import { CompanySite } from "../../types";

interface LegacySiteNotesProps {
  site: CompanySite;
}

const styles = StyleSheet.create({
  divider: {
    marginStart: 16,
  },
  privateNotesHeader: {
    paddingEnd: 8,
    paddingStart: 8,
  },
});

const LegacySiteNotes = ({ site }: LegacySiteNotesProps) => (
  <>
    {Boolean(site.notes) && <BaseListItem disabled title={site.notes} />}
    {Boolean(site.privateNotes) && (
      <BaseListItem
        disabled
        title={(textProps) => (
          <>
            <Text
              appearance="hint"
              category="c1"
              style={styles.privateNotesHeader}
            >
              PRIVATE
            </Text>
            <Text {...textProps}>{site.privateNotes}</Text>
          </>
        )}
      />
    )}
  </>
);

export default LegacySiteNotes;
