import {
  ListItem as DefaultListItem,
  ListItemProps as DefaultListItemProps,
  Text,
  TextProps,
} from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

type BaseListItemProps =
  | {
      title?: DefaultListItemProps["title"];
      titleProps?: never;
    }
  | {
      title?: string;
      titleProps: TextProps;
    };

export type ListItemProps = BaseListItemProps &
  Omit<DefaultListItemProps, "title">;

const styles = StyleSheet.create({
  root: {
    borderRadius: 8,
    minHeight: 48,
    overflow: "hidden",
  },
});

const ListItem = ({ style, ...props }: ListItemProps) => {
  return (
    <DefaultListItem
      {...props}
      style={[styles.root, style]}
      title={
        props.titleProps
          ? // @ts-ignore
            () => <Text {...props.titleProps}>{props.title}</Text>
          : props.title
      }
    />
  );
};

export default ListItem;
