/* eslint-disable global-require */
import { DrawerNavigationHelpers } from "@react-navigation/drawer/lib/typescript/src/types";
import {
  Divider,
  Drawer as KittenDrawer,
  DrawerItem,
  Icon,
  Layout,
  ListItem,
  Spinner,
  Text,
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { Badge } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import appsyncClient from "../../api/client";
import getMedia from "../../api/functions/getMedia";
import useInboxList from "../../hooks/useInboxList";
import selectLiveConnectionStatus from "../../store/appInfo/selectors/selectLiveConnectionStatus";
import selectCompany from "../../store/company/selectors/selectCompany";
import selectSignedInUser from "../../store/users/selectors/selectSignedInUser";
import { ScreenNames } from "../../types";
import getAppVersion from "../../utils/getAppVersion";
import logout from "../../utils/logout";
import userRolePermitted from "../../utils/userRolePermitted";
import Separator from "../buildingBlocks/Separator";
import { addNotification } from "../InAppNotifications";
import LevelTwoListItem from "../listItems/LevelTwo";
import PendingMutationsModal from "../modals/PendingMutations";
import TutorialsModal from "../modals/Tutorials";

interface DrawerProps {
  navigation: DrawerNavigationHelpers;
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  safeAreaView: {
    flex: 1,
  },
  spinnerContainer: {
    paddingEnd: 8,
    paddingStart: 8,
  },
  tour: {
    marginEnd: 16,
    marginStart: 16,
  },
  versionText: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 16,
  },
  logo: {
    width: "100%",
    height: 140,
    resizeMode: "contain",
  },
  header: {
    marginHorizontal: 8,
  },
});

const Root = styled(Layout)`
  flex: 1;
`;

const Drawer = ({ navigation }: DrawerProps) => {
  const insets = useSafeAreaInsets();

  const [companyName, setCompanyName] = useState(null);
  const [showTutorials, setShowTutorials] = useState(false);
  const [pendingMutationsVisible, setPendingMutationsVisible] = useState(false);
  const [logoutPending, setLogoutPending] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const componentMounted = useRef(false);
  const company = useSelector(selectCompany);
  const selfCompanyUser = useSelector(selectSignedInUser);
  const connectionStatus = useSelector(selectLiveConnectionStatus);

  const { inboxItemIds } = useInboxList({
    userId: selfCompanyUser?.id,
  });

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
    }
  }, [company]);
  useEffect(() => {
    const getLogo = async () => {
      try {
        const { url } = await getMedia(company.logo.id);
        setLogoUrl(url);
      } catch (error) {
        addNotification({
          message: "There was an error retrieving the logo.",
          title: "Error",
          status: "danger",
        });
      }
    };

    if (company?.logo) {
      getLogo().then();
    } else {
      setLogoUrl(null);
    }
  }, [company]);

  const billingVisible =
    Platform.OS === "web" &&
    selfCompanyUser &&
    userRolePermitted({
      permittedRoles: ["Admin"],
      userRole: selfCompanyUser?.role,
    }) &&
    company &&
    company.billing;

  const navigateTo = (route: ScreenNames): void => {
    navigation.navigate(route);
    navigation.closeDrawer();
  };
  const runLogout = async () => {
    setLogoutPending(true);
    await logout();
    if (componentMounted.current) {
      setLogoutPending(false);
    }
  };
  const attemptLogout = async () => {
    // @ts-ignore: They are WRONG about the typescript error they show here.
    if (appsyncClient.cache.store.getState().offline.outbox.length > 0) {
      setPendingMutationsVisible(true);
    } else {
      await runLogout();
    }
  };

  const renderInbox = () => {
    if (
      userRolePermitted({
        permittedRoles: ["Admin", "Supervisor"],
        userRole: selfCompanyUser?.role,
      })
    ) {
      return (
        <DrawerItem
          title="Inbox"
          onPress={() => navigateTo("Inbox")}
          accessoryRight={() => (
            <Badge visible={inboxItemIds.length > 0}>
              {inboxItemIds.length}
            </Badge>
          )}
        />
      );
    }
    return null;
  };

  const renderBilling = () => {
    if (billingVisible) {
      return (
        <DrawerItem title="Billing" onPress={() => navigateTo("Billing")} />
      );
    }
    return null;
  };

  const renderEmailActivity = () => {
    if (
      userRolePermitted({
        permittedRoles: ["Admin", "Supervisor"],
        userRole: selfCompanyUser?.role,
      })
    ) {
      return (
        <DrawerItem
          title="Email activity"
          onPress={() => navigateTo("EmailActivity")}
        />
      );
    }
    return null;
  };

  const renderCustomers = () => {
    if (
      userRolePermitted({
        permittedRoles: ["Admin", "Supervisor"],
        userRole: selfCompanyUser?.role,
      })
    ) {
      return (
        <DrawerItem
          data-test="customersButtonInDrawer"
          title="Customers"
          onPress={() => navigateTo("Customers")}
        />
      );
    }
    return null;
  };

  const paymentsEnabled = true;
  // const paymentsEnabled =
  //   !company || isFeatureEnabled({ company, feature: "payments" });

  return (
    <>
      <Root>
        <View
          style={[
            styles.safeAreaView,
            {
              paddingBottom: insets.bottom,
              paddingStart: insets.left,
              paddingTop: insets.top,
            },
          ]}
        >
          <KittenDrawer
            footer={() => (
              <>
                <Divider />
                <Separator size="small" />
                <LevelTwoListItem
                  accessoryRight={(imageProps) => (
                    <Icon {...imageProps} name="film" />
                  )}
                  onPress={() => {
                    setShowTutorials(true);
                    navigation.closeDrawer();
                  }}
                  style={styles.tour}
                  title="Tutorials"
                />
                <Separator size="small" />
                <LevelTwoListItem
                  accessoryRight={(imageProps) =>
                    logoutPending ? (
                      <View style={styles.spinnerContainer}>
                        <Spinner size="small" />
                      </View>
                    ) : (
                      <Icon {...imageProps} name="log-out-outline" />
                    )
                  }
                  onPress={attemptLogout}
                  style={styles.tour}
                  title="Logout"
                />
                <Separator size="small" />
              </>
            )}
            header={() => (
              <View>
                <View style={styles.logoContainer}>
                  {company && company.logo ? (
                    <Image style={styles.logo} source={{ uri: logoUrl }} />
                  ) : (
                    <Image
                      style={styles.logo}
                      source={require("../../../assets/icon_raw.png")}
                    />
                  )}
                </View>
                <ListItem
                  style={styles.header}
                  title={() => {
                    if (selfCompanyUser)
                      return (
                        <Text category="s1">{`${selfCompanyUser.firstName} ${selfCompanyUser.lastName}`}</Text>
                      );
                    return null;
                  }}
                  description={() => (
                    <Text appearance="hint" category="p2">
                      {selfCompanyUser?.role} for {companyName}
                    </Text>
                  )}
                />
                <Separator size="small" />
                <Text
                  appearance="hint"
                  category="p2"
                  style={styles.versionText}
                >
                  {`${
                    connectionStatus.current !== null
                      ? connectionStatus.current[0].toUpperCase() +
                        connectionStatus.current.slice(1)
                      : "Not connected"
                  } \u2022 ${getAppVersion()}`}
                </Text>
                <Separator size="medium" />
                <Divider />
              </View>
            )}
          >
            {renderInbox()}
            {renderCustomers()}
            <DrawerItem
              data-test="jobSitesButtonInDrawer"
              title="Locations"
              onPress={() => navigateTo("CompanySites")}
            />
            <DrawerItem
              data-test="groupsButtonInDrawer"
              title="Groups"
              onPress={() => navigateTo("CompanyGroups")}
            />
            <DrawerItem
              data-test="usersButtonInDrawer"
              title="Users"
              onPress={() => navigateTo("CompanyUsers")}
            />
            {renderEmailActivity()}
            {paymentsEnabled &&
              userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: selfCompanyUser?.role,
              }) && (
                <DrawerItem
                  onPress={() => navigateTo("Invoices")}
                  title="Payments"
                />
              )}
            {renderBilling()}
            <DrawerItem
              data-test="settingsButtonInDrawer"
              title="Settings"
              onPress={() => navigateTo("Settings")}
            />
          </KittenDrawer>
        </View>
      </Root>
      <PendingMutationsModal
        title="Syncing with server...you will be logged out after completion."
        continueDescription="Discard changes and logout now"
        isVisible={pendingMutationsVisible}
        onCancel={() => setPendingMutationsVisible(false)}
        onContinue={() => {
          setPendingMutationsVisible(false);
          runLogout().then();
        }}
        onEmptyOutbox={() => {
          setPendingMutationsVisible(false);
          runLogout().then();
        }}
      />
      <TutorialsModal
        visible={showTutorials}
        onClose={() => setShowTutorials(false)}
      />
    </>
  );
};

export default Drawer;
