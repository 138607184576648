import React from "react";
import { useSelector } from "react-redux";
import selectGroups from "../../../store/groups/selectors/selectGroups";
import GroupAssigneeListItem from "../../listItems/GroupAssignee";

interface GroupAssigneesProps {
  groupIds: Array<string>;
}

const GroupAssignees = ({ groupIds }: GroupAssigneesProps) => {
  const groups = useSelector(selectGroups);
  return (
    <>
      {groupIds
        .filter((groupId) => !groups.entities[groupId].isDeleted)
        .map((groupId) => {
          return <GroupAssigneeListItem key={groupId} groupId={groupId} />;
        })}
    </>
  );
};

export default GroupAssignees;
