import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { SafeAreaView, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import completeInboxItem from "../../api/functions/completeInboxItem";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import Container from "../../components/Container";
import HeaderContainer from "../../components/HeaderContainer";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCustomerById from "../../store/customers/selectors/selectCustomerById";
import selectInboxItemById from "../../store/inbox/selectors/selectInboxItemById";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { StackParamList } from "../../types";

const IconTextContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding: 0 16px;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledIcon = styled(Icon)`
  height: 24px;
  width: 24px;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const InboxItemDetails = () => {
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "InboxItemDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "InboxItemDetails">>();
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);
  const { id } = useSelector(selectUser);

  const { inboxItemId } = route.params;

  const inboxItem = useSelector((state) =>
    selectInboxItemById(state, inboxItemId)
  );
  const customer = useSelector((state) =>
    selectCustomerById(state, inboxItem.metadata?.customerId)
  );

  return (
    <>
      <Root level="2">
        <HeaderContainer>
          <HeaderWithTextAction
            navigation={{
              icon: "arrow-back",
              onPress: navigation.goBack,
            }}
            title="Inbox item details"
          />
        </HeaderContainer>
        <Divider />
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          scrollIndicatorInsets={{ right: 1 }}
        >
          <SafeAreaView>
            <Container>
              <Separator size="medium" />
              <StyledCard>
                <Separator size="medium" />
                <StyledText category="h6">Email not delivered</StyledText>
                <Separator size="small" />
                <IconTextContainer>
                  <StyledIcon
                    name="calendar-outline"
                    style={{
                      tintColor: theme["text-basic-color"],
                    }}
                  />
                  <Separator horizontal size="small" />
                  <Text category="s1">
                    {dayjs(inboxItem.createdAt).format(dateTimeFormat)}
                  </Text>
                </IconTextContainer>
                {customer && (
                  <>
                    <Separator size="small" />
                    <IconTextContainer>
                      <StyledIcon
                        name="person-outline"
                        style={{
                          tintColor: theme["text-basic-color"],
                        }}
                      />
                      <Separator horizontal size="small" />
                      <Text category="s1">{customer.name}</Text>
                    </IconTextContainer>
                  </>
                )}
                <Separator size="medium" />
                <StyledDivider />
                <Separator size="medium" />
                <StyledButton
                  onPress={() => {
                    completeInboxItem(
                      { id: inboxItem.id, userId: id },
                      inboxItem
                    );
                    navigation.goBack();
                  }}
                >
                  Mark complete
                </StyledButton>
                <Separator size="medium" />
              </StyledCard>
              <Separator />
              <StyledCard title="Additional information">
                <StyledText appearance="hint" category="c1">
                  Details
                </StyledText>
                <StyledText>{inboxItem.title}</StyledText>
                <Separator size={4} />
                <StyledText>{inboxItem.description}</StyledText>
                <Separator size="medium" />
              </StyledCard>
              <Separator size={48} />
              <Separator />
            </Container>
          </SafeAreaView>
        </ScrollView>
      </Root>
    </>
  );
};

export default InboxItemDetails;
