import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import UsersSelect from "../../UsersSelect";
import FullScreenModal from "../FullScreen";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";

const UsersPickerModal = ({
  availableUserIds,
  initialSelectedUserIds,
  isVisible,
  onClose,
  onSubmit,
}) => {
  const [selected, setSelected] = useState([]);
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });
  useEffect(() => {
    if (isVisible) {
      setSelected(initialSelectedUserIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <UsersSelect
        actionItem={{
          onPress: () => onSubmit(selected),
          text: "Done",
        }}
        availableUserIds={availableUserIds}
        navigation={{
          icon: "close-outline",
          onPress: onClose,
        }}
        onChange={setSelected}
        selectedUserIds={selected}
        title="Select Users"
      />
    </FullScreenModal>
  );
};

UsersPickerModal.propTypes = {
  availableUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialSelectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UsersPickerModal;
