import * as Location from "expo-location";
import { Platform } from "react-native";
import { addNotification } from "../../components/InAppNotifications";

let location: GoogleMapCoordinates = null;
let previous: GoogleMapCoordinates = null;

export default interface GoogleMapCoordinates {
  latitude: number;
  longitude: number;
  altitude: number;
  timestamp: number;
  accuracy: number;
  speed: number;
  heading: number;
}

const setLocation = (coordinates: GoogleMapCoordinates): void => {
  previous = location;
  location = coordinates;
};
const getLocation = async (errorNotification?: {
  title: string;
}): Promise<GoogleMapCoordinates> => {
  if (Platform.OS !== "web") {
    return location;
  }
  try {
    const locationObject = await Location.getCurrentPositionAsync({
      accuracy: Location.LocationAccuracy.High,
    });
    return {
      accuracy: locationObject.coords.accuracy,
      altitude: locationObject.coords.altitude,
      heading: locationObject.coords.heading,
      latitude: locationObject.coords.latitude,
      longitude: locationObject.coords.longitude,
      speed: locationObject.coords.speed,
      timestamp: locationObject.timestamp,
    };
  } catch (error) {
    if (errorNotification) {
      addNotification({
        message: "Your location could not be determined",
        status: "danger",
        title: errorNotification.title,
      });
    }
    return null;
  }
};
const getPrevious = (): GoogleMapCoordinates => previous;

export { getLocation, setLocation, getPrevious };
