import {
  createAction,
  createEntityAdapter,
  createSlice,
  Draft,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { sync } from "../../data/actions";
import {
  CompanyGroup,
  EntityState,
  OptimisticCompanyGroup,
} from "../../../types";

const adapter = createEntityAdapter<CompanyGroup>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});
const optimisticAdapter = createEntityAdapter<OptimisticCompanyGroup>();

export interface State extends EntityState<CompanyGroup> {
  optimistic: EntityState<OptimisticCompanyGroup>;
}

const initialState = adapter.getInitialState({
  optimistic: optimisticAdapter.getInitialState(),
}) as State;

const addGroup = ({
  group,
  state,
}: {
  group: CompanyGroup;
  state: Draft<State>;
}) => {
  const existingGroup = state.entities[group.id];
  if (
    existingGroup === undefined ||
    group.syncDateTime > existingGroup.syncDateTime
  ) {
    adapter.upsertOne(state, group);
  }
};

const name = "groups";

interface SetGroupPayload {
  group: CompanyGroup;
  mutation: string;
}

export const setGroupPending = createAction<SetGroupPayload>(
  `${name}/setGroup/pending`
);
export const setGroupFulfilled = createAction<SetGroupPayload>(
  `${name}/setGroup/fulfilled`
);
export const setGroupRejected = createAction(
  `${name}/setGroup/rejected`,
  (payload: { id: string; mutation: string }, error: Error) => ({
    error,
    payload,
  })
);

const groupsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGroup: (state, action) => {
      const { group } = action.payload;
      addGroup({ group, state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setGroupPending, (state, action) => {
      const { group, mutation } = action.payload;
      const existingOptimisticGroup = state.optimistic.entities[group.id];
      if (
        existingOptimisticGroup === undefined ||
        group.syncDateTime > existingOptimisticGroup.syncDateTime
      ) {
        optimisticAdapter.upsertOne(state.optimistic, {
          ...group,
          mutation,
          pending: true,
        });
      }
    });
    builder.addCase(setGroupFulfilled, (state, action) => {
      const { group, mutation } = action.payload;
      addGroup({ group, state });
      const existingOptimisticGroup = state.optimistic.entities[group.id];
      if (
        existingOptimisticGroup !== undefined &&
        mutation === existingOptimisticGroup.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, group.id);
      }
    });
    builder.addCase(setGroupRejected, (state, action) => {
      const { id, mutation } = action.payload;
      const existingOptimisticGroup = state.optimistic.entities[id];
      if (
        existingOptimisticGroup !== undefined &&
        mutation === existingOptimisticGroup.mutation
      ) {
        optimisticAdapter.removeOne(state.optimistic, id);
      }
    });
    builder.addCase(sync.fulfilled, (state, action) => {
      const { groups, lastSync } = action.payload;
      if (lastSync.fullSync) {
        adapter.setAll(state, groups);
      } else {
        groups.forEach((group) => addGroup({ group, state }));
      }
    });
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = groupsSlice;

export const { setGroup } = actions;

export default reducer;
