import API, { GraphQLResult } from "@aws-amplify/api";
import { Stripe } from "stripe";

import { StripeTaxRate } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import mutation from "./graphql";

export interface UpdateProductInput
  extends Pick<Stripe.TaxRateUpdateParams, "active" | "display_name"> {
  id: string;
}

const updateTaxRate = async ({
  active,
  display_name,
  id,
}: UpdateProductInput) => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
      variables: {
        active,
        display_name,
        id,
      },
    })) as GraphQLResult<{ updateTaxRate: StripeTaxRate }>;
    return data.updateTaxRate;
  } catch ({ errors }) {
    throw generateErrorMessage("updateTaxRate", errors);
  }
};

export default updateTaxRate;
