import mutation from "./graphql";
import client from "../../client";
import mapToCoordinatesInput from "../../utils/mapToCoordinatesInput";
import store from "../../../store";
import { setUserPending } from "../../../store/users/slice";
import { CompanyUser, Coordinates } from "../../../types";

type AdminClockOutUserInput = {
  coordinates: Coordinates;
  timestamp: string;
  id: string;
  userIdToClockOut: string;
};

const dispatchOptimisticResponse = (user: CompanyUser) => {
  const { dispatch } = store;
  dispatch(
    setUserPending({
      mutation: "adminClockOutUser",
      user: {
        ...user,
        syncDateTime: new Date().toISOString(),
        clockedIn: null,
      },
    })
  );
};

const adminClockOutUser = (
  { coordinates, timestamp, id, userIdToClockOut }: AdminClockOutUserInput,
  user: CompanyUser
) => {
  dispatchOptimisticResponse(user);
  client
    .mutate({
      errorPolicy: "all",
      mutation,
      variables: {
        coordinates: mapToCoordinatesInput(coordinates),
        timestamp,
        id,
        userIdToClockOut,
      },
    })
    .then();
};

export default adminClockOutUser;
