import gql from "graphql-tag";

import { stripePriceFragment } from "../../../fragments";

const updatePrice = gql`
  mutation updatePrice(
    $active: Boolean
    $id: ID!
    $metadata: StripePriceMetadataInput
  ) {
    updatePrice(active: $active, id: $id, metadata: $metadata) {
      ...stripePriceFragment
    }
  }
  ${stripePriceFragment}
`;

export default updatePrice;
