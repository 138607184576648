import gql from "graphql-tag";

import { stripeExpandedInvoiceFragment } from "../../../fragments";

const updateInvoice = gql`
  mutation updateInvoice($default_tax_rates: [ID], $id: ID!) {
    updateInvoice(default_tax_rates: $default_tax_rates, id: $id) {
      ...stripeExpandedInvoiceFragment
    }
  }
  ${stripeExpandedInvoiceFragment}
`;

export default updateInvoice;
