import gql from "graphql-tag";

import { stripePriceFragment } from "../../../fragments";

const retrievePrice = gql`
  query retrievePrice($id: ID!) {
    retrievePrice(id: $id) {
      ...stripePriceFragment
    }
  }
  ${stripePriceFragment}
`;

export default retrievePrice;
