import { createSelector } from "@reduxjs/toolkit";

import selectActionItems from "../selectActionItems";

const selectActionItemEntities = createSelector(
  [selectActionItems],
  (actionItems) => actionItems.entities
);

export default selectActionItemEntities;
