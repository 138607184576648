import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "../../../types";

import AlertModal from "../Alert";
import { addNotification } from "../../InAppNotifications";
import MultiActionAlertModal from "../MultiActionAlert";
import { getLocation } from "../../../location/mapLocation";
import dismissJob from "../../../api/functions/dismissJob";
import { clearMapSelection } from "../../../store/mapSelection/slice";

momentDurationFormatSetup(moment);

interface Props {
  isVisible: boolean;
  job: Job;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
}

const StartJobLateAlertModal = ({
  isVisible,
  onCancel,
  onClose,
  onConfirm,
  job,
}: Props) => {
  const [duration, setDuration] = useState(null);
  const [showDismissAlert, setShowDismissAlert] = useState(false);
  const selectedId = useSelector((state) => state.mapSelection.id);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isVisible) {
      setDuration(
        moment
          .duration(
            moment().diff(moment(job.startDateTime), "minutes"),
            "minute"
          )
          .format("y [years], w [weeks], d [days], h [hours] [and] m [minutes]")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const closeAlert = () => setShowDismissAlert(false);

  const runDismissJob = async () => {
    closeAlert();
    const userCoordinates = await getLocation({
      title: "Failed to dismiss job",
    });
    if (userCoordinates) {
      dismissJob(
        {
          coordinates: {
            lat: userCoordinates.latitude,
            lng: userCoordinates.longitude,
          },
        },
        job
      );
      if (selectedId === job.id) {
        dispatch(clearMapSelection());
      }
      addNotification({
        title: "Job dismissed",
        message:
          job.rrule &&
          "The next repeating job in the sequence has been created.",
        status: "success",
      });
      onClose();
    }
  };

  return (
    <>
      <MultiActionAlertModal
        actions={[
          {
            onPress: () => setShowDismissAlert(true),
            text: "Dismiss job",
          },
          {
            appearance: "ghost",
            onPress: onConfirm,
            text: "Start job",
          },
          {
            appearance: "ghost",
            onPress: onCancel,
            text: "Cancel",
          },
        ]}
        isVisible={isVisible}
        message={`You will be starting this job ${duration} late. Are you sure you want to start this job?`}
        onClose={onClose}
      />
      <AlertModal
        isVisible={showDismissAlert}
        onClose={closeAlert}
        message="Are you sure you want to dismiss this job?"
        confirmText="Dismiss job"
        onConfirm={runDismissJob}
        cancelText="Cancel"
        onCancel={closeAlert}
      />
    </>
  );
};

export default StartJobLateAlertModal;
