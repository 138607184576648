import {
  ButtonProps,
  Icon,
  Text,
  TextProps,
  useTheme,
} from "@ui-kitten/components";
import React from "react";
import { StyleSheet, View } from "react-native";

interface Props
  extends Pick<ButtonProps, "status">,
    Pick<TextProps, "children"> {
  iconName?: string;
  iconPack?: "MaterialIcons" | "MaterialCommunityIcons";
}

const styles = StyleSheet.create({
  icon: {
    marginEnd: 4,
  },
  root: {
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
});

const Label = ({ children, iconName, iconPack, status }: Props) => {
  const theme = useTheme();
  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: theme[`color-${status}-transparent-default`],
          borderColor: theme[`text-${status}-color`],
        },
      ]}
    >
      {iconName && (
        <Icon
          height={18}
          fill={theme[`text-${status}-color`]}
          name={iconName}
          pack={iconPack}
          style={styles.icon}
          width={18}
        />
      )}
      <Text category="s2" status={status}>
        {children}
      </Text>
    </View>
  );
};

Label.defaultProps = {
  iconName: undefined,
  iconPack: undefined,
};

export default Label;
