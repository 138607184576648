import moment from "moment-timezone";

const getDateTimeRangeString = ({ start, end, dateTimeFormat, timeFormat }) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  if (startMoment.isSame(endMoment)) {
    return startMoment.format(dateTimeFormat);
  }
  if (startMoment.isSame(endMoment, "day")) {
    return `${startMoment.format(dateTimeFormat)} - ${endMoment.format(
      timeFormat
    )}`;
  }
  return `${startMoment.format(dateTimeFormat)} - ${endMoment.format(
    dateTimeFormat
  )}`;
};

export default getDateTimeRangeString;
