import React from "react";
import { useSelector } from "react-redux";
import { View, StyleSheet, Pressable } from "react-native";
import { Text, useTheme } from "@ui-kitten/components";
import moment from "moment-timezone";

import selectTimeFormat from "../../../store/settings/selectors/selectTimeFormat";
import selectById from "../../../store/users/selectors/selectUserById";

const styles = StyleSheet.create({
  markerContainer: {
    borderRadius: 8,
    padding: 8,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  clickable: {
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    top: "100%",
    left: "50%",
    // @ts-ignore
    transform: [{ translateX: "-50%" }, { translateY: "-100%" }],
  },
  tail: {
    width: 0,
    height: 0,
    borderLeftWidth: 4,
    borderLeftColor: "transparent",
    borderRightWidth: 4,
    borderRightColor: "transparent",
    borderTopWidth: 4,
  },
  whiteText: {
    color: "#FFFFFF",
  },
});

interface UserMarkerProps {
  id: string;
  onPress: (id: string) => void;
}

const UserMarker = ({ id, onPress }: UserMarkerProps) => {
  const theme = useTheme();
  const user = useSelector((state) => selectById(state, id));
  const timeFormat = useSelector(selectTimeFormat);

  if (!user) {
    return null;
  }
  const color = theme["color-basic-900"];

  const handleMarkerPress = (e) => {
    e.nativeEvent.stopPropagation();
    onPress(id);
  };
  const { speed } = user;

  return (
    <Pressable
      style={styles.clickable}
      onPress={handleMarkerPress}
      pointerEvents="box-none"
    >
      <View style={[styles.markerContainer, { backgroundColor: color }]}>
        <Text
          category="s1"
          numberOfLines={1}
          style={styles.whiteText}
        >{`${user.firstName} ${user.lastName}`}</Text>
        <Text
          category="c1"
          numberOfLines={1}
          style={styles.whiteText}
        >{`${moment(user.locationUpdatedAt).format(timeFormat)}${
          speed && speed >= 1
            ? ` \u2022 ${(speed * 2.237).toFixed(0)} mph`
            : " \u2022 Still"
        }`}</Text>
      </View>
      <View style={[styles.tail, { borderTopColor: color }]} />
    </Pressable>
  );
};

export default UserMarker;
