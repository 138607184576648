import API, { GraphQLResult } from "@aws-amplify/api";

import { EmailActivity } from "../../../types";
import generateErrorMessage from "../../utils/generateErrorMessage";
import query from "./graphql";

interface QueryCustomerEmailActivityInput {
  after?: string;
  id: string;
}

const queryCustomerEmailActivity = async ({
  after,
  id,
}: QueryCustomerEmailActivityInput) => {
  try {
    const { data } = (await API.graphql({
      query,
      variables: {
        after,
        id,
      },
    })) as GraphQLResult<{
      queryCustomerEmailActivity: {
        items: Array<EmailActivity>;
        nextToken?: string;
      };
    }>;
    return data.queryCustomerEmailActivity;
  } catch ({ errors }) {
    throw generateErrorMessage("queryCustomerEmailActivity", errors);
  }
};

export default queryCustomerEmailActivity;
