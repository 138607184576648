import { CompanyUser } from "../../types";

const getUserFullName = (user: CompanyUser): string => {
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return "[undefined]";
};

export default getUserFullName;
