import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { CompanyGroup } from "../../../../types";

const selectGroups = createSelector(
  [
    (state: any) => state.data.groups,
    (state: any) => state.data.groups.optimistic,
  ],
  (
    groups,
    optimisticGroups
  ): { entities: Record<string, CompanyGroup>; ids: string[] } => {
    const entities = {
      ...groups.entities,
      ...optimisticGroups.entities,
    };
    return {
      entities,
      ids: _.union([...groups.ids, ...optimisticGroups.ids]),
    };
  }
);

export default selectGroups;
