import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { resetUser } from "../../auth/slice";
import { DateTimeRange, EntityState, EmailActivity } from "../../../types";
import { Mode } from "../../../components/TimeRangePicker";

const adapter = createEntityAdapter<EmailActivity>();

export interface State extends EntityState<EmailActivity> {
  emails: Array<string>;
  queryDateTimes: Partial<DateTimeRange>;
  customRangeDateTimes: Partial<DateTimeRange>;
  mode: Mode;
  selectedDate: string;
}

const initialState: State = adapter.getInitialState({
  emails: [],
  queryDateTimes: {
    startDateTime: null,
    endDateTime: null,
  },
  customRangeDateTimes: {
    startDateTime: null,
    endDateTime: null,
  },
  selectedDate: new Date().toISOString(),
  mode: "week",
}) as State;

const name = "emailActivity";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setEmails: (state, action) => {
      const { emails }: { emails: Array<string> } = action.payload;
      state.emails = emails.map((email) => email.trim().toLowerCase());
    },
    setMode: (state, action) => {
      const { mode }: { mode: Mode } = action.payload;
      state.mode = mode;
    },
    setCustomRangeDateTimes: (state, action) => {
      const {
        startDateTime,
        endDateTime,
      }: { startDateTime: string; endDateTime: string } = action.payload;
      state.customRangeDateTimes = { startDateTime, endDateTime };
    },
    setQueryDateTimes: (state, action) => {
      const {
        startDateTime,
        endDateTime,
      }: { startDateTime: string; endDateTime: string } = action.payload;
      state.queryDateTimes = { startDateTime, endDateTime };
    },
    setSelectedDate: (state, action) => {
      const { selectedDate }: { selectedDate: string } = action.payload;
      state.selectedDate = selectedDate;
    },
    setEmailActivities: (state, action) => {
      const {
        emailActivities,
      }: { emailActivities: Array<EmailActivity> } = action.payload;
      adapter.setAll(state, emailActivities);
    },
    resetFilters: (state) => {
      state.emails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetUser.fulfilled, () => initialState);
    builder.addCase(PURGE, () => initialState);
  },
});

const { actions, reducer } = slice;

export const {
  setEmails,
  setMode,
  setCustomRangeDateTimes,
  setSelectedDate,
  resetFilters,
  setQueryDateTimes,
  setEmailActivities,
} = actions;

export default reducer;
