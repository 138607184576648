import { Coordinates } from "../../../types";

const mapToCoordinatesInput = (coordinates: Coordinates) =>
  coordinates && {
    accuracy: coordinates.accuracy,
    lat: coordinates.lat,
    lng: coordinates.lng,
  };

export default mapToCoordinatesInput;
