import mutation from "./graphql";
import client from "../../client";
import deepCopy from "../../utils/deepCopy";
import store from "../../../store";
import selectSiteById from "../../../store/sites/selectors/selectById";
import { setSitePending } from "../../../store/sites/slice";
import { Coordinates, Media } from "../../../types";

interface AdminUpdateCompanySiteInput {
  id: string;
  coordinates: Coordinates;
  address: string;
  name: string;
  notes: string;
  privateNotes: string;
  geofence: number;
  timezone: string;
  media: Array<Media>;
}

const dispatchOptimisticResponse = ({
  id,
  coordinates,
  address,
  name,
  notes,
  privateNotes,
  geofence,
  timezone,
}: AdminUpdateCompanySiteInput) => {
  const timestamp = new Date().toISOString();
  const { dispatch, getState } = store;
  const state = getState();
  const { id: userId } = state.auth.user;
  const site = selectSiteById(state, id);
  dispatch(
    setSitePending({
      mutation: "adminUpdateCompanySite",
      site: {
        ...site,
        syncDateTime: timestamp,
        updated: {
          at: timestamp,
          by: userId,
        },
        coordinates,
        address,
        name,
        geofence,
        privateNotes,
        notes,
        timezone,
      },
    })
  );
};

const adminUpdateCompanySite = ({
  id,
  coordinates,
  address,
  name,
  notes,
  privateNotes,
  geofence,
  timezone,
  media,
}: AdminUpdateCompanySiteInput) => {
  dispatchOptimisticResponse({
    id,
    coordinates,
    address,
    name,
    notes,
    privateNotes,
    geofence,
    timezone,
    media,
  });
  client.mutate({
    mutation,
    variables: deepCopy({
      id,
      coordinates,
      address,
      name,
      notes,
      privateNotes,
      geofence,
      timezone,
      media,
    }),
    errorPolicy: "all",
  });
};

export default adminUpdateCompanySite;
