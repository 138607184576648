import { CheckBox } from "@ui-kitten/components";
import React, { Fragment } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import useJobUtils from "../../../hooks/useJobUtils";
import { Job, Task, TaskCompletionRecord } from "../../../types";
import TaskListItem from "../../listItems/Task/TaskListItem";
import TaskCompletionRecordListItem from "../../listItems/TaskCompletionRecord";

interface CompletableTasksListProps {
  job: Job;
  onTaskPress: (task: Task) => void;
  onTaskCompletionRecordPress: ({
    task,
    taskCompletionRecord,
  }: {
    task: Task;
    taskCompletionRecord: TaskCompletionRecord;
  }) => void;
  reportsLoading?: boolean;
}

const StyledCheckBox = styled(CheckBox)`
  margin-left: 16px;
  margin-right: 16px;
`;

const CompletableTasksList = ({
  job,
  onTaskPress,
  onTaskCompletionRecordPress,
  reportsLoading,
}: CompletableTasksListProps) => {
  const { getTaskCompletionRecordByTaskId } = useJobUtils(job.id);

  const accessoryLeft = () => (
    <View pointerEvents="none">
      <StyledCheckBox />
    </View>
  );

  const renderListItem = (task: Task) => {
    const taskCompletionRecord = getTaskCompletionRecordByTaskId(task.id);
    if (taskCompletionRecord) {
      return (
        <TaskCompletionRecordListItem
          jobId={job.id}
          onPress={onTaskCompletionRecordPress}
          reportsLoading={reportsLoading}
          task={task}
          taskCompletionRecord={taskCompletionRecord}
        />
      );
    }
    return (
      <TaskListItem
        accessoryLeft={accessoryLeft}
        onPress={onTaskPress}
        task={task}
      />
    );
  };

  return (
    <>
      {job.tasks.map((task) => (
        <Fragment key={task.id}>{renderListItem(task)}</Fragment>
      ))}
    </>
  );
};

export default CompletableTasksList;
