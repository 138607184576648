import mutation from "./graphql";
import client from "../../client";
import mapToCoordinatesInput from "../../utils/mapToCoordinatesInput";
import store from "../../../store";
import { setUserPending } from "../../../store/users/slice";
import { CompanyUser, Coordinates } from "../../../types";

type ClockOutInput = {
  coordinates: Coordinates;
  timestamp: string;
  id: string;
};

const dispatchOptimisticResponse = (user: CompanyUser) => {
  const { dispatch } = store;
  dispatch(
    setUserPending({
      mutation: "clockOut",
      user: {
        ...user,
        syncDateTime: new Date().toISOString(),
        clockedIn: null,
      },
    })
  );
};

const clockOut = (
  { coordinates, timestamp, id }: ClockOutInput,
  user: CompanyUser
) => {
  dispatchOptimisticResponse(user);
  client.mutate({
    errorPolicy: "all",
    mutation,
    variables: {
      coordinates: mapToCoordinatesInput(coordinates),
      timestamp,
      id,
    },
  });
};

export default clockOut;
