import { useNavigation } from "@react-navigation/native";
import { Divider, Spinner, Text } from "@ui-kitten/components";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";

import queryQuoteEmailAcivity from "../../api/functions/queryQuoteEmailAcivity";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import { EmailActivity } from "../../types";
import Button from "../buildingBlocks/Button";
import Separator from "../buildingBlocks/Separator";
import Card from "../Card";
import IconButton from "../IconButton";
import EmailActivityListItem from "../listItems/EmailActivity";

interface QuoteEmailActivityCardProps {
  quoteId: string;
  refreshTimestamp?: string;
  style?: StyleProp<ViewStyle>;
}

const RefreshButtonContainer = styled.View`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const SpinnerContainer = styled.View`
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const QuoteEmailActivityCard = ({
  quoteId,
  refreshTimestamp,
  style,
}: QuoteEmailActivityCardProps) => {
  const navigation = useNavigation();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [emailActivityFeed, setEmailActivityFeed] = useState<{
    items: Array<EmailActivity>;
    nextToken?: string;
  }>();

  const [
    queryQuoteEmailActivityPending,
    setQueryQuoteEmailActivityPending,
  ] = useState(false);
  const [
    queryQuoteEmailActivityError,
    setQueryQuoteEmailActivityError,
  ] = useState<Error>();

  const runQueryQuoteEmailActivity = useCallback(async (id: string) => {
    setQueryQuoteEmailActivityPending(true);
    try {
      setEmailActivityFeed(await queryQuoteEmailAcivity({ id }));
    } catch (error) {
      setQueryQuoteEmailActivityError(error);
    }
    setQueryQuoteEmailActivityPending(false);
  }, []);

  useEffect(() => {
    runQueryQuoteEmailActivity(quoteId);
  }, [quoteId, refreshTimestamp]);

  return (
    <Card style={style} title="Email activity">
      {queryQuoteEmailActivityPending ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          {queryQuoteEmailActivityError ||
          emailActivityFeed?.items.length === 0 ? (
            <StyledText appearance="hint">
              {queryQuoteEmailActivityError
                ? `Error: ${queryQuoteEmailActivityError.message}`
                : "No email activity. Up to a few minutes may be required for data to be updated."}
            </StyledText>
          ) : (
            <>
              {emailActivityFeed?.items.map((emailActivity, index) => (
                <Fragment key={emailActivity.id}>
                  {index > 0 && <StyledDivider />}
                  <EmailActivityListItem
                    emailActivity={emailActivity}
                    hideContentType
                    timestampFormat={dateTimeFormat}
                  />
                </Fragment>
              ))}
              {emailActivityFeed?.nextToken && (
                <>
                  <StyledDivider />
                  <Separator size="medium" />
                  <StyledButton
                    appearance="ghost"
                    onPress={() =>
                      navigation.navigate("QuoteEmailActivity", { quoteId })
                    }
                  >
                    View all
                  </StyledButton>
                </>
              )}
            </>
          )}
        </>
      )}
      <Separator size="medium" />
      <RefreshButtonContainer>
        <IconButton
          appearance="ghost"
          disabled={queryQuoteEmailActivityPending}
          name="refresh"
          onPress={() => runQueryQuoteEmailActivity(quoteId)}
        />
      </RefreshButtonContainer>
    </Card>
  );
};

export default QuoteEmailActivityCard;
