import { useEffect, useRef, useState } from "react";
import { Animated } from "react-native";

const useFadeAnimation = ({
  visible,
  enterDuration = 200,
  exitDuration = 300,
}) => {
  const [componentRendered, setComponentRendered] = useState(visible);
  const componentMounted = useRef(false);
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    if (visible) {
      setComponentRendered(true);
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: enterDuration,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: exitDuration,
        useNativeDriver: true,
      }).start(() => {
        if (componentMounted.current) {
          setComponentRendered(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);
  return { componentRendered, fadeAnimation };
};

export default useFadeAnimation;
