import { Divider, Input, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import styled from "styled-components/native";
import uuidv4 from "uuid/v4";
import createInboxItem from "../../../api/functions/createInboxItem";
import updateInboxItem from "../../../api/functions/updateInboxItem";
import selectDateTimeFormat from "../../../store/settings/selectors/selectDateTimeFormat";

import { Reminder } from "../../../types";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import ListItem from "../../listItems/Base";
import CustomerListItem from "../../listItems/Customer";
import UserListItem from "../../listItems/User";
import CustomerSelectModal from "../CustomerSelect";
import DateAndTimePickerModal from "../DateAndTimePicker";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";
import UserSelectModal from "../UserSelect";

interface EditReminderModalProps extends FullScreenModalProps {
  initialValues?: {
    customerId?: string;
    userId?: string;
  };
  reminder?: Reminder;
}

interface FormValues {
  customerId: string;
  dateTime: string;
  description?: string;
  title: string;
  userId: string;
}

const Root = styled(Layout)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  margin: 0 16px;
`;

const StyledCustomerListItem = styled(CustomerListItem)`
  margin: 0 16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const StyledListItem = styled(ListItem)`
  margin: 0 16px;
`;

const StyledText = styled(Text)`
  padding: 0 16px;
`;

const StyledUserListItem = styled(UserListItem)`
  margin: 0 16px;
`;

const getInitialValues = ({
  initialValues,
  reminder,
}: Pick<EditReminderModalProps, "initialValues" | "reminder">): FormValues => {
  if (reminder) {
    return {
      customerId: reminder.metadata.customerId,
      dateTime: reminder.metadata.dateTime,
      description: reminder.description,
      title: reminder.title,
      userId: reminder.metadata.userId,
    };
  }
  return {
    customerId: initialValues?.customerId || null,
    dateTime: undefined,
    description: "",
    title: "",
    userId: initialValues?.userId || null,
  };
};

const EditReminderModal = ({
  initialValues,
  isVisible,
  onClose,
  reminder,
}: EditReminderModalProps) => {
  const [savePending, setSavePending] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: getInitialValues({ initialValues, reminder }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      if (!values.userId) {
        addNotification({
          message: "Please complete the required field: Assigned to",
          status: "danger",
          title: `${reminder ? "Update" : "Create"} reminder failed`,
        });
        return;
      }
      if (!values.customerId) {
        addNotification({
          message: "Please complete the required field: Customer",
          status: "danger",
          title: `${reminder ? "Update" : "Create"} reminder failed`,
        });
        return;
      }
      if (!values.title) {
        addNotification({
          message: "Please complete the required field: Title",
          status: "danger",
          title: `${reminder ? "Update" : "Create"} reminder failed`,
        });
        return;
      }
      if (!values.dateTime) {
        addNotification({
          message: "Please complete the required field: Due date",
          status: "danger",
          title: `${reminder ? "Update" : "Create"} reminder failed`,
        });
        return;
      }
      setSavePending(true);
      try {
        if (reminder) {
          updateInboxItem(
            {
              description: values.description,
              metadata: {
                customerId: values.customerId,
                dateTime: values.dateTime,
                userId: values.userId,
              },
              title: values.title,
            },
            reminder
          );
        } else {
          createInboxItem({
            description: values.description,
            id: uuidv4(),
            metadata: {
              customerId: values.customerId,
              dateTime: values.dateTime,
              userId: values.userId,
            },
            title: values.title,
            type: "Reminder",
          });
        }
        onClose();
      } catch (error) {
        addNotification({
          message: error.message,
          status: "danger",
          title: "Create inbox item failed",
        });
      }
      setSavePending(false);
    },
  });
  const theme = useTheme();

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const [userSelectModalVisible, setUserSelectModalVisible] = useState(false);
  const [customerSelectModalVisible, setCustomerSelectModalVisible] = useState(
    false
  );
  const [
    dateAndTimePickerModalVisible,
    setDateAndTimePickerModalVisible,
  ] = useState(false);

  useEffect(() => {
    if (isVisible) {
      formik.resetForm({
        values: getInitialValues({ initialValues, reminder }),
      });
    }
  }, [isVisible]);

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Root level="2">
          <Header
            navigation={{ icon: "close", onPress: onClose }}
            title={`${reminder ? "Update" : "Create"} reminder`}
          />
          <Divider />
          <ScrollView
            keyboardShouldPersistTaps="never"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <KeyboardAvoidingView>
              <Separator size="medium" />
              <StyledText category="h6">Assigned to</StyledText>
              {Boolean(formik.values.userId) && (
                <>
                  <Separator size="medium" />
                  <StyledUserListItem disabled userId={formik.values.userId} />
                </>
              )}
              <Separator size="medium" />
              <StyledButton
                onPress={() => setUserSelectModalVisible(true)}
                status="basic"
              >
                {`${formik.values.userId ? "Change" : "Set"} user`}
              </StyledButton>
              <Separator size="medium" />
              <StyledText category="h6">Customer</StyledText>
              {Boolean(formik.values.customerId) && (
                <>
                  <Separator size="medium" />
                  <StyledCustomerListItem
                    customerId={formik.values.customerId}
                    disabled
                  />
                </>
              )}
              <Separator size="medium" />
              <StyledButton
                onPress={() => setCustomerSelectModalVisible(true)}
                status="basic"
              >
                {`${formik.values.customerId ? "Change" : "Set"} customer`}
              </StyledButton>
              <Separator size="medium" />
              <StyledText category="h6">Title</StyledText>
              <Separator size="medium" />
              <StyledInput
                onChangeText={(text) => formik.setFieldValue("title", text)}
                style={{ backgroundColor: theme["background-basic-color-1"] }}
                value={formik.values.title}
              />
              <Separator size="medium" />
              <StyledText category="h6">Description</StyledText>
              <Separator size="medium" />
              <StyledInput
                multiline
                numberOfLines={3}
                onChangeText={(text) =>
                  formik.setFieldValue("description", text)
                }
                style={{ backgroundColor: theme["background-basic-color-1"] }}
                textStyle={{ height: 64 }}
                defaultValue={formik.values.description}
              />
              <Separator size="medium" />
              <StyledText category="h6">Due date</StyledText>
              {Boolean(formik.values.dateTime) && (
                <>
                  <Separator size="medium" />
                  <StyledListItem
                    disabled
                    title={dayjs(formik.values.dateTime).format(dateTimeFormat)}
                  />
                </>
              )}
              <Separator size="medium" />
              <StyledButton
                onPress={() => setDateAndTimePickerModalVisible(true)}
                status="basic"
              >
                {`${formik.values.dateTime ? "Change" : "Set"} due date`}
              </StyledButton>
              <Separator size="medium" />
            </KeyboardAvoidingView>
          </ScrollView>
          <Divider />
          <Layout>
            <SafeAreaView>
              <Separator size="medium" />
              <StyledButton
                loading={savePending}
                onPress={() => formik.handleSubmit()}
              >
                Save
              </StyledButton>
              <Separator size="medium" />
            </SafeAreaView>
          </Layout>
        </Root>
      </FullScreenModal>
      <UserSelectModal
        filterByRole={["Admin", "Supervisor"]}
        headerTitle="Set user"
        isVisible={userSelectModalVisible}
        onClose={() => setUserSelectModalVisible(false)}
        onSubmit={(userId) => {
          formik.setFieldValue("userId", userId);
          setUserSelectModalVisible(false);
        }}
      />
      <CustomerSelectModal
        isVisible={customerSelectModalVisible}
        onClose={() => setCustomerSelectModalVisible(false)}
        onSubmit={(customerId) => {
          formik.setFieldValue("customerId", customerId);
          setCustomerSelectModalVisible(false);
        }}
      />
      <DateAndTimePickerModal
        dateTime={dayjs(formik.values.dateTime).toDate()}
        visible={dateAndTimePickerModalVisible}
        onClose={() => setDateAndTimePickerModalVisible(false)}
        onDone={(date) => {
          formik.setFieldValue("dateTime", dayjs(date).toISOString());
          setDateAndTimePickerModalVisible(false);
        }}
      />
    </>
  );
};

export default EditReminderModal;
