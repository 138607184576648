import React, { useRef, useState, useEffect } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Video, Audio } from "expo-av";
import { Text, Button, Spinner } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";

import { Tutorial } from "../../../types";
import DefaultFullScreenModal from "../DefaultFullScreen";
import { Separator } from "../../buildingBlocks";
import getTutorialMedia from "../../../api/functions/getTutorialMedia";
import { addNotification } from "../../InAppNotifications";

dayjs.extend(duration);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  mobileVideo: {
    height: 500,
  },
  webVideo: {
    width: "100%",
    maxHeight: "80vh",
  },
  description: {
    padding: 16,
    alignSelf: "center",
  },
  getHelp: {
    padding: 16,
  },
  getHelpButton: {
    margin: 16,
  },
  contentContainer: {
    margin: 16,
  },
  timestampContainer: {
    flexDirection: "row",
    marginVertical: 2,
  },
  spinnerContainer: {
    height: 500,
    justifyContent: "center",
    alignItems: "center",
  },
});

interface TutorialModalProps {
  visible: boolean;
  onClose: () => void;
  tutorial: Tutorial;
}

const TutorialModal = ({ visible, onClose, tutorial }: TutorialModalProps) => {
  const video = useRef(null);
  const [videoUrl, setVideoUrl] = useState<string>(null);

  useEffect(() => {
    (async () => {
      if (visible) {
        try {
          await Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
          const { url } = await getTutorialMedia(tutorial.s3FileName);
          setVideoUrl(url);
        } catch (e) {
          addNotification({ message: e, title: "Error", status: "danger" });
        }
      }
    })();
  }, [tutorial, visible]);
  return (
    <DefaultFullScreenModal
      isVisible={visible}
      onClose={onClose}
      scrollable
      title={tutorial.title}
      leftControlIcon="close-outline"
    >
      <Text style={styles.description} category="h5">
        {tutorial.description}
      </Text>
      <View style={styles.root}>
        {!videoUrl ? (
          <View style={styles.spinnerContainer}>
            <Spinner />
          </View>
        ) : (
          <Video
            ref={video}
            style={Platform.OS === "web" ? styles.webVideo : styles.mobileVideo}
            source={{
              uri: videoUrl,
            }}
            useNativeControls
            resizeMode="contain"
            onLoad={() => {
              // fix for bug on ios
              video.current.playAsync();
              video.current.pauseAsync();
            }}
          />
        )}
        <View style={styles.contentContainer}>
          {videoUrl !== null && (
            <>
              <Text category="h6">Content</Text>
              {tutorial.timestamps.map(({ timestamp, description }) => (
                <View style={styles.timestampContainer} key={timestamp}>
                  <Text
                    onPress={() => {
                      video.current.setPositionAsync(timestamp);
                      video.current.playAsync();
                    }}
                    status="primary"
                  >
                    {`${dayjs
                      .duration(timestamp)
                      .format("mm:ss")} - ${description}`}
                  </Text>
                </View>
              ))}
              <Separator />
            </>
          )}

          <Text category="h6">Questions?</Text>
          <Text style={styles.timestampContainer}>
            Please reach out if you have any questions or need assistance using
            Talarium.
          </Text>
          <Text style={styles.timestampContainer}>
            You can re-watch this tutorial by opening the side menu and pressing
            the Tutorials button.
          </Text>
          <Separator />
          <Button
            onPress={async () => {
              await WebBrowser.openBrowserAsync("https://talarium.io/contact");
            }}
          >
            Talk to support
          </Button>
        </View>
      </View>
    </DefaultFullScreenModal>
  );
};

export default TutorialModal;
