import { useTheme } from "@ui-kitten/components";
import React from "react";

import Circle from "../Circle";

interface SiteMapCircleProps {
  center: {
    latitude: number;
    longitude: number;
  };
  radius: number;
}

const SiteMapCircle = ({ center, radius }: SiteMapCircleProps) => {
  const theme = useTheme();
  return (
    <Circle
      center={center}
      fillColor={theme["color-basic-transparent-500"]}
      radius={radius}
    />
  );
};

export default SiteMapCircle;
