import gql from "graphql-tag";

import { companyGroupFragment } from "../../../fragments";

export default gql`
  mutation adminUpdateCompanyGroup($id: ID!, $name: String!, $members: [ID!]!) {
    adminUpdateCompanyGroup(id: $id, name: $name, members: $members) {
      ...companyGroupFragment
    }
  }
  ${companyGroupFragment}
`;
