import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import selectJobs from "../selectJobs";
import selectUpcomingJobIds from "../selectUpcomingJobIds";
import { FormattedJob } from "../../../../types";

const adapter = createEntityAdapter<FormattedJob>();

const selectUserInProgressJobs = createSelector(
  [(_, userId: string) => userId, selectJobs, selectUpcomingJobIds],
  (userId, jobs, upcomingJobIds) => {
    return adapter.upsertMany(
      adapter.getInitialState(),
      upcomingJobIds
        .filter((jobId) => {
          const job = jobs.entities[jobId];
          return (
            !job.isDeleted &&
            !job.finished &&
            job.startJobRecords.some(
              (startJobRecord) => startJobRecord.record.by === userId
            )
          );
        })
        .map((jobId) => jobs.entities[jobId])
    );
  }
);

export default selectUserInProgressJobs;
