import API, { GraphQLResult } from "@aws-amplify/api";

import mutation from "./graphql";
import generateErrorMessage from "../../utils/generateErrorMessage";
import { PostmarkMessageSendingResponse } from "../../../types";

const sendUnsubscribeRequest = async () => {
  try {
    const { data } = (await API.graphql({
      query: mutation,
    })) as GraphQLResult<{
      sendUnsubscribeRequest: PostmarkMessageSendingResponse[];
    }>;
    return data.sendUnsubscribeRequest;
  } catch ({ errors }) {
    throw generateErrorMessage("sendUnsubscribeRequest", errors);
  }
};

export default sendUnsubscribeRequest;
