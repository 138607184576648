import { useSelector } from "react-redux";
import selectCompany from "../../store/company/selectors/selectCompany";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

const useCompanySettings = () => {
  const company = useSelector(selectCompany);

  const reportsEnabled = isFeatureEnabled({ company, feature: "reports" });
  const dailyActivityReportsEnabled =
    reportsEnabled &&
    (company?.jobSettings
      ? company.jobSettings.dailyActivityReportsEnabled
      : true);
  const incidentReportsEnabled =
    reportsEnabled &&
    (company?.jobSettings ? company.jobSettings.incidentReportsEnabled : true);

  return {
    dailyActivityReportsEnabled,
    incidentReportsEnabled,
  };
};

export default useCompanySettings;
