import {
  Divider,
  Icon,
  Input,
  Layout,
  Spinner,
  Text,
  TopNavigationAction,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Keyboard, SafeAreaView } from "react-native";
import styled from "styled-components/native";

import listTaxRates from "../../../api/functions/listTaxRates";
import useAndroidBackHandler from "../../../device/useAndroidBackHandler";
import { StripeTaxRate } from "../../../types";
import Button from "../../buildingBlocks/Button";
import Header from "../../buildingBlocks/Header";
import Separator from "../../buildingBlocks/Separator";
import { addNotification } from "../../InAppNotifications";
import TaxRateListItem from "../../listItems/TaxRate";
import CreateTaxRateModal from "../CreateTaxRate";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface TaxRateSelectModalProps extends FullScreenModalProps {
  createTaxRateDisabled?: boolean;
  headerSubtitle?: string;
  headerTitle?: string;
  onSubmit: (taxRates: Array<StripeTaxRate>) => void;
  selected?: Array<StripeTaxRate>;
  submitAsync?: boolean;
}

const ListEmptyContainer = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const Root = styled(Layout)`
  flex: 1;
`;

const StyledDivider = styled(Divider)`
  margin-left: 16px;
`;

const StyledInput = styled(Input)`
  margin: 0 16px;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const listTaxRatesErrorTitle = "List tax rates failed";

const TaxRateSelectModal = ({
  createTaxRateDisabled = false,
  headerSubtitle,
  headerTitle = "Add tax",
  isVisible,
  onClose,
  onSubmit,
  selected = [],
}: TaxRateSelectModalProps) => {
  const theme = useTheme();
  useAndroidBackHandler({ enabled: isVisible, onBackPress: onClose });

  const [taxRates, setTaxRates] = useState<Array<StripeTaxRate>>();

  const [searchText, setSearchText] = useState("");

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [tempSelected, setTempSelected] = useState<Array<StripeTaxRate>>([]);
  const [createTaxRateModalVisible, setCreateTaxRateModalVisible] = useState(
    false
  );
  const [submitPending, setSubmitPending] = useState(false);

  const runListTaxRates = useCallback(async () => {
    try {
      let data = [];
      let startingAfter: string;
      do {
        const listTaxRatesResult = await listTaxRates({
          active: true,
          starting_after: startingAfter,
        });
        data = data.concat(listTaxRatesResult.data);
        startingAfter = listTaxRatesResult.has_more
          ? listTaxRatesResult.data[listTaxRatesResult.data.length - 1].id
          : null;
      } while (startingAfter);
      setTaxRates(data);
    } catch (error) {
      addNotification({
        message: error?.message || error,
        status: "danger",
        title: listTaxRatesErrorTitle,
      });
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      (async () => {
        setLoading(true);
        setTaxRates(null);
        await runListTaxRates();
        setLoading(false);
      })();
    }
  }, [isVisible, runListTaxRates]);

  useEffect(() => {
    if (isVisible) {
      setSearchText("");
      setTempSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const tempSelectedIds = tempSelected.map((taxRate) => taxRate.id);
  const availableTaxRates = taxRates?.filter((taxRate) => {
    if (taxRate.percentage === 0) {
      return false;
    }
    return (
      !searchText ||
      taxRate.percentage
        .toString()
        .toLowerCase()
        .includes(searchText.trim().toLowerCase())
    );
  });

  const toggleTaxRateSelect = (taxRate) => {
    if (tempSelectedIds.includes(taxRate.id)) {
      setTempSelected(
        tempSelected.filter(
          (currentTaxRate) => currentTaxRate.id !== taxRate.id
        )
      );
    } else {
      setTempSelected([
        ...tempSelected.filter(
          (currentTaxRate) => currentTaxRate.id !== taxRate.id
        ),
        taxRate,
      ]);
    }
  };

  return (
    <>
      <FullScreenModal isVisible={isVisible} onClose={onClose}>
        <Header
          accessoryRight={
            !createTaxRateDisabled &&
            (() => (
              <TopNavigationAction
                icon={(imageProps) => <Icon {...imageProps} name="plus" />}
                onPress={() => {
                  Keyboard.dismiss();
                  setCreateTaxRateModalVisible(true);
                }}
              />
            ))
          }
          navigation={{ icon: "close-outline", onPress: onClose }}
          subtitle={headerSubtitle}
          title={headerTitle}
        />
        <Separator size="small" />
        <StyledInput
          keyboardType="numeric"
          onChangeText={setSearchText}
          placeholder="Search..."
          value={searchText}
        />
        <Separator size="small" />
        <Divider />
        <Root level="2">
          <FlatList
            data={availableTaxRates}
            extraData={tempSelected}
            ItemSeparatorComponent={() => (
              <Layout>
                <StyledDivider />
              </Layout>
            )}
            keyExtractor={(item) => item.id}
            ListEmptyComponent={
              <ListEmptyContainer>
                <Separator />
                {loading ? (
                  <Spinner />
                ) : (
                  <Text appearance="hint" category="s1">
                    No results found
                  </Text>
                )}
              </ListEmptyContainer>
            }
            onRefresh={async () => {
              setRefreshing(true);
              await runListTaxRates();
              setRefreshing(false);
            }}
            refreshing={refreshing}
            renderItem={({ item }) => (
              <Layout>
                <TaxRateListItem
                  accessoryRight={(imageProps) => {
                    if (tempSelectedIds.includes(item.id)) {
                      return (
                        <Icon
                          {...imageProps}
                          fill={theme["color-primary-default"]}
                          name="checkmark-outline"
                        />
                      );
                    }
                    return null;
                  }}
                  onPress={toggleTaxRateSelect}
                  taxRate={item}
                />
              </Layout>
            )}
          />
        </Root>
        {!loading && taxRates?.length > 0 && (
          <>
            <Divider />
            <Layout>
              <SafeAreaView>
                <SubmitButtonContainer>
                  <Button
                    disabled={
                      !Array.isArray(availableTaxRates) ||
                      availableTaxRates.length === 0
                    }
                    loading={submitPending}
                    onPress={async () => {
                      setSubmitPending(true);
                      await onSubmit(tempSelected);
                      setSubmitPending(false);
                    }}
                  >
                    Select
                  </Button>
                </SubmitButtonContainer>
              </SafeAreaView>
            </Layout>
          </>
        )}
      </FullScreenModal>
      <CreateTaxRateModal
        isVisible={createTaxRateModalVisible}
        onClose={() => setCreateTaxRateModalVisible(false)}
        onSuccess={async (taxRate) => {
          toggleTaxRateSelect(taxRate);
          setSubmitPending(true);
          await onSubmit([
            ...tempSelected.filter(
              (currentTaxRate) => currentTaxRate.id !== taxRate.id
            ),
            taxRate,
          ]);
          setSubmitPending(false);
          setCreateTaxRateModalVisible(false);
        }}
      />
    </>
  );
};

export default TaxRateSelectModal;
