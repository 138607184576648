import gql from "graphql-tag";

import { privateCompanySiteFragment } from "../../../fragments";

export default gql`
  mutation adminRestoreCompanySite($id: ID!) {
    adminRestoreCompanySite(id: $id) {
      ...privateCompanySiteFragment
    }
  }
  ${privateCompanySiteFragment}
`;
