import {
  Divider,
  Icon,
  Input,
  Layout,
  Text,
  TopNavigationAction,
} from "@ui-kitten/components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Keyboard, SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";

import createTaxRate from "../../../api/functions/createTaxRate";
import { StripeTaxRate } from "../../../types";
import Button from "../../buildingBlocks/Button";
import HeaderWithTextAction from "../../buildingBlocks/HeaderWithTextAction";
import Separator from "../../buildingBlocks/Separator";
import Card from "../../Card";
import { addNotification } from "../../InAppNotifications";
import FullScreenModal, { Props as FullScreenModalProps } from "../FullScreen";

interface FormValues {
  percentage: string;
}

interface CreateTaxRateModalProps
  extends Pick<FullScreenModalProps, "isVisible" | "onClose"> {
  onSuccess: (taxRate: StripeTaxRate) => void;
}

const Root = styled(Layout)`
  flex: 1;
`;

const SubmitButtonContainer = styled.View`
  padding: 8px 16px;
`;

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledInput = styled(Input)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledText = styled(Text)`
  padding-left: 8px;
  padding-right: 8px;
`;

const createTaxRateErrorTitle = "Create tax rate failed";

const CreateTaxRateModal = ({
  isVisible,
  onClose,
  onSuccess,
}: CreateTaxRateModalProps) => {
  const [createTaxRatePending, setCreateTaxRatePending] = useState<boolean>(
    false
  );

  const formik = useFormik<FormValues>({
    initialValues: {
      percentage: "",
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      const percentage = parseFloat(values.percentage);
      if (Number.isNaN(percentage)) {
        addNotification({
          message: "Rate is not a number",
          status: "danger",
          title: createTaxRateErrorTitle,
        });
        return;
      }
      if (percentage <= 0) {
        addNotification({
          message: "Rate must be greater than zero",
          status: "danger",
          title: createTaxRateErrorTitle,
        });
        return;
      }
      setCreateTaxRatePending(true);
      try {
        const taxRate = await createTaxRate({
          display_name: "Sales tax",
          inclusive: false,
          percentage,
        });
        await onSuccess(taxRate);
      } catch (error) {
        addNotification({
          message: error?.message || error,
          status: "danger",
          title: createTaxRateErrorTitle,
        });
      }
      setCreateTaxRatePending(false);
    },
  });

  useEffect(() => {
    if (isVisible) {
      formik.resetForm();
    }
  }, [isVisible]);

  return (
    <FullScreenModal isVisible={isVisible} onClose={onClose}>
      <Root level="2">
        <HeaderWithTextAction
          navigation={{
            icon: "close-outline",
            onPress: onClose,
          }}
          title="Add tax rate"
        />
        <Divider />
        <ScrollView>
          <Separator size="medium" />
          <StyledCard>
            <Separator size="medium" />
            <StyledInput
              accessoryRight={() => <StyledText>%</StyledText>}
              keyboardType="numeric"
              label="Rate"
              onChangeText={(text) => formik.setFieldValue("percentage", text)}
              value={formik.values.percentage}
            />
            <Separator size="medium" />
          </StyledCard>
        </ScrollView>
        <Divider />
        <Layout>
          <SafeAreaView>
            <SubmitButtonContainer>
              <Button
                loading={createTaxRatePending}
                onPress={() => formik.handleSubmit()}
              >
                Add tax rate
              </Button>
            </SubmitButtonContainer>
          </SafeAreaView>
        </Layout>
      </Root>
    </FullScreenModal>
  );
};

export default CreateTaxRateModal;
