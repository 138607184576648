import gql from "graphql-tag";

import { jobFragment } from "../../../fragments";

export default gql`
  mutation createTaskCompletionRecord(
    $jobId: ID!
    $taskId: ID!
    $timestamp: AWSDateTime!
    $coordinates: CoordinatesInput!
    $media: MediaInput
    $dailyActivityReportId: ID
  ) {
    createTaskCompletionRecord(
      jobId: $jobId
      taskId: $taskId
      timestamp: $timestamp
      coordinates: $coordinates
      media: $media
      dailyActivityReportId: $dailyActivityReportId
    ) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;
