import { ButtonProps } from "@ui-kitten/components";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import Button from "../buildingBlocks/Button";
import selectUser from "../../store/auth/selectors/selectUser";
import selectUserById from "../../store/users/selectors/selectUserById";
import runClockIn from "../../utils/runClockIn";

interface Props extends Pick<ButtonProps, "appearance" | "size" | "style"> {
  "data-test"?: string;
}

const ClockInButton = ({
  appearance,
  "data-test": dataTest,
  size,
  style,
}: Props) => {
  const { id } = useSelector(selectUser);
  const user = useSelector((state) => selectUserById(state, id));

  const [clockInPending, setClockInPending] = useState(false);

  return (
    <Button
      appearance={appearance}
      data-test={dataTest}
      loading={clockInPending}
      onPress={async () => {
        setClockInPending(true);
        await runClockIn({ user });
        setClockInPending(false);
      }}
      size={size}
      style={style}
    >
      Clock in
    </Button>
  );
};

ClockInButton.defaultProps = {
  "data-test": undefined,
};

export default ClockInButton;
