import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Divider, Icon, Layout, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import equal from "fast-deep-equal";
import _ from "lodash/array";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { ProgressBar } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { rrulestr } from "rrule";
import styled from "styled-components/native";
import uuidv4 from "uuid/v4";

import completeJobMutation from "../../api/functions/completeJob";
import createCommuteRecord from "../../api/functions/createCommuteRecord";
import createDailyActivityReport from "../../api/functions/createDailyActivityReport";
import createIncidentReport from "../../api/functions/createIncidentReport";
import createStartJobRecord from "../../api/functions/createStartJobRecord";
import createTaskCompletionRecord from "../../api/functions/createTaskCompletionRecord";
import dismissJob from "../../api/functions/dismissJob";
import queryJobAccessories from "../../api/functions/queryJobAccessories";
import Button from "../../components/buildingBlocks/Button";
import HeaderWithTextAction from "../../components/buildingBlocks/HeaderWithTextAction";
import Separator from "../../components/buildingBlocks/Separator";
import Card from "../../components/Card";
import ClockInEnabledButton from "../../components/ClockInEnabledButton";
import Container from "../../components/Container";
import { addNotification } from "../../components/InAppNotifications";
import JobButtons from "../../components/JobButtons";
import JobStatusCompactLabels from "../../components/JobStatusCompactLabels";
import JobTeamCard from "../../components/JobTeamCard";
import ListItem from "../../components/listItems/Base";
import CustomerListItem from "../../components/listItems/Customer";
import LevelTwoListItem from "../../components/listItems/LevelTwo";
import ReportListItem from "../../components/listItems/Report";
import CompletableActionItemsList from "../../components/lists/CompletableActionItems";
import CompletableTasksList from "../../components/lists/CompletableTasks";
import ActionItemCompletionModal from "../../components/modals/ActionItemCompletion";
import AlertModal from "../../components/modals/Alert";
import CompleteJobEarlyModal from "../../components/modals/CompleteJobEarly";
import JobEditingModal from "../../components/modals/JobEditing";
import MediaViewerModal from "../../components/modals/MediaViewer";
import MultiActionAlertModal from "../../components/modals/MultiActionAlert";
import ReportEditingModal from "../../components/modals/ReportEditing";
import StartJobEarlyAlertModal from "../../components/modals/StartJobEarlyAlert";
import StartJobLateAlertModal from "../../components/modals/StartJobLateAlert";
import TaskCompletionModal from "../../components/modals/TaskCompletionModal";
import SendJobSummariesButton from "../../components/SendJobSummariesButton";
import TimeRange from "../../components/TimeRange";
import * as autocompleteJobConstants from "../../constants/autocompleteJob";
import companyUserRoles from "../../constants/companyUserRoles";
import JobStatuses from "../../constants/jobStatuses";
import rruleFreqToRepeatFrequency from "../../constants/rruleFreqToRepeatFrequency";
import useActionItemList from "../../hooks/useActionItemList";
import useAsyncStorage from "../../hooks/useAsyncStorage";
import useClockInAlertModal from "../../hooks/useClockInAlertModal";
import useJobUtils from "../../hooks/useJobUtils";
import usePrevious from "../../hooks/usePrevious";
import { getLocation } from "../../location/mapLocation";
import selectUser from "../../store/auth/selectors/selectUser";
import selectCompany from "../../store/company/selectors/selectCompany";
import { setJob } from "../../store/jobs/slice";
import selectId from "../../store/mapSelection/selectors/selectId";
import { clearMapSelection } from "../../store/mapSelection/slice";
import selectDateTimeFormat from "../../store/settings/selectors/selectDateTimeFormat";
import selectSiteById from "../../store/sites/selectors/selectById";
import selectSignedInUser from "../../store/users/selectors/selectSignedInUser";
import {
  ActionItem,
  ActionItemCompletionRecord,
  Coordinates,
  StackParamList,
  Task,
  TaskCompletionRecord,
} from "../../types";
import convertUtcRRuleToLocalWeekdays from "../../utils/convertUtcRRuleToLocal";
import getAsyncStorageKey from "../../utils/getAsyncStorageKey";
import getLocalByWeekdayRRuleDescription from "../../utils/getLocalByWeekdayRRuleDescription";
import getNumberOfNotCompletedTasks from "../../utils/getNumberOfNotCompletedTasks";
import getNumberOfNotSubmittedReports from "../../utils/getNumberOfNotSubmittedReports";
import isCustomersEnabled from "../../utils/isCustomersEnabled";
import isFeatureEnabled from "../../utils/isFeatureEnabled";
import { navigateWithGoogleMaps } from "../../utils/map";
import runGeofenceDependentFunction from "../../utils/runGeofenceDependentFunction";
import userRolePermitted from "../../utils/userRolePermitted";

const styles = StyleSheet.create({
  bottomButtonContainer: {
    flexDirection: "row",
    marginHorizontal: 16,
  },
  bottomButtons: {
    flex: 1,
  },
  button: {
    marginEnd: 16,
    marginStart: 16,
  },
  buttonsRowContainer: {
    flexDirection: "row",
    paddingEnd: 16,
    paddingStart: 16,
  },
  container: {
    flex: 1,
    flexDirection: "row",
  },
  content: {
    flex: 1,
  },
  disabledWarningContainer: {
    paddingBottom: 8,
    paddingEnd: 16,
    paddingStart: 16,
    paddingTop: 8,
  },
  divider: {
    marginStart: 16,
  },
  ghostLabel: {
    borderColor: "transparent",
    borderRadius: 4,
    borderWidth: 1,
    paddingEnd: 4,
    paddingStart: 4,
    textAlign: "center",
  },
  jobHeader: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  icon: {
    height: 24,
    width: 24,
  },
  label: { marginRight: 8 },
  labelContainer: {
    alignItems: "center",
    marginHorizontal: 16,
    paddingBottom: 8,
    paddingTop: 8,
  },
  largeSeparator: {
    height: 16,
  },
  largeHorizontalSeparator: {
    width: 16,
  },
  listAccessory: {
    flexDirection: "row",
    alignItems: "center",
    marginEnd: 8,
    marginStart: 8,
  },
  mapFrame: {
    borderRadius: 4,
    overflow: "hidden",
  },
  map: {
    flex: 1,
  },
  mapContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  root: {
    flex: 1,
  },
  progressBarContainer: {
    bottom: 0,
    end: 0,
    position: "absolute",
    start: 0,
  },
  rowButton: {
    flex: 1,
  },
  scrollContent: {
    paddingTop: 8,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingEnd: 16,
    paddingStart: 16,
  },
  separator: {
    height: 8,
  },
  siteActionContainer: {
    marginEnd: 16,
    marginStart: 16,
  },
  text: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  timeRange: {
    paddingHorizontal: 16,
  },
  topNavigationActionOverlay: {
    position: "absolute",
    bottom: 0,
    end: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
  },
});

const StyledCard = styled(Card)`
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledJobTeamCard = styled(JobTeamCard)`
  margin-left: 16px;
  margin-right: 16px;
`;

const JobDetails = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation<
    StackNavigationProp<StackParamList, "JobDetails">
  >();
  const route = useRoute<RouteProp<StackParamList, "JobDetails">>();
  const { jobId } = route.params;
  const dispatch = useDispatch();
  const { group } = useSelector(selectUser);
  const { role: selfUserRole, id: selfUserId } = useSelector(
    selectSignedInUser
  );

  const dateTimeFormat = useSelector(selectDateTimeFormat);

  const selectedId = useSelector(selectId);
  const company = useSelector(selectCompany);
  const {
    createActionItemCompletionRecord,
    job,
    jobStarted,
    reports,
    selfClockedIn,
    tasksCompleted,
  } = useJobUtils(jobId);
  const { ClockInAlertModal, showClockInAlert } = useClockInAlertModal();
  const previousJobUpdated = usePrevious(job?.updated);

  const previousTasksCompleted = usePrevious(tasksCompleted);
  const commuteStarted = Boolean(
    job.commuteRecords?.find((commuteRecord) => commuteRecord.by === selfUserId)
  );
  const theme = useTheme();
  const site = useSelector((state) => job && selectSiteById(state, job.siteId));
  const { actionItems } = useActionItemList();

  const [queryPending, setQueryPending] = useState(false);
  const [completeJobAlertVisible, setCompleteJobAlertVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(null);
  const [editVisible, setEditVisible] = useState(false);
  const [
    createDailyActivityReportPending,
    setCreateDailyActivityReportPending,
  ] = useState(false);
  const [reportEditing, setReportEditing] = useState({
    reportId: null,
    visible: false,
  });
  const [mediaViewer, setMediaViewer] = useState({
    mediaId: null,
    visible: false,
  });
  const [actionItemsCollapsed, setActionItemsCollapsed] = useState(true);
  const [taskCompletionModal, setTaskCompletionModal] = useState<{
    taskCompletionParams?: {
      coordinates: Coordinates;
      taskId: string;
    };
    visible: boolean;
  }>({
    visible: false,
  });
  const [actionItemCompletionModal, setActionItemCompletionModal] = useState<{
    actionItemCompletionParams?: {
      actionItemId: string;
      coordinates: Coordinates;
    };
    visible: boolean;
  }>({
    visible: false,
  });
  const autocompleteJobAsyncStorage = useAsyncStorage(
    getAsyncStorageKey({
      key: autocompleteJobConstants.asyncStorageKey,
      userId: selfUserId,
    })
  );
  const autocompleteJob =
    autocompleteJobAsyncStorage.value ||
    autocompleteJobConstants.permissions.ask;

  const completeJob = async () => {
    await runGeofenceDependentFunction({
      errorTitle: "Job completion failed",
      location: {
        coordinates: site.coordinates,
        geofence: site.geofence,
      },
      onSuccess: (userCoordinates) => {
        completeJobMutation(
          {
            coordinates: {
              lat: userCoordinates.lat,
              lng: userCoordinates.lng,
            },
          },
          job
        );
        if (selectedId === job.id) {
          dispatch(clearMapSelection());
        }
        addNotification({
          title: "Job Completed",
          message:
            job.rrule &&
            "The next repeating job in the sequence has been created.",
          status: "success",
        });
        navigation.goBack();
      },
      userRole: selfUserRole,
    });
  };

  useEffect(() => {
    if (
      !job.finished &&
      typeof previousTasksCompleted === "boolean" &&
      tasksCompleted &&
      !previousTasksCompleted
    ) {
      const lastTaskCompletionRecord =
        job.taskCompletionRecords[job.taskCompletionRecords.length - 1];
      if (lastTaskCompletionRecord.record.by === selfUserId) {
        if (autocompleteJob === autocompleteJobConstants.permissions.always) {
          completeJob();
        } else if (
          autocompleteJob === autocompleteJobConstants.permissions.ask
        ) {
          setCompleteJobAlertVisible(true);
        }
      }
    }
  }, [tasksCompleted]);

  const customersEnabled = isCustomersEnabled(company);
  const dailyActivityReportsEnabled = company.jobSettings
    ? company.jobSettings.dailyActivityReportsEnabled
    : true;
  const incidentReportsEnabled = company.jobSettings
    ? company.jobSettings.incidentReportsEnabled
    : true;

  // Open the report editor if a daily activity report is attached to the task completion record
  // and it has not been submitted
  const handleTaskCompletionRecordPress = ({
    task,
    taskCompletionRecord,
  }: {
    task: Task;
    taskCompletionRecord: TaskCompletionRecord;
  }) => {
    if (selfClockedIn) {
      if (jobStarted) {
        if (
          taskCompletionRecord.record.by === selfUserId ||
          userRolePermitted({
            permittedRoles: ["Admin", "Supervisor"],
            userRole: selfUserRole,
          })
        ) {
          if (taskCompletionRecord.dailyActivityReportId) {
            const taskCompletionRecordDailyActivityReport =
              reports.entities[taskCompletionRecord.dailyActivityReportId];
            if (
              taskCompletionRecordDailyActivityReport &&
              !taskCompletionRecordDailyActivityReport.submitted
            ) {
              setReportEditing({
                reportId: taskCompletionRecord.dailyActivityReportId,
                visible: true,
              });
            } else {
              navigation.navigate("TaskCompletionRecordDetails", {
                jobId,
                taskId: task.id,
              });
            }
          } else {
            navigation.navigate("TaskCompletionRecordDetails", {
              jobId,
              taskId: task.id,
            });
          }
        } else {
          setAlertVisible("noAccess");
        }
      } else {
        setAlertVisible("startJobFromAction");
      }
    } else {
      showClockInAlert();
    }
  };
  const handleActionItemCompletionRecordPress = ({
    actionItem,
    actionItemCompletionRecord,
  }: {
    actionItem: ActionItem;
    actionItemCompletionRecord: ActionItemCompletionRecord;
  }) => {
    if (selfClockedIn) {
      if (jobStarted) {
        if (actionItemCompletionRecord.dailyActivityReportId) {
          const taskCompletionRecordDailyActivityReport =
            reports.entities[actionItemCompletionRecord.dailyActivityReportId];
          if (
            taskCompletionRecordDailyActivityReport &&
            !taskCompletionRecordDailyActivityReport.submitted
          ) {
            setReportEditing({
              reportId: actionItemCompletionRecord.dailyActivityReportId,
              visible: true,
            });
          } else {
            navigation.navigate("ActionItemCompletionRecordDetails", {
              actionItemId: actionItem.id,
              jobId,
            });
          }
        } else {
          navigation.navigate("ActionItemCompletionRecordDetails", {
            actionItemId: actionItem.id,
            jobId,
          });
        }
      } else {
        setAlertVisible("startJobFromAction");
      }
    } else {
      showClockInAlert();
    }
  };

  const runQueryJobAccessories = async () => {
    setQueryPending(true);
    try {
      await queryJobAccessories(jobId);
    } catch (error) {
      let message = "An unexpected error occurred.";
      if (error) {
        if (error.message) {
          message = error.message;
        }
      }
      addNotification({
        message,
        status: "danger",
        title: "Error",
      });
    }
    setQueryPending(false);
  };
  useEffect(() => {
    const triggerJobAccessoriesQuery = equal(job?.updated, previousJobUpdated);
    if (triggerJobAccessoriesQuery) {
      (async () => {
        await runQueryJobAccessories();
      })();
    }
  }, [job?.updated, previousJobUpdated]);

  const reportsEnabled = isFeatureEnabled({ company, feature: "reports" });
  const jobSummariesEnabled = isFeatureEnabled({
    company,
    feature: "jobSummaries",
  });
  const attemptCompleteJob = () => {
    if (getNumberOfNotCompletedTasks(job) > 0) {
      setAlertVisible("tasks");
    } else if (getNumberOfNotSubmittedReports(reports) > 0) {
      setAlertVisible("reports");
    } else if (moment(job.endDateTime).diff(moment(), "minutes") > 5) {
      setAlertVisible("completeJobEarly");
    } else {
      completeJob();
    }
  };
  const closeAlert = () => setAlertVisible(null);
  const closeReportEditing = () =>
    setReportEditing((prevState) => ({ ...prevState, visible: false }));
  const runDismissJob = async (nextId?: string) => {
    closeAlert();
    const location = await getLocation({ title: "Delete job failed" });
    if (location) {
      dismissJob(
        {
          coordinates: { lat: location.latitude, lng: location.longitude },
          nextId,
        },
        job
      );
      if (selectedId === job.id) {
        dispatch(clearMapSelection());
      }
      addNotification({
        title: "Job deleted",
        message:
          nextId && "The next repeating job in the sequence has been created.",
        status: "success",
      });
      navigation.goBack();
    }
  };
  const renderNoJobFound = () => {
    return (
      <ListItem
        title="Job not found"
        description="Make sure you have internet"
      />
    );
  };
  const renderJob = () => {
    const actionItemCompletionRecordCount =
      job.actionItemCompletionRecords?.length || 0;
    const runStartJob = async () => {
      closeAlert();
      await runGeofenceDependentFunction({
        bypassGeofence: Boolean(job.immediate),
        errorTitle: "Job start failed",
        location: {
          coordinates: site.coordinates,
          geofence: site.geofence,
        },
        onSuccess: (userCoordinates) =>
          createStartJobRecord(
            {
              coordinates: {
                lat: userCoordinates.lat,
                lng: userCoordinates.lng,
              },
            },
            job
          ),
        userRole: selfUserRole,
      });
    };
    const attemptRunStartJob = () => {
      if (moment(job.startDateTime).diff(moment(), "minutes") > 5) {
        setAlertVisible("startJobEarly");
      } else if (moment().diff(moment(job.startDateTime), "days") > 1) {
        setAlertVisible("startJobLate");
      } else {
        setAlertVisible("startJob");
      }
    };
    const handleCompletableTaskPress = async (task: Task) => {
      if (selfClockedIn) {
        if (jobStarted) {
          await runGeofenceDependentFunction({
            location: {
              coordinates: site.coordinates,
              geofence: site.geofence,
            },
            errorTitle: "Report creation failed",
            onSuccess: (userCoordinates) => {
              if (task.dailyActivityReportRequired) {
                const nowTimestamp = new Date().toISOString();
                const reportId = uuidv4();
                createDailyActivityReport({
                  id: reportId,
                  jobId,
                  started: {
                    at: nowTimestamp,
                    coordinates: {
                      lat: userCoordinates.lat,
                      lng: userCoordinates.lng,
                    },
                  },
                });
                createTaskCompletionRecord(
                  {
                    coordinates: userCoordinates,
                    dailyActivityReportId: reportId,
                    taskId: task.id,
                    timestamp: nowTimestamp,
                  },
                  job
                );
                setReportEditing({
                  reportId,
                  visible: true,
                });
              } else {
                setTaskCompletionModal({
                  taskCompletionParams: {
                    coordinates: userCoordinates,
                    taskId: task.id,
                  },
                  visible: true,
                });
              }
            },
            userRole: selfUserRole,
          });
        } else {
          setAlertVisible("startJobFromAction");
        }
      } else {
        showClockInAlert();
      }
    };
    const handleCompletableActionItemPress = async (actionItem: ActionItem) => {
      if (selfClockedIn) {
        if (jobStarted) {
          await runGeofenceDependentFunction({
            location: {
              coordinates: site.coordinates,
              geofence: site.geofence,
            },
            errorTitle: "Report creation failed",
            onSuccess: (userCoordinates) => {
              if (actionItem.dailyActivityReportRequired) {
                const nowTimestamp = new Date().toISOString();
                const reportId = uuidv4();
                createDailyActivityReport({
                  id: reportId,
                  jobId,
                  started: {
                    at: nowTimestamp,
                    coordinates: {
                      lat: userCoordinates.lat,
                      lng: userCoordinates.lng,
                    },
                  },
                });
                createActionItemCompletionRecord({
                  actionItemId: actionItem.id,
                  coordinates: userCoordinates,
                  dailyActivityReportId: reportId,
                });
                setReportEditing({
                  reportId,
                  visible: true,
                });
              } else {
                setActionItemCompletionModal({
                  actionItemCompletionParams: {
                    actionItemId: actionItem.id,
                    coordinates: userCoordinates,
                  },
                  visible: true,
                });
              }
            },
            userRole: selfUserRole,
          });
        } else {
          setAlertVisible("startJobFromAction");
        }
      } else {
        showClockInAlert();
      }
    };

    const renderButton = () => {
      if (jobStarted) {
        return (
          <ClockInEnabledButton
            onPress={attemptCompleteJob}
            style={styles.button}
          >
            Complete job
          </ClockInEnabledButton>
        );
      }

      return (
        <ClockInEnabledButton
          ConditionalTooltipProps={{
            anchorStyle: {
              flex: 1,
            },
          }}
          data-test="startButtonInJobDetails"
          onPress={attemptRunStartJob}
          style={styles.button}
        >
          Start job
        </ClockInEnabledButton>
      );
    };

    return (
      <>
        <View style={{ flex: 1 }}>
          <Container>
            <Layout>
              <View style={styles.labelContainer}>
                <JobStatusCompactLabels jobId={jobId} />
              </View>
            </Layout>
            <Divider />
          </Container>
          <ScrollView
            contentInsetAdjustmentBehavior="automatic"
            scrollIndicatorInsets={{ right: 1 }}
          >
            <Container>
              <Separator size="medium" />
              <Text category="h5" style={{ paddingEnd: 16, paddingStart: 16 }}>
                {site.name}
              </Text>
              <View>
                <ListItem
                  accessoryLeft={(imageProps) => (
                    <Icon {...imageProps} name="calendar" />
                  )}
                  disabled
                  style={{ backgroundColor: "transparent" }}
                  title={(textProps) => (
                    <TimeRange
                      appearance="hint"
                      category="s1"
                      endDateTime={job.endDateTime}
                      startDateTime={job.startDateTime}
                      rrule={job.rrule}
                      style={textProps.style}
                    />
                  )}
                />
              </View>
              {(job.tasks.length > 0 || actionItems.ids.length > 0) && (
                <>
                  <Separator />
                  <StyledCard>
                    {job.tasks.length > 0 && (
                      <CompletableTasksList
                        job={job}
                        onTaskPress={handleCompletableTaskPress}
                        onTaskCompletionRecordPress={
                          handleTaskCompletionRecordPress
                        }
                        reportsLoading={queryPending}
                      />
                    )}
                    {actionItems.ids.length > 0 && (
                      <>
                        <Separator size="medium" />
                        <LevelTwoListItem
                          accessoryRight={(imageProps) => (
                            <Icon
                              {...imageProps}
                              name={
                                actionItemsCollapsed
                                  ? "arrow-ios-downward"
                                  : "arrow-ios-upward"
                              }
                            />
                          )}
                          onPress={() =>
                            setActionItemsCollapsed(!actionItemsCollapsed)
                          }
                          style={{ marginEnd: 16, marginStart: 16 }}
                          title={() => (
                            <View
                              style={{
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                category="s1"
                                style={{ paddingEnd: 12, paddingStart: 12 }}
                              >
                                Extras
                              </Text>
                              {actionItemCompletionRecordCount > 0 && (
                                <>
                                  <View
                                    style={{
                                      alignItems: "center",
                                      backgroundColor:
                                        theme[
                                          "color-primary-transparent-default"
                                        ],
                                      borderRadius: 8,
                                      flexDirection: "row",
                                      overflow: "hidden",
                                      paddingEnd: 8,
                                      paddingStart: 8,
                                    }}
                                  >
                                    <Icon
                                      name="checkmark"
                                      fill={theme["color-primary-default"]}
                                      style={{ height: 16, width: 16 }}
                                    />
                                    <Separator horizontal size={4} />
                                    <Text status="primary">
                                      {actionItemCompletionRecordCount}
                                    </Text>
                                  </View>
                                </>
                              )}
                            </View>
                          )}
                        />
                        <Separator size="medium" />
                        {!actionItemsCollapsed && (
                          <CompletableActionItemsList
                            job={job}
                            onActionItemPress={handleCompletableActionItemPress}
                            onActionItemCompletionRecordPress={
                              handleActionItemCompletionRecordPress
                            }
                          />
                        )}
                      </>
                    )}
                  </StyledCard>
                </>
              )}
              <Separator />
              <Separator size="small" />
              <View style={{ marginBottom: -4, marginTop: -4 }}>
                <ListItem
                  accessoryLeft={(imageProps) => (
                    <Icon {...imageProps} name="pin" />
                  )}
                  disabled
                  style={{ backgroundColor: "transparent", minHeight: 0 }}
                  title={() => (
                    <Text
                      appearance="hint"
                      style={{
                        marginHorizontal: 8,
                      }}
                    >
                      {site.address}
                    </Text>
                  )}
                />
              </View>
              {job.autostart && (
                <View style={{ marginBottom: -4, marginTop: -4 }}>
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="play-circle" />
                    )}
                    disabled
                    style={{
                      backgroundColor: "transparent",
                      minHeight: 0,
                    }}
                    title={() => (
                      <Text appearance="hint" style={{ marginHorizontal: 8 }}>
                        Starts automatically at start time
                      </Text>
                    )}
                  />
                </View>
              )}
              {job.rrule && (
                <View style={{ marginBottom: -4, marginTop: -4 }}>
                  <ListItem
                    accessoryLeft={(imageProps) => (
                      <Icon {...imageProps} name="refresh" />
                    )}
                    disabled
                    style={{
                      backgroundColor: "transparent",
                      minHeight: 0,
                    }}
                    title={() => (
                      <Text
                        appearance="hint"
                        ellipsizeMode="tail"
                        numberOfLines={1}
                        style={{ marginHorizontal: 8 }}
                      >
                        {`Repeats ${getLocalByWeekdayRRuleDescription({
                          startDateTime: job.startDateTime,
                          byWeekday: convertUtcRRuleToLocalWeekdays({
                            utcRRule: job.rrule,
                          }),
                          frequency:
                            rruleFreqToRepeatFrequency[
                              rrulestr(job.rrule).origOptions.freq
                            ],
                        })}`}
                      </Text>
                    )}
                  />
                </View>
              )}
              <View>
                <Separator />
                <Divider style={{ marginEnd: 16, marginStart: 16 }} />
                <Separator size="medium" />
              </View>
              <JobButtons
                job={job}
                onDetailsPress={() =>
                  navigation.navigate("SiteDetails", {
                    siteId: job.siteId,
                  })
                }
                onLocatePress={() =>
                  navigation.navigate("UpcomingJobs", site.coordinates)
                }
                onMessagesSuccess={() =>
                  navigation.navigate("Channel", {
                    channelId: job.id,
                    type: "messaging",
                  })
                }
                onNavigateSuccess={async (location) => {
                  const startCommuteAvailable =
                    !commuteStarted && !job.finished;
                  if (location && startCommuteAvailable) {
                    const updatedJob = await createCommuteRecord({
                      jobId: job.id,
                      coordinates: {
                        lat: location.latitude,
                        lng: location.longitude,
                      },
                      timestamp: new Date().toISOString(),
                    });
                    dispatch(setJob({ job: updatedJob }));
                  }
                  navigateWithGoogleMaps({
                    destination: {
                      latitude: site.coordinates.lat,
                      longitude: site.coordinates.lng,
                    },
                  });
                }}
                site={site}
              />
              <View>
                <Separator size="medium" />
                <Divider style={{ marginEnd: 16, marginStart: 16 }} />
                <Separator size="medium" />
              </View>
              {(Boolean(site.notes) ||
                Boolean(
                  site.privateNotes && group !== companyUserRoles.EMPLOYEE
                )) && (
                <StyledCard title="Location notes">
                  <View style={{ paddingEnd: 16, paddingStart: 16 }}>
                    {Boolean(site.notes) && (
                      <>
                        <Separator size="medium" />
                        <Text>{site.notes}</Text>
                      </>
                    )}
                    {Boolean(
                      site.privateNotes && group !== companyUserRoles.EMPLOYEE
                    ) && (
                      <>
                        <Separator size="medium" />
                        <Text appearance="hint" category="c1">
                          PRIVATE
                        </Text>
                        <Text>{site.privateNotes}</Text>
                      </>
                    )}
                    <Separator size="medium" />
                  </View>
                </StyledCard>
              )}
              {site.customerId &&
                customersEnabled &&
                userRolePermitted({
                  permittedRoles: ["Admin", "Supervisor"],
                  userRole: selfUserRole,
                }) && (
                  <>
                    <Separator />
                    <StyledCard title="Customer">
                      <CustomerListItem
                        accessoryRight={(imageProps) => (
                          <Icon
                            {...imageProps}
                            name="arrow-ios-forward-outline"
                          />
                        )}
                        customerId={site.customerId}
                        onPress={() =>
                          navigation.navigate("CustomerDetails", {
                            customerId: site.customerId,
                          })
                        }
                      />
                      {jobSummariesEnabled && (
                        <>
                          <Divider style={styles.divider} />
                          {job.lastSent && (
                            <>
                              <Separator size="medium" />
                              <View>
                                <Text
                                  appearance="hint"
                                  style={{ marginHorizontal: 16 }}
                                >
                                  {`Summary sent: ${dayjs(
                                    job.lastSent.at
                                  ).format(dateTimeFormat)}`}
                                </Text>
                              </View>
                            </>
                          )}
                          <Separator size="medium" />
                          <SendJobSummariesButton
                            jobIds={[jobId]}
                            status="basic"
                            style={styles.button}
                          >
                            Send job summary
                          </SendJobSummariesButton>
                          <Separator size="medium" />
                        </>
                      )}
                    </StyledCard>
                  </>
                )}
              {reportsEnabled &&
                (dailyActivityReportsEnabled ||
                  incidentReportsEnabled ||
                  (reports && reports.ids.length > 0)) && (
                  <>
                    <Separator />
                    <StyledCard title="Reports">
                      {reports && reports.ids.length > 0 && (
                        <>
                          {reports.ids
                            .filter((reportId) => {
                              const report = reports.entities[reportId];
                              let reportDeleted = false;
                              if ("deleted" in report) {
                                reportDeleted = report.deleted;
                              }
                              return (
                                !reportDeleted &&
                                (selfUserRole !== companyUserRoles.EMPLOYEE ||
                                  report.created.by === selfUserId)
                              );
                            })
                            .sort((a, b) =>
                              reports.entities[a].created.at.localeCompare(
                                reports.entities[b].created.at
                              )
                            )
                            .map((reportId) => (
                              <React.Fragment key={reportId}>
                                <ReportListItem
                                  data-test={`${reportId}-reportListItem`}
                                  id={reportId}
                                  jobId={jobId}
                                  onPress={() => {
                                    const report = reports.entities[reportId];
                                    if (report.submitted) {
                                      navigation.navigate("ReportDetails", {
                                        jobId,
                                        reportId,
                                      });
                                    } else {
                                      setReportEditing({
                                        reportId,
                                        visible: true,
                                      });
                                    }
                                  }}
                                />
                              </React.Fragment>
                            ))}
                          <Divider style={styles.divider} />
                        </>
                      )}
                      {(dailyActivityReportsEnabled ||
                        incidentReportsEnabled) && (
                        <>
                          <Separator size="medium" />
                          <View style={styles.buttonsRowContainer}>
                            {dailyActivityReportsEnabled && (
                              <View style={styles.rowButton}>
                                <ClockInEnabledButton
                                  data-test="dailyActivityButtonInJobDetails"
                                  loading={createDailyActivityReportPending}
                                  onPress={async () => {
                                    setCreateDailyActivityReportPending(true);
                                    await runGeofenceDependentFunction({
                                      location: {
                                        coordinates: site.coordinates,
                                        geofence: site.geofence,
                                      },
                                      errorTitle: "Report creation failed",
                                      onSuccess: (userCoordinates) => {
                                        const reportId = uuidv4();
                                        createDailyActivityReport({
                                          id: reportId,
                                          jobId,
                                          started: {
                                            at: new Date().toISOString(),
                                            coordinates: {
                                              lat: userCoordinates.lat,
                                              lng: userCoordinates.lng,
                                            },
                                          },
                                        });
                                        setReportEditing({
                                          reportId,
                                          visible: true,
                                        });
                                      },
                                      userRole: selfUserRole,
                                    });
                                    setCreateDailyActivityReportPending(false);
                                  }}
                                  status="basic"
                                >
                                  Daily activity
                                </ClockInEnabledButton>
                              </View>
                            )}
                            {dailyActivityReportsEnabled &&
                              incidentReportsEnabled && (
                                <Separator horizontal size="small" />
                              )}
                            {incidentReportsEnabled && (
                              <View style={styles.rowButton}>
                                <ClockInEnabledButton
                                  data-test="incidentReportButtonInJobDetails"
                                  onPress={() => {
                                    const reportId = uuidv4();
                                    createIncidentReport({
                                      id: reportId,
                                      jobId,
                                      timestamp: new Date().toISOString(),
                                    });
                                    setReportEditing({
                                      reportId,
                                      visible: true,
                                    });
                                  }}
                                  status="basic"
                                >
                                  Incident
                                </ClockInEnabledButton>
                              </View>
                            )}
                          </View>
                          <Separator size="medium" />
                        </>
                      )}
                    </StyledCard>
                  </>
                )}
              <Separator />
              <StyledJobTeamCard
                job={job}
                onSendMessagePress={() =>
                  navigation.navigate("Channel", {
                    channelId: job.id,
                    type: "messaging",
                  })
                }
              />
              <Separator size={48} />
              {userRolePermitted({
                permittedRoles: ["Admin", "Supervisor"],
                userRole: selfUserRole,
              }) &&
                !job.finished && (
                  <Button
                    appearance="ghost"
                    data-test="dismissButtonInJobDetails"
                    onPress={() => setAlertVisible("dismiss")}
                    status="danger"
                    style={{ marginEnd: 16, marginStart: 16 }}
                  >
                    Delete this job
                  </Button>
                )}
              <Separator />
            </Container>
          </ScrollView>
          {!job.finished && (
            <>
              <Divider />
              <Layout>
                <SafeAreaView>
                  <Container>
                    <Separator size="small" />
                    {renderButton()}
                    <Separator size="small" />
                  </Container>
                </SafeAreaView>
              </Layout>
            </>
          )}
        </View>
        <MediaViewerModal
          isVisible={mediaViewer.visible}
          media={
            site.media &&
            site.media[
              _.findIndex(
                site.media,
                (media) => media.id === mediaViewer.mediaId
              )
            ]
          }
          onClose={() =>
            setMediaViewer((prevState) => ({ ...prevState, visible: false }))
          }
        />
        <CompleteJobEarlyModal
          isVisible={alertVisible === "completeJobEarly"}
          jobEndDateTime={job.endDateTime}
          onCancel={closeAlert}
          onClose={closeAlert}
          onConfirm={completeJob}
        />
        <StartJobEarlyAlertModal
          isVisible={alertVisible === "startJobEarly"}
          jobStartDateTime={job.startDateTime}
          onCancel={closeAlert}
          onClose={closeAlert}
          onConfirm={runStartJob}
        />
        <StartJobLateAlertModal
          isVisible={alertVisible === "startJobLate"}
          job={job}
          onCancel={closeAlert}
          onClose={closeAlert}
          onConfirm={runStartJob}
        />
        <AlertModal
          confirmText="Start job"
          isVisible={alertVisible === "startJob"}
          message="Are you sure you want to start this job?"
          onCancel={closeAlert}
          onClose={closeAlert}
          onConfirm={runStartJob}
        />
        <AlertModal
          confirmText="Start job"
          isVisible={alertVisible === "startJobFromAction"}
          message={
            job.status === JobStatuses.future
              ? "You cannot act on a job that is in the future"
              : "You must start this job in order to continue"
          }
          onCancel={closeAlert}
          onClose={closeAlert}
          onConfirm={job.status === JobStatuses.future ? null : runStartJob}
        />
      </>
    );
  };
  return (
    <>
      <Layout style={styles.root}>
        <View>
          <Container>
            <View
              style={{
                paddingEnd: insets.right,
                paddingStart: insets.left,
                paddingTop: insets.top,
              }}
            >
              <HeaderWithTextAction
                action={
                  userRolePermitted({
                    permittedRoles: ["Admin", "Supervisor"],
                    userRole: selfUserRole,
                  }) && {
                    loading: false,
                    onPress: () => setEditVisible(true),
                    text: "Edit",
                  }
                }
                navigation={{
                  icon: "arrow-back-outline",
                  onPress: () => navigation.goBack(),
                }}
                title="Job details"
              />
            </View>
          </Container>
          <Divider />
          {queryPending && (
            <View style={styles.progressBarContainer}>
              {/* @ts-ignore */}
              <ProgressBar
                color={theme["color-primary-default"]}
                indeterminate
              />
            </View>
          )}
        </View>
        <Layout level="2" style={styles.content}>
          {job ? renderJob() : renderNoJobFound()}
        </Layout>
      </Layout>
      <MultiActionAlertModal
        actions={
          job.rrule
            ? [
                {
                  onPress: () => runDismissJob(uuidv4()),
                  text: "Delete this job only",
                },
                {
                  onPress: () => runDismissJob(),
                  text: "Delete all future jobs",
                },
                { appearance: "ghost", onPress: closeAlert, text: "Cancel" },
              ]
            : [
                { onPress: () => runDismissJob(), text: "Delete job" },
                { appearance: "ghost", onPress: closeAlert, text: "Cancel" },
              ]
        }
        isVisible={alertVisible === "dismiss"}
        onClose={closeAlert}
        message={`Are you sure you want to delete this job?${
          job.rrule ? " This is a repeating job" : ""
        }`}
      />
      <JobEditingModal
        initialValues={job}
        isVisible={editVisible}
        onClose={() => setEditVisible(false)}
      />
      <ReportEditingModal
        isVisible={reportEditing.visible}
        jobId={jobId}
        onClosePress={() =>
          setReportEditing((prevState) => ({ ...prevState, visible: false }))
        }
        onDelete={() => {
          setReportEditing((prevState) => ({ ...prevState, visible: false }));
        }}
        onSubmit={() => {
          setReportEditing((prevState) => ({ ...prevState, visible: false }));
        }}
        reportId={reportEditing.reportId}
      />
      {ClockInAlertModal}
      <AlertModal
        cancelText="No"
        confirmText="Complete job"
        isVisible={alertVisible === "reports"}
        message={`${getNumberOfNotSubmittedReports(reports)} ${
          getNumberOfNotSubmittedReports(reports) === 1
            ? "report has"
            : "reports have"
        } not been submitted. Are you sure you want to complete the job?`}
        onCancel={closeAlert}
        onClose={closeAlert}
        onConfirm={completeJob}
      />
      <AlertModal
        cancelText="No"
        confirmText="Complete job"
        isVisible={alertVisible === "tasks"}
        message={`${job && getNumberOfNotCompletedTasks(job)} ${
          job && getNumberOfNotCompletedTasks(job) === 1
            ? "task has"
            : "tasks have"
        } not been completed. Are you sure you want to complete the job?`}
        onCancel={closeAlert}
        onClose={closeAlert}
        onConfirm={completeJob}
      />
      <AlertModal
        cancelText="No"
        confirmText="Complete job"
        doNotAskAgainText="Remember this decision"
        isVisible={completeJobAlertVisible}
        message="All tasks have been completed. Do you want to complete the job?"
        onCancel={async (doNotAskAgain) => {
          setCompleteJobAlertVisible(false);
          if (doNotAskAgain) {
            await autocompleteJobAsyncStorage.setItem(
              autocompleteJobConstants.permissions.never
            );
          }
        }}
        onClose={() => setCompleteJobAlertVisible(false)}
        onConfirm={async (doNotAskAgain) => {
          setCompleteJobAlertVisible(false);
          await completeJob();
          if (doNotAskAgain) {
            await autocompleteJobAsyncStorage.setItem(
              autocompleteJobConstants.permissions.always
            );
          }
        }}
        showDoNotAskAgain={
          autocompleteJob === autocompleteJobConstants.permissions.ask
        }
      />
      <AlertModal
        cancelText="Close"
        isVisible={alertVisible === "noAccess"}
        message="You do not have permission to access checklist items being handled by other users"
        onCancel={closeAlert}
        onClose={closeAlert}
      />
      <TaskCompletionModal
        isVisible={taskCompletionModal.visible}
        job={job}
        onClose={async () => {
          setTaskCompletionModal((prevState) => ({
            ...prevState,
            visible: false,
          }));
        }}
        taskCompletionParams={taskCompletionModal.taskCompletionParams}
      />
      <ActionItemCompletionModal
        isVisible={actionItemCompletionModal.visible}
        job={job}
        onClose={() =>
          setActionItemCompletionModal((prevState) => ({
            ...prevState,
            visible: false,
          }))
        }
        actionItemCompletionParams={
          actionItemCompletionModal.actionItemCompletionParams
        }
      />
    </>
  );
};

export default JobDetails;
