import { UserRecord } from "../../types";

const isCompletionRecordCompleted = ({
  checklistItem,
  completionRecord,
  completionRecordDailyActivityReport,
}: {
  checklistItem: {
    dailyActivityReportRequired?: boolean;
  };
  completionRecord: {
    dailyActivityReportId?: string;
  };
  completionRecordDailyActivityReport?: {
    submitted?: UserRecord;
  };
}) => {
  if (!completionRecord) {
    return false;
  }
  if (completionRecord.dailyActivityReportId) {
    return Boolean(completionRecordDailyActivityReport?.submitted);
  }
  return !checklistItem.dailyActivityReportRequired;
};

export default isCompletionRecordCompleted;
