import gql from "graphql-tag";

import { stripeTaxRateFragment } from "../../../fragments";

const updateTaxRate = gql`
  mutation updateTaxRate($active: Boolean, $display_name: String, $id: ID!) {
    updateTaxRate(active: $active, display_name: $display_name, id: $id) {
      ...stripeTaxRateFragment
    }
  }
  ${stripeTaxRateFragment}
`;

export default updateTaxRate;
