import gql from "graphql-tag";

import { companyUserFragment } from "../../../fragments";

export default gql`
  mutation adminRestoreCompanyUser($id: ID!) {
    adminRestoreCompanyUser(id: $id) {
      ...companyUserFragment
    }
  }
  ${companyUserFragment}
`;
