import _ from "lodash/lang";
import { memo, useContext, useEffect } from "react";
import { MapCircleProps } from "react-native-maps";

import GoogleApi from "../map/GoogleApi";

const Circle = ({
  center,
  fillColor,
  radius,
  strokeColor,
  strokeWidth,
}: MapCircleProps) => {
  const googleApi = useContext(GoogleApi);
  const map = googleApi && googleApi.map;
  const maps = googleApi && googleApi.maps;
  useEffect(() => {
    let GoogleMapsCircle;
    if (map && maps) {
      GoogleMapsCircle = new maps.Circle({
        center: { lat: center.latitude, lng: center.longitude },
        clickable: false,
        fillColor,
        radius,
        strokeColor,
        strokeWidth,
      });
      GoogleMapsCircle.setMap(map);
    }
    return () => {
      if (GoogleMapsCircle) {
        GoogleMapsCircle.setMap(null);
      }
    };
  }, [center, fillColor, map, maps, radius, strokeColor, strokeWidth]);
  return null;
};

Circle.defaultProps = {
  fillColor: undefined,
  strokeColor: "transparent",
  strokeWidth: undefined,
};

export default memo(Circle, (prevProps, nextProps) =>
  _.isEqual(
    {
      center: prevProps.center,
      fillColor: prevProps.fillColor,
      radius: prevProps.radius,
      strokeColor: prevProps.strokeColor,
      strokeWidth: prevProps.strokeWidth,
    },
    {
      center: nextProps.center,
      fillColor: nextProps.fillColor,
      radius: nextProps.radius,
      strokeColor: nextProps.strokeColor,
      strokeWidth: nextProps.strokeWidth,
    }
  )
);
